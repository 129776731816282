import { configureStore } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom/client';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import createSagaMiddleware from 'redux-saga';
import { Layout } from './design_system';
import { reducers } from './redux';
import { rootSaga } from './sagas';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

setConfiguration({
  gutterWidth: 0,
  gridColumns: Layout.columns,
  maxScreenClass: 'xxl',
  breakpoints: Object.values(Layout.breakpoints)
});

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware)
})

sagaMiddleware.run(rootSaga)


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <ScreenClassProvider>
        <App />
      </ScreenClassProvider>
    </PersistGate >
  </Provider>
);
