import { ReactNode } from "react"
import { useSelector } from "react-redux"
import { Badge, BadgePropsForMethod, CardHorizzontalSpacer, Col, DescriptionBlock, Icon, P, Spacer } from "../.."
import { REDUX_STATE } from "../../../redux"
import { PAYMENT_METHOD_STATE, PaymentMethod, UUID } from "../../../utilities"


const TopMethodsTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={`1px solid ${palette.gray2}`}>
            <Spacer size={head ? 8 : 12} />
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="30%" max_w="30%">{children[0]}</Col>
                <Col w="30%" max_w="30%">{children[1]}</Col>
                <Col w="30%" max_w="30%">{children[2]}</Col>
                <Col w="10%" max_w="10%">{children[3]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            <Spacer size={head ? 8 : 12} />
        </Col>

    return render()
}

export const TopMethodsTableHead = () => {

    let n = 0, id = UUID.create()

    const cols = [
        { name: "Amount" },
        { name: "State" },
        { name: "Volume" },
        { name: "Revenue" }
    ]

    const render = () =>
        <TopMethodsTableRowLayout head>
            {
                cols.map(x =>
                    <Col key={id + n++} pointer wrap="nowrap" align="center">
                        <P regular variant="secondary"> {x.name} </P>
                    </Col>
                )
            }
        </TopMethodsTableRowLayout>

    return render()
}

export interface TopMethodsTableRowProps {
    method: PaymentMethod
    state: PAYMENT_METHOD_STATE
    volume: number
    revenue: number
}

export const TopMethodsTableRow = (props: TopMethodsTableRowProps) => {

    const { method, state, volume, revenue } = props

    const render = () =>
        <TopMethodsTableRowLayout>
            <DescriptionBlock data={method} type="method-psp-large" />
            <Badge {...BadgePropsForMethod(state)} />
            <P regular variant="dark">${volume.toLocaleString()}</P>
            <P regular variant="dark">${revenue.toLocaleString()}</P>
        </TopMethodsTableRowLayout >

    return render()
}
