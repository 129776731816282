import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { PaletteProps } from "../../design_system"
import { REDUX_STATE } from "../../redux"

export interface CheckboxProps {
    disabled?: boolean
    defaultChecked?: boolean
    onChange?: (val: boolean) => void
}

export const Checkbox = (props: CheckboxProps) => {

    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <CheckboxStyled
            type="checkbox"
            palette={palette}
            disabled={props.disabled}
            defaultChecked={props.defaultChecked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange && props.onChange(e.currentTarget.checked)} />

    return render()
}

const CheckboxStyled = styled.input<{ palette: PaletteProps }>`
${({ palette }) => css`

    /* Not removed via appearance */
    margin: 0;
    /* Remove most all native input styles */
    appearance: none;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* For iOS < 15 */
    background-color: ${palette.white};

    display: grid;
    place-content: center;
    transform: translateY(-0.075em);
    width: 28px;
    height: 28px;
    transition: 190ms background-color ease-in-out;
    border-radius: 4px;
    border: 2px solid ${palette.gray2};

    &::before {
        content: "";
        transform: scale(0);
        background-position:center;
        background-repeat: no-repeat;
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        width: 12px;
        height: 12px;
        background-size: 12px;
        background-image: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' fill='none'%3E%3Cpath d='M1.5 7.5L5 11L13 2' stroke='${palette.white.replace('#', '%23')}' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`};
    }

    &:checked {
        background-color: ${palette.primary2};
        border-color: ${palette.gray2};
        
        &::before {
            transform: scale(1);
        }
    }

    &:disabled {
        pointer-events: none;
        background-color: ${palette.gray2};
        border-color: ${palette.gray2};
        
        &::before {
            background-image: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' fill='none'%3E%3Cpath d='M1.5 7.5L5 11L13 2' stroke='${palette.gray1.replace('#', '%23')}' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`};
        }
    }
`}
`

