import { useDispatch, useSelector } from "react-redux"
import { Toggle } from ".."
import { Layout } from "../../design_system"
import { REDUX_STATE, changeTheme } from "../../redux"

export const ThemeSelector = (props: { visible?: boolean }) => {

  const dispatch = useDispatch()
  let { negative, isDark } = useSelector(REDUX_STATE).theme

  const Render = () =>
    <Toggle checked={isDark} onToggle={(v) => {
      document.body.style.opacity = "0"
      document.body.style.background = negative[Layout.background]

      window.setTimeout(() => {
        dispatch(changeTheme(v))
        document.body.style.opacity = ""
        document.body.style.background = ""
      }, 150)

    }} />

  return props.visible === false ? <></> : Render()
}
