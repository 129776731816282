export interface LanguagesProps {
    ro: string
    it: string
    us: string
    fr: string
    se: string
    gb: string
    nl: string
    de: string
    pt: string
    be: string
    at: string
    gr: string
    al: string
    fi: string
    pl: string
    au: string
    es: string
    hr: string
    ua: string
    si: string
    ie: string
    cz: string
    ar: string
    ca: string
}

export const Languages = {
    ro: "Romanian",
    it: "Italian",
    us: "English",
    fr: "French",
    se: "Swedish",
    gb: "English",
    nl: "Dutch",
    de: "Dutch",
    pt: "Portuguese",
    be: "Dutch",
    at: "Dutch",
    gr: "Greek",
    al: "Albanian",
    fi: "Finnish",
    pl: "Polish",
    au: "English",
    es: "Spanish",
    hr: "Croatian",
    ua: "Ukrainian",
    si: "Slovenian",
    ie: "English",
    cz: "Czech",
    ar: "Spanish",
    ca: "English",
}

export interface LanguagesCodeProps {
    Romanian: string
    Italian: string
    French: string
    Swedish: string
    English: string
    Dutch: string
    Portuguese: string
    Greek: string
    Albanian: string
    Finnish: string
    Polish: string
    Spanish: string
    Croatian: string
    Ukrainian: string
    Slovenian: string
    Czech: string
}

export const LanguagesCode = {
    Romanian: "ro",
    Italian: "it",
    French: "fr",
    Swedish: "se",
    English: "gb",
    Dutch: "de",
    Portuguese: "pt",
    Greek: "gr",
    Albanian: "al",
    Finnish: "fi",
    Polish: "pl",
    Spanish: "es",
    Croatian: "hr",
    Ukrainian: "ua",
    Slovenian: "si",
    Czech: "cz",
} 