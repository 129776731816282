import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

export interface AccessData {
    canAccess: boolean
    lastUpdate: number
}

const initialState: AccessData = {
    canAccess: false,
    lastUpdate: new Date().getTime()
}

export const accessSlice = createSlice({
    name: 'access',
    initialState,
    reducers: {

        updateTimestamp: (state: AccessData) => {
            return {
                ...state,
                lastUpdate: new Date().getTime()
            }
        },

        setAccess: (state: AccessData, action: PayloadAction<boolean>) => {
            return {
                ...state,
                canAccess: action.payload,
                lastUpdate: new Date().getTime() 
            }
        }
    }
})

export const { updateTimestamp, setAccess } = accessSlice.actions

export const accessReducer = persistReducer(
    {
        key: 'accessKey',
        storage,
    },
    accessSlice.reducer
)
