import "flatpickr/dist/flatpickr.css"
import { useEffect, useRef, useState } from "react"
import DatePicker from "react-flatpickr"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { Col, Container, Icon, TextProps, InputDesign as design } from ".."
import { PaletteProps } from "../../design_system"
import { REDUX_STATE } from "../../redux"
import { checkDate } from "../../utilities"

export interface DateInputProps {
    onChange?: (val: number) => void
    value?: Date
    defaultValue?: Date
    withControls?: boolean
    error?: boolean
    disabled?: boolean
}

export const DateInput = (props: DateInputProps) => {

    const ref = useRef<DatePicker>(null)
    const { palette } = useSelector(REDUX_STATE).theme
    const { error, disabled, value, defaultValue, onChange } = props

    const [day, setDay] = useState('')

    const refreshPicker = () => {
        setDay(checkDate(ref.current?.flatpickr?.selectedDates[0]))
    }

    useEffect(refreshPicker, [])
    useEffect(() => {
        value && ref.current?.flatpickr?.setDate(value)
    }, [value])

    const render = () =>
        <Container>
            <Col xs='content' h="100%" right={`${design.horizontalPadding + design.borderWidth}px`} position="absolute" top="0" align="center">
                {
                    error && !disabled &&
                    <Icon width={24} onClick={() => ref.current?.flatpickr.open()} variant='danger' color={palette.error2} />
                }
            </Col>
            {
                day !== '' &&
                <Col bg={design.background} align="center" justify="center" position="absolute" m={4} w={`calc(100% - ${8}px)`} h={`calc(100% - ${8}px)`} onClick={() => ref.current?.flatpickr?.open()} >
                    <Today palette={palette}> {day} </Today>
                </Col>
            }
            <DateInputStyled ref={ref} palette={palette} error={error}
                options={{ defaultDate: defaultValue ?? "today", dateFormat: "d-m-Y" }}
                onChange={() => {
                    refreshPicker()
                    const d = ref.current?.flatpickr?.selectedDates[0]
                    if (d) { onChange && onChange(d.getTime()) }
                }} />
        </Container >

    return render()
}


interface TodayProps extends TextProps { palette: PaletteProps }
const Today = styled.div <TodayProps>`  
${({ palette }) => css`
    cursor: default;    
    ${design.typography.default}
    color: ${palette[design.color]};    
`}
`

const DateInputStyled = styled(DatePicker) <{ palette: PaletteProps, error?: boolean }>`
${({ palette, error }) => css`
    cursor: default;
    text-align: center;
    ${design.typography.default};

    width: 100%;
    height: ${design.height.default}px;
    transition: ${design.transition};
    padding: 0px ${design.horizontalPadding}px;

    color: ${palette[design.color]};
    background: ${palette[design.background]};

    border-width: ${design.borderWidth}px;
    border-style: ${design.borderStyle};
    border-color: ${palette[design.borderColor]};
    border-radius: ${design.borderRadius}px;

    outline-width: ${design.outlineWidth}px;
    outline-style: ${design.outlineStyle};
    outline-color: ${palette[design.outlineColor]};

    &:hover {
        border-color: ${palette[design.onHover.borderColor]};
    }

    &.active, &:focus {
        border-color: ${palette[design.borderColor]};
        box-shadow: ${design.onFocus.shadow(palette)};
        border-color: ${palette[design.onFocus.borderColor]};
        outline-color: ${palette[design.onFocus.outlineColor]};
    }

    &:disabled {
        pointer-events: none;
        color: ${palette[design.onDisabled.color]};
        background: ${palette[design.onDisabled.background]};
        border-color: ${palette[design.onDisabled.borderColor]};
        outline-color: ${palette[design.onDisabled.outlineColor]};
    }

    ${error && css`
        padding-right: ${design.horizontalPadding * 2 + 20}px;
        border-color: ${palette[design.onError.borderColor]};
        
        &:hover {
            border-color: ${palette[design.onError.onHover.borderColor]};   
        }
        
        &:focus {
            border-color: ${palette[design.onError.onFocus.borderColor]};
            box-shadow: ${design.onError.onFocus.shadow(palette)};   
        }
    `}
`}
`
