import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_KEYS_TABLE_COLS, ApiKeysEngine, ApiKeysOrder, ApiKeysRowProps } from "../utilities";

export interface ApiKeysData {
    apiKeys: ApiKeysRowProps[]
    order: ApiKeysOrder
    pagination: {
        results: number
        current: number
    }
}

const initialState: ApiKeysData = {
    apiKeys: [],
    order: {
        by: API_KEYS_TABLE_COLS.CREATED,
        ascendent: true
    },
    pagination: {
        results: 15,
        current: 0
    }
}

export const apiKeysSlice = createSlice({
    name: 'apiKeys',
    initialState,
    reducers: {

        loadData: (state: ApiKeysData) => {
            var apiKeys = ApiKeysEngine.getRows()
            return {
                ...state,
                apiKeys,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order: initialState.order
            }
        },

        orderData: (state: ApiKeysData, action: PayloadAction<API_KEYS_TABLE_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order
            }
        },

        setCurrentPage: (state: ApiKeysData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: ApiKeysData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        createApiKey: (state: ApiKeysData, action: PayloadAction<ApiKeysRowProps>) => {
            return {
                ...state,
                apiKeys: [...state.apiKeys, action.payload]
            }
        }
    }
})

export const apiKeysActions = apiKeysSlice.actions

export const apiKeysReducer = apiKeysSlice.reducer

