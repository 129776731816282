import { useSelector } from "react-redux";
import { Col, Row, Widget } from "../..";
import { Fees } from "../../../fake_data";
import { REDUX_STATE } from "../../../redux";
import { PAYIN_STATE, PayinProps, UUID } from "../../../utilities";


export const AnalyticsWidgets = () => {

    const { payins, sellers, buyers } = useSelector(REDUX_STATE).analytics

    const getValues = (p: PayinProps[]) => {
        const succeeded = p.filter(x => x.state === PAYIN_STATE.SUCCEEDED)
        return {
            succeeded,
            volume: succeeded.length < 1 ? 0 : succeeded.map(x => x.amount).reduce((a, b) => a + b),
            revenue: succeeded.length < 1 ? 0 : succeeded.map(x => {
                var fee = Fees[x.method.value]
                var countryFee = fee[x.seller.country]
                var platformFee = countryFee?.platformFee
                return x.amount * ((platformFee ? platformFee / 100 : 0) ?? 0)
            }).reduce((a, b) => a + b)
        }
    }

    const current = getValues(payins.current)
    const last = getValues(payins.last)

    const getPercentage = (current: number, last: number) => current > 0 && last > 0 ? ((current - last) / last) * 100 : 0

    const volumeGrowth = getPercentage(current.volume, last.volume)
    const revenueGrowth = getPercentage(current.revenue, last.revenue)
    const transactionsGrowth = getPercentage(current.succeeded.length, last.succeeded.length)
    const sellersGrowth = getPercentage(sellers.current.length, sellers.last.length)
    const buyersGrowth = getPercentage(buyers.current.length, buyers.last.length)

    const data = [
        { title: "Volume", subtitle: `$${current.volume.toLocaleString()}`, value: volumeGrowth },
        { title: "Revenue", subtitle: `$${current.revenue.toLocaleString()}`, value: revenueGrowth },
        { title: "Transactions", subtitle: `${current.succeeded.length}`, value: transactionsGrowth },
        { title: "Active Sellers", subtitle: `${sellers.current.length}`, value: sellersGrowth },
        { title: "Active Buyers", subtitle: `${buyers.current.length}`, value: buyersGrowth },
    ]

    let n = 0, id = UUID.create()

    const render = () =>
        <Row mr={-16} wrap="nowrap">
            {
                data.map(x =>
                    <Col pr={16} key={id + n++}>
                        <Widget {...x} />
                    </Col>
                )
            }
        </Row>

    return render()
}
