import { useSelector } from "react-redux"
import { CardHorizzontalSpacer, CheckoutVisitsView, Col, Container, CountryFlag, DeviceLogo, P, Row, S, Spacer } from "../.."
import { REDUX_STATE } from "../../../redux"
import { Countries, CountryCode, LanguagesCode, LanguagesCodeProps, PAYIN_STATE, PayinState, UUID } from "../../../utilities"

export const CheckoutVisitsTableHead = (props: { state: [CheckoutVisitsView, React.Dispatch<React.SetStateAction<CheckoutVisitsView>>] }) => {

    let n = 0, id = UUID.create()
    const [view, setView] = props.state
    const { theme: { palette } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Location", value: CheckoutVisitsView.Location },
        { name: "Language", value: CheckoutVisitsView.Language },
        { name: "Device", value: CheckoutVisitsView.Device },
        { name: "State", value: CheckoutVisitsView.State }
    ]

    const render = () =>
        <Container>
            <Row gutterWidth={16} wrap="nowrap">
                <CardHorizzontalSpacer />
                {
                    cols.map(x =>
                        <Col key={id + n++} zIndex={1} xs="content" style={{ marginBottom: '-1px' }}>
                            <Col px={0} bb={view === x.value ? `2px solid ${palette.primary2}` : undefined} onClick={() => setView(x.value)} pointer>
                                <P regular nowrap variant={view === x.value ? "dark" : "secondary"}>
                                    {x.name}
                                </P>
                                <Spacer size={8} />
                            </Col>
                        </Col>
                    )
                }
                <CardHorizzontalSpacer />
            </Row>
            <Col xs={12} left="0px" bottom="-1px" zIndex={0} position="absolute" bb={`1px solid ${palette.gray2}`} />
        </Container>

    return render()
}

export interface CheckoutVisitsTableRowProps { view: CheckoutVisitsView, data: string, percentage: number }

export const CheckoutVisitsTableRow = (props: CheckoutVisitsTableRowProps) => {

    const { view, data, percentage } = props

    const render = () =>
        <Col xs={12}>
            <Spacer size={8} />
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col justify="between" align="center">
                    <Col wrap="nowrap" align="center">
                        {
                            view === CheckoutVisitsView.Location ?
                                <>
                                    <CountryFlag variant={data} />
                                    <Spacer w size={16} />
                                    <P regular>{Countries[data as CountryCode]}</P>
                                </>
                                :
                                view === CheckoutVisitsView.Language ?
                                    <>
                                        <CountryFlag variant={LanguagesCode[data as keyof LanguagesCodeProps]} />
                                        <Spacer w size={16} />
                                        <P regular>{data}</P>
                                    </>
                                    :
                                    view === CheckoutVisitsView.Device ?
                                        <>
                                            <DeviceLogo variant={data} />
                                            <Spacer w size={16} />
                                            <P regular>{data}</P>
                                        </>
                                        :
                                        <P regular>{PayinState(Number(data) as PAYIN_STATE)}</P>
                        }
                    </Col>
                    <S medium variant="secondary">{percentage.toFixed(2)}%</S>
                </Col>
                <CardHorizzontalSpacer />
            </Col>
            <Spacer size={8} />
        </Col>

    return render()
}
