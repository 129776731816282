import { Dashboard, DisputeEvidenceForm, DisputeInfoDetails, DisputeStateInfo, Spacer } from "../.."
import { Payins } from "../../../fake_data"
import { DemoRoutes } from "../../../routes"
import { DISPUTE_STATE, DisputesLedgerEngine } from "../../../utilities"

export const DisputeDetails = () => {

        const path = window.location.pathname.split('/')
        const id = path[path.length - 1]
        const payin = Payins.find(x => x.dispute?.id === id)
        const dispute = payin ? DisputesLedgerEngine.toLedgerRow(payin) : undefined

        const Render = () =>
                <Dashboard
                        pageTitle="Details"
                        onPage={DemoRoutes.ledger.disputeDetails}
                        breadcrumb={["Ledger", "Payments", "Disputes"]}>
                        {
                                dispute && <>
                                        <DisputeInfoDetails {...dispute} />
                                        <Spacer size={24} />
                                        {
                                                (dispute.state === DISPUTE_STATE.WON || dispute.state === DISPUTE_STATE.LOST) &&
                                                <DisputeStateInfo {...dispute} />
                                                ||
                                                (dispute.state === DISPUTE_STATE.NEEDS_RESPONSE || dispute.state === DISPUTE_STATE.UNDER_REVIEW) &&
                                                <DisputeEvidenceForm {...dispute} />
                                        }
                                </>
                        }
                </Dashboard>

        return Render()
}
