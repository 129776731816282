import { BadgePropsForSeller, Card, CardDataCollection, CardDivider, CardHeaderData, CardTitle } from "../.."
import { Payins } from "../../../fake_data"
import { PAYIN_STATE, PAYOUT_STATE, SellerProps } from "../../../utilities"

export const SellerAccountDetails = (seller: SellerProps) => {

    const payins = Payins.filter(x => x.seller.id === seller.id && x.state === PAYIN_STATE.SUCCEEDED)
    const to_pay = payins.filter(x => x.payout === PAYOUT_STATE.TO_PAY).map(x => x.amount).reduce((a, b) => a + b, 0)
    const paid = payins.filter(x => x.payout === PAYOUT_STATE.PAID).map(x => x.amount).reduce((a, b) => a + b, 0)
    const locked = payins.filter(x => x.payout === PAYOUT_STATE.LOCKED).map(x => x.amount).reduce((a, b) => a + b, 0)
    const pending = payins.filter(x => x.payout === PAYOUT_STATE.PENDING).map(x => x.amount).reduce((a, b) => a + b, 0)

    const render = () =>
        <Card>
            <CardTitle>Account</CardTitle>

            <CardHeaderData
                id={seller.id}
                title={seller.name}
                badge={BadgePropsForSeller(seller.state)} />

            <CardDivider />

            <CardDataCollection data={[
                { data: ["Lifetime volume", `$ ${(to_pay + paid + locked + pending).toFixed(2)}`] },
                { data: ["Current balance", `$ ${(to_pay + locked + pending).toFixed(2)}`] },
                { data: ["In transit to bank", `$ ${pending.toFixed(2)}`] },
                { data: ["Disputed", `$ ${locked.toFixed(2)}`] },
            ]} />
        </Card>

    return render()
}