import { ErrorMessages } from "./errors/messages";

export class Validators {

    static ForNumber(value: string): boolean {
        const regexp = /^[\d.]+$/;
        return regexp.test(value);
    }

    static ForEmail(value: string): boolean {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(value)
    }

    static ForBookingDate(value: number): boolean {
        return new Date(new Date().setHours(0, 0, 0, 0)).getTime() <= value
    }

    static ForPassword(value: string): boolean {
        const regexp = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+.]).*$/;
        return regexp.test(value)
    }

    static ForPhone(value: string): boolean {
        const regexp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,14}$/;
        return regexp.test(value)
    }

}

export type RequestErrors = {
    [key: string]: string;
};

export const isInvalidRequest = <T extends RequestErrors>(errors: T): boolean => {
    return Object.keys(errors)
        .filter(key => key !== 'generic')
        .map(x => errors[x as keyof T])
        .some(x => x.length > 0);
};

export const hasErrors = (errors: Object) => {
    return Object.values(errors).some(x => x.length > 0)
}

export type ValidationResult = {
    success: boolean
    errors: ValidationErrors
}

export type ValidationErrors = {
    [key: string]: string
}

export const GenericValidationErrors = {
    generic: ErrorMessages.server_error
}
