import { BuyerProps, DisputeProps, PAYMENT_METHOD, PAYOUT_STATE, PaymentMethod, PspProps, SellerProps } from "."

export enum PAYIN_STATE {
    INITIATED,
    PROVIDED_METHOD,
    PENDING,
    FAILED,
    SUCCEEDED,
    DISPUTED,
    REFUNDED,
}

export interface PayinProps {
    currency: string
    amount: number
    state: PAYIN_STATE
    isFraudolent?: boolean
    id: string
    provider: PspProps
    method: PaymentMethod
    buyer: BuyerProps
    seller: SellerProps
    date: number
    payout: PAYOUT_STATE
    device: string
    dispute?: DisputeProps
}
