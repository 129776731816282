import { useSelector } from "react-redux"
import { Col, H5, Icon, IconProps, Spacer } from ".."
import { REDUX_STATE } from "../../redux"
import { SpacerValue } from "../../design_system"

export interface SidebarItemProps {
        name: string,
        variant: 'primary' | 'secondary'
        icon: IconProps["variant"],
        iconSize?: number
        url?: string
        active?: boolean,
        open?: boolean
}

export const SidebarItem = (props: SidebarItemProps) => {

        const { name, icon, iconSize, variant, url, active, open } = props
        const { palette, collapsedSidebar } = useSelector(REDUX_STATE).theme

        const renderSecondary = () =>
                <Col xs={12} b_rad="8px" bg={active ? palette.gray2 : undefined}>
                        <Spacer size={8} />

                        <Col xs="content" wrap="nowrap">
                                <Spacer size={collapsedSidebar ? 8 : 32} w />
                                <Icon width={iconSize ?? 19} variant={icon} color={active ? palette.primary2 : palette.gray3} />
                                <H5 medium nowrap color={active || open ? palette.black : palette.gray4}>
                                        <Spacer size={(8 + 19 - (iconSize ?? 19)) as SpacerValue} w />
                                        {name}
                                </H5>
                                <Spacer size={collapsedSidebar ? 8 : 12} w />
                        </Col>

                        <Spacer size={8} />
                </Col>

        const renderPrimary = () =>
                <Col xs={collapsedSidebar ? "content" : 12} b_rad="8px" bg={collapsedSidebar && open ? palette.gray2 : active ? palette.gray2 : undefined}>
                        <Spacer size={8} />

                        <Col xs="content" wrap="nowrap">
                                <Spacer size={collapsedSidebar ? 8 : 12} w />
                                <Icon width={iconSize ?? 19} variant={icon} color={collapsedSidebar && open ? palette.primary2 : open ? palette.gray5 : active ? palette.primary2 : palette.gray3} />
                                {
                                        !collapsedSidebar &&
                                        <H5 medium nowrap color={active || open ? palette.black : palette.gray4}>
                                                <Spacer size={(16 + 19 - (iconSize ?? 19)) as SpacerValue} w />
                                                {name}
                                        </H5>
                                }
                                <Spacer size={collapsedSidebar ? 8 : 12} w />
                        </Col>

                        <Spacer size={8} />
                </Col >

        return <a href={url}>
                {
                        variant === "primary" ?
                                renderPrimary() : renderSecondary()
                }
        </a>
}

