import { createGlobalStyle } from "styled-components";
import { PaletteProps } from "./palette";
import { Layout } from "./layout";
import { AccessCodeContainer, AccessCodeFormStyled } from "../components";
import { cssTransition } from "../utilities";

export const GlobalStyles = createGlobalStyle<{ palette: PaletteProps }>`
    * {
        box-sizing: border-box;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        width: 100%;
        min-width: 100%;
        height: 100%;
        line-height: 1px;
        position: relative;
        background-color: ${p => p.palette[Layout.background]};
        transition: .15s opacity ease-out, .3s background-color ease-out ;
    }

    #root {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
    }

    button, span {
    }

    a {
        text-decoration: none;
    }

    .ReactModal__Overlay {
        background: ${p => p.palette.white}00 !important;
        transition: background-color 180ms ease-out!important;
    }
    
    .ReactModal__Overlay--after-open {
        background: ${p => p.palette.white}D9 !important;
    }
    
    .ReactModal__Overlay--before-close {
        background: ${p => p.palette.white}00 !important;
    }


    .bios-modal.ReactModal__Content {
        overflow: unset;
    }

    .modal-page.ReactModal__Content {
        opacity: 0 !important;
        right: -50% !important;
        transition: right 400ms ease-out, opacity 400ms ease-out!important;
    }

    .modal-page.ReactModal__Content--after-open {
        opacity: 1 !important;
        right: 0% !important;
        transition: right 180ms ease-out, opacity 90ms ease-out!important;
    }
    
    .modal-page.ReactModal__Content--before-close {
        opacity: 0 !important;
        right: -50% !important;
        transition: right 400ms ease-out, opacity 400ms ease-out!important;
    }


    &.authorized {

        ${AccessCodeFormStyled} {
            opacity: 0;
            transform: scale(0);
            ${cssTransition("all 200ms ease-in")}
        }

        ${AccessCodeContainer} {
            transform: scale(4);
            ${cssTransition("all 1s 200ms ease")}
        }

        #root {
            opacity: 0;
            ${cssTransition("opacity 1s 200ms ease-in")}
        }
    }

    #root {
        opacity: 1;
        ${cssTransition("opacity 1s 200ms ease-out")}
    }
`

// font-family: ${Typography.family};

export const LadingPageStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }
    
    *:focus {
        outline: none;
    }
    
    html, body {
        overflow-x: hidden;
        position: relative;
    }
    
    body {
        background-color: #030014;
        overflow-y: hidden;    
        color: #fff;
        width: 100vw;

        -ms-overflow-style: none;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    @keyframes galacticSpaceAnimation {
       0% {
            opacity: 0;
            transform: scale(0.4);
            transform: rotate(8deg);
            animation-timing-function: ease-in;
        }

        75% {
            opacity: 1;
        }
        
        100% {
            opacity: .2;
            transform: scale(1.5);
        }
    }

    &.authorized {

        ${AccessCodeFormStyled} {
            opacity: 0;
            transform: scale(0);
            ${cssTransition("all 200ms ease-in")}
        }

        ${AccessCodeContainer} {
            transform: scale(4);
            ${cssTransition("all 1s 200ms ease")}
        }

        #root {
            opacity: 0;
            ${cssTransition("opacity 1s 200ms ease-in")}
        }
    }
    
    #root {
        opacity: 1;
        ${cssTransition("opacity 1s 200ms ease-out")}
    }
`




