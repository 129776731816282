import { ReactElement, useEffect, useRef, useState } from "react"
import _Select, { Props as _SelectProps } from 'react-select'
import styled, { css } from "styled-components"
import { Col, H5, IconProps, SelectOptionsDesign, SidebarItem, SidebarItemProps, Spacer } from ".."
import { PaletteProps } from "../../design_system"
import { UUID } from "../../utilities"
import { useSelector } from "react-redux"
import { REDUX_STATE } from "../../redux"

interface SidebarBlockProps {
    title: string
    items: {
        name: string,
        icon: IconProps["variant"],
        iconSize?: number
        url: string
        active?: boolean
        open?: boolean
        sub?: {
            name: string
            url: string
            icon: IconProps["variant"],
            iconSize?: number
            active?: boolean
            open?: boolean
        }[]
    }[]
}

export const SidebarBlock = (props: SidebarBlockProps) => {

    const { title } = props
    const { palette, collapsedSidebar } = useSelector(REDUX_STATE).theme
    let n = 0, id = UUID.create()

    const render = () =>
        <Col>
            {
                collapsedSidebar ?
                    <Col bt={`1px solid ${palette.gray2}`} /> :
                    <H5 semiBold variant="secondary">
                        {title}
                    </H5>
            }
            <Spacer size={8} />
            {
                props.items.map(x =>
                    <Col key={id + n++} >
                        {
                            collapsedSidebar ?
                                (x.sub?.length ?? 0) < 1 ?
                                    <SidebarItem {...{ ...x, variant: 'primary' }} />
                                    :
                                    <SidebarDropdown title={x.name}
                                        options={x.sub?.map(y => { return { ...y, variant: "secondary" } }) ?? []}>
                                        <SidebarItem {...{ ...x, variant: 'primary', url: undefined }} />
                                    </SidebarDropdown>
                                :
                                <>
                                    <SidebarItem {...{ ...x, variant: 'primary' }} />
                                    {
                                        x.open && x.sub &&
                                        x.sub.map(y =>
                                            <SidebarItem key={id + n++} {...{ ...y, variant: 'secondary' }} />
                                        )
                                    }
                                </>

                        }
                    </Col>
                )
            }
            {
                collapsedSidebar &&
                <Spacer size={8} />
            }
        </Col >


    return render()
}

interface SidebarDropdownProps {
    title: string
    children: ReactElement
    options: SidebarItemProps[]
}

const SidebarDropdown = (props: SidebarDropdownProps) => {

    const { title, children, options } = props
    const [state, setState] = useState(false)
    const { palette } = useSelector(REDUX_STATE).theme
    let n = 0

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                setState(false);
            }
        };
        window.addEventListener("click", handler)
        return () => {
            window.removeEventListener("click", handler)
        }
    })

    const render = () =>
        <div ref={ref}>
            <Col>
                <div onClick={() => setState(!state)}>
                    {children}
                </div>
                <DropdownStyled palette={palette}
                    options={options}
                    menuIsOpen={state}
                    onChange={(e) => { }}
                    components={{
                        MenuList: (props) => {
                            return <Col p={12} min_w="250px">
                                {props.children}
                            </Col>
                        },
                        Option: (props) => {
                            n++
                            let opt = props.data as SidebarItemProps
                            return <>
                                <Col>
                                    {
                                        n === 1 &&
                                        <>
                                            <Spacer size={8} />
                                            <H5 medium variant="secondary">
                                                <Spacer size={8} />
                                                {title}
                                            </H5>
                                            <Spacer size={8} />
                                        </>
                                    }
                                    <SidebarItem {...opt} />
                                </Col>
                            </>
                        }
                    }}
                    styles={{
                        control: (baseStyles) => ({ ...baseStyles, display: 'none !important' }),
                        menu: (baseStyles) => ({ ...baseStyles,zIndex:10000, color: SelectOptionsDesign.color, minWidth: 'max-content' }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: palette[state.isSelected ? SelectOptionsDesign.onSelected.color : SelectOptionsDesign.color],
                            background: palette[state.isSelected ? SelectOptionsDesign.onSelected.background : SelectOptionsDesign.background],
                            ':hover': {
                                color: palette[SelectOptionsDesign.onHover.color],
                                background: palette[SelectOptionsDesign.onHover.background]
                            }
                        })
                    }} />
            </Col>
        </div>

    return render()
}

interface DropdownStyledProps extends _SelectProps {
    palette: PaletteProps
}

const DropdownStyled = styled(_Select) <DropdownStyledProps>`
${({ palette }) => css`


`}
`