import { ReactNode, useEffect } from "react"
import styled from "styled-components"
import { Col, Container, H2, Row, Spacer } from ".."
import { Layout } from "../../design_system"
import { UUID } from "../../utilities"

export const FormPage = (props: { children: ReactNode }) => {

    const render = () =>
        <Container>
            <Spacer size={80} />
            <Row justify="center" px={24}>
                <Col max_w="600px">
                    {props.children}
                </Col>
            </Row>
        </Container>

    return render()
}

export const Form = (props: { title?: string, children: ReactNode[], onSumbit?: React.FormEventHandler<HTMLFormElement> }) => {
    let n = 0
    let uniqueId = '';

    useEffect(() => { uniqueId = UUID.create() }, [])

    return <FormStyled onSubmit={(e) => { e.preventDefault(); props.onSumbit && props.onSumbit(e) }}>
        <Container>
            <Row direction="column" gutterHeight={24}>
                {
                    props.title && <H2>{props.title}</H2>
                }
                {
                    props.children.map(x => {
                        n++
                        return <Col key={`${n}${uniqueId}`} xs={Layout.columns}>{x}</Col>
                    })
                }
            </Row>
        </Container>
    </FormStyled>
}

const FormStyled = styled.form``