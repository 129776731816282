import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BUYER_STATE, BUYERS_LEDGER_COLS, BuyersLedgerEngine, BuyersLedgerFilter, BuyersLedgerOrder, BuyersLedgerRowProps } from "../utilities";

export interface BuyersLedgerData {
    buyers: BuyersLedgerRowProps[]
    period: {
        start: number
        end: number
    }
    order: BuyersLedgerOrder
    filters: BuyersLedgerFilter[]
    pagination: {
        results: number
        current: number
    }
}

const initialState: BuyersLedgerData = {
    buyers: [],
    period: {
        start: new Date(2023, 0, 1).getTime(),
        end: new Date().getTime()
    },
    order: {
        by: BUYERS_LEDGER_COLS.FROM,
        ascendent: true
    },
    filters: [
        {
            state: true,
            type: BUYER_STATE.ACTIVE
        },
        {
            state: true,
            type: BUYER_STATE.LOCKED
        }
    ],
    pagination: {
        results: 15,
        current: 0
    }
}

export const buyersLedgerSlice = createSlice({
    name: 'buyersLedger',
    initialState,
    reducers: {

        setPeriod: (state: BuyersLedgerData, action: PayloadAction<{ start: number, end: number }>) => {
            const { start, end } = action.payload
            return {
                ...state,
                period: { start: start, end: end }
            }
        },

        loadData: (state: BuyersLedgerData) => {
            var buyers = BuyersLedgerEngine.getRows(state.period.start, state.period.end)

            return {
                ...state,
                buyers,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order: initialState.order
            }
        },

        orderData: (state: BuyersLedgerData, action: PayloadAction<BUYERS_LEDGER_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order
            }
        },

        setCurrentPage: (state: BuyersLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: BuyersLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        toggleAppliedFilter: (state: BuyersLedgerData, action: PayloadAction<BUYER_STATE>) => {
            return {
                ...state,
                filters: state.filters.map(x => x.type !== action.payload ? x : { ...x, state: !x.state }),
                pagination: {
                    ...state.pagination,
                    current: 0
                }
            }
        }
    }
})

export const buyersLedgerActions = buyersLedgerSlice.actions

export const buyersLedgerReducer = buyersLedgerSlice.reducer

