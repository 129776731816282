import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, CardHorizzontalSpacer, Col, Icon, P, S, Spacer } from "../.."
import { REDUX_STATE, apiKeysActions } from "../../../redux"
import { API_KEYS_TABLE_COLS, API_KEY_TYPE, ApiKeysRowProps, UUID } from "../../../utilities"


export const ApiKeysTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={head ? undefined : `1px solid ${palette.gray2}`} pointer>
            {!head && <Spacer size={12} />}
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="15%" max_w="15%">{children[0]}</Col>
                <Col w="13%" max_w="13%">{children[1]}</Col>
                <Col w="50%" max_w="50%">{children[2]}</Col>
                <Col w="11%" max_w="11%">{children[3]}</Col>
                <Col w="11%" max_w="11%" justify="end">{children[4]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            {!head && <Spacer size={12} />}
        </Col>

    return render()
}

export const ApiKeysTableHead = () => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { orderData } = apiKeysActions
    const { theme: { palette }, apiKeys: { order } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Name", col: API_KEYS_TABLE_COLS.NAME },
        { name: "Type", col: API_KEYS_TABLE_COLS.TYPE },
        { name: "Public key", col: API_KEYS_TABLE_COLS.PUBLIC_KEY },
        { name: "Last use", col: API_KEYS_TABLE_COLS.LAST_USED },
        { name: "Created", col: API_KEYS_TABLE_COLS.CREATED },
    ]

    const render = () =>
        <ApiKeysTableRowLayout head>
            {
                cols.map(x =>
                    <Col xs="content" key={id + n++} py={12} pointer wrap="nowrap" align="center" onClick={() => dispatch(orderData(x.col as API_KEYS_TABLE_COLS))}>
                        <P nowrap mb={2} medium variant="secondary"> {x.name} </P>
                        <Icon pointer color={palette.black} height={18} variant={`ordinament${order.by !== x.col ? "" : order.ascendent ? "-asc" : "-des"}`} />
                    </Col>
                )
            }
        </ApiKeysTableRowLayout>

    return render()
}

export const ApiKeysTableRow = (props: ApiKeysRowProps) => {

    const { id, name, type, publicKey, lastUse, created } = props


    const render = () =>
        <ApiKeysTableRowLayout>
            <P regular variant="dark">{name}</P>
            <Badge variant={type === API_KEY_TYPE.LIVE ? "success" : "pending"}>
                {type === API_KEY_TYPE.LIVE ? "Live" : "Test"}
            </Badge>
            <S regular variant="primary">{publicKey}</S>
            <P regular variant="dark">{lastUse ? new Date(lastUse).toLocaleDateString() : "-"}</P>
            <P regular variant="dark">{new Date(created).toLocaleDateString()}</P>
        </ApiKeysTableRowLayout >

    return render()
}
