import { ReactNode, useState } from "react"
import { useSelector } from "react-redux"
import _Select from 'react-select'
import styled, { css } from "styled-components"
import { Badge, BadgePropsForMethod, Button, CardHorizzontalSpacer, Col, DescriptionBlock, Icon, IconStyled, Input, InputStyled, P, SelectOptionsDesign, Spacer } from "../.."
import { FontWeight, SpacerValue } from "../../../design_system/types"
import { PaletteProps } from "../../../design_system/palette"
import { Typography } from "../../../design_system/typography"
import { REDUX_STATE } from "../../../redux"
import { CountryCode, FeeProps, PAYMENT_METHOD_STATE, UUID } from "../../../utilities"


const MethodTableRowLayout = (props: { id?: string, children: ReactNode[], head?: boolean }) => {

    const { id, head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={`1px solid ${palette.gray2}`}>
            <div id={id} style={{ position: "absolute", top: "-80px" }} />
            <Spacer size={head ? 8 : 12} />
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="8%" max_w="8%">{children[0]}</Col>
                <Col w="12%" max_w="12%">{children[1]}</Col>
                <Col w="20%" max_w="20%">{children[2]}</Col>
                <Col w="20%" max_w="20%">{children[3]}</Col>
                <Col w="15%" max_w="15%">{children[4]}</Col>
                <Col>{children[5]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            <Spacer size={head ? 8 : 12} />
        </Col>

    return render()
}

export const MethodTableHead = () => {

    let n = 0, id = UUID.create()

    const cols = [
        { name: "Location" },
        { name: "State" },
        { name: "BIOS Fee" },
        { name: "Interchange Fee" },
        { name: "Your Fee" },
        { name: "" }
    ]

    const render = () =>
        <MethodTableRowLayout head>
            {
                cols.map(x =>
                    <Col key={id + n++} pointer wrap="nowrap" align="center">
                        <P regular variant="secondary"> {x.name} </P>
                    </Col>
                )
            }
        </MethodTableRowLayout>

    return render()
}


export function MethodTableRow(props: FeeProps) {

    const [state, setState] = useState(props.state)
    const [isLoadingState, setIsLoadingState] = useState(false)
    const [cache, setCache] = useState(props.platformFee)
    const [fee, setFee] = useState(props.platformFee)
    const [isUpdatingFee, setIsUpdatingFee] = useState(false)
    const { country, biosFeeFix, biosFeePerc, interchangeFee, platformFee } = props

    const interchangeFeeMin = interchangeFee === undefined ? undefined : interchangeFee.min.toFixed(2)
    const interchangeFeeMax = interchangeFee === undefined ? undefined : interchangeFee.max.toFixed(2)

    const render = () => <MethodTableRowLayout id={country}>
        <DescriptionBlock data={country as CountryCode} type="country" />
        <MethodStateSelector {...{ state, setState: onChangeState }} />
        <P medium nowrap variant="primary" spacing=".3px">{`$${biosFeeFix.toFixed(2)} + ${biosFeePerc.toFixed(2)}%`}</P>
        <P medium nowrap variant="primary" spacing=".3px">{interchangeFeeMin !== undefined && interchangeFeeMax !== undefined ? `${interchangeFeeMin.charAt(interchangeFeeMin.length - 1) === '0' ? interchangeFeeMin.slice(0, interchangeFeeMin.length - 1) : interchangeFeeMin} - ${interchangeFeeMax.charAt(interchangeFeeMax.length - 1) === '0' ? interchangeFeeMax.slice(0, interchangeFeeMax.length - 1) : interchangeFeeMax}%` : "-"}</P>
        <FeeInput value={fee} setCache={setCache} isUpdating={isUpdatingFee} />
        <Col justify="end">
            {isUpdatingFee ?
                <Button xs variant="primary" onClick={() => { setFee(cache); setIsUpdatingFee(!isUpdatingFee) }} style={{ maxWidth: '80px' }}>Confirm</Button>
                :
                state === PAYMENT_METHOD_STATE.SUSPENDED ? <span></span> :
                    <Button xs variant="alternate" isLoading={isLoadingState} onClick={() => onChangeState(PAYMENT_METHOD_STATE[state === PAYMENT_METHOD_STATE.ENABLED ? "DISABLED" : "ENABLED"])} style={{ maxWidth: '80px' }}>{state === PAYMENT_METHOD_STATE.ENABLED ? "Disable" : "Enable"} </Button>}
            <Spacer size={8} />
            <Button xs variant="alternate" onClick={() => setIsUpdatingFee(!isUpdatingFee)} style={{ maxWidth: '80px' }}>{isUpdatingFee ? "Back" : "Set Fee"}</Button>
        </Col>
    </MethodTableRowLayout>

    const onChangeState = (state: PAYMENT_METHOD_STATE) => {
        setIsLoadingState(true)
        window.setTimeout(() => {
            setState(state)
            setIsLoadingState(false)
        }, 400)
    }

    return render()
}



interface MethodStateSelectorProps {
    state: PAYMENT_METHOD_STATE
    setState: (s: PAYMENT_METHOD_STATE) => void
}


export const MethodStateSelector = (props: MethodStateSelectorProps) => {

    const { state } = props
    const { palette, isDark } = useSelector(REDUX_STATE).theme

    const render = () =>
        <MethodStateSelectorStyled state={state} palette={palette} isDark={isDark}
            isSearchable={false}
            hideSelectedOptions={true}
            isDisabled={state === PAYMENT_METHOD_STATE.SUSPENDED}
            value={{
                label: state === PAYMENT_METHOD_STATE.ENABLED ? 'Enable' : state === PAYMENT_METHOD_STATE.SUSPENDED ? 'Suspend' : 'Disable',
                value: state
            }}
            options={[
                { label: 'Enable this country', value: PAYMENT_METHOD_STATE.ENABLED },
                { label: 'Disable this country', value: PAYMENT_METHOD_STATE.DISABLED }
            ]}
            onChange={(e) => {
                const { value } = e as { label: string, value: number }
                props.setState(value)
            }}
            components={{
                ValueContainer: ({ children, innerProps, ...rest }) =>
                    <Col {...innerProps} {...rest}>
                        {children}
                        <MethodStateBadge>
                            <Badge xl {...BadgePropsForMethod(state)} />
                        </MethodStateBadge>
                    </Col>
            }}
            classNames={{
                menu: () => 'method-state-menu',
                menuList: () => 'method-state-menu-list',
                option: () => 'method-state-option',
                control: () => 'method-state-control',
                singleValue: () => 'method-state-single-value',
                input: () => 'method-state-input',
                container: () => 'method-state-container',
                valueContainer: () => 'method-state-value-container',
                dropdownIndicator: () => 'method-state-dropdown-indicator',
                indicatorSeparator: () => 'method-state-indicator-separator',
            }}
            styles={{
                option: (baseStyles, state) => ({
                    ...baseStyles,
                    color: palette[state.isSelected ? SelectOptionsDesign.onSelected.color : SelectOptionsDesign.color],
                    background: `${palette[state.isSelected ? SelectOptionsDesign.onSelected.background : SelectOptionsDesign.background]}${state.isSelected && isDark ? "1A" : ""}`,
                    ':hover': {
                        color: palette[SelectOptionsDesign.onHover.color],
                        background: `${palette[SelectOptionsDesign.onHover.background]}${isDark ? "1A" : ""}`
                    }
                })
            }} />

    return render()
}


const MethodStateBadge = styled.div`
    &:hover {
        opacity: .75;
    }

`

const MethodStateSelectorStyled = styled(_Select) <{ palette: PaletteProps, isDark: boolean, state: number }>`
${({ palette }) => css`

        ${Typography.S.styles};

        border: unset;
        display: flex;
        border-radius: 4px;
        background-color: unset;
        max-height: min-content;
        max-width: min-content;

        .method-state-single-value {
            display: none;
        }
        
        .method-state-menu {
            z-index: 10;
            overflow: hidden;
            min-width: max-content;
            color: ${palette[SelectOptionsDesign.color]};
            background: ${palette[SelectOptionsDesign.background]};
        }
        
        .method-state-menu-list {
            padding: 0px;
        }
        
        .method-state-dropdown-indicator {
                display: none;
        }
        
        .method-state-indicator-separator {
                width: 0px;
                background: transparent;
        }

        .method-state-control {
            input {
                position: absolute;
            }

            cursor: pointer;
            padding: 0px;
            min-height: unset;
            box-shadow: unset;
            min-width: min-content;
            background: transparent;

            border-width: 0px;
            border-style: solid;
            border-color: transparent;

            &:hover: {
                box-shadow: unset;
                border-width: 0px;
                border-style: solid;
                border-color: transparent;
            }

            outline-width: 0px;
            outline-style: solid;
            outline-color: transparent;
        }
        
        .method-state-option {
            ${Typography.S.styles};
            font-weight: 500;
            cursor: pointer;
        }
`} 
`


export const FeeInput = (props: { value: number, isUpdating?: boolean, setCache: (n: number) => void }) => {

    const { value, isUpdating, setCache } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <FeeInputStyled palette={palette}>
            {
                isUpdating ?
                    <Col ml={-5 as SpacerValue} wrap="nowrap" align="center" justify="between" max_w="50px" min_w="50px">
                        <Col wrap="nowrap" align="center" justify="between">
                            <Input xs type="number" defaultValue={value} style={{ paddingLeft: '4px', paddingRight: '4px' }} step={0.01} maxValue={100} onChange={(v) => { setCache(v) }} />
                        </Col>
                    </Col>
                    :
                    <P my={1 as SpacerValue} spacing=".3px" mb={2} medium nowrap variant="primary">
                        {`${value.toFixed(2)}%`}
                        <Icon ml={2} variant="edit" color={palette.gray5} width={18} stroke={1.3} />
                    </P>
            }
        </FeeInputStyled>

    return render()
}

const FeeInputStyled = styled.div<{ palette: PaletteProps }>`
    
    display: flex;
    width: min-content;

    ${IconStyled} {
        display: none;
    }

    ${InputStyled} {
        border-radius: 4px;
        letter-spacing: .3px;
        font-weight: ${FontWeight.medium};
        color: ${p => p.palette.gray5};
        background-color: transparent!important;
    }
`




