import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Dashboard, PayoutsTable, PeriodSelector, Spacer, TableFilters, TablePagination } from "../.."
import { REDUX_STATE, payoutsLedgerActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { PAYOUT_STATE, PayoutState, PayoutsLedgerEngine } from "../../../utilities"

export const PayoutsLedger = () => {

        const dispatch = useDispatch()
        useEffect(() => { dispatch(loadData()) }, [])

        const state = useSelector(REDUX_STATE).payouts
        var payouts = PayoutsLedgerEngine.getValues(state)

        const { results, current } = state.pagination
        const { loadData, setCurrentPage, toggleAppliedFilter, setPeriod } = payoutsLedgerActions

        const pages = Math.floor(payouts.length / results) - (payouts.length % results === 0 ? 1 : 0)
        const rows = payouts.slice(current * results, (current * results) + results)

        const Render = () =>
                <Dashboard
                        pageTitle="Payouts"
                        onPage={DemoRoutes.ledger.payouts}
                        breadcrumb={["Ledger", "Payments", "Payouts"]}>

                        <Col justify="between" wrap="nowrap">
                                <TableFilters
                                        filters={state.filters.map(x => { return { name: PayoutState(x.type), state: x.state, type: x.type } })}
                                        onClick={(val: number) => dispatch(toggleAppliedFilter(val as PAYOUT_STATE))} />
                                <PeriodSelector start={new Date(state.period.start)} end={new Date(state.period.end)} onApplied={(s, e) => { dispatch(setPeriod({ start: s.getTime(), end: e.getTime() })); window.setTimeout(() => { dispatch(loadData()) }, 500) }} />
                        </Col>

                        <Spacer size={16} />

                        <PayoutsTable {...rows} />

                        <Spacer size={24} />

                        <TablePagination {...{
                                pages, current, results,
                                items: payouts.length,
                                setCurrent: (page: number) => dispatch(setCurrentPage(page))
                        }} />
                </Dashboard>

        return Render()
}

