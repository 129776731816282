import { ReactNode } from "react"
import { useSelector } from "react-redux"
import { Button, Card, CardBlock, CardDivider, CardLink, CardSubtilte, CardText, CardTitle, Col, Copyable, Icon, P, Row, S, Spacer, Spacers } from "../.."
import { SpacerValue } from "../../../design_system"
import { REDUX_STATE } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { DISPUTE_REASON, DISPUTE_STATE, DisputeReason, Months } from "../../../utilities"


interface DisputeStateInfoProps {
    id: string
    dueOn: number
    amount: number
    state: DISPUTE_STATE
    reason: DISPUTE_REASON
    networkReasonCode: string
    submittedEvidenceOn?: number
}

export const DisputeStateInfo = (props: DisputeStateInfoProps) => {

    const { id, amount, state, reason, networkReasonCode } = props
    const { palette } = useSelector(REDUX_STATE).theme

    let
        dueOn = new Date(props.dueOn),
        submittedEvidenceOn = props.submittedEvidenceOn ? new Date(props.submittedEvidenceOn) : undefined

    const EvidenceSubmitted = () =>
        <Card noPadding overflow="hidden">
            <CardTitle withPadding>
                <Icon mr={8} width={14} variant="hammer" />
                Your dispute evidence has been submitted
            </CardTitle>

            <CardBlock bg={palette.gray1}>
                <Row>
                    <Col xs={6} pr={24}>
                        <CardText>
                            Your response submitted{submittedEvidenceOn && ` on ${Months[submittedEvidenceOn.getMonth()].slice(0, 3)} ${submittedEvidenceOn.getDate()}, ${submittedEvidenceOn.getFullYear()}`} is being reviewed by the cardholder’s issuing bank. We will notify you when we receive the issuing bank’s decision, which can take up to three months.
                        </CardText>
                        <Spacer size={8} />
                        <CardLink>
                            Learn more about the dispute lifecycle
                        </CardLink>
                    </Col>
                    <Col xs={6} pl={24}>
                        <CardSubtilte>
                            Relevant documents
                        </CardSubtilte>
                        <CardLink>
                            View your submitted evidence
                        </CardLink>
                    </Col>
                </Row>
            </CardBlock>
        </Card>

    const Lost = () =>
        <Card noPadding overflow="hidden">
            <CardTitle withPadding>
                <Icon mr={8} width={14} variant="refunded" />
                You accepted the dispute
            </CardTitle>

            <CardBlock bg={palette.gray1}>
                <CardText>
                    The refund to the cardholder stands and no further action is required.
                </CardText>
                <Spacer size={8} />
                <CardLink>
                    Learn more about preventing chargebacks
                </CardLink>
            </CardBlock>
        </Card>

    const Won = () =>
        <Card noPadding overflow="hidden">
            <CardTitle withPadding>
                <Icon mr={8} width={18} variant="checkmark" color={palette.success2} />
                You won the dispute
            </CardTitle>

            <CardBlock bg={palette.gray1}>
                <Row>
                    <Col xs={6} pr={24}>
                        <CardText>
                            We have completed our investigation and found in your favor. The disputed amount will be fully refunded to your buyer's payment method. Thank you for your patience throughout this process.
                        </CardText>
                        <Spacer size={8} />
                        <CardLink>
                            Learn more about preventing chargebacks
                        </CardLink>
                    </Col>
                    <Col xs={6} pl={24}>
                        <CardSubtilte>
                            Relevant documents
                        </CardSubtilte>
                        <CardLink>
                            View your submitted evidence
                        </CardLink>
                    </Col>
                </Row>
            </CardBlock>
        </Card>

    const NeedResponse = () =>
        <Card noPadding>
            <CardTitle withPadding>
                <Icon mr={8} width={18} variant="danger-filled" />
                This payment was disputed
            </CardTitle>

            <CardBlock bg={palette.gray1}>

                <CardSubtilte>
                    The cardholder claims they did not authorize the purchase.
                </CardSubtilte>
                <CardText>
                    You may either counter the dispute by providing evidence to prove that the cardholder is the legitimate purchaser, or you can accept this dispute immediately to refund the cardholder and close the dispute.
                </CardText>
                <Spacer size={8} />
                <CardLink>
                    Learn how to respond to inquiries
                </CardLink>

                <Spacer size={32} />

                <Col justify="start">
                    <Col max_w='55%' pr={56}>
                        <CardSubtilte>
                            What steps can I take to address this inquiry?
                        </CardSubtilte>
                        <DisputeGuideBlock first>
                            <CardText>
                                Email your customer to understand why they filed this inquiry
                            </CardText>
                        </DisputeGuideBlock>
                        <DisputeGuideBlock>
                            <CardText nowrap >
                                If your customer agrees to withdraw this dispute,<CardLink ml={4}>follow this guide</CardLink>
                            </CardText>
                        </DisputeGuideBlock>
                        <DisputeGuideBlock>
                            <CardText>
                                Counter or accept the dispute by {`${Months[dueOn.getMonth()].slice(0, 3)} ${dueOn.getDate()}, ${dueOn.getFullYear()}`}
                            </CardText>
                        </DisputeGuideBlock>
                    </Col>

                    <Col>
                        <CardSubtilte>
                            Dispute details
                        </CardSubtilte>
                        <Col wrap="nowrap">
                            <Col xs="content">
                                <DisputeInfoBlock value="Amount" />
                                <DisputeInfoBlock value="Reason" />
                                <DisputeInfoBlock value="Network reason code" />
                                <DisputeInfoBlock value="Dispute ID" />
                            </Col>
                            <Spacer size={56} />
                            <Col>
                                <DisputeInfoBlock value={`$${amount.toFixed(2)} USD`} />
                                <DisputeInfoBlock value={DisputeReason(reason)} small />
                                <DisputeInfoBlock value={networkReasonCode} />
                                <DisputeInfoBlock value={<Copyable children={`${id.slice(0, 15)}...`} value={id} />} small />
                            </Col>
                        </Col>
                    </Col>
                </Col>
            </CardBlock>

            <CardDivider noPadding />

            <Col justify="end" py={12} px={Spacers.cardHorizzontal as SpacerValue}>
                <Button w100={false} variant="alternate">
                    Accept dispute
                </Button>
                <Spacer size={8} />
                <a href={DemoRoutes.ledger.disputeDetails + id}>
                    <Button w100={false} variant="primary">
                        Counter dispute
                    </Button>
                </a>
            </Col>
        </Card>

    return state === DISPUTE_STATE.UNDER_REVIEW ? EvidenceSubmitted() :
        state === DISPUTE_STATE.LOST ? Lost() :
            state === DISPUTE_STATE.WON ? Won() :
                NeedResponse()
}

export const DisputeGuideBlock = (props: { children: ReactNode, first?: boolean }) => {

    const { children, first } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () => <>
        {
            !first && <>
                <Spacer size={2} />
                <Spacer size={8} />
                <CardDivider noPadding />
                <Spacer size={2} />
                <Spacer size={8} />
            </>
        }
        <Col wrap="nowrap" align="start">
            <Icon mt={6 as SpacerValue} variant='dot' width={6} color={palette.gray5} />
            <Spacer size={8} />
            {children}
        </Col>
    </>

    return render()
}


export const DisputeInfoBlock = (props: { value: ReactNode, small?: boolean }) => {

    const { value, small } = props

    const render = () =>
        <Col xs={12} pb={8}>
            {
                small ?
                    <S nowrap>{value}</S> :
                    <P nowrap>{value}</P>
            }
        </Col>

    return render()
}
