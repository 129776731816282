import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PAYOUT_STATE, PAYOUTS_LEDGER_COLS, PayoutsLedgerEngine, PayoutsLedgerFilter, PayoutsLedgerOrder, PayoutsLedgerRowProps } from "../utilities";

export interface PayoutsLedgerData {
    payouts: PayoutsLedgerRowProps[]
    period: {
        start: number
        end: number
    }
    order: PayoutsLedgerOrder
    filters: PayoutsLedgerFilter[]
    pagination: {
        results: number
        current: number
    }
}

const initialState: PayoutsLedgerData = {
    payouts: [],
    period: {
        start: new Date(2023, 0, 1).getTime(),
        end: new Date().getTime()
    },
    order: {
        by: PAYOUTS_LEDGER_COLS.DATE,
        ascendent: true
    },
    filters: [
        {
            state: false,
            type: PAYOUT_STATE.LOCKED
        },
        {
            state: true,
            type: PAYOUT_STATE.PAID
        },
        {
            state: true,
            type: PAYOUT_STATE.PENDING
        }
    ],
    pagination: {
        results: 15,
        current: 0
    }
}

export const payoutsLedgerSlice = createSlice({
    name: 'payoutsLedger',
    initialState,
    reducers: {

        setPeriod: (state: PayoutsLedgerData, action: PayloadAction<{ start: number, end: number }>) => {
            const { start, end } = action.payload
            return {
                ...state,
                period: { start: start, end: end }
            }
        },

        loadData: (state: PayoutsLedgerData) => {
            var payins = PayoutsLedgerEngine.getRows(state.period.start, state.period.end)
            return {
                ...state,
                payouts: payins,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                filters: state.filters,
                order: initialState.order
            }
        },

        orderData: (state: PayoutsLedgerData, action: PayloadAction<PAYOUTS_LEDGER_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                order,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
            }
        },

        setCurrentPage: (state: PayoutsLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: PayoutsLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        toggleAppliedFilter: (state: PayoutsLedgerData, action: PayloadAction<PAYOUT_STATE>) => {
            return {
                ...state,
                filters: state.filters.map(x => x.type !== action.payload ? x : { ...x, state: !x.state }),
                pagination: {
                    ...state.pagination,
                    current: 0
                }
            }
        }
    }
})

export const payoutsLedgerActions = payoutsLedgerSlice.actions

export const payoutsLedgerReducer = payoutsLedgerSlice.reducer

