
export interface ApiLog {
    id: string
    date: number
    endpoint: string
    time: string
    status: HTTP_STATUS_CODE
    origin: string
    apiVersion: string
    method: HTTP_METHOD
    idempotency: string
}

export interface ApiLogDetails {
    query: string
    request: string[]
    response: string[]
}

export enum HTTP_STATUS_CODE {
    OK = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    SERVER_ERROR = 500
}

export enum HTTP_METHOD {
    GET,
    POST
}

export const HttpStatusCodes = ["200 OK", "400 ERR", "401 ERR", "404 ERR", "500 ERR"]
export const HttpMethods = ["GET", "POST"]