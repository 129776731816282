import { CountryCode } from "."
import { PSP } from "./psp"
import { REGION } from "./regions"


export enum PAYMENT_METHOD_STATE {
    ENABLED,
    DISABLED,
    SUSPENDED
}

export enum PAYMENT_METHOD {
    CARD = 0,
    BANCONTACT = 1,
    ALIPAY = 2,
    WECHATPAY = 3,
    EPS = 4,
    SOFORT = 5,
    P24 = 6,
    PAYPAL = 7,
    OXXO = 8,
    GOOGLE_PAY = 9,
    APPLE_PAY = 10,
    GIROPAY = 11,
    IDEAL = 12
}

export interface PaymentMethod {
    value: PAYMENT_METHOD
    active: boolean
    regions: REGION[]
    name: string
    display_name: string
    description: string
    short_description: string
    provider: PSP
    countries: CountryCode[]
    info?: {
        confirmation: string
        recurring: 'Yes' | 'No' | 'Supported via SEPA Direct Debit' | 'Requires approval'
        refund: 'Yes' | 'No'
        dispute: 'Yes' | 'No'
        currencies?: string
    }
}

export const PaymentMethods: PaymentMethod[]
    = [
        {
            value: PAYMENT_METHOD.CARD,
            active: false,
            regions: [REGION.AMERICA, REGION.EUROPE, REGION.OCEANIA],
            name: 'card',
            display_name: "Card",
            description: "Accept Visa, Mastercard, American Express, and China UnionPay payments from customers worldwide.",
            short_description: "Used globally",
            provider: PSP.STRIPE,
            countries: [
                "au", "at", "be", "bg", "ca", "cy", "cz",
                "dk", "ee", "fi", "fr", "de", "gr", "hk",
                "hu", "ie", "it", "jp", "lv", "lt", "lu",
                "mt", "mx", "nl", "nz", "no", "pl", "pt",
                "ro", "sg", "sk", "si", "es", "se", "ch",
                "gb", "us"
            ] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'Yes',
                refund: 'Yes',
                dispute: 'Yes'
            }
        },
        {
            value: PAYMENT_METHOD.BANCONTACT,
            active: true,
            regions: [REGION.EUROPE],
            name: 'bancontact',
            display_name: "Bancontact",
            description: "Bancontact is the most popular online payment method in Belgium, with over 15 million cards in circulation. Customers use a Bancontact card or mobile app linked to a Belgian bank account to make online payments that are secure, guaranteed, and confirmed immediately.",
            short_description: "Used in Belgium",
            provider: PSP.STRIPE,
            countries: ['au', 'at', 'be', 'bg', 'ca', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gi', 'gr', 'hk', 'hu', 'ie', 'it', 'jp', 'lv', 'li', 'lt', 'lu', 'mt', 'mx', 'nl', 'nz', 'no', 'pl', 'pt', 'ro', 'sg', 'sk', 'si', 'es', 'se', 'ch', 'gb', 'us'] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'Supported via SEPA Direct Debit',
                refund: 'Yes',
                dispute: 'No',
                currencies: 'AU, CA, Europe, HK, JP, NZ, SG, US',
            }
        },
        {
            value: PAYMENT_METHOD.ALIPAY,
            active: false,
            regions: [REGION.ASIA],
            name: 'alipay',
            display_name: "Alipay",
            description: "Alipay enables Chinese consumers to pay directly via online transfer from their bank account. Customers are redirected to Alipay's payment page to log in and approve payments.",
            short_description: "Used in China",
            provider: PSP.STRIPE,
            countries: ['au', 'at', 'be', 'bg', 'ca', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gi', 'gr', 'hk', 'hu', 'ie', 'it', 'jp', 'lv', 'li', 'lt', 'lu', 'my', 'mt', 'nl', 'nz', 'no', 'pt', 'ro', 'sg', 'sk', 'si', 'es', 'se', 'ch', 'gb', 'us'] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'Requires approval',
                refund: 'Yes',
                dispute: 'No',
                currencies: 'CNY, AUD, CAD, EUR, GBP, HKD, JPY, SGD, MYR, NZD, and USD (depending on business locations)'
            }
        },
        {
            value: PAYMENT_METHOD.WECHATPAY,
            active: false,
            regions: [REGION.ASIA],
            name: 'wechat_pay',
            display_name: "WeChat Pay",
            description: "WeChat Pay enables Chinese consumers to pay directly via online transfer from their account. Customers are given a QR Code to scan using their WeChat mobile application to approve payments.",
            short_description: "Used in China",
            provider: PSP.STRIPE,
            countries: ['au', 'at', 'be', 'ca', 'dk', 'fi', 'fr', 'de', 'hk', 'ie', 'it', 'jp', 'lu', 'nl', 'no', 'pt', 'sg', 'es', 'se', 'ch', 'gb', 'us'] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'Requires approval',
                refund: 'Yes',
                dispute: 'No',
                currencies: 'CNY, AUD, CAD, EUR, GBP, HKD, JPY, SGD, USD, DKK, NOK, SEK, CHF (depending on business location)'
            }
        },
        {
            value: PAYMENT_METHOD.EPS,
            active: true,
            regions: [REGION.EUROPE],
            name: 'eps',
            display_name: "Eps",
            description: "EPS enables customers in Austria to pay directly via online payouts from their bank account. Customers are redirected to their bank's payment page to log in and approve payments.",
            short_description: "Used in Austria",
            provider: PSP.STRIPE,
            countries: ['au', 'at', 'be', 'bg', 'ca', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gi', 'gr', 'hk', 'hu', 'ie', 'it', 'jp', 'lv', 'li', 'lt', 'lu', 'mt', 'mx', 'nl', 'nz', 'no', 'pl', 'pt', 'ro', 'sg', 'sk', 'si', 'es', 'se', 'ch', 'gb', 'us'] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'No',
                refund: 'Yes',
                dispute: 'No',
                currencies: 'EUR'
            }
        },
        {
            value: PAYMENT_METHOD.SOFORT,
            active: false,
            regions: [REGION.EUROPE],
            name: 'sofort',
            display_name: "Sofort",
            description: "Sofort enables customers in Austria, Belgium, Germany, Italy, Netherlands and Spain to pay via direct online payouts from their bank accounts. Customers log into Sofort's payment page to approve payments.",
            short_description: "Used in Germany, Austria and Switzerland",
            provider: PSP.STRIPE,
            countries: ['au', 'at', 'be', 'bg', 'ca', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gi', 'gr', 'hk', 'hu', 'ie', 'it', 'jp', 'lv', 'li', 'lt', 'lu', 'mt', 'mx', 'nl', 'nz', 'no', 'pl', 'pt', 'ro', 'sg', 'sk', 'si', 'es', 'se', 'ch', 'gb', 'us'] as CountryCode[],
            info: {
                confirmation: 'Immediate directional confirmation; definitive confirmation delayed by typically 2 but up to 14 business days',
                recurring: 'Supported via SEPA Direct Debit',
                refund: 'Yes',
                dispute: 'No',
                currencies: 'EUR'
            }
        },
        {
            value: PAYMENT_METHOD.P24,
            active: false,
            regions: [REGION.EUROPE],
            name: 'p24',
            display_name: "P24",
            description: "Przelewy24 (P24) enables customers in Poland to pay directly via online payouts from their bank account. Customers are redirected to their bank's payment page to log in and approve payments.",
            short_description: "Used in Poland",
            provider: PSP.STRIPE,
            countries: ['au', 'at', 'be', 'bg', 'ca', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gi', 'gr', 'hk', 'hu', 'ie', 'it', 'jp', 'lv', 'li', 'lt', 'lu', 'mt', 'mx', 'nl', 'nz', 'no', 'pl', 'pt', 'ro', 'sg', 'sk', 'si', 'es', 'se', 'ch', 'gb', 'us'] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'No',
                refund: 'Yes',
                dispute: 'No',
                currencies: 'EUR, PLN'
            }
        },
        {
            value: PAYMENT_METHOD.PAYPAL,
            active: false,
            regions: [REGION.EUROPE, REGION.OCEANIA, REGION.AMERICA],
            name: 'paypal',
            display_name: "Paypal Credit",
            description: "PayPal Holdings, Inc. is an American multinational financial technology company operating an online payments system in the majority of countries that support online money transfers, and serves as an electronic alternative to traditional paper methods such as checks and money orders.",
            short_description: "Used globally",
            provider: PSP.PAYPAL,
            countries: [
                "au", "at", "be", "bg", "ca", "cy", "cz",
                "dk", "ee", "fi", "fr", "de", "gr", "hk",
                "hu", "ie", "it", "jp", "lv", "lt", "lu",
                "mt", "mx", "nl", "nz", "no", "pl", "pt",
                "ro", "sg", "sk", "si", "es", "se", "ch",
                "gb", "us"
            ] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'Yes',
                refund: 'Yes',
                dispute: 'Yes'
            }
        },
        {
            value: PAYMENT_METHOD.OXXO,
            active: false,
            regions: [REGION.AMERICA],
            name: 'oxxo',
            display_name: "Oxxo",
            description: "Oxxo is a Mexican chain of convenience stores with thousands of locations across Latin America and represents nearly 20% of online transactions in Mexico. OXXO allows customers to pay bills and online purchases in-store with cash.",
            short_description: "Used in Latin America",
            provider: PSP.STRIPE,
            countries: ["mx"],
            info: {
                confirmation: 'Immediate',
                recurring: 'Yes',
                refund: 'Yes',
                dispute: 'Yes'
            }
        },
        {
            value: PAYMENT_METHOD.GOOGLE_PAY,
            active: true,
            regions: [REGION.AMERICA, REGION.EUROPE, REGION.OCEANIA, REGION.ASIA],
            name: 'googlepay',
            display_name: "Google Pay",
            description: "Google Pay allows customers to make payments in your app or website using any credit or debit card saved to their Google Account, including those from Google Play, YouTube, Chrome, or an Android device.",
            short_description: "Used globally",
            provider: PSP.STRIPE,
            countries: [
                "au", "at", "be", "bg", "ca", "cy", "cz",
                "dk", "ee", "fi", "fr", "de", "gr", "hk",
                "hu", "ie", "it", "jp", "lv", "lt", "lu",
                "mt", "mx", "nl", "nz", "no", "pl", "pt",
                "ro", "sg", "sk", "si", "es", "se", "ch",
                "gb", "us"
            ] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'Yes',
                refund: 'Yes',
                dispute: 'Yes'
            }
        },
        {
            value: PAYMENT_METHOD.APPLE_PAY,
            active: true,
            regions: [REGION.AMERICA, REGION.EUROPE, REGION.OCEANIA, REGION.ASIA],
            name: 'applepay',
            display_name: "Apple Pay",
            description: "Apple Pay enables frictionless card payments and eliminates the need to manually type card or shipping details.",
            short_description: "Used globally",
            provider: PSP.STRIPE,
            countries: [
                "au", "at", "be", "bg", "ca", "cy", "cz",
                "dk", "ee", "fi", "fr", "de", "gr", "hk",
                "hu", "ie", "it", "jp", "lv", "lt", "lu",
                "mt", "mx", "nl", "nz", "no", "pl", "pt",
                "ro", "sg", "sk", "si", "es", "se", "ch",
                "gb", "us"
            ] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'Yes',
                refund: 'Yes',
                dispute: 'Yes'
            }
        },
        {
            value: PAYMENT_METHOD.GIROPAY,
            active: true,
            regions: [REGION.AMERICA, REGION.EUROPE, REGION.OCEANIA, REGION.ASIA],
            name: 'giropay',
            display_name: "Giropay",
            description: "Giropay enables customers in Germany to pay directly via online payouts from their bank account. Customers are redirected to their bank's payment page to log in and approve payments.",
            short_description: "Used in Germany",
            provider: PSP.STRIPE,
            countries: ['au', 'at', 'be', 'bg', 'ca', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gi', 'gr', 'hk', 'hu', 'ie', 'it', 'jp', 'lv', 'li', 'lt', 'lu', 'mt', 'mx', 'nl', 'nz', 'no', 'pl', 'pt', 'ro', 'sg', 'sk', 'si', 'es', 'se', 'ch', 'gb', 'us'] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'No',
                refund: 'Yes',
                dispute: 'No',
                currencies: "EUR"
            }
        },
        {
            value: PAYMENT_METHOD.IDEAL,
            active: true,
            regions: [REGION.AMERICA, REGION.EUROPE, REGION.OCEANIA, REGION.ASIA],
            name: 'ideal',
            display_name: "iDEAL",
            description: "iDEAL enables customers in the Netherlands to pay directly via online payouts from their bank account. Customers are redirected to their bank's payment page to log in and approve payments.",
            short_description: "Used in Netherlands",
            provider: PSP.STRIPE,
            countries: ['au', 'at', 'be', 'bg', 'ca', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gi', 'gr', 'hk', 'hu', 'ie', 'it', 'jp', 'lv', 'li', 'lt', 'lu', 'mt', 'mx', 'nl', 'nz', 'no', 'pl', 'pt', 'ro', 'sg', 'sk', 'si', 'es', 'se', 'ch', 'gb', 'us'] as CountryCode[],
            info: {
                confirmation: 'Immediate',
                recurring: 'Supported via SEPA Direct Debit',
                refund: 'Yes',
                dispute: 'No',
                currencies: "EUR"
            }
        }
    ]


