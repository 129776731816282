import axios from "axios"
import { ValidationErrors, ValidationResult, handleEmptyBadResponse, handleEmptyResponse } from "."
import { ApiRoutes, Url } from "../routes"

export type DisputeUpdateRequestErrors = {
    generic: string
    why: string
    description: string
    type: string
    document: string
    buyer_email: string
    buyer_name: string
    buyer_address: string
}

export type DisputeUpdateRequest = {
    why: string
    description: string
    type: string
    document: string
    buyer_email: string
    buyer_name: string
    buyer_address: string
}

export class DisputeUpdateRequestValidator {
    constructor() { }

    static validate(request: DisputeUpdateRequest): ValidationResult {
        // const emptyPassword = request.password.length === 0

        const errors: ValidationErrors = {}

        // emptyRePassword && (errors["password_confirmation"] = ErrorMessages.empty_password_confirmation)

        const result: ValidationResult = {
            success: Object.keys(errors).length < 1,
            errors
        }
        return result
    }
}

const updateDisputeAPI = async (req: DisputeUpdateRequest) =>
    await axios.post(
        `${ApiRoutes.UPDATE_DISPUTE}/${Url.queryString('token')}`,
        req
    )
        .then(
            handleEmptyResponse
        )
        .catch(
            handleEmptyBadResponse
        )

