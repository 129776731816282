import { BadgePropsForPayin, Card, CardDataCollection, CardDivider, CardHeaderData, CardTitle } from "../.."
import { PayinProps } from "../../../utilities"

export const PaymentDetails = (payin: PayinProps) => {

    const { currency, amount, state, provider, method, seller, buyer, date } = payin

    const render = () =>
        <Card>
            <CardTitle>Payment</CardTitle>

            <CardHeaderData
                id={payin.id}
                title={currency + amount}
                badge={BadgePropsForPayin(payin)} />

            <CardDivider />

            <CardDataCollection data={[
                { data: ["Gateway", `${provider.display_name}/${method.display_name}`] },
                { data: ["Seller", seller.email] },
                { data: ["Buyer", buyer.email] },
                { data: ["Last Update", new Date(date).toDateString()] }
            ]} />
        </Card>

    return render()
}
