import { useSelector } from "react-redux";
import styled from "styled-components";
import { Card, CardTitle, Col, LineChart, P, Row, Spacer, XS } from "../..";
import { REDUX_STATE } from "../../../redux";
import { Months, PAYIN_STATE } from "../../../utilities";

export const VolumeSellersChart = () => {

    const { theme: { palette }, analytics: { payins, sellers, period: { start, end } } } = useSelector(REDUX_STATE)

    const periods = generateChartDates(new Date(start), new Date(end))

    var data: {
        sellers: number[]
        volumes: number[]
        labels: string[]
    } = {
        sellers: [],
        volumes: [],
        labels: []
    }


    let s = sellers.current, p = payins.current.filter(x => x.state === PAYIN_STATE.SUCCEEDED)

    let m: number | null = null

    periods.forEach(date => {
        let sCount = 0
        s = s.filter(sel => {
            if (sel.registration <= date.getTime()) {
                sCount++
                return false
            }
            return true
        })
        data.sellers.push(sCount)

        let vAmount = 0
        p = p.filter(pay => {
            if (pay.date <= date.getTime()) {
                vAmount += pay.amount
                return false
            }
            return true
        })
        data.volumes.push(vAmount)

        let month = ""
        if (m !== date.getMonth()) {
            m = date.getMonth();
            month = Months[date.getMonth()].slice(0, 3)
        }

        data.labels.push(`${date.getDate()} ${month}`)
    })


    const render = () =>
        <Row>
            <Col>
                <Card>
                    <CardTitle>Volume & Sellers</CardTitle>
                    <Col xs={12} position="absolute" top="0px" justify="end" pt={2}>
                        <P nowrap>
                            <Dot color={palette.gray5} />
                            <Spacer w size={8} />
                            Volume
                        </P>
                        <Spacer w size={16} />
                        <P nowrap>
                            <Dot color={palette.primary2} />
                            <Spacer w size={8} />
                            Sellers
                        </P>
                    </Col>
                    <Spacer h size={12} />
                    <Col xs={12} h="220px">
                        <LineChart {...data} />
                    </Col>
                    <Spacer h size={16} />
                    <Col justify="center">
                        <XS variant="light">
                            This graph will always show a 14 days or higher time span
                        </XS>
                    </Col>
                </Card>
            </Col>
        </Row>

    return render()
}

const Dot = styled.div<{ color?: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${p => p.color};
`


function generateChartDates(start: Date, end: Date): Date[] {
    const DAY_IN_MS: number = 24 * 60 * 60 * 1000

    const diffInDays: number = Math.round((end.getTime() - start.getTime()) / DAY_IN_MS)
    const diffInMonths: number = end.getMonth() - start.getMonth() + (12 * (end.getFullYear() - start.getFullYear()))

    const labels: Date[] = []
    const Y = { start: start.getFullYear(), end: end.getFullYear() }
    const M = { start: start.getMonth(), end: end.getMonth() }
    const D = { start: start.getDate(), end: end.getDate() }

    if (diffInMonths >= 12) {
        let curr: Date = new Date(Y.start, (M.start - 1), 1);
        labels.push(new Date(curr));

        while (curr.getTime() < end.getTime()) {
            curr.setMonth(curr.getMonth() + 1);
            curr.getTime() > end.getTime() && (curr = end)
            labels.push(new Date(curr));
        }

        return labels;
    }
    else if (diffInMonths >= 4) {
        let curr: Date = new Date(Y.start, M.start, D.start < 15 ? 1 : 15);
        labels.push(new Date(curr));

        while (curr.getTime() < end.getTime()) {

            curr.getDate() >= 15 && curr.setMonth(curr.getMonth() + 1);
            curr.setDate(curr.getDate() >= 15 ? 1 : 15);

            curr.getTime() > end.getTime() && (curr = end)
            labels.push(new Date(curr));
        }

        return labels;
    }
    else if (diffInMonths >= 2) {
        let curr: Date = start
        while (curr.getDay() !== 1) {
            curr.setDate(curr.getDate() + 1)
        }

        labels.push(new Date(curr));

        while (curr.getTime() < end.getTime()) {
            curr.setDate(curr.getDate() + 7)
            curr.getTime() > end.getTime() && (curr = end)
            labels.push(new Date(curr));
        }

        return labels;
    }
    else if (diffInDays >= 28) {
        let curr: Date = start
        labels.push(new Date(curr));

        while (curr.getTime() < end.getTime()) {
            curr.setDate(curr.getDate() + 3)
            curr.getTime() > end.getTime() && (curr = end)
            labels.push(new Date(curr));
        }

        return labels;
    }
    else if (diffInDays >= 14) {
        let curr: Date = start
        labels.push(new Date(curr));

        while (curr.getTime() < end.getTime()) {
            curr.setDate(curr.getDate() + 2)
            curr.getTime() > end.getTime() && (curr = end)
            labels.push(new Date(curr));
        }

        return labels;
    }
    else {
        let curr: Date = start

        for (let i = 0; i < 13; i++) {
            labels.push(new Date(curr));
            curr.setDate(curr.getDate() + 1)
        }

        return labels;
    }
}

