import styled from "styled-components"
import { Col, Icon, P, S, Spacer } from ".."
import { PaletteProps } from "../../design_system"
import { useSelector } from "react-redux"
import { REDUX_STATE } from "../../redux"


export const UploadFileInput = () => {

    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <UploadFileInputStyled palette={palette}>
            <Col py={24} xs="content" direction="column" align="center">
                <Icon mb={4} variant="image" width={26} />
                <P mb={4} nowrap><P mr={4} nowrap variant="link">Click to upload</P>or Drag n Drop Image</P>
                <S nowrap>Files Supported: JPEG,PNG, Gif (max 5mb)</S>
            </Col>
        </UploadFileInputStyled>

    return render()
}

const UploadFileInputStyled = styled.div<{ palette: PaletteProps }>`
    display: flex;
    border-radius: 8px;
    justify-content: center;
    border: 1px dashed ${p => p.palette.gray2};
`