

export class UUID {
    public static create(): string {
        const randomValues = crypto.getRandomValues(new Uint8Array(16));
        randomValues[6] = (randomValues[6] & 0x0f) | 0x40; // Version 4
        randomValues[8] = (randomValues[8] & 0x3f) | 0x80; // Variant 10

        let uuid = "";
        for (let i = 0; i < 16; i++) {
            let byte = randomValues[i].toString(16);
            if (byte.length === 1) {
                byte = "0" + byte;
            }
            uuid += byte;
        }

        return uuid;
    }
}