import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { Col } from ".."
import { PaletteProps } from "../../design_system"
import { REDUX_STATE } from "../../redux"


export interface ToggleProps {
    disabled?: boolean,
    checked?: boolean
    onToggle?: (val: boolean) => void
}


export const Toggle = (props: ToggleProps) => {

    const { disabled, onToggle } = props
    const { palette, isDark } = useSelector(REDUX_STATE).theme

    const [checked, setChecked] = useState(props.checked)

    useEffect(() => { setChecked(props.checked) }, [props.checked])

    const render = () =>
        <Col xs="content">
            <ToggleStyled palette={palette} isDark={isDark} disabled={disabled} onClick={() => { onToggle && onToggle(!checked) }}>
                <input type="checkbox" checked={checked} disabled={disabled} onChange={()=>{}} />
                <Slider />
            </ToggleStyled>
        </Col>

    return render()
}


const design = {
    width: 42,
    height: 19,
    cursorSize: 19
}

const Slider = styled.span``
const ToggleStyled = styled.label<{ palette: PaletteProps, isDark?: boolean, disabled?: boolean }>`
${({ palette, disabled }) => css`
    
    /* The switch - the box around the slider */
    position: relative;
    display: inline-block;
    width: ${design.width}px;
    height: ${design.height}px;

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    ${Slider} {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: .4s;
        cursor: pointer;
        position: absolute;
        border-radius: 34px;
        -webkit-transition: .4s;
        background-color: ${disabled ? palette.gray3 : palette.gray2};
    }

    ${Slider}:before {
        position: absolute;
        border-radius: 50%;
        content: "";
        width: ${design.cursorSize}px;
        height: ${design.cursorSize}px;
        left: ${(design.height - design.cursorSize) / 2 + 1}px;
        bottom: ${(design.height - design.cursorSize) / 2}px;
        background-color: ${palette.gray2};
        -webkit-transition: .4s;
        transition: .4s;
        background-color: ${palette.white};
        background-color: ${disabled ? palette.gray2 : palette.white};
    }

    input:checked + ${Slider} {
        background-color: ${palette.gray3};
    }


    input:checked + ${Slider}:before {
        -webkit-transform: ${`translateX(${design.width - design.cursorSize - (design.height - design.cursorSize) - 1}px)`};
        -ms-transform: ${`translateX(${design.width - design.cursorSize - (design.height - design.cursorSize) - 1}px)`};
        transform: ${`translateX(${design.width - design.cursorSize - (design.height - design.cursorSize) - 1}px)`};
        background-color: ${palette.light};
    }

`}
`