import { ApiKeysData } from "../../redux"


export enum API_KEY_TYPE {
    TEST,
    LIVE
}

export enum API_KEYS_TABLE_COLS {
    NAME,
    TYPE,
    PUBLIC_KEY,
    LAST_USED,
    CREATED
}

export interface ApiKeysOrder {
    by: API_KEYS_TABLE_COLS,
    ascendent: boolean
}

export interface ApiKeysRowProps {
    id: string
    name: string
    type: API_KEY_TYPE
    publicKey: string
    lastUse?: number
    created: number
}

export class ApiKeysEngine {

    static getValues(state: ApiKeysData): ApiKeysRowProps[] {
        const reoredered = this.getOrderedValues(state.apiKeys, state.order)
        return reoredered
    }

    static getOrderedValues(rows: ApiKeysRowProps[], order: ApiKeysOrder): ApiKeysRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {

            case API_KEYS_TABLE_COLS.NAME:
                result = result.sort((a, b) => a.name < b.name ? factor * -1 : factor * 1)
                break

            case API_KEYS_TABLE_COLS.TYPE:
                result = result.sort((a, b) => a.type < b.type ? factor * -1 : factor * 1)
                break

            case API_KEYS_TABLE_COLS.PUBLIC_KEY:
                result = result.sort((a, b) => a.publicKey < b.publicKey ? factor * -1 : factor * 1)
                break

            case API_KEYS_TABLE_COLS.LAST_USED:
                result = result.sort((a, b) => (a.lastUse ?? 0) < (b.lastUse ?? 0) ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => a.created < b.created ? factor * -1 : factor * 1)
                break
        }
        return result
    }

    static getRows(): ApiKeysRowProps[] {

        var result: ApiKeysRowProps[] = [
            {
                id: "1919b53ca05d4e1eb2980d548b766fdc",
                name: "Analytics key",
                type: API_KEY_TYPE.TEST,
                publicKey: "b77521f7120f492b9ff0d65bceddff50",
                lastUse: new Date().setDate(-4),
                created: new Date(2023, 6, 20).getTime()
            },
            {
                id: "6edd1c7be7db4bcdb235e659947fa1c8",
                name: "Payment key",
                type: API_KEY_TYPE.TEST,
                publicKey: "f87ac20dc8f84655ab34a6ce5d3ef145",
                lastUse: new Date().setDate(-1),
                created: new Date(2023, 5, 20).getTime()
            }
        ]

        return result
    }
}
