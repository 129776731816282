import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Card, CardHorizzontalSpacer, CardTitle, CheckoutVisitsTableHead, CheckoutVisitsTableRow, CheckoutVisitsTableRowProps, Col, Row, Spacer } from "../..";
import { REDUX_STATE } from "../../../redux";
import { Languages, LanguagesProps, UUID } from "../../../utilities";

export enum CheckoutVisitsView {
        Location,
        Language,
        Device,
        State
}

export const CheckoutVisitsTable = () => {

        const { payins } = useSelector(REDUX_STATE).analytics
        const [view, setView] = useState(CheckoutVisitsView.Location)

        let n = 0, id = UUID.create()
        const render = () =>
                <Row h="100%">
                        <Col h="100%">
                                <Card h="100%" noHorizzontalPadding overflow="hidden">
                                        <div ref={ref}>
                                                <Col wrap="nowrap">
                                                        <CardHorizzontalSpacer />
                                                        <CardTitle>Checkout Visits</CardTitle>
                                                        <CardHorizzontalSpacer />
                                                </Col>
                                                <Spacer size={8} />
                                                <CheckoutVisitsTableHead state={[view, setView]} />
                                                <Spacer size={16} />
                                        </div>
                                        <CheckoutVisitContainer style={{ height: `calc(100% - ${h}px)` }}>
                                                {
                                                        getItems()
                                                                .sort((a, b) => b.percentage - a.percentage)
                                                                .map(x =>
                                                                        <CheckoutVisitsTableRow key={id + n++} {...{ ...x, view }} />
                                                                )
                                                }
                                        </CheckoutVisitContainer>
                                </Card>
                        </Col>
                </Row>


        const getItems = () => {
                let values = payins.current
                        .map(x => {
                                switch (view) {
                                        case CheckoutVisitsView.Location: return x.buyer.country
                                        case CheckoutVisitsView.Language: return Languages[x.buyer.country as keyof LanguagesProps] ?? Languages.us
                                        case CheckoutVisitsView.Device: return x.device
                                        default: return x.state
                                }
                        })
                        .reduce((a: { [data: string]: number }, b) => {
                                a[b] = (a[b] ?? 0) + 1; return a
                        }, {})

                return Object.keys(values).map(d => {
                        return {
                                data: d,
                                view: view,
                                percentage: (values[d] / payins.current.length) * 100
                        }
                })
        }


        const ref = useRef<HTMLDivElement>(null)
        const [h, setH] = useState(ref.current?.scrollHeight)
        useEffect(() => {
                const element = ref?.current;
                if (!element) return;

                const observer = new ResizeObserver(() =>
                        setH(element.scrollHeight)
                );

                observer.observe(element);

                return () => {
                        observer.disconnect()
                }
        }, [])

        return render()
}

const CheckoutVisitContainer = styled.div`
        width: 100%;
        position: absolute;
        overflow-y: auto;

        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
                display: none;  /* Safari and Chrome */
        }    
`
