import { useSelector } from "react-redux";
import { Card, CardHorizzontalSpacer, CardTitle, CardVerticalSpacer, Col, MethodTableHead, MethodTableRow, Row } from "../..";
import { Fees } from "../../../fake_data/Fees";
import { REDUX_STATE } from "../../../redux";
import { PAYMENT_METHOD, UUID } from "../../../utilities";
import { useEffect } from "react";

export const MethodTable = (props: { method: PAYMENT_METHOD }) => {

        let n = 0, id = UUID.create()


        useEffect(() => {
                window.setTimeout(() => {
                        const path = window.location.href.split('#')
                        const anchor = path[1]
                        const targetElement = document.getElementById(anchor);

                        if (targetElement) {
                                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;

                                var offset = 0
                                window.scrollTo({
                                        top: targetPosition - offset,
                                        behavior: "smooth" // Opzionale: rende lo scorrimento fluido
                                });
                        }
                }, 500)
        }, [])

        const render = () =>
                <Row>
                        <Col>
                                <Card h="100%" noPadding>
                                        <CardVerticalSpacer />
                                        <Col wrap="nowrap">
                                                <CardHorizzontalSpacer />
                                                <CardTitle>Pricing</CardTitle>
                                                <CardHorizzontalSpacer />
                                        </Col>
                                        <MethodTableHead />
                                        {
                                                Object.values(Fees[props.method]).map(fee =>
                                                        <MethodTableRow key={id + n++} {...fee} />
                                                )
                                        }
                                </Card>
                        </Col>
                </Row>

        return render()
}