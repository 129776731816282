import { Badge, Card, CardTitle, Col, Container, H2, Row, Spacer } from ".."


export const Widget = (props: { title: string, subtitle: string, value: number }) => {

    const { title, subtitle, value } = props

    const render = () =>
        <Container>
            <Row>
                <Col>
                    <Card>
                        <CardTitle>{title}</CardTitle>
                        <H2 variant="dark" medium>{subtitle}</H2>
                        <Spacer size={16} />
                        <Spacer size={4} />
                        <Badge variant={value > 0 ? "success" : value < -1 ? "error" : "default"}>{`${value.toFixed(2)}%`}</Badge>
                    </Card>
                </Col>
            </Row>
        </Container>

    return render()
}
