import { DISPUTE_REASON, DISPUTE_STATE, DisputeProps } from "../utilities";

export const Disputes: DisputeProps[] = [
    { id: "6a9b86ee1bff45728452d3c0ad312046", state: new Date(2023, 1, 3, 14, 19, 3) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.SUBSCRIPTION_CANCELLED, networkReasonCode: "4846", disputedOn: new Date(2023, 0, 25, 14, 19, 3).getTime(), dueOn: new Date(2023, 1, 3, 14, 19, 3).getTime() },
    { id: "e5f0d217d9404a56906209eb8180eea4", state: new Date(2023, 0, 30 + 4, 15, 25, 41) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 0, 30 + 4, 15, 25, 41).getTime(), reason: DISPUTE_REASON.INCORRECT_ACCOUNT_DETAILS, networkReasonCode: "R13", disputedOn: new Date(2023, 0, 21, 15, 25, 41).getTime(), dueOn: new Date(2023, 0, 30, 15, 25, 41).getTime() },
    { id: "a4449ba420634464a3a9a1eb1df7958c", state: new Date(2023, 0, 23, 21, 43, 26) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.DEBIT_NOT_AUTHORIZED, networkReasonCode: "155", disputedOn: new Date(2023, 0, 14, 21, 43, 26).getTime(), dueOn: new Date(2023, 0, 23, 21, 43, 26).getTime() },
    { id: "389710aaa1f541e2979353048f9e6ed2", state: new Date(2023, 0, 28, 6, 24, 12) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.SUBSCRIPTION_CANCELLED, networkReasonCode: "10.5", disputedOn: new Date(2023, 0, 19, 6, 24, 12).getTime(), dueOn: new Date(2023, 0, 28, 6, 24, 12).getTime() },
    { id: "5222cedf62ff4e9aaf318b4148f081b4", state: new Date(2023, 1, 6, 20, 52, 27) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.PRODUCT_UNACCEPTABLE, networkReasonCode: "4755 ", disputedOn: new Date(2023, 0, 28, 20, 52, 27).getTime(), dueOn: new Date(2023, 1, 6, 20, 52, 27).getTime() },
    { id: "1695750f31a64bceb56ac4ba5dd41ca7", state: new Date(2023, 1, 9 + 4, 7, 43, 40) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 1, 9 + 4, 7, 43, 40).getTime(), reason: DISPUTE_REASON.INSUFFICIENT_FUNDS, networkReasonCode: "12.1", disputedOn: new Date(2023, 0, 31, 7, 43, 40).getTime(), dueOn: new Date(2023, 1, 9, 7, 43, 40).getTime() },
    { id: "7d31e347c9274d748d2300ec1c3429af", state: new Date(2023, 1, 5, 10, 15, 23) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.SUBSCRIPTION_CANCELLED, networkReasonCode: "6343", disputedOn: new Date(2023, 0, 27, 10, 15, 23).getTime(), dueOn: new Date(2023, 1, 5, 10, 15, 23).getTime() },
    { id: "c33e0bd9579b4e37be88db6608db7197", state: new Date(2023, 1, 22 + 4, 12, 1, 9) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 1, 22 + 4, 12, 1, 9).getTime(), reason: DISPUTE_REASON.UNRECOGNIZED, networkReasonCode: "176", disputedOn: new Date(2023, 1, 13, 12, 1, 9).getTime(), dueOn: new Date(2023, 1, 22, 12, 1, 9).getTime() },
    { id: "d2b0fa9a90064278bfc0e1208d039a98", state: new Date(2023, 1, 22, 11, 46, 8) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.INCORRECT_ACCOUNT_DETAILS, networkReasonCode: "34", disputedOn: new Date(2023, 1, 13, 11, 46, 8).getTime(), dueOn: new Date(2023, 1, 22, 11, 46, 8).getTime() },
    { id: "8fdccf85ddd94d28954f751543504ea6", state: new Date(2023, 1, 25 + 4, 4, 55, 7) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 1, 25 + 4, 4, 55, 7).getTime(), reason: DISPUTE_REASON.PRODUCT_UNACCEPTABLE, networkReasonCode: "S04", disputedOn: new Date(2023, 1, 16, 4, 55, 7).getTime(), dueOn: new Date(2023, 1, 25, 4, 55, 7).getTime() },
    { id: "db82abdb091a4e5ea79dfc2f6ddc6287", state: new Date(2023, 1, 20, 6, 0, 53) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.UNRECOGNIZED, networkReasonCode: "C14", disputedOn: new Date(2023, 1, 11, 6, 0, 53).getTime(), dueOn: new Date(2023, 1, 20, 6, 0, 53).getTime() },
    { id: "bc4da6cf25334519a5f359a22c2dee11", state: new Date(2023, 2, 12, 21, 57, 31) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.PRODUCT_UNACCEPTABLE, networkReasonCode: "C04", disputedOn: new Date(2023, 2, 3, 21, 57, 31).getTime(), dueOn: new Date(2023, 2, 12, 21, 57, 31).getTime() },
    { id: "5b31707fb21d464ba317a83197786660", state: new Date(2023, 2, 16, 8, 48, 53) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CREDIT_NOT_PROCESSED, networkReasonCode: "024", disputedOn: new Date(2023, 2, 7, 8, 48, 53).getTime(), dueOn: new Date(2023, 2, 16, 8, 48, 53).getTime() },
    { id: "e3d309723b40420983a5514ffc0bc82f", state: new Date(2023, 2, 23, 20, 6, 33) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CHECK_RETURNED, networkReasonCode: "M01", disputedOn: new Date(2023, 2, 14, 20, 6, 33).getTime(), dueOn: new Date(2023, 2, 23, 20, 6, 33).getTime() },
    { id: "ee6264412ce448dcabdcec59c1667c0f", state: new Date(2023, 2, 25, 16, 31, 12) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.BANK_CANNOT_PROCESS, networkReasonCode: "12.6.2", disputedOn: new Date(2023, 2, 16, 16, 31, 12).getTime(), dueOn: new Date(2023, 2, 25, 16, 31, 12).getTime() },
    { id: "8d45d1cf68884637b989a2478d9844ff", state: new Date(2023, 3, 1 + 4, 20, 45, 41) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 3, 1 + 4, 20, 45, 41).getTime(), reason: DISPUTE_REASON.UNRECOGNIZED, networkReasonCode: "158", disputedOn: new Date(2023, 2, 23, 20, 45, 41).getTime(), dueOn: new Date(2023, 3, 1, 20, 45, 41).getTime() },
    { id: "c66574c5cdcf45b296ba6409315e33fb", state: new Date(2023, 2, 24, 13, 49, 54) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.PRODUCT_NOT_RECEIVED, networkReasonCode: "33", disputedOn: new Date(2023, 2, 15, 13, 49, 54).getTime(), dueOn: new Date(2023, 2, 24, 13, 49, 54).getTime() },
    { id: "a2fdf6b7dd2c446383624d2d3c1eb7b7", state: new Date(2023, 2, 25 + 4, 7, 4, 16) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 2, 25 + 4, 7, 4, 16).getTime(), reason: DISPUTE_REASON.CUSTOMER_INITIATED, networkReasonCode: "S01", disputedOn: new Date(2023, 2, 16, 7, 4, 16).getTime(), dueOn: new Date(2023, 2, 25, 7, 4, 16).getTime() },
    { id: "f488a8c39d6e469089e38808d1195619", state: new Date(2023, 3, 8, 12, 3, 26) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.UNRECOGNIZED, networkReasonCode: "127", disputedOn: new Date(2023, 2, 30, 12, 3, 26).getTime(), dueOn: new Date(2023, 3, 8, 12, 3, 26).getTime() },
    { id: "b8e7fddba6264b5c8f12f6c585c75aae", state: new Date(2023, 3, 20, 20, 56, 38) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.DEBIT_NOT_AUTHORIZED, networkReasonCode: "A02", disputedOn: new Date(2023, 3, 11, 20, 56, 38).getTime(), dueOn: new Date(2023, 3, 20, 20, 56, 38).getTime() },
    { id: "ee8ee495cbfd46b99572c71f9b1e06f4", state: new Date(2023, 3, 24, 3, 52, 24) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CUSTOMER_INITIATED, networkReasonCode: "4863 ", disputedOn: new Date(2023, 3, 15, 3, 52, 24).getTime(), dueOn: new Date(2023, 3, 24, 3, 52, 24).getTime() },
    { id: "a8be327773c64ca88d987002b6380962", state: new Date(2023, 3, 27, 13, 43, 39) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.DUPLICATE, networkReasonCode: "12.3", disputedOn: new Date(2023, 3, 18, 13, 43, 39).getTime(), dueOn: new Date(2023, 3, 27, 13, 43, 39).getTime() },
    { id: "2018915c47204672b496ef56127e6eb7", state: new Date(2023, 4, 10, 21, 24, 55) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.GENERAL, networkReasonCode: "A01", disputedOn: new Date(2023, 4, 1, 21, 24, 55).getTime(), dueOn: new Date(2023, 4, 10, 21, 24, 55).getTime() },
    { id: "ab750b003fdb41d1b592dabebac345d0", state: new Date(2023, 4, 17, 12, 0, 22) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.INCORRECT_ACCOUNT_DETAILS, networkReasonCode: "10.1", disputedOn: new Date(2023, 4, 8, 12, 0, 22).getTime(), dueOn: new Date(2023, 4, 17, 12, 0, 22).getTime() },
    { id: "8ddaa0736d754cf2b446408fc0403207", state: new Date(2023, 4, 19, 0, 41, 33) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.PRODUCT_NOT_RECEIVED, networkReasonCode: "4834", disputedOn: new Date(2023, 4, 10, 0, 41, 33).getTime(), dueOn: new Date(2023, 4, 19, 0, 41, 33).getTime() },
    { id: "7561143b0b5c41bfbca0351b4eed105d", state: new Date(2023, 4, 12, 22, 43, 28) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.UNRECOGNIZED, networkReasonCode: "176", disputedOn: new Date(2023, 4, 3, 22, 43, 28).getTime(), dueOn: new Date(2023, 4, 12, 22, 43, 28).getTime() },
    { id: "425dd6a749ca40be92d6ac4cf0aed293", state: new Date(2023, 4, 16, 0, 12, 31) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CUSTOMER_INITIATED, networkReasonCode: "4586 ", disputedOn: new Date(2023, 4, 7, 0, 12, 31).getTime(), dueOn: new Date(2023, 4, 16, 0, 12, 31).getTime() },
    { id: "c4cf047fa5ad4d60a9be6601ef6b3a25", state: new Date(2023, 4, 18, 17, 45, 6) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.GENERAL, networkReasonCode: "M42", disputedOn: new Date(2023, 4, 9, 17, 45, 6).getTime(), dueOn: new Date(2023, 4, 18, 17, 45, 6).getTime() },
    { id: "deda834b76ef4ee195eeeedb97ac9b89", state: new Date(2023, 5, 7 + 4, 19, 10, 15) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 5, 7 + 4, 19, 10, 15).getTime(), reason: DISPUTE_REASON.CREDIT_NOT_PROCESSED, networkReasonCode: "11.3", disputedOn: new Date(2023, 4, 29, 19, 10, 15).getTime(), dueOn: new Date(2023, 5, 7, 19, 10, 15).getTime() },
    { id: "7744f00fae434a1693b8ebd382d8b1ba", state: new Date(2023, 5, 5, 2, 39, 11) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CHECK_RETURNED, networkReasonCode: "13.1", disputedOn: new Date(2023, 4, 27, 2, 39, 11).getTime(), dueOn: new Date(2023, 5, 5, 2, 39, 11).getTime() },
    { id: "9e889c6b9e80449497dcbf1be78780f5", state: new Date(2023, 6, 5, 15, 36, 11) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CREDIT_NOT_PROCESSED, networkReasonCode: "4870", disputedOn: new Date(2023, 5, 26, 15, 36, 11).getTime(), dueOn: new Date(2023, 6, 5, 15, 36, 11).getTime() },
    { id: "93bf8fee11124270afeaeb13297b4014", state: new Date(2023, 6, 5, 9, 19, 7) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CHECK_RETURNED, networkReasonCode: "4586 ", disputedOn: new Date(2023, 5, 26, 9, 19, 7).getTime(), dueOn: new Date(2023, 6, 5, 9, 19, 7).getTime() },
    { id: "b18c4ff9959c4c6c9a8d035f27094be6", state: new Date(2023, 6, 13, 15, 24, 54) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.FRAUDULENT, networkReasonCode: "7010 ", disputedOn: new Date(2023, 6, 4, 15, 24, 54).getTime(), dueOn: new Date(2023, 6, 13, 15, 24, 54).getTime() },
    { id: "66b7cc37941e499ca2a9366266b2dc5b", state: new Date(2023, 6, 22, 9, 37, 48) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.SUBSCRIPTION_CANCELLED, networkReasonCode: "4849", disputedOn: new Date(2023, 6, 13, 9, 37, 48).getTime(), dueOn: new Date(2023, 6, 22, 9, 37, 48).getTime() },
    { id: "14c42b0ad20b47bd9cc805390c2cf080", state: new Date(2023, 6, 29, 7, 9, 24) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.FRAUDULENT, networkReasonCode: "34", disputedOn: new Date(2023, 6, 20, 7, 9, 24).getTime(), dueOn: new Date(2023, 6, 29, 7, 9, 24).getTime() },
    { id: "67f72626ba8348a3a00e531c51cf4049", state: new Date(2023, 6, 21, 13, 5, 8) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.DUPLICATE, networkReasonCode: "F31", disputedOn: new Date(2023, 6, 12, 13, 5, 8).getTime(), dueOn: new Date(2023, 6, 21, 13, 5, 8).getTime() },
    { id: "a371119242cd4a3eb292f98585e6e95b", state: new Date(2023, 6, 26, 23, 59, 55) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.BANK_CANNOT_PROCESS, networkReasonCode: "M36", disputedOn: new Date(2023, 6, 17, 23, 59, 55).getTime(), dueOn: new Date(2023, 6, 26, 23, 59, 55).getTime() },
    { id: "d8baa6e38b414e62955897dcb4650004", state: new Date(2023, 7, 11 + 4, 6, 9, 46) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 7, 11 + 4, 6, 9, 46).getTime(), reason: DISPUTE_REASON.CHECK_RETURNED, networkReasonCode: "10.1", disputedOn: new Date(2023, 7, 2, 6, 9, 46).getTime(), dueOn: new Date(2023, 7, 11, 6, 9, 46).getTime() },
    { id: "02ab30501b93408b87d49c1dde82b9df", state: new Date(2023, 7, 6 + 4, 6, 2, 13) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 7, 6 + 4, 6, 2, 13).getTime(), reason: DISPUTE_REASON.FRAUDULENT, networkReasonCode: "13.7", disputedOn: new Date(2023, 6, 28, 6, 2, 13).getTime(), dueOn: new Date(2023, 7, 6, 6, 2, 13).getTime() },
    { id: "72b63c0c1f774da6ba8f624f3d2e7417", state: new Date(2023, 7, 27, 1, 5, 58) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.BANK_CANNOT_PROCESS, networkReasonCode: "175", disputedOn: new Date(2023, 7, 18, 1, 5, 58).getTime(), dueOn: new Date(2023, 7, 27, 1, 5, 58).getTime() },
    { id: "93ac89c720054faaa71d837e43271492", state: new Date(2023, 7, 8, 11, 0, 21) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.BANK_CANNOT_PROCESS, networkReasonCode: "4586 ", disputedOn: new Date(2023, 6, 30, 11, 0, 21).getTime(), dueOn: new Date(2023, 7, 8, 11, 0, 21).getTime() },
    { id: "01d40ba06a534011acbb06d10aa9b70e", state: new Date(2023, 7, 9 + 4, 5, 5, 56) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 7, 9 + 4, 5, 5, 56).getTime(), reason: DISPUTE_REASON.CUSTOMER_INITIATED, networkReasonCode: "C02", disputedOn: new Date(2023, 6, 31, 5, 5, 56).getTime(), dueOn: new Date(2023, 7, 9, 5, 5, 56).getTime() },
    { id: "db989a30e7f64d1788ffbfa4018f85cf", state: new Date(2023, 8, 8, 10, 55, 12) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.INCORRECT_ACCOUNT_DETAILS, networkReasonCode: "4867 ", disputedOn: new Date(2023, 7, 30, 10, 55, 12).getTime(), dueOn: new Date(2023, 8, 8, 10, 55, 12).getTime() },
    { id: "f37b7544af564ab38d3733bd494ec4f6", state: new Date(2023, 8, 2 + 4, 13, 27, 37) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 8, 2 + 4, 13, 27, 37).getTime(), reason: DISPUTE_REASON.INSUFFICIENT_FUNDS, networkReasonCode: "P03", disputedOn: new Date(2023, 7, 24, 13, 27, 37).getTime(), dueOn: new Date(2023, 8, 2, 13, 27, 37).getTime() },
    { id: "0f9b6840310f46c68b888901944d1d5b", state: new Date(2023, 8, 19, 8, 17, 34) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.PRODUCT_UNACCEPTABLE, networkReasonCode: "4553 ", disputedOn: new Date(2023, 8, 10, 8, 17, 34).getTime(), dueOn: new Date(2023, 8, 19, 8, 17, 34).getTime() },
    { id: "27cd50b765c94a90acea2ece263b8019", state: new Date(2023, 8, 5 + 4, 23, 7, 37) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, submittedEvidenceOn: new Date(2023, 8, 5 + 4, 23, 7, 37).getTime(), reason: DISPUTE_REASON.PRODUCT_UNACCEPTABLE, networkReasonCode: "002 ", disputedOn: new Date(2023, 7, 26, 23, 7, 37).getTime(), dueOn: new Date(2023, 8, 5, 23, 7, 37).getTime() },
    { id: "a136adfde88747ef81a07f9d805d184b", state: new Date(2023, 8, 22 + 4, 3, 40, 18) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 8, 22 + 4, 3, 40, 18).getTime(), reason: DISPUTE_REASON.FRAUDULENT, networkReasonCode: "12.1", disputedOn: new Date(2023, 8, 13, 3, 40, 18).getTime(), dueOn: new Date(2023, 8, 22, 3, 40, 18).getTime() },
    { id: "1a65fad6249440989830eb83d4f3c937", state: new Date(2023, 8, 25, 23, 39, 50) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.FRAUDULENT, networkReasonCode: "4831", disputedOn: new Date(2023, 8, 16, 23, 39, 50).getTime(), dueOn: new Date(2023, 8, 25, 23, 39, 50).getTime() },
    { id: "58db5f2159a145b3bc0bf0a570c67c8c", state: new Date(2023, 9, 8, 8, 57, 53) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.FRAUDULENT, networkReasonCode: "4841", disputedOn: new Date(2023, 8, 27, 8, 57, 53).getTime(), dueOn: new Date(2023, 9, 8, 8, 57, 53).getTime() },
    { id: "3868d3c413474bdfa8cc0f5bbe1cb4fb", state: new Date(2023, 9, 4, 12, 10, 5) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.PRODUCT_NOT_RECEIVED, networkReasonCode: "C31", disputedOn: new Date(2023, 8, 25, 12, 10, 5).getTime(), dueOn: new Date(2023, 9, 4, 12, 10, 5).getTime() },
    { id: "74608e4eab494dcb9d0232eed7c60994", state: new Date(2023, 8, 30, 20, 12, 33) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.BANK_CANNOT_PROCESS, networkReasonCode: "063", disputedOn: new Date(2023, 8, 21, 20, 12, 33).getTime(), dueOn: new Date(2023, 8, 30, 20, 12, 33).getTime() },
    { id: "d162c2154794459abdd37224c963721f", state: new Date(2023, 9, 20 + 4, 2, 55, 21) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 9, 20 + 4, 2, 55, 21).getTime(), reason: DISPUTE_REASON.FRAUDULENT, networkReasonCode: "4541", disputedOn: new Date(2023, 9, 11, 2, 55, 21).getTime(), dueOn: new Date(2023, 9, 20, 2, 55, 21).getTime() },
    { id: "544a39610e0247cbaa22d6ed2071cca1", state: new Date(2023, 9, 21, 7, 13, 59) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.PRODUCT_NOT_RECEIVED, networkReasonCode: "4859", disputedOn: new Date(2023, 9, 12, 7, 13, 59).getTime(), dueOn: new Date(2023, 9, 21, 7, 13, 59).getTime() },
    { id: "f7e2760c65de47799cc1cef20c1036fc", state: DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date().setDate(new Date().getDate() - 3), reason: DISPUTE_REASON.SUBSCRIPTION_CANCELLED, networkReasonCode: "4853", disputedOn: new Date().setDate(new Date().getDate() - 6), dueOn: new Date().setDate(new Date().getDate() + 9) },
    { id: "d8188b990eac4276b1c7b785bbc7e7e1", state: DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.DEBIT_NOT_AUTHORIZED, networkReasonCode: "11.3", disputedOn: new Date().setDate(new Date().getDate() - 1), dueOn: new Date().setDate(new Date().getDate() + 8) },
    { id: "1379774bde0640c3b324a7662350f825", state: new Date(2023, 9, 25, 4, 18, 15) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.BANK_CANNOT_PROCESS, networkReasonCode: "6323", disputedOn: new Date(2023, 9, 16, 4, 18, 15).getTime(), dueOn: new Date(2023, 9, 25, 4, 18, 15).getTime() },
    { id: "f329393457264ff198978bb421c21cf1", state: new Date(2023, 10, 17, 0, 34, 8) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.SUBSCRIPTION_CANCELLED, networkReasonCode: "A01", disputedOn: new Date(2023, 10, 8, 0, 34, 8).getTime(), dueOn: new Date(2023, 10, 17, 0, 34, 8).getTime() },
    { id: "ba81e327010f49fc9bd26ff635a7dfca", state: new Date(2023, 10, 8, 0, 22, 41) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.SUBSCRIPTION_CANCELLED, networkReasonCode: "4860", disputedOn: new Date(2023, 9, 30, 0, 22, 41).getTime(), dueOn: new Date(2023, 10, 8, 0, 22, 41).getTime() },
    { id: "d6070e4fb75e4bab8dc1df946ba61555", state: new Date(2023, 10, 23 + 4, 0, 6, 54) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 10, 23 + 4, 0, 6, 54).getTime(), reason: DISPUTE_REASON.DUPLICATE, networkReasonCode: "P08", disputedOn: new Date(2023, 10, 14, 0, 6, 54).getTime(), dueOn: new Date(2023, 10, 23, 0, 6, 54).getTime() },
    { id: "8bf004f5e4a747318949ef13f62904e5", state: new Date(2023, 10, 14, 0, 11, 10) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.PRODUCT_UNACCEPTABLE, networkReasonCode: "4837", disputedOn: new Date(2023, 10, 5, 0, 11, 10).getTime(), dueOn: new Date(2023, 10, 14, 0, 11, 10).getTime() },
    { id: "c11face60cae498a9bb0c168d861f721", state: new Date(2023, 10, 25, 21, 44, 50) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CUSTOMER_INITIATED, networkReasonCode: "4855", disputedOn: new Date(2023, 10, 16, 21, 44, 50).getTime(), dueOn: new Date(2023, 10, 25, 21, 44, 50).getTime() },
    { id: "e1172f459e0c46f4af75fd0c69146890", state: new Date(2023, 11, 17, 16, 29, 29) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.GENERAL, networkReasonCode: "175", disputedOn: new Date(2023, 11, 8, 16, 29, 29).getTime(), dueOn: new Date(2023, 11, 17, 16, 29, 29).getTime() },
    { id: "8d9f5f5810e14a28962ebfae2b6d9b30", state: new Date(2023, 11, 21, 21, 21, 25) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CREDIT_NOT_PROCESSED, networkReasonCode: "4905", disputedOn: new Date(2023, 11, 12, 21, 21, 25).getTime(), dueOn: new Date(2023, 11, 21, 21, 21, 25).getTime() },
    { id: "a9bc068a39d849948899f19bb4e98672", state: new Date(2023, 11, 17 + 4, 5, 9, 20) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 11, 17 + 4, 5, 9, 20).getTime(), reason: DISPUTE_REASON.PRODUCT_UNACCEPTABLE, networkReasonCode: "155", disputedOn: new Date(2023, 11, 8, 5, 9, 20).getTime(), dueOn: new Date(2023, 11, 17, 5, 9, 20).getTime() },
    { id: "8e17c5901d6d46cb9d4c032a1545e976", state: new Date(2023, 11, 14, 7, 46, 7) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CREDIT_NOT_PROCESSED, networkReasonCode: "11.3", disputedOn: new Date(2023, 11, 5, 7, 46, 7).getTime(), dueOn: new Date(2023, 11, 14, 7, 46, 7).getTime() },
    { id: "c98828ae7a6647dd9ab44762d3e0e23e", state: new Date(2023, 11, 22, 7, 17, 59) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CUSTOMER_INITIATED, networkReasonCode: "13.1", disputedOn: new Date(2023, 11, 13, 7, 17, 59).getTime(), dueOn: new Date(2023, 11, 22, 7, 17, 59).getTime() },
    { id: "beff7c2d97eb43c78dc7fa7c10ae5402", state: new Date(2024, 0, 2, 11, 28, 15) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.GENERAL, networkReasonCode: "A02", disputedOn: new Date(2023, 11, 24, 11, 28, 15).getTime(), dueOn: new Date(2024, 0, 2, 11, 28, 15).getTime() },
    { id: "cb39f4fef34c4fc19b0c97a1db977fa6", state: new Date(2023, 11, 21, 16, 38, 55) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CHECK_RETURNED, networkReasonCode: "680", disputedOn: new Date(2023, 11, 12, 16, 38, 55).getTime(), dueOn: new Date(2023, 11, 21, 16, 38, 55).getTime() },
    { id: "3a04b928998f46619bf330e96de4748e", state: new Date(2023, 11, 29 + 4, 2, 28, 44) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2023, 11, 29 + 4, 2, 28, 44).getTime(), reason: DISPUTE_REASON.DEBIT_NOT_AUTHORIZED, networkReasonCode: "M01", disputedOn: new Date(2023, 11, 20, 2, 28, 44).getTime(), dueOn: new Date(2023, 11, 29, 2, 28, 44).getTime() },
    { id: "b418b600b96d40d5baff131f5baabf3d", state: new Date(2024, 0, 4 + 4, 7, 10, 48) < new Date() ? DISPUTE_STATE.LOST : DISPUTE_STATE.UNDER_REVIEW, submittedEvidenceOn: new Date(2024, 0, 4 + 4, 7, 10, 48).getTime(), reason: DISPUTE_REASON.PRODUCT_UNACCEPTABLE, networkReasonCode: "6040 ", disputedOn: new Date(2023, 11, 26, 7, 10, 48).getTime(), dueOn: new Date(2024, 0, 4, 7, 10, 48).getTime() },
    { id: "a4c53b0083ee4cffaac0fc7e6d254876", state: new Date(2024, 0, 1, 0, 37, 9) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.CHECK_RETURNED, networkReasonCode: "4541", disputedOn: new Date(2023, 11, 23, 0, 37, 9).getTime(), dueOn: new Date(2024, 0, 1, 0, 37, 9).getTime() },
    { id: "73dddecb2e564eaa9a4e797a777d4621", state: new Date(2024, 0, 7, 1, 42, 6) < new Date() ? DISPUTE_STATE.WON : DISPUTE_STATE.NEEDS_RESPONSE, reason: DISPUTE_REASON.DUPLICATE, networkReasonCode: "13.8", disputedOn: new Date(2023, 11, 29, 1, 42, 6).getTime(), dueOn: new Date(2024, 0, 7, 1, 42, 6).getTime() }]