import { DateTime } from ".."
import { Payins, Payouts } from "../../fake_data"
import { PayoutsLedgerData } from "../../redux"
import { PAYOUT_STATE } from "../payouts"


export enum PAYOUTS_LEDGER_COLS {
    AMOUNT,
    STATUS,
    DESCRIPTION,
    DATE
}

export interface PayoutsLedgerOrder {
    by: PAYOUTS_LEDGER_COLS,
    ascendent: boolean
}

export interface PayoutsLedgerFilter {
    state: boolean
    type: PAYOUT_STATE
}

export interface PayoutsLedgerRowProps {
    amount: number
    state: PAYOUT_STATE
    bank: string
    date: string
    description: string
}


export class PayoutsLedgerEngine {

    static getValues(state: PayoutsLedgerData): PayoutsLedgerRowProps[] {
        const rows = this.filterValues(state.payouts, state.filters)
        const reoredered = this.getOrderedValues(rows, state.order)
        return reoredered
    }

    static filterValues(payouts: PayoutsLedgerRowProps[], applied: PayoutsLedgerFilter[]) {
        let filtered: PayoutsLedgerRowProps[] = []
        applied.some(x => x.type === PAYOUT_STATE.LOCKED && x.state) && (filtered = filtered.concat(payouts.filter(x => x.state === PAYOUT_STATE.LOCKED)))
        applied.some(x => x.type === PAYOUT_STATE.PAID && x.state) && (filtered = filtered.concat(payouts.filter(x => x.state === PAYOUT_STATE.PAID)))
        applied.some(x => x.type === PAYOUT_STATE.PENDING && x.state) && (filtered = filtered.concat(payouts.filter(x => x.state === PAYOUT_STATE.PENDING)))
        return filtered
    }

    static getOrderedValues(rows: PayoutsLedgerRowProps[], order: PayoutsLedgerOrder): PayoutsLedgerRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {
            case PAYOUTS_LEDGER_COLS.AMOUNT:
                result = result.sort((a, b) => a.amount < b.amount ? factor * -1 : factor * 1)
                break

            case PAYOUTS_LEDGER_COLS.STATUS:
                result = result.sort((a, b) => a.state < b.state ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => new Date(a.date) > new Date(b.date) ? factor * -1 : factor * 1)
                break
        }
        return result
    }

    static getRows(start: number, end: number): PayoutsLedgerRowProps[] {

        const payins = Payins
            .filter(({ date }) =>
                date >= start &&
                date <= end
            )
            .sort((a, b) =>
                a.date - b.date
            )

        const payouts = Payouts
            .filter(({ date }) =>
                date >= start &&
                date <= new Date().getTime()
            )
            .sort((a, b) =>
                a.date - b.date
            )

        var result: PayoutsLedgerRowProps[] = payouts.map(payout => {
            return {
                amount: Number(payins.filter(payin => DateTime.AreInSameMonth(payout.date, payin.date)).map(x => x.amount).reduce((a, b) => a + b).toFixed(2)),
                state: payout.state,
                bank: 'Bank name',
                date: new Date(payout.date).toDateString(),
                description: payout.id
            }
        })

        return result
    }

}
