
export const checkDate = (date: Date | undefined) => {

    if (date === undefined) return ''

    const c = new Date().setHours(0, 0, 0, 0)
    const d = date.setHours(0, 0, 0, 0)

    if (d === c) return 'Oggi'
    if (d === c - 24 * 60 * 60 * 1000) return 'Ieri'
    if (d === c + 24 * 60 * 60 * 1000) return 'Domani'
    return ''
}

export class Timestamp {

    static atMidnight(n: number) {
        return n - n % 24 * 60 * 60 * 1000
    }
}

export class TimeSpan {
    static Day = 1000 * 60 * 60 * 24
    static Days(n: number) {
        return this.Day * (n ?? 1);
    }
}

export class Minutes {

    static toString(min: number) {
        return Math.floor(min / 60 - (min / 60 > 24 ? 24 : 0)) + ":" + `${(min % 60)}0`.slice(0, 2)
    }
}


export class DateTime {
    dd: number
    MM?: number
    yyyy?: number
    yy?: number
    hh?: number
    mm?: number
    ss?: number

    constructor(date?: number) {
        let d = date ? new Date(date) : new Date()
        this.dd = d.getDate()
        this.MM = d.getMonth()
        this.yy = Number(`${d.getFullYear()}`.slice(2))
    }

    static getDate(n: number) {
        return new Date(n).getDate()
    }

    static getDay(n: number) {
        return new Date(n).getDay()
    }

    static getMonth(n: number) {
        return new Date(n).getMonth()
    }

    static getYear(n: number) {
        return new Date(n).getFullYear().toFixed()
    }

    toChartLabel(): string {
        return `${Months[(this.MM ?? 0)]} ${this.MM === 0 ? '\n' + this.yy : ''}`
    }

    static getChartLabel(date: Date, timeframe: number): string {
        if (date === undefined) return ''
        let d = date.getDate()
        let M = date.getMonth()
        return timeframe < TimeSpan.Days(30) ?
            `${d}/${M + 1}` :
            `${Months[M]}${M === 0 ? '\n' + date.getFullYear() : ''}`
    }

    static toddMMYY(date: Date): string {
        return `${date.getDate()} ${Months[(date.getMonth() ?? 0)]} ${Number(`${date.getFullYear()}`.slice(2))}`
    }

    static toMMddYYYY(date: Date): string {
        return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`
    }

    static addMonths(d: Date, _n: number): Date {
        let n = _n
        let m = d.getMonth()
        let y = d.getFullYear()

        if (n === 0)
            return d

        else if (n > 0) {
            for (n; n > 0; n--) {
                if (m < 12) {
                    m++
                }
                else {
                    m = 0
                    y++
                }
            }
        }
        else {
            for (n; n < 0; n++) {
                if (m >= 0) {
                    m--
                }
                else {
                    m = 11
                    y--
                }
            }
        }
        return new Date(y, m, 1)
    }

    static subtractMoths(n: number): Date {
        if (n === 0)
            return new Date(2021, 0, 1)

        const current = new Date()
        var month = current.getMonth()
        var year = current.getFullYear()
        for (let idx = n; idx > 0; idx--) {
            if (month >= 0) {
                month--
                break
            }
            month = 11
            year--
        }
        return new Date(year, month, 1)
    }

    static AreInSameMonth(one: number, two: number): boolean {
        let
            d1 = new Date(one),
            d2 = new Date(two)
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
    }
}



export enum MONTH {
    JAN = 0,
    FEB = 1,
    MAR = 2,
    APR = 3,
    MAY = 4,
    JUN = 5,
    JUL = 6,
    AUG = 7,
    SEP = 8,
    OCT = 9,
    NOV = 10,
    DEC = 11
}

export const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

