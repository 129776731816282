
export enum REGION {
    EUROPE = 0,
    ASIA = 1,
    AMERICA = 2,
    AFRICA = 3,
    OCEANIA = 4
}

export const Regions: { [key in REGION]: string[] } = {
    [REGION.EUROPE]: ["gb", "ax", "al", "ad", "am", "at", "az", "by", "be", "ba", "bg", "hr", "cy", "cz", "dk", "ee", "fi", "fr", "ge", "de", "gr", "hu", "is", "ie", "it", "lv", "li", "lt", "lu", "mt", "md", "mc", "me", "nl", "mk", "no", "pl", "pt", "ro", "ru", "sm", "rs", "sk", "si", "es", "se", "ch", "ua", "va", "im", "rs", "me", "je", "sh", "bl", "mf", "pm", "gg", "je", "rs", "ad", "li", "mc", "rs", "sm", "va"],
    [REGION.ASIA]: ["af", "bh", "bd", "bt", "bn", "kh", "cn", "cx", "cc", "tl", "hk", "in", "id", "ir", "iq", "il", "jp", "jo", "kz", "kw", "kg", "la", "lb", "mo", "my", "mv", "mn", "mm", "np", "kp", "om", "pk", "ps", "ph", "qa", "sa", "sg", "kr", "lk", "sy", "tw", "tj", "th", "tr", "tm", "ae", "uz", "vn", "ye"],
    [REGION.AMERICA]: ["us", "ca", "ai", "ar", "aw", "bs", "bb", "bz", "bm", "bo", "bq", "br", "vg", "ky", "cl", "co", "cr", "cu", "cw", "dm", "do", "ec", "sv", "fk", "gf", "gd", "gp", "gt", "gy", "ht", "hn", "jm", "mq", "mx", "ms", "ni", "pa", "py", "pe", "pr", "kn", "lc", "vc", "sx", "tt", "tc", "us", "uy", "ve", "vi"],
    [REGION.AFRICA]: ["dz", "ao", "bj", "bw", "io", "bf", "bi", "cv", "cm", "cf", "td", "km", "cg", "cd", "dj", "gq", "er", "sz", "et", "tf", "ga", "gm", "gh", "gn", "gw", "ci", "ke", "ls", "lr", "ly", "mg", "mw", "ml", "mr", "mu", "yt", "ma", "mz", "na", "ne", "ng", "re", "rw", "sh", "st", "sn", "sc", "sl", "so", "za", "ss", "sd", "sz", "tz", "tg", "tn", "ug", "zm", "zw"],
    [REGION.OCEANIA]: ["as", "au", "ck", "fj", "pf", "gu", "ki", "mh", "fm", "nr", "nc", "nz", "nu", "nf", "mp", "pw", "pg", "pn", "ws", "sb", "tk", "to", "tv", "vu", "wf"],
};
