import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AccessCode, ApiKeys, ApiLogs, BuyerDetails, BuyersLedger, CalendarMenuStyles, DisputeDetails, DisputesLedger, Docs, FeesLedger, MethodPage, Overview, PayinDetails, PayinsLedger, PayoutsLedger, PeriodStyles, SellerDetails, SellersLedger, WebhookChannels, WebhookLogs } from './components';
import { GlobalStyles, LadingPageStyles } from './design_system';
import { REDUX_STATE, updateTimestamp } from './redux';
import { DemoRoutes } from './routes';
import { PAYMENT_METHOD } from './utilities';


const App = () => {

    const dispatch = useDispatch()
    useEffect(() => { dispatch(updateTimestamp()) }, [])

    const { theme: { palette }, access: { canAccess, lastUpdate } } = useSelector(REDUX_STATE)

    const hasAccess = canAccess && lastUpdate > new Date().setHours(new Date().getHours() - 1)

    useEffect(() => {
        hasAccess &&
            (document.body.className = "")
    }, [hasAccess])

    const render = () => <>
        {
            hasAccess ?
                <GlobalStyles palette={palette} /> : <LadingPageStyles />
        }
        <CalendarMenuStyles palette={palette} />
        <PeriodStyles palette={palette} />
        {
            hasAccess ?
                <Router>
                    <Routes>

                        {/* Dashboard */}
                        <Route
                            path={DemoRoutes.index}
                            element={<Overview />}
                        />

                        <Route
                            path={DemoRoutes.system.stripeCard}
                            element={<MethodPage method={PAYMENT_METHOD.CARD} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeBancontact}
                            element={<MethodPage method={PAYMENT_METHOD.BANCONTACT} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeAlipay}
                            element={<MethodPage method={PAYMENT_METHOD.ALIPAY} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeWechatPay}
                            element={<MethodPage method={PAYMENT_METHOD.WECHATPAY} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeEps}
                            element={<MethodPage method={PAYMENT_METHOD.EPS} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeSofort}
                            element={<MethodPage method={PAYMENT_METHOD.SOFORT} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeP24}
                            element={<MethodPage method={PAYMENT_METHOD.P24} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeOxxo}
                            element={<MethodPage method={PAYMENT_METHOD.OXXO} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeGooglePay}
                            element={<MethodPage method={PAYMENT_METHOD.GOOGLE_PAY} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeApplePay}
                            element={<MethodPage method={PAYMENT_METHOD.APPLE_PAY} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeGiroPay}
                            element={<MethodPage method={PAYMENT_METHOD.GIROPAY} />}
                        />
                        <Route
                            path={DemoRoutes.system.stripeIdeal}
                            element={<MethodPage method={PAYMENT_METHOD.IDEAL} />}
                        />
                        <Route
                            path={DemoRoutes.system.paypal}
                            element={<MethodPage method={PAYMENT_METHOD.PAYPAL} />}
                        />

                        <Route
                            path={DemoRoutes.ledger.payins}
                            element={<PayinsLedger />}
                        />
                        <Route
                            path={`${DemoRoutes.ledger.payinDetails}/*`}
                            element={<PayinDetails />}
                        />
                        <Route
                            path={DemoRoutes.ledger.payouts}
                            element={<PayoutsLedger />}
                        />
                        <Route
                            path={DemoRoutes.ledger.disputes}
                            element={<DisputesLedger />}
                        />
                        <Route
                            path={DemoRoutes.ledger.fees}
                            element={<FeesLedger />}
                        />
                        <Route
                            path={`${DemoRoutes.ledger.disputeDetails}/*`}
                            element={<DisputeDetails />}
                        />
                        <Route
                            path={DemoRoutes.ledger.sellers}
                            element={<SellersLedger />}
                        />
                        <Route
                            path={`${DemoRoutes.ledger.sellerDetails}/*`}
                            element={<SellerDetails />}
                        />
                        <Route
                            path={DemoRoutes.ledger.buyers}
                            element={<BuyersLedger />}
                        />
                        <Route
                            path={`${DemoRoutes.ledger.buyerDetails}/*`}
                            element={<BuyerDetails />}
                        />

                        <Route
                            path={DemoRoutes.os.docs}
                            element={<Docs />}
                        />
                        <Route
                            path={DemoRoutes.os.apiKeys}
                            element={<ApiKeys />}
                        />
                        <Route
                            path={DemoRoutes.os.apiLogs}
                            element={<ApiLogs />}
                        />
                        <Route
                            path={DemoRoutes.os.webhookChannel}
                            element={<WebhookChannels />}
                        />
                        <Route
                            path={DemoRoutes.os.webhookLogs}
                            element={<WebhookLogs />}
                        />

                    </Routes>
                </Router>
                :
                <AccessCode />
        }
    </>

    return render()
}

export default App;
