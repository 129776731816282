import { useState } from "react"
import { CapabilityBadge, Col, Container, Row } from ".."
import { PAYMENT_METHOD, PaymentMethods, UUID } from "../../utilities"


export const CapabilitiesSelectors = (props: { capabilities: { method: PAYMENT_METHOD, disabled?: boolean, state: boolean }[] }) => {

    let n = 0, id = UUID.create()
    const [state, setState] = useState(props.capabilities.sort((a, b) => a.method - b.method))

    const set = (val: { method: PAYMENT_METHOD, state: boolean }) => {
        setState([
            ...state.filter(x => val.method !== x.method),
            val
        ].sort((a, b) => a.method - b.method))
    }

    const render = () =>
        <Container>
            <Row gutterWidth={16} gutterHeight={16}>
                {
                    state.filter(x => !x.disabled).map(cap =>
                        PaymentMethods[cap.method] ?
                            <Col xs={4} min_w="300px" key={id + n++} >
                                <CapabilityBadge method={cap.method} active={cap.state}
                                    onClick={() => set({
                                        method: cap.method,
                                        state: !cap.state
                                    })} />
                            </Col>
                            : <></>)
                }
            </Row>
        </Container>


    return render()
}
