import { BadgePropsForBuyer, Card, CardDataCollection, CardDivider, CardHeaderData, CardTitle } from "../.."
import { Payins } from "../../../fake_data"
import { BuyerProps, PAYIN_STATE } from "../../../utilities"

export const BuyerAccountDetails = (buyer: BuyerProps) => {

    const payins = Payins.filter(x => x.buyer.id === buyer.id)
    const refunded = payins.filter(x => x.state === PAYIN_STATE.REFUNDED).map(x => x.amount).reduce((a, b) => a + b, 0)
    const succeeded = payins.filter(x => x.state === PAYIN_STATE.SUCCEEDED).map(x => x.amount).reduce((a, b) => a + b, 0)
    const uncaptured = payins.filter(x => x.state === PAYIN_STATE.PENDING).map(x => x.amount).reduce((a, b) => a + b, 0)

    const render = () =>
        <Card>
            <CardTitle>Account</CardTitle>

            <CardHeaderData
                id={buyer.id}
                title={buyer.name}
                badge={BadgePropsForBuyer(buyer.state)} />
            <CardDivider />

            <CardDataCollection data={[
                { data: ["Lifetime volume", `$ ${succeeded.toFixed(2)}`] },
                { data: ["Pending", `$ ${uncaptured.toFixed(2)}`] },
                { data: ["Disputed", `$ ${refunded.toFixed(2)}`] }
            ]} />
        </Card>

    return render()
}
