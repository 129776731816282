import { useDispatch, useSelector } from "react-redux"
import { Analytics, CheckoutVisitsTable, Col, Dashboard, Row, Spacer, TopMethodsTable } from "../.."
import { DemoRoutes } from "../../../routes"
import { useEffect } from "react"
import { LOAD_ANALYTICS_DATA } from "../../../sagas"
import { REDUX_STATE } from "../../../redux"

export const Overview = () => {

        const dispatch = useDispatch()
        const { analytics: { period: { start, end } } } = useSelector(REDUX_STATE)
        useEffect(() => { dispatch({ type: LOAD_ANALYTICS_DATA }) }, [start, end])
        
        const Render = () =>
                <Dashboard
                        pageTitle="System Overview"
                        onPage={DemoRoutes.index}
                        breadcrumb={["System", "Overview"]}>

                        <Analytics />

                        <Spacer size={24} />

                        <Row wrap="nowrap">
                                <Col max_w="30%">
                                        <CheckoutVisitsTable />
                                </Col>
                                <Spacer size={16} />
                                <Col>
                                        <TopMethodsTable />
                                </Col>
                        </Row>


                </Dashboard>

        return Render()
}

