import { ReactNode, createRef, useState } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { Badge, BadgeProps, CardHorizzontalSpacer, CardVerticalSpacer, Col, ColProps, Copyable, DescriptionBlock, DescriptionBlockProps, H3, H4, H5, Icon, P, Row, S, Spacer, Spacers, TextProps } from ".."
import { Overflow, PaletteProps, SpacerValue } from "../../design_system"
import { REDUX_STATE } from "../../redux"
import { UUID } from "../../utilities"

interface CardProps extends ColProps {
        noPadding?: boolean
        noHorizzontalPadding?: boolean
        onClick?: () => void
}

export const Card = (props: CardProps) => {

        const hasPadding = !props.noPadding
        const hasHorizzontalPadding = !props.noHorizzontalPadding
        const { isDark, palette } = useSelector(REDUX_STATE).theme

        return <CardStyled w={props.w} h={props.h} max_w={props.max_w} isDark={isDark} palette={palette} bg={props.bg} overflow={props.overflow} onClick={props.onClick}>
                {
                        hasPadding && <CardVerticalSpacer />
                }
                <Col wrap="nowrap" {...{ h: `calc(100% - ${hasPadding ? (Spacers.cardVertical * 2) : 0}px)` }}>
                        {
                                hasPadding && hasHorizzontalPadding && <CardHorizzontalSpacer />
                        }
                        <Col {...{ ...props, onClick: undefined, bg: undefined, max_w: undefined, overflow: undefined }}>
                                {
                                        Array.isArray(props.children) ? props.children.map(x => x) : props.children
                                }
                        </Col>
                        {
                                hasPadding && hasHorizzontalPadding && <CardHorizzontalSpacer />
                        }
                </Col>
                {
                        hasPadding && <CardVerticalSpacer />
                }
        </CardStyled >
}

interface CardStyledProps extends CardProps { palette: PaletteProps, isDark?: boolean, onClick?: () => void, overflow?: Overflow }
const CardStyled = styled.div<CardStyledProps>`
${({ palette, isDark, w, h, bg, max_w, overflow }) => css`
        border-radius: 6px;
        background: ${bg ?? palette.white};
        box-shadow: ${`0px 1px 3px rgba(${isDark ? "255,255, 255, .08" : "0, 0, 0, 0.12"})${isDark ? "" : ", 0px 2px 8px -1px rgba(0, 0, 0, 0.05)"}`};
        ${overflow && css`overflow: ${overflow};`}
        ${w && css`width: ${w};`}
        ${h && css`height: ${h};`}
        ${max_w && css`max-width: ${max_w};`}
        transition: all 1s ease-out, background-color 0s ease-out;
`}
`

export const CardTitle = (props: { children: ReactNode, withPadding?: boolean }) => {
        const { children, withPadding } = props

        return <Col pt={withPadding ? Spacers.cardVertical as SpacerValue : 0} px={withPadding ? Spacers.cardHorizzontal as SpacerValue : 0}>
                <H4 medium variant="dark" nowrap>{children}</H4>
                <Spacer size={16} />
        </Col>
}

export const CardBlock = (props: { children: ReactNode, bg?: string }) => {
        const { children, bg } = props

        return <>
                <CardDivider noPadding />
                <Col bg={bg} py={Spacers.cardVertical as SpacerValue} px={Spacers.cardHorizzontal as SpacerValue} overflow="hidden">
                        {children}
                <Spacer size={4}/>
                </Col>
        </>
}

export const CardSubtilte = (props: { children: ReactNode }) => {
        const { children } = props

        return <>
                <H5 medium variant="dark" nowrap>
                        {children}
                </H5>
                <Spacer size={12} />
        </>
}

export const CardText = (props: TextProps) => {

        return <P {...props} />
}

export const CardLink = (props: { children: ReactNode, ml?: SpacerValue }) => {
        const { children, ml } = props
        const { palette } = useSelector(REDUX_STATE).theme

        return <P ml={ml} nowrap variant="link" href={'#'}>{children}<Icon ml={4} height={10} variant="link" color={palette.blue} /></P>
}

export const CardDivider = (props: { noPadding?: boolean }) => {

        const { noPadding } = props
        const { palette } = useSelector(REDUX_STATE).theme

        return <>
                <Spacer size={noPadding ? 0 : 16} />
                <Col xs={12} bt={`1px solid ${palette.gray2}`} />
                <Spacer size={noPadding ? 0 : 16} />
        </>
}

export const CardDataCollection = (props: { data: DescriptionBlockProps[] }) => {

        let n = 0, id = UUID.create()
        const { palette } = useSelector(REDUX_STATE).theme

        return <Row align="start" gutterHeight={24} gutterWidth={40}>
                {
                        props.data.map(x => {
                                n++
                                return <Col key={id + n} xs="content" br={n === props.data.length ? undefined : `1px solid ${palette.gray2}`}>
                                        <DescriptionBlock {...x} />
                                </Col>
                        })
                }
        </Row>
}



export const CardScrollableItem = (props: { cta: string, children: ReactNode }) => {

        const [h, setH] = useState(0)
        const { cta, children } = props
        const ref = createRef<HTMLDivElement>()

        const toggle = () => {
                h === 0 ? setH(ref.current?.scrollHeight ?? 0) : setH(0)
        }

        const render = () =>
                <Col>
                        <CardVerticalSpacer negative />

                        <Col onClick={toggle} pointer>
                                <CardVerticalSpacer />
                                <Col wrap="nowrap" align="center">
                                        <Col>
                                                <P>{cta}</P>
                                        </Col>

                                        <Icon variant="arrow" rotateX={h === 0 ? 0 : 180} />
                                </Col>
                                <CardVerticalSpacer />
                        </Col>

                        <CardVerticalSpacer negative />

                        <Col px={24} mx={-24} mb={-2} max_h={`${h}px`} style={{ transition: 'max-height ease-out .5s' }} overflowY="hidden">
                                <div ref={ref}>
                                        <CardVerticalSpacer />
                                        {children}
                                        <Spacer size={2} />
                                </div>
                        </Col>
                </Col>

        return render()
}


export const CardHeaderData = (props: { id?: string, title: string | ReactNode, badge?: BadgeProps }) => {

        const { id, title, badge } = props

        const render = () =>
                <Col wrap="nowrap" align="center">
                        {
                                typeof title === "string" ?
                                        <H3 medium variant="dark">{title}</H3> : title
                        }
                        {
                                badge && <>
                                        <Spacer size={16} />
                                        <Badge {...badge} />
                                </>
                        }
                        {
                                id &&
                                <Col justify="end">
                                        <Copyable value={id}>
                                                <S nowrap>{id}</S>
                                        </Copyable>
                                </Col>
                        }
                </Col>

        return render()
}