import { ReactNode } from "react"
import styled from "styled-components"
import { Layout } from "../../../design_system/layout"
import { LightPalette } from "../../../design_system/palette"
import { ColStyled } from "./Col"
import { RowStyled } from "./Row"

interface GridDebuggerStyles {
  children: ReactNode
}

export const GridDebugger = (props: GridDebuggerStyles) =>
  <GridDebuggerStyled>{props.children}</GridDebuggerStyled>

const GridDebuggerStyled = styled.div`
  ${ColStyled} {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    color: #ede9f9;
    font-weight: 800;
    background-color: #eb484837;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    
    border-left: 1px solid ${LightPalette.primary2};
    border-right: 1px solid ${LightPalette.primary2};

    &:after {
      z-index: -1;
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      bottom: 0px;
      background-color: #00ff9d94;
      left: ${Layout.defaultSpacer}px;
      right: ${Layout.defaultSpacer}px;
    }
  }

  ${RowStyled} {
    background: ${LightPalette.primary2};
  }
  
`
