import { Col } from "../.."
import { PSP, UUID } from "../../../utilities"

interface ProviderLogoProps {
        variant: PSP
}

export const ProviderLogo = (props: ProviderLogoProps) => {

        let id = UUID.create()

        return <Col min_w="28px" min_h="28px">
                {
                        props.variant === PSP.STRIPE &&
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.4 0H5.6C2.50721 0 0 2.50721 0 5.6V22.4C0 25.4928 2.50721 28 5.6 28H22.4C25.4928 28 28 25.4928 28 22.4V5.6C28 2.50721 25.4928 0 22.4 0Z" fill="#635BFF" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.0706 10.6025C13.0706 9.88242 13.6613 9.60553 14.6398 9.60553C16.0429 9.60553 17.8152 10.0301 19.2183 10.7871V6.4486C17.686 5.83936 16.1721 5.59937 14.6398 5.59937C10.8921 5.59937 8.39978 7.55629 8.39978 10.824C8.39978 15.9194 15.4152 15.1071 15.4152 17.304C15.4152 18.1532 14.6767 18.4301 13.6429 18.4301C12.1106 18.4301 10.1537 17.8025 8.60286 16.9532V21.3471C10.3198 22.0855 12.0552 22.3994 13.6429 22.3994C17.4829 22.3994 20.1228 20.4978 20.1228 17.1932C20.1044 11.6917 13.0706 12.6701 13.0706 10.6025Z" fill="white" />
                        </svg>
                        ||
                        props.variant === PSP.PAYPAL &&
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.4 0H5.6C2.50721 0 0 2.50721 0 5.6V22.4C0 25.4928 2.50721 28 5.6 28H22.4C25.4928 28 28 25.4928 28 22.4V5.6C28 2.50721 25.4928 0 22.4 0Z" fill="#27346A" />
                                <mask id={id} maskUnits="userSpaceOnUse" x="7" y="5" width="15" height="18">
                                        <path d="M21.0903 5.59985H7V22.3999H21.0903V5.59985Z" fill="white" />
                                </mask>
                                <g mask={`url(#${id})`}>
                                        <path opacity="0.6" d="M19.4892 9.7561C19.4741 9.85311 19.4567 9.95229 19.4373 10.0542C18.7668 13.496 16.4735 14.685 13.5442 14.685H12.0528C11.6946 14.685 11.3928 14.9451 11.3369 15.2985L10.5733 20.1412L10.3571 21.5139C10.3208 21.7459 10.4996 21.9551 10.7338 21.9551H13.3789C13.6922 21.9551 13.9583 21.7274 14.0076 21.4186L14.0335 21.2842L14.5317 18.1236L14.5636 17.9502C14.6124 17.6401 14.879 17.4126 15.1923 17.4126H15.5878C18.1507 17.4126 20.1569 16.372 20.7433 13.3611C20.9883 12.1032 20.8615 11.053 20.2133 10.3143C20.0171 10.0916 19.7738 9.90676 19.4892 9.7561Z" fill="white" />
                                        <path opacity="0.8" d="M18.7878 9.47719C18.6855 9.44738 18.5798 9.42028 18.4714 9.39589C18.3625 9.37205 18.2508 9.35091 18.1359 9.33249C17.7337 9.26746 17.2931 9.23657 16.8212 9.23657H12.8369C12.7388 9.23657 12.6456 9.25879 12.5621 9.29889C12.3784 9.38723 12.2418 9.56119 12.2088 9.77417L11.3612 15.1425L11.3368 15.2992C11.3926 14.9458 11.6945 14.6857 12.0527 14.6857H13.5441C16.4733 14.6857 18.7668 13.4962 19.4371 10.0549C19.4571 9.95301 19.4739 9.85383 19.4892 9.75683C19.3195 9.66686 19.1358 9.5899 18.938 9.52433C18.8891 9.50807 18.8387 9.49237 18.7878 9.47719Z" fill="white" />
                                        <path d="M12.2102 9.77222C12.2432 9.55924 12.3798 9.38527 12.5635 9.29748C12.6475 9.25738 12.7402 9.23516 12.8383 9.23516H16.8225C17.2946 9.23516 17.7352 9.26605 18.1373 9.33108C18.2522 9.3495 18.3638 9.37064 18.4727 9.39448C18.5812 9.41887 18.6868 9.44598 18.7893 9.47578C18.8402 9.49096 18.8906 9.50667 18.9399 9.52239C19.1377 9.58796 19.3214 9.66546 19.4911 9.75487C19.6904 8.48296 19.4894 7.61693 18.8017 6.83276C18.0436 5.96945 16.6751 5.59985 14.9241 5.59985H9.84082C9.48314 5.59985 9.17803 5.85999 9.12275 6.21387L7.00541 19.6349C6.96368 19.9004 7.16853 20.14 7.43625 20.14H10.5746L11.3626 15.1406L12.2102 9.77222Z" fill="white" />
                                </g>
                        </svg>
                        ||
                        <></>
                }
        </Col>
}