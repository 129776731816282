import { DISPUTE_STATE, DateTime, DisputeProps, PspProps } from ".."
import { Payins } from "../../fake_data"
import { PayinsLedgerData } from "../../redux"
import { PAYIN_STATE } from "../payins"
import { PaymentMethod } from "../paymentMethods"


export enum PAYINS_LEDGER_COLS {
    AMOUNT,
    STATUS,
    GATEWAY,
    SELLER,
    BUYER,
    DATE
}

export interface PayinsLedgerOrder {
    by: PAYINS_LEDGER_COLS,
    ascendent: boolean
}

export interface PayinsLedgerFilter {
    state: boolean
    type: PAYIN_STATE
}

export interface PayinsLedgerRowProps {
    amount: number
    state: PAYIN_STATE
    id: string
    provider: PspProps
    method: PaymentMethod
    buyer: string
    seller: string
    date: string
    selected?: boolean
    dispute?: DisputeProps
}

export class PayinsLedgerEngine {

    static getValues(state: PayinsLedgerData): PayinsLedgerRowProps[] {
        const rows = this.filterValues(state.payins, state.filters)
        const reoredered = this.getOrderedValues(rows, state.order)
        return reoredered
    }

    static filterValues(payins: PayinsLedgerRowProps[], applied: PayinsLedgerFilter[]) {
        let filtered: PayinsLedgerRowProps[] = []
        applied.some(x => x.type === PAYIN_STATE.FAILED && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === PAYIN_STATE.FAILED)))
        applied.some(x => x.type === PAYIN_STATE.REFUNDED && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === PAYIN_STATE.REFUNDED)))
        applied.some(x => x.type === PAYIN_STATE.PENDING && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === PAYIN_STATE.PENDING)))
        applied.some(x => x.type === PAYIN_STATE.INITIATED && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === PAYIN_STATE.INITIATED)))
        applied.some(x => x.type === PAYIN_STATE.SUCCEEDED && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === PAYIN_STATE.SUCCEEDED)))
        applied.some(x => x.type === PAYIN_STATE.SUCCEEDED && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === PAYIN_STATE.SUCCEEDED || x.state === PAYIN_STATE.DISPUTED)))
        return filtered
    }

    static getOrderedValues(rows: PayinsLedgerRowProps[], order: PayinsLedgerOrder): PayinsLedgerRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {
            case PAYINS_LEDGER_COLS.AMOUNT:
                result = result.sort((a, b) => a.amount < b.amount ? factor * -1 : factor * 1)
                break

            case PAYINS_LEDGER_COLS.STATUS:
                result = result.sort((a, b) => a.state < b.state ? factor * -1 : factor * 1)
                break

            case PAYINS_LEDGER_COLS.GATEWAY:
                result = result.sort((a, b) => a.provider.display_name < b.provider.display_name ? factor * -1 : factor * 1)
                break

            case PAYINS_LEDGER_COLS.BUYER:
                result = result.sort((a, b) => a.buyer.toLowerCase() < b.buyer.toLowerCase() ? factor * -1 : factor * 1)
                break

            case PAYINS_LEDGER_COLS.SELLER:
                result = result.sort((a, b) => a.seller.toLowerCase() < b.seller.toLowerCase() ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => new Date(a.date) > new Date(b.date) ? factor * -1 : factor * 1)
                break
        }
        return result
    }

    static getRows(start: number, end: number): PayinsLedgerRowProps[] {

        const payins = Payins
            .filter(({ date }) =>
                date >= start &&
                date <= end
            )
            .sort((a, b) =>
                a.date - b.date
            )

        var result: PayinsLedgerRowProps[] = payins.map(s => {

            return {
                amount: s.amount,
                state: s.state,
                id: s.id,
                provider: s.provider,
                method: s.method,
                buyer: s.buyer.email,
                seller: s.seller.email,
                date: new Date(s.date).toDateString(),
                dispute: s.dispute
            }
        })

        return result
    }
}