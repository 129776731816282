import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApiLogsTable, Dashboard, Spacer, TableFilters, TablePagination } from "../.."
import { REDUX_STATE, apiLogsActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { ApiLogsEngine, HttpStatusCode } from "../../../utilities"

export const ApiLogs = () => {

        const dispatch = useDispatch()
        useEffect(() => { dispatch(loadData()) }, [])

        const state = useSelector(REDUX_STATE).apiLogs
        var apiLogs = ApiLogsEngine.getValues(state)

        const { results, current } = state.pagination
        const { loadData, setCurrentPage, toggleAppliedFilter } = apiLogsActions

        const pages = Math.floor(apiLogs.length / results) - (apiLogs.length % results === 0 ? 1 : 0)
        const rows = apiLogs.slice(current * results, (current * results) + results)

        const Render = () =>
                <Dashboard
                        pageTitle="Api Logs"
                        onPage={DemoRoutes.os.apiLogs}
                        breadcrumb={["OS", "Api", "Logs"]}>

                        <TableFilters {...{ filters: state.filters.map(x => { return { name: HttpStatusCode(x.type), state: x.state, type: x.type } }), onClick: (val: number) => dispatch(toggleAppliedFilter(val)) }} />

                        <Spacer size={16} />

                        <ApiLogsTable {...rows} />

                        <Spacer size={24} />

                        <TablePagination {...{
                                pages, current, results,
                                items: apiLogs.length,
                                setCurrent: (page: number) => dispatch(setCurrentPage(page))
                        }} />
                </Dashboard>

        return Render()
}

