import { BuyersTableHead, BuyersTableRow, Card } from "../.."
import { BuyersLedgerRowProps, UUID } from "../../../utilities"

export const BuyersTable = (rows: BuyersLedgerRowProps[]) => {

    let n = 0, id = UUID.create()

    const render = () =>
        <Card noPadding>
            <BuyersTableHead />
            {
                Object.values(rows).map(row =>
                    <BuyersTableRow key={id + n++} {...row} />
                )
            }
        </Card>

    return render()
}