import { combineReducers } from "@reduxjs/toolkit"
import { accessReducer, apiKeysReducer, apiLogsReducer, buyersLedgerReducer, disputeFormReducer, disputesLedgerReducer, feesLedgerReducer, payinsLedgerReducer, payoutsLedgerReducer, sellersLedgerReducer, themeReducer, webhookChannelsReducer } from "."
import { analyticsDataReducer } from "./analytics"
import { methodsReducer } from "./methods"

export * from './access'
export * from './analytics'
export * from './apiKeys'
export * from './apiLogs'
export * from './buyers'
export * from './disputeForm'
export * from './disputes'
export * from './fees'
export * from './payins'
export * from './payouts'
export * from './sellers'
export * from './theme/palette'
export * from './webhookChannels'

export const reducers = combineReducers({
    theme: themeReducer,
    methods: methodsReducer,
    analytics: analyticsDataReducer,
    buyers: buyersLedgerReducer,
    sellers: sellersLedgerReducer,
    payins: payinsLedgerReducer,
    disputes: disputesLedgerReducer,
    fees: feesLedgerReducer,
    disputeForm: disputeFormReducer,
    apiKeys: apiKeysReducer,
    webhookChannels: webhookChannelsReducer,
    apiLogs: apiLogsReducer,
    payouts: payoutsLedgerReducer,
    access: accessReducer,
})

export type State = ReturnType<typeof reducers>

export const REDUX_STATE = (state: State) => state

