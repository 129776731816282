import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_KEYS_TABLE_COLS, WEBHOOK_CHANNELS_TABLE_COLS, WebhookChannelsEngine, WebhookChannelsOrder, WebhookChannelsRowProps } from "../utilities";

export interface WebhookChannelsData {
    webhookChannels: WebhookChannelsRowProps[]
    order: WebhookChannelsOrder
    pagination: {
        results: number
        current: number
    }
}

const initialState: WebhookChannelsData = {
    webhookChannels: [],
    order: {
        by: WEBHOOK_CHANNELS_TABLE_COLS.CREATED,
        ascendent: true
    },
    pagination: {
        results: 15,
        current: 0
    }
}

export const webhookChannelsSlice = createSlice({
    name: 'webhookChannels',
    initialState,
    reducers: {

        loadData: (state: WebhookChannelsData) => {
            var webhookChannels = WebhookChannelsEngine.getRows()
            return {
                ...state,
                webhookChannels,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order: initialState.order
            }
        },

        orderData: (state: WebhookChannelsData, action: PayloadAction<WEBHOOK_CHANNELS_TABLE_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order
            }
        },

        setCurrentPage: (state: WebhookChannelsData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: WebhookChannelsData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        createWebhookChannel: (state: WebhookChannelsData, action: PayloadAction<WebhookChannelsRowProps>) => {
            return {
                ...state,
                webhookChannels: [...state.webhookChannels, action.payload]
            }
        }
    }
})

export const webhookChannelsActions = webhookChannelsSlice.actions

export const webhookChannelsReducer = webhookChannelsSlice.reducer

