import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Copyable, FormInput, ModalTitle, P, S, Spacer } from "../..";
import { REDUX_STATE, apiKeysActions } from "../../../redux";
import { API_KEY_TYPE, UUID } from "../../../utilities";



export const ApiKeyCreationForm = () => {

    const dispatch = useDispatch()
    const { createApiKey } = apiKeysActions
    const [publicKey, setPublicKey] = useState(UUID.create())
    const [state, setState] = useState({ name: '', type: '' })
    const [err, setErr] = useState({ name: '', type: '' })
    const { palette } = useSelector(REDUX_STATE).theme

    const form = () =>
        <Col>
            <ModalTitle>
                Create api key
            </ModalTitle>
            <FormInput type="radio" vertical value={state.type} option={["Test", "Live"]} onSelected={onChange("type")} errorMessage={err.type} />
            <Spacer size={16} />
            <FormInput type="text" onChange={onChange("name")} errorMessage={err.name}>Name</FormInput>
            <Spacer size={24} />
            <Button variant="primary" onClick={onConfirm} disabled={err.name.length > 0 || err.type.length > 0}>Confirm</Button>
        </Col>

    const validate = () => {
        var valid = true
        var name = ''
        var type = ''

        if (state.name.length === 0) {
            name = "Type an api key name."
            valid = false
        }
        else if (state.name.length < 3) {
            name = "Type a name longer at least 3 characters."
            valid = false
        }

        if (state.type.length === 0) {
            type = "Select an api key type."
            valid = false
        }


        setErr({ name, type })
        return valid
    }

    const onChange = (field: "name" | "type") => (val: string) => {
        setState({ ...state, [field]: val })

        if (err.name.length === 0 && err.type.length === 0) {
            return
        }

        validate()
    }


    const onConfirm = () => {

        if (!validate()) {
            return
        }

        dispatch(createApiKey({
            id: UUID.create(),
            name: state.name,
            type: state.type === "Test" ? API_KEY_TYPE.TEST : API_KEY_TYPE.LIVE,
            publicKey: publicKey,
            lastUse: undefined,
            created: new Date().getTime()
        }))

        setState({ name: '', type: '' })
        setCreated(true)
    }


    const secret = UUID.create()
    const confirmation = () =>
        <Col>
            <ModalTitle>
                Confirmed
            </ModalTitle>
            <P>Congratulations! You've successfully generated an API key.<br /> Please make sure to store the secret in a secure location, as it will not be displayed again for security reasons.<br /> Keep your data safe and happy coding!</P>
            <Spacer size={16} />
            <S>Public</S>
            <Spacer size={4} />
                <Copyable value={publicKey}>
                    <P variant="dark">{publicKey}</P>
                </Copyable>
            <Spacer size={16} />
            <S>Secret</S>
            <Spacer size={4} />
                <Copyable value={secret}>
                    <P variant="dark">{secret}</P>
                </Copyable>
        </Col>


    const [created, setCreated] = useState(false)

    return created ? confirmation() : form()
}