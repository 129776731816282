import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PAYIN_STATE, PAYINS_LEDGER_COLS, FeesLedgerEngine, FeesLedgerFilter, FeesLedgerOrder, FeesLedgerRowProps, FEES_LEDGER_COLS, PSP } from "../utilities";

export interface FeesLedgerData {
    fees: FeesLedgerRowProps[]
    period: {
        start: number
        end: number
    }
    order: FeesLedgerOrder
    filters: FeesLedgerFilter[]
    pagination: {
        results: number
        current: number
    }
}

const initialState: FeesLedgerData = {
    fees: [],
    period: {
        start: new Date(2023, 0, 1).getTime(),
        end: new Date().getTime()
    },
    order: {
        by: FEES_LEDGER_COLS.DATE,
        ascendent: true
    },
    filters: [
        {
            state: true,
            type: PSP.STRIPE
        },
        {
            state: true,
            type: PSP.PAYPAL
        },
    ],
    pagination: {
        results: 15,
        current: 0
    }
}

export const feesLedgerSlice = createSlice({
    name: 'feesLedger',
    initialState,
    reducers: {

        setPeriod: (state: FeesLedgerData, action: PayloadAction<{ start: number, end: number }>) => {
            const { start, end } = action.payload
            return {
                ...state,
                period: { start: start, end: end }
            }
        },

        loadData: (state: FeesLedgerData) => {
            var fees = FeesLedgerEngine.getRows(state.period.start, state.period.end)
            return {
                ...state,
                fees,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order: initialState.order
            }
        },

        orderData: (state: FeesLedgerData, action: PayloadAction<FEES_LEDGER_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order
            }
        },

        setCurrentPage: (state: FeesLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: FeesLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        toggleAppliedFilter: (state: FeesLedgerData, action: PayloadAction<PSP>) => {
            return {
                ...state,
                filters: state.filters.map(x => x.type !== action.payload ? x : { ...x, state: !x.state }),
                pagination: {
                    ...state.pagination,
                    current: 0
                }
            }
        }
    }
})

export const feesLedgerActions = feesLedgerSlice.actions

export const feesLedgerReducer = feesLedgerSlice.reducer

