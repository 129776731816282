import "flatpickr/dist/flatpickr.css"
import { createGlobalStyle, css } from "styled-components"
import { Typography } from "../../design_system/typography"
import { SpacerValue, } from "../../design_system/types"
import { PaletteProps } from "../../design_system/palette"

const design: CalendarDesignProps = {
    menu: {
        background: 'white',
        verticalPadding: 8,
        horizontalPadding: 4,
    },
    dropdowns: {
        typography: `${Typography.P.styles}; letter-spacing: .7px`
    },
    weekDay: {
        typography: `font-family: ${Typography.family}; font-weight: 500; font-size: ${Typography.P.size * .85}px; letter-spacing: .6px`,
        color: 'gray4',

        topPadding: 8,
        bottomPadding: 0,
    },
    day: {
        typography: `font-family: ${Typography.family}; font-weight: ${Typography.P.weight}; font-size: ${Typography.P.size * .9}px`,
        color: 'gray4',

        borderRadius: 4,
        otherMonthColor: 'gray3',

        onHover: {
            background: 'gray2',
            borderColor: 'gray2'
        },
        onSelected: {
            background: 'primary2',
            borderColor: 'white'
        }
    },
    today: {
        background: 'gray1',
        borderColor: 'gray2'
    }
}

export const CalendarMenuStyles = createGlobalStyle<{ palette: PaletteProps }>`
${({ palette }) => css`
    div.flatpickr-calendar {
        background: ${palette[design.menu.background]};
        
        /// Top
        .flatpickr-months {
            padding-top: ${design.menu.verticalPadding}px;
            padding-left: ${design.menu.horizontalPadding}px;
            padding-right: ${design.menu.horizontalPadding}px;
            
            .flatpickr-next-month , .flatpickr-prev-month {
                display: none;
            }

            .numInputWrapper {
                input {
                    ${design.dropdowns.typography};
                }

                &:hover {
                    background-color: transparent;
                }
            }

            .flatpickr-monthDropdown-months {
                ${design.dropdowns.typography};
                
                margin-right: 16px;
                
                &:hover {
                    background-color: transparent;
                }
            }
        } 

        /// WeekDays
        .flatpickr-weekdays { 
            margin-top: ${design.weekDay.topPadding}px;
            margin-bottom: ${design.weekDay.bottomPadding}px;
            
            .flatpickr-weekdaycontainer { 
                padding-left: ${design.menu.horizontalPadding * 1.1}px;
                padding-right: ${design.menu.horizontalPadding * 1.1}px;
                
                .flatpickr-weekday {
                    ${design.weekDay.typography};
                    color: ${palette[design.weekDay.color]};
                }
            }
        }

        /// Days
        .dayContainer {
            padding-left: ${design.menu.horizontalPadding}px;
            padding-right: ${design.menu.horizontalPadding}px;
            padding-bottom: ${design.menu.verticalPadding}px;
            
            .flatpickr-day  {
                ${design.day.typography};

                color: ${palette[design.day.color]};
                border-radius: ${design.day.borderRadius}px;

                &.prevMonthDay, &.nextMonthDay  {
                    ${design.day.typography};
                    
                    color: ${palette[design.day.otherMonthColor]};
                }
                
                &.today {
                    background: ${palette[design.today.background]};
                    border-color: ${palette[design.today.borderColor]};
                }

                &:hover {
                    background: ${palette[design.day.onHover.background]};
                    border-color: ${palette[design.day.onHover.borderColor]};
                }

                &.selected {
                    color: white;
                    background: ${palette[design.day.onSelected.background]};
                    border-color: ${palette[design.day.onSelected.borderColor]};
                }
            }
        }

    }
`}
`


interface CalendarDesignProps {

    menu: {
        background: keyof PaletteProps
        verticalPadding: SpacerValue
        horizontalPadding: SpacerValue
    }

    dropdowns: {
        typography: string
    }

    weekDay: {
        typography: string
        color: keyof PaletteProps
        topPadding: SpacerValue
        bottomPadding: SpacerValue
    }

    day: {
        borderRadius: number
        typography: string
        color: keyof PaletteProps
        otherMonthColor: keyof PaletteProps

        onHover: {
            background: keyof PaletteProps
            borderColor: keyof PaletteProps
        }

        onSelected: {
            background: keyof PaletteProps
            borderColor: keyof PaletteProps
        }
    }

    today: {
        background: keyof PaletteProps
        borderColor: keyof PaletteProps
    }
}
