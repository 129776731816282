import { useSelector } from "react-redux"
import { Card, Col, MethodLogo, Radio, S, Spacer } from "../.."
import { REDUX_STATE } from "../../../redux"
import { PaymentMethods } from "../../../utilities"

interface CapabilityBadgeProps {
        method: number
        active?: boolean
        onClick?: () => void
}

export const CapabilityBadge = (props: CapabilityBadgeProps) => {

        const { method, active } = props
        const { palette } = useSelector(REDUX_STATE).theme
        const _method = PaymentMethods.find(x => x.value === method)

        const render = () =>
                <Card noPadding onClick={props.onClick} bg={palette.light}>
                        <Spacer size={12} h />

                        <Col justify="between" align="center" wrap="nowrap" px={0}>

                                <Col align="center" px={0}>
                                        <MethodLogo variant={method} />
                                        <Spacer size={8} w />
                                        <S medium nowrap>{_method?.display_name}</S>
                                </Col>

                                <Col justify="end" px={0} h="100%">
                                        <Radio checked={active} />
                                </Col>

                        </Col>

                        <Spacer size={12} h />
                </Card>

        return _method ? render() : <></>
}