import { Col, Container, Row } from "..";
import { BUYER_STATE, DISPUTE_STATE, HTTP_STATUS_CODE, PAYIN_STATE, PAYOUT_STATE, PSP, SELLER_STATE, UUID } from "../../utilities";
import { Filter } from "../atoms/Filter";


export const TableFilters = (props: { filters: { name: string, state: boolean, type: (PAYOUT_STATE | PAYIN_STATE | SELLER_STATE | BUYER_STATE | HTTP_STATUS_CODE | DISPUTE_STATE | PSP) }[], onClick: (type: number) => void }) => {

    let n = 0, id = UUID.create()
    const { filters, onClick } = props

    const render = () =>
        <Container>
            <Row gutterWidth={8} wrap='nowrap'>
                {
                    Object
                        .values(filters)
                        .map(({ name, state, type }) =>
                            <Col key={id + n++} xs='content'>
                                <Filter {...{ active: state, onClick: () => onClick(type) }}>{(name)}</Filter>
                            </Col>
                        )
                }
            </Row>
        </Container>

    return render()
}
