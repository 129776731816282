import React, { ReactNode, RefObject } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { PaletteProps, SpacerValue, Weight } from "../../design_system";
import { Typography, TypographyProps } from "../../design_system/typography";
import { REDUX_STATE } from "../../redux";


export interface TextProps {

  negative?: boolean
  isDark?: boolean

  id?: string
  variant?: 'dark' | 'primary' | 'secondary' | 'light' | 'error' | 'link'
  color?: string
  spacing?: string
  nowrap?: boolean
  wrap?: boolean
  ref?: RefObject<HTMLHeadingElement>
  href?: string
  targetBlank?: boolean

  thin?: boolean
  extraLight?: boolean
  light?: boolean
  regular?: boolean
  medium?: boolean
  semiBold?: boolean
  bold?: boolean
  extraBold?: boolean
  black?: boolean

  weight?: Weight
  uppercase?: boolean
  capitalize?: boolean

  center?: boolean
  left?: boolean
  right?: boolean

  m?: SpacerValue
  mx?: SpacerValue
  my?: SpacerValue
  mt?: SpacerValue
  mr?: SpacerValue
  mb?: SpacerValue
  ml?: SpacerValue

  hoverColor?: string

  children?: ReactNode
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

const shared = css<TextProps>`
  margin-bottom: 0px;
  margin-top: 0px;

  ${p => p.onClick && css` cursor: pointer; `}
  ${p => p.hoverColor && css` color: ${p.hoverColor}; `}
  ${p => p.uppercase && css` text-transform: uppercase; `}
  ${p => p.capitalize && css` text-transform: capitalize; `}

  &, a, span {
  
    color: ${p => p.color};
    
    ${p => p.wrap && css`
      display: flex;
      flex-wrap: nowrap !important;
      white-space: break-spaces;
    `}

    ${p => p.nowrap && css` 
      display: flex;
      align-items: center;
      flex-wrap: nowrap !important;
      white-space: nowrap;
    `}

    ${p => p.spacing && css` 
      letter-spacing: ${p.spacing};
    `}
  
    ${p => p.variant === 'link' && css`
      cursor: pointer;
      color: ${p.color}!important;
      text-decoration: none;
    `}
  }

  
  ${p =>
    p.center ? css`
      text-align: center;
      display: flex;
      justify-content: center;
  `:
      p.right ? css`
      text-align: right;
  `: css`
      text-align: left;
  `}

  ${p => p.thin && css`font-weight: 100;`}
  ${p => p.extraLight && css`font-weight: 200;`}
  ${p => p.light && css`font-weight: 300;`}
  ${p => p.regular && css`font-weight: 400;`}
  ${p => p.medium && css`font-weight: ${p.isDark ? 400 : 500};`}
  ${p => p.semiBold && css`font-weight: ${p.isDark ? 500 : 600};`}
  ${p => p.bold && css`font-weight: ${p.isDark ? 600 : 700};`}
  ${p => p.extraBold && css`font-weight: ${p.isDark ? 700 : 800};`}
  ${p => p.black && css`font-weight: ${p.isDark ? 800 : 900};`}
  ${p => p.weight && css`font-weight: ${p.weight};`}
  
  ${p => p.m !== undefined && css`margin:${p.m}px;`}
  ${p => p.mx !== undefined && css`margin-left:${p.mx}px;margin-right:${p.mx}px;`}
  ${p => p.my !== undefined && css`margin-top:${p.my}px;margin-bottom:${p.my}px;`}
  ${p => p.mt !== undefined && css`margin-top:${p.mt}px;`}
  ${p => p.mr !== undefined && css`margin-right:${p.mr}px;`}
  ${p => p.mb !== undefined && css`margin-bottom:${p.mb}px;`}
  ${p => p.ml !== undefined && css`margin-left:${p.ml}px;`}
`

export interface TextColorsProps {
  dark: keyof PaletteProps
  primary: keyof PaletteProps
  secondary: keyof PaletteProps
  light: keyof PaletteProps
  error: keyof PaletteProps
  link: keyof PaletteProps
}

export const TextColors: TextColorsProps = {
  dark: 'black',
  primary: 'gray5',
  secondary: 'gray4',
  light: 'gray3',
  error: 'error2',
  link: 'blue',
}

const Text = (props: { textProps: TextProps, variant: keyof TypographyProps }) => {
  const { theme } = useSelector(REDUX_STATE)
  var { textProps, variant } = props

  const palette = textProps.negative ? theme.negative : theme.palette

  const p: TextProps = {
    ...textProps,
    isDark: theme.isDark,
    color: textProps.color ? textProps.color : textProps.variant === undefined ? palette[TextColors.primary] : palette[TextColors[textProps.variant]],
    nowrap: variant === "S" || variant === "P" ? textProps.nowrap : textProps.nowrap ?? true,
    children: textProps.href === undefined ? textProps.children :
      <a href={textProps.href} target={textProps.targetBlank ? '_blank' : undefined}>
        {textProps.children}
      </a>
  }

  return variant === "H1" ? <H1Styled {...{ ...p }} /> :
    variant === "H2" ? <H2Styled {...{ ...p }} /> :
      variant === "H3" ? <H3Styled {...{ ...p }} /> :
        variant === "H4" ? <H4Styled {...{ ...p }} /> :
          variant === "H5" ? <H5Styled {...{ ...p }} /> :
            variant === "P" ? <PStyled {...{ ...p }} /> :
              variant === "S" ? <SStyled {...{ ...p }} /> : <XSStyled {...{ ...p }} />
}

export const H1 = (props: TextProps) => <Text textProps={props} variant="H1" />
export const H2 = (props: TextProps) => <Text textProps={props} variant="H2" />
export const H3 = (props: TextProps) => <Text textProps={props} variant="H3" />
export const H4 = (props: TextProps) => <Text textProps={props} variant="H4" />
export const H5 = (props: TextProps) => <Text textProps={props} variant="H5" />
export const P = (props: TextProps) => <Text textProps={props} variant="P" />
export const S = (props: TextProps) => <Text textProps={props} variant="S" />
export const XS = (props: TextProps) => <Text textProps={props} variant="XS" />

export const H1Styled = styled.h1<TextProps>`${Typography.H1.styles};font-weight:${Typography.H1.weight - (Typography.H1.weight > 300 ? 0 : 100)};${shared}`;
export const H2Styled = styled.h2<TextProps>`${Typography.H2.styles};font-weight:${Typography.H2.weight - (Typography.H2.weight > 300 ? 0 : 100)};${shared}`;
export const H3Styled = styled.h3<TextProps>`${Typography.H3.styles};font-weight:${Typography.H3.weight - (Typography.H3.weight > 300 ? 0 : 100)};${shared}`;
export const H4Styled = styled.h4<TextProps>`${Typography.H4.styles};font-weight:${Typography.H4.weight - (Typography.H4.weight > 300 ? 0 : 100)};${shared}`;
export const H5Styled = styled.h5<TextProps>`${Typography.H5.styles};font-weight:${Typography.H5.weight - (Typography.H5.weight > 300 ? 0 : 100)};${shared}`;
export const PStyled = styled.span<TextProps>`${Typography.P.styles};font-weight:${Typography.P.weight - (Typography.P.weight > 300 ? 0 : 100)};${shared}`;
export const SStyled = styled.span<TextProps>`${Typography.S.styles};font-weight:${Typography.S.weight - (Typography.S.weight > 300 ? 0 : 100)};${shared}`;
export const XSStyled = styled.span<TextProps>`${Typography.XS.styles};font-weight:${Typography.XS.weight - (Typography.XS.weight > 300 ? 0 : 100)};${shared}`;