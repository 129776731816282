import { useSelector } from "react-redux";
import { Card, CardTitle, Col, Donut, Row, S, Spacer } from "../..";
import { Percentage } from "../../../design_system";
import { REDUX_STATE } from "../../../redux";
import { PAYIN_STATE } from "../../../utilities";


export const FraudsRateChart = () => {

        const { payins } = useSelector(REDUX_STATE).analytics

        const disputes = payins.current.filter(x => x.state === PAYIN_STATE.DISPUTED || x.state === PAYIN_STATE.REFUNDED).length

        const currentFraud = payins.current.filter(x => x.isFraudolent).length
        const lastFraud = payins.last.filter(x => x.isFraudolent).length

        const currentFraudRate = (currentFraud > 0 ? (currentFraud / payins.current.length) * 100 : 0) as Percentage
        const lastFraudRate = (lastFraud > 0 ? (lastFraud / payins.last.length) * 100 : 0) as Percentage

        const render = () =>
                <Row h="100%">
                        <Col h="100%">
                                <Card h="100%">
                                        <CardTitle>Frauds Rate</CardTitle>
                                        <Spacer size={16} />
                                        <Donut maxValue={1} value={currentFraudRate} growth={(currentFraudRate - lastFraudRate) as Percentage} />
                                        <Col xs={12} bottom="0px" position="absolute" justify="between" zIndex={3}>
                                                <S medium>Disputes Rate</S>
                                                <S medium>{(disputes > 0 ? (disputes / payins.current.length) * 100 : 0).toFixed(2)}%</S>
                                        </Col>
                                </Card>
                        </Col>
                </Row>

        return render()
}

