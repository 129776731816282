import { useSelector } from "react-redux"
import { Card, CardDataCollection, CardHeaderData, CardTitle, Col, Row, Spacer } from "../.."
import { Payins } from "../../../fake_data"
import { REDUX_STATE } from "../../../redux"
import { BuyerProps, PayinProps, PaymentMethod, UUID } from "../../../utilities"

export const BuyerMethodsDetails = (buyer: BuyerProps) => {

    const { palette } = useSelector(REDUX_STATE).theme
    var methods: PayinProps[] = Array.from(new Set(Payins.filter(x => x.buyer.id === buyer.id)))

    const getDate = () => {
        let d1 = buyer.firstPayment
        let d2 = buyer.lastPayment
        return new Date(d1 + (d2 - d1) * Math.random())
    }

    const grouped = methods.reduce((result: {
        [key: number]: {
            method: PaymentMethod
            amount: number
            creation: Date
        }
    }, item) => {
        const { method, amount } = item
        result[method.value] = {
            method,
            amount: result[method.value]?.amount ?? 0 + amount,
            creation: getDate()
        };
        return result;
    }, {});


    let n = 1, id = UUID.create()

    const render = () =>
        <Card>
            <CardTitle>Payment Methods</CardTitle>
            <Spacer size={8} />

            <Row gutterHeight={24} mb={-16}>
                {
                    Object.values(grouped).slice(0, 3)
                        .map(x =>
                            <Col xs={12} key={id + n++} bb={n === 3 ? undefined : `1px solid ${palette.gray2}`}>
                                <CardHeaderData
                                    id={UUID.create()}
                                    title={
                                        <CardDataCollection data={[
                                            { type: "method-psp", data: x.method },
                                            { data: ["Creation", `${x.creation.toDateString()}`] },
                                            { data: ["Total Volume", `$${x.amount}`] }
                                        ]} />
                                    } />
                                <Spacer size={24} />
                            </Col>
                        )
                }
            </Row>
        </Card>

    return render()
}