import { ErrorCodesProps } from "./codes"

export const ErrorMessages: ErrorCodesProps = {
    server_error: "Internal server error.",
    processing_error: "Processing error.",

    empty_name: "Name field cannot be empty.",
    short_name: "Name must be at least 4 characters.",
    invalid_name: "Type a valid name.",

    empty_email: "Email field cannot be empty.",
    duplicate_email: "An account with the same email alredy exists.",
    invalid_email: "Type a valid email.",
    unexisting_email: "We don't know any account with this email.",

    empty_phone: "Phone field cannot be empty.",
    invalid_phone: "Type a valid phone.",

    unselected_country: 'Select a valid country.',
    empty_country: "Country field cannot be empty.",
    invalid_country: "Type a valid country.",

    empty_password: "Password field cannot be empty.",
    invalid_password: "Type a valid password.",

    empty_password_confirmation: "Password confirmation field cannot be empty.",
    password_mismatch: "Doesn't match with Password.",

    invalid_credentials: "Invalid credentials.",

    unaccepted_terms: "We need your terms acceptance.",
    invalid_booking_date: "Booking date must be in the future.",
    zero_people: "People must be greater than 0.",
    long_notes: "Notes must be less than 300 characters.",
    unselected_time: "Select a valid Time.",

    zero_seats: 'Imposta un numero maggiore di 0.',
    too_much_seats: 'Puoi impostare al massimo 300 coperti.',

    zero_max_per_table: 'Imposta un numero maggiore di 0.',
    too_much_max_per_table: 'Imposta un numero minore o uguale ai coperti in sala.',

    lunch_open_after_close: 'L\' orario di chiusura deve essere dopo quello di apertura.',
    dinner_open_after_close: 'L\' orario di chiusura deve essere dopo quello di apertura.',

    lunch_and_dinner_overlap: 'La chiusura di pranzo è in conflitto con l\' apertura serale.',

    empty_closing_type: 'Scegli un tipo di chiusura.',
    empty_closing_value: 'Scegli un giorno per la chiusura.',
    invalid_type: 'Choose a valid type.',
    invalid_month: 'Scegli un mese valido.',
    invalid_date: 'Scegli un giorno valido.',
    invalid_day: 'Scegli un giorno della settimana valido.',
    invalid_passed_day: 'Scegli un giorno che non sia passato.',
    empty_from: 'Scegli una data di inizio periodo.',
    empty_to: 'Scegli una data di fine periodo.'
}

export class ErrorMessage {

    static from(err?: string | undefined | null): string {

        if (err === null || err === undefined) return ''

        return ErrorMessages[err as keyof ErrorCodesProps] ?? ErrorMessages.server_error
    }

}

