
export enum BookingSpan {
    Lunch,
    Dinner
}

enum BookingStateIT {
    Confermato,
    Sospeso,
    Cancellato,
    Arrivato
}

export enum BookingState {
    Confirmed,
    Suspended,
    Cancelled,
    Arrived
}

export const BookingStates = {
    IT: BookingStateIT,
    EN: BookingState
}

export enum BookingTag {
    OneBabyChair,
    TwoBabyChairs,
    MoreBabyChairs,
    OneDisabled,
    TwoDisabled,
    MoreDisabled,
    Animals,
    OneCeliac,
    TwoCeliac,
    MoreCeliac
}

const BookingTagsIT = [
    'Un seggiolino',
    'Due seggiolini',
    'Più seggiolini',
    'Un disabile',
    'Due disabili',
    'Più disabili',
    'Animali',
    'Un celiaco',
    'Due celiaco',
    'Piu celiaci'
]

const BookingTagsEN = [
    'One baby chair',
    'Two baby chairs',
    'More baby chairs',
    'One disabled',
    'Two disabled',
    'More disabled',
    'Animals',
    'One celiac',
    'Two celiac',
    'More celiac'
]

export const BookingTags = {
    IT: BookingTagsIT,
    EN: BookingTagsEN
}