import { useSelector } from "react-redux"
import { Col, P, Spacer } from "../.."
import { SpacerValue } from "../../../design_system"
import { REDUX_STATE } from "../../../redux"
import { BUYER_STATE, BUYER_TYPE, BuyerState, BuyerType, DISPUTE_STATE, DisputeProps, DisputeState, DisputesLedgerRowProps, ONBOARDING_STATE, OnboardingState, PAYIN_STATE, PAYMENT_METHOD_STATE, PayinProps, PayinState, PayinsLedgerRowProps, PaymentMethodState, SELLER_STATE, SellerState } from "../../../utilities"

export interface BadgeProps {
        xl?: boolean
        children: string
        variant: 'default' | 'danger' | 'primary' | 'error' | 'success' | 'pending'
}


export const Badge = (props: BadgeProps) => {

        const { children, variant } = props
        const { isDark, palette } = useSelector(REDUX_STATE).theme

        const render = () =>
                <Col xs="content" b_rad="4px" bg={palette.gray2 + (isDark ? "" : "cc")}>
                        <Spacer h size={4} />

                        <Col wrap="nowrap" align="center">
                                <Spacer w size={10 as SpacerValue} />
                                {
                                        variant !== "default" &&
                                        <div style={{
                                                minWidth: '9px',
                                                maxWidth: '9px',
                                                minHeight: '9px',
                                                maxHeight: '9px',
                                                borderRadius: '50%',
                                                marginRight: '6px',
                                                background: variant === "danger" ? palette.danger2 :
                                                        variant === "primary" ? palette.primary2 :
                                                                variant === "error" ? palette.error2 :
                                                                        variant === "pending" ? palette.yellow2 :
                                                                                variant === "success" ? palette.success2 : undefined
                                        }} />
                                }
                                <P nowrap medium variant="primary">

                                        {
                                                children &&
                                                children[0].toUpperCase() + children.slice(1)
                                        }
                                </P>
                                <Spacer w size={10 as SpacerValue} />
                        </Col>

                        <Spacer h size={4} />
                </Col>

        return render()
}


export const BadgePropsForDispute: (dispute: DisputeProps | DisputesLedgerRowProps) => BadgeProps =
        (dispute) => {
                return {
                        variant: dispute.state ===
                                DISPUTE_STATE.LOST ? "error" : dispute.state ===
                                        DISPUTE_STATE.WON ? "success" : dispute.state ===
                                                DISPUTE_STATE.UNDER_REVIEW ? "primary" : dispute.state ===
                                                        DISPUTE_STATE.NEEDS_RESPONSE ? "danger" : "default",
                        children: DisputeState(dispute)
                }
        }

export const BadgePropsForPayin: (payin: PayinProps | PayinsLedgerRowProps) => BadgeProps =
        ({ state, dispute }) => {

                return state ===
                        PAYIN_STATE.DISPUTED && dispute ? BadgePropsForDispute(dispute) :
                        {
                                variant: state ===
                                        PAYIN_STATE.SUCCEEDED ? "success" : state ===
                                                PAYIN_STATE.FAILED ? "error" : state ===
                                                        PAYIN_STATE.PENDING ? "pending" : "default",
                                children: PayinState(state)
                        }
        }

export const BadgePropsForMethod: (state: PAYMENT_METHOD_STATE) => BadgeProps =
        (state) => {
                return {
                        variant: state ===
                                PAYMENT_METHOD_STATE.DISABLED ? "error" : state ===
                                        PAYMENT_METHOD_STATE.SUSPENDED ? "danger" : "success",
                        children: PaymentMethodState(state)
                }
        }

export const BadgePropsForSeller: (state: SELLER_STATE) => BadgeProps =
        (state) => {
                return {
                        variant: state === SELLER_STATE.LOCKED ? "error" : "success",
                        children: SellerState(state)
                }
        }

export const BadgePropsForBuyer: (state: BUYER_STATE) => BadgeProps =
        (state) => {
                return {
                        variant: state === BUYER_STATE.LOCKED ? "error" : "success",
                        children: BuyerState(state)
                }
        }

export const BadgePropsForSellerOnboarding: (state: ONBOARDING_STATE) => BadgeProps =
        (state) => {
                return {
                        variant: state ===
                                ONBOARDING_STATE.TO_ONBOARD ? "default" : state ===
                                        ONBOARDING_STATE.VERIFIED ? "success" : state ===
                                                ONBOARDING_STATE.PENDING ? "pending" : "error",
                        children: OnboardingState(state)
                }
        }

export const BadgePropsForBuyerType: (state: BUYER_TYPE) => BadgeProps =
        (state) => {
                return {
                        variant: "default",
                        children: BuyerType(state)
                }
        }

