import { ReactNode, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { Col, Icon, S, Spacer } from ".."
import { PaletteProps } from "../../design_system"
import { Typography } from "../../design_system/typography"
import { REDUX_STATE } from "../../redux"


export const Copyable = (props: { children: ReactNode, value: string }) => {

        const { palette } = useSelector(REDUX_STATE).theme
        const [classname, setClassname] = useState("")

        const Render = () =>
                <CopyableStyled palette={palette} className={classname}>
                        <Col xs="content" wrap="nowrap" onClick={OnClick} pointer>
                                <CopyableText>
                                        <Col xs="content" wrap="nowrap" position="relative">
                                                {props.children}
                                                <Spacer w size={8} />
                                        </Col>
                                </CopyableText>
                                <Tooltip>
                                        <S medium>Copied</S>
                                </Tooltip>
                                <Icon variant="copy" />
                        </Col>
                </CopyableStyled>

        const OnClick = () => {
                navigator.clipboard.writeText(props.value)
                setClassname("copying")
                window.setTimeout(() => setClassname(""), 800)
        }

        return Render()
}

const Tooltip = styled.div``
const CopyableText = styled.div``
const CopyableStyled = styled.div<{ palette: PaletteProps }>`

        ${Tooltip}{
                opacity: 0;
                transition: opacity .4s ease-out
        }   
        
        ${CopyableText}{
                opacity: 1;
                transition: opacity .8s ease-out
        }   
        
        &.copying {
                ${Tooltip}{
                        opacity: 1;
                        transition: opacity .3s ease-out
                }   

                ${CopyableText}{
                        opacity: 0;
                        transition: opacity .4s ease-out;
                }   
        }

        ${Tooltip} {
                        opacity: 0;
                ${Typography.S.styles}
                top: 0;
                bottom:0;
                z-index: 1;
                width: 100%;
                height: 100%;
                display: flex;
                position: absolute;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                background-color: transparent;    
        }
`
