import { ApiLogDetails, DateTime, HTTP_METHOD, HTTP_STATUS_CODE, SellerState, UUID } from ".."
import { ApiLogs, Sellers } from "../../fake_data"
import { ApiLogsData } from "../../redux"
import { ApiRoutes } from "../../routes"


export enum API_LOGS_TABLE_COLS {
    STATUS,
    METHOD,
    ENDPOINT,
    DATE
}

export interface ApiLogsOrder {
    by: API_LOGS_TABLE_COLS,
    ascendent: boolean
}

export interface ApiLogsFilter {
    state: boolean
    type: HTTP_STATUS_CODE
}


export interface ApiLogsRowProps {
    id: string
    status: HTTP_STATUS_CODE
    method: HTTP_METHOD
    endpoint: string
    date: Date
    time: string
    idempotency: string
    apiVersion: string
    origin: string
}

export class ApiLogsEngine {

    static getValues(state: ApiLogsData): ApiLogsRowProps[] {
        const rows = this.filterValues(state.apiLogs, state.filters)
        const reoredered = this.getOrderedValues(rows, state.order)
        return reoredered
    }

    static filterValues(apiLogs: ApiLogsRowProps[], applied: ApiLogsFilter[]) {
        let filtered: ApiLogsRowProps[] = []
        applied.some(x => x.type === HTTP_STATUS_CODE.OK && x.state) && (filtered = filtered.concat(apiLogs.filter(x => x.status === HTTP_STATUS_CODE.OK)))
        applied.some(x => x.type === HTTP_STATUS_CODE.BAD_REQUEST && x.state) && (filtered = filtered.concat(apiLogs.filter(x => x.status === HTTP_STATUS_CODE.BAD_REQUEST)))
        applied.some(x => x.type === HTTP_STATUS_CODE.NOT_FOUND && x.state) && (filtered = filtered.concat(apiLogs.filter(x => x.status === HTTP_STATUS_CODE.NOT_FOUND)))
        applied.some(x => x.type === HTTP_STATUS_CODE.SERVER_ERROR && x.state) && (filtered = filtered.concat(apiLogs.filter(x => x.status === HTTP_STATUS_CODE.SERVER_ERROR)))
        applied.some(x => x.type === HTTP_STATUS_CODE.UNAUTHORIZED && x.state) && (filtered = filtered.concat(apiLogs.filter(x => x.status === HTTP_STATUS_CODE.UNAUTHORIZED)))
        return filtered
    }

    static getOrderedValues(rows: ApiLogsRowProps[], order: ApiLogsOrder): ApiLogsRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {

            case API_LOGS_TABLE_COLS.ENDPOINT:
                result = result.sort((a, b) => a.endpoint < b.endpoint ? factor * -1 : factor * 1)
                break

            case API_LOGS_TABLE_COLS.METHOD:
                result = result.sort((a, b) => a.method < b.method ? factor * -1 : factor * 1)
                break

            case API_LOGS_TABLE_COLS.STATUS:
                result = result.sort((a, b) => a.status < b.status ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => a.date < b.date ? factor * -1 : factor * 1)
                break
        }
        return result
    }

    static getRows(period: number): ApiLogsRowProps[] {

        const start = DateTime.subtractMoths(period)
        const apiLogs = ApiLogs
            .filter(x =>
                x.date >= start.getTime() &&
                x.date <= new Date().getTime()
            )
            .sort((a, b) =>
                a.date - b.date
            )

        var result: ApiLogsRowProps[] = apiLogs.map(s => {

            return {
                id: s.id,
                status: s.status,
                method: s.method,
                endpoint: s.endpoint,
                date: new Date(s.date),
                time: s.time,
                apiVersion: s.apiVersion,
                origin: s.origin,
                idempotency: s.idempotency
            }
        })


        return result
    }

    static getLog(log: ApiLogsRowProps): ApiLogDetails {
        var n = Math.round(104 / (Math.random() * 100))
        var seller = Sellers[n]
        const id = UUID.create()
        const id2 = UUID.create()
        const amount = Math.round(Math.random() * 100).toFixed(2)
        return log.endpoint ===
            ApiRoutes.CREATE_SELLER ? {
            query: '',
            request: [
                '{',
                `"object" : "seller",`,
                `"created" : ${log.date.getTime()},`,
                `"email" : "${seller.email}",`,
                `"country" : "${seller.country}"`,
                '}'
            ],
            response: [
                '{',
                `"id" : "${id2}",`,
                `"account" : "acct_${id}",`,
                `"onboard_url" : "https://enterbios.com/sellers",`,
                '}'
            ]
        } : log.endpoint ===

            ApiRoutes.UPDATE_SELLER ? {
            query: `?id=acct_${seller.id}`,
            request: [
                '{',
                `"object" : "seller",`,
                `"created" : ${log.date.getTime()},`,
                `"state" : "${SellerState(seller.state)}",`,
                '}'
            ],
            response: [
                '{',
                `"id" : "${id2}",`,
                `"account" : "acct_${id}",`,
                `"state" : "${SellerState(seller.state)}",`,
                '}'
            ]
        } : log.endpoint ===

            ApiRoutes.GET_SELLER ? {
            query: `acct_${id}`,
            request: ['{', '', '}'],
            response: [
                '{',
                `"id" : "${id2}",`,
                `"account" : "acct_${id}",`,
                `"email" : "${seller.email}",`,
                `"country" : "${seller.country}"`,
                '}'
            ]
        } : log.endpoint ===

            ApiRoutes.CREATE_PAYIN ? {
            query: '',
            request: [
                '{',
                `"object" : "payin",`,
                `"created" : ${log.date.getTime()},`,
                `"amount" : ${amount},`,
                `"seller" : "acct_${id}",`,
                `"currency" : "USD",`,
                '}'
            ],
            response: [
                '{',
                `"id" : "${UUID.create()}",`,
                `"session" : "ps_${id}",`,
                `"amount" : ${amount},`,
                `"seller" : "acct_${id}",`,
                `"currency" : "USD",`,
                '}'
            ]
        } : log.endpoint ===

            ApiRoutes.UPDATE_PAYIN ? {
            query: `?id=pay_${id}`,
            request: [
                '{',
                `"object" : "payin",`,
                `"created" : ${log.date.getTime()},`,
                `"amount" : "${(amount + 20)}",`,
                '}'
            ],
            response: [
                '{',
                `"id" : "${UUID.create()}",`,
                `"session" : "pay_${id}",`,
                `"amount" : ${amount + 20},`,
                `"seller" : "acct_${id}",`,
                `"currency" : "USD",`,
                '}'
            ]
        } :
            {
                query: `pay_${id}`,
                request: ['{', '', '}'],
                response: [
                    '{',
                    `"id" : "${UUID.create()}",`,
                    `"session" : "ps_${id}",`,
                    `"amount" : ${amount},`,
                    `"seller" : "acct_${id}",`,
                    `"currency" : "USD",`,
                    '}'
                ]

            }

    }
}
