

export interface PaletteProps {
    blue: string
    success2: string
    success1: string
    error2: string
    error1: string
    primary2: string
    primary1: string
    danger2: string
    danger1: string
    yellow2: string
    yellow1: string
    black: string
    gray5: string
    gray4: string
    gray3: string
    gray2: string
    gray1: string
    light: string
    white: string
    transparent: string
    unset: string
}


export const LightPalette: PaletteProps = {
    blue: '#4263EB',
    success2: '#006908',
    success1: '#D7F7C2',
    error2: '#F03D3E',
    error1: '#FBDDDE',
    primary2: '#4263EB',
    primary1: '#CDD6FF',
    danger2: '#FFA900',
    danger1: '#FFECC7',
    yellow2: "#FFF803",
    yellow1: "#FFFED9",
    
    black: '#121417',
    gray5: '#555D67',
    gray4: '#777F89',
    gray3: '#A2AAB4',
    gray2: '#E8EDF0',
    gray1: '#F5F7F8',
    light: '#FFFFFF',
    white: '#FFFFFF',

    transparent: 'transparent',
    unset: 'unset'
}

export const DarkPalette: PaletteProps = {
    blue: '#4263EB',
    success2: '#006908',
    success1: '#D7F7C2',
    error2: '#F03D3E',
    error1: '#F0D4D5',
    primary2: '#4263EB',
    primary1: '#CDD6FF',
    danger2: '#FFA900',
    danger1: '#FFECC7',
    yellow2: "#FFF803",
    yellow1: "#FFFED9",
    
    black: '#F5F5F5',
    gray5: '#777F89',
    gray4: '#777F89',
    gray3: '#A2AAB4',
    gray2: '#262A30',
    gray1: '#121417',
    light: '#262A30',
    white: '#1B1F23',

    transparent: 'transparent',
    unset: 'unset'
}