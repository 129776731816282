import { Container as _Container, ContainerProps as _ContainerProps } from 'react-grid-system';
import styled from 'styled-components';
import { Overflow, Position, SpacerValue } from '../../../design_system';
import { Layout } from '../../../design_system/layout';

export interface ContainerProps extends _ContainerProps {
    debug?: boolean

    overflow?: Overflow
    overflowX?: Overflow
    overflowY?: Overflow

    position?: Position
    left?: string
    right?: string
    top?: string
    bottom?: string

    bg?: string

    min_w?: string
    w?: string
    max_w?: string

    min_h?: string
    h?: string
    max_h?: string

    b?: string
    bx?: string
    by?: string
    bl?: string
    br?: string
    bt?: string
    bb?: string
    b_rad?: string

    p?: SpacerValue
    px?: SpacerValue
    py?: SpacerValue
    pl?: SpacerValue
    pr?: SpacerValue
    pt?: SpacerValue
    pb?: SpacerValue

    m?: SpacerValue
    mx?: SpacerValue
    my?: SpacerValue
    ml?: SpacerValue
    mr?: SpacerValue
    mt?: SpacerValue
    mb?: SpacerValue
}

export const Container = (props: ContainerProps) => {

    var style: React.CSSProperties = props.style ?? {}

    // Default
    style.width = style.width ? style.width : '100%'
    style.maxWidth = style.maxWidth ? style.maxWidth : 'unset'
    style.paddingRight = '0px'
    style.paddingLeft = '0px'
    style.paddingTop = '0px'
    style.paddingBottom = '0px'
    style.marginRight = style.marginRight ? style.marginRight : '0px'
    style.marginLeft = style.marginLeft ? style.marginLeft : '0px'

    props.overflow && (style.overflow = props.overflow)
    props.overflowX && (style.overflowX = props.overflowX)
    props.overflowY && (style.overflowY = props.overflowY)

    props.position && (style.position = props.position)
    props.left && (style.left = props.left)
    props.right && (style.right = props.right)
    props.top && (style.top = props.top)
    props.bottom && (style.bottom = props.bottom)

    props.bg && (style.background = props.bg)

    props.min_w !== undefined && (style.minWidth = props.min_w)
    props.w !== undefined && (style.width = props.w)
    props.max_w !== undefined && (style.maxWidth = props.max_w)

    props.min_h !== undefined && (style.minHeight = props.min_h)
    props.h !== undefined && (style.height = props.h)
    props.max_h !== undefined && (style.maxHeight = props.max_h)

    props.b && (style.border = props.b)
    props.bx && (style.borderLeft = props.bx) && (style.borderRight = props.bx)
    props.by && (style.borderTop = props.by) && (style.borderBottom = props.by)
    props.bt && (style.borderTop = props.bt)
    props.bb && (style.borderBottom = props.bb)
    props.bl && (style.borderLeft = props.bl)
    props.br && (style.borderRight = props.br)
    props.b_rad && (style.borderRadius = props.b_rad)

    props.p !== undefined && (style.paddingLeft = `${props.p}px`) && (style.paddingRight = `${props.p}px`) && (style.paddingTop = `${props.p}px`) && (style.paddingBottom = `${props.p}px`)
    props.px !== undefined && (style.paddingLeft = `${props.px}px`) && (style.paddingRight = `${props.px}px`)
    props.py !== undefined && (style.paddingTop = `${props.py}px`) && (style.paddingBottom = `${props.py}px`)
    props.pt !== undefined && (style.paddingTop = `${props.pt}px`)
    props.pb !== undefined && (style.paddingBottom = `${props.pb}px`)
    props.pl !== undefined && (style.paddingLeft = `${props.pl}px`)
    props.pr !== undefined && (style.paddingRight = `${props.pr}px`)

    props.m !== undefined && (style.margin = `${props.m}px`)
    props.mx !== undefined && (style.marginLeft = `${props.mx}px`) && (style.marginRight = `${props.mx}px`)
    props.my !== undefined && (style.marginTop = `${props.my}px`) && (style.marginBottom = `${props.my}px`)
    props.mt !== undefined && (style.marginTop = `${props.mt}px`)
    props.mb !== undefined && (style.marginBottom = `${props.mb}px`)
    props.ml !== undefined && (style.marginLeft = `${props.ml}px`)
    props.mr !== undefined && (style.marginRight = `${props.mr}px`)

    const render = () =>
        <ContainerStyled
            id={props.id}
            style={style}
            debug={props.debug}
            fluid={props.fluid}
            onBlur={props.onBlur}
            onLoad={props.onLoad}
            onClick={props.onClick}
            className={props.className}
            onMouseOut={props.onMouseOut}
            onMouseOver={props.onMouseOver}>
            {props.children}
        </ContainerStyled>

    return render()
}

const ContainerStyled = styled(_Container) <{ debug?: boolean }>`

    @media (min-width: ${Layout.breakpoints.xs}px) {
        max-width: 690px;
    }
 
    @media (min-width: ${Layout.breakpoints.lg}px) {
        max-width: 1000px;
    }
    
    @media (min-width: ${Layout.breakpoints.xl}px) {
        max-width: 1350px;
    }

`
