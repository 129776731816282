import { Button, ButtonGroup, Container, H5, P, Row } from ".."


export interface TablePaginationProps {
    pages: number
    items: number
    results: number
    current: number
    setCurrent: (current: number) => void
}

export const TablePagination = (props: TablePaginationProps) => {

    var { pages, items, results, current, setCurrent } = props

    current = current < 0 ? 0 : current >= pages ? pages : current

    const render = () =>
        <Container>
            {
                current !== pages &&
                <Row justify='between' align='end'>
                    <H5 mb={0} nowrap>{results < items && `${current * results}-${Math.min(current * results + results, items)} of ${items} results`}</H5><br />
                    <ButtonGroup>
                        <Button variant="alternate" onClick={() => setCurrent(current === 0 ? 0 : current - 1)} disabled={current === 0}>Prev</Button>
                        <Button variant="alternate" onClick={() => setCurrent((current + 1) > pages ? pages : current + 1)} disabled={current === pages}>Next</Button>
                    </ButtonGroup>
                </Row>
            }
        </Container>

    return render()
}
