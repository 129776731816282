import { Card, SellersTableHead, SellersTableRow } from "../.."
import { SellersLedgerRowProps, UUID } from "../../../utilities"

export const SellersTable = (rows: SellersLedgerRowProps[]) => {

    let n = 0, id = UUID.create()

    const render = () =>
        <Card noPadding>
            <SellersTableHead />
            {
                Object.values(rows).map(row =>
                    <SellersTableRow key={id + n++} {...row} />
                )
            }
        </Card>

    return render()
}