import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, BadgePropsForPayin, CardHorizzontalSpacer, Col, DescriptionBlock, DisputeStateInfo, Icon, ModalPage, P, PaymentDetails, PaymentMethodDetails, Spacer } from "../.."
import { Payins } from "../../../fake_data"
import { REDUX_STATE, payinsLedgerActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { PAYINS_LEDGER_COLS, PayinsLedgerRowProps, UUID } from "../../../utilities"


export const PayinsTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={head ? undefined : `1px solid ${palette.gray2}`} pointer>
            {!head && <Spacer size={12} />}
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="8%" max_w="8%">{children[0]}</Col>
                <Col w="15%" max_w="15%">{children[1]}</Col>
                <Col w="17%" max_w="17%">{children[2]}</Col>
                <Col w="24%" max_w="24%">{children[3]}</Col>
                <Col w="24%" max_w="24%">{children[4]}</Col>
                <Col w="12%" max_w="12%">{children[5]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            {!head && <Spacer size={12} />}
        </Col>

    return render()
}

export const PayinsTableHead = () => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { orderData } = payinsLedgerActions
    const { theme: { palette }, payins: { order } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Amount", col: PAYINS_LEDGER_COLS.AMOUNT },
        { name: "State", col: PAYINS_LEDGER_COLS.STATUS },
        { name: "Gateway", col: PAYINS_LEDGER_COLS.GATEWAY },
        { name: "Seller", col: PAYINS_LEDGER_COLS.SELLER },
        { name: "Buyer", col: PAYINS_LEDGER_COLS.BUYER },
        { name: "Date", col: PAYINS_LEDGER_COLS.DATE },
    ]

    const render = () =>
        <PayinsTableRowLayout head>
            {
                cols.map(x =>
                    <Col key={id + n++} py={12} pointer wrap="nowrap" align="center" onClick={() => dispatch(orderData(x.col as PAYINS_LEDGER_COLS))}>
                        <P mb={2} medium variant="secondary"> {x.name} </P>
                        <Icon pointer color={palette.black} height={18} variant={`ordinament${order.by !== x.col ? "" : order.ascendent ? "-asc" : "-des"}`} />
                    </Col>
                )
            }
        </PayinsTableRowLayout>

    return render()
}

export const PayinsTableRow = (props: PayinsLedgerRowProps) => {

    const { id, amount, method, seller, buyer, date } = props

    const render = () =>
        <PayinsTableRowLayout>
            <P medium variant="dark">${amount}</P>
            <Badge {...BadgePropsForPayin(props)} />
            <DescriptionBlock data={method} type="psp" />
            <P regular variant="dark">{seller}</P>
            <P regular variant="dark">{buyer}</P>
            <P regular variant="dark">{date}</P>
        </PayinsTableRowLayout >

    const payin = Payins.find(x => x.id === id)

    return payin ?
        <ModalPage href={DemoRoutes.ledger.payinDetails + id}>
            {render()}
            <Col>
                <PaymentDetails {...payin} />
                {
                    payin.dispute && <>
                        <Spacer size={32} />
                        <DisputeStateInfo {...{ ...payin.dispute, amount: payin.amount }} />
                    </>
                }
                <Spacer size={32} />
                <PaymentMethodDetails {...{ method: payin.method, buyer: payin.buyer }} />
            </Col>
        </ModalPage>
        :
        render()
}
