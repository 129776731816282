import { createRef } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { Col, ColProps, Container, Icon, PageHorizzontalSpacer } from ".."
import { PaletteProps } from "../../design_system/palette"
import { Typography } from "../../design_system/typography"
import { FontWeight, SpacerValue } from "../../design_system/types"
import { REDUX_STATE } from "../../redux"

export interface BaseInputProps {
    xs?: boolean
    name?: string
    error?: boolean
    success?: boolean
    disabled?: boolean
    autoFocus?: boolean
    placeholder?: string
    defaultValue?: string | number
    style?: React.CSSProperties
}

export interface TextInputProps extends BaseInputProps {
    onClick?: () => void
    onChange?: (val: string) => void
    type: 'text' | 'password' | 'search'
    onKeyDown?: (val: React.KeyboardEvent<HTMLInputElement>) => void
}

export interface EditableInputProps extends BaseInputProps {
    type: 'editable'
    onChange?: undefined
    onClick?: () => void
    onKeyDown?: undefined
}

export interface NumberInputProps extends BaseInputProps {
    type: 'number'
    minValue?: number
    step?: number
    maxValue?: number
    onClick?: () => void
    onChange?: (val: number) => void
    onKeyDown?: (val: React.KeyboardEvent<HTMLInputElement>) => void
}

export const Input = (props: TextInputProps | NumberInputProps | EditableInputProps) => {

    const { palette } = useSelector(REDUX_STATE).theme

    const ref = createRef<HTMLInputElement>()

    const { name, error, success, disabled, autoFocus, type, placeholder, defaultValue, onClick, onChange, onKeyDown, style, xs } = props

    const Render = () => <Container>
        <InputStyled
            palette={palette}
            xs={xs}
            ref={ref}
            name={name}
            error={error}
            disabled={disabled}
            autoFocus={autoFocus}
            placeholder={placeholder ?? type === "search" ? "Search..." : ""}
            defaultValue={defaultValue}
            readOnly={type === "editable"}
            type={type === "password" ? "password" : "text"}
            onChange={OnChangeHandler}
            onClick={onClick}
            onKeyDown={OnKeyDown}
            search={type === "search"}
            style={style}
        />

        {
            type === "search" && !disabled &&
            <Col xs='content' position="absolute" top='0px' left="0px" h="100%" align="center">
                <PageHorizzontalSpacer />
                <Icon width={15} variant="search" onClick={() => ref.current?.click()} color={palette.gray3} />
            </Col>
        }

        <Col xs='content' position="absolute" top='0px' right={`${InputDesign.horizontalPadding + InputDesign.borderWidth}px`} h="100%" align="center">
            {
                // type === "number" &&
                // <Col xs='content' h='100%' wrap="wrap" align="center" right={`-${InputDesign.horizontalPadding}px`}                >
                //     <NumberInputControls palette={palette} onClick={PlusOne}
                //         style={{
                //             borderTopRightRadius: `${InputDesign.borderRadius - 1}px`,
                //             marginTop: `${InputDesign.borderWidth}px`
                //         }}>
                //         <Icon width={10} variant="close" rotate={45} pointer onClick={() => ref.current?.click()} color={palette.gray4} />
                //     </NumberInputControls>

                //     <NumberInputControls palette={palette} onClick={MinusOne}
                //         style={{
                //             borderBottomRightRadius: `${InputDesign.borderRadius - 1}px`,
                //             marginBottom: `${InputDesign.borderWidth}px`
                //         }}>
                //         <Icon width={9} variant="hypen" pointer onClick={() => ref.current?.click()} color={palette.gray4} />
                //     </NumberInputControls>

                // </Col>

                // ||
                (error || success) && !disabled &&
                <Icon width={20} onClick={() => ref.current?.focus()} variant={error ? 'danger' : 'success'} color={error ? palette.error2 : palette.success2} />

                ||
                type === "editable" && !disabled &&
                <Icon width={24} variant="edit" pointer onClick={() => ref.current?.click()} color={palette.primary2} />

            }
        </Col>
    </Container>

    const OnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === 'number') {
            if (e.key === 'ArrowUp') PlusOne()
            else if (e.key === 'ArrowDown') MinusOne()
        }
        onKeyDown && onKeyDown(e)
    }

    const PlusOne = () => SetNumberInput(props.type === "number" && props.step !== undefined ? props.step : 0.01)
    const MinusOne = () => SetNumberInput(-1 * (props.type === "number" && props.step !== undefined ? props.step : 0.01))
    const SetNumberInput = (n: number) => {
        if (type === 'number') {
            OnChange(
                (Number(ref.current?.value) + n).toFixed(countDecimals(props.step ?? 0.01))
            )
        }
    }

    const OnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => OnChange(e.currentTarget.value)

    const OnChange = (value: string) => {

        if (type !== 'number') {
            onChange && onChange(value)
            return
        }

        let { minValue, maxValue } = props
        let step = props.step ?? 0.01

        let arr = value.split('.')
        let strValue = `${arr[0]}${arr.length > 1 || value.endsWith('.') ? "." : ""}${arr[1] ? arr[1].substring(0, countDecimals(step)) : ""} `
        let numValue = Number(strValue)

        let cleaned = (!isNaN(numValue) && numValue < 0) || value.startsWith('-') ? '-' : ''
        for (let i = 0; i < strValue.length; i++) {
            const isNum = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].some(char => char === strValue[i])

            if (isNum || (strValue[i] === "." && !cleaned.includes('.'))) {
                cleaned += strValue[i]
            }
        }

        if (minValue !== undefined && Number(cleaned) < minValue) {
            cleaned = minValue.toFixed()
        }

        if (maxValue !== undefined && Number(cleaned) > maxValue) {
            cleaned = maxValue.toFixed()
        }

        if (ref.current) {
            ref.current.value = cleaned
            if (type === 'number') {
                onChange && onChange(Number(cleaned))
            }
        }
    }

    return Render()
}

function countDecimals(n: number) {
    if (Math.floor(n) === n) return 0
    return n.toString().split('.')[1].length || 0
}

export const InputDesign: InputDesignProps = {
    height: {
        default: 36,
        xs: 28,
    },
    transition: '.1s all ease-out',

    verticalPadding: 0,
    horizontalPadding: 16,

    typography: {
        default: Typography.H5.styles,
        xs: Typography.P.styles
    },
    color: 'gray4',
    background: 'white',
    placeholderColor: 'gray3',

    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: 'gray2',

    outlineWidth: 1,
    outlineStyle: 'solid',
    outlineColor: 'transparent',

    onHover: {
        borderColor: 'gray2'
    },

    onFocus: {
        shadow: (p: PaletteProps) => `0px 0px 0px .6px ${p.primary2} 4D`,
        borderColor: 'primary2',
        outlineColor: 'transparent'
    },

    onError: {
        borderColor: 'error2',

        onHover: {
            shadow: (p: PaletteProps) => 'unset',
            borderColor: 'error2'
        },

        onFocus: {
            shadow: (p: PaletteProps) => `0px 0px 0px .6px ${p.error2} 4D`,
            borderColor: 'error2'
        }
    },

    onDisabled: {
        color: 'gray2',
        outlineColor: 'transparent',
        borderColor: 'gray3',
        background: 'gray2'
    }
}


interface NumberInputControlsProps extends ColProps { palette: PaletteProps }
const NumberInputControls = styled((p: NumberInputControlsProps) => Col({ ...p, px: 8, align: "center" })) <NumberInputControlsProps>`
${({ palette }) => css`
    cursor: pointer;
    height: ${`calc(50% - ${InputDesign.borderWidth}px)`};
    background-color: ${palette.gray1};

    &:hover {
        background-color: ${palette.gray2};
    }   
`}
        `

export const InputStyled = styled.input<{ palette: PaletteProps, error?: boolean, search?: boolean, xs?: boolean }>`
${({ palette, error, readOnly, search, xs }) => css`
    ${InputDesign.typography[xs ? "xs" : "default"]}

    width: 100%;
    height: ${InputDesign.height[xs ? "xs" : "default"]}px;
    transition: ${InputDesign.transition};
    padding: 0px ${InputDesign.horizontalPadding}px;

    color: ${palette[InputDesign.color]};
    background: ${palette[InputDesign.background]};

    border-width: ${InputDesign.borderWidth}px;
    border-style: ${InputDesign.borderStyle};
    border-color: ${palette[InputDesign.borderColor]};
    border-radius: ${InputDesign.borderRadius}px;

    outline-width: ${InputDesign.outlineWidth}px;
    outline-style: ${InputDesign.outlineStyle};
    outline-color: ${palette[InputDesign.outlineColor]};

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-weight: 300;
        color: ${palette[InputDesign.placeholderColor]};
    }

    &:-ms-input-placeholder,
    &::-ms-input-placeholder { /* Microsoft Edge *//* Internet Explorer 10-11 */
        opacity: 1; /* Firefox */
        font-weight: 300;
        color: ${palette[InputDesign.placeholderColor]};
    }

    &:hover {
        border-color: ${palette[InputDesign.onHover.borderColor]};   
    }

    &:focus {
        box-shadow: ${InputDesign.onFocus.shadow(palette)};
        border-color: ${palette[InputDesign.onFocus.borderColor]};
        outline-color: ${palette[InputDesign.onFocus.outlineColor]};
    }

    ${search && css`
        height: 25px;
        max-height: 25px;
        padding-left: 48px;
        background-color: transparent!important;
        font-weight: ${FontWeight.medium};
        
        &::placeholder {
            font-weight: ${FontWeight.medium};
        }

        &:-ms-input-placeholder,
        &::-ms-input-placeholder { 
            font-weight: ${FontWeight.medium};
        }

        &, :hover, :focus {
            border-color: transparent;
            outline-color: transparent;
            box-shadow: unset;
        }
    `}

    &:disabled {
        pointer-events: none;
        color: ${palette[InputDesign.onDisabled.color]};
        background: ${palette[InputDesign.onDisabled.background]}4D;
        border-color: ${palette[InputDesign.onDisabled.borderColor]}80;
        outline-color: ${palette[InputDesign.onDisabled.outlineColor]};
    }
  
    ${error && css`
        padding-right: ${InputDesign.horizontalPadding * 2 + 20}px;
        border-color: ${palette[InputDesign.onError.borderColor]}80;

        &:hover {
            border-color: ${palette[InputDesign.onError.onHover.borderColor]}80;   
            box-shadow: ${InputDesign.onError.onHover.shadow(palette)};   
        }

        &:focus {
            border-color: ${palette[InputDesign.onError.onFocus.borderColor]}80;
            box-shadow: ${InputDesign.onError.onFocus.shadow(palette)};   
        }
    `}

    ${readOnly && css`
        cursor: pointer;
        border-color: ${palette[InputDesign.borderColor]};

        &:hover {
            border-color: ${palette[InputDesign.borderColor]};   
        }

        &:focus {
            box-shadow: unset;   
            border-color: ${palette[InputDesign.borderColor]};
        }
    `}
`}
        `



export interface InputDesignProps {
    height: {
        default: number
        xs: number
    }
    typography: {
        default: string
        xs: string
    }
    transition: string

    color: keyof PaletteProps
    background: keyof PaletteProps
    placeholderColor: keyof PaletteProps

    verticalPadding: SpacerValue
    horizontalPadding: SpacerValue

    borderWidth: number
    borderStyle: string
    borderColor: keyof PaletteProps
    borderRadius: number

    outlineWidth: number
    outlineStyle: string
    outlineColor: keyof PaletteProps


    onHover: {
        borderColor: keyof PaletteProps
    }

    onFocus: {
        shadow: (palette: PaletteProps) => string
        borderColor: keyof PaletteProps
        outlineColor: keyof PaletteProps
    }

    onError: {
        borderColor: keyof PaletteProps

        onHover: {
            shadow: (palette: PaletteProps) => string
            borderColor: keyof PaletteProps
        }

        onFocus: {
            shadow: (palette: PaletteProps) => string
            borderColor: keyof PaletteProps
        }
    }

    onDisabled: {
        color: keyof PaletteProps
        background: keyof PaletteProps
        borderColor: keyof PaletteProps
        outlineColor: keyof PaletteProps
    }
}
