import { all } from "redux-saga/effects"
import { systemWatcherSaga } from "./system/watcher"

export const LOAD_ANALYTICS_DATA = 'LOAD_ANALYTICS_DATA'

export function* rootSaga() {
    yield all([
        systemWatcherSaga()
    ])
}
