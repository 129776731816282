export interface DevicesProps {
    desktopMacs: string
    desktopWindows: string
    tabletIPad: string
    tabledAndroid: string
    mobileIPhone: string
    mobileAndroid: string
    mobileWindows: string
    consolePlaystation: string
    consoleXbox: string
    smartTv: string
}

export const Devices = {
    desktopMacs: "Desktop - Macs",
    desktopWindows: "Desktop - Windows",
    tabletIPad: "Tablet - IPad",
    tabledAndroid: "Tabled - Android",
    mobileIPhone: "Mobile - IPhone",
    mobileAndroid: "Mobile - Android phone",
    mobileWindows: "Mobile - Windows phone",
    consolePlaystation: "Console - Playstation",
    consoleXbox: "Console - Xbox",
    smartTv: "Smart Tv"
}


export const DevicesKeys = {
    [Devices.desktopMacs]: "desktopMacs",
    [Devices.desktopWindows]: "desktopWindows",
    [Devices.tabletIPad]: "tabletIPad",
    [Devices.tabledAndroid]: "tabledAndroid",
    [Devices.mobileIPhone]: "mobileIPhone",
    [Devices.mobileAndroid]: "mobileAndroid",
    [Devices.mobileWindows]: "mobileWindows",
    [Devices.consolePlaystation]: "consolePlaystation",
    [Devices.consoleXbox]: "consoleXbox",
    [Devices.smartTv]: "smartTv"
}