import { PAYMENT_METHOD_STATE, PaymentMethod } from "./paymentMethods"

export interface SellerProps {
    id: string
    name: string
    email: string
    state: SELLER_STATE
    onboarding: ONBOARDING_STATE
    currency: string
    country: string
    registration: number
    methods: {
        [method: number]: PAYMENT_METHOD_STATE
    }
}

export enum SELLER_STATE {
    LOCKED = 0,
    ACTIVE = 1
}

export enum ONBOARDING_STATE {
    TO_ONBOARD = 0,
    PENDING = 1,
    HAS_ERRORS = 2,
    VERIFIED = 3
}
