import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApiKeyCreationForm, ApiKeysTable, Button, ButtonsDesign, Col, Dashboard, Icon, Modal, Spacer, TablePagination } from "../.."
import { REDUX_STATE, apiKeysActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { ApiKeysEngine } from "../../../utilities"

export const ApiKeys = () => {

        const dispatch = useDispatch()
        useEffect(() => { dispatch(loadData()) }, [])

        const state = useSelector(REDUX_STATE).apiKeys
        const { palette } = useSelector(REDUX_STATE).theme
        var apiKeys = ApiKeysEngine.getValues(state)

        const { results, current } = state.pagination
        const { loadData, setCurrentPage } = apiKeysActions

        const pages = Math.floor(apiKeys.length / results) - (apiKeys.length % results === 0 ? 1 : 0)
        const rows = apiKeys.slice(current * results, (current * results) + results)

        const Render = () =>
                <Dashboard
                        pageTitle="Api Keys"
                        onPage={DemoRoutes.os.apiKeys}
                        breadcrumb={["OS", "Api", "Keys"]}>

                        <Col mt={-40} position="absolute" justify="end" wrap="nowrap">
                                <Modal>
                                        <Button xs w100={false} variant="alternate">
                                                <Icon ml={-4} mr={4} variant="close" rotate={45} width={10} color={palette[ButtonsDesign["alternate"].color]} />
                                                Create key
                                        </Button>
                                        <ApiKeyCreationForm />
                                </Modal>
                        </Col>

                        <ApiKeysTable {...rows} />

                        <Spacer size={24} />

                        <TablePagination {...{
                                pages, current, results,
                                items: apiKeys.length,
                                setCurrent: (page: number) => dispatch(setCurrentPage(page))
                        }} />
                </Dashboard>

        return Render()
}

