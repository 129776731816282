import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { REDUX_STATE, feesLedgerActions } from "../../../redux"
import { FeesLedgerEngine, PSP, PSPs } from "../../../utilities"
import { Col, Dashboard, FeesTable, PeriodSelector, Spacer, TableFilters, TablePagination } from "../.."
import { DemoRoutes } from "../../../routes"

export const FeesLedger = () => {

        const dispatch = useDispatch()
        useEffect(() => { dispatch(loadData()) }, [])

        const state = useSelector(REDUX_STATE).fees
        var fees = FeesLedgerEngine.getValues(state)

        const { results, current } = state.pagination
        const { loadData, setCurrentPage, toggleAppliedFilter, setPeriod } = feesLedgerActions

        const pages = Math.floor(fees.length / results) - (fees.length % results === 0 ? 1 : 0)
        const rows = fees.slice(current * results, (current * results) + results)

        const Render = () =>
                <Dashboard
                        pageTitle="Fees"
                        onPage={DemoRoutes.ledger.fees}
                        breadcrumb={["Ledger", "Payments", "Fees"]}>

                        <Col justify="between" wrap="nowrap">
                                <TableFilters
                                        filters={state.filters.map(x => { return { name: PSPs[x.type].display_name, state: x.state, type: x.type } })}
                                        onClick={(val: number) => dispatch(toggleAppliedFilter(val as PSP))} />
                                <PeriodSelector start={new Date(state.period.start)} end={new Date(state.period.end)} onApplied={(s, e) => { dispatch(setPeriod({ start: s.getTime(), end: e.getTime() })); window.setTimeout(() => { dispatch(loadData()) }, 500) }} />
                        </Col>

                        <Spacer size={16} />

                        <FeesTable {...rows} />

                        <Spacer size={24} />

                        <TablePagination {...{
                                pages, current, results,
                                items: fees.length,
                                setCurrent: (page: number) => dispatch(setCurrentPage(page))
                        }} />
                </Dashboard>

        return Render()
}

