import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, BadgePropsForDispute, CardHorizzontalSpacer, Col, DescriptionBlock, DisputeEvidenceForm, DisputeInfoDetails, DisputeStateInfo, Icon, ModalPage, P, Spacer } from "../.."
import { REDUX_STATE, disputesLedgerActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { DISPUTES_LEDGER_COLS, DISPUTE_STATE, DisputeReason, DisputesLedgerRowProps, Months, UUID } from "../../../utilities"
import { Payins } from "../../../fake_data"


export const DisputesTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={head ? undefined : `1px solid ${palette.gray2}`} pointer>
            {!head && <Spacer size={12} />}
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="8%" max_w="8%">{children[0]}</Col>
                <Col w="15%" max_w="15%">{children[1]}</Col>
                <Col w="15%" max_w="15%">{children[2]}</Col>
                <Col w="30%" max_w="30%">{children[3]}</Col>
                <Col w="12%" max_w="12%">{children[4]}</Col>
                <Col w="11%" max_w="11%">{children[5]}</Col>
                <Col w="9%" max_w="9%">{children[6]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            {!head && <Spacer size={12} />}
        </Col>

    return render()
}

export const DisputesTableHead = () => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { orderData } = disputesLedgerActions
    const { theme: { palette }, disputes: { order } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Amount", col: DISPUTES_LEDGER_COLS.AMOUNT },
        { name: "State", col: DISPUTES_LEDGER_COLS.STATE },
        { name: "Reason", col: DISPUTES_LEDGER_COLS.REASON },
        { name: "Buyer", col: DISPUTES_LEDGER_COLS.BUYER },
        { name: "Gateway", col: DISPUTES_LEDGER_COLS.PROVIDER },
        { name: "Disputed on", col: DISPUTES_LEDGER_COLS.DISPUTED_ON },
        { name: "Due on", col: DISPUTES_LEDGER_COLS.DUE_ON },
    ]

    const render = () =>
        <DisputesTableRowLayout head>
            {
                cols.map(x =>
                    <Col key={id + n++} py={12} pointer wrap="nowrap" align="center" onClick={() => dispatch(orderData(x.col as DISPUTES_LEDGER_COLS))}>
                        <P nowrap mb={2} medium variant="secondary"> {x.name} </P>
                        <Icon pointer color={palette.black} height={18} variant={`ordinament${order.by !== x.col ? "" : order.ascendent ? "-asc" : "-des"}`} />
                    </Col>
                )
            }
        </DisputesTableRowLayout>

    return render()
}

export const DisputesTableRow = (props: DisputesLedgerRowProps) => {

    const { id, amount, state, reason, customer, method } = props

    let
        dueOn = new Date(props.dueOn),
        disputedOn = new Date(props.disputedOn),
        h = dueOn.getHours().toString(),
        m = dueOn.getMinutes().toString()

    const render = () =>
        <DisputesTableRowLayout>
            <P medium variant="dark">${amount}</P>
            <Badge {...BadgePropsForDispute(props)} />
            <P regular variant="dark">{DisputeReason(reason)}</P>
            <P regular variant="dark">{customer.email}</P>
            <DescriptionBlock data={method} type="psp" />
            <P regular variant="dark">{`${Months[disputedOn.getMonth()].slice(0, 3)} ${disputedOn.getDate()}`}</P>
            <P regular variant="dark">{`${Months[dueOn.getMonth()].slice(0, 3)} ${dueOn.getDate()}, ${h.length > 1 ? h : `0${h}`}:${m.length > 1 ? m : `0${m}`}`}</P>
        </DisputesTableRowLayout >


    return <ModalPage href={DemoRoutes.ledger.disputeDetails + id}>
        {render()}
        <Col>
            <DisputeInfoDetails {...props} />
            <Spacer size={32} />
            <DisputeStateInfo {...props} />
        </Col>
    </ModalPage>
}
