import { useSelector } from "react-redux"
import styled, { keyframes } from "styled-components"
import { Layout, SpacerValue } from "../../design_system"
import { REDUX_STATE } from "../../redux"


export const Spinner = (props: { color?: string, size?: SpacerValue }) => {

    const { negative } = useSelector(REDUX_STATE).theme

    return <SpinnerSyled color={props.color ?? negative[Layout.background]} size={props.size ?? 24} />
}

interface SpinnerProps { color: string, size: SpacerValue }

export const SpinnerSyled = styled.div<SpinnerProps>`
    border-radius: 50%;
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    border:  solid ${p => p.size * .115}px transparent;
    border-top-color:  ${p => p.color};
    border-left-color:  ${p => p.color};
    animation: 400ms linear infinite ${keyframes`
        0%   { transform: rotate(0deg);   transform: rotate(0deg); }
        100% { transform: rotate(360deg); transform: rotate(360deg); }
    `};
`

