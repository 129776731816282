import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBlock, CardDivider, CardLink, CardSubtilte, CardText, CardTitle, CardVerticalSpacer, Col, FormInput, Icon, P, Spacer, Spacers, UploadFileInput } from "../.."
import { DisputeUpdateRequest, DisputeUpdateRequestValidator, hasErrors, isInvalidRequest } from "../../../api"
import { SpacerValue } from "../../../design_system"
import { REDUX_STATE, disputeFormActions } from "../../../redux"
import { DISPUTE_REASON, DISPUTE_STATE, DisputesLedgerRowProps, UUID } from "../../../utilities"

export const DisputeEvidenceForm = (dispute: DisputesLedgerRowProps) => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { disputeForm: { errors, request }, theme: { palette } } = useSelector(REDUX_STATE)
    const { reason, customer } = dispute

    const evidenceSubmitted = dispute.state === DISPUTE_STATE.NEEDS_RESPONSE

    const initial: DisputeUpdateRequest = {
        ...request,
        why: evidenceSubmitted ? "The cardholder was refunded" : "",
        description: evidenceSubmitted ? "The product is a book" : "",
        type: evidenceSubmitted ? "Physical product" : "",
        buyer_email: customer.email,
        buyer_name: customer.name,
        buyer_address: customer.address
    }

    // useEffect(() => {window.setTimeout(()=>{ dispatch(setRequest(initial))},300) }, [])

    const render = () =>
        <Card noPadding>
            <CardTitle withPadding>
                <Icon mr={8} width={14} variant="hammer" />
                {
                    evidenceSubmitted ?
                        "Counter the dispute" : "Review your submitted evidence"
                }
            </CardTitle>

            <CardBlock>
                <CardSubtilte>
                    Why should you win this dispute?
                </CardSubtilte>
                <FormInput type="radio"
                    onSelected={onChange("why")}
                    errorMessage={requestError("why")}
                    option={reason === DISPUTE_REASON.PRODUCT_NOT_RECEIVED ?
                        [
                            "The cardholder withdrew the dispute",
                            "The cardholder was refunded",
                            "The cardholder received the product or service",
                            "The product, service, event or booking was cancelled or delayed due to a government order (COVID-19)",
                            "Other",
                        ] : [
                            "The cardholder withdrew the dispute",
                            "The cardholder was refunded",
                            "The purchase was made by the rightful cardholder",
                            "Other",
                        ]} value={request.why} />
            </CardBlock>

            <CardBlock>
                <CardSubtilte>
                    What type of product or service is this
                </CardSubtilte>
                <FormInput type="radio" onSelected={onChange("type")} errorMessage={requestError("type")} option={["Physical product", "Digital product or service", "Offline service", "Event", "Booking or reservation", "Other"]} value={request.type} />
                
                <Spacer size={24} />
                <FormInput type="text" defaultValue={request.description} onChange={onChange("description")} errorMessage={requestError("description")}>
                    Description
                </FormInput>
            </CardBlock>

            <CardBlock>
                <CardSubtilte>
                    Supporting evidence
                </CardSubtilte>
                <CardText>
                    The following evidence is most relevant to this dispute. If you would like to share other types of evidence not listed below, upload it and label it as 'other'.
                </CardText>
                <Spacer size={8} />
                <CardLink>Learn best practices for submitting evidence.</CardLink>

                <Spacer size={32} />

                <CardSubtilte>
                    Submit at least one of the following
                </CardSubtilte>
                <Col>
                    {
                        ["Customer communication", "Customer signature", "Shipping documentation"]
                            .map(x =>
                                <P key={n++ + id} mb={8} nowrap><Icon mr={4} variant="file" width={15} color={palette.gray3} />{x}</P>
                            )
                    }
                </Col>

                <Spacer size={24} />

                <CardSubtilte>
                    We also recommend including
                </CardSubtilte>
                <Col>
                    {
                        ["Receipt", "Other evidence"]
                            .map(x =>
                                <P key={id + n++} mb={8} nowrap><Icon mr={4} variant="file" width={15} color={palette.gray3} />{x}</P>
                            )
                    }
                </Col>

                <Spacer size={24} />

                <CardSubtilte>
                    Upload file from your computer
                </CardSubtilte>
                <UploadFileInput />
            </CardBlock>

            <CardBlock>
                <CardSubtilte>
                    Customer details
                </CardSubtilte>
                <FormInput type="text" defaultValue={customer.email} onChange={onChange("buyer_email")} errorMessage={requestError("buyer_email")}>
                    Email
                </FormInput>
                <Spacer size={16} />
                <FormInput type="text" defaultValue={customer.name} onChange={onChange("buyer_name")} errorMessage={requestError("buyer_name")}>
                    Name
                </FormInput>
                <Spacer size={16} />
                <FormInput type="text" defaultValue={customer.address} onChange={onChange("buyer_address")} errorMessage={requestError("buyer_address")}>
                    Billing address
                </FormInput>
            </CardBlock>


            <CardDivider noPadding />

            <Col justify="end" py={12} px={Spacers.cardHorizzontal as SpacerValue}>
                <Button w100={false} variant="primary" disabled={
                    (request.why.length === 0 || request.why === initial.why) &&
                    (request.description.length === 0 || request.description === initial.description) &&
                    (request.type.length === 0 || request.type === initial.type) &&
                    (request.document.length === 0 || request.document === initial.document) &&
                    (request.buyer_email.length === 0 || request.buyer_email === initial.buyer_email) &&
                    (request.buyer_name.length === 0 || request.buyer_name === initial.buyer_name) &&
                    (request.buyer_address.length === 0 || request.buyer_address === initial.buyer_address)
                }>
                    Submit
                </Button>
            </Col>
        </Card>


    let requestError = (key: keyof DisputeUpdateRequest) => hasErrors(errors) ? errors[key] : undefined

    const { setRequest, setValidationErrors } = disputeFormActions
    const onChange = (prop: keyof DisputeUpdateRequest) => (val: string | boolean) => {
        const req: DisputeUpdateRequest = {
            ...request,
            [prop]: val
        }

        dispatch(setRequest(req))

        if (isInvalidRequest(errors)) {
            let result = DisputeUpdateRequestValidator.validate(req)
            dispatch(setValidationErrors(result))
        }
    }

    return render()
}
