import { ReactNode } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { Spinner, SpinnerSyled } from ".."
import { PaletteProps } from "../../design_system/palette"
import { FontWeight, SpacerValue } from "../../design_system/types"
import { Typography } from "../../design_system/typography"
import { REDUX_STATE } from "../../redux"

export interface ButtonProps {
    xs?: boolean
    w100?: boolean
    smooth?: boolean
    disabled?: boolean
    isLoading?: boolean
    children: ReactNode
    onClick?: (e: React.MouseEvent) => void
    style?: React.CSSProperties
    type?: "button" | "submit" | "reset"
    variant: 'primary' | 'secondary' | 'alternate'
}

export const Button = (props: ButtonProps) => {

    const { palette } = useSelector(REDUX_STATE).theme

    const Render = () =>
        <ButtonStyled {...{
            ...props,
            role: "button",
            type: props.type,
            palette: palette,
            children: props.children,
            design: ButtonsDesign[props.variant],
        }}>
            {
                props.isLoading &&
                <Spinner size={ButtonsDesign[props.variant].height[(props.xs ? "xs" : "default")] * .4 as SpacerValue} />
            }
            {props.children}
        </ButtonStyled>

    return Render()
}


export const ButtonsDesign: ButtonsDesignProps = {
    primary: {
        height: {
            default: 36,
            xs: 28
        },
        typography: {
            default: Typography.P.styles,
            xs: Typography.S.styles
        },

        verticalPadding: 12,
        horizontalPadding: 16,

        color: 'white',
        shadow: 'unset',
        background: 'black',

        borderWidth: 0,
        borderStyle: 'unset',
        borderColor: `unset`,
        borderRadius: 4,

        onHover: {
            background: 'black',
            borderColor: 'unset',
        },

        onFocus: {
            background: 'black',
            borderColor: `unset`
        },

        onDisabled: {
            background: 'gray3',
            borderColor: `unset`
        }
    },

    secondary: {
        height: {
            default: 36,
            xs: 28
        },
        typography: {
            default: Typography.P.styles,
            xs: Typography.S.styles
        },

        verticalPadding: 12,
        horizontalPadding: 16,

        color: 'white',
        shadow: 'unset',
        background: 'primary2',

        borderWidth: 0,
        borderStyle: 'unset',
        borderColor: `unset`,
        borderRadius: 4,

        onHover: {
            background: 'primary2',
            borderColor: `unset`
        },

        onFocus: {
            background: 'primary2',
            borderColor: `unset`
        },

        onDisabled: {
            background: 'primary2',
            borderColor: `unset`
        }
    },

    alternate: {
        height: {
            default: 36,
            xs: 28
        },
        typography: {
            default: `${Typography.P.styles}; font-weight:${FontWeight.medium}`,
            xs: `${Typography.S.styles}; font-weight:${FontWeight.medium}`,
        },
        verticalPadding: 12,
        horizontalPadding: 16,

        color: 'black',
        shadow: '0 1px 2px rgba(18,20,23,.06)',
        background: 'light',

        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray2',
        borderRadius: 4,

        onHover: {
            background: 'light',
            borderColor: 'gray2'
        },

        onFocus: {
            background: 'light',
            borderColor: 'gray2'
        },

        onDisabled: {
            background: 'gray1',
            borderColor: 'gray2'
        }
    }
}



interface ButtonPropsStyled extends ButtonProps { palette: PaletteProps, design: ButtonDesignProps }
export const ButtonStyled = styled.button<ButtonPropsStyled>`
 ${({ palette, design, w100, isLoading, smooth, xs }) => css`
    
    &, span {
        white-space: nowrap;
        ${design.typography[xs ? "xs" : "default"]};
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${design.shadow};
    height: ${design.height[xs ? "xs" : "default"]}px;
    max-height: ${design.height[xs ? "xs" : "default"]}px;
    ${!(w100 === false) && css`width:100%;`}
    padding: ${`${design.verticalPadding}px ${design.horizontalPadding}px`};


    color: ${palette[design.color]};
    background: ${palette[design.background]}; 

    border-style: ${design.borderStyle}; 
    border-width: ${design.borderWidth}px; 
    border-color: ${palette[design.borderColor]}; 
    border-radius: ${design.borderRadius * (smooth ? 2 : 1)}px; 

    &:hover{
        background: ${palette[design.onHover.background]}; 
        border-color: ${palette[design.onHover.borderColor]}; 
    }

    &:focus{
        background: ${palette[design.onFocus.background]}; 
    }

    &:disabled {
        cursor: default;
        pointer-events: none;
        background: ${palette[design.onDisabled.background]}; 
    }

    ${SpinnerSyled} {
        position: absolute;
        top: ${`calc(52% - ${design.height[xs ? "xs" : "default"] * .2}px)`};
        left: ${`calc(50% - ${design.height[xs ? "xs" : "default"] * .2}px)`};
    }
    
    ${isLoading && css`
        cursor: default;
        pointer-events: none;
        user-select: none; 
        -ms-user-select: none;        
        -moz-user-select: none; 
        -webkit-user-select: none; 
        opacity: .7; 
        color: transparent;
        display: inline-block;
        line-height: 1.4;
        position: relative;
        transition: padding-right .3s ease-out;

       
        @keyframes spin { 
            to {
                transform: rotate(359deg);
            }
        }
    `}
`}

`

// &:after {
//     content: "";
//     position: absolute;
//     border-top-color: transparent;
//     border-radius: 100%;
//     left: calc(50% - 11px);
//     top: calc(50% - 11px);
//     width: 18px;
//     height: 18px;
//     animation: spin .5s infinite linear;
//     border: 2px solid ${palette[design.color]};
// }



interface ButtonsDesignProps {
    primary: ButtonDesignProps
    secondary: ButtonDesignProps
    alternate: ButtonDesignProps
}

interface ButtonDesignProps {
    typography: {
        default: string
        xs: string
    }
    height: {
        default: number
        xs: number
    }
    color: keyof PaletteProps
    shadow: string
    background: keyof PaletteProps
    borderWidth: number
    borderStyle: string
    borderColor: keyof PaletteProps
    borderRadius: number
    verticalPadding: SpacerValue
    horizontalPadding: SpacerValue
    onHover: {
        background: keyof PaletteProps
        borderColor: keyof PaletteProps
    }
    onFocus: {
        background: keyof PaletteProps
        borderColor: keyof PaletteProps
    }
    onDisabled: {
        background: keyof PaletteProps
        borderColor: keyof PaletteProps
    }
}
