import { REGION } from "./regions"


export enum PSP {
    STRIPE = 0,
    PAYPAL = 1
}


export enum PSP_ONBOARDING_STATE {
    DISABLED = 0,
    PENDING = 1,
    VERIFIED = 2
}

export interface PspProps {
    name: string
    display_name: string
    value: PSP
    description: string
    area: REGION[]
}

export const PSPs: PspProps[]
    = [
        {
            name: 'stripe',
            display_name: 'Stripe',
            value: PSP.STRIPE,
            description: "Sell online, process payments, build financial products, or use business tools designed to grow your business.",
            area: [REGION.AMERICA, REGION.EUROPE, REGION.ASIA, REGION.OCEANIA, REGION.AFRICA]
        },
        {
            name: 'paypal',
            display_name: 'Paypal',
            value: PSP.PAYPAL,
            description: "PayPal Holdings, Inc. is an American multinational financial technology company operating an online payments system in the majority of countries that support online money transfers, and serves as an electronic alternative to traditional paper methods such as checks and money orders.",
            area: [REGION.EUROPE, REGION.OCEANIA, REGION.AMERICA],
        },
    ]

