import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, CardHorizzontalSpacer, Col, Icon, P, Spacer } from "../.."
import { REDUX_STATE, webhookChannelsActions } from "../../../redux"
import { UUID, WEBHOOK_CHANNELS_TABLE_COLS, WebhookChannelsRowProps } from "../../../utilities"


export const WebhookChannelsTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={head ? undefined : `1px solid ${palette.gray2}`}>
            {!head && <Spacer size={12} />}
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="20%" max_w="20%">{children[0]}</Col>
                <Col w="30%" max_w="30%">{children[1]}</Col>
                <Col w="30%" max_w="30%">{children[2]}</Col>
                <Col w="10%" max_w="10%">{children[3]}</Col>
                <Col w="10%" max_w="10%" justify="end">{children[4]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            {!head && <Spacer size={12} />}
        </Col>

    return render()
}

export const WebhookChannelsTableHead = () => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { orderData } = webhookChannelsActions
    const { theme: { palette }, webhookChannels: { order } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Name", col: WEBHOOK_CHANNELS_TABLE_COLS.NAME },
        { name: "Url", col: WEBHOOK_CHANNELS_TABLE_COLS.URL },
        { name: "Event type", col: WEBHOOK_CHANNELS_TABLE_COLS.EVENT_TYPE },
        { name: "Last event", col: WEBHOOK_CHANNELS_TABLE_COLS.LAST_EVENT },
        { name: "Created", col: WEBHOOK_CHANNELS_TABLE_COLS.CREATED },
    ]

    const render = () =>
        <WebhookChannelsTableRowLayout head>
            {
                cols.map(x =>
                    <Col xs="content" key={id + n++} py={12} pointer wrap="nowrap" align="center" onClick={() => dispatch(orderData(x.col as WEBHOOK_CHANNELS_TABLE_COLS))}>
                        <P nowrap mb={2} medium variant="secondary"> {x.name} </P>
                        <Icon pointer color={palette.black} height={18} variant={`ordinament${order.by !== x.col ? "" : order.ascendent ? "-asc" : "-des"}`} />
                    </Col>
                )
            }
        </WebhookChannelsTableRowLayout>

    return render()
}

export const WebhookChannelsTableRow = (props: WebhookChannelsRowProps) => {

    const { id, name, url, eventType, lastEvent, created } = props

    const render = () =>
        <WebhookChannelsTableRowLayout>
            <P regular variant="dark">{name}</P>
            <P regular variant="dark">{url}</P>
            <Badge variant="default">
                {eventType}
            </Badge>
            <P regular variant="dark">{lastEvent ? new Date(lastEvent).toLocaleDateString() : "-"}</P>
            <P regular variant="dark">{new Date(created).toLocaleDateString()}</P>
        </WebhookChannelsTableRowLayout >

    return render()
}
