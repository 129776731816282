import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DISPUTE_STATE, DISPUTES_LEDGER_COLS, DisputesLedgerEngine, DisputesLedgerFilter, DisputesLedgerOrder, DisputesLedgerRowProps } from "../utilities";

export interface DisputesLedgerData {
    disputes: DisputesLedgerRowProps[]
    period: {
        start: number
        end: number
    }
    order: DisputesLedgerOrder
    filters: DisputesLedgerFilter[]
    pagination: {
        results: number
        current: number
    }
}

const initialState: DisputesLedgerData = {
    disputes: [],
    period: {
        start: new Date(2023, 0, 1).getTime(),
        end: new Date().getTime()
    },
    order: {
        by: DISPUTES_LEDGER_COLS.DUE_ON,
        ascendent: true
    },
    filters: [
        {
            state: true,
            type: DISPUTE_STATE.LOST
        },
        {
            state: true,
            type: DISPUTE_STATE.NEEDS_RESPONSE
        },
        {
            state: true,
            type: DISPUTE_STATE.UNDER_REVIEW
        },
        {
            state: true,
            type: DISPUTE_STATE.WON
        }
    ],
    pagination: {
        results: 15,
        current: 0
    }
}

export const disputesLedgerSlice = createSlice({
    name: 'disputesLedger',
    initialState,
    reducers: {

        setPeriod: (state: DisputesLedgerData, action: PayloadAction<{ start: number, end: number }>) => {
            const { start, end } = action.payload
            return {
                ...state,
                period: { start: start, end: end }
            }
        },

        loadData: (state: DisputesLedgerData) => {
            var disputes = DisputesLedgerEngine.getRows(state.period.start, state.period.end)
            return {
                ...state,
                disputes,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order: initialState.order
            }
        },

        orderData: (state: DisputesLedgerData, action: PayloadAction<DISPUTES_LEDGER_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order
            }
        },

        setCurrentPage: (state: DisputesLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: DisputesLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        toggleAppliedFilter: (state: DisputesLedgerData, action: PayloadAction<DISPUTE_STATE>) => {
            return {
                ...state,
                filters: state.filters.map(x => x.type !== action.payload ? x : { ...x, state: !x.state }),
                pagination: {
                    ...state.pagination,
                    current: 0
                }
            }
        }
    }
})

export const disputesLedgerActions = disputesLedgerSlice.actions
export const disputesLedgerReducer = disputesLedgerSlice.reducer

