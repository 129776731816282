import { Card, WebhookChannelsTableHead, WebhookChannelsTableRow } from "../..";
import { UUID, WebhookChannelsRowProps } from "../../../utilities";



export const WebhookChannelsTable = (rows: WebhookChannelsRowProps[]) => {

    let n = 0, id = UUID.create()

    const render = () =>
        <Card noPadding>
            <WebhookChannelsTableHead />
            {
                Object.values(rows).map(row =>
                    <WebhookChannelsTableRow key={id + n++} {...row} />
                )
            }
        </Card>

    return render()
}