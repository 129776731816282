import { useState } from "react"
import { useDispatch } from "react-redux"
import ReactTyped from "react-typed"
import styled from "styled-components"
import { Button, Col, Container, P, Row, Spacer, Visible } from "../../components"
import { Typography } from "../../design_system/typography"
import { Layout } from "../../design_system/layout"
import { setAccess } from "../../redux"
import { cssTransition } from "../../utilities"


const Authorized = [
    "Fratello" 
]

enum PHASE {
    FIRST,
    SECOND,
    THIRD,
    FOURTH
}

export const AccessCodeForm = () => {

    const [val, setVal] = useState("")
    const [errors, setErrors] = useState("")
    const [phase, setPhase] = useState(PHASE.FIRST)

    const render = () =>
        <AccessCodeFormStyled>
            <form onSubmit={(e) => { e.preventDefault(); onConfirm() }}>
                <Container p={28}>
                    <Row>
                        <Col direction="column">
                            <P spacing=".5px" variant="light">
                                {
                                    phase === PHASE.FIRST ?
                                        <ReactTyped strings={["Welcome to the product demo. "]} typeSpeed={30} onComplete={() => window.setTimeout(() => { setPhase(PHASE.SECOND) }, 500)} /> :
                                        <>Welcome to the product demo. </>
                                }
                            </P>
                            <Spacer size={phase === PHASE.FIRST ? 0 : 4} />
                            <P spacing=".5px" variant="light">
                                {
                                    phase === PHASE.FIRST ? <></> :
                                        phase === PHASE.SECOND ?
                                            <ReactTyped strings={["Let's enter the BIOS "]} typeSpeed={20} startDelay={500} onComplete={() => window.setTimeout(() => { setPhase(PHASE.THIRD) }, 500)} /> :
                                            <>Let's enter the BIOS</>
                                }
                            </P>
                            <Spacer size={phase === PHASE.FIRST || phase === PHASE.SECOND ? 0 : 28} />
                            <P spacing=".5px" color={"#00D4FF"}>
                                {
                                    phase === PHASE.FIRST || phase === PHASE.SECOND ? <></> :
                                        phase === PHASE.THIRD ?
                                            <ReactTyped strings={["Enter your access code "]} typeSpeed={20} startDelay={500} onComplete={() => window.setTimeout(() => { setPhase(PHASE.FOURTH) }, 500)} /> :
                                            <>Enter your access code</>
                                }
                            </P>
                            {
                                phase === PHASE.FOURTH ?
                                    <Container>
                                        <Visible till="xs">
                                            <Row pt={12} justify="between" align="center">
                                                <Col>
                                                    <Input onChange={(e) => { setVal(e) }} autoFocus />
                                                </Col>
                                            </Row>
                                            <Row pt={12} justify="between" align="center">
                                                <Col xs={12}>
                                                    <Button w100 variant="secondary" onClick={onConfirm}>
                                                        Confirm
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Visible>
                                        <Visible over="xs">
                                            <Row pt={8} justify="between" align="center">
                                                <PropmtCursor />
                                                <Spacer size={12} />
                                                <Col>
                                                    <Input onChange={(e) => { setVal(e) }} autoFocus />
                                                </Col>
                                                <Spacer size={8} />
                                                <Col xs="content">
                                                    <Button variant="secondary" onClick={onConfirm}>
                                                        Confirm
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Visible>
                                    </Container>
                                    :
                                    <></>
                            }
                            {
                                errors.length > 0 &&
                                <>
                                    <Spacer size={16} />
                                    <P medium color="#f27777b5">{errors}</P>
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </form>
        </AccessCodeFormStyled>


    const dispatch = useDispatch()
    const onConfirm = () => {
        if (Authorized.some(x => x === val)) {
            document.body.classList.add("authorized")
            window.setTimeout(() => {
                dispatch(setAccess(true))
            }, 1000)
        }
        else {
            setErrors(val.length === 0 ? "Type your code" : "We are sorry, but you cannot access the Demo")
        }
    }

    return render()
}

const PropmtCursor = styled.span`
    color: #FFCE48;
    
    &:after {
        content: "→";
    }
`

export const AccessCodeFormStyled = styled.div`
    min-width: 500px;
    position: absolute;
    top: calc(35%);
    left: calc(50% - 250px);
    border: 1px solid rgba(243,238,255,.08);
    background: rgba(38,24,118,.8);
    border-radius: 8px;

    &:before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        border-radius: 8px;
        background:  #17113d 0%;
    }

    @media (max-width: ${Layout.breakpoints.xs}px) {   
        left: 5%;
        width: 90%;
        min-width: unset;
    }

`

const Input = (props: { children?: string, onChange?: (e: string) => void, autoFocus?: boolean }) => {

    const render = () =>
        <Container>
            <InputStyled>
                <input placeholder={props.children} onChange={(e) => { props.onChange && props.onChange(e.currentTarget.value) }} autoFocus={props.autoFocus} />
            </InputStyled>
        </Container>

    return render()
}


const InputStyled = styled.div`
    align-items: center;
    background-color: transparent;
    border: 1px solid rgba(243,238,255,.1);
    border-radius: 8px;
    display: flex;
    ${cssTransition(".15s cubic-bezier(.6,.6,0,1) background-color,.15s cubic-bezier(.6,.6,0,1) border-color,.15s cubic-bezier(.6,.6,0,1) box-shadow")}
    
    &:hover {
        background: rgba(243,238,255,.06);
    }
    
    &:focus {
        outline: none;
    }

    &:focus-within {
        border-color: #9382ff;
        box-shadow: 0 0 0 2px #030014, 0 0 0 3px #9382ff52;
    }

    input {
        ${Typography.S.styles};
        padding: 8px 12px;
        background-color: transparent;
        border: 0;
        color: #f4f0ff;
        display: block;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
`