import { useState } from "react"
import { CapabilitiesSelectors, Card, CardDataCollection, CardDivider, CardScrollableItem, CardTitle } from "../.."
import { PAYMENT_METHOD, PSP, PSP_ONBOARDING_STATE, PaymentMethods, SellerProps } from "../../../utilities"

export const SellerCapabilitiesDetails = (seller: SellerProps) => {

    let n = Math.random() * 10
    const [{ stripeOnboard, paypalOnboard }, setState] = useState({
        stripeOnboard: n < 1 ? PSP_ONBOARDING_STATE.DISABLED : n < 2 ? PSP_ONBOARDING_STATE.PENDING : PSP_ONBOARDING_STATE.VERIFIED,
        paypalOnboard: n < 3 ? PSP_ONBOARDING_STATE.DISABLED : n < 6 ? PSP_ONBOARDING_STATE.PENDING : PSP_ONBOARDING_STATE.VERIFIED
    })

    const render = () =>
        <Card>
            <CardTitle>Providers</CardTitle>

            <CardDataCollection data={[
                { type: "psp-state", data: [PSP.STRIPE, stripeOnboard] },
                { type: "psp-state", data: [PSP.PAYPAL, paypalOnboard] }
            ]} />

            {
                (
                    stripeOnboard === PSP_ONBOARDING_STATE.VERIFIED ||
                    paypalOnboard === PSP_ONBOARDING_STATE.VERIFIED
                ) && <>
                    <CardDivider />
                    <CardScrollableItem cta={"Click to show witch methods to enable"}>
                        <CapabilitiesSelectors capabilities={
                            PaymentMethods.map(x => {
                                return {
                                    method: x.value,
                                    disabled: x.value === PAYMENT_METHOD.PAYPAL ? !paypalOnboard : !stripeOnboard,
                                    state: Math.round(Math.random()) === 0
                                }
                            })
                        } />
                    </CardScrollableItem>
                </>
            }
        </Card>

    return render()
}
