import { Card, DisputesTableHead, DisputesTableRow } from "../.."
import { DisputesLedgerRowProps, UUID } from "../../../utilities"

export const DisputesTable = (rows: DisputesLedgerRowProps[]) => {

    let n = 0, id = UUID.create()

    const render = () =>
        <Card noPadding>
            <DisputesTableHead />
            {
                Object.values(rows).map(row =>
                    <DisputesTableRow key={id + n++} {...row} />
                )
            }
        </Card>

    return render()
}