import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiErrors, DisputeUpdateRequest, DisputeUpdateRequestErrors, ErrorMessage, ValidationResult } from "../api";

export interface DisputeFormData {
    isCompleted: boolean
    isAwaitingResponse: boolean
    errors: DisputeUpdateRequestErrors
    request: DisputeUpdateRequest
}

const initialState: DisputeFormData = {
    isCompleted: false,
    isAwaitingResponse: false,
    errors: {
        generic: '',
        why: '',
        description: '',
        type: '',
        document: '',
        buyer_email: '',
        buyer_name: '',
        buyer_address: ''
    },
    request: {
        why: '',
        description: '',
        type: '',
        document: '',
        buyer_email: '',
        buyer_name: '',
        buyer_address: ''
    },
}

export const disputeFormSlice = createSlice({
    name: 'disputeForm',
    initialState,
    reducers: {

        setRequest: (state: DisputeFormData, action: PayloadAction<DisputeUpdateRequest>) => {
            return {
                ...state,
                request: action.payload
            }
        },

        setIsAwaitingResponse: (state: DisputeFormData, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isAwaitingResponse: action.payload
            }
        },

        setIsCompleted: (state: DisputeFormData) => {
            return {
                ...state,
                isCompleted: true
            }
        },



        setApiErrors: (state: DisputeFormData, action: PayloadAction<ApiErrors>) => {
            const err = (name: string) => ErrorMessage.from(action.payload[name])
            return {
                ...state,
                errors: {
                    generic: err("generic"),
                    why: err("why"),
                    description: err("description"),
                    type: err("type"),
                    document: err("document"),
                    buyer_email: err("buyer_email"),
                    buyer_name: err("buyer_name"),
                    buyer_address: err("buyer_address"),
                }
            }
        },

        setValidationErrors: (state: DisputeFormData, action: PayloadAction<ValidationResult>) => {
            const err = action.payload.errors
            return {
                ...state,
                errors: {
                    generic: err["generic"] ?? "",
                    why: err["why"] ?? "",
                    description: err["description"] ?? "",
                    type: err["type"] ?? "",
                    document: err["document"] ?? "",
                    buyer_email: err["buyer_email"] ?? "",
                    buyer_name: err["buyer_name"] ?? "",
                    buyer_address: err["buyer_address"] ?? "",
                }
            }
        },
    }
})

export const disputeFormActions = disputeFormSlice.actions
export const disputeFormReducer = disputeFormSlice.reducer