import { useSelector } from "react-redux";
import { Col, Container, NavbarControls, NavbarLogo } from "..";
import { REDUX_STATE } from "../../redux";

export const Navbar = () => {

    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Container bg={palette.white} bb={`1px solid ${palette.gray2}`} style={{ boxShadow: '0 2px 4px -1px rgba(7,14,35,.08' }}>
            <Col wrap="nowrap" align="center">
                <NavbarLogo />
                <NavbarControls />
            </Col>
        </Container>

    return render()
}
