import { PAYOUT_STATE, PayoutProps } from "../utilities/payouts";


export const Payouts: PayoutProps[] = [
    { id: "po_7689fasd-2b3c-466b-83fc-da0a64a612e4", state: PAYOUT_STATE.PAID, date: new Date(2023, 0, 1).getTime() },
    { id: "po_7689fsdd-2b3c-466b-83fc-da0a64a612e4", state: PAYOUT_STATE.PAID, date: new Date(2023, 1, 1).getTime() },
    { id: "po_768fv76a-2b3c-466b-83fc-da0a64ghjyg4", state: PAYOUT_STATE.PAID, date: new Date(2023, 2, 1).getTime() },
    { id: "po_7689f76a-2b3c-466b-83fc-da0a64agegb4", state: PAYOUT_STATE.PAID, date: new Date(2023, 3, 1).getTime() },
    { id: "po_76894nrn-2b3c-466b-83fc-da0a64afgbg4", state: PAYOUT_STATE.PAID, date: new Date(2023, 4, 1).getTime() },
    { id: "po_76nfr76a-2b3c-466b-83fc-da0a64a6das4", state: PAYOUT_STATE.PAID, date: new Date(2023, 5, 1).getTime() },
    { id: "po_7689476a-2b3c-466b-83fc-da0a64a612e4", state: PAYOUT_STATE.PENDING, date: new Date(2023, 6, 1).getTime() },
]
