

export const Url = {
    queryString: (key: string) => {
        let searchParams = new URLSearchParams(window.location.search)
        const queryString = searchParams.get(key)
        return queryString
    },
}

export const ApiDomain = process.env.NODE_ENV === 'development' ? "http://localhost:5000" : "https://apibios.altervista.org"
export const LadingDomain = process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://bios.altervista.org"
export const DemoDomain = process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://biosdemo.altervista.org"


export const LandingRoutes = {
    index: LadingDomain + "/"
}

export const DemoRoutes = {
    index: '/',
    system: {
        stripeCard: '/stripe/card',
        stripeBancontact: '/stripe/bancontact',
        stripeAlipay: '/stripe/alipay',
        stripeWechatPay: '/stripe/wechatpay',
        stripeEps: '/stripe/eps',
        stripeSofort: '/stripe/sofort',
        stripeP24: '/stripe/p24',
        stripeOxxo: '/stripe/oxxo',
        stripeGooglePay: '/stripe/googlepay',
        stripeApplePay: '/stripe/applepay',
        stripeGiroPay: '/stripe/giropay',
        stripeIdeal: '/stripe/ideal',

        paypal: '/paypal',
    },
    ledger: {
        payins: '/payins',
        payinDetails: '/payin/',
        payouts: '/payouts',
        disputes: '/disputes',
        fees: '/fees',
        disputeDetails: '/dispute/',
        sellers: '/sellers',
        sellerDetails: '/seller/',
        buyers: '/buyers',
        buyerDetails: '/buyer/'
    },
    os: {
        docs: '/docs',
        apiKeys: '/apikeys',
        apiLogs: '/apilogs',
        webhookChannel: '/webhookchannel',
        webhookLogs: '/webhooklogs'
    }
}


export const ApiRoutes = {

    // User
    REGISTRATION: `${ApiDomain}/v1/users`,
    ACCOUNT_CONFIRM: `${ApiDomain}/v1/user/confirm`,
    PASSWORD_FORGOT: `${ApiDomain}/v1/user/password/forgot`,
    PASSWORD_RESET: `${ApiDomain}/v1/user/password/reset`,

    // Authentication
    LOGIN: `${ApiDomain}/v1/user/login`,
    LOGIN_TEST: `${ApiDomain}/v1/user/login/test`,
    LOGOUT: `${ApiDomain}/v1/logout`,

    // Sellers
    CREATE_SELLER: ApiDomain + "/sellers",
    UPDATE_SELLER: ApiDomain + "/sellers",
    GET_SELLER: ApiDomain + "/sellers",

    // Payins
    CREATE_PAYIN: ApiDomain + "/payins",
    UPDATE_PAYIN: ApiDomain + "/payins",
    GET_PAYIN: ApiDomain + "/payins",

    // Dispute
    UPDATE_DISPUTE: ApiDomain + "/disputes",
}
