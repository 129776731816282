import { InputHTMLAttributes } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { Col, P } from ".."
import { PaletteProps } from "../../design_system"
import { REDUX_STATE } from "../../redux"


export interface RadioProps {
    name?: string
    disabled?: boolean
    checked?: boolean
    onChange?: (val: boolean) => void
    size?: number
    children?: string
}

export const Radio = (props: RadioProps) => {

    const { palette, isDark } = useSelector(REDUX_STATE).theme

    const render = () => <Col xs="content" wrap="nowrap" align="center" pointer onClick={() => props.onChange && props.onChange(!props.checked)}>
        <RadioStyled
            type="radio"
            size={props.size ?? 12}
            isDark={isDark}
            palette={palette}
            name={props.name}
            disabled={props.disabled}
            $checked={props.checked}
        />
        {
            props.children &&
            <P nowrap ml={8}>
                {props.children}
            </P>
        }
    </Col>

    return render()
}

interface RadioStyledProps extends InputHTMLAttributes<HTMLInputElement> { isDark?: boolean, $checked?: boolean, palette: PaletteProps, size: number }
const RadioStyled = styled.input<RadioStyledProps>`
${({ isDark, palette, size, $checked }) => css` 

    /* Not removed via appearance */
    margin: 0;
    margin-top: 1px;
    /* Remove most all native input styles */
    appearance: none;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* For iOS < 15 */
    background-color: ${isDark ? palette.gray1 : palette.transparent};

    width: ${size}px;
    height: ${size}px;
    display: grid;
    border-radius: 50%;
    place-content: center;
    transform: translateY(-0.075em);
    border: 1px solid ${palette.gray3}80;


    &::before {
        width: ${size / 3}px;
        height: ${size / 3}px;
        content: "";
        border-radius: 50%;
        transform: scale(0);
        /* Windows High Contrast Mode */
        background-color: ${palette.transparent};
        transition: 120ms transform ease-in-out;
    }

    ${$checked && css`
        border-width: ${size / 3}px;
        border-color: ${palette.primary2};

        &::before {
            transform: scale(1);
            transition: 120ms transform ease-in-out;
        }
    `} 

    &:disabled {
        border-color: ${palette.gray3};
        background-color: ${palette.gray2};
    }

`}
`