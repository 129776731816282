import { useSelector } from "react-redux";
import { Card, CardHorizzontalSpacer, CardTitle, CardVerticalSpacer, Col, Row, TopMethodsTableHead, TopMethodsTableRow, TopMethodsTableRowProps } from "../..";
import { Fees } from "../../../fake_data";
import { REDUX_STATE } from "../../../redux";
import { PAYIN_STATE, PAYMENT_METHOD_STATE, UUID } from "../../../utilities";

export const TopMethodsTable = () => {

        const { payins } = useSelector(REDUX_STATE).analytics

        const grouped = payins.current.reduce((result: {
                [key: number]: TopMethodsTableRowProps
        }, item) => {
                const { method, amount, state, seller } = item
                var platformFee = Fees[method.value][seller.country]?.platformFee
                result[method.value] = {
                        method,
                        state: PAYMENT_METHOD_STATE.ENABLED,
                        volume: (result[method.value]?.volume ?? 0) + (state === PAYIN_STATE.SUCCEEDED ? amount : 0),
                        revenue: (result[method.value]?.revenue ?? 0) + (state === PAYIN_STATE.SUCCEEDED ? amount * ((platformFee ? platformFee / 100 : 0) ?? 0) : 0)
                };
                return result;
        }, {});

        const topFive = Object.values(grouped).sort((a, b) => b.volume - a.volume).slice(0, 5)

        let n = 0, id = UUID.create()

        const render = () =>
                <Row>
                        <Col>
                                <Card h="100%" noPadding>
                                        <CardVerticalSpacer />
                                        <Col wrap="nowrap">
                                                <CardHorizzontalSpacer />
                                                <CardTitle>Top 5 payment methods</CardTitle>
                                                <CardHorizzontalSpacer />
                                        </Col>
                                        <TopMethodsTableHead />
                                        {
                                                topFive.map(x =>
                                                        <TopMethodsTableRow key={id + n++} {...x} />
                                                )
                                        }
                                </Card>
                        </Col>
                </Row>

        return render()
}