import { createRef } from "react"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { Col, Container, Icon, InputDesign as design } from ".."
import { PaletteProps } from "../../design_system"
import { REDUX_STATE } from "../../redux"

export interface TextAreaProps {
    name?: string
    error?: boolean
    success?: boolean
    disabled?: boolean
    placeholder?: string
    defaultValue?: string | number
    onChange?: (val: string) => void
}

export const TextArea = (props: TextAreaProps) => {

    const { palette } = useSelector(REDUX_STATE).theme

    const ref = createRef<HTMLTextAreaElement>()
    const { name, error, success, disabled, placeholder, defaultValue, onChange } = props

    const Render = () => <Container>
        <InputStyled
            palette={palette}
            ref={ref}
            name={name}
            error={error}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={(e) => onChange && onChange(e.currentTarget.value)}
        />
        <Col xs='content' position="absolute" top='0px' right={`${design.horizontalPadding + design.borderWidth}px`} h="100%" align="center">
            {
                (error || success) && !disabled &&
                <Icon width={24} onClick={() => ref.current?.focus()} variant={error ? 'danger' : 'success'} color={error ? palette.error2 : palette.success2} />
            }
        </Col>
    </Container>

    return Render()
}


const InputStyled = styled.textarea<{ palette: PaletteProps, error?: boolean }>`
${({ palette, readOnly, error }) => css`
    ${design.typography.default}

    width: 100%;
    min-width: 100%;
    max-width: 100%;
    transition: ${design.transition};
    min-height: ${design.height.default * 1.8}px;
    padding: ${design.verticalPadding}px ${design.horizontalPadding}px;

    color: ${palette[design.color]};
    background: ${palette[design.background]};

    border-width: ${design.borderWidth}px;
    border-style: ${design.borderStyle};
    border-color: ${palette[design.borderColor]};
    border-radius: ${design.borderRadius}px;

    outline-width: ${design.outlineWidth}px;
    outline-style: ${design.outlineStyle};
    outline-color: ${palette[design.outlineColor]};

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-weight: 300;
        color: ${palette[design.placeholderColor]};
    }

    &:-ms-input-placeholder,
    &::-ms-input-placeholder { /* Microsoft Edge *//* Internet Explorer 10-11 */
        opacity: 1; /* Firefox */
        font-weight: 300;
        color: ${palette[design.placeholderColor]};
    }

    &:hover {
        border-color: ${palette[design.onHover.borderColor]};   
    }

    &:focus {
        box-shadow: ${design.onFocus.shadow(palette)};
        border-color: ${palette[design.onFocus.borderColor]};
        outline-color: ${palette[design.onFocus.outlineColor]};
    }

    &:disabled {
        pointer-events: none;
        color: ${palette[design.onDisabled.color]};
        background: ${palette[design.onDisabled.background]};
        border-color: ${palette[design.onDisabled.borderColor]};
        outline-color: ${palette[design.onDisabled.outlineColor]};
    }

    ${error && css`
        padding-right: ${design.horizontalPadding * 2 + 20}px;
        border-color: ${palette[design.onError.borderColor]};
        
        &:hover {
            border-color: ${palette[design.onError.onHover.borderColor]};   
        }
        
        &:focus {
            border-color: ${palette[design.onError.onFocus.borderColor]};
            box-shadow: ${design.onError.onFocus.shadow(palette)};   
        }
    `}

    ${readOnly && css`
        cursor: pointer;
        border-color: ${palette[design.borderColor]};

        &:hover {
            border-color: ${palette[design.borderColor]};   
        }
        
        &:focus {
            box-shadow: unset;   
            border-color: ${palette[design.borderColor]};
        }
    `}
`}
`


