import { DateTime, PAYIN_STATE, PAYOUT_STATE, SELLER_STATE } from ".."
import { Payins, Sellers } from "../../fake_data"
import { SellersLedgerData } from "../../redux"


export enum SELLERS_LEDGER_COLS {
    EMAIL,
    STATE,
    BALANCE,
    VOLUME,
    COUNTRY,
    ONBOARDED
}

export interface SellersLedgerOrder {
    by: SELLERS_LEDGER_COLS,
    ascendent: boolean
}

export interface SellersLedgerFilter {
    state: boolean
    type: SELLER_STATE
}

export interface SellersLedgerRowProps {
    id: string
    email: string
    state: SELLER_STATE
    balance: number
    volume: number
    display_balance: string
    display_volume: string
    country: string
    onboarded: number
}


export class SellersLedgerEngine {

    static getValues(state: SellersLedgerData): SellersLedgerRowProps[] {
        const rows = this.filterValues(state.sellers, state.filters)
        const reoredered = this.getOrderedValues(rows, state.order)
        return reoredered
    }

    static filterValues(sellers: SellersLedgerRowProps[], applied: SellersLedgerFilter[]) {
        let filtered: SellersLedgerRowProps[] = []
        applied.some(x => x.type === SELLER_STATE.ACTIVE && x.state) && (filtered = filtered.concat(sellers.filter(x => x.state === SELLER_STATE.ACTIVE)))
        applied.some(x => x.type === SELLER_STATE.LOCKED && x.state) && (filtered = filtered.concat(sellers.filter(x => x.state === SELLER_STATE.LOCKED)))
        return filtered
    }

    static getOrderedValues(rows: SellersLedgerRowProps[], order: SellersLedgerOrder): SellersLedgerRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {
            case SELLERS_LEDGER_COLS.BALANCE:
                result = result.sort((a, b) => a.balance < b.balance ? factor * -1 : factor * 1)
                break

            case SELLERS_LEDGER_COLS.COUNTRY:
                result = result.sort((a, b) => a.country < b.country ? factor * -1 : factor * 1)
                break

            case SELLERS_LEDGER_COLS.EMAIL:
                result = result.sort((a, b) => a.email < b.email ? factor * -1 : factor * 1)
                break

            case SELLERS_LEDGER_COLS.VOLUME:
                result = result.sort((a, b) => a.volume < b.volume ? factor * -1 : factor * 1)
                break

            case SELLERS_LEDGER_COLS.COUNTRY:
                result = result.sort((a, b) => a.country < b.country ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => new Date(a.onboarded) > new Date(b.onboarded) ? factor * -1 : factor * 1)
                break
        }
        return result
    }

    static getRows(start: number, end: number): SellersLedgerRowProps[] {

        const sellers = Sellers
            .filter(x =>
                x.registration >= start &&
                x.registration <= end
            )
            .sort((a, b) =>
                a.registration - b.registration
            )

        const payins = Payins
            .filter(x =>
                x.date >= start &&
                x.date <= end
            )
            .sort((a, b) =>
                a.date - b.date
            )

        let p = []
        let b = 0
        let v = 0

        var result: SellersLedgerRowProps[] = sellers.map(s => {
            p = payins.filter(x => x.seller.email === s.email && x.state === PAYIN_STATE.SUCCEEDED)
            b = p.filter(x => x.payout === PAYOUT_STATE.PENDING).reduce((a, b) => a + b.amount, 0)
            v = p.filter(x => x.payout === PAYOUT_STATE.PAID).reduce((a, b) => a + b.amount, 0)

            return {
                id: s.id,
                email: s.email,
                state: s.state,
                balance: b,
                volume: v,
                display_balance: b.toFixed(2),
                display_volume: v.toFixed(2),
                country: s.country,
                onboarded: s.registration
            }
        })

        return result
    }

}
