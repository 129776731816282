import { Devices } from "../utilities";
import { PAYIN_STATE, PayinProps } from "../utilities/payins";
import { PaymentMethods } from "../utilities/paymentMethods";
import { PAYOUT_STATE } from "../utilities/payouts";
import { PSPs } from "../utilities/psp";
import { Buyers } from "./Buyers";
import { Disputes } from "./Disputes";
import { Sellers } from "./Sellers";

export const Payins: PayinProps[] =
    [
        { currency: "$", amount: 131.14, id: "ps_a2ab3725-2b0c-45a4-80d0-4c2ecde8e800", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[34], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 1, 0, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 89.88, id: "ps_52ca1275-8115-48da-b0a7-d0b1b06c4daa", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[29], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 1, 13, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 91.31, id: "ps_f380f2a6-5110-46ae-b6c5-8537e542b512", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[14], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 1, 9, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 281.23, id: "ps_3ae1c3f1-f4c3-41fa-b94c-21fe43a18fda", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[77], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 2, 14, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 108.8, id: "ps_b87753b2-58e9-4fa4-8619-126ba1de4ef2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[24], seller: Sellers[41], state: PAYIN_STATE.PENDING, date: new Date(2023, 0, 2, 21, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 50.26, id: "ps_ea74faba-0428-4c09-bc6c-7360e5bf5ce5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[12], seller: Sellers[65], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 2, 6, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 391.33, id: "ps_054bd798-62f6-4bc2-a4ef-6edadd6b78fc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[36], seller: Sellers[30], state: PAYIN_STATE.INITIATED, date: new Date(2023, 0, 2, 11, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 86.09, id: "ps_c1fa4b7a-348e-4acf-acbb-3f73b5682342", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[16], seller: Sellers[21], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 2, 8, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 39.07, id: "ps_e5140b01-161a-47e1-b987-96b72b0206b6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[51], seller: Sellers[80], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 2, 16, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 36.98, id: "ps_f2665fc2-a327-4d19-aed4-8646c408f38e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[83], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 2, 2, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 16.73, id: "ps_e9d66e30-7818-4c19-982a-e68626a44f8e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[53], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 2, 2, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 27.48, id: "ps_85d54a93-c8d7-42db-89c8-e478029d5c5f", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[88], seller: Sellers[22], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 3, 19, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 107.46, id: "ps_1274b5a6-5ba8-41dd-9493-ee3dbae192d5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[45], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 3, 2, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 71.4, id: "ps_930710b9-a5b2-4912-85cf-0f4ff30df77e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[94], seller: Sellers[61], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 3, 0, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 75.99, id: "ps_8e19bfa0-6c99-4089-af8d-f0dab5c394db", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[101], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 3, 3, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 167.46, id: "ps_5b14a5ee-91f0-48e0-969a-93c1bfe291bb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 4, 15, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 137.93, id: "ps_3347bce3-0bce-4a65-8e5a-a02af8f2ad01", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[13], seller: Sellers[5], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 0, 4, 19, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone, dispute: Disputes[0] },
        { currency: "$", amount: 97.05, id: "ps_409bfc27-e15c-460e-8996-4c0c85923e4b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[53], seller: Sellers[16], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 0, 4, 3, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[1] },
        { currency: "$", amount: 18.53, id: "ps_6814412f-632f-455c-97dc-defa12b52c9f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[92], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 4, 3, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 428.51, id: "ps_8b33ee0c-ba5c-49af-bcd4-c75113e177cf", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[26], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 4, 7, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 127.39, id: "ps_e0a8892f-d186-4472-8f7e-6fcd56681851", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[59], seller: Sellers[22], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 0, 4, 3, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[2] },
        { currency: "$", amount: 71.11, id: "ps_77d504d2-3e6e-4afb-90c4-eced3fff4be0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[60], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 4, 9, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 20.95, id: "ps_fa122cf7-8ff8-467d-9eb8-ebf7c81fb36a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[73], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 4, 18, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 19.86, id: "ps_385d279d-21de-4cee-af0a-c6329a4a95f7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 4, 19, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 379.4, id: "ps_fcdcce19-7238-4e2d-b5fb-d3499a621248", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[73], seller: Sellers[34], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 5, 13, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 48.02, id: "ps_c87eae9e-c61b-4b1a-91df-f4eee8c81aec", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[46], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 5, 22, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 56.73, id: "ps_22f2bb3e-465b-4e1d-b01a-778be202379d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[95], seller: Sellers[9], state: PAYIN_STATE.INITIATED, date: new Date(2023, 0, 5, 7, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 69.35, id: "ps_6676f627-5c3a-4634-bf74-47e0a70b2104", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[62], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 5, 14, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 467.68, id: "ps_6d02e350-47bf-4fdb-907d-b62731b33471", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 5, 6, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 432.01, id: "ps_8e9b0b33-90e0-4439-ac57-fb2d22803014", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 6, 19, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 172.85, id: "ps_4094c818-b689-4af3-a931-53b50cc541f5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[39], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 6, 0, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 26.35, id: "ps_2bf2099a-1082-4d75-9321-043d5eb2f716", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 6, 1, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 102.45, id: "ps_35ce11dc-5d14-40f3-ac8e-8118dd2b348a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[74], seller: Sellers[74], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 0, 6, 18, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone, dispute: Disputes[3] },
        { currency: "$", amount: 162.67, id: "ps_be9c36a1-54f3-4d97-8690-f2fdd771eec2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[18], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 6, 13, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 186.81, id: "ps_7ea03835-2912-4515-b215-87d1259c882e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[39], seller: Sellers[5], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 6, 5, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 166.18, id: "ps_04118667-ec92-499f-980a-5c83e9bcf03a", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[28], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 7, 15, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 52.26, id: "ps_ef1f118b-6d4d-4ecb-abfc-1a68067bb36f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[2], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 7, 23, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 82.58, id: "ps_a607e948-fd4a-4137-8092-751a9b4680d3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 7, 14, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 85.62, id: "ps_17a8051e-54aa-4eb3-8a6b-389fa99f281b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[83], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 7, 1, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 428.26, id: "ps_9bbd3568-6273-499c-84d4-271969dcc0d6", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[85], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 7, 5, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 21.16, id: "ps_f5dd0539-2481-48ba-a038-0b1970bd3aef", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[22], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 7, 3, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 39.32, id: "ps_f01df4bb-2099-44a1-ac23-7341f6114d82", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[4], seller: Sellers[72], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 7, 23, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 14.88, id: "ps_b3cf077c-79f5-43de-8f13-1bf773ba21a2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[87], seller: Sellers[19], state: PAYIN_STATE.PENDING, date: new Date(2023, 0, 7, 22, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 48.92, id: "ps_337e61d0-c323-4c14-a0a2-e1ebdb08962c", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[6], seller: Sellers[72], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 7, 8, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 85.36, id: "ps_115a9b9d-0295-463f-a68e-27318f6382ad", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 7, 19, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 96.52, id: "ps_3b2c99d6-bdda-4351-a58d-e4259afe88bc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[64], seller: Sellers[10], state: PAYIN_STATE.INITIATED, date: new Date(2023, 0, 8, 15, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 94.49, id: "ps_6f0ad3b2-d14f-4e5a-a827-fba7e23489e7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[82], seller: Sellers[78], state: PAYIN_STATE.PENDING, date: new Date(2023, 0, 8, 1, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 95.21, id: "ps_033b14da-02d3-4679-9afd-ef216d94a3e6", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[45], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 8, 13, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 16.67, id: "ps_0fa2f70c-d7c3-4825-a038-d562ffa76262", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[13], seller: Sellers[45], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 8, 20, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 18.82, id: "ps_03f74109-db7c-48f7-b56f-d5710449d412", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[91], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 8, 5, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 153, id: "ps_66cfe0a0-8dff-44aa-b9be-a29e3e1994b8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[80], seller: Sellers[1], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 8, 16, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 11.66, id: "ps_3fb2ee7f-6388-4654-b056-3a173bf16d32", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[22], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 8, 14, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 55.24, id: "ps_5add39bf-1a87-415d-b13d-01c6134331ce", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[71], seller: Sellers[76], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 0, 9, 16, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 63.74, id: "ps_fcc2d86e-de9c-4f33-998d-431e4b7a7fff", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[39], seller: Sellers[17], state: PAYIN_STATE.PENDING, date: new Date(2023, 0, 9, 14, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 83.52, id: "ps_f74d2c29-abe0-4fb5-a758-a95413fdf4af", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[87], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 9, 8, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 379.35, id: "ps_b2b6c9c6-c0ac-4dbd-b89e-3a3da8f2f7f8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[40], state: PAYIN_STATE.PENDING, date: new Date(2023, 0, 9, 11, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 26.58, id: "ps_0ec75880-f830-407a-99e8-969b656b77ca", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[21], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 9, 14, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 57.37, id: "ps_a191a271-6992-4173-8bb4-d933915b54a9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 9, 13, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 49.51, id: "ps_e474391e-1b22-4b55-b38e-522dc3fe0cd9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[17], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 9, 21, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 72.21, id: "ps_6a497b13-a89c-47ce-8267-451b16e47839", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[93], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 10, 16, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 13.77, id: "ps_24f3f2ed-fa78-4c5e-a61e-8ccdcc2b2563", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[36], seller: Sellers[85], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 0, 10, 13, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 43.54, id: "ps_ceb4a32a-1cc8-497f-a08a-c6a8346e7afd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[63], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 10, 5, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 12.92, id: "ps_4db68d49-5d7c-4022-a298-60b2f8381a65", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[99], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 10, 13, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 90.36, id: "ps_cb166a40-c563-4449-9e33-0da0a9b93881", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[96], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 10, 14, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 109.9, id: "ps_b7311165-ebfe-4e43-8c48-5dc0d0e2518f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[98], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 10, 1, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 15.15, id: "ps_e74a0582-8054-4463-ac52-52abb8f217e7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[50], seller: Sellers[51], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 0, 10, 17, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox, dispute: Disputes[4] },
        { currency: "$", amount: 430.32, id: "ps_fd646766-5628-47ed-b664-a348d90b1d64", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[102], seller: Sellers[23], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 10, 7, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 176.37, id: "ps_a472af7e-da8d-4edc-8964-89958e6279d4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[32], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 11, 13, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 19.03, id: "ps_2d93b162-7ffa-4256-8747-f50164bd6023", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[66], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 11, 12, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 65.44, id: "ps_a89fbbde-ebbb-4550-b4af-3b5785de6179", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[81], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 11, 15, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 114.13, id: "ps_944b1a7d-892b-4f86-879e-8394e11a5338", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 11, 10, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 493.63, id: "ps_fc361e34-b3ea-410d-9d00-e27b5d66d880", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[54], seller: Sellers[62], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 11, 23, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 250.95, id: "ps_253d68f1-a1e4-4194-a84e-b1bdb32a26b7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[63], seller: Sellers[38], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 11, 23, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.74, id: "ps_c52dbed8-828e-4f61-8cec-9714ede0f2f1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[56], seller: Sellers[14], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 11, 6, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 259.45, id: "ps_dec02da6-968e-4b0e-89c6-5c360ff576cc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[91], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 11, 9, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 136.98, id: "ps_937680e3-7981-4fc9-ab78-d7ceeebf47be", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[17], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 11, 14, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 24.57, id: "ps_8aad71ba-67f8-486a-8de5-22e81dd08232", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[2], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 12, 14, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 15.97, id: "ps_00523a2c-2526-446f-a390-79dc239aece0", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[49], seller: Sellers[76], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 12, 2, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 222.97, id: "ps_e32ba6dd-35e1-4f9d-8606-ca39aefa3112", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[97], seller: Sellers[70], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 12, 0, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 12.07, id: "ps_29aba89c-394a-4ceb-8874-4a00e453b43f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[103], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 13, 11, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 114.84, id: "ps_04fab0a0-25cc-4572-8b7c-e8431f84a056", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[51], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 13, 0, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 69.72, id: "ps_50e87353-7f90-4e5b-9e8b-b69279b899d9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[26], seller: Sellers[35], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 0, 13, 9, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[5] },
        { currency: "$", amount: 40.1, id: "ps_512a2ab5-5ac8-4953-b68c-7c72c6c19cdc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[63], seller: Sellers[40], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 13, 15, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.6, id: "ps_b5818df1-c3d1-4db9-9499-0f2bf93b3a82", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[104], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 14, 1, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 19.63, id: "ps_114841c2-68a7-4243-a032-16a6ef9eadd6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[66], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 14, 2, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 58.57, id: "ps_05a6f109-42fe-4685-b2f6-6d298d80ffe3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[70], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 14, 13, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 85.51, id: "ps_5b794e3e-4817-4cd0-b615-9ac7dc1f0907", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[11], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 14, 10, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 79.37, id: "ps_a7ba4cd5-2120-463d-8fd9-150b8048c272", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[55], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 15, 15, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 25.12, id: "ps_2a7d1d3e-e319-45e0-b23d-fb5d81f6b707", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[18], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 15, 20, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 35.45, id: "ps_c34a6c38-95d2-405c-af35-71db2fd4fe3c", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[37], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 15, 2, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 163.33, id: "ps_2832ef39-4c3a-46ee-81d3-35827508d37e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 16, 12, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 55.1, id: "ps_87df3b50-e648-49a6-ad48-0688ce3ef4a3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[40], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 16, 15, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.31, id: "ps_a938f453-a5fd-4979-a3d4-3cfe6c25afdb", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[101], seller: Sellers[43], state: PAYIN_STATE.INITIATED, date: new Date(2023, 0, 16, 5, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 79.18, id: "ps_c5aa949a-c20b-4858-89d0-62ce3240bd67", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[38], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 16, 7, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 432.09, id: "ps_0b6cac03-79ca-48b7-bf33-c07b797e645c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[57], seller: Sellers[47], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 0, 16, 1, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone, dispute: Disputes[6] },
        { currency: "$", amount: 63.69, id: "ps_5835ee5d-e5c6-4a0c-8589-b4e78f0ea73d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[42], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 0, 17, 10, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 44.24, id: "ps_e5b1c59b-eed4-482b-ab76-eef9ec5bed3d", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[68], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 17, 12, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 37.7, id: "ps_b22a8863-70ea-489c-aa61-baa156601305", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[55], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 17, 4, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 10.38, id: "ps_0f15c113-fdcc-4a4f-97ea-2bab113e22a6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[98], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 17, 15, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 42.33, id: "ps_75a39ec9-211b-4488-bb80-1775f6444283", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[14], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 17, 7, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 69.55, id: "ps_1d14ded3-98ae-42d2-b57b-1f6f715ee2aa", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[73], seller: Sellers[9], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 0, 18, 1, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 53.01, id: "ps_eccf0a32-2a10-4a9a-b145-eeb57b7b11ff", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[75], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 18, 14, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 143.49, id: "ps_399ce259-2dc0-4567-a185-9a25f66a5be9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[13], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 18, 18, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 19.62, id: "ps_bf27cc7a-9a59-4369-a857-39fbfb82081a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[72], seller: Sellers[13], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 18, 16, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 61.03, id: "ps_832cc237-d72f-42e1-ab45-52cd5fa85772", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[84], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 19, 3, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 182.44, id: "ps_f85af060-fa37-4e1c-b90e-248db819dad3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[35], seller: Sellers[26], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 19, 21, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 17.42, id: "ps_05782c86-5537-4d95-995b-4bbdf609514e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[20], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 19, 1, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 51.99, id: "ps_37db95f0-a97d-4405-8cd6-82289fe61f78", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[46], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 19, 22, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 77.36, id: "ps_9eac0f4c-61bb-4cf0-b853-aff021af2699", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[91], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 19, 7, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 22.16, id: "ps_f4e6ccf6-ded3-4f28-8efa-bdbe0cc174c3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[61], seller: Sellers[62], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 0, 20, 11, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 106.78, id: "ps_6d37975d-60ec-4dcb-af9f-e7d70f6f3b5a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[98], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 20, 16, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 63.36, id: "ps_34449084-81c6-4eef-b2e8-2ba69a05d85a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[37], seller: Sellers[44], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 0, 20, 0, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 11.61, id: "ps_d9372e16-513c-4514-ba75-6ab97dddc9e5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[15], seller: Sellers[79], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 0, 20, 21, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 63.02, id: "ps_87c9c5e8-15ab-4621-adc5-2993f5e7af79", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[87], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 20, 3, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 94.77, id: "ps_b13c3cc4-119b-4126-8ce3-44130b31d0ae", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[35], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 20, 13, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 95.11, id: "ps_83f547c8-1622-4499-97c3-876552d6f069", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[53], seller: Sellers[86], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 20, 14, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 91.32, id: "ps_98b69040-406a-4950-8635-b641cf0beb75", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[64], seller: Sellers[53], state: PAYIN_STATE.INITIATED, date: new Date(2023, 0, 20, 19, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 32.28, id: "ps_b5be8cee-1db5-4263-bd1d-07767e370407", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[7], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 20, 10, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 105.28, id: "ps_cd026be7-9cc4-4610-b3b9-ede940cb2efd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[74], seller: Sellers[25], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 0, 21, 2, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 10.79, id: "ps_9443df27-e9ff-4f58-bf19-b5ddcdee9cba", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[26], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 22, 21, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.94, id: "ps_11e119b3-a81d-4946-9b05-8eda525724dd", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[46], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 23, 8, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.2, id: "ps_c0b33e4b-5628-47d1-ae2c-fc17cf79d720", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[98], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 23, 17, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 78.98, id: "ps_ce1794b4-1df9-4e21-a0bc-d89ff4913745", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[50], seller: Sellers[60], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 23, 18, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 92.78, id: "ps_52b3d883-ea98-4641-9637-5a49b7650727", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[79], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 23, 21, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 81.79, id: "ps_79f3c767-96fd-4765-868e-0b39e86ab6f9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[56], seller: Sellers[9], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 23, 17, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 270.74, id: "ps_ab566b84-7519-44c7-800a-a7f74ba3b321", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[84], seller: Sellers[80], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 23, 19, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 16.73, id: "ps_c73cc2f0-ad0a-45dc-b6b6-d078227e6e2e", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[79], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 23, 12, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 194.96, id: "ps_e649088d-9487-447c-94eb-a4b1b548c78b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[61], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 23, 13, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 118.27, id: "ps_5c3e2476-87e5-4c76-95a5-a8a1a4ce4abb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[102], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 23, 3, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 71.93, id: "ps_5916abd1-4966-42ba-b1cc-42284433ff83", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[78], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 24, 17, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 13.37, id: "ps_a0019686-2006-452b-8762-fdb8f893af03", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[64], seller: Sellers[32], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 24, 4, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 153.7, id: "ps_bdd2c151-ef94-44b2-b42b-ff4a4b0a56a4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[35], state: PAYIN_STATE.PENDING, date: new Date(2023, 0, 24, 17, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 393.81, id: "ps_e8754188-b48b-4cda-a581-66557ca1d87e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[39], seller: Sellers[23], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 24, 13, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 126.82, id: "ps_80a7c37d-d48d-492f-848d-1f9a9a0c741d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[52], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 24, 14, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 82.44, id: "ps_b0878d42-ccd3-44fb-a113-275611832b66", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[84], seller: Sellers[5], state: PAYIN_STATE.INITIATED, date: new Date(2023, 0, 24, 9, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.05, id: "ps_65622f75-0e1e-4c9f-9ac7-ff309413714b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 24, 3, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 60.05, id: "ps_d9c5900f-9715-487e-9aac-c2bcdf49cbbf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[33], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 25, 14, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 32.02, id: "ps_dfccf93c-60ee-499f-bc4e-5de71e41c416", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[82], seller: Sellers[56], state: PAYIN_STATE.INITIATED, date: new Date(2023, 0, 25, 21, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 451.71, id: "ps_603bb052-4a5d-409e-a4e9-3e58319fe18d", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[80], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 25, 2, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 26.64, id: "ps_f032c8b8-b48a-45b8-b5e7-cc7bb99a0f2d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[30], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 25, 16, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 68.96, id: "ps_53bf3b43-64c3-42eb-badd-24b8f78720bd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[3], seller: Sellers[11], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 25, 23, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 64.02, id: "ps_628383d4-6ad2-40b9-87a7-67905bef50de", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[30], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 25, 4, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 81.21, id: "ps_36eb5b7c-5f7f-4c31-94ed-b98a659f09f6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[59], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 25, 9, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 44.12, id: "ps_713b1487-de0d-4cbd-94a7-f505da27cc82", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[35], seller: Sellers[63], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 25, 12, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 13.22, id: "ps_6bda903f-8477-479b-8e90-99cfe39f938b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[71], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 25, 22, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 57.37, id: "ps_82ececd5-3ac0-48ce-b87a-86e1aa009ef9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[40], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 25, 23, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 42.24, id: "ps_fbf38473-75d6-499b-be01-ced259e39f32", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[20], seller: Sellers[75], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 25, 21, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 37.75, id: "ps_e26c7aee-89c1-47ef-9776-c3b93ce97caa", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[24], seller: Sellers[4], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 25, 23, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 20.86, id: "ps_5c0d4f4f-7e51-427c-8420-c9ee092c9ad7", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[37], seller: Sellers[69], state: PAYIN_STATE.PENDING, date: new Date(2023, 0, 26, 12, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 40.18, id: "ps_0edfa3cc-dae6-443c-91df-0165ab0986b4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[71], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 26, 21, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 74.92, id: "ps_7f878d58-a3da-49a5-a4c1-b3dd119d038f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[69], seller: Sellers[43], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 26, 10, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 17.1, id: "ps_c226aeb7-25c1-4653-b9cd-496bc2f3dc7e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[8], seller: Sellers[10], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 26, 14, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 99.73, id: "ps_7ca3ff05-9d70-4543-af85-c5c7d6d2bdf3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[54], seller: Sellers[57], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 27, 2, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 72.35, id: "ps_290c304e-892c-4e46-950c-f9861ec45efd", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[27], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 27, 18, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 142.67, id: "ps_5515be7b-e8ff-48f1-b0cd-b61ea7e0025f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[41], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 27, 13, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 51.59, id: "ps_88bd39d3-b179-4864-b3e0-837ccd695eb0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[16], seller: Sellers[31], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 0, 27, 14, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[7] },
        { currency: "$", amount: 46.18, id: "ps_2b183f7a-1f4c-41d3-865e-c3a342492574", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[65], seller: Sellers[45], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 27, 15, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 90.35, id: "ps_6a2e6ed1-0e12-4417-bcaa-e1aa60b879f2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[61], seller: Sellers[23], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 27, 9, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 79.95, id: "ps_f4827273-35d9-4a69-b454-f875b491a0fd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[78], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 27, 3, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 184.8, id: "ps_b0902d5f-a0c5-4740-b58d-0fa3cffd6422", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[17], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 28, 11, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 309.77, id: "ps_22b23ca5-af2b-497c-96f6-b0911a79964e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[87], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 28, 12, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 115.97, id: "ps_5404132b-8b10-4790-90ba-6a8abe208f40", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 28, 4, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 74.61, id: "ps_702d29c1-719d-4bcb-80d7-8f944429377a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[32], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 28, 18, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 79.86, id: "ps_dd106ea5-36c3-46cb-ba47-7dc908f4b491", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[79], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 28, 9, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 89.33, id: "ps_0cd27de6-29fe-4da5-9344-16ada3b7d661", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[43], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 29, 10, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 368.5, id: "ps_780cd5e6-b8a8-4ebd-8f36-09471608315b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[58], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 29, 20, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 66.38, id: "ps_79e95a5b-fcc4-4339-bb2f-0d86f1a21052", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 29, 14, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 17.14, id: "ps_9425de45-a723-4ca2-82c9-61664c5f94cf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[45], seller: Sellers[49], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 0, 29, 5, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation, dispute: Disputes[8] },
        { currency: "$", amount: 63.67, id: "ps_31bdc7ab-bf52-46a4-a3f1-ddd06523bb29", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[17], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 0, 29, 20, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[9] },
        { currency: "$", amount: 95.35, id: "ps_f9d9b769-6a5d-499e-a457-298fa5dad909", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[24], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 29, 5, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 96.6, id: "ps_3ad2fbe7-549b-4388-9252-f4798208a549", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[80], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 29, 18, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 30.01, id: "ps_b72a07ee-ca69-4173-88d1-b78fe42f73cc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[7], seller: Sellers[46], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 0, 30, 21, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad, dispute: Disputes[10] },
        { currency: "$", amount: 87.9, id: "ps_6ce3e85d-75fc-4bec-9608-d6ba1c883417", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[78], seller: Sellers[4], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 0, 30, 16, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 128.72, id: "ps_bbf3ffd6-c268-4a95-aaad-82b4231a13a3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[60], seller: Sellers[47], state: PAYIN_STATE.PENDING, date: new Date(2023, 0, 30, 7, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 66.89, id: "ps_7adca5c5-952d-4a39-a1d9-f5dcc2217f68", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[60], seller: Sellers[7], state: PAYIN_STATE.FAILED, date: new Date(2023, 0, 30, 19, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 61.04, id: "ps_ce0e1b4b-b8ff-4838-8269-babfc549feba", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[32], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 30, 5, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 49.18, id: "ps_2ca0ebbb-fb5e-4867-8980-eb4ee8cbb61d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 30, 19, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 94.37, id: "ps_8797d763-7240-4e0b-baa4-f667697cb46b", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[51], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 30, 1, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 87.1, id: "ps_61669cb5-3e10-44e8-a750-88a972f46306", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[19], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 30, 8, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 475.67, id: "ps_1a70d645-7a9f-4170-9e0e-49682f228bbd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[63], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 31, 3, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 85.76, id: "ps_2be5a465-a7e9-4440-b16c-c5d2d0c75595", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 0, 31, 15, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 16.82, id: "ps_e2bf1694-442b-4b21-9405-9a246ca256db", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 1, 21, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 474.36, id: "ps_812292e4-e6a2-4c18-a7c0-f38bbae9693e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[77], seller: Sellers[67], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 1, 7, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 73.92, id: "ps_d761e8bf-9058-43ea-bdbb-92e8588ba850", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[103], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 1, 20, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 39.24, id: "ps_8d825353-7bb4-4685-ae3f-100c80c52c97", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[39], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 1, 18, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 55.45, id: "ps_34c2b0cf-4e8a-432c-9c72-d88ef98fc809", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[41], seller: Sellers[35], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 1, 0, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 240.11, id: "ps_9957485e-f253-44b3-8f1e-73edcc1580c0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[14], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 1, 2, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.82, id: "ps_c3cd94cb-f39f-4d53-b10f-9081eb890e67", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[81], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 2, 15, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 24.12, id: "ps_27242aaf-5402-4011-bb2e-c552171234fa", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[72], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 2, 14, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 255.56, id: "ps_b1922287-5857-4519-b3fa-c29ec779a79d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 2, 10, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.38, id: "ps_7689476a-2b3c-466b-83fc-da0a64a612e4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[74], seller: Sellers[77], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 3, 1, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 74.3, id: "ps_41f63f9a-e665-4441-93dd-935459edc34f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 3, 9, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 90, id: "ps_45e9fc85-ab51-46d8-b788-a75d16bd050f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[23], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 3, 23, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 11.99, id: "ps_5c70e81d-3486-428a-b8e7-3e293774917a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[38], seller: Sellers[54], state: PAYIN_STATE.PENDING, date: new Date(2023, 1, 3, 19, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 16.64, id: "ps_f8fa93e6-da13-4367-b828-8ad592c0cc33", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[77], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 3, 13, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 82.99, id: "ps_9b8a6e68-2a56-4568-a421-255686db9b8c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 3, 15, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 99.37, id: "ps_18cbe049-7cb9-4c9d-9475-26ce8a4c5392", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[11], seller: Sellers[60], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 3, 23, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 149.84, id: "ps_a2a09437-13e2-4294-ad74-bc1bb08136e5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[68], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 3, 8, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 33.62, id: "ps_f5bddfa0-c275-4f89-a237-3b3fe6f56837", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[67], seller: Sellers[41], state: PAYIN_STATE.PENDING, date: new Date(2023, 1, 4, 2, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 81.21, id: "ps_4a2077db-9643-41dc-8961-fe2ac9c4dcde", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[13], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 4, 19, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 79, id: "ps_b29ae3e3-8f83-44ce-a7dd-52498e856bce", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[41], seller: Sellers[52], state: PAYIN_STATE.PENDING, date: new Date(2023, 1, 4, 0, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 61.29, id: "ps_a292805f-063b-4cd3-a78d-3d3d22a5017f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[37], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 4, 16, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 289.73, id: "ps_bbaf9315-f06a-488f-b7df-4bb07f05a388", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[8], state: PAYIN_STATE.PENDING, date: new Date(2023, 1, 4, 14, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 28.96, id: "ps_ddebf158-3864-451f-a1e8-6a5e6b62937f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[1], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 1, 5, 11, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 29.54, id: "ps_0dca0787-8b9d-4080-b3a4-4d19e4e2b4e8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[96], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 5, 5, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 80.13, id: "ps_324953a2-3680-42c3-b5b5-19bdb61b886b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[31], seller: Sellers[63], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 5, 19, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 18.17, id: "ps_fb8481f8-884a-4212-829f-e87a21e1664d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[41], seller: Sellers[26], state: PAYIN_STATE.PENDING, date: new Date(2023, 1, 5, 18, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 61.58, id: "ps_d75cc5e6-61be-4536-a3c5-17349708e096", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[47], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 5, 10, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 12.02, id: "ps_3eee39e5-2aa2-4d22-93de-94f9c78381b2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[40], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 5, 16, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 91.65, id: "ps_3c4b58e8-bc9f-44ad-8942-64d009b451c9", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[63], seller: Sellers[20], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 6, 3, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 424.33, id: "ps_6f93ce7b-7026-4566-a2ef-1a0b9e49e2bc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[91], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 6, 6, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 82.18, id: "ps_393e85b1-1a32-4917-adfd-98c48053ed64", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 6, 12, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 461.56, id: "ps_bb45588b-5378-4e30-9923-c3dfc08cc1f3", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[66], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 6, 17, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 85.77, id: "ps_40889caa-d55e-46e8-aac3-be0adbb2b92f", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[19], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 6, 3, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 82.49, id: "ps_62592497-0382-4adf-a04a-31e94bf65480", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[77], seller: Sellers[21], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 6, 5, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 75.73, id: "ps_d7943618-8b4d-4673-86f4-39b46a6cd661", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[31], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 7, 10, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 79.58, id: "ps_43fc8bb9-b4a9-4834-b91d-10d6d785c45f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 7, 0, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 15.25, id: "ps_3d9a93cf-ed10-41b5-81ad-8fcc07c2cb8c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[21], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 7, 22, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 78.61, id: "ps_2d182a12-0308-41be-b5fe-489ecea072ca", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[84], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 7, 6, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 206.28, id: "ps_2e5708a2-abe1-47ac-8123-bbfe5cd63ab4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[71], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 8, 7, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 126.99, id: "ps_6b4aac61-2ab7-40f6-a4b9-3bbe73c78fc6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[25], seller: Sellers[4], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 8, 10, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 99.27, id: "ps_0ba58400-a1bd-4c3b-a2a4-fecf6d659f07", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[48], seller: Sellers[6], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 8, 8, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 244.15, id: "ps_525eb56e-d039-4d3e-bd9f-80aa504154ab", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[31], seller: Sellers[35], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 8, 21, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 14.68, id: "ps_79c6048e-7fd0-4ecb-8607-7b2b6ce16eec", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[83], seller: Sellers[80], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 1, 9, 11, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 42.96, id: "ps_dcaeeaef-96c9-4826-8237-9927bbca414a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[90], seller: Sellers[63], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 1, 9, 19, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 73.65, id: "ps_21f01b7b-4531-4c87-84e4-62e35bd026e4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[55], seller: Sellers[18], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 9, 10, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 40.92, id: "ps_ab2aab56-8b82-44a7-a205-dfbedd7cf4aa", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[66], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 10, 18, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 224.82, id: "ps_eb1a685e-ff2c-42ad-aa0b-eef4d47e1e47", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[104], seller: Sellers[9], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 1, 10, 21, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 125.02, id: "ps_e8fa9a9f-e173-4117-8584-e16bd7a6e1d8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[40], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 10, 11, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 232.46, id: "ps_b458de9d-1847-4dd4-ae7b-99c3788609f6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 11, 0, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 300.1, id: "ps_e2f8f35d-bc42-40d1-9753-6a899ba7007a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[10], seller: Sellers[55], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 11, 13, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 57.9, id: "ps_52a8cfcc-fc9c-4057-9dcc-f19147a2068c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[20], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 11, 2, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 69.27, id: "ps_02b93ac3-8e2c-437c-b13b-3cb8918ec74b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[56], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 11, 5, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 238.43, id: "ps_7cb080d2-7ccd-4d2e-8638-92619484a671", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[13], seller: Sellers[30], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 11, 13, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 56.69, id: "ps_dcab809c-4a83-4abb-a3da-af35092dadff", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[14], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 11, 11, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 52.99, id: "ps_2f9b1b3f-7d55-481a-96c5-eaaaf8f8d9af", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[82], seller: Sellers[17], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 12, 21, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 327.03, id: "ps_3ccc3e9f-5ece-42a4-867e-e7fb5e2759c9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[50], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 12, 3, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 395.41, id: "ps_d4c14126-9e86-4d27-81be-750efd84ca10", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 12, 22, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 446.32, id: "ps_3733d75f-d49f-49e0-b625-bd35552fa06a", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[12], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 12, 13, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 10.79, id: "ps_dc1621bd-3fd8-41cb-8cda-96a2dc65e1d9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[81], seller: Sellers[28], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 13, 3, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 49.88, id: "ps_7c570414-59f7-4b47-b290-446114410800", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[78], seller: Sellers[36], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 14, 1, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 157.97, id: "ps_39cec6d6-87a2-4c89-b30c-26e053b9add4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 14, 2, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 53.93, id: "ps_e7181e16-f1d9-4f5f-9b7f-d0c102c899c6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 14, 23, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 224.05, id: "ps_f4983f59-3a18-431e-88fe-00eda46d8ae5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[103], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 14, 13, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 60.8, id: "ps_9aebcc63-feff-42de-8352-0aaf50adf2cc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[53], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 14, 21, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 56.56, id: "ps_ad3e9ee3-1d8f-4a10-8f5b-ba362f2eb946", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[17], seller: Sellers[48], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 14, 4, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 68.79, id: "ps_31dfed6b-5ac0-4ca6-bf8a-964135b2c0db", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[53], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 14, 4, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 245.16, id: "ps_72ef4163-58fd-4424-9824-250db308a7a5", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[91], seller: Sellers[81], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 15, 7, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 63.35, id: "ps_fd3aecbd-2783-417b-9a86-512e77cc8a51", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[81], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 15, 1, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 68.84, id: "ps_9fddb256-febe-42f9-a738-7ad370ce4383", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[45], seller: Sellers[50], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 15, 15, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 229.91, id: "ps_641e13a6-10de-4a93-b5c2-6bc984fa2d96", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[33], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 15, 2, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 61.4, id: "ps_d57a2631-e862-4fba-a69d-c4975661984a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[92], seller: Sellers[6], state: PAYIN_STATE.REFUNDED, "isFraudolent": true, date: new Date(2023, 1, 15, 3, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 92.76, id: "ps_5ea9ba30-43fe-47d9-ac15-5a6c0260066a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 15, 7, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 63.9, id: "ps_d628d5ea-7d46-4754-8901-9f200316a5e5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 15, 23, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 96.76, id: "ps_86ea83b2-ee59-4ac1-b810-d9c9f2a5eba1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 16, 17, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 369.65, id: "ps_819569ae-40de-4cca-8646-93beaa2af3dc", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 16, 17, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 76.56, id: "ps_74a171c3-bd17-488b-8d7e-4fad28cc37b4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[4], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 16, 10, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 75.96, id: "ps_6bbe8c5b-d703-4553-9f69-fff93a9d161e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[51], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 16, 22, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 84.28, id: "ps_396d25fa-601f-400f-ab69-445d754cc5a1", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[96], seller: Sellers[62], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 16, 9, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 242.92, id: "ps_5a6efb1d-79cc-4448-9694-cb882854126d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[71], seller: Sellers[69], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 16, 9, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 179.85, id: "ps_8063355b-27d0-4fdc-8e92-3b9d1a75e9f1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[17], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 17, 17, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 27.6, id: "ps_cab1e594-d97c-453c-b0eb-16e62c843de7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[80], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 17, 9, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 458.21, id: "ps_5a9f6523-2b8f-485c-a7b3-0df68a6e1de0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[61], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 17, 5, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 410.69, id: "ps_e03ce179-46c8-4927-8248-2cb431b4de55", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[85], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 17, 9, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 89.21, id: "ps_a0b05ad1-dc1e-4564-9303-6d9e021f282d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[88], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 17, 3, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 91.08, id: "ps_018672ba-1eec-46e2-8343-6779339c6500", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[52], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 18, 6, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 11.9, id: "ps_e3eba0de-5dd7-4af1-b917-2f1e3c4b6c96", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[88], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 18, 3, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 69.48, id: "ps_287c49d1-82dc-474d-959a-be88aa9733ff", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[5], seller: Sellers[36], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 1, 18, 19, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows, dispute: Disputes[11] },
        { currency: "$", amount: 92.02, id: "ps_83a17cf5-e993-4afe-a8e8-a83375d3e687", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 18, 3, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 18.78, id: "ps_74d1108c-07f7-40a0-9071-94eef176dbfc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[30], seller: Sellers[67], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 18, 13, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 52.12, id: "ps_6f110074-48b2-4431-bbb9-48c11a2eda5c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[12], seller: Sellers[61], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 18, 23, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 152.38, id: "ps_19c7910b-d87c-4188-be26-e113746411d5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 19, 11, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 467.85, id: "ps_e39b4600-cf37-4906-bfe5-3eafb4a5e926", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[52], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 19, 16, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 28.82, id: "ps_8433f172-b751-473e-af20-67feabc8436e", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[94], seller: Sellers[73], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 19, 2, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 133.24, id: "ps_4ac20924-706c-49ca-badf-29109289fd76", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[11], seller: Sellers[47], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 19, 19, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.61, id: "ps_53ab9f50-78ca-496b-91ed-3670f7fb9249", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[74], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 20, 21, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 49.7, id: "ps_97f6a61e-f797-4c06-b2ca-37cf84a6a84b", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[52], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 20, 9, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 279.96, id: "ps_06e876c6-c74f-49ee-9b50-9fe9cb2ff582", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[89], seller: Sellers[63], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 21, 2, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 33.65, id: "ps_16cdca92-69c2-435f-936c-c5c905ea62dd", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[81], seller: Sellers[26], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 1, 21, 21, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[12] },
        { currency: "$", amount: 28.62, id: "ps_cf3bb16d-d5f6-45a8-a7e0-64ce530a8f06", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[90], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 21, 21, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 181.57, id: "ps_5caa5dce-9217-4e90-89fe-4d3ac1833315", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[58], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 21, 19, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 36.64, id: "ps_6c4461e9-0f50-4eda-9e6a-37cc9b155302", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[72], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 21, 9, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 94.92, id: "ps_06c9c99f-b98c-4905-89b5-9606a624f064", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[48], seller: Sellers[57], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 21, 5, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 410.27, id: "ps_a668fe12-eb69-4da5-a0e3-6eba629db324", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[36], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 21, 8, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 173.18, id: "ps_6a0461a4-d6f3-4dd2-b589-db82e377d7f7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[65], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 21, 5, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 55.89, id: "ps_9e5c9749-6075-4eec-b78c-27a41ccf05bf", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[60], seller: Sellers[85], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 1, 21, 2, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 77.72, id: "ps_8527a500-ff9e-44e5-8106-678e5af5b48c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[60], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 22, 15, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 87.34, id: "ps_715f9128-49e4-4e78-ba90-c8009ccf9e1c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[44], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 1, 22, 9, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 59.94, id: "ps_724f9dd1-e8c8-4d63-90fe-0ee2a3093aa1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[50], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 22, 21, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 108.44, id: "ps_e08f5a0d-065e-44c1-8237-6527096c25b7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[97], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 22, 5, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 42.21, id: "ps_625b53ed-8077-488c-8016-9b2aa94197ee", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[41], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 22, 1, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 99.54, id: "ps_69f00304-0c69-4abd-9a44-1d4c830ae537", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[41], seller: Sellers[60], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 22, 4, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 60.68, id: "ps_678d35d1-7e2d-41b9-a2c0-cbb6693dff77", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[61], seller: Sellers[63], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 22, 15, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 75.74, id: "ps_2313f7f8-233d-43bb-8354-5c2884e488fe", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[89], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 22, 0, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 56.31, id: "ps_4c52144d-5ce0-4fbf-bded-754f939d30d5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[80], seller: Sellers[82], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 1, 22, 16, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad, dispute: Disputes[13] },
        { currency: "$", amount: 296.63, id: "ps_36c0ba44-79b6-461d-8780-463e282054ef", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[10], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 23, 18, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 22.7, id: "ps_9689e0cf-6a39-4d4c-956e-693557678830", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[79], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 23, 21, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 68.65, id: "ps_0cdb924e-07ed-497b-874f-402d06f17101", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 23, 7, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 31.28, id: "ps_24514ce3-c4d1-43bd-a719-397e73155a0b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 23, 17, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 16.14, id: "ps_958247b7-20b5-4ea2-97db-74223186d541", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 23, 21, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 58.92, id: "ps_504ba75f-0a33-4d7e-86db-86dcc4ab9ef2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[72], seller: Sellers[69], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 24, 1, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 57.93, id: "ps_9b9021ad-79dd-4a14-89ba-7690cc1129de", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[65], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 24, 18, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 90.01, id: "ps_57cead3f-79d8-4315-af77-5ab0fc7f546e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 24, 2, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 72.88, id: "ps_726f13db-6470-4b92-b611-17ee2bc77211", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[80], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 24, 11, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 491.62, id: "ps_61251367-d4e7-4111-9a51-d20e82d40176", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[34], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 24, 20, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 218.93, id: "ps_3f53310c-73ce-4e64-b381-330a3fbd789d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 24, 8, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 156.94, id: "ps_bc414cd0-b79b-4b4c-bc1d-790814d35077", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[72], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 24, 4, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 43.13, id: "ps_5f799efa-7ce6-430c-ab83-ca5d1bc9c413", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[27], seller: Sellers[73], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 24, 20, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 13.95, id: "ps_444db0b8-8f70-4f7a-90be-6c55995fe4f5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 24, 15, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 423.81, id: "ps_18564264-0736-494e-b6db-d6ffb0114382", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[41], state: PAYIN_STATE.PENDING, date: new Date(2023, 1, 24, 20, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 276.12, id: "ps_7a1349cc-19ba-451f-9e0d-981854b97ebb", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[38], seller: Sellers[67], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 1, 25, 2, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 16, id: "ps_a8e176a8-2a3c-43aa-a81b-9fc315452e87", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[78], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 25, 22, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 48.52, id: "ps_cba09cce-6a77-4013-9b76-46fd4262e58c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[73], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 25, 14, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 76.78, id: "ps_55f79e53-5376-473a-aec4-ebd3a6a88406", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[12], seller: Sellers[19], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 25, 10, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 31.63, id: "ps_beeb74e4-4898-4f71-96ce-34ba94a3f8b2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 25, 4, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 186.98, id: "ps_60375d55-e05d-4b40-8c20-e42f69cc40b2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[82], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 25, 19, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 60.17, id: "ps_042d3b3a-d9af-44bb-8558-eca5dafe6a74", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[51], seller: Sellers[14], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 1, 26, 21, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 333.14, id: "ps_dd7bb336-80d2-4662-b27d-d92bc42089cf", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[57], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 26, 6, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 31.3, id: "ps_04168ea1-520c-4540-b3d8-8f4b01528566", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[104], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 26, 23, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 454.19, id: "ps_193b16b1-c929-4f8a-875b-e6486756dac7", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[95], seller: Sellers[5], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 1, 26, 20, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone, dispute: Disputes[14] },
        { currency: "$", amount: 176.55, id: "ps_12812ea9-d745-4ffc-bd62-c24a6d0a2034", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[34], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 26, 21, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 344.94, id: "ps_09ca3d9b-7a48-431f-ae74-c070506efba2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[82], seller: Sellers[1], state: PAYIN_STATE.PENDING, date: new Date(2023, 1, 26, 10, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 78.22, id: "ps_56a207e2-c283-4191-b70d-181b43226619", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[97], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 26, 14, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 24.79, id: "ps_80295226-25dd-45b4-b661-88afb5de5e50", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 26, 16, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 41.34, id: "ps_71b36a24-2881-4a33-b545-7f144cdba3b6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[31], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 27, 6, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 343.07, id: "ps_cde7ca05-9a1e-473d-b825-e85bc63303ff", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[65], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 27, 16, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 131.86, id: "ps_983eabe4-cf29-453f-bb2b-ce6b49d6bd4b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[10], seller: Sellers[68], state: PAYIN_STATE.INITIATED, date: new Date(2023, 1, 27, 17, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 62.55, id: "ps_b2911c2c-1c1d-4ccf-b2f4-35cfeca624c8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[69], seller: Sellers[55], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 27, 2, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 100.74, id: "ps_26975423-e894-4bc4-88e1-29981e7910ca", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[41], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 27, 1, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 18.96, id: "ps_f6b928bd-1511-4c29-bd54-68bc6c33b41c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[9], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 28, 18, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 262.34, id: "ps_8139af7d-5a9b-4c54-97fd-28edeb43731e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[29], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 28, 13, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 71.92, id: "ps_0b77b7e9-4785-49d8-9cae-5811474472ae", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[49], state: PAYIN_STATE.FAILED, date: new Date(2023, 1, 28, 7, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 36.58, id: "ps_ac5cbd68-62c2-45d6-990d-14745b36aaa5", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[44], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 1, 28, 8, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 93.15, id: "ps_f28b3e08-a6ff-4e8a-a3c2-6261f3bf101b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[2], seller: Sellers[58], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 1, 28, 2, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 119.84, id: "ps_97139ccc-cf15-44f2-b224-08ed59376bb0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 9, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 91.88, id: "ps_33ae28b3-3d9a-4c80-8706-ae45f4e9d537", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[45], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 18, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 418.82, id: "ps_dba13529-d6c9-4a42-849c-e4daabe500f3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 18, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 51.58, id: "ps_de50b4d1-821a-4c71-95bf-5a8a5641580c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[94], seller: Sellers[45], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 1, 21, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 74.72, id: "ps_8dafd8d5-6977-4eb8-bfa3-4117a38d0cf0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[96], seller: Sellers[9], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 2, 1, 14, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 65.27, id: "ps_c71ac388-e3ee-47d5-8676-1e468834c999", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[88], seller: Sellers[75], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 1, 8, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 78.57, id: "ps_7e0324c3-6c13-4f69-ad2a-a7ae703f382d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[10], seller: Sellers[27], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 1, 19, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 402.56, id: "ps_434cb750-dcf3-42ef-995a-ab6afe14cc2a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 3, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 27.79, id: "ps_0e6fb073-2a62-4e96-85f9-0fa22cec557d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[46], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 9, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 174.44, id: "ps_28e01aa7-47b7-47bb-8ef5-8b328f176092", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 0, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 478.09, id: "ps_fb8ff7e7-32e5-4ab2-a8fd-55368484d91c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[62], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 1, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 73.34, id: "ps_ebe960e0-1619-4464-aed9-ff0eae456a7c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[17], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 5, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 71.27, id: "ps_18771b33-c2e0-414e-aade-23ca9e72f9f1", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[95], seller: Sellers[57], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 1, 18, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 154.4, id: "ps_a0597208-fb67-4400-bc21-6181e1ad6488", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[7], seller: Sellers[44], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 1, 3, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 236.85, id: "ps_27b5b3ec-8fde-4109-a995-270767e41b44", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[88], seller: Sellers[40], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 1, 0, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 47.83, id: "ps_bca74464-ac71-42b2-bd2a-f5b34acdd2a4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[34], seller: Sellers[82], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 1, 16, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 40.25, id: "ps_fa564d9c-3ff2-4f23-bb19-4597b8a18097", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[95], seller: Sellers[17], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 1, 23, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 66.54, id: "ps_4ee1a9b6-bc80-4d81-b84b-6a986a68918f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[60], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 16, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 41.71, id: "ps_a765e974-ca08-42d9-906e-4a07c1bee04d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[87], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 1, 13, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 34.94, id: "ps_3277389d-9d4f-458a-b837-020275c30d25", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 1, 19, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 88.57, id: "ps_acf636e3-0f96-4f2e-91b9-d1d4f361c4c8", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[76], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 2, 7, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 76.29, id: "ps_2538ae86-f743-4390-b6f6-1afd644b2287", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 2, 15, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 368.96, id: "ps_b89497fd-7fe5-4eb9-9716-379c96f9697b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[59], seller: Sellers[18], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 2, 18, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 192.87, id: "ps_8f4fab43-b786-4e80-b694-f78547c50672", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[22], seller: Sellers[65], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 2, 23, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 62.07, id: "ps_d22ff45a-b6a5-48ca-8216-f55e07b012cb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[55], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 2, 10, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.67, id: "ps_ebaa61c6-66a5-48fe-aec0-e348dd379060", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[2], seller: Sellers[77], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 2, 2, 21, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 275.01, id: "ps_30b20fbc-e7d1-4173-ae18-88d167116b69", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[34], seller: Sellers[73], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 2, 15, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 46.52, id: "ps_0d60c690-a0a7-4fb9-b490-22ddd640b703", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[70], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 2, 8, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 29.55, id: "ps_83859b58-5e9c-4759-9958-61668f271b69", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[90], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 2, 16, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 17.82, id: "ps_d5d340fc-c647-40e2-b3c8-71d8b616195d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[99], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 2, 0, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 243.3, id: "ps_a2995d68-52f9-4c04-9a59-ea47d74e3e7d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[29], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 2, 2, 23, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 18.49, id: "ps_a3f289f0-1d80-4bd4-9a21-2e70946ed4a6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[33], seller: Sellers[12], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 2, 16, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 42.32, id: "ps_c06243f5-62fc-4ac6-b50b-b1bfaf39f677", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[9], seller: Sellers[29], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 2, 3, 15, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone, dispute: Disputes[15] },
        { currency: "$", amount: 46.91, id: "ps_42b282f9-e7e8-4500-a605-451bf0ac4fa7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[30], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 3, 2, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 107.44, id: "ps_64addb18-7ebc-4445-9f16-d143cd7f579a", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[59], seller: Sellers[34], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 2, 3, 9, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[16] },
        { currency: "$", amount: 144.06, id: "ps_a0d0c93c-df7f-4023-a0c4-9c5f316af4e2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[77], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 3, 10, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 303.15, id: "ps_6120400f-b8d3-4a21-8147-812fc9d8b09b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[8], seller: Sellers[1], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 3, 6, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 464.74, id: "ps_546deaa4-3299-495e-83c6-4fcf204c971f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 3, 8, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 115.37, id: "ps_48eeeb2e-1174-4e97-94e0-3fe8a83a5bbf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 3, 19, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 161.96, id: "ps_dfa2938d-03f9-4185-9928-88b7cd814314", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 3, 15, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 92.62, id: "ps_fc3f806f-fecc-4fd0-88d3-91ed1a95e3c0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[14], seller: Sellers[17], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 3, 8, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 47.62, id: "ps_8779c397-a07c-4f35-bc9f-ffa4e42f4daf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[40], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 3, 16, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 349.39, id: "ps_44b7e8ae-1220-4893-bc9a-ef527babd770", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[23], seller: Sellers[76], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 3, 14, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 40.47, id: "ps_491e0395-ac3c-429c-8903-85309ae6e496", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[51], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 3, 17, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 144.07, id: "ps_92429913-88da-4720-b3bc-c41360f2fc79", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[53], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 4, 2, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 195.81, id: "ps_a6332987-5ffb-4e57-982e-cc7a1c294f02", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[35], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 4, 19, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 60.44, id: "ps_8196b03b-f09d-421a-9702-70b4fb5ac027", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[52], seller: Sellers[4], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 4, 5, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 21.32, id: "ps_e7df1293-6e23-41e3-b962-30bcc6d1c78c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[103], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 4, 14, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 13.5, id: "ps_7e6399d6-ce2d-4ba8-b10e-8e27f6c8c7f4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[85], seller: Sellers[69], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 4, 18, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 17.88, id: "ps_74c5f95f-f217-4a69-8490-d21046cb8bc2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[62], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 4, 11, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 194.81, id: "ps_e33ffc09-18c3-4cbf-b032-97e21f15e803", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[45], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 4, 22, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 81.61, id: "ps_9eabe483-cc80-4382-928b-665a86de55d9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[16], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 4, 17, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 77.45, id: "ps_b9efd89b-3ee5-4464-9679-c7dc3ff9f3a2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[82], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 5, 16, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 95.7, id: "ps_383ae4a9-46ba-4cac-82c3-817c5ce01e54", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[100], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 5, 15, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 279.7, id: "ps_097c6e15-2b8f-499d-8733-b29e04c2a9d1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[73], seller: Sellers[38], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 5, 1, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 40.68, id: "ps_d93f16a1-737c-4af3-902a-3ab9dbf69dfd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[80], seller: Sellers[57], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 2, 5, 10, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[17] },
        { currency: "$", amount: 81.91, id: "ps_f1799edd-cad9-4e02-9e96-2aab8c9d0870", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[68], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 5, 22, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 241.57, id: "ps_71821b51-8de4-4e4f-a264-03386403ddd3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[10], seller: Sellers[33], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 5, 15, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 159.38, id: "ps_b9360557-1eae-4afe-8f6f-014a70403cf0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[84], seller: Sellers[12], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 6, 5, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 35.38, id: "ps_59520da8-70c7-4789-aa5e-15629a5db9c9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[62], seller: Sellers[68], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 6, 22, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 98.69, id: "ps_4426b140-68e3-4ace-a06d-dc9daf76f946", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[50], seller: Sellers[7], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 6, 0, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 174.19, id: "ps_c0eb13e0-f601-475b-8199-c0db4dbd4c47", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[4], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 6, 6, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 25.73, id: "ps_0ed0f8f4-fea5-4931-89e6-cea36e4b0d1c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[15], seller: Sellers[82], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 6, 12, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 428.27, id: "ps_67aac2eb-8695-4f1f-a802-ed9506af656e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[33], seller: Sellers[32], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 2, 6, 7, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 93.64, id: "ps_033a31d2-e23b-494d-a5ef-2bc5d735d04d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[99], seller: Sellers[81], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 7, 3, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 72.55, id: "ps_af39359d-e559-4352-9a34-5ab2ae200d78", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[67], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 7, 11, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 37.64, id: "ps_83cc41a7-e15a-421f-9130-bdcda1934485", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[2], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 7, 22, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 128.86, id: "ps_d5fff9a2-df15-473a-9165-d2289eb167d4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[75], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 7, 10, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 299.36, id: "ps_80e2d019-5ac2-4886-a14c-3468d29a5c3c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 7, 13, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 45.22, id: "ps_e7ab190d-fc91-4fc8-8a2c-2c41f18a79ee", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[7], seller: Sellers[72], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 7, 5, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.27, id: "ps_d0bc230e-7609-4a75-bc6f-dba3b32c83fa", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[98], seller: Sellers[77], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 7, 13, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 193.46, id: "ps_55c5cb7e-69c3-4034-a27c-0b16517a3144", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 7, 23, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.76, id: "ps_b9f532f9-d606-4fcf-b6f4-9e091cd71820", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[93], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 8, 6, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 89.44, id: "ps_2b597cde-1aaf-4b6b-9d92-1b54fd8f85de", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[69], seller: Sellers[18], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 8, 6, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 60.86, id: "ps_9c315182-6eb0-4358-bdf2-1638ba5690f0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[101], seller: Sellers[13], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 8, 16, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 247.7, id: "ps_9f6d4a31-be99-408e-bdbb-e3e263674d5b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[20], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 9, 10, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 3.22, id: "ps_a94c9b26-e996-4082-8cb9-1b822fbe3b7e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[29], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 2, 9, 20, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 262.29, id: "ps_0c0a71c5-b7f2-4e95-af96-20052cc64c64", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[47], seller: Sellers[25], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 9, 16, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 63.78, id: "ps_a5301779-3950-486e-9762-143aa8db6055", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[12], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 9, 0, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 49.72, id: "ps_536c961e-b79f-4ca5-b435-9134baf388bc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[56], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 9, 16, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 59.37, id: "ps_728672ed-cf68-40cb-aebc-52ba77223fd0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[46], seller: Sellers[43], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 9, 16, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 163.48, id: "ps_de0038fb-728c-468a-81b2-3f65605d4cbc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 9, 22, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 15.59, id: "ps_507b4e34-4d1d-4f38-9b9a-1ef0145db96a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[55], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 9, 16, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 10.46, id: "ps_c9f584e2-6cf0-45fa-82b4-f9e86f176fdf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[39], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 9, 11, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 55.74, id: "ps_dbaa3c3d-f6d0-43fa-ab6c-671fd0cbefc4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[29], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 10, 0, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 21.48, id: "ps_5b8521f1-451f-44d9-9ce1-6a13f318bfc9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[101], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 11, 23, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 20.05, id: "ps_c666552d-f5a6-49b4-b832-c1750d66827b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[86], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 12, 10, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 80.09, id: "ps_f2e60fc0-1490-4803-a0c6-93b888ae00ca", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 12, 7, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 12.67, id: "ps_a1d24256-4a8f-41c4-8752-a85aa462af0c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[32], seller: Sellers[23], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 12, 0, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 29.41, id: "ps_7d090ada-b914-4235-b752-89283eaecb6d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[4], seller: Sellers[62], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 12, 3, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 119.79, id: "ps_df44f111-2188-4d0c-83d8-8c41da1e5031", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[43], seller: Sellers[48], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 12, 14, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 43.06, id: "ps_9a0f2ca3-0a6a-4b91-9ac8-d0ee129d3953", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[102], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 12, 5, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 378.02, id: "ps_964e077d-1d6c-4250-bed1-bdd121c1b0d8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[75], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 12, 13, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 87.47, id: "ps_b5fc522e-7275-4745-bc93-9a182d6f6ac4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 12, 16, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 207.61, id: "ps_9d0d0f65-995c-45e6-acd8-31441cc229b8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 12, 18, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 78.31, id: "ps_ddc14fb6-60a5-4070-91d3-118c3eb6248f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[11], seller: Sellers[55], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 13, 23, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 67.09, id: "ps_5f1f5529-787e-4b0d-8bf6-ba6635b5cbc4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[36], seller: Sellers[79], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 13, 7, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 14.47, id: "ps_b5a96fa6-9462-4b0e-b7cf-6336663220ea", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[40], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 13, 14, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 49.17, id: "ps_5ece7cc3-ff92-4253-98f1-cb74da1739f7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[57], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 14, 4, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 12.82, id: "ps_7be444ed-9a22-49ab-83ee-19deb8201c8c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[95], seller: Sellers[6], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 14, 20, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 93.57, id: "ps_4dde69d4-597f-460f-8e3f-0cac1a5185b5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 14, 8, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 208.92, id: "ps_ff8dee07-eeae-4b2a-a193-a5feb9bf6a23", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[37], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 14, 9, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 80.27, id: "ps_addb6ea0-6688-4830-8e42-ae2ec146b85a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 15, 7, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 57.06, id: "ps_7f65d43f-e6f6-49d5-b90c-029a26c2286c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[60], seller: Sellers[19], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 15, 5, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 15.25, id: "ps_77cea250-486b-406d-bb99-9f1dc07d0ec0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[74], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 15, 17, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 62.87, id: "ps_62a45d10-cfde-411e-a13e-b44096856699", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[19], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 15, 11, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 61.94, id: "ps_fe00c19e-7383-4feb-9aa3-1330d337f427", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[90], seller: Sellers[67], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 15, 23, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 15.98, id: "ps_af54d623-3ff6-40e2-ba57-9d4bb211c2fa", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[44], seller: Sellers[67], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 15, 18, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 89.34, id: "ps_b17089c5-f729-4cab-a50f-594efe13902c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[102], seller: Sellers[67], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 16, 16, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 18.48, id: "ps_e2090a33-6f83-44f2-8266-cd659a235e51", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[71], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 16, 8, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 434.2, id: "ps_15b692f3-a926-4ea5-98d0-ad352a491349", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[51], seller: Sellers[10], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 16, 23, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 51.71, id: "ps_fa995223-5e7a-452d-948d-46937cdc7c59", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[98], seller: Sellers[30], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 16, 1, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 13.09, id: "ps_9747af01-61ae-4da4-bd5b-f92f24befabd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[59], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 16, 1, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 16.41, id: "ps_a8c6d2bb-0d71-46c1-80d0-858a8c2da939", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[68], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 2, 17, 12, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 22.95, id: "ps_9b1198af-426f-460d-a31a-47341136fc58", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[77], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 17, 13, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 443.53, id: "ps_ed133ca9-3ed6-4ca3-b89a-e941cc3363e9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[81], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 17, 5, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 37.1, id: "ps_07916759-6723-46ea-a8ce-b7bd58c47999", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 17, 6, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 372.17, id: "ps_ce6012a5-3cd8-41e5-9b6f-604fa2fb8bfc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[17], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 17, 22, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 482.37, id: "ps_22d39a4f-9144-45e6-81a6-a042a48b2099", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[91], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 18, 1, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 99.65, id: "ps_3582f17e-5c77-4444-91f3-6ceb7d68ecba", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[6], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 18, 14, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 31.74, id: "ps_d77e0206-222a-4f5b-b003-97aae148f8b5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[53], seller: Sellers[19], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 2, 18, 22, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.31, id: "ps_09a0b915-0336-4ef2-8078-6972189a8f9a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[35], seller: Sellers[29], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 18, 3, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 15.58, id: "ps_8c1a31dc-5a7a-486f-9562-26a4b88faecd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[32], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 18, 12, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 87.69, id: "ps_ab3787f8-41a6-4667-a43a-d95bb7c98cbd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[30], seller: Sellers[60], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 18, 15, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 26.83, id: "ps_d18ec55c-b1fd-4ed5-aae5-b49640d5c181", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[25], seller: Sellers[77], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 18, 12, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 45.18, id: "ps_4fc481c4-f473-4959-9b30-29b8cd6adf09", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[57], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 18, 11, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 429.44, id: "ps_d246348d-9806-4440-87fb-63f9930af121", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[50], seller: Sellers[78], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 19, 15, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 256.23, id: "ps_dfd6fc14-657c-4612-b0ce-7d44518a0345", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[52], seller: Sellers[18], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 2, 19, 3, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 69.22, id: "ps_25f8e6ab-4d7b-4e58-a94f-0ee040de9d9e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[33], seller: Sellers[50], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 19, 3, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 99.66, id: "ps_b42a2ee7-3dc6-4871-91fe-05cc8701f114", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[45], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 19, 9, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 10.4, id: "ps_1d1f7a58-be24-4ca0-8619-08a3665cf837", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[7], seller: Sellers[48], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 2, 19, 18, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone, dispute: Disputes[18] },
        { currency: "$", amount: 402.31, id: "ps_4b0b7ff7-2cc6-4c60-88d1-bff555594bf0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[88], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 20, 20, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 60.23, id: "ps_01420012-cb52-4052-a35c-6de6dd2f247d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[93], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 20, 22, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 59.56, id: "ps_6bc8b1c5-f298-4311-b7ff-8f22a48b38da", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 20, 1, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 12.53, id: "ps_88e6f613-5125-45dd-a8b9-be97b547cfae", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[74], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 20, 13, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 377.55, id: "ps_f86df20c-a53b-4644-9641-2c9cfb147f83", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[68], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 20, 3, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 197.76, id: "ps_95813613-07bd-4722-920e-f151002d2ea4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 21, 0, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 24.27, id: "ps_5b113906-3885-4dca-a29d-fdd93434e36d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[99], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 21, 1, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 86.49, id: "ps_b5e0f20d-ed81-4305-9e24-42b32c4931d1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[45], seller: Sellers[34], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 21, 1, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 93.84, id: "ps_1b311741-2c14-416c-b3ad-7fa811a211b4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[73], seller: Sellers[38], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 22, 6, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 315.27, id: "ps_fe0e74dc-8885-4641-ab41-253da407712c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[7], seller: Sellers[20], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 2, 22, 0, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid, dispute: Disputes[19] },
        { currency: "$", amount: 270.46, id: "ps_fabb66cf-609f-4a14-bf4e-c90f46418ffb", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[18], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 22, 3, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 56.16, id: "ps_0fca60ce-2200-41ca-858b-e55de4048021", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[60], seller: Sellers[7], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 22, 10, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 132.19, id: "ps_3b6bf8e9-6ecf-4364-8206-d13f260735f0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[98], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 22, 3, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 22.52, id: "ps_ff1c3cf7-53da-4812-a169-2d8a67ee53a2", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[61], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 23, 5, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 283.28, id: "ps_8e2b1f72-ae9b-4bea-90b3-8d3e2edcd5de", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[63], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 23, 8, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 67.6, id: "ps_2412c544-915e-45ab-8028-0af49aa219f3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[48], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 24, 3, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 55.19, id: "ps_54f719ef-baff-4cba-be9e-13a26491a2cd", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[79], seller: Sellers[30], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 24, 13, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 231.84, id: "ps_9f1dc8ec-5093-4656-844f-c5d995974239", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[20], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 24, 10, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 13.03, id: "ps_94e38cbe-1c29-4c30-8354-a0da441f457e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[87], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 24, 1, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 11.76, id: "ps_b5f2ec00-908b-47c6-8853-d2c571c71ff4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[58], seller: Sellers[15], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 24, 23, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 68.48, id: "ps_847b950e-0915-47cf-808c-125fa338d2d2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[55], seller: Sellers[80], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 25, 22, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 484.68, id: "ps_7fda763a-52c6-45ad-80ec-b5ba9be8c37d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[83], seller: Sellers[67], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 25, 5, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 85.71, id: "ps_d09a66ef-241a-4cc6-bad8-cc71885cd585", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[63], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 25, 6, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 260.56, id: "ps_600050f7-eee8-477a-a91f-89eee2c6b1c9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[24], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 25, 13, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 16.51, id: "ps_9067d622-487f-4403-b0cb-e864c93ea34a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[45], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 25, 9, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 92.61, id: "ps_fbc3798c-689b-4845-8f89-bbad556ce174", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[39], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 26, 12, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 75.52, id: "ps_434cb08e-fe9e-4992-b67f-a087f9660cf4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[97], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 26, 21, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 103.83, id: "ps_876fabdd-ebd9-4614-8d1a-429cb4d8ad74", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[34], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 26, 13, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 19.44, id: "ps_adb90129-4c17-42dd-9654-507e04c9ea28", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[87], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 27, 20, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 75.5, id: "ps_ed096fd7-483a-4a35-8a11-b5dc0535d9c5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[87], seller: Sellers[22], state: PAYIN_STATE.INITIATED, date: new Date(2023, 2, 27, 19, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 92.01, id: "ps_9e7e4de9-7d47-4204-8a7b-4ca9334af2c0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[78], seller: Sellers[29], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 2, 27, 5, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows, dispute: Disputes[20] },
        { currency: "$", amount: 13.41, id: "ps_f5d635e7-cb7d-45b2-babf-80327ed89058", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[2], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 27, 6, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 82.13, id: "ps_d3666a9a-314e-4741-a990-29af96529cf2", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[36], seller: Sellers[67], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 27, 10, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 74.96, id: "ps_1ae7e08c-e0d9-4ef0-8c89-5ffaf4fab7aa", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[3], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 27, 16, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 188.44, id: "ps_8ccaca32-65dc-44d9-9642-946dd487eb40", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[69], seller: Sellers[45], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 2, 27, 16, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 87.99, id: "ps_e7a02e42-c6ae-422e-ab09-d88911888dbd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[38], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 27, 4, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 91.36, id: "ps_785d1d99-a3bd-4b4f-89f2-d0703d122732", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[85], state: PAYIN_STATE.FAILED, date: new Date(2023, 2, 27, 19, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 151.57, id: "ps_90b252b6-3082-42d5-bd12-66a5450cb749", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[21], seller: Sellers[80], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 28, 4, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 420.71, id: "ps_c31c3dc0-0767-425b-bba4-db87e626add0", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[1], seller: Sellers[23], state: PAYIN_STATE.PENDING, date: new Date(2023, 2, 28, 10, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 131.56, id: "ps_462b4e14-ca0b-4e5c-a55b-e106a25b36d3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[101], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 28, 11, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 281.17, id: "ps_1f6a3cc6-f290-4b8f-970e-2de9ce2f68c4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[58], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 28, 12, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 39.84, id: "ps_916d76ce-5bf0-4016-8f6d-2332d127b6df", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[20], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 2, 28, 2, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 88.65, id: "ps_b4ebc82b-9ca4-4e87-9793-30616bfa3450", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[31], seller: Sellers[5], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 1, 5, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 80.35, id: "ps_018e1c47-d25d-4051-86b0-d16e88f47757", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[95], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 1, 5, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 97.59, id: "ps_d859f940-cb09-4558-8e9b-da46474bd4b1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[23], seller: Sellers[3], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 1, 14, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 342.48, id: "ps_a4f405da-a849-4987-99e1-7f141ccb7e8a", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[42], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 1, 8, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 13.22, id: "ps_29d43e30-7d8f-4c05-a759-cab2de19fec4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[85], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 1, 7, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 138.17, id: "ps_5d868ca7-d905-44d0-94a2-5c57d9ef0712", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[32], seller: Sellers[45], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 1, 7, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 104.45, id: "ps_367825dc-50f6-413c-9874-f95c15009a5b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[26], seller: Sellers[32], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 1, 17, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 31.79, id: "ps_f7924cba-e82f-4b5a-beec-82f782126982", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 1, 20, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 86.45, id: "ps_4cf55d8e-790b-492a-a5c3-067f3db6d393", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[83], seller: Sellers[38], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 1, 14, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 217.78, id: "ps_9f29e439-3aa7-435e-8214-762c30f8a95f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[44], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 3, 2, 23, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad, dispute: Disputes[21] },
        { currency: "$", amount: 14.56, id: "ps_3a5d5556-e870-49f9-ba22-202aaa169f31", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[61], seller: Sellers[47], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 2, 8, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 74.02, id: "ps_4fc96c1e-dedf-4adf-a5f3-14c74563ea5a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[56], seller: Sellers[4], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 2, 8, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 82.95, id: "ps_a2c72c90-ee9e-476a-85ef-56d38fe6952d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[58], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 2, 15, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 494.72, id: "ps_0fc437e4-3c02-4ce3-acda-9521f7eb8f8e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 2, 15, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 220.56, id: "ps_617f0921-f1d9-4d7d-b3bb-26b0ff59b66e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 2, 13, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 28.37, id: "ps_929cfed9-6c51-4165-823c-78890fe95cca", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[7], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 2, 14, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 52.47, id: "ps_05c8d869-790d-48a9-909a-6c15dc9c0822", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[34], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 3, 2, 0, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 484.47, id: "ps_58e07cf1-c195-4253-99d7-129ea10aebd5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[41], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 2, 7, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 190.34, id: "ps_adc4dd38-1baf-41de-b7ce-6a02efe290d4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[12], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 3, 10, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 35.39, id: "ps_7c929a8e-c46d-45e6-80b1-342ab7661431", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[6], seller: Sellers[1], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 3, 13, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 18.58, id: "ps_14b9e779-55c1-4757-ab4d-c54ceaad7339", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[67], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 3, 16, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 406.49, id: "ps_82018ab4-cc3e-484a-980c-73de790e9189", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[83], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 3, 15, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 180.94, id: "ps_4dadbde3-7a54-44d8-9b97-ff478b58b0c9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[58], seller: Sellers[54], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 3, 17, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 12.69, id: "ps_a634908f-0c87-4f8e-83d9-5269f7b687a7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[5], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 3, 5, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 475.13, id: "ps_293be401-3fba-4524-9767-b45e95df8cb6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[94], seller: Sellers[5], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 3, 4, 12, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 395.98, id: "ps_1a0abd1c-9a78-4ac6-8208-c9ac5a4502c2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[1], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 4, 8, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 476.69, id: "ps_da083392-bf88-45df-853f-62d42d0d3282", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[52], seller: Sellers[75], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 4, 14, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 12.06, id: "ps_9a56b7d3-2a4d-40ea-b799-799ee440bf36", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[12], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 4, 21, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 82.53, id: "ps_644cf0b1-f194-4763-982a-57fb695db4f4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[40], seller: Sellers[44], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 4, 12, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 59.39, id: "ps_6dedf39f-dc85-4ce9-a071-a495e92bd5d6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[46], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 4, 22, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 91.97, id: "ps_10eba302-c8a8-4d72-8c43-f572230c807e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[87], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 5, 5, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 91.57, id: "ps_04696d51-e358-4f3a-82b3-395dc6ca5915", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[91], seller: Sellers[5], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 5, 0, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 82.63, id: "ps_74e0f892-b2e2-4376-ad06-c7b649c034c0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[63], seller: Sellers[25], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 5, 15, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 36.87, id: "ps_4309e876-c0e0-45ee-a467-e69ab47c50ec", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 5, 23, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 50.39, id: "ps_00fb5ccb-9293-48e0-b360-a98c6aa1eea4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[23], state: PAYIN_STATE.INITIATED, date: new Date(2023, 3, 5, 16, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 400.3, id: "ps_7c33457b-daea-427a-b5fc-5f8e1392f758", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 5, 20, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 59.75, id: "ps_39f13003-b56a-4942-bb58-6def2b7fd459", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[37], seller: Sellers[7], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 6, 1, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 365.83, id: "ps_da466809-8a43-49db-99b2-2645913b80a0", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[27], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 6, 2, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 25.84, id: "ps_24316246-5595-44bc-bde7-08694468ad1f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[23], seller: Sellers[2], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 6, 15, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 43.08, id: "ps_78ac934d-80fb-444d-bdb4-b5b27456b3a0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 7, 9, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 35.71, id: "ps_168dad00-cee0-4765-9f51-4e07f8669872", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[43], seller: Sellers[57], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 7, 11, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 82.71, id: "ps_09a45ed8-6c8d-4a23-be76-bf4710d02a3e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[2], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 7, 5, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 29.06, id: "ps_3b8e0632-89ae-432f-8ea0-d0ec28853a2c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[93], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 7, 15, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 75.24, id: "ps_282dc7fe-ebb8-4004-8a3d-95ef75eab9e2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[37], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 8, 14, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 56.69, id: "ps_a3b6d12c-539c-4bf9-b4b4-29d0089b8d97", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[36], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 3, 8, 1, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 207.53, id: "ps_8be6684e-5f39-4050-8289-286519c0a76d", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[19], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 8, 7, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 467.26, id: "ps_61ae668a-4280-4f5f-a8d7-0d8da0e03b1c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[41], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 8, 4, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 246.88, id: "ps_98beb0c1-4441-4405-82d2-f0cd24e8de8e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[36], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 9, 10, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 30.57, id: "ps_d9276b46-eb2a-42af-be5b-ce722ae317fa", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 9, 2, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 62.44, id: "ps_01d91427-9426-4450-9452-e47ba17e6084", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[12], seller: Sellers[36], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 3, 9, 13, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 381.54, id: "ps_93b40f64-c5b7-4904-8ef0-f49564e4fc17", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[22], seller: Sellers[38], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 3, 9, 14, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 247.74, id: "ps_5a3a0d18-0da9-489f-b0ca-70bb63f663cf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[13], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 9, 12, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 334.16, id: "ps_37a47d87-e067-418c-b174-26c76a7780f2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 9, 10, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 53.8, id: "ps_fa5ea239-2a90-46fc-aaa0-5065fa1859b1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[61], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 10, 21, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 117.04, id: "ps_68ae2884-913e-426f-bb14-a7b777ef1a90", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[56], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 10, 13, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 3.41, id: "ps_a882f183-2e27-4e0f-bc4f-e2bb9b548a80", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[13], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 10, 9, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 118.45, id: "ps_6893108c-8281-43ac-9d36-89d6101e3d09", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[36], seller: Sellers[72], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 10, 3, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 91.13, id: "ps_076af046-6ce8-47ee-b49e-fdc431ed8cbb", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[53], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 10, 0, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 90.67, id: "ps_a2143c5b-ca02-4dc0-a927-f31e811392c1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[88], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 10, 16, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.16, id: "ps_4971d02f-e1af-4ba8-ad0e-b34526beba3a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[4], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 10, 5, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 178.05, id: "ps_8a097f12-d88c-4370-9cd2-a1397d44f235", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[79], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 11, 6, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 92.73, id: "ps_3d38796e-f89f-4fc5-ae53-8fc979dfb9d3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[61], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 11, 17, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 75.7, id: "ps_3f19e408-1e6e-4988-a3e5-e8d564218692", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[25], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 11, 23, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 16.94, id: "ps_e5a1e977-de6c-4b35-9fe2-319aea3ed945", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[74], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 12, 21, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 99.82, id: "ps_10cbef5d-8dde-4c0a-bfe9-670beaa9b251", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[49], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 12, 22, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 106.99, id: "ps_1ea1c8b4-b1bc-47d9-950b-8f93d028463d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[74], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 12, 9, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 243.48, id: "ps_f3cb6d69-67be-49a4-b19a-b57ed826e161", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[41], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 12, 1, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 486.02, id: "ps_f8318431-b461-46c8-954f-d1fd5e6fe76e", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[30], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 12, 7, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 92.66, id: "ps_5f52e80c-5a2b-44ad-ad6c-4637ab7839d5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[103], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 12, 22, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 43.46, id: "ps_d0b0c901-7dbf-4104-98cb-f226da506096", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[53], seller: Sellers[9], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 12, 17, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 13.52, id: "ps_7f1486b8-2d40-44a0-8562-84fdcced5f84", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[60], seller: Sellers[72], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 12, 9, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 41.97, id: "ps_a1ae3d4c-99bd-48d9-af02-e6682b5e8f75", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[11], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 13, 19, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 17.92, id: "ps_fe2a7b88-1b70-4ecd-830a-0bfc70c2a97b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[62], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 13, 6, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 140.71, id: "ps_ff92d654-dedf-45d2-bbe0-735226d8b90e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[6], seller: Sellers[80], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 3, 13, 11, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 82.59, id: "ps_8d94e7c8-dc3c-4998-8030-5e2493eec777", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[92], seller: Sellers[27], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 13, 14, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 72.77, id: "ps_75457cd6-8d19-4273-a533-f2e943fef765", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[56], seller: Sellers[87], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 13, 15, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 357.18, id: "ps_1d00c850-685f-4ffc-811c-c23e58afb6f1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[37], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 3, 13, 8, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows, dispute: Disputes[22] },
        { currency: "$", amount: 74.31, id: "ps_f7b7e62d-54b9-4055-a047-cede497df879", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[22], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 13, 14, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 81.81, id: "ps_e4f35420-cc27-462d-a6f1-f632a154f673", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[70], seller: Sellers[86], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 14, 19, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 176, id: "ps_1948606d-bf2c-4637-9e60-63e9c2a71650", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[28], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 14, 0, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 91.51, id: "ps_afd3b98e-eca1-406e-b641-a9b9ee21a2fa", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[88], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 14, 5, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 16.4, id: "ps_591ffd14-86c6-4159-9acc-e5d524c2f1ed", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[13], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 14, 20, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 395.72, id: "ps_82017d4b-e112-4877-8cc9-cbf02aa5d7e3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[41], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 14, 4, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 51.01, id: "ps_741fa24c-9e21-47b7-b7db-f97bf7e60d13", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[91], seller: Sellers[25], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 15, 20, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 244.15, id: "ps_b47260db-f081-4c20-84ae-7ed26afb32bd", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 15, 22, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 99.14, id: "ps_27ab58f8-7592-418a-9c7a-3551c5a7a5ed", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[69], seller: Sellers[67], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 15, 14, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 377.79, id: "ps_3e0ed278-8ac1-4b51-b8c2-dd5af6a485fa", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[76], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 15, 1, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 77.5, id: "ps_61a4d0b2-0464-461d-ab3a-8aec24cde393", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[41], seller: Sellers[6], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 15, 4, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 443.74, id: "ps_554d45cb-deb3-4ee7-8afa-010a19baa66a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[7], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 15, 2, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 37.18, id: "ps_d051c5e5-e04e-4c3c-810c-75dfae1438ca", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[5], seller: Sellers[85], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 15, 1, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 325.99, id: "ps_6ca36bc2-0ea6-433a-9d41-e9821f05a1ba", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[20], seller: Sellers[87], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 15, 3, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 479.36, id: "ps_c4d84dbf-9488-4234-89b0-1ef666623a55", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[51], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 15, 23, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 40.6, id: "ps_61d5b7e7-b33d-4f82-be14-ded6019de275", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[81], seller: Sellers[59], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 16, 21, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 97.21, id: "ps_16b65c56-a0ba-4ba4-a8fb-3ce8a60d9acf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[78], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 16, 3, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 26.85, id: "ps_d6ccdbe3-90f3-4ce2-8a40-77731f78a6b2", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[5], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 16, 13, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 122.91, id: "ps_d65e2ddb-f26c-407e-951f-0ea27c46e80c", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[77], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 16, 3, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 86.07, id: "ps_9fecc4cc-d9ef-49c9-a400-1a067ed16b35", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[101], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 17, 12, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 51.61, id: "ps_60b40178-39b6-4dcc-b436-0e4ee08443fe", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[13], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 17, 13, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 237.76, id: "ps_ddc1cf1a-b3ff-4b10-b51c-5ca3c2320844", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[77], seller: Sellers[36], state: PAYIN_STATE.INITIATED, date: new Date(2023, 3, 17, 0, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 321.14, id: "ps_9bfc400e-7c71-4a7d-b8d9-143f8f736751", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[30], seller: Sellers[75], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 17, 13, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 418.56, id: "ps_05345caf-e377-43b2-ae8e-7b8ae8f1dc60", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[56], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 17, 6, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 491.81, id: "ps_569ce20d-56ee-4f57-9bda-7f95c3314d94", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[101], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 17, 9, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 467.22, id: "ps_768de394-0413-426d-8cfe-7e13bc4351c2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[101], seller: Sellers[54], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 17, 2, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 59.79, id: "ps_64062850-76f9-4fc2-ac3e-cde3e88685fd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[103], seller: Sellers[45], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 18, 19, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 173.41, id: "ps_1461d605-37bd-485c-9cc0-6fe272fbd6a7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[42], seller: Sellers[10], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 18, 14, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 102.16, id: "ps_16323182-0c15-42b6-89c4-6ad033a4b508", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 18, 0, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 478.54, id: "ps_77929827-6d1a-42cd-87e6-83dd5adcdb85", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[59], seller: Sellers[12], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 18, 8, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 53.77, id: "ps_8cc8402d-b2f6-4ca2-9e02-6764f2830786", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[4], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 18, 9, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 61.45, id: "ps_6f73c95f-1fbb-433a-8ee1-46fe78b27706", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[5], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 19, 3, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 311.34, id: "ps_adbafe0d-1d95-43b5-8fbd-cdc3b34540ce", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[69], seller: Sellers[50], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 19, 12, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 31.31, id: "ps_4277bbea-285a-44f6-88f3-284c5504eb31", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[6], seller: Sellers[65], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 19, 15, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 14.76, id: "ps_16c19cc5-a976-4f06-97f2-252a83332e71", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[72], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 20, 4, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 42.5, id: "ps_dd75df85-06be-4eba-a72d-e4966b5eae2c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[17], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 20, 22, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 334.52, id: "ps_d1b67e24-4b8f-4a9b-891e-171e560dd9ce", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[94], seller: Sellers[45], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 20, 18, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 113.51, id: "ps_44f4bb65-0d94-4bde-ae07-c58e3c7d1fea", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[43], seller: Sellers[7], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 3, 20, 1, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid, dispute: Disputes[23] },
        { currency: "$", amount: 421.94, id: "ps_25bb7a3a-76c6-4343-9564-f3ed90409a7f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[91], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 20, 14, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 72.52, id: "ps_f20a8dbb-37d2-4546-b14c-9ceb57d0bfec", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[11], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 20, 2, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 91.14, id: "ps_36a4af2b-128d-4e71-8c29-9836a6a36a07", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[40], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 20, 18, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 29.63, id: "ps_982fe705-7f44-44f7-9aee-78958fcd16ba", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[48], seller: Sellers[33], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 3, 21, 10, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 209.56, id: "ps_2a590d8a-f07d-4214-93a4-be90bdaf6d07", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[78], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 21, 8, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 79.54, id: "ps_a6d956c1-82d2-4fbe-a0bc-aa05ef452e3a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[39], seller: Sellers[7], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 21, 3, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 22.95, id: "ps_c4ea7165-c7be-4771-8697-97ba1a7196b9", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[55], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 21, 2, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 220.91, id: "ps_bf464505-9350-4414-bd22-944a81ce685f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[18], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 22, 10, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 169.81, id: "ps_8f340951-3a59-4551-9d70-8da960d04836", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[5], seller: Sellers[29], state: PAYIN_STATE.PENDING, date: new Date(2023, 3, 22, 16, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 153.58, id: "ps_39c02834-7fbf-45b2-8de0-331a67456b5e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[2], seller: Sellers[24], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 22, 16, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 182.28, id: "ps_631bcab2-e882-496b-85b9-16dd53c6fde4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[70], seller: Sellers[18], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 22, 14, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 11.5, id: "ps_c558ef78-439f-4283-a2d4-40f363dc6ec2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 22, 20, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 13.01, id: "ps_4c2ab2f0-7ced-42af-b7a8-92140d9f3f8f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[22], seller: Sellers[42], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 22, 12, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 104.47, id: "ps_a59c91fe-ff36-4550-9c34-c68029ffceea", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[62], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 22, 15, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 449.48, id: "ps_4c30f2ca-af08-4461-b566-10c34133521e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[60], state: PAYIN_STATE.INITIATED, date: new Date(2023, 3, 23, 19, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 105.68, id: "ps_742b027d-e883-4a5a-ad4f-2c4a7197858e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[21], seller: Sellers[66], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 23, 18, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 53.08, id: "ps_6a06b129-398c-4d53-bbf2-50097ed5eec4", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[89], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 23, 3, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 22.33, id: "ps_2f4c2ca8-c1ef-42cb-a798-3a4918c12642", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[69], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 3, 23, 17, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone, dispute: Disputes[24] },
        { currency: "$", amount: 82.99, id: "ps_fd4a1c80-6c1b-4b63-8755-a2b97b491974", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[103], seller: Sellers[32], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 3, 24, 11, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad, dispute: Disputes[25] },
        { currency: "$", amount: 186.53, id: "ps_6250d9fd-3670-4d4b-8a63-4b997687fac3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 24, 22, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 70.24, id: "ps_4e9661ae-b56d-4147-a271-ccd2665eea38", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[68], seller: Sellers[16], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 24, 23, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 258.9, id: "ps_38af3c76-5849-4009-ab42-29e5ea4effed", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[78], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 24, 5, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 12.56, id: "ps_f6f15407-a20d-4c94-a571-32fa8fd13e1b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[67], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 24, 21, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 438.64, id: "ps_855a445e-81af-4ced-a9f3-c61f926a802f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[71], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 24, 9, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 25.63, id: "ps_9a0daabe-0267-4b6b-8feb-bfff28833606", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[5], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 25, 14, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 49.5, id: "ps_f1b1f6d1-9ba0-491f-90ca-4ba265d987aa", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[73], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 25, 4, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 46.47, id: "ps_dab8e8be-2033-452b-a4b3-43b4c2e67ae2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[64], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 25, 5, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 90.67, id: "ps_d543f973-7714-47c7-b896-53ed8c9f77b6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[49], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 25, 20, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 99.94, id: "ps_f7db7c6a-138a-4f56-9c32-21beb1f0a18e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[40], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 25, 8, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 369.75, id: "ps_0232ebd8-a82d-405f-83c2-a797c23638f7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[95], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 25, 21, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 389.78, id: "ps_07e9153c-1d58-4572-b94c-176083a76650", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[61], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 25, 8, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 81.44, id: "ps_13c0321b-7003-4d8f-b600-f431266a9827", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[83], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 26, 10, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 10.22, id: "ps_24fab8c3-4e39-434c-bd52-1e97f8bd9908", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[18], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 26, 21, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 96.26, id: "ps_65736f76-1c63-4dcf-804b-7e0cb0a6f4ad", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[26], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 26, 22, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 87.06, id: "ps_d5ad5913-f8ae-48b5-8116-6b54020a9a2b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[7], seller: Sellers[17], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 26, 2, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 119.7, id: "ps_46d40d30-a0d5-4408-ac35-13880cc0c2c5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[69], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 27, 19, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 154.65, id: "ps_c24a8c50-503d-4f2f-913c-4da0beb85b7a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[70], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 27, 21, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 42.72, id: "ps_a0d3dff9-848b-425b-bfc3-9be2aa3363ea", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[72], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 27, 6, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 248.39, id: "ps_9eee7dd6-94a9-47ea-aaad-dd52c06a4880", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[37], seller: Sellers[7], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 3, 27, 15, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[26] },
        { currency: "$", amount: 85.81, id: "ps_305d5e3e-6525-4593-8bdd-271d817675db", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[53], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 27, 0, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 453.37, id: "ps_6339868f-d0d7-4e2a-8bdf-56b5a6f299b0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[93], seller: Sellers[4], state: PAYIN_STATE.INITIATED, date: new Date(2023, 3, 28, 0, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 98.46, id: "ps_5f3d33ae-fa5a-4167-bafa-d6cc1a4c3945", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[73], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 28, 6, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 80.78, id: "ps_dc6eee25-ed3b-4951-8cc6-a39d8a2ae4d7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[49], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 28, 19, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 235.04, id: "ps_3b338cb7-6e03-43e3-aa57-06a616484ca2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 28, 10, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 89.31, id: "ps_75cd2cff-7da2-4cba-8c39-695247e17104", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[41], seller: Sellers[75], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 3, 28, 10, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 275.56, id: "ps_bf8dc237-be74-4ea9-8a44-4525822df609", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[73], seller: Sellers[63], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 3, 28, 4, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone, dispute: Disputes[27] },
        { currency: "$", amount: 60.53, id: "ps_15f8b6c8-6499-45c8-8d16-b9c73e94162c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[2], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 28, 15, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 326.05, id: "ps_b0eb8b63-5bff-4975-809b-eb98d3ccd757", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[25], seller: Sellers[25], state: PAYIN_STATE.INITIATED, date: new Date(2023, 3, 28, 0, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 197.16, id: "ps_0d10a84b-d405-4c0d-af38-7bc68449ffb4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[29], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 29, 5, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 116.41, id: "ps_2d4e1590-b298-46ae-98c6-b690cc9524b2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[98], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 29, 11, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 55.81, id: "ps_b9fffb6a-6108-4265-a04d-8ffc10f6bc8f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[47], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 29, 23, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 71.39, id: "ps_3c3ec0ee-ccf0-4f64-a620-83412b74551a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[24], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 29, 11, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 425.8, id: "ps_b46caff5-bad7-4f21-b737-8b161a232ce1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[32], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 30, 13, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 497.8, id: "ps_1cc865e0-c93f-4c7c-a75d-3e2bd34fce7b", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[28], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 30, 20, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 54.42, id: "ps_9d8c02bd-c1de-4360-be73-bbbc7ee0be5d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[54], state: PAYIN_STATE.INITIATED, date: new Date(2023, 3, 30, 18, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 72.73, id: "ps_bad81a4c-20de-408c-83e1-80f666b37b59", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[36], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 3, 30, 5, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 93.67, id: "ps_ca5609ee-976b-45e5-9d1f-5a57d6f96f30", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[82], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 3, 30, 17, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 11.99, id: "ps_b29dea0e-c282-43ce-ba6a-d15b5562eef2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[90], seller: Sellers[44], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 1, 16, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 176.48, id: "ps_2d028f8c-302d-489a-b064-c0699398ca52", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[4], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 1, 20, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 65.62, id: "ps_521ab737-fcb1-4db5-8583-03b2f563b1c0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[24], seller: Sellers[6], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 1, 14, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 41.94, id: "ps_75f481fb-b7b4-4228-a525-321c6173658a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[44], seller: Sellers[83], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 1, 6, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 45.41, id: "ps_fd3360c8-02f1-4b17-acbe-8746d4d8f274", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[17], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 1, 8, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 287.71, id: "ps_6cf46483-0688-4312-8cb1-1188fbac786f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[82], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 1, 3, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 377.13, id: "ps_d16f4b8e-1e16-49ab-8e5e-d5d0ac462ba6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[102], seller: Sellers[63], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 1, 2, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 84.76, id: "ps_19815d26-b876-4c2b-86ed-6f9bc1fb7538", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[36], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 2, 0, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 199.99, id: "ps_dbfcdf81-2dcf-4f02-84d2-e1fe51f6bfa1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[74], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 2, 0, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 482.57, id: "ps_6d64bc78-25e5-4c46-874c-1256045bddcb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[98], seller: Sellers[58], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 2, 6, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 62.46, id: "ps_2ed777dc-8bbd-4164-b03f-f59faf90aacb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[59], seller: Sellers[70], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 2, 16, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 26.66, id: "ps_d0e50b87-52fa-432d-a43c-44de7c4ccff7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[79], seller: Sellers[33], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 2, 1, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 76.23, id: "ps_9367cb31-69fc-4d2d-87e0-035f07958447", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[65], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 2, 4, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 18.66, id: "ps_4d34799c-cc63-4481-83a9-91414ab0dac6", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[100], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 3, 18, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 24.2, id: "ps_6ec3e121-a117-4393-bf20-6d0086bc81a5", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[61], seller: Sellers[35], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 3, 2, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 39.45, id: "ps_c930848e-62d5-4311-9e2b-957cfb308b48", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 3, 3, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 20.49, id: "ps_1495859c-aaa3-420a-9ece-23579c64e12b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 3, 8, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 49.58, id: "ps_98394006-2e98-4f8e-9ad6-4041305b8aea", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[76], seller: Sellers[64], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 3, 19, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 16.16, id: "ps_4a49d7f2-f24f-48e4-b905-5f62d42d76bd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[3], seller: Sellers[53], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 3, 9, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 63.54, id: "ps_12a38155-1ab9-4ec2-8d3a-4c225a974cb2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[32], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 4, 20, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 11.22, id: "ps_96cdab97-80a2-4b46-887e-4fcb0cf5ba83", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[42], seller: Sellers[73], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 4, 18, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 412.29, id: "ps_18cb03b7-3b8e-43de-adf7-251e86f8d275", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[61], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 4, 12, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 370.72, id: "ps_430bc1f7-667c-4245-a2a9-70ab22fea807", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[96], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 5, 6, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 19.24, id: "ps_8974d1e7-2121-422c-81b6-6e797e7d57cb", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[5], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 5, 4, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 16.48, id: "ps_6c251499-749f-4e9f-ba6b-1dd54aa72f4b", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[36], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 5, 9, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 273.43, id: "ps_95524913-640b-4b11-8558-83995b91976b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[97], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 5, 0, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 17.45, id: "ps_0378c32d-f814-408e-87f7-0d0fcf152b1b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[84], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 5, 15, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 432.88, id: "ps_cc298427-82ce-4fc6-af64-b89285d854f8", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[53], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 5, 9, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 124.5, id: "ps_c9db505f-8717-48a2-9ed6-c669407b6952", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[36], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 5, 17, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 68.59, id: "ps_2b1820f5-e101-4926-94c9-67a5baf38cd4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[30], seller: Sellers[2], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 6, 20, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 149.31, id: "ps_8a0960ca-c8b9-48ca-8374-d790eb4f2631", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 6, 0, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 334.27, id: "ps_be5c08f9-da59-44f1-a136-63ec7e594216", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[78], seller: Sellers[22], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 6, 20, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 117.29, id: "ps_c4e7c338-a8a1-41e7-950c-0a782b3a827e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[28], seller: Sellers[12], state: PAYIN_STATE.REFUNDED, "isFraudolent": true, date: new Date(2023, 4, 6, 1, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 13.37, id: "ps_c14b5b43-d7bc-41cc-87db-21e4046b6d02", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[13], seller: Sellers[11], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 6, 15, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 49.32, id: "ps_76951a01-0d6b-453e-837f-022428769f52", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[53], seller: Sellers[33], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 7, 4, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 182.42, id: "ps_05a0fb8b-0417-49e0-9f62-3c0579f15a64", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[74], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 7, 16, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.29, id: "ps_35c5f079-ccb1-488b-9074-822dfbb8d0f7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[38], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 7, 23, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 41.94, id: "ps_b5a8091c-9183-43cb-81f7-fd6e743c3a41", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[8], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 7, 19, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 24.68, id: "ps_42931e30-f905-4708-9637-cac4f4c01d70", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[88], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 7, 23, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 377.39, id: "ps_be379b67-1579-4c25-a3da-ae11feaa213b", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[8], seller: Sellers[14], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 4, 7, 6, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 10.62, id: "ps_677b3f90-2786-4a8d-b3a7-d19b4a1dabc2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[6], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 8, 19, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 84.99, id: "ps_51d1b4f1-0a08-429e-bea5-3a338e974216", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[10], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 8, 22, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 415.15, id: "ps_577562fc-ef4a-47cf-bcd1-b22ccd1e0e91", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[93], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 8, 11, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 95.19, id: "ps_39b70463-0a00-41aa-8bbf-68d2016ea98a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[60], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 8, 3, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 3.54, id: "ps_58a4538e-04a1-43a9-b784-e5271575659f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[73], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 8, 16, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 19.87, id: "ps_6e963673-6d0b-4d8e-9d2d-7a85574e196b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[99], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 9, 13, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 183.85, id: "ps_36a4727f-9323-4287-9fc1-b94c9ea657a6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[32], seller: Sellers[14], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 9, 13, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 73.86, id: "ps_b2f14a96-626f-4ff4-b7b7-1397f94d3032", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[50], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 9, 18, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 113.27, id: "ps_12d2eb27-6a24-4d09-b7f8-3e7e15123003", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[61], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 10, 1, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 47.54, id: "ps_cfcf5426-af14-4e37-8cce-abe1edada4cf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[38], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 10, 11, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 467.02, id: "ps_318a5bb7-28ef-428f-8d37-0b59d5f12230", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[9], seller: Sellers[84], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 10, 9, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 261.78, id: "ps_cb66727a-9cfa-43c4-9197-9fb9ff1efa93", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[23], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 10, 19, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 151.63, id: "ps_c1307a9c-339c-4873-a1bf-50e688cc119b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[47], seller: Sellers[78], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 10, 18, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 24.37, id: "ps_a4ce6f04-3423-4070-a45f-755b4fb17c95", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[2], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 10, 8, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 118.38, id: "ps_865a7b6a-2386-49c9-be35-8462dae5f67b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 10, 4, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 83.35, id: "ps_6dc8f5ed-a12f-41ca-9024-6af6ee8d3826", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[26], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 11, 4, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 74.87, id: "ps_150b33b0-e638-472a-ae84-b2f44882d639", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[32], seller: Sellers[65], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 11, 13, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 27.78, id: "ps_35ae3cf0-660f-4e12-9239-5796ded5e75e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[42], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 11, 20, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 19.17, id: "ps_645ea44c-d0ec-4c45-ab92-c895a8f95508", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 11, 7, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 62.32, id: "ps_c4907d2b-97cf-4ec0-a2bc-916de6e52a3c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[31], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 11, 1, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 18.63, id: "ps_a84d4945-34a0-42aa-80db-f2c37a93cd6b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[96], seller: Sellers[76], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 11, 0, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 118.26, id: "ps_c74dd862-23f7-4268-b225-2d056684d88b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[51], seller: Sellers[75], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 11, 12, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 73.92, id: "ps_c0862df0-6ace-49ba-b390-4f341b8e177c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[19], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 11, 3, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 35.59, id: "ps_a8fc0ea4-075a-4153-bec7-c29fc60b9de4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[88], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 11, 12, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 70.44, id: "ps_7bbb6ff4-7c59-417e-aaf7-5ab96fb12120", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[19], seller: Sellers[69], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 12, 17, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 87.15, id: "ps_1001876e-fedb-4313-8ed8-8c71cf913c60", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[66], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 12, 14, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 259.03, id: "ps_537d5d34-1001-4f41-bab2-107d7ec23d38", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[41], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 12, 8, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 61.4, id: "ps_71fd6885-e5b5-40fc-a5a2-2b70111021d9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[40], seller: Sellers[13], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 12, 0, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 95.04, id: "ps_29bedb3a-38c9-4fd3-9bae-f0e83afb7309", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 13, 1, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 48.54, id: "ps_b2de1f06-6fd8-47d1-a36b-b1720628ac18", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[7], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 13, 18, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 26.37, id: "ps_2fd01253-3e32-4814-b5c1-046560238bea", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[100], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 13, 4, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 28.58, id: "ps_7774067e-3431-4cbc-b2e2-c6abba2a51e4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[17], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 13, 4, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 65.25, id: "ps_fe451995-c187-4e96-8040-414ae16a8e14", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[67], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 13, 7, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 397.3, id: "ps_23f02f11-f120-467d-85b8-335b9dda8101", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[42], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 13, 0, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 17.09, id: "ps_b9da3640-b378-402b-87d6-a1aa71bc4fbb", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[55], seller: Sellers[7], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 13, 18, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 69.25, id: "ps_93565e69-1300-4e59-b93c-a22d40d3a501", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[65], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 13, 5, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 16.92, id: "ps_93c284e9-a187-4049-98b7-9fb2b914a0c9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[42], seller: Sellers[63], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 4, 14, 19, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[28] },
        { currency: "$", amount: 115.81, id: "ps_a9e9204d-ec80-4bcb-aa6d-2b26c6f3eeea", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[18], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 14, 15, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 12.61, id: "ps_2405269d-4191-4626-9dd7-3b8d090ded0d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[14], seller: Sellers[82], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 14, 14, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 73.87, id: "ps_fb9adc81-b91f-4b4c-a5ab-872c890ba507", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[19], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 14, 19, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 30.96, id: "ps_1a6dc6d4-465e-4ef3-9a71-f3b13d40f6c4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 14, 10, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.45, id: "ps_59e5f25d-d7c6-4191-a310-8f2bbf432ee2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[65], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 14, 20, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 87.71, id: "ps_b6008987-c8d5-444b-a58c-782eea9ab64f", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[16], seller: Sellers[5], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 14, 19, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 43.68, id: "ps_e729fceb-e3b1-4ade-98a3-9f72fd02eff5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[48], seller: Sellers[42], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 14, 1, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 364.9, id: "ps_0e1061d9-b8c0-428e-af8f-635640bdcb20", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 14, 7, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 15.96, id: "ps_2faa277f-f20a-4450-b077-588f527cf70c", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[61], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 15, 18, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 19.06, id: "ps_6b2e660f-5053-4058-9d0c-8a3abd930379", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 15, 0, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 294.03, id: "ps_0fd0f8ba-078c-4f26-aed9-0ec40ddf52d6", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[54], seller: Sellers[50], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 4, 15, 10, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation, dispute: Disputes[29] },
        { currency: "$", amount: 17.26, id: "ps_74a2ec75-b8f8-4f26-bb5b-bdc37700e372", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[18], seller: Sellers[12], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 15, 21, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 15.08, id: "ps_6f778687-df92-445d-bf3d-d78531f8387a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[78], seller: Sellers[9], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 15, 16, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 16.69, id: "ps_141626b0-4dd6-42ab-8aa0-294363b35b57", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[13], seller: Sellers[29], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 15, 22, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 199.11, id: "ps_3681339e-ba43-4c17-aa84-dd1e7aaf4c98", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[70], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 16, 22, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 42.22, id: "ps_717faf6f-da43-408b-857b-32a4fbda5778", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[94], seller: Sellers[86], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 16, 21, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 423.15, id: "ps_523ede07-ede4-4e62-8ddc-8a6c910e391c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[67], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 16, 16, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 17.99, id: "ps_1d325984-93d0-4b90-83f6-6431a4852948", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[59], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 16, 0, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 30.75, id: "ps_3c8ba43a-7e76-487a-95c7-e3fee80b65ed", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[18], seller: Sellers[73], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 17, 2, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 35.96, id: "ps_b8ed267f-b92e-4e32-ba06-2f4e10cd364b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[15], seller: Sellers[29], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 17, 19, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 195.01, id: "ps_53e4a11a-d640-4b23-810b-0dbf60b93d04", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[5], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 17, 17, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 71.63, id: "ps_4d145b8f-462f-47fd-b08d-afeedfe42f6a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[43], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 17, 10, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 35.63, id: "ps_7b9ee5ac-caba-41f9-9f10-1ecf770f6499", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[9], seller: Sellers[67], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 17, 11, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 137.17, id: "ps_d68a2ca6-80f8-49ff-b226-8753b23704b3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[87], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 17, 8, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 178.22, id: "ps_4a2f1e51-b4ac-477d-b4ce-d1e476f6da33", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[13], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 17, 12, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 50.18, id: "ps_61471910-c29a-402d-a9aa-b5dfe4113f46", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[76], seller: Sellers[12], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 18, 19, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 63.98, id: "ps_5e62ba15-4c05-4747-91cf-bf31c0bd0611", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 18, 5, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 83.97, id: "ps_52a73b5d-5b23-4f64-934e-cb81e0dde8ba", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[90], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 18, 7, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 46.25, id: "ps_437acc2a-1d6e-4b04-abf2-42f24e9161ac", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[4], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 18, 14, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 69.7, id: "ps_641f481c-cb69-4535-b0fb-ac118338c605", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[71], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 4, 18, 20, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 53.69, id: "ps_5f4632e5-2e72-4efc-a400-47fe4a172d5a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 18, 22, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 19.89, id: "ps_ab3dfc04-a17a-455f-b6aa-bef809047329", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[15], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 18, 21, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 47.9, id: "ps_d364b763-ad9e-4a29-ad35-1c233a74ffcf", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[99], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 18, 8, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 27.3, id: "ps_0d03de33-472e-492c-a411-2a90083538ad", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[32], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 18, 17, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 93.16, id: "ps_bbaf2c77-dc28-41d9-870f-f358523a1fb8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[72], seller: Sellers[75], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 18, 8, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 89.85, id: "ps_1644a3b2-6ddd-46d1-b226-8c88022a8f58", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[9], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 19, 5, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 54.72, id: "ps_71d5683e-d1b2-45f3-b018-00f2039ae6c0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[45], seller: Sellers[56], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 19, 21, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 71.57, id: "ps_d2067ce3-4bdd-4510-8885-12c4bfd6f95e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[57], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 19, 21, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 65.17, id: "ps_cc08e935-3653-40f8-9737-f12f2eb693bb", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[48], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 19, 16, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 29.98, id: "ps_5dfad2b6-6e84-4745-bae1-1c47c34437bc", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[24], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 19, 17, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 84.28, id: "ps_d7377e5a-feac-4e2a-88c5-f6eb30da09c4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[97], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 19, 8, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 24.07, id: "ps_1081df56-bde4-442b-862f-84edf8d39151", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[68], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 19, 22, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 16.47, id: "ps_4f6c125c-9cb4-4516-b6f7-8e9977c1719c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[18], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 19, 21, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 17.67, id: "ps_b103a1bc-ce7e-41e1-8786-46ae7ee22292", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[59], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 4, 19, 2, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 317.99, id: "ps_1bdf65e0-e2b5-4275-b56a-423f6c1de176", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[5], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 19, 8, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 83.58, id: "ps_f9fbe75c-f903-4466-bc1a-9932e7ecad20", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[1], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 19, 4, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 241.02, id: "ps_0f56fba6-cfde-464c-979e-666bf0c5dca5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[52], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 19, 22, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 219.85, id: "ps_8a758091-e161-41d9-9e8d-0c63e32d51aa", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[16], seller: Sellers[86], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 20, 10, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 483.24, id: "ps_dd0a4a8a-a4da-467b-ac9d-76a95af3dabf", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[18], seller: Sellers[20], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 4, 20, 15, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 151.14, id: "ps_5db3de38-dcc0-4220-b1f0-f9e2df866d64", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[44], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 20, 18, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 338.38, id: "ps_a75b662d-0ec6-417d-ae01-0f49dfc09fb6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[50], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 20, 5, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 92.93, id: "ps_160e4eef-0a6f-4296-9ef1-55e0c3ec68f1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[44], seller: Sellers[16], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 20, 18, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 91.2, id: "ps_82a67df1-1373-4603-8ee5-7fbd7274dad1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[15], seller: Sellers[2], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 20, 7, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 486.7, id: "ps_d77c9f43-204e-4b50-988f-16231eae3c77", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[85], seller: Sellers[84], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 20, 6, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 403.25, id: "ps_b1a2355c-7e3b-45e1-aa20-82aa28ab4128", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[12], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 20, 6, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 279.72, id: "ps_20ac0aaa-2178-484c-a83f-26023a8f8b4b", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[96], seller: Sellers[72], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 21, 15, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 89.84, id: "ps_0e4310ec-b821-427a-ba84-b26a180e5f28", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[37], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 21, 8, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 97.46, id: "ps_1295cfd5-991b-4f37-afce-d7c286073f4c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[12], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 21, 14, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 86.9, id: "ps_9ef22297-0930-48f0-be0a-088a067e6fd0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[90], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 21, 14, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 102.86, id: "ps_57dfcb31-2b1e-4011-8921-527bd5318464", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[34], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 21, 9, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 197.48, id: "ps_06917d60-d6f5-40e6-b369-5c886d6bda2e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[61], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 21, 22, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 391.67, id: "ps_3a78dd70-4b59-4fc2-a173-c9c4a1e0f6ef", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[67], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 22, 19, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 28.43, id: "ps_26f93410-90e3-4bbb-aaf2-59e9b758331e", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[40], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 22, 3, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 288.08, id: "ps_09712912-fc8e-4fe6-8cd7-7599425e5573", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[25], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 22, 6, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 30.26, id: "ps_d59d44e0-ab5d-44a2-a906-93b6dbc925b6", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[52], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 22, 13, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 75.18, id: "ps_d53a9429-b9e6-4a73-a002-e14bf3a43460", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[62], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 22, 7, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 25.38, id: "ps_45914f72-6794-4e94-98cf-58c59b3bc05a", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[15], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 23, 12, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 95.34, id: "ps_b8db02f1-625c-4468-9bd0-4d7e9236327e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 23, 17, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 93, id: "ps_d91723c2-3c51-44fb-a230-ae3fbf8b2ccc", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[77], seller: Sellers[74], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 23, 1, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 72.47, id: "ps_aaab8991-444e-47f4-8974-94457b0f9958", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[6], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 23, 23, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 342.32, id: "ps_11bb9e34-f9b7-4143-8a7f-6c9ee0e4cfbf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[49], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 23, 18, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 18.38, id: "ps_8a637f2d-eb03-4263-b4ca-645566107027", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 23, 16, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 225.14, id: "ps_fe87f92e-9831-4920-abb5-1470bfff9f96", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[19], seller: Sellers[14], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 23, 0, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 87.57, id: "ps_41b259b9-5deb-4ad4-86ec-a7b24f278e2d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[6], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 24, 9, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 0, id: "ps_39bce916-4291-45eb-be10-30215a45dfc8", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[83], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 24, 0, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 49.39, id: "ps_1b457434-e525-441f-b1f6-9d59d829859f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[87], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 24, 15, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 26.81, id: "ps_8e22c271-5ebe-40b2-9343-4811d5e4f6bb", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[67], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 24, 19, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 48.22, id: "ps_80c1ba9a-922f-4cb6-8316-25ada1f6f857", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[24], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 24, 17, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 27.23, id: "ps_9efbbc6f-d3c4-46f4-a8b8-8ab761de261e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[85], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 25, 2, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 15.22, id: "ps_5973270a-f00d-42a2-ba94-330fd224e308", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[17], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 25, 19, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 90.63, id: "ps_7b69fb8b-81d0-4909-a7a9-94210cb066db", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[52], seller: Sellers[20], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 25, 22, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 66.14, id: "ps_a51a7cf3-ed52-40e8-846c-fa8cf5fe71e9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[99], seller: Sellers[80], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 25, 10, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 29.26, id: "ps_4afdabd8-a4da-4f5a-9c76-2992da1022da", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[53], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 25, 17, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 296.48, id: "ps_e50302e8-c27b-4280-a2e9-645a5322d01d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[35], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 25, 0, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 213.29, id: "ps_91a5ed02-0b88-4ebf-a9e9-66fc8856dcad", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[28], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 26, 0, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 12.98, id: "ps_913c6667-9d4c-4d5f-a38c-0611c81f5bb0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[73], seller: Sellers[9], state: PAYIN_STATE.INITIATED, date: new Date(2023, 4, 26, 11, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.07, id: "ps_b31e98ce-13f4-44b4-bceb-9b931f75c1ad", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[103], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 26, 4, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 76.65, id: "ps_1e677aa0-3c60-4d8b-adab-f0ee53028229", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[9], seller: Sellers[45], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 26, 5, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 193.83, id: "ps_d5e6d873-9ee5-4579-a619-222dc8aee608", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[40], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 26, 22, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 47.04, id: "ps_2971187c-ac40-43c3-ae1c-2d8a333417e9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[90], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 27, 14, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 22.55, id: "ps_f7a0ef27-560f-495e-b440-fb3bcbd29261", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[37], seller: Sellers[53], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 4, 27, 3, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 112.03, id: "ps_d1dcfeba-e3db-4d75-97a2-03569b808ce0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[84], seller: Sellers[20], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 27, 8, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 162.23, id: "ps_d5ed7df7-41c2-40d2-928c-99af08ed5b6a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[44], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 27, 11, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 32.49, id: "ps_e01cd18b-baa0-4122-9e9f-c47cf7ed05e8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[24], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 27, 20, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 422.67, id: "ps_a68d7fb6-041b-4f03-b727-f21f4b1a51e7", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[30], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 27, 1, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 93.82, id: "ps_19c50d7a-76fd-471d-a20a-6c5f799b6c74", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[15], seller: Sellers[16], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 27, 3, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 79.76, id: "ps_c4e9f022-1902-4558-946d-a804ec05ec6a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[43], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 27, 15, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 22.72, id: "ps_b1708eaf-2203-4f96-b8ff-56dd3d0afacb", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[8], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 27, 19, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 48.59, id: "ps_27471a96-8375-474b-a8bc-7800ed1261ef", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[47], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 28, 6, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 261.73, id: "ps_c3045cf9-4858-4fac-81cc-9299b23bea69", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[15], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 28, 23, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 53.36, id: "ps_c81f9672-e8b9-4f13-9b64-0dfc8f545277", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[72], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 28, 9, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 21.5, id: "ps_99d06a84-d4d9-44aa-8117-0fec7e096d60", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[67], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 28, 14, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 63.29, id: "ps_bb7f18e3-6a77-4096-b67d-1cb42d5deb48", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[39], seller: Sellers[82], state: PAYIN_STATE.PENDING, date: new Date(2023, 4, 28, 0, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 109.28, id: "ps_3fc3b69f-5abc-43cc-aff9-ee2d7cb808f4", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[98], seller: Sellers[11], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 28, 22, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 68.66, id: "ps_56be2a09-db6f-43cb-a5f8-a163dd307559", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 28, 17, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 185.5, id: "ps_10833f08-5f66-45f2-9d52-8c893a6e37b4", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[56], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 28, 23, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 14.58, id: "ps_712e0b09-1222-44b9-995f-a0347ddd52ce", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[76], seller: Sellers[32], state: PAYIN_STATE.FAILED, date: new Date(2023, 4, 28, 7, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 96.44, id: "ps_5240a469-ccd6-4958-8084-fb5ebebe30be", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[7], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 28, 11, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 21.21, id: "ps_d7a46be7-5cd5-40ea-8d40-8f95244a5c4c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 28, 14, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 87.56, id: "ps_228e9fc2-7779-4b4d-9c35-798f4b759359", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 29, 16, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 46.58, id: "ps_1355880a-6324-4c80-a537-6b459a313bbe", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[71], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 29, 10, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 147.81, id: "ps_2635e036-dd80-4708-a83b-bc7f2b8e3843", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[31], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 29, 16, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 14.32, id: "ps_b76ddc98-2bb5-4c2f-a8e3-c1e5592d3b01", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[41], seller: Sellers[48], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 4, 29, 6, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 136.03, id: "ps_a941942c-8b42-4179-9c00-81346c437300", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[16], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 29, 9, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 99.76, id: "ps_4828e897-2af9-4ed3-9e8f-9b96cbc88f13", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[96], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 30, 4, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 152.01, id: "ps_4a6d6004-1fa6-4808-b19b-7895af06ccf1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 30, 15, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 115.51, id: "ps_bf0ac1ac-d992-4693-a3c5-5fa29a5c2fc4", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[84], seller: Sellers[57], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 4, 30, 1, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 45.3, id: "ps_fed9ae6a-d1f4-4f02-8e49-0a00cc978262", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[56], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 30, 4, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 10.89, id: "ps_eadb6013-d270-46fc-8d32-ec2c8d11d80b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[65], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 30, 9, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 103.29, id: "ps_ee900f9b-b6e6-45c3-b259-2c8d2cbc6b8b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[81], seller: Sellers[17], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 30, 19, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 96.6, id: "ps_887e57fd-7c08-481e-b7c0-ca66bac61739", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[3], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 4, 30, 9, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 41.97, id: "ps_dc03e7a4-09e5-4a36-aae8-5f9684394659", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[79], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 1, 18, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 78.42, id: "ps_0751fb0d-b53a-4a7f-b0d7-cf502fee5f30", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 1, 9, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 105.31, id: "ps_676c0276-cc8f-4823-ad25-669b40780067", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[9], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 2, 8, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 33.81, id: "ps_d1ea944c-af33-4ebd-848b-35d14c7c4705", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[23], seller: Sellers[32], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 2, 6, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 32.99, id: "ps_eb1798ad-9252-45f6-ac43-670550da2ba2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[69], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 2, 4, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 221.31, id: "ps_87aa3dfa-556c-431a-81bd-e8fe775a83e5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[6], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 3, 23, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 105.94, id: "ps_9969ec7d-c198-4453-9b7f-3a5af879ed5e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 3, 7, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 87.58, id: "ps_2ea7fe9d-6d64-4782-8271-e811fa4b1b20", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[46], seller: Sellers[70], state: PAYIN_STATE.PENDING, date: new Date(2023, 5, 3, 10, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 36.87, id: "ps_bce5ab67-98f8-436a-9f16-6fca1b5d5f79", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[2], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 3, 2, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 375.26, id: "ps_8149917c-391a-4173-bf97-26e7b4ba29a4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 4, 20, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 87.89, id: "ps_75b56b00-c2e9-4505-ae41-2f7138abf105", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[94], seller: Sellers[75], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 4, 6, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 14.8, id: "ps_ce2909f5-5d2f-42ac-9596-863448499c96", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[76], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 4, 1, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 104.57, id: "ps_c698bb00-421d-4aa9-8c29-a2abcdd2cf0d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[8], seller: Sellers[86], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 4, 22, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 43.9, id: "ps_6ebb52d6-9c7d-447b-88c6-0b2825c4404a", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[15], seller: Sellers[68], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 5, 4, 12, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 42.99, id: "ps_35a973aa-9163-459a-b769-79f8020c2790", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[67], seller: Sellers[68], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 4, 13, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 90.92, id: "ps_f4d36412-05eb-45a5-9021-b184d6641ae9", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[3], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 4, 12, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 13.33, id: "ps_dc1779ed-bdb4-4ec0-a6b9-58631876ed38", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[26], seller: Sellers[18], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 4, 22, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 91.73, id: "ps_9422186d-7223-46b4-b053-1dc56d018bdf", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[19], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 5, 22, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 88.38, id: "ps_f15c3a97-ff81-4226-8f24-ca47b86a0960", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[19], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 5, 15, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 19.76, id: "ps_6bacd07b-1d35-42cc-9a3e-6a0c556ef84b", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[40], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 5, 18, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 66.31, id: "ps_e56a7ab4-0d93-4479-8cac-a3f8c9e6c59a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[27], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 5, 6, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 420.36, id: "ps_b82594db-f8e0-4a70-aabe-a628b974d81c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 5, 7, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 31.31, id: "ps_109550c2-4472-4359-9c07-04b3f67bf488", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[55], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 5, 9, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 64.39, id: "ps_0b4488d0-468b-452d-bea8-0d84bd80529e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[19], seller: Sellers[53], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 5, 7, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 25.31, id: "ps_f48d587f-cde9-46d3-a015-49298d53f3af", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[66], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 6, 22, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 140.17, id: "ps_e87e8511-1817-4642-bc58-0631758f2891", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[57], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 6, 13, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 64.68, id: "ps_8e00edde-d520-4407-88d4-46bce170bd67", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[36], seller: Sellers[57], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 5, 7, 1, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 33.95, id: "ps_0ff0dc52-f6c9-40c0-adf3-c4c353605783", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[46], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 7, 2, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 41.81, id: "ps_86d495e0-32da-4c70-b9dd-6a4407ba3e05", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[21], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 7, 17, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 47.81, id: "ps_ea8921f6-61e4-4173-b738-3a30ca9833c0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[15], seller: Sellers[58], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 5, 7, 21, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 333.39, id: "ps_533932ba-55c3-4158-97df-358196ba07b3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[22], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 8, 10, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 403.69, id: "ps_f4fa6a02-aab3-48d4-b4be-10100a0cf6d1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[93], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 8, 22, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 305.07, id: "ps_0b7aa6f1-c8b6-421b-b6dc-5b828d056a64", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 8, 17, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 35.79, id: "ps_142b4a8e-e01e-48e7-8233-2aad20c11118", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[64], seller: Sellers[24], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 9, 10, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 196.87, id: "ps_9f764b32-3247-4520-88e0-4526c92ffc5e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[2], seller: Sellers[4], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 9, 5, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 232.98, id: "ps_7782c979-9699-4494-b299-fac8d1f318f3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[42], seller: Sellers[44], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 9, 13, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 359.25, id: "ps_2a190a78-4c21-484a-9d45-f0d071476515", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[104], seller: Sellers[17], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 9, 1, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 110.74, id: "ps_21a7cd50-2316-4403-a1c4-d8a31c4dc8a1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 9, 6, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 91.21, id: "ps_d84f5e0e-117e-47ca-ab72-27ab762bce87", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[26], seller: Sellers[14], state: PAYIN_STATE.INITIATED, date: new Date(2023, 5, 9, 16, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 12.75, id: "ps_2884f4a4-6c70-4660-a06f-1a2f0ad06a58", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[72], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 10, 9, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 149.84, id: "ps_cb1e7dbe-0883-4a1e-8865-5bba0ca28a3a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[79], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 10, 18, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 127.58, id: "ps_4a5381f7-1349-4ffe-8f9d-cbff2e6e38ca", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[29], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 10, 3, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.56, id: "ps_8363c151-8831-4e31-bdf2-c44a9b190a6b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[100], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 11, 16, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 80.34, id: "ps_209bedf7-b918-4bf6-b689-483767cb6eb0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[62], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 5, 12, 10, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 23.37, id: "ps_b9dc4dec-0b6d-4527-8ad8-906dc3318681", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[7], seller: Sellers[82], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 5, 12, 18, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad, dispute: Disputes[30] },
        { currency: "$", amount: 375.26, id: "ps_6914a74e-cdbd-48e7-90a1-7f6e8720aa4f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[46], seller: Sellers[74], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 12, 2, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 37.15, id: "ps_8a006bd2-b6c7-49fd-80fa-e78aa6312eb9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[77], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 13, 1, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 71.15, id: "ps_48e71a4d-a23a-4660-a2f1-9d94c6201d10", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[3], seller: Sellers[46], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 13, 3, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 48.82, id: "ps_08de2a1c-91f7-471d-a4a2-29863b8ee624", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[5], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 13, 2, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 34.06, id: "ps_2cc13592-b6cd-4647-9370-02f4f402b9fa", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[25], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 13, 19, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 55.29, id: "ps_0880c44c-ce7d-44eb-aa87-10b1c7e6befb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[104], seller: Sellers[40], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 13, 13, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 76.17, id: "ps_6da96635-0ea7-4412-8bf6-5cbcbe51bf1f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[6], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 14, 15, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 10.98, id: "ps_a6c6df1d-029d-4ec9-8f2c-e67e128d6f6f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[7], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 14, 20, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 46.93, id: "ps_2980147f-673e-487e-a630-5418fb53d949", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[29], seller: Sellers[53], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 14, 5, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 245.28, id: "ps_11c1fd01-23fc-4b0c-88ff-b3489dc52944", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[96], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 14, 20, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 86.68, id: "ps_2e672ead-cb11-4a65-bfbc-c771622b3cd0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[58], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 14, 11, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 290.35, id: "ps_3b094034-1b72-4fab-adc8-44d24fdd93c1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[25], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 15, 5, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 93.36, id: "ps_2a1d7b1e-78c4-4dcc-abb1-e0433048b961", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[100], seller: Sellers[34], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 15, 6, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 132.77, id: "ps_336ca928-fedb-4639-8ff6-bdf0c238ff9e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[9], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 15, 8, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 37.81, id: "ps_cfafa72a-a0f8-4541-99d3-b4c0049bd31f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[61], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 15, 15, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 42.84, id: "ps_9827dabf-0f8e-4bba-88fc-5bb628dee6e6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 15, 2, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 282.46, id: "ps_e92f6ca2-0c4a-4e21-94e0-f5f4d4c41391", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[68], seller: Sellers[62], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 15, 4, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 28.4, id: "ps_8191909c-9e64-45cf-9288-9f83fcddb122", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 15, 21, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 17.08, id: "ps_210e8f57-2147-4e6f-95c4-1b0d2b6752b7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[48], seller: Sellers[61], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 16, 9, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 288.62, id: "ps_a1eba007-1f22-4d41-aa0c-c8da46b2d7d6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[86], seller: Sellers[64], state: PAYIN_STATE.PENDING, date: new Date(2023, 5, 16, 18, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 84.87, id: "ps_50aa69c2-3557-49ed-8ca5-f5b5642521b4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[83], seller: Sellers[39], state: PAYIN_STATE.INITIATED, date: new Date(2023, 5, 16, 9, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 14.55, id: "ps_90e41975-c7d5-4ace-9f97-2edfa372ec35", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[19], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 16, 4, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 12.72, id: "ps_e6c39369-f4e2-4007-8eca-45724d369632", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[9], state: PAYIN_STATE.INITIATED, date: new Date(2023, 5, 16, 2, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 68.33, id: "ps_67dfcf59-0f45-40f3-b9e5-d5944d06bc78", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[85], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 17, 7, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 493.03, id: "ps_da926a61-8e0a-4288-8349-451e2de73115", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[92], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 17, 0, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 40.4, id: "ps_48ade1c0-c224-40a5-a27b-3a439ec37fd5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[11], seller: Sellers[13], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 17, 23, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 333.84, id: "ps_84f3fa7d-14e6-4929-b19d-69fdc4956129", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[40], seller: Sellers[45], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 17, 9, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 80.4, id: "ps_20dbb88d-a76f-41da-86ca-4f961b830066", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 17, 11, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 255.17, id: "ps_ffb4ac41-3297-4003-b006-4ffa46061eb8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[33], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 17, 13, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 71.36, id: "ps_2bdbbac5-feca-4022-80c8-65061cc37777", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[51], seller: Sellers[43], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 5, 17, 21, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[31] },
        { currency: "$", amount: 136.17, id: "ps_6f483b1c-bd5a-4482-97a5-194172b2d3d1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 17, 1, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 33.69, id: "ps_90d1748e-c84c-4824-9127-02e50646c99e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[39], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 17, 2, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 467.76, id: "ps_1a9cf4b9-9ab7-4d56-83a0-27431ccfe648", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[22], seller: Sellers[38], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 18, 9, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 181.14, id: "ps_b596d622-fff0-4ee1-93d9-e7cdb3d8ab2d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[37], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 18, 14, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 460.03, id: "ps_41d722ed-a3e9-4ca4-b556-b65ef185ea9f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[70], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 5, 18, 14, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone, dispute: Disputes[32] },
        { currency: "$", amount: 41.86, id: "ps_11bfcf61-a078-44fd-8386-93b1e948d859", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[71], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 19, 19, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 83.22, id: "ps_0295b0e3-11d9-4bf1-893a-2ac23304d361", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[6], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 19, 9, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 56.25, id: "ps_f38f1fcc-6f4c-4c13-8d3c-9e09055560a5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[90], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 20, 11, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 81.69, id: "ps_0829f273-82f4-4452-ac6f-b2beadaaed57", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[11], seller: Sellers[85], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 20, 16, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 268.62, id: "ps_8ad1da2a-e7f7-432d-88d6-5f9e37d79194", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[10], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 5, 20, 21, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 83.6, id: "ps_90ce9ef1-ead2-4c9a-ad2c-87da85ee2b03", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[54], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 20, 7, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 42.09, id: "ps_0b9ffc9c-2d56-4760-af19-dd30b27d0ab1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 21, 12, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 94.58, id: "ps_fbc5cc92-b67f-4794-917d-bd04a35be043", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[4], state: PAYIN_STATE.PENDING, date: new Date(2023, 5, 21, 21, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 49.24, id: "ps_cfe6a8a9-b2f4-4b65-a960-74d806cec1f1", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[62], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 21, 5, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 426.76, id: "ps_333cc82c-867a-4312-b661-fc02d65959b2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[14], seller: Sellers[80], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 21, 1, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 283.42, id: "ps_c9a0d838-a0a3-4da8-91da-b9dfd5b6a5cc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 22, 3, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 89.64, id: "ps_3e3dbffa-09cb-4870-8553-d37eba97135f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[18], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 22, 16, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 299.6, id: "ps_67d873d5-6289-4931-9810-bcacf198b64b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[98], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 22, 16, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 16.55, id: "ps_20b8502b-12f0-467e-ab18-8185c48095d4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[44], seller: Sellers[24], state: PAYIN_STATE.INITIATED, date: new Date(2023, 5, 22, 15, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 277.37, id: "ps_a57940dd-896b-4b7b-a20c-62224240ce56", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[9], seller: Sellers[82], state: PAYIN_STATE.INITIATED, date: new Date(2023, 5, 22, 19, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 76.77, id: "ps_146429c4-b44c-4e43-9697-d6a0e7192b04", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[91], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 23, 13, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 97.3, id: "ps_72469195-29ac-40ae-ac06-d896edbb066e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[60], seller: Sellers[16], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 23, 22, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 46.94, id: "ps_28233ab4-1c73-47e3-9242-9494e35ae89d", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[96], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 23, 8, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 29, id: "ps_9868c3a8-575e-4d67-bac5-5883730305b2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[35], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 23, 2, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 86.33, id: "ps_ce9cf8b4-caa6-44cc-ade2-baeb29650c1a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[5], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 23, 16, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 29.14, id: "ps_2d17fc5d-736a-4c1c-afb2-23028acc9c47", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[20], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 23, 17, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 233.32, id: "ps_3b5f6d25-dea3-438b-b829-1040668a7c4f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[70], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 24, 13, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 91.19, id: "ps_6dfa64e7-a21b-464a-bdcb-6cc232a224b8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[55], seller: Sellers[72], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 24, 12, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 88.65, id: "ps_eb4686d8-088f-4421-a073-0db9fd4089db", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[65], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 24, 11, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 55.91, id: "ps_e5f7554a-a98a-49cb-9616-68aad8b68471", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[44], seller: Sellers[75], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 24, 5, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 42.21, id: "ps_7e205a61-dfd4-4f1a-91ae-a51e02ec42df", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[64], seller: Sellers[3], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 24, 12, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 87.29, id: "ps_3e6e03ea-ec7f-468e-82fe-4e5a82ea1927", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[35], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 24, 22, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 78.88, id: "ps_4b4ec4e9-78b3-426b-a969-5ea352b3c4b6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[104], seller: Sellers[82], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 24, 13, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 400.83, id: "ps_9378edd1-d16a-4f7d-8e3a-e2dfec440dfa", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[99], seller: Sellers[84], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 24, 13, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 16.5, id: "ps_95f17d43-c76d-4ed4-8ea9-20f945be8f35", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[47], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 24, 14, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 191.5, id: "ps_77c20132-a0fe-42c5-a6e8-fe805ad68117", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[9], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 24, 14, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 161.68, id: "ps_f4010b72-c4e9-41f2-9bc4-271446fbbeee", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[7], seller: Sellers[55], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 25, 6, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 49.03, id: "ps_4c7c09de-ad30-49eb-8631-91c0cff32331", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[44], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 25, 5, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 68.25, id: "ps_68b1fe35-a3cf-4080-8cf6-28cc5eb5c3c9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[104], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 25, 22, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 461.89, id: "ps_e4dd2812-4091-45e6-9749-d4a4cdd4697b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[68], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 25, 20, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 38.18, id: "ps_b420813e-ffe8-4116-9b30-a56e4d8fc6b9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 25, 18, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 117.77, id: "ps_5d8252d3-4b60-4eb6-be06-38443b245c58", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[47], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 25, 14, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 146.81, id: "ps_0f199a7c-20b1-4e30-8131-6bb496ec403a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[101], seller: Sellers[43], state: PAYIN_STATE.PENDING, date: new Date(2023, 5, 25, 23, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 38.41, id: "ps_9aef31dd-8f70-432c-a2a3-3678b71ad498", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 25, 6, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 73.55, id: "ps_9c7a07f1-14eb-41d6-9d57-8e99baf7a659", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[88], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 26, 17, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 31.84, id: "ps_4c70132a-58f3-4421-b911-589e276f463b", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[13], seller: Sellers[35], state: PAYIN_STATE.INITIATED, date: new Date(2023, 5, 26, 22, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 189.65, id: "ps_fa9a8735-b16b-468c-ac76-99d7603623e3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[59], seller: Sellers[72], state: PAYIN_STATE.INITIATED, date: new Date(2023, 5, 26, 13, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 218.32, id: "ps_6b98295c-71d1-4a9e-9d5a-0ebd01eb311b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[71], seller: Sellers[83], state: PAYIN_STATE.PENDING, date: new Date(2023, 5, 27, 1, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 17.45, id: "ps_65e3186f-baa6-4b47-9492-a5eb67fda888", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[51], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 27, 14, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 94.65, id: "ps_0b95ac5d-cf9c-4944-a45e-0a2e0d1972fc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[88], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 27, 7, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 133.07, id: "ps_c691790b-6607-4af0-8425-6896e46f78a9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 28, 16, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 79.44, id: "ps_b541ce31-a9e0-4921-8ad2-effd62ccc985", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[20], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 28, 7, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 483, id: "ps_614a484f-f6ee-47af-8902-5f8941b8baa6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[57], seller: Sellers[8], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 28, 23, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 71.94, id: "ps_63c79bb9-98c4-4bd7-877c-ab1c2729a30c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[16], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 28, 6, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 73, id: "ps_58a6e290-e5a6-4a1d-8e8d-31e4725577ea", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 29, 5, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 191.77, id: "ps_725a100d-3e20-4584-bd7e-d7fa3c59e430", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[35], seller: Sellers[3], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 29, 17, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 42.92, id: "ps_f1d4545b-0c70-47b5-8550-bfb3d561f419", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[39], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 29, 22, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 12.09, id: "ps_bf6d624e-01f9-4735-be5d-16793e9a271c", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[58], seller: Sellers[40], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 29, 19, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 99.94, id: "ps_6f029e39-8188-4a7a-a5c0-4133aac5fe51", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[90], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 29, 21, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 49.97, id: "ps_f52f8513-9311-48e5-a606-7b07f40d10ac", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[62], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 30, 3, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 34.87, id: "ps_ec6f5003-b993-4218-a7e0-beef2e8bc0ab", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[17], seller: Sellers[57], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 30, 17, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 42.62, id: "ps_ab56340f-0978-411d-81c1-44038f87c0f3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[40], seller: Sellers[25], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 5, 30, 12, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 93.36, id: "ps_0711cfcb-5f8c-4a39-a344-2abe68da86dc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[24], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 30, 19, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.41, id: "ps_277a1842-96b8-4e7d-a664-7b396e34816c", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[43], seller: Sellers[21], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 5, 30, 2, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid, dispute: Disputes[33] },
        { currency: "$", amount: 65.1, id: "ps_9c6daff2-1944-4dda-9c55-bc3ca2e03e54", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[89], seller: Sellers[84], state: PAYIN_STATE.FAILED, date: new Date(2023, 5, 30, 19, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 49.05, id: "ps_49125039-c62e-47e9-ae7b-213546e14e36", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[90], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 5, 30, 7, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 103, id: "ps_dd4e3939-d785-41a1-a2a9-4c21d8658c4e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[10], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 1, 5, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 68.96, id: "ps_f4af51df-3482-4175-aa12-1e9c0febfd8a", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[5], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 1, 8, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 49.09, id: "ps_88812d82-da56-4d71-8477-bb8a90c60560", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[54], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 1, 0, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 99.88, id: "ps_f6320bbe-229f-4af9-8e5d-41cde6c8dc39", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[6], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 1, 14, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 28.11, id: "ps_1958c223-979b-4d55-80e5-88d0072e39f2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[60], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 1, 18, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 13.71, id: "ps_3d41631e-f880-41ea-bd64-f59efa67530d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[8], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 1, 7, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 19.67, id: "ps_1c019bd8-9a44-4727-a6b3-ea130ebc2872", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[3], seller: Sellers[40], state: PAYIN_STATE.PENDING, date: new Date(2023, 6, 1, 20, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 352.17, id: "ps_1eeba2b6-9b4b-42d4-8ee5-18ec7bbbe114", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 1, 6, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 14.77, id: "ps_ae2e872d-9b31-4ca9-ac95-577434022a11", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[40], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 1, 16, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 434.63, id: "ps_82b967e6-9771-4387-8ab1-b3e99bd26dea", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[97], seller: Sellers[80], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 1, 4, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 68.98, id: "ps_52ce26fb-ba2d-48d7-8273-24e4cbf19ca0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[13], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 2, 2, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 44.75, id: "ps_cebe1813-e148-4658-9c07-a721616df058", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 2, 18, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 42.01, id: "ps_8f986516-63f4-4f75-b026-5408035ef27c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[41], state: PAYIN_STATE.INITIATED, date: new Date(2023, 6, 2, 6, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 13.09, id: "ps_8b8a0b67-44fe-471e-8724-e893d35f6576", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[7], seller: Sellers[87], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 6, 2, 23, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows, dispute: Disputes[34] },
        { currency: "$", amount: 86.93, id: "ps_0a09d97c-441f-4d03-b247-8cf3fe51526c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[38], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 2, 8, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 162.51, id: "ps_e7ef08a9-638c-457f-8949-cd126a6de725", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[56], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 2, 3, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 71.58, id: "ps_208ae70d-3502-4211-ad92-eabd86af567c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[79], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 2, 8, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 202, id: "ps_fefd8e19-ca24-4fb4-a09e-eefab4ae9676", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[15], seller: Sellers[25], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 2, 2, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 26.93, id: "ps_4cb17321-7055-406d-a88f-bb5c0535cd4d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[46], seller: Sellers[80], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 3, 8, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 189.5, id: "ps_0d00bff2-f69c-422a-81d1-e39955fd8aa9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 3, 17, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 96.75, id: "ps_b67f2a1d-6efa-4d95-bba7-58b97c96e405", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 3, 22, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 36.94, id: "ps_23482bba-0554-4174-90b7-88f65687dbca", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[37], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 3, 20, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 133.59, id: "ps_2779206b-fdb5-4bcb-856c-859c6e54fe7e", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[47], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 3, 12, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 13.89, id: "ps_b13b648f-59bb-42d7-a81e-11dc625a7495", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[22], seller: Sellers[56], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 6, 3, 5, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv, dispute: Disputes[35] },
        { currency: "$", amount: 18.08, id: "ps_3f267ab5-b30f-4b11-b3ef-a9caafa02650", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[16], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 4, 5, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 50.81, id: "ps_398f877c-b2d5-4139-97fe-049641326cdf", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[30], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 4, 5, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 244.38, id: "ps_da59c0c9-3099-4c5f-9ccb-b9552b40b0e1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[25], seller: Sellers[83], state: PAYIN_STATE.PENDING, date: new Date(2023, 6, 4, 7, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 56.13, id: "ps_9e85e6ca-6d3f-4a9a-96ae-684151a2e89e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[71], seller: Sellers[4], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 4, 19, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 81.83, id: "ps_9853baca-bcc8-4827-8b60-0e4c62f0c7e1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[63], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 4, 3, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 133.71, id: "ps_24277d1b-6f12-4281-9497-0622488580f7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[93], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 5, 23, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 10.55, id: "ps_b2c9885b-cf7f-428e-b349-981246c43e27", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 5, 12, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 43.55, id: "ps_99c830a5-9c21-46a6-9398-d5dc7284a53c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 5, 13, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 16.11, id: "ps_bb0b84fa-8f2a-4fd6-b5b7-503618c0e7d2", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[57], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 5, 17, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 54.17, id: "ps_74491177-c5d2-4c5e-be76-86b1d762b648", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[93], seller: Sellers[64], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 5, 18, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 41.38, id: "ps_18fcbe0b-fd24-4e0b-bcd5-573e7950e36e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 5, 8, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 137.66, id: "ps_4337e87a-8c21-4fbf-bb8b-c6921c6ed574", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[36], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 5, 23, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 92.19, id: "ps_a117fd00-9498-46f6-bd46-b2b359d53765", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[50], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 6, 5, 10, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[36] },
        { currency: "$", amount: 92.78, id: "ps_ab702134-7d54-4edd-ada3-2afa932405a2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[20], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 5, 18, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 402.69, id: "ps_dea84dd2-6122-4791-b731-122408c3a844", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[56], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 6, 14, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 34.81, id: "ps_266fe9dd-8025-465b-b9fb-c62455cca5cf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[102], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 6, 16, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 144.71, id: "ps_90b998e2-2b61-4e1b-ad96-616ee94553ef", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[84], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 6, 20, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 233.05, id: "ps_74737f74-c175-4275-97b4-9bc2622ad6bb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[11], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 6, 22, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 352.01, id: "ps_959c1f0e-46ae-492b-84a2-db522f24cd43", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[23], seller: Sellers[23], state: PAYIN_STATE.INITIATED, date: new Date(2023, 6, 6, 2, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 41.72, id: "ps_5d2c776a-26d4-4b54-a5d2-575c59efefe2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[87], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 7, 11, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 494.34, id: "ps_1179bb8e-d44d-4c0b-8083-9bc1f2b5ba32", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[102], seller: Sellers[33], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 7, 18, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 97.61, id: "ps_e801859c-e303-4746-9845-f6c6c5c6ed9e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[64], seller: Sellers[18], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 8, 23, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 46.96, id: "ps_8af7d1b3-10d4-4e34-9788-8411e60e1740", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[89], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 8, 2, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 63.73, id: "ps_96e1abde-e571-42ac-9493-05fa144f204b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 8, 3, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 95.4, id: "ps_90a1183b-7749-4712-a6df-2843cd803e85", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[74], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 8, 21, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 88.64, id: "ps_f4215e92-5761-4fcd-883f-d9198bf587e9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 8, 15, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 30.33, id: "ps_a82fa7b1-9d13-41e0-a168-0bf2535b0557", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[14], seller: Sellers[4], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 8, 9, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 196.12, id: "ps_a01f1403-a90b-4dd4-8671-064058d2c52e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[93], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 9, 16, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 202.67, id: "ps_d7d0c685-4e3a-4540-8384-f59347e801f0", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[34], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 9, 17, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 17.96, id: "ps_f73aa02f-fc10-45b4-a5cc-5277c1433678", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[34], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 9, 12, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 39.82, id: "ps_87acccaa-38c9-4b30-acf9-cb671287ed92", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[98], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 10, 21, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 127.31, id: "ps_4fcaec62-6b4a-4ada-bd7c-c8df2c29cef4", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[31], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 10, 3, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 54.93, id: "ps_a1c59453-6138-46ff-b4fc-df3825366c07", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[26], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 10, 5, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 48.41, id: "ps_7817ad81-2cab-4e06-9b72-fc94d42089ae", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[54], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 10, 8, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 55.67, id: "ps_836a15b8-8b63-4e60-9dc0-521fa29fb527", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[20], seller: Sellers[46], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 10, 11, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 184.55, id: "ps_8afc2f07-fa56-4147-b494-001a8e903b6d", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[12], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 10, 10, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 11.23, id: "ps_48e0dc16-8e8a-4843-857a-594bf6d51224", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[84], seller: Sellers[72], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 10, 3, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 65.77, id: "ps_2690e62c-997b-4ffe-93e4-40594154fb72", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[30], seller: Sellers[34], state: PAYIN_STATE.PENDING, date: new Date(2023, 6, 10, 21, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 73.78, id: "ps_2c07f3d9-77fb-4b95-a5f5-d530f5f6caeb", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[71], seller: Sellers[15], state: PAYIN_STATE.PENDING, date: new Date(2023, 6, 11, 17, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 54.41, id: "ps_59124bfd-3e98-46e7-8a6b-f1acddd823f6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[55], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 11, 0, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 51.96, id: "ps_67687895-0d25-4a41-8763-88b178e27c83", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[12], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 12, 20, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 18.77, id: "ps_96add592-cb48-4dd6-a9c5-a29f81e5a354", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[60], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 12, 10, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 136.8, id: "ps_5d0e639e-7c63-4cca-84e0-a3a6ac635f61", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[74], seller: Sellers[35], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 12, 10, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 12.95, id: "ps_f48cf697-af54-4a49-aca0-4fad627f87f8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[29], seller: Sellers[28], state: PAYIN_STATE.INITIATED, date: new Date(2023, 6, 12, 14, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 63.38, id: "ps_d1794cf5-e18a-4767-9e15-7d3dff794601", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[19], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 13, 9, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 15.08, id: "ps_3fb1ba37-7b3b-4b6e-b710-fe2fa04667fb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[60], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 13, 5, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 29.88, id: "ps_43e69bc7-340f-4d65-8133-862275d931f4", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 13, 7, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 194.25, id: "ps_f4211c7b-001a-42e9-8d38-b15e3f157d56", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 13, 12, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 28.75, id: "ps_49a8e1ec-bdb8-4a3e-aae0-49a58022d5d5", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[95], seller: Sellers[64], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 14, 13, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 55.72, id: "ps_f99504db-fa36-45e0-b22b-18cfdfe24542", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[40], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 14, 0, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 3.18, id: "ps_49bafe78-e5f9-4f44-9541-2b04c217f59d", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[83], seller: Sellers[77], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 14, 1, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 89.82, id: "ps_df5f541b-c48d-4b54-872d-294c76908968", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 14, 6, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 72.7, id: "ps_24e266a3-2940-4a9b-8092-9233df466f2d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[104], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 14, 23, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 46.11, id: "ps_0baad1fe-cf97-4004-9c18-c5c614f682c4", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[35], seller: Sellers[55], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 6, 14, 15, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows, dispute: Disputes[37] },
        { currency: "$", amount: 155.94, id: "ps_9809f312-4272-4bde-b073-66408dfd2250", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[55], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 14, 23, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 82.79, id: "ps_21334d8d-3c1d-4442-84df-dec1d741bec5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[74], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 14, 11, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 23.73, id: "ps_80c50870-1a22-4e6c-90e3-0d648b24a332", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[50], seller: Sellers[23], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 14, 4, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 152.69, id: "ps_6c6b21f6-f443-4bd5-80d8-a5a5aa06b647", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 15, 15, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 40.73, id: "ps_210b09aa-87d8-4141-be03-0e101563cb9e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[93], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 15, 6, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 87.75, id: "ps_bee66c0c-042e-437a-bd2e-f81fe8a469a9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[65], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 15, 2, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 377.33, id: "ps_b6af601a-46e4-49f2-ba51-c09e86eb1350", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[81], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 15, 8, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 160.7, id: "ps_90efd702-6220-45ac-aff0-144fb1612292", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[5], seller: Sellers[23], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 16, 16, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 35.87, id: "ps_4267d569-3953-46ad-96b0-23902d4a1d17", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[59], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 16, 1, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 57.68, id: "ps_51a868e4-053a-41ae-8054-2be237f1191e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[50], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 16, 10, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 40.99, id: "ps_430fb7e0-a3aa-4955-92e7-511b11c4e5c2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[82], seller: Sellers[40], state: PAYIN_STATE.PENDING, date: new Date(2023, 6, 17, 4, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 16.15, id: "ps_f1663af8-9cc1-404f-820f-1c5ffa5b363e", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[59], seller: Sellers[83], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 17, 18, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 61.24, id: "ps_b074fac9-e3f4-4560-9db6-c36299ab552a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[97], seller: Sellers[67], state: PAYIN_STATE.PENDING, date: new Date(2023, 6, 17, 12, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 99.12, id: "ps_345ddb7c-2183-40d3-9e65-75d501d8cfb0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[92], seller: Sellers[68], state: PAYIN_STATE.INITIATED, date: new Date(2023, 6, 18, 13, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 14.27, id: "ps_312ad684-e559-43ce-ac60-d6fb65ea9af9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[91], seller: Sellers[60], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 18, 18, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 91.95, id: "ps_c8035492-97d1-4f68-abec-826e41f908a6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[28], seller: Sellers[5], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 6, 18, 13, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid, dispute: Disputes[38] },
        { currency: "$", amount: 45.02, id: "ps_1f9e5403-918b-44b6-a896-a9a81fe7414a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[64], seller: Sellers[34], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 6, 18, 2, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[39] },
        { currency: "$", amount: 77.74, id: "ps_2a2a7a65-3d98-453d-93a9-506e3f1cd593", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 19, 7, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 284.31, id: "ps_63263112-2f17-42a8-937f-816730dd1869", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[3], seller: Sellers[54], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 6, 19, 12, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows, dispute: Disputes[40] },
        { currency: "$", amount: 30.66, id: "ps_1ee57085-48a0-469d-ba57-e5d8bee7c3f2", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[66], state: PAYIN_STATE.INITIATED, date: new Date(2023, 6, 19, 19, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 310.97, id: "ps_276d5288-f4b2-4728-a8a0-bdf624541d68", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[36], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 19, 5, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 22.29, id: "ps_922ac2cc-a62f-4c1d-9c91-e9e29e19a6e5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[47], seller: Sellers[7], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 6, 19, 4, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad, dispute: Disputes[41] },
        { currency: "$", amount: 49.92, id: "ps_5435ee52-f215-4a04-9a2c-b8ceb47f20ff", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[1], state: PAYIN_STATE.PENDING, date: new Date(2023, 6, 20, 17, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 320.98, id: "ps_e86ec837-4109-4e92-b86b-c4cc1f266410", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[3], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 20, 11, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 108.69, id: "ps_9138a732-8b1b-4335-a568-2e13f8e86147", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[30], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 20, 20, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 425.08, id: "ps_dfbaf3a1-55f0-4161-a8cf-d8a1e75f3e79", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[68], seller: Sellers[54], state: PAYIN_STATE.INITIATED, date: new Date(2023, 6, 20, 9, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 162.42, id: "ps_fd645241-2ee4-4a3a-b7b6-4b75e384f012", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[96], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 20, 12, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 185.64, id: "ps_7494a5e9-0bd7-4036-a4d9-38487ba8d6b2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[27], seller: Sellers[88], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 21, 6, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 328.3, id: "ps_563630b2-316b-47c6-8364-cc44b71f50ec", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 21, 1, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 67.15, id: "ps_f0248750-3860-403c-bf96-082e507ee837", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[69], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 21, 17, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 278.14, id: "ps_ddcad282-3cfb-4301-9777-a78dc63d1942", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[82], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 21, 14, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 103.7, id: "ps_165de1ee-a349-4a3d-a014-e43c1e79ec29", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[87], seller: Sellers[9], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 21, 7, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 481.21, id: "ps_d00e4795-0f05-4796-93b6-ba59c7b052be", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[51], seller: Sellers[85], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 21, 2, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 20.94, id: "ps_0abb260a-9f71-46a7-a1ba-f9a3041bf18c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 22, 2, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 5, id: "ps_362299f1-3547-444d-983c-5b81f0e4f1bb", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[7], seller: Sellers[37], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 22, 23, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 228.83, id: "ps_100cc3ca-0a61-42f1-b15e-6bca85b4c36e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 22, 1, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 63.17, id: "ps_7e6b5966-2f73-4545-95c0-f40b21d9e4b2", provider: PSPs[1], method: PaymentMethods[8], buyer: Buyers[19], seller: Sellers[1], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 22, 8, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 145.68, id: "ps_2e7ad88e-fe7a-45f0-83f9-e8517ccdd505", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[5], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 22, 23, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 55.47, id: "ps_3d9cff9f-ff3a-4c30-a67a-9597de23ef6e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[87], seller: Sellers[32], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 22, 3, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 42.21, id: "ps_079331c7-038e-4b37-b7ba-1fc30e1b0549", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[17], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 22, 21, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 43.14, id: "ps_74b68b83-8fbf-49fe-997e-1d067f506881", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[17], seller: Sellers[9], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 23, 2, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 64.03, id: "ps_006cf468-f082-458e-ac90-ae9d35b97136", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 23, 9, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 89.56, id: "ps_ac6168c6-080b-4707-80cd-37dcf4d12062", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[99], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 23, 20, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 401.66, id: "ps_ca125a20-0550-459a-bc40-25501f8e76c2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[6], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 24, 9, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 65.86, id: "ps_6d3a52bd-5bba-4544-984c-e299428cebb8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[34], seller: Sellers[66], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 24, 5, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 366.67, id: "ps_53c60f73-be5d-4bc5-9a99-8d2355935917", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[11], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 24, 9, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 362.33, id: "ps_c1b72d5b-8450-4963-9792-110505ce8512", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[98], seller: Sellers[75], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 25, 9, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 61.01, id: "ps_3eeb36a0-c862-4634-acee-4102e79a7aeb", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[57], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 25, 3, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 290.05, id: "ps_8cc7192a-3b02-421a-980e-0de247746c4e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[3], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 25, 6, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 376.34, id: "ps_e308271d-a02b-428b-b84c-e94ca2806ddc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 25, 7, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 51.91, id: "ps_59373159-2ef0-434b-aeae-710812e28b92", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[5], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 25, 15, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 307.96, id: "ps_b5f17c11-2abf-4c1e-aeeb-660bdeda120b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[104], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 25, 21, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 199.87, id: "ps_706d0fde-944c-486a-b072-7c4d1b61bc4b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[41], seller: Sellers[67], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 25, 13, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 15.39, id: "ps_7cb106c8-0673-402b-9bce-ee4483eaf2d9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[18], seller: Sellers[68], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 26, 23, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 19.49, id: "ps_18c399b7-d1d9-4fd0-a7a4-9d3f848d1a28", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[36], seller: Sellers[56], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 26, 16, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 19.31, id: "ps_3fa974dd-8765-4569-a0b5-979539de582c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[6], seller: Sellers[78], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 26, 20, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 207.82, id: "ps_2a7793a8-cd62-472a-b85b-eb933fb5cdba", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 26, 2, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 156.75, id: "ps_fac92114-6b5b-442c-ab02-3f614ce51ccc", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[60], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 26, 11, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 46.93, id: "ps_41808a0b-d571-4bbb-9653-7982825b7a7e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[5], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 26, 16, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 179.35, id: "ps_f7008f6d-854c-4830-9760-31884a157e0e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[8], seller: Sellers[63], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 26, 19, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 88.68, id: "ps_df4e14e2-61e3-4580-b186-c7d8a5ccb0fa", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[30], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 27, 4, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 71.88, id: "ps_5fca8cff-d910-42d2-9471-ad4e74bdecf1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[44], seller: Sellers[24], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 27, 20, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 34.33, id: "ps_6e561a1e-d68d-4127-88ba-e1756ca9299f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[83], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 27, 15, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 18.09, id: "ps_82a5c654-5e0e-41a5-aff8-41511ad00104", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[35], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 27, 8, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 49.21, id: "ps_8bf0563a-260b-4882-a8e2-e151bc4438c1", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[50], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 27, 20, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 47.2, id: "ps_3b059fdb-b3c3-4ce2-ae63-86ebe7ed0546", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[34], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 27, 10, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 185, id: "ps_3c72785c-b016-4e14-bd7d-dfd5e845bc0f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[94], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 27, 23, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 58.58, id: "ps_2ef11eba-b21d-4870-80c8-72dd799721d2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 28, 20, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 320.83, id: "ps_eb594e43-9851-4b3d-8dd5-391d2d85ce44", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[63], seller: Sellers[13], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 28, 18, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 285.67, id: "ps_41add38d-ecdc-4f3d-b55a-661ce86a2f86", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[89], seller: Sellers[37], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 28, 0, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 91.14, id: "ps_5f86362f-8138-41ac-a184-c25bad7ccfe3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[49], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 28, 13, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 53.53, id: "ps_dd929102-e7b1-41c8-98fe-32b3ded69f83", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[84], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 28, 8, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 67.05, id: "ps_0eb026b1-0b9c-4cf2-92a2-a11275ec8fcf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 28, 21, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.44, id: "ps_d9a9cd71-9432-44ab-ad08-721282ad6952", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 28, 7, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 369.47, id: "ps_71986833-0a9d-4984-b3f5-b31af3284b41", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[5], seller: Sellers[7], state: PAYIN_STATE.FAILED, date: new Date(2023, 6, 29, 13, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 363.97, id: "ps_2c5d177c-8afa-44be-9623-4ebf9e8ecbb3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[96], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 29, 18, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 82.91, id: "ps_64dcfdf4-fc71-4d27-a0aa-ccac05bfcdfe", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[32], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 29, 11, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 71.79, id: "ps_f40f6c59-46a3-4cc8-9445-f699164796c5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 30, 0, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 77.44, id: "ps_1fc9ee26-8cd9-460d-8412-a19780c9dc13", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[45], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 30, 6, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 17.99, id: "ps_ce091921-7b76-4d0e-9867-b7d8957de5bb", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[25], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 6, 30, 10, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 10.68, id: "ps_300eb30b-16af-4674-b9d8-7e45a31409f7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[2], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 30, 10, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 40.14, id: "ps_59592f4b-2637-4122-bcaf-b6b0e7f6e434", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[17], seller: Sellers[33], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 30, 15, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 53.42, id: "ps_a63ec09f-202c-44a9-8976-db83a0cb3b3a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[101], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 6, 30, 22, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 92.89, id: "ps_e8879982-aec4-4b7a-9e25-d296e3a9b977", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[60], seller: Sellers[54], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 6, 30, 16, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 62.45, id: "ps_dc411792-edab-4f43-a701-02a360330212", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[82], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 1, 17, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 34.26, id: "ps_4d212c8c-0028-4da3-b2c0-a9f336f3ebe5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[86], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 1, 13, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 233.43, id: "ps_bccc2b8f-2806-48dc-b6ac-09e1f9a92e9a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[14], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 2, 19, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 323.14, id: "ps_ce941fe4-50d1-4de1-b33c-f2c287e10709", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[40], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 2, 7, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 47.9, id: "ps_cba52f5a-7e75-49a6-ac5d-6a47269d061e", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[3], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 2, 23, 22).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 68.94, id: "ps_f2339188-69e5-48c3-b540-72e3d70d73bd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[39], seller: Sellers[38], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 2, 13, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 48.94, id: "ps_539eba59-e447-4713-9be5-fa44ba06e01c", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[16], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 2, 14, 11).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 62.97, id: "ps_8efc5f7a-6c6d-4350-af88-9c04f86df20f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[73], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 2, 1, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 28.94, id: "ps_3a332b52-f88a-491d-bfd7-647123980409", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[101], seller: Sellers[81], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 3, 2, 13).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 391.76, id: "ps_8e197d2f-d84c-476c-b540-e578a6c5d41a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[50], seller: Sellers[55], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 3, 17, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 132.87, id: "ps_d2c68f9f-8a12-4309-a752-0409b4a231c2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[88], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 3, 12, 13).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileWindows },
        { currency: "$", amount: 63.74, id: "ps_a2400b77-b7f9-4bdd-acee-26d87102ae3b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[30], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 3, 8, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 49.5, id: "ps_0537d94f-b402-475a-b4a9-d68da3bbd4a2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[11], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 3, 6, 13).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 12.32, id: "ps_df69c801-6075-4910-8267-d67d0d141b52", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[37], seller: Sellers[58], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 4, 17, 59).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 223.27, id: "ps_04d455b3-e09d-4ab6-b260-a9581d280f0a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[66], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 4, 6, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 111.24, id: "ps_087ea71b-efd7-4b86-bd27-ab4ae21ef097", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[55], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 4, 22, 23).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 72.48, id: "ps_bfa00f4c-c934-4dfd-961f-a0170f3065fa", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[85], seller: Sellers[53], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 7, 5, 4, 6).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 295.37, id: "ps_44ecd4ad-6e18-4ddc-9b42-cb9fa1bb3f3e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[27], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 5, 12, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 130.69, id: "ps_4542c7dc-63a0-4f38-8e4b-194798471fa8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[77], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 5, 5, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 317.19, id: "ps_8c0a9b6a-d901-4fe5-985c-692c20cd2693", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[32], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 5, 16, 0).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 438.32, id: "ps_b2a5c74e-d8a1-4526-a5c6-6ace495ecc52", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[44], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 5, 10, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 461.04, id: "ps_69279981-ebae-4be6-a132-a222041c7a8a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[5], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 5, 18, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 16.14, id: "ps_c5b4432c-3529-4ebe-a68f-5c8029eebc22", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[16], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 5, 8, 28).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 300.82, id: "ps_1c74465d-4366-4ac7-a6f2-1c6d0e3b119a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[20], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 6, 8, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 435.3, id: "ps_caa5aef8-07a9-488c-837e-97b7e21d2b05", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[33], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 6, 9, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 40.04, id: "ps_fda3d40e-7fee-4aba-a18c-a25de1b2d138", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[23], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 6, 18, 52).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 368.44, id: "ps_3747a70f-deb4-44c9-9a4c-58bbe9284cb6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[24], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 6, 7, 38).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 142.04, id: "ps_c8253ee7-be62-492c-b7a3-fb0669dd6e58", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[77], seller: Sellers[61], state: PAYIN_STATE.PENDING, date: new Date(2023, 7, 6, 14, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 21.59, id: "ps_2a0f3bbe-3e99-4a6c-b8bb-53ceb6c44aa4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[83], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 6, 8, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 118.16, id: "ps_f63cb156-58ce-4588-aa13-4a5e1d65563b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[84], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 7, 17, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 91.13, id: "ps_e56a1cc2-93db-4ad0-a599-b8aa4326ae45", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[36], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 7, 12, 28).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 98.61, id: "ps_98fa472b-474b-4aa5-9c8a-c9fbee6d6625", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[36], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 7, 14, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 30.69, id: "ps_0c16c413-357d-4228-b56d-687e3dafdef1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[11], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 7, 13, 2).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 10.79, id: "ps_b331924d-d68d-4201-97a7-a5fd3bbff7c4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[11], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 8, 6, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 59.02, id: "ps_95bcd68a-6106-4c55-9422-fca2be543082", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[70], seller: Sellers[67], state: PAYIN_STATE.INITIATED, date: new Date(2023, 7, 8, 0, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 102.52, id: "ps_a67e31e5-e50c-4f36-80dd-502beb679a23", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[39], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 8, 2, 46).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 229.04, id: "ps_bcbdaed7-72c9-4f5c-9431-c62a8deb8f7e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[28], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 8, 10, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 43.57, id: "ps_c165d296-bc89-45d1-bb44-094041048b37", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 9, 17, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 270.71, id: "ps_10c97978-e810-42fd-a656-ada20a3bb415", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[77], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 10, 2, 46).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 286.98, id: "ps_8204e082-c168-4da9-919e-05de209b34e2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[69], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 11, 7, 11).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 57.22, id: "ps_a4464ce9-f9d7-4bcc-b209-cd37ed973fe5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[83], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 11, 13, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 147.85, id: "ps_30c7f23b-2c67-4074-bee7-8f8cc1b3c415", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[19], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 11, 7, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 53.46, id: "ps_92659d6a-70b6-4226-9440-94c69523ef2b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[59], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 11, 4, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 421.2, id: "ps_0fd9cd15-4b25-4e0e-9af7-b0e5fb01446d", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[37], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 11, 9, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 91.37, id: "ps_b06e66ba-ea59-4bd5-9dfc-98ab15a7af7a", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[97], seller: Sellers[32], state: PAYIN_STATE.INITIATED, date: new Date(2023, 7, 11, 22, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 331.71, id: "ps_85130cc7-90e6-4525-8868-c4bc06d98af8", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[52], seller: Sellers[83], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 7, 12, 11, 52).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 64.97, id: "ps_14ce4d76-03c7-4344-b073-86a039e470f5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 12, 10, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 82.83, id: "ps_ed088445-b67f-43da-a2e3-c2e85df64a5d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[94], seller: Sellers[2], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 12, 11, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 77.14, id: "ps_3f06c04b-3247-46b8-881f-5ff5f4d11cc0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[38], seller: Sellers[60], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 7, 12, 16, 3).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 181.43, id: "ps_a8b49087-6257-4c91-99bf-06445718c390", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[79], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 12, 11, 16).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 131.37, id: "ps_dcb5e9a2-1959-4eb8-89ba-17e96147de78", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 12, 19, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 96.98, id: "ps_c9dfed11-d49a-472b-a9d9-fa603acc3d02", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[35], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 12, 14, 22).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 176.47, id: "ps_93882424-d8a9-46f9-9b9b-7127d3be49eb", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[88], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 12, 2, 27).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 29.73, id: "ps_99b87508-e097-4c32-9fd5-429bf5a902af", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 13, 23, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 10.66, id: "ps_a0304093-8be5-476d-aaf6-2f7805807265", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[22], seller: Sellers[74], state: PAYIN_STATE.PENDING, date: new Date(2023, 7, 13, 5, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 63.51, id: "ps_cc92703e-1427-47fc-9b5d-5c8e450cffe7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 13, 1, 48).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 38.02, id: "ps_8bfac2f7-85f5-4c2f-9cd8-9439bbacff5a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 13, 20, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 268.45, id: "ps_5b11ce14-b450-41d6-8635-89147ad9c5b9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[59], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 13, 18, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 74, id: "ps_751512bc-7a64-4022-aeaa-8d11c6d0aecb", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[17], seller: Sellers[48], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 14, 21, 21).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 44.95, id: "ps_6fd77b5b-dd35-44e9-955f-726bd9155b84", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[74], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 14, 21, 52).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 31.22, id: "ps_0b39c4c2-ed18-40e2-860f-403394548a9f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 15, 2, 42).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 49.59, id: "ps_2b35e748-04ec-4a57-bf7c-c9551822eb13", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 15, 6, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 13.82, id: "ps_eadebbf9-9f66-4df6-9ebc-113a62208235", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[83], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 15, 1, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 68.56, id: "ps_9358bd00-56cd-4819-978d-92d720b79e2a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[5], seller: Sellers[88], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 15, 22, 6).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 448.9, id: "ps_27c6ebb0-3b0d-477c-9bbe-c1689c6509e0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[45], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 7, 16, 7, 40).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 75.7, id: "ps_be0e4313-fdaa-4a98-80e2-43227e9abce4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[2], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 16, 9, 31).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 483.97, id: "ps_631157bb-30ff-4ff7-85e9-b6ea666dec19", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[43], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 16, 7, 2).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 114.74, id: "ps_1ca0385e-1a29-4eff-aff7-4cf30c37cce3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[35], seller: Sellers[36], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 16, 1, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 205.45, id: "ps_b435a0bd-acf5-4e5c-832e-a7a9552d06fd", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[6], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 16, 16, 10).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 82.18, id: "ps_0324b81b-f29f-4ae4-a9d2-fe389824187a", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[32], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 16, 0, 16).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 14.23, id: "ps_98d80846-ab84-44d1-a9e2-357d76be422c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 17, 10, 52).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 87.94, id: "ps_273c4609-4e42-4b47-bdf0-5780549f55e0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[85], seller: Sellers[82], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 7, 17, 13, 49).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.smartTv },
        { currency: "$", amount: 18.74, id: "ps_3349eb7a-884e-40da-946b-fe35927ebfa7", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[13], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 17, 3, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 77.77, id: "ps_134d236b-067d-4fa1-a0ae-09b9ca2e8ffe", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[54], seller: Sellers[78], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 17, 18, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 59.55, id: "ps_637b0165-0d98-4535-ad51-5c57365da138", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[65], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 17, 10, 59).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 23.33, id: "ps_6039bb8a-1cc1-4f55-b1af-a7bb3e0c66c3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[102], seller: Sellers[63], state: PAYIN_STATE.PENDING, date: new Date(2023, 7, 17, 22, 37).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consoleXbox },
        { currency: "$", amount: 477.28, id: "ps_84aa5b19-8f63-4e9f-b6e2-22469ba0ba75", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[20], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 18, 5, 12).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 75.8, id: "ps_dfc56b7b-93ec-482a-b63b-380bdecf73c1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[15], seller: Sellers[2], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 18, 10, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 95.06, id: "ps_1a916856-e33d-4b56-9382-7c007ebc216c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 18, 1, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 487.73, id: "ps_5b49f8ec-9d03-417e-ad63-54711a5bc71d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[44], seller: Sellers[43], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 18, 2, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 308.25, id: "ps_d70c8717-eb65-416c-9166-8a106432f97d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[43], seller: Sellers[18], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 19, 7, 36).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 63.96, id: "ps_2a1964f0-4dc9-4334-b8c4-b0b08d169c46", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[68], seller: Sellers[51], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 19, 11, 47).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 94.37, id: "ps_6680160c-9f2b-43d3-a35a-ceb3d280227b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[16], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 19, 9, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 87.9, id: "ps_ad224284-4c5c-4e74-aa42-782127d8a7af", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[94], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 19, 7, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 79.21, id: "ps_4aee1cb5-1b51-4370-83e9-9a4062f26590", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[34], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 19, 14, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 91.27, id: "ps_cd93850a-a230-4b72-91ca-3867d9546bb5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[13], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 19, 0, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 45.75, id: "ps_f217d1eb-0577-4312-8f4b-d7250a680133", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[67], seller: Sellers[53], state: PAYIN_STATE.INITIATED, date: new Date(2023, 7, 19, 21, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 42.76, id: "ps_5dcdea1d-ac6e-4460-aefa-dbccce7a38c4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[64], seller: Sellers[26], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 19, 6, 22).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 71.01, id: "ps_6a85929c-eba9-4908-a0c6-a5e6e09ea1f6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[71], seller: Sellers[29], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 7, 19, 18, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone, dispute: Disputes[42] },
        { currency: "$", amount: 82.74, id: "ps_b426cb9d-edf4-426b-80b7-6cb93865da4c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[104], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 20, 4, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 23.35, id: "ps_a48d6c6f-586d-4e84-b7cd-e87400d325d0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[60], seller: Sellers[75], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 7, 20, 14, 33).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone, dispute: Disputes[43] },
        { currency: "$", amount: 37.81, id: "ps_655dec21-5c6b-4dd9-8c25-5bf52e139451", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[31], seller: Sellers[45], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 20, 5, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 225.41, id: "ps_67c090f7-9f91-4973-95b9-d5fe486e6780", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 21, 0, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 205, id: "ps_b344802b-69a9-4a22-9a80-3e88fc5fc6c5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[66], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 21, 15, 23).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consolePlaystation },
        { currency: "$", amount: 249.88, id: "ps_793dac61-b54e-4c3b-acb7-c4052db3e58d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[12], seller: Sellers[86], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 21, 6, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 377.84, id: "ps_1c7e60cd-a987-42d3-85ad-690cb91efed2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 21, 16, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.62, id: "ps_02c31b87-677f-405c-bd11-71566c608a32", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[32], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 21, 8, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 92.12, id: "ps_10d1ab93-5704-4180-a6c3-6b33f5f1fadf", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[50], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 22, 20, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 18.21, id: "ps_d6d29572-37f4-419d-b61f-5e3622d9c89e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[25], seller: Sellers[68], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 7, 22, 19, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad, dispute: Disputes[44] },
        { currency: "$", amount: 59.26, id: "ps_e4debaf3-14d4-4986-b1d4-7a71238f45c0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[66], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 22, 16, 0).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consoleXbox },
        { currency: "$", amount: 66.07, id: "ps_a554feb5-02e9-4ba7-801f-a8151f5aa938", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[92], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 23, 20, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 77.47, id: "ps_61e0343a-03cd-4eeb-b58f-967ce12d161b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[25], seller: Sellers[60], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 7, 23, 5, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs, dispute: Disputes[45] },
        { currency: "$", amount: 217.56, id: "ps_19e20860-0f80-4e13-a87b-ed512d26bf68", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[38], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 23, 10, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 77.01, id: "ps_eae337c6-1ac9-473e-9e70-8420c3213e67", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[49], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 23, 2, 56).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 74.24, id: "ps_fe5633c1-9326-4bc3-a3fe-5d9c8a0ac162", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[9], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 23, 4, 40).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 96.23, id: "ps_657c2b35-a853-4596-b954-4527acf2065c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[85], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 23, 10, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 143.38, id: "ps_cd5de368-e8ed-4d36-9a2b-7a80786ec3c8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[35], seller: Sellers[42], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 24, 4, 16).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 358.56, id: "ps_f0090a03-10b3-4a8f-9fab-92e7bfd5f1d8", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[16], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 24, 11, 37).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.smartTv },
        { currency: "$", amount: 454.41, id: "ps_fa061e3e-d740-4864-b3fa-8df51bbbd33e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 24, 16, 50).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 10.49, id: "ps_8af0587f-6a51-4781-a7a2-c53dd2d799f7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[33], seller: Sellers[88], state: PAYIN_STATE.PENDING, date: new Date(2023, 7, 24, 19, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 158.67, id: "ps_7fc1d455-a166-4199-ae71-16401d6ea682", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[42], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 7, 24, 0, 20).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 19.34, id: "ps_fb5b8e8b-7bcc-4dcc-b93b-6a6731af90d3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[41], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 24, 8, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 78.79, id: "ps_c9d06040-c667-49f4-87df-2749b9f04d3f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[19], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 24, 7, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 87.26, id: "ps_ab70e5a7-6dc8-41f4-86a8-cef218bab13f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[70], seller: Sellers[40], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 25, 2, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 37.15, id: "ps_716d862e-37ff-4194-9482-ae3cbbd043e9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[75], seller: Sellers[39], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 25, 8, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 68.19, id: "ps_e5cd4e6b-5838-4022-9694-ece022c50d1b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[10], seller: Sellers[20], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 25, 15, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 182.91, id: "ps_5f4cf563-a53b-4d03-977f-1e5bd18ce2b0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[80], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 25, 15, 38).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 136.61, id: "ps_e5df9528-40cf-4e87-a752-b655a4c4ec77", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[82], seller: Sellers[38], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 25, 18, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 61.49, id: "ps_5c22bed6-3dbf-4818-bf9a-5937b2d50127", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[34], seller: Sellers[11], state: PAYIN_STATE.PENDING, date: new Date(2023, 7, 26, 22, 30).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 74.86, id: "ps_50f3fba7-aeb4-4502-a221-94c991ff47e4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[45], seller: Sellers[17], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 26, 11, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 114.99, id: "ps_b0d986cc-55ad-4bfb-ac34-d90fc6a24a89", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[35], state: PAYIN_STATE.REFUNDED, "isFraudolent": true, date: new Date(2023, 7, 26, 0, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 367.35, id: "ps_60f0cc15-bcd0-4f76-be4b-0f943b0aafef", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[47], seller: Sellers[6], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 7, 27, 18, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 206.15, id: "ps_9d48c70d-186d-45d5-8a27-9fb00315352b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 27, 22, 3).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 82.84, id: "ps_2f20b60e-bd94-4af2-89d5-d43de6a03fe5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[62], seller: Sellers[16], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 28, 3, 58).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 22.45, id: "ps_cc5e7c99-8f86-45f5-9b3c-e1482b9382b1", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[71], seller: Sellers[20], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 28, 4, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 90.01, id: "ps_23ccef02-a8c1-4a5a-af7a-faf72e2279f9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[59], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 28, 18, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 18.85, id: "ps_b4a30420-015a-410b-b23a-530d13e4b9e0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[87], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 28, 6, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 13.07, id: "ps_f35453f9-831d-43b3-b023-a0d3392cb080", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[53], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 29, 12, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 56.95, id: "ps_a433ae3e-89da-4e12-9a7a-bf5c0c8d29bf", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 29, 7, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 48.91, id: "ps_ed2832b5-13fb-4399-b3ee-4ca66e4dd540", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[74], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 29, 1, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 38.66, id: "ps_806e75b7-1243-4ab5-8a3f-85c13cd2e4bb", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[78], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 29, 9, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 28.28, id: "ps_aadcca81-0cdd-463a-b16b-cd881b89a5b1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[78], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 29, 4, 10).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.smartTv },
        { currency: "$", amount: 72.06, id: "ps_e56fc23e-5727-444e-9a2c-ca4efa7bfc99", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[41], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 29, 15, 9).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 43.27, id: "ps_5d466d4f-0811-4186-b76f-964fd0a65123", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[61], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 30, 13, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 127.39, id: "ps_835691cd-7e20-4941-beab-853d9849ceab", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[74], seller: Sellers[9], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 30, 4, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 150.68, id: "ps_54189585-9b1d-47d3-a4a6-d3960c0818b1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[65], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 30, 0, 53).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 50.43, id: "ps_e2e5a100-fcc9-4aa8-896e-e955dcf70a56", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[6], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 30, 12, 28).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 495.52, id: "ps_c3264288-26b7-4ac3-8c7e-f3ddef2b6409", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[9], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 7, 30, 18, 46).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileWindows },
        { currency: "$", amount: 84.66, id: "ps_7b943299-52b1-46ab-8565-eb5bc5d3af9f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[68], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 30, 18, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 190.81, id: "ps_9f4df5a8-3e1b-4c1c-bec8-2823dff0c05a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[57], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 7, 31, 8, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 99.31, id: "ps_48ba1c2f-725e-4d77-b646-4b7bcaa2acc3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[49], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 31, 1, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 52.29, id: "ps_e128eb93-28cc-4718-9ae7-0134b1d01c35", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[58], seller: Sellers[3], state: PAYIN_STATE.PENDING, date: new Date(2023, 7, 31, 1, 17).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 49.88, id: "ps_7e8758e3-0d97-4da8-b9d3-ffaec565ac93", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[48], seller: Sellers[77], state: PAYIN_STATE.FAILED, date: new Date(2023, 7, 31, 19, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 342.4, id: "ps_908a3a67-5ce1-41e2-bb25-f4c941cdaa3c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[24], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 1, 5, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 15.61, id: "ps_c50d985b-c18d-49ef-b0db-faa1687c3818", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[95], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 1, 9, 56).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 68.07, id: "ps_07f3b782-cb58-4765-aba9-9adbb00daff0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[5], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 1, 7, 33).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 99.38, id: "ps_1f4db17d-d5d6-4f18-b403-f2f21600716d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[67], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 1, 4, 48).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 14.8, id: "ps_43c53dd6-3831-45bd-8584-9785cccf2994", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[39], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 2, 20, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 470.26, id: "ps_99f9a0e0-89a1-40b5-87fc-64f5707bed5f", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[89], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 2, 0, 2).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 157.44, id: "ps_90b37747-ea04-4738-895e-aa7673a7fbea", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[74], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 2, 10, 26).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 31.12, id: "ps_96c0f4a9-2780-422e-9f37-248ec73c1477", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[99], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 2, 2, 16).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consolePlaystation },
        { currency: "$", amount: 22.29, id: "ps_8c90e358-af6b-493d-9249-2f7a3a2e04dc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[103], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 2, 13, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 12.66, id: "ps_21e3c3b7-89b7-4c27-98ff-c608631ca27d", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[5], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 2, 3, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 13.28, id: "ps_c8414233-74f4-4623-bb0e-dae4d9df4a25", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 2, 4, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 482, id: "ps_2bdfc4af-835f-46ec-b66a-ed21393ff238", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[39], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 2, 20, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 41.55, id: "ps_6f495604-c427-4161-afa3-d9ccf8f6cec8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[64], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 3, 10, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 74.28, id: "ps_bd24a0b0-138b-4f38-8def-b488c3cc8fb8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[61], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 3, 1, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 86.77, id: "ps_47398552-8c50-4448-a59f-2273d5a63b57", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[39], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 3, 13, 21).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 42.24, id: "ps_052a154f-2262-4509-b9a3-e4c2942be1ce", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[68], state: PAYIN_STATE.PENDING, date: new Date(2023, 8, 3, 0, 2).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consoleXbox },
        { currency: "$", amount: 14.12, id: "ps_ea736c24-b384-4020-a55a-1325b3a3985c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[43], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 3, 0, 28).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 381.39, id: "ps_5b54ebc1-74c8-4e3a-80a9-b34c8bd0eb10", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[62], seller: Sellers[13], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 3, 5, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 318.66, id: "ps_d27f8e88-4a49-4811-aa08-c0878b0962c7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[51], seller: Sellers[35], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 3, 18, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 72.49, id: "ps_f198c8f5-ad2d-4772-beae-1bd1cec44604", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[53], seller: Sellers[82], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 3, 10, 28).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 331.4, id: "ps_09bc5377-20b3-467b-b72e-0be7a8b04e28", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[36], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 3, 12, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 493.14, id: "ps_2b6b43e8-20c5-4b97-b902-824634d89544", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[55], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 4, 23, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 72.27, id: "ps_013d6127-163d-402a-9f20-2470e8b3a2c8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[96], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 4, 3, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.78, id: "ps_45598e16-f408-4290-9a4f-acd5852779df", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[19], seller: Sellers[62], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 4, 0, 16).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.smartTv },
        { currency: "$", amount: 34.01, id: "ps_ebab38ab-75d3-4676-905e-835f7f29ec84", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[24], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 4, 22, 20).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 185.66, id: "ps_213d2c36-92ba-42a9-bb7a-28d6ddc1936f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[35], seller: Sellers[46], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 5, 17, 53).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 372.4, id: "ps_a016118f-b1da-4721-b728-bfbb521233ce", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[36], seller: Sellers[44], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 5, 11, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 226.4, id: "ps_0b2bd199-1335-440d-87c2-d499c23a1010", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[6], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 5, 9, 7).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileWindows },
        { currency: "$", amount: 139.66, id: "ps_56bcf908-e767-4638-a266-a333fe9ec980", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[60], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 5, 22, 36).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 36.74, id: "ps_e891c405-e3f6-404a-bca2-38ee12a63122", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 5, 6, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 58.01, id: "ps_2e19a09d-8f0f-4537-8169-8b75a85b0512", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[45], seller: Sellers[68], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 5, 19, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 94.74, id: "ps_39c67dc7-e387-409e-9d35-3e3800a8b773", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[75], seller: Sellers[14], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 6, 19, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 16.28, id: "ps_69657152-de9e-46da-9cfe-0ea48c0599ea", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 6, 13, 36).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 34.86, id: "ps_be373119-e4c9-4056-959a-f5685d2728e0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[94], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 6, 22, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 70.66, id: "ps_d03f801d-b50e-43d9-8eb8-1aa063a1ae7f", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[96], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 6, 7, 39).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabledAndroid },
        { currency: "$", amount: 133.38, id: "ps_e7cd7d6e-0882-41b4-9a4d-c589d4906fbc", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[17], seller: Sellers[83], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 6, 4, 58).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 448.93, id: "ps_89b65a28-5211-4693-aa9f-aa4d19c59abf", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[100], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 6, 20, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 53.06, id: "ps_d4f78b77-0259-419a-adc0-bea7d6a5aad4", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[13], seller: Sellers[67], state: PAYIN_STATE.PENDING, date: new Date(2023, 8, 7, 6, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 23.25, id: "ps_79eea493-b9b3-4b51-af24-181a966d81a2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[55], seller: Sellers[63], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 7, 11, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 197.92, id: "ps_4435a76b-adab-46a1-9da8-f9660c05c1a2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[36], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 7, 10, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 92.49, id: "ps_13c9660e-3c8d-4e01-b344-95efab4d1f2c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[86], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 8, 1, 0).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 14.49, id: "ps_e3bd14e3-ecae-41d1-90f8-fc16d70d117f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 8, 20, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 74.82, id: "ps_603f134a-03dc-4f52-8b67-00d49052f33a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[47], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 8, 15, 59).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 40.26, id: "ps_4c01907c-ff2f-44da-b554-6c2344ecc45b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[80], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 8, 5, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 11.5, id: "ps_9b80491d-4c2a-400c-93f3-5a282aa39fcb", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[46], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 8, 17, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 302.34, id: "ps_ae7745b0-71ce-4ace-9348-71d0439a9690", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[55], seller: Sellers[50], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 9, 17, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 339.2, id: "ps_cfd6a3e7-7c26-44b0-b553-99bbf9800d5f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[10], seller: Sellers[54], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 9, 22, 10).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.smartTv },
        { currency: "$", amount: 161.96, id: "ps_2fe2ea12-700c-4901-9b39-d311ea57b78f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[55], seller: Sellers[80], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 9, 5, 6).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 41.14, id: "ps_a6990326-963b-4fda-aeb3-8f8bc0d8bfa9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[14], seller: Sellers[16], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 8, 9, 0, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[46] },
        { currency: "$", amount: 88.76, id: "ps_76cf68b6-4016-4cf1-a834-371427a2146f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[3], seller: Sellers[15], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 9, 13, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 75.36, id: "ps_75eeddb3-eba7-46fe-bdde-041d0bf65864", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[42], seller: Sellers[3], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 9, 19, 20).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 211.68, id: "ps_b807a5e2-59ae-4b22-9f33-0579f4aa2bcd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[58], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 10, 18, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 470.1, id: "ps_6506e304-66fd-4acb-a533-8e59126d79d0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[21], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 10, 18, 18).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 273.8, id: "ps_7bfa1a5c-c131-443d-8d42-a90cefe3d348", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[10], seller: Sellers[71], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 10, 0, 8).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 85.11, id: "ps_5754cc7f-37b8-41aa-ba9f-8fe0aa2c3b1f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 10, 5, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 34.56, id: "ps_c0f438fd-5c2e-48b8-b958-5a858c8cffa7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 10, 23, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 53.77, id: "ps_800b53a3-bf55-426a-989f-12cd54d41a43", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[76], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 10, 3, 12).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 165.73, id: "ps_dafaacde-af29-4dee-9970-50c8b5a7c3cf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[87], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 10, 4, 50).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 11.28, id: "ps_4a9a404b-a2f6-4363-8a0b-d837fbeef546", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[53], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 11, 7, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 486.36, id: "ps_abc0c755-21e3-4006-bde3-1db91b980f53", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[101], seller: Sellers[30], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 11, 2, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 63.81, id: "ps_eaef39f4-30e1-4837-923a-12bbb43fbe3b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 11, 4, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 44.46, id: "ps_67bca5d0-562b-4a6c-a798-7d4e7a0c01d0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[92], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 11, 17, 0).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 74.62, id: "ps_ea1e08ab-45fc-491b-91ff-436f4cd1363f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[20], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 11, 23, 20).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 405.8, id: "ps_fa57ff52-ec13-4b53-9f66-29063404b28d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[56], seller: Sellers[13], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 11, 9, 32).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 10.23, id: "ps_8d66ed81-30dc-499f-baf8-c77da946abe1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[32], seller: Sellers[38], state: PAYIN_STATE.PENDING, date: new Date(2023, 8, 11, 7, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 399.49, id: "ps_08e11aa6-c137-4888-a890-34fb34ce1488", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 11, 17, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 52.75, id: "ps_fb5344d1-4fc1-4241-bac3-7e37d0373dec", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 12, 21, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 87.03, id: "ps_1d167516-33b2-4269-af5d-56ec53aeb5d9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[101], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 12, 17, 26).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 141.83, id: "ps_7af73b05-7083-485b-a504-4bdfa21a8131", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[51], seller: Sellers[9], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 12, 6, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 347.04, id: "ps_62bb2c8c-cadc-44e0-8bfb-beec02810cff", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[3], seller: Sellers[67], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 12, 13, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 56.57, id: "ps_cee5bc32-c3ec-4124-b41f-9e3056fbd651", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[20], seller: Sellers[44], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 12, 18, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 108.33, id: "ps_2d7fca2e-ff62-4709-a263-b354db8c1cf5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[75], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 8, 13, 21, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv, dispute: Disputes[47] },
        { currency: "$", amount: 263.75, id: "ps_fc0f3a72-3911-4d68-b7f1-11c3fe8cf25b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[76], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 13, 11, 56).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileWindows },
        { currency: "$", amount: 25.49, id: "ps_de4854f9-2952-4faa-9fd0-dfe620abc2ac", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 13, 3, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.29, id: "ps_9fc3aeb1-4c2a-4310-aa65-e0c63008b06d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[96], seller: Sellers[3], state: PAYIN_STATE.PENDING, date: new Date(2023, 8, 13, 22, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 88.2, id: "ps_0989f3e5-ab21-49ea-ad1a-f6ba1b595d47", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[40], seller: Sellers[50], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 13, 23, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 99.69, id: "ps_90524c9e-81ba-4ce0-8fc0-4f1b156dffe4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[20], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 14, 3, 57).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.smartTv },
        { currency: "$", amount: 18.99, id: "ps_c91081ce-566f-4ec2-a286-07e79a4e0ab3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[36], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 8, 14, 9, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone, dispute: Disputes[48] },
        { currency: "$", amount: 317.51, id: "ps_0462a798-995b-46e6-9f6a-b525457c8a0e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[46], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 14, 20, 13).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 93.25, id: "ps_6e5df0ff-be5a-45e5-9575-eba69b48f52d", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[47], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 14, 1, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 23.73, id: "ps_a1701404-52f4-494e-9f7a-8d94cd02d89e", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[14], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 14, 9, 0).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 49.21, id: "ps_e62c2802-9389-45b3-ac6d-4e6d05e056b8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[82], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 14, 11, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 322.79, id: "ps_de2fd3eb-f22b-4c88-b162-eb289973e4ed", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 14, 4, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 12.76, id: "ps_fabd5732-68ed-4443-abbc-89f2c4726ea5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[46], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 14, 6, 19).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 35.58, id: "ps_6e2f8152-d2ec-46ed-8305-8da825f07075", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[2], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 15, 4, 29).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileWindows },
        { currency: "$", amount: 151.37, id: "ps_429b423c-8852-4790-bea5-b1ce3504dc77", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[94], seller: Sellers[65], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 15, 12, 51).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 69.71, id: "ps_cb8cae44-816f-44bd-9802-7f8751cd5f20", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[41], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 15, 18, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 54.56, id: "ps_1ff99811-b99f-4d5e-ac80-136041ef7195", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[10], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 15, 12, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 496.29, id: "ps_5b2c0393-09df-4e91-b62d-c55dee3c150b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[67], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 15, 2, 47).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consoleXbox },
        { currency: "$", amount: 362.91, id: "ps_1b5589cd-ae6f-45d5-8b38-3d688a037ab6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[99], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 15, 7, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 120.65, id: "ps_320470b8-12d5-46a7-8c32-a0aac062f6f1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[10], seller: Sellers[26], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 8, 16, 8, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone, dispute: Disputes[49] },
        { currency: "$", amount: 82.18, id: "ps_8c6d2b50-0538-4469-b162-af7453a3d27f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[53], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 16, 13, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 10.12, id: "ps_f448e12d-168d-42b0-b8b8-2850051217a8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[68], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 17, 2, 42).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 332.81, id: "ps_106a10a0-4012-4af1-ad86-c1a7bd180681", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[26], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 17, 19, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 177.85, id: "ps_b29a5639-4b98-45fd-9088-97396a66b334", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[76], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 8, 17, 23, 11).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone, dispute: Disputes[50] },
        { currency: "$", amount: 94.74, id: "ps_4a612d4c-a078-4a78-92a7-5de1be8f5909", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[23], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 17, 15, 19).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 85.15, id: "ps_84d64c91-bea1-4a2d-8b5d-31e58ddabed8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[32], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 17, 3, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 87.11, id: "ps_8f67e73c-fe6c-4669-ac12-bf9f9d269309", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 18, 15, 53).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 256.71, id: "ps_f8222d4c-6593-4241-83a3-3603097432d1", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[52], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 18, 10, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 29.66, id: "ps_a42ff0a7-98e2-4576-8ea1-536d14e4ed2f", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[10], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 18, 21, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 36.67, id: "ps_5fa14c95-a063-42f4-bee5-e8fa104ec783", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[18], seller: Sellers[36], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 18, 20, 11).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 102.91, id: "ps_25c134c8-93cf-43c9-8434-92b9714e23bd", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[67], seller: Sellers[86], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 19, 3, 32).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 26.39, id: "ps_353143cd-500e-458c-a24b-4c0f1f7d73d1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[32], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 19, 14, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 220.88, id: "ps_e71757e7-22e5-432c-82ab-514b5152817b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[40], seller: Sellers[7], state: PAYIN_STATE.PENDING, date: new Date(2023, 8, 19, 14, 19).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 54.41, id: "ps_50c8c45d-e893-44e8-a83c-2ab5479bff07", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[43], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 19, 4, 59).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 421.04, id: "ps_2f0b71a1-4e47-42c6-b4b7-9c6d1f77646c", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[42], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 19, 6, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 73.39, id: "ps_583d54e5-0456-4825-8163-e220f941237e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[43], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 19, 7, 12).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 233.99, id: "ps_ef916de6-cdd7-4f2c-b315-638b84b5e7bf", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[34], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 19, 18, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 90.23, id: "ps_a2eb7602-1b10-4d67-a970-39236d613ab0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[73], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 19, 8, 26).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 11.53, id: "ps_9cdd5611-6c7e-4c16-87e5-b489ddd3b6d0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[64], seller: Sellers[77], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 20, 22, 10).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 15.28, id: "ps_1bb0adb7-96ac-4126-8d25-a8c99794f8c6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[52], seller: Sellers[62], state: PAYIN_STATE.PENDING, date: new Date(2023, 8, 20, 9, 39).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 82.24, id: "ps_a5ad929c-bff4-4598-87c2-d2b5c75221e6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[33], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 20, 11, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 119.27, id: "ps_3cffff42-8dc1-4a87-8220-b8581705318b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[63], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 20, 7, 26).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 16.25, id: "ps_6b518660-c64c-42cc-980d-b1d2ab579f1e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[82], seller: Sellers[77], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 20, 6, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 146.1, id: "ps_1c9f3c7a-d751-45cb-8763-5910f02e85ab", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[61], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 20, 7, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 40.06, id: "ps_0d292165-dd26-4567-ba99-9ee0e49b756d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[71], seller: Sellers[75], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 20, 0, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 41.01, id: "ps_c52cb596-1a8e-45cc-97d3-c4c8a49d5363", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[37], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 21, 21, 33).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 29.52, id: "ps_854269b4-e2ed-4a12-9f63-14d3053ca1e0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[10], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 21, 21, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 17.57, id: "ps_8a0b413a-1830-4b51-b69f-68773d5220bc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[33], seller: Sellers[78], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 21, 16, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 46.17, id: "ps_cdf482d2-6256-4f32-acf1-58ac45f75cbf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[40], seller: Sellers[19], state: PAYIN_STATE.PENDING, date: new Date(2023, 8, 21, 5, 46).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 29.27, id: "ps_e5215b57-fec3-4e40-a544-5577e7a8ded3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[81], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 21, 19, 8).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 94.99, id: "ps_76101eda-df1b-4b38-a281-824ee6131913", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[12], seller: Sellers[50], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 21, 10, 17).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 60.32, id: "ps_442b73a3-7d31-4a4a-8afe-1d829cd1104b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[60], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 22, 23, 21).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 96.79, id: "ps_0fb3d0a2-d672-4960-a1cf-bd99ff3da690", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[88], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 22, 16, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 61.43, id: "ps_65968c96-d714-4c67-afde-d455f194dee0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[97], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 22, 10, 38).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 454.91, id: "ps_4f2fdf40-3da0-49af-84a2-ce7668957a77", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[76], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 22, 23, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.66, id: "ps_78be81e8-3cac-4efb-9d1b-851bb0d7c81d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[77], seller: Sellers[7], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 22, 4, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 22.74, id: "ps_c072196d-e518-4fff-8919-dc910a8b8a04", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[66], seller: Sellers[1], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 22, 8, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 34.87, id: "ps_0be8af23-34ed-4d78-9694-b357feb9591c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[31], seller: Sellers[32], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 22, 5, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 154.96, id: "ps_72898710-bf4e-4ed3-9213-e59c8d8df734", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[87], seller: Sellers[9], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 8, 22, 13, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 304.36, id: "ps_bc6d022e-ea22-4eef-8217-7dc17bb57245", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[15], seller: Sellers[80], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 23, 5, 21).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 172.07, id: "ps_57ecae25-c4c7-47d2-a28b-c26be347776b", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[25], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 23, 11, 31).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 12.83, id: "ps_e28a49cf-4bdf-4fa4-a00b-7ce60b5f0c25", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[53], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 23, 8, 36).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consoleXbox },
        { currency: "$", amount: 69.99, id: "ps_45f86341-a048-4dc1-be3e-45007d467906", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[39], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 23, 21, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 21.47, id: "ps_f95a9061-46b9-4f88-8870-176fd650ad26", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[100], seller: Sellers[38], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 23, 15, 59).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 268.33, id: "ps_b32ab663-adda-4a24-a1f7-8c4e9a1ef36b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 23, 23, 31).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 273.32, id: "ps_2d6a8ca3-cd3c-4a15-8c1a-ad67bd47fd63", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[33], seller: Sellers[45], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 23, 18, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 12.96, id: "ps_0d0eb96f-606a-43f9-90a3-539dc7cf7be5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[48], seller: Sellers[51], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 23, 6, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 143.6, id: "ps_568a6642-e675-461d-9dc7-1b21e87d5e99", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[102], seller: Sellers[62], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 24, 20, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 65.76, id: "ps_a7b2779a-a4e1-48c3-81da-37026baa79c4", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[100], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 24, 0, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 19.16, id: "ps_dc974211-c3ab-4c90-91ba-e878ad6728b3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[102], seller: Sellers[60], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 25, 4, 27).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 408.35, id: "ps_e9421a7f-2954-4872-9b9c-0cfc22312e8a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 25, 2, 42).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabledAndroid },
        { currency: "$", amount: 111.43, id: "ps_f360419a-3470-480b-8be5-166b73f7ceb2", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[74], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 25, 15, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 146.03, id: "ps_33be316c-54cf-4f17-8ceb-dbf557ea07b3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[16], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 25, 8, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 71.32, id: "ps_6476018e-b8ec-4f07-9632-00ec771a34f3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 25, 9, 37).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabletIPad },
        { currency: "$", amount: 352.02, id: "ps_53f15966-860d-4474-bbd0-4cbd00c38a29", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[99], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 26, 2, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 239.75, id: "ps_fbe167d7-35fc-4ed9-b122-9f7a51b1f968", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[25], seller: Sellers[45], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 26, 10, 36).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consolePlaystation },
        { currency: "$", amount: 63.94, id: "ps_2c605a62-cdeb-461a-bb5b-5c354f7d19fe", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[44], seller: Sellers[6], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 26, 1, 33).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consolePlaystation },
        { currency: "$", amount: 77.08, id: "ps_5a56dcb1-b52a-4abb-a22f-ad1ea3636c11", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[68], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 26, 16, 17).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 65.08, id: "ps_9b6ccb29-01d1-4687-8507-f12cb3c0b792", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[72], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 26, 21, 12).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 217.79, id: "ps_99503dea-e064-4b96-90d2-9fc369960be7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[60], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 26, 12, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 74.98, id: "ps_67d7eb4b-b082-43fd-a0b5-47147be53b56", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[60], seller: Sellers[57], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 26, 3, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 189.91, id: "ps_77226161-f1c6-4ee3-8826-2abb3ce0bc23", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[77], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 26, 10, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 14.43, id: "ps_e98770fa-079d-4357-8d7c-5efa42345099", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[28], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 26, 12, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 87.73, id: "ps_95271b97-df8e-42e5-905a-882a22f77ce6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[65], seller: Sellers[77], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 8, 26, 12, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 79.64, id: "ps_1bfd788c-749f-46ba-8d28-70d233c75928", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[9], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 27, 23, 32).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopMacs },
        { currency: "$", amount: 233.3, id: "ps_a8f5fd3a-d6b7-4e49-872e-58574c3cbe35", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[10], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 27, 1, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 48.52, id: "ps_e0b561fb-a1e0-4768-8d31-16eddfe277a3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[32], seller: Sellers[22], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 27, 5, 52).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.93, id: "ps_4eaf6157-4be3-4212-bdfe-d5065b657e3b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[73], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 28, 21, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 63.63, id: "ps_1fc5901f-e3c0-42b0-ab48-3cd56069e473", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[8], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 28, 12, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 332.09, id: "ps_55120c89-c66f-4820-a8bb-895fab72fb9c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[66], state: PAYIN_STATE.INITIATED, date: new Date(2023, 8, 28, 20, 17).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileWindows },
        { currency: "$", amount: 482.29, id: "ps_b613620e-28e8-406f-86ec-545405bdcbd7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[103], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 28, 19, 33).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 66.12, id: "ps_01442ab2-34e9-4ccf-bdc4-7da08762ea40", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[68], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 28, 22, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 376.79, id: "ps_d8db5d41-1c9f-4e23-ada0-4834e497cdfc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[96], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 28, 10, 42).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 99.89, id: "ps_2c9afe72-24bf-4a1e-bb3d-70d3e794be3e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[102], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 28, 0, 16).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 262.76, id: "ps_25f8db8f-73a7-4511-bdfc-4554ef451419", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[45], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 29, 4, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 62.47, id: "ps_20a5b774-8d1d-42f2-b990-1623f4916209", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[15], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 29, 22, 43).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consolePlaystation },
        { currency: "$", amount: 17.32, id: "ps_f877ce2e-bf01-4625-bbb9-6b7f4853bd6a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[61], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 29, 0, 57).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consolePlaystation },
        { currency: "$", amount: 184.3, id: "ps_4fc60924-e676-4622-bc2a-05e552215434", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[103], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 29, 9, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 15.93, id: "ps_928e1a54-0e7a-4a3c-9592-7c83efdd2c7c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[49], seller: Sellers[76], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 8, 29, 4, 48).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone, dispute: Disputes[51] },
        { currency: "$", amount: 67.08, id: "ps_08001957-82de-4231-9ee9-54159ecea34b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[16], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 30, 2, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 62.44, id: "ps_0c355a25-8b65-4507-9705-357738021377", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[63], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 30, 10, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 3.59, id: "ps_6f5fa80e-f2d0-46a6-b746-1a283fd96893", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[46], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 30, 21, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 61.18, id: "ps_ecb2cfe2-6e83-4ab7-9b30-f2f8ed733611", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[45], seller: Sellers[44], state: PAYIN_STATE.PENDING, date: new Date(2023, 8, 30, 17, 12).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consolePlaystation },
        { currency: "$", amount: 93.57, id: "ps_79cfafc5-6eeb-4c32-8dcc-66b0892c7ccd", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[50], seller: Sellers[56], state: PAYIN_STATE.FAILED, date: new Date(2023, 8, 30, 3, 51).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 162.91, id: "ps_ce1519c5-533a-4122-97ff-367e6a8a5609", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[76], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 30, 6, 58).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 51.71, id: "ps_030117ae-e34e-49c7-94a2-03b329a350af", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[71], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 30, 23, 39).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 23.77, id: "ps_65da34a6-2af5-4141-a928-d5448a4c50a4", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[82], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 30, 19, 6).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.consoleXbox },
        { currency: "$", amount: 107.08, id: "ps_e9afbaae-8e22-4c69-881b-3ccc92533f6d", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[76], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 8, 30, 23, 36).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 82.45, id: "ps_5dc1607a-508f-4ded-b138-109fa32b220e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[16], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 1, 22, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 23.54, id: "ps_5c9be247-8ab7-422e-b4b6-69933191b7f2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[96], seller: Sellers[10], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 1, 1, 38).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileAndroid },
        { currency: "$", amount: 403.51, id: "ps_0bcde65f-d7d5-433d-bfb1-18c1c14e9151", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 1, 6, 26).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.mobileIPhone },
        { currency: "$", amount: 15.83, id: "ps_059e217a-9680-471a-828c-981864f08808", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[32], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 1, 20, 50).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.desktopWindows },
        { currency: "$", amount: 17.02, id: "ps_f723cadc-8368-40e2-810d-557a6c2f6886", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[97], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 1, 2, 52).getTime(), payout: PAYOUT_STATE.PENDING, device: Devices.tabledAndroid },
        { currency: "$", amount: 331.27, id: "ps_2f71b8f2-4d67-4eea-ab93-03dd6e3cb5b9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[45], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 1, 8, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 19.39, id: "ps_7c85e8bd-c32b-4328-976b-e1fe467669b1", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[73], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 1, 5, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 63.76, id: "ps_4b620b65-9896-4295-90c6-d9b9b47ac3dc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[20], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 1, 20, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 82.12, id: "ps_be38495d-a013-484c-9ffb-f9477c5d6f9a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[51], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 1, 14, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 488.76, id: "ps_17b5d0a9-db2e-4cc3-b4f9-f33de589ee2b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[46], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 1, 16, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 20.6, id: "ps_bd13f74d-9661-495c-827c-dd879833a432", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[51], seller: Sellers[69], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 9, 1, 1, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone, dispute: Disputes[52] },
        { currency: "$", amount: 62.08, id: "ps_c037f1ee-1b2c-4249-8850-7356685e1e4b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[92], seller: Sellers[68], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 9, 2, 5, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 50.83, id: "ps_cd048455-b64a-451f-8f77-cd893604edb9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 2, 22, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 87.38, id: "ps_0563a80a-c1dc-4e2c-9ab2-c9b260973b5d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[48], seller: Sellers[14], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 9, 2, 0, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[53] },
        { currency: "$", amount: 31.1, id: "ps_78ca549f-cc65-4a28-b7de-f376128d6500", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[78], seller: Sellers[2], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 2, 3, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 56.35, id: "ps_bfce7faf-8cf4-4f9e-a53e-0806a0e0440d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[57], state: PAYIN_STATE.PENDING, date: new Date(2023, 9, 3, 3, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 168.71, id: "ps_8c3153f2-b953-47fb-9e2c-6833b3ff22a0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[60], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 3, 5, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 53.02, id: "ps_a06822f9-9dbc-4eb8-aad5-3e492a6b42f5", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[33], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 4, 9, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 209.2, id: "ps_0e3bf121-6410-4265-9cda-56feb627479f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[59], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 4, 18, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 43.37, id: "ps_88d00fe6-4f7a-457f-a121-2cfc6ea60c88", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[45], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 4, 15, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 200.03, id: "ps_7633356c-e79a-47ba-89be-97363c9ba01d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[22], seller: Sellers[42], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 5, 15, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 15.11, id: "ps_37e3789c-c54c-4921-b0d8-86b322eb8d5b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[95], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 5, 1, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 333.77, id: "ps_884beb30-aa37-4372-8590-ef01a12c76e3", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[39], seller: Sellers[8], state: PAYIN_STATE.PENDING, date: new Date(2023, 9, 5, 17, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 255.29, id: "ps_cdfb7892-e918-4100-8ef4-26b15428e35e", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[93], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 5, 22, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 374.42, id: "ps_501b513b-b11a-4b6b-992d-484bd6f7acb0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[30], seller: Sellers[27], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 5, 23, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 17.03, id: "ps_2345aff6-0f4e-46b9-a51f-0600c487e3df", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[22], seller: Sellers[84], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 5, 3, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 66.4, id: "ps_411e37d3-5808-422c-9575-4eede37aa0d8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[45], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 5, 4, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 275.05, id: "ps_5df5b38e-15ce-4965-b9ce-375fa5407dd3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[71], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 5, 20, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 251.62, id: "ps_66b87229-e653-4adc-8e85-6566e2749ebb", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 5, 10, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 81.67, id: "ps_3499e9be-d8cf-4764-9447-cfac92449e33", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[26], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 6, 1, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 63.82, id: "ps_77a5f2be-1459-4d55-8098-3b140d47c72d", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[40], seller: Sellers[29], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 6, 6, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 108.29, id: "ps_0db63397-b9f5-42d6-908e-6cd22e6a38f8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[75], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 7, 8, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 13.58, id: "ps_c8f1d3c0-463a-42de-81ed-8d9c06e14c96", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[33], state: PAYIN_STATE.PENDING, date: new Date(2023, 9, 7, 20, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 231.43, id: "ps_6191a53d-a8d9-43c1-87b9-0c0e37a907de", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[69], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 7, 15, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 84.57, id: "ps_0bf10050-4113-4444-955b-40658bd1f08d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[22], seller: Sellers[60], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 7, 12, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 56.23, id: "ps_9faea656-eee5-42ab-affc-bae8915725a3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[52], seller: Sellers[15], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 7, 8, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 252.73, id: "ps_63e7e66a-466a-4846-b3c3-1220eccf5670", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[70], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 7, 9, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 314.98, id: "ps_6ccb9c05-cca6-434d-81c3-6af9b6061d2c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[69], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 7, 14, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 85.45, id: "ps_51047685-e5e6-4c2d-b88c-e18defaa061b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[49], seller: Sellers[13], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 7, 5, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 67.82, id: "ps_21d3966f-fa30-4245-99c2-9e75e0f1d80b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[70], seller: Sellers[5], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 8, 8, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 68.08, id: "ps_0120898c-1848-4182-a007-efb40da7fbf4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[22], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 8, 6, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 53.36, id: "ps_56bc86e6-e996-43b3-84f9-0e5e3a642346", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[52], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 8, 13, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 31.28, id: "ps_6c0a740d-cf3e-4b96-b5eb-7be7885ba2f8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[63], seller: Sellers[36], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 8, 9, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 42.37, id: "ps_176fe3a9-bf59-4f6c-8d5f-b635d7ca57b9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[57], seller: Sellers[42], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 8, 11, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 56.21, id: "ps_3f7602ad-bd60-46ee-ba67-049edf6d0663", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[90], seller: Sellers[40], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 9, 8, 21, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows, dispute: Disputes[54] },
        { currency: "$", amount: 58.7, id: "ps_65d7b6e4-2f7d-43ad-8e37-ac033b50e78b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[90], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 8, 15, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 91.72, id: "ps_4a88d847-7dd9-4d42-8580-e8a5b9fddbff", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[70], seller: Sellers[62], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 9, 11, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 98.4, id: "ps_b233d433-a1c4-4fe0-a973-2bf54d6a9ece", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[81], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 9, 1, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 206.44, id: "ps_91e8c304-82b9-4bf7-9756-b31ed395db47", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 10, 10, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 71.15, id: "ps_3a1b1f4c-d973-4946-a331-a337b7bb2e34", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[3], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 10, 7, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 58.77, id: "ps_8aba3a5c-6654-4d02-87ea-53ae0c88ef6a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[90], seller: Sellers[2], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 10, 17, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 12.18, id: "ps_804af727-d6f2-4c4d-bad5-941fd94a65ae", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[42], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 10, 19, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 62.48, id: "ps_8227c006-9f45-462a-97d0-352016888915", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[17], seller: Sellers[11], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 10, 14, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 50.49, id: "ps_07c5be45-d4ea-4229-986c-a7ef3281ff4a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[15], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 11, 7, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 49.94, id: "ps_9cfe61e3-7c19-4d63-87f4-43f476312dd2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 11, 0, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 350.16, id: "ps_937106be-51fc-45d9-8962-3f334da6e796", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[72], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 11, 7, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.52, id: "ps_133b03a6-e2a6-49c0-8121-4d7dbf4f5112", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[67], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 11, 1, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 92.95, id: "ps_c8f08bdf-a118-47a9-89e5-c4f811330011", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[26], seller: Sellers[29], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 11, 12, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 325.21, id: "ps_aca1c91c-46d9-48a2-bff6-e53a00fa00f1", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[37], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 11, 18, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 18.62, id: "ps_d2c0babe-f451-4832-9cd5-aac521244fb0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[85], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 12, 2, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 177.41, id: "ps_c4736a96-2356-4626-824d-2dc47f4aaf8a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[29], seller: Sellers[19], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 12, 22, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 291, id: "ps_26e1fb9f-b323-4d68-aa0b-9372ac6bcefa", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[23], seller: Sellers[67], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 9, 12, 5, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid, dispute: Disputes[55] },
        { currency: "$", amount: 46.91, id: "ps_15749316-a59b-405f-981b-f6d5a5690cd4", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[3], seller: Sellers[64], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 12, 9, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 384.83, id: "ps_be5ddcc6-01c4-4c99-991b-8ca98c2b6dc4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[10], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 12, 22, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 18.64, id: "ps_57e948e3-0e83-4a07-b389-8f7c8d396da8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[2], seller: Sellers[57], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 12, 22, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 86.46, id: "ps_7fa7e47a-eab8-49aa-b896-a11bb62be23e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[75], seller: Sellers[63], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 12, 3, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 52.25, id: "ps_c396a834-eb43-402c-9b7f-20133de833a0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 12, 8, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 313.29, id: "ps_d73b2cb7-00e5-4578-8f38-d3bc6d0e4fbe", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[17], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 12, 10, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 459.37, id: "ps_a3d5eece-a3e5-47aa-9e34-0eac339eaf76", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[100], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 12, 12, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 50.39, id: "ps_71f3d3d9-dfe1-4d5c-825a-e7c825079897", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[43], seller: Sellers[24], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 13, 22, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 113.41, id: "ps_8122a40f-f3a6-4b0c-8eb5-5972f7881601", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[7], seller: Sellers[77], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 9, 13, 5, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 58.11, id: "ps_ef3352c3-02f1-4400-a4d2-2492d9e0dc93", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[16], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 13, 18, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 178.52, id: "ps_88437224-d29c-4897-a1e8-9fb7b76c9c54", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[32], seller: Sellers[70], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 13, 22, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 18.05, id: "ps_e00d7d73-7761-4c3d-a67f-e04ef384e87f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[59], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 14, 0, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 181.42, id: "ps_2d959ff4-2267-4a69-a000-111e04cd862b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[28], seller: Sellers[73], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 14, 1, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 280.91, id: "ps_f417859d-3986-4fa2-8357-28c7c47b7e6f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[71], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 14, 6, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 10.39, id: "ps_2e1174b0-df0b-4253-ab25-c58678d7bd73", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[22], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 14, 15, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 43.15, id: "ps_080fa320-1afa-40b7-9068-1287601f119a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[19], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 14, 18, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 240.29, id: "ps_680da76e-6501-4832-a38e-58f073f19c79", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[97], seller: Sellers[17], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 15, 5, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 87.53, id: "ps_8715ce5f-fc11-4b3b-985d-7f9c13aa3ef6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[47], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 15, 3, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 91.22, id: "ps_043e4144-aa35-413b-b99d-3edf9fdb8f62", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[56], seller: Sellers[34], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 15, 4, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 23.76, id: "ps_8de16444-e383-4595-85c4-b85fa81178d1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[4], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 15, 20, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 381.25, id: "ps_45968dcc-f90c-4573-b29c-a42d5b3c6914", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[11], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 15, 1, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 233.79, id: "ps_2f706e49-df0e-4290-82d6-49ea361fd4bf", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[42], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 15, 1, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 135.35, id: "ps_55f61e74-04a4-4e3c-8960-5142f94a3ac4", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[43], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 16, 16, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 292.83, id: "ps_b523a320-2aaf-4995-bed6-12d5a458a75e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[101], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 16, 20, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 55.29, id: "ps_0459e46a-729a-451a-9ce5-3f40309564c8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[89], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 16, 17, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 31.19, id: "ps_c7ff408f-0a9c-4320-aa20-bea09461e07c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[7], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 16, 18, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 82.65, id: "ps_330b515e-5471-4329-86ec-cc120db1d4f3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[81], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 16, 4, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 135.38, id: "ps_ba2c53c4-b43f-4683-ae92-da7d73f3d847", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[82], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 16, 23, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 15.58, id: "ps_21cc67e3-953b-403a-8a9e-75e4f767458e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 16, 21, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 87.51, id: "ps_f3fa1db3-d6e1-4a13-82ea-af2b46a77ae7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[65], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 17, 11, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 468.61, id: "ps_e96f9169-a327-462b-9b9a-faa21547ceea", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[27], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 17, 12, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 100.78, id: "ps_c3aefa6b-2593-4ba4-85b1-9c1862a3b76b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[84], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 17, 20, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 23.17, id: "ps_483b461e-1046-4223-bf88-fdfd0557b920", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[17], seller: Sellers[66], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 17, 0, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.45, id: "ps_52b8a755-2bfc-4010-bb37-ac37bc2a618b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[28], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 17, 3, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 14.25, id: "ps_b45d22bc-1fec-483e-bdce-7e7bfb24b09e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[21], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 17, 4, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 182.23, id: "ps_943e56ec-033f-4a66-9632-3dcd65589d62", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[71], seller: Sellers[80], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 18, 3, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 487.98, id: "ps_1d0ac9ef-5b97-421a-a06a-1e1235972e0d", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[60], seller: Sellers[46], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 18, 12, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 344.14, id: "ps_a24e1070-a8ef-498e-9977-1420d6a75466", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 18, 18, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 463.56, id: "ps_4b76dfaf-c317-40d9-b75a-edf813c74d30", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[55], seller: Sellers[79], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 9, 18, 17, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 15.83, id: "ps_4ec62056-0588-40fd-8822-b8b8a114ac70", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[87], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 18, 19, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 59.08, id: "ps_9d260122-1a55-4841-9493-ed30c03d01d9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[60], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 18, 4, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 87.81, id: "ps_087e3095-76d8-4bbe-8f66-9c96162a4ac7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[26], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 19, 9, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 276.91, id: "ps_5935d7ff-0ad6-40a2-899a-efad4ce18a2e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[42], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 19, 14, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 336.51, id: "ps_8279dc5d-fd3d-4d76-911a-2858d91c0069", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[9], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 19, 11, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 65.05, id: "ps_cf8aff9d-77c0-435a-aff8-7e23f412c187", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[15], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 19, 4, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 64.99, id: "ps_547fcdfb-507a-49bc-8207-fcca4c210474", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[18], seller: Sellers[4], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 19, 21, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 49.85, id: "ps_7dc37127-5b56-48b8-b5f7-0cfb7a29e052", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[3], seller: Sellers[2], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 19, 11, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 358.24, id: "ps_586639d4-1fc2-4bcd-a55a-c1c60e3698cd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[34], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 19, 2, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 49.18, id: "ps_3832b7b0-834a-49c2-9be8-36b7f83b0b6c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[29], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 19, 9, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 216.78, id: "ps_8b3f9601-2433-445b-ab57-7ed4a6a858c5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[92], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 19, 12, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 16.22, id: "ps_f10846ad-b801-4a0a-8064-571c9e873265", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[55], seller: Sellers[74], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 9, 20, 18, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 99.11, id: "ps_74b6bd4e-7993-4c9c-a0c0-9639aaaf40e5", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[23], seller: Sellers[68], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 20, 6, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 63.45, id: "ps_93fc894e-6dc8-44d5-aa24-9e00cf28d2ac", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[84], seller: Sellers[61], state: PAYIN_STATE.PENDING, date: new Date(2023, 9, 20, 19, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 87.16, id: "ps_522dcd23-f1f4-44b6-9f82-686d76d4c43e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[55], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 20, 10, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 13.38, id: "ps_1a49e9b2-9b9e-4a09-8172-e06fcfc1ee3d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[79], seller: Sellers[4], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 20, 12, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 404.74, id: "ps_44ddf975-e554-4109-90e3-f0f1327d0245", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[23], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 21, 12, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 474.75, id: "ps_0396d396-c842-4d79-ab55-f475e2d93aab", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[48], seller: Sellers[22], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 9, 21, 19, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 158.36, id: "ps_be1c5a4a-be4c-44c3-84c1-e37afc650649", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 21, 10, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 39.66, id: "ps_10643643-016e-43a3-a689-49eab56bd37f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[43], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 21, 11, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 83.23, id: "ps_b6ad42f9-f22c-473c-8d89-2a5657eaae54", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[47], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 9, 22, 18, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 92.43, id: "ps_564852bb-2f02-45c5-bf13-e7f47b85e8f0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[9], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 9, 22, 7, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 82.96, id: "ps_d0be5cf2-f51f-407d-9b50-e9d534399968", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[10], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 22, 17, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 32.83, id: "ps_9f1a25ba-d2b0-4792-9274-e874f9baffbb", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[39], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 22, 12, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 306.96, id: "ps_055b3b10-f2f8-41ed-a42f-826acec3dccc", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[11], seller: Sellers[81], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 22, 21, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 200.08, id: "ps_cfc2bc5a-c651-4482-abe6-b1cc693eef77", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[75], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 22, 13, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 31.55, id: "ps_fa5c8e7b-8bb4-49f2-8324-d08e1a123f70", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[74], seller: Sellers[55], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 23, 2, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 50.36, id: "ps_8fb0961c-1602-4c27-96f9-92f58db8461c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 23, 19, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 97.36, id: "ps_0fcdb818-0fdf-44d3-8476-8c8201505610", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[98], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 24, 14, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 58.68, id: "ps_f154e606-3ee4-4722-93bb-62d29149165c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[32], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 24, 23, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 24.56, id: "ps_eeb586b6-a02e-42e7-8aa2-1dbd9e8dd126", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 24, 17, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 81.31, id: "ps_38691f2f-0b4d-48fe-8005-372398e9b971", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[4], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 24, 0, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 71.26, id: "ps_0465be53-cd08-43c9-bb29-902f8b400adb", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 25, 14, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 223.65, id: "ps_a0a7032b-440d-41c9-9978-8d617211afb4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 25, 11, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 272.76, id: "ps_edcbc597-7fe4-431f-82b3-715436fd9f22", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[36], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 25, 8, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 12.61, id: "ps_7902804b-0801-4b91-97aa-e9acffb52afc", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[2], seller: Sellers[14], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 25, 6, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 46.01, id: "ps_7f2599e6-91be-46dd-800d-8c5d3d228eba", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[20], seller: Sellers[13], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 25, 22, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 49.94, id: "ps_31fa4a68-df38-4afa-8e57-ff9ecb928382", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[33], seller: Sellers[88], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 9, 25, 14, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consolePlaystation },
        { currency: "$", amount: 279.85, id: "ps_4bd3b642-7c57-4983-b6c8-c2221d378d93", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[53], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 25, 18, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 52.09, id: "ps_eb6c9b12-cd58-4e74-bc69-fcbec3e9f371", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[102], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 25, 18, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 424.08, id: "ps_3fb4fb71-e688-490a-9aec-940fcc421a2a", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 26, 4, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 394.46, id: "ps_2f058b6f-3faf-47ff-a216-606ee6e950ab", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[26], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 26, 23, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 40.61, id: "ps_5271cfbd-932d-4a98-afa4-7f5f5875b5a1", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[12], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 26, 16, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 30.63, id: "ps_b763b3aa-2031-44c4-a3b5-4b2d58dc7474", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[34], seller: Sellers[80], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 26, 1, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 35.27, id: "ps_90b2b6d1-8ec2-42ed-a132-7652e83c7881", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[72], seller: Sellers[13], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 9, 27, 18, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[56] },
        { currency: "$", amount: 13.73, id: "ps_16a1a505-d164-448f-af51-ab26869f3f64", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[14], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 9, 27, 1, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone, dispute: Disputes[57] },
        { currency: "$", amount: 10.08, id: "ps_f4fddf92-e8e3-4962-93ce-e1d3bc5ec2e7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[19], seller: Sellers[63], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 27, 14, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 113.18, id: "ps_779c4436-b2bb-4b98-8a2a-dc38660f2851", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[102], seller: Sellers[77], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 27, 20, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 32.78, id: "ps_aa1b93d7-0c4f-4504-a346-901e6bd710b3", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 28, 18, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 16.03, id: "ps_eb91b54b-f768-4f59-b6ee-1a22866644a1", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[70], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 28, 2, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 70.46, id: "ps_a510cc0d-8058-4bae-9933-07be45392e79", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[13], seller: Sellers[88], state: PAYIN_STATE.INITIATED, date: new Date(2023, 9, 28, 17, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 40.1, id: "ps_0be627e2-cdc7-420b-ac61-9921d78c81c0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[59], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 28, 1, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 21.03, id: "ps_19ef8ce6-b2cd-406d-83d1-632a88a7b1c7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[48], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 28, 21, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 42.37, id: "ps_095c2262-cb37-4009-8e94-404c5ecc2818", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[24], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 28, 16, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 123.02, id: "ps_a66ad97b-3cb8-486c-9d1f-21160eb31238", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[7], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 28, 5, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 294.16, id: "ps_0c9b99eb-d49a-4061-b2ec-b130268d9f8f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[35], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 29, 0, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 39.25, id: "ps_94cdcd19-761e-45b9-940a-2c69c5004a71", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[79], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 29, 5, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 153.2, id: "ps_07ed79f0-69b2-4a60-9c22-d5e9789e3e32", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[52], seller: Sellers[47], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 29, 15, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 106.24, id: "ps_4ce08f91-704e-4268-806f-ef7948bbd4b3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[40], seller: Sellers[21], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 29, 17, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 40.93, id: "ps_4f975271-74b4-472d-8d4f-5071a28d3064", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[60], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 29, 16, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 300.62, id: "ps_e1837403-abe8-4529-b128-3ef6a8be3cc5", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[100], seller: Sellers[78], state: PAYIN_STATE.PENDING, date: new Date(2023, 9, 30, 23, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 163.75, id: "ps_bc3d5cf8-300a-414c-9bb2-0c2b47bf7877", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[22], seller: Sellers[80], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 30, 11, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 180.28, id: "ps_4d53a2e1-57e9-423d-881c-ea2f2e0855e4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[78], seller: Sellers[86], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 30, 8, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 143.99, id: "ps_e80e972a-7819-438b-b59e-6ec0f9cb9f3f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[86], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 30, 6, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 61.1, id: "ps_04f1641a-9db9-4a36-a106-11a5e7258457", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[74], state: PAYIN_STATE.FAILED, date: new Date(2023, 9, 30, 22, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 13.76, id: "ps_3fac78b2-8fa7-48af-8d98-96498b9e5c51", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[20], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 9, 30, 21, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 56.88, id: "ps_a2bf8aec-3490-4d7f-909e-5e87a28c2cc3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 1, 4, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 177.5, id: "ps_7e1b9cdc-a846-4b97-8d6c-3da9acb52751", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[2], seller: Sellers[26], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 1, 11, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 152.85, id: "ps_171a36de-f272-4aef-b2d1-b903e5305011", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[57], seller: Sellers[2], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 1, 18, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.1, id: "ps_98272efb-19e6-429a-9352-6284e18a0e5d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 1, 18, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 291.02, id: "ps_7948ea8e-494b-47ff-b6ed-a60d4efe15fd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[29], seller: Sellers[36], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 1, 7, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 160.32, id: "ps_5613a814-d7af-4bba-91b5-302fd137adb8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[24], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 1, 6, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 184.87, id: "ps_34e984e6-4a72-4fd2-ae65-fee7275809f0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[7], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 1, 6, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 284.99, id: "ps_ba3528d5-fdee-4642-9c1d-c3ceeea1d426", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[101], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 1, 12, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 76.37, id: "ps_af4ca11f-f832-4ec8-bba9-cea17dfa631a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[55], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 1, 9, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 98.28, id: "ps_24f031a1-8db4-4b48-b7f6-841d4f038917", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[48], seller: Sellers[53], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 2, 3, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 96.07, id: "ps_5d6a3362-6d34-430b-b59e-528970575e3a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[77], seller: Sellers[12], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 2, 11, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 11.08, id: "ps_f8ae8509-2437-43f8-8a35-d38a6b6e0143", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[14], seller: Sellers[85], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 2, 20, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 407.55, id: "ps_9e9ae92e-1826-44bb-8a09-e858f4bba733", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 2, 17, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 54.72, id: "ps_67499dda-5276-4251-900d-748acf247846", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[63], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 3, 3, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 57.09, id: "ps_0c4b06ff-3a3a-459d-b803-aa0da47d5bbf", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[63], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 3, 9, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 11.52, id: "ps_9fa01e42-9017-4b50-8b91-00e7bb808b5b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[64], seller: Sellers[3], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 3, 10, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 130.35, id: "ps_f37f9754-d920-48d0-8ff2-2ba1ee4a65e4", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[20], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 3, 8, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 23.18, id: "ps_d037f138-4f33-4ed3-92f8-ce616e2c07fc", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[53], seller: Sellers[24], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 10, 4, 2, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[58] },
        { currency: "$", amount: 53.66, id: "ps_8894a2c3-5600-4a79-9a96-d2db80d3b35c", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[94], seller: Sellers[41], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 4, 9, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 44.76, id: "ps_1c6590c5-0651-45f9-869c-a3c4312b2b5c", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[65], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 4, 21, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 460.14, id: "ps_99c9d944-a911-440d-9a6b-23cbf68ac588", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[104], seller: Sellers[51], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 4, 16, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 127.42, id: "ps_895f3418-ea24-442e-a5c7-864093d6b145", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[61], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 4, 4, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 75.52, id: "ps_15a4fd2c-08b2-4dc4-9cd3-4a946d13e828", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[4], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 10, 4, 19, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad, dispute: Disputes[59] },
        { currency: "$", amount: 43.13, id: "ps_fcc698b2-a3d1-4461-9def-eaae430389ac", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[64], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 4, 9, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 58.09, id: "ps_63e73004-9c5a-4a4c-bee9-a4951ccd6af0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[92], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 4, 17, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 258.12, id: "ps_89fecdf4-36d1-43e5-9efa-5288ef8a5093", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[6], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 4, 20, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 48.4, id: "ps_dc759080-dc9d-440b-889a-f6ee9f688ffa", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[11], seller: Sellers[17], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 4, 6, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 82.83, id: "ps_9e3df2d6-20d3-425e-93b7-85d54104af95", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[38], seller: Sellers[17], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 4, 3, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 27.52, id: "ps_9cbbf9bb-2831-43a5-8061-d4940698bc40", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[10], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 5, 0, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 32.44, id: "ps_45806225-795d-4052-a99b-c516d1104713", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[38], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 5, 4, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 36.77, id: "ps_dfaa2d7f-ade3-4e60-81a0-509eaf0b90c9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[63], seller: Sellers[16], state: PAYIN_STATE.PENDING, date: new Date(2023, 10, 5, 0, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 184.29, id: "ps_38c3e1b1-8fda-4cfe-bac6-153e25c0c98f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[67], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 5, 13, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 162.58, id: "ps_330065bd-0f40-4614-b255-43b229647e69", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[85], seller: Sellers[86], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 5, 16, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 452.88, id: "ps_dc59b1d2-c7e1-419e-b940-749e7e2e14ce", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[50], seller: Sellers[16], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 5, 3, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 79.53, id: "ps_801cf62b-5a61-49ac-9691-4f7f54d465d8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[58], seller: Sellers[21], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 5, 12, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 102.84, id: "ps_610356b2-7bbb-4f43-a138-c707b19d5b3f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[2], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 6, 14, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 138.49, id: "ps_e8bb3392-b7da-4a97-8d82-bde8b121669e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[91], seller: Sellers[42], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 6, 2, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 69.93, id: "ps_338d1e72-a151-471f-865c-ba921adc83b2", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[26], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 6, 15, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 121.91, id: "ps_3b2f3339-d577-4f4b-8a27-56e7afb7ec06", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[13], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 6, 3, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 26.41, id: "ps_488d534b-aa91-47af-9c20-74dbbcf4eb78", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[49], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 6, 6, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 15.07, id: "ps_36797ac6-2256-46e9-9c41-0dd19b370008", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[64], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 6, 3, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 45.85, id: "ps_264ba91d-b9eb-4b70-b6c5-78ccb63fc480", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[16], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 6, 23, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 13.12, id: "ps_17f61c77-cbc2-45e6-b3a0-7fb6c89a969b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[90], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 7, 14, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 16.32, id: "ps_96c21052-e585-4bcd-93b2-332560b1ad14", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[44], seller: Sellers[45], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 7, 17, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 15.15, id: "ps_b3e37708-ca00-4e7e-9ad0-a6a312ba13ca", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[44], seller: Sellers[82], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 10, 7, 15, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 15.43, id: "ps_b944f01e-7cf0-49d4-a728-e4d14d1b8c49", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[12], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 7, 2, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 72.95, id: "ps_b4b5dc66-a7d9-4a61-90df-3db1aaa0fa29", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[52], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 7, 3, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 91.91, id: "ps_33a292b2-36c8-41ad-847c-e8d6890b199b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[38], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 7, 3, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 198.09, id: "ps_14995451-8393-4b0d-80db-d1d037dc5819", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[13], seller: Sellers[3], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 10, 7, 6, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 23.03, id: "ps_ff9cce25-1ac5-454c-a702-e46a227e3563", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[49], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 8, 3, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 82.43, id: "ps_beff9547-aa1f-4604-9570-09cd44ee2aba", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[2], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 8, 0, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 57.37, id: "ps_6a88e3c2-5308-47be-8029-e455e69d79ae", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[53], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 8, 4, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 409.64, id: "ps_264ada22-91ee-4674-864f-ab5c77c5eed2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[76], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 9, 0, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 300.56, id: "ps_497c838b-34bd-4e82-ad20-44250b7b9e30", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[38], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 9, 15, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 10.27, id: "ps_91c31de3-7847-4694-bab6-97c323b95163", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[59], seller: Sellers[56], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 9, 2, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 12.04, id: "ps_83cb089c-7c0b-40ab-9d51-de701d40d667", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[91], seller: Sellers[76], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 9, 12, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 156.83, id: "ps_d64280fb-0a6e-40f5-ac32-dfba54236b77", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[26], seller: Sellers[5], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 9, 0, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 19.38, id: "ps_cbef649c-dc24-4bde-97d9-48cb3394fbf9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[5], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 9, 0, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 80.86, id: "ps_c4183a25-a80d-4d5b-a1c9-bb8e5a3317d4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[95], seller: Sellers[58], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 10, 1, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 49.5, id: "ps_dfee98c0-1936-4bbe-a7b9-213c5837cb6b", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[39], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 10, 14, 23).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 17.78, id: "ps_baf1232d-8983-4261-baaf-0e85e6b622d1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[26], seller: Sellers[44], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 10, 7, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 33.84, id: "ps_052a1a61-7fda-44f2-944d-862ca474e330", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[69], seller: Sellers[17], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 10, 21, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 97.93, id: "ps_b440e167-7458-463d-9388-ba542c159e9a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[91], seller: Sellers[9], state: PAYIN_STATE.PENDING, date: new Date(2023, 10, 10, 1, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 69.98, id: "ps_d96ef4de-42d2-49e2-a9f1-a5019cf33bab", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[102], seller: Sellers[53], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 11, 15, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 401.59, id: "ps_1a3b1150-e1e8-4e8c-9de5-9be24d8d4b11", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[85], seller: Sellers[5], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 11, 17, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 75.05, id: "ps_22d16265-7adc-46c3-8b4d-4dd8bb67b2cd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[94], seller: Sellers[37], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 12, 2, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 322.43, id: "ps_16b1fc56-0ac7-458d-a393-182dfe0c337c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[21], seller: Sellers[25], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 12, 23, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 45.06, id: "ps_c5c030a7-d38d-476b-8d81-a01a3d9ea0e4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 12, 13, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 100.69, id: "ps_2710ec75-ef4b-4f6e-ab8c-1676470e7406", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[20], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 12, 7, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 73.19, id: "ps_3a462693-1d2e-4bc9-9fc1-003a3fb756fb", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[30], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 12, 13, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 43.17, id: "ps_00ca993a-1615-408c-b289-ea494213e267", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[59], seller: Sellers[39], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 13, 6, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 101.94, id: "ps_ab56982b-02f0-4de6-870a-8ba3a22d04ea", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[26], seller: Sellers[13], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 13, 16, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 14.02, id: "ps_7b8a40eb-eafb-4070-b127-e3c713b9d015", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[102], seller: Sellers[78], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 13, 16, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 11.11, id: "ps_71613375-0b49-4aae-a45f-06b3c12e7265", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[6], seller: Sellers[87], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 13, 0, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 76.58, id: "ps_b2af00fa-7a1a-42f3-a31d-0cba9d1ba6ea", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[24], seller: Sellers[32], state: PAYIN_STATE.PENDING, date: new Date(2023, 10, 13, 9, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 82.59, id: "ps_ecdcc9cb-4bac-4dae-9c57-c904b972b9e6", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[59], seller: Sellers[60], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 13, 23, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 54.78, id: "ps_9b7004bb-9d2a-4317-a3d4-130b15843eec", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[82], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 14, 9, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 288.35, id: "ps_5abd3a7f-a591-4e22-9208-0a0838ddf6e9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[51], seller: Sellers[44], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 10, 14, 22, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows, dispute: Disputes[60] },
        { currency: "$", amount: 368.57, id: "ps_a548d3d0-9fec-4252-b6bc-4790a59e9f31", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[74], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 14, 7, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 12.97, id: "ps_7f39664d-51c5-4a2d-a635-bbba8d43cb4c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[95], seller: Sellers[32], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 14, 15, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 169.56, id: "ps_1e8c8ccd-3ca3-41a2-ac0f-41f18a27e843", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[5], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 14, 20, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 355.63, id: "ps_b1c23ac1-d6dc-4c28-8153-b0be6a24e35d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[55], seller: Sellers[86], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 14, 19, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 80.55, id: "ps_5bd2214d-bf7d-4675-8aaa-5e8e5870c88b", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[18], seller: Sellers[25], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 14, 12, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 56.25, id: "ps_8cf70c06-8b38-4ed7-8093-d362da84a4cd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[74], state: PAYIN_STATE.PENDING, date: new Date(2023, 10, 14, 5, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 97.39, id: "ps_f16a4a97-5cf0-4e5d-bf86-e999e64d182d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[37], seller: Sellers[20], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 15, 6, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 32.45, id: "ps_f76fddac-1198-4392-a85b-6b63a36641a7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[21], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 15, 3, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 295.58, id: "ps_7481a9cb-6481-4d06-8a34-f4e58ff2520f", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[75], seller: Sellers[20], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 15, 1, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 413.16, id: "ps_b8e72b7a-3e76-42fe-9d0a-5f4c07a24722", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[69], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 15, 10, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 361.83, id: "ps_ab3736f0-4bfe-429a-95a1-9f6ad63b1683", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[21], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 15, 7, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 121.65, id: "ps_c3810c54-ec94-483d-919e-32ba5ba63e59", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[82], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 15, 23, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 46.98, id: "ps_d9d706ce-75e8-4916-827c-cc1726872719", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[17], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 15, 19, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 492.1, id: "ps_81fdb721-455d-499c-bb8f-e65373c6d993", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[87], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 16, 16, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 341.66, id: "ps_00d09b73-d736-4036-b414-0db459ae27b2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[12], seller: Sellers[70], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 16, 10, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 94.01, id: "ps_640dfea0-1a7b-44ac-a18a-0346b5a669e8", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[74], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 16, 8, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 480.66, id: "ps_37b94844-1edf-4b0b-b05d-a24faafaae4b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[52], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 16, 10, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 79.76, id: "ps_14b1c67b-c310-4337-8d98-733995b0f481", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[1], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 16, 12, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 67.5, id: "ps_62d89577-61d8-427f-a735-963708a93089", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[30], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 17, 15, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 81.7, id: "ps_08b11274-e80a-4410-a03b-91c08f0d2c1b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[26], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 17, 5, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 132.29, id: "ps_2e15deb6-929d-4ad2-9c46-107c787116e0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[85], seller: Sellers[87], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 18, 22, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 396.67, id: "ps_47294447-0acd-44da-a79f-e25e0af1fecf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[22], seller: Sellers[15], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 10, 18, 19, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 280.59, id: "ps_d14c94c5-75cc-43c0-960c-1418b9b2c718", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[5], seller: Sellers[18], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 19, 8, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 72.81, id: "ps_6f31c2bd-b73d-48ce-80b0-0d330e722be6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 19, 15, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 69.12, id: "ps_d11ca9aa-6408-4ec9-aec8-3bba535e34c1", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[34], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 19, 6, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 67.98, id: "ps_df403184-9094-400c-beb9-8600fb71aa89", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[31], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 19, 1, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 17.74, id: "ps_3092d5dd-f696-4a39-92aa-e52a24d494aa", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[46], seller: Sellers[67], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 10, 19, 12, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 145.04, id: "ps_503264d2-7853-4beb-81a7-86244498ccf1", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[93], seller: Sellers[53], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 20, 20, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 192.1, id: "ps_43174e71-31be-4976-8ec2-b8675b672b56", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[22], seller: Sellers[14], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 20, 7, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 200.37, id: "ps_e7a537bc-3f44-4226-8550-29a4b7c5d6fa", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[37], seller: Sellers[4], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 20, 7, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 16.55, id: "ps_b138b2f5-a7bf-4a40-ba20-658c396202c4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[31], seller: Sellers[61], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 20, 20, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 73.77, id: "ps_cc7915fe-94c5-471d-89b4-a6ed587e9d79", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[88], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 20, 14, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 19.83, id: "ps_132d027f-4caf-480b-8051-8cd4c6eca5ee", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[96], seller: Sellers[57], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 20, 21, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 145.51, id: "ps_737f6e92-e14d-422c-9005-521bab73c14e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[26], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 21, 8, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 15.87, id: "ps_806abecb-fcca-433e-8d8b-1099f8b79784", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[13], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 21, 5, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 85.05, id: "ps_998b969c-95d2-4c00-9cb0-c1f8d14c7f14", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[68], seller: Sellers[64], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 21, 12, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 274.14, id: "ps_f6b06993-2223-4977-91bd-d1aaa1e43052", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[76], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 21, 19, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 49.23, id: "ps_76e29ac8-4665-4e90-a768-2466fc9f07ff", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[49], seller: Sellers[83], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 21, 7, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 13.37, id: "ps_8a42c3d5-83df-41e9-87f0-a043cf9c2771", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[52], seller: Sellers[31], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 21, 17, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 254.4, id: "ps_542a7e42-271a-4b0f-b6df-176bc7bb9f46", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[30], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 22, 21, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 23.75, id: "ps_14600411-0f02-41da-b3e4-cb3c3bfd61ba", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[29], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 22, 3, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 48.24, id: "ps_d82d535c-b2eb-4171-8130-e1a86fcbc77c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[44], seller: Sellers[8], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 22, 3, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 51.87, id: "ps_127acf67-bd02-45c6-8b8f-3bf6234c7ee4", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[27], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 22, 8, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 44.1, id: "ps_844267b9-7fe6-4194-adfd-9356e2563f23", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[41], seller: Sellers[41], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 22, 21, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 53.4, id: "ps_2be5c10a-9f4a-4eee-ad86-e3caf7d8035c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[91], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 22, 7, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 153.75, id: "ps_4201675a-d282-40e4-b17c-d36347b8c90c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[97], seller: Sellers[67], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 22, 1, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 90.02, id: "ps_ce710e50-7e30-4c59-b458-842616ea97b3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[8], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 23, 16, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 276.9, id: "ps_6945694b-44e2-44c3-9a1f-0e0938007654", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[35], seller: Sellers[40], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 23, 22, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 303.66, id: "ps_564df207-f471-4781-b3f9-16c9cecfd97b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[45], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 23, 19, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 38.3, id: "ps_dbeceec6-2c01-4b55-85c1-2765edcb9c00", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[15], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 23, 6, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 42.04, id: "ps_699565f3-67f7-40db-82fd-b2615ef193d3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[26], seller: Sellers[52], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 24, 16, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 65.87, id: "ps_24ecc3c7-77db-4212-98cb-ecd39efd4712", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[42], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 25, 16, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 112.41, id: "ps_e9ae86ea-4824-42e5-9241-038b659d795c", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[77], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 25, 7, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 95.34, id: "ps_d4fed2bc-b965-497f-9da2-2a3ec83ee13e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[82], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 26, 6, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 182.96, id: "ps_e23196aa-b78a-49e0-9ccd-3171c78e3d25", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[46], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 26, 8, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.67, id: "ps_fc668f56-f5d1-4832-9023-a47c7ac4f8df", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[70], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 26, 10, 34).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.5, id: "ps_375aba66-4b50-4b75-bee0-f666ed0ba005", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 26, 1, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 32.62, id: "ps_8da9ce2f-cd3a-4554-9193-d8288a1f3afa", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 26, 22, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 54.92, id: "ps_91030d0f-b751-42b3-9bd7-398b70a43af9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[23], seller: Sellers[83], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 26, 17, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 33.76, id: "ps_f8352ed3-ad02-4442-8f32-0db7258062be", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[85], seller: Sellers[13], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 27, 23, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 81.26, id: "ps_8775c575-c4f2-4f50-a79f-67ef0848abad", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[95], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 27, 5, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 27.24, id: "ps_09cfb1cd-2888-4745-a9ad-28ddf01340b3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[94], seller: Sellers[2], state: PAYIN_STATE.PENDING, date: new Date(2023, 10, 27, 3, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 473.99, id: "ps_2c69f2c7-2f63-42d7-b3c2-125e7d3f1c62", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[4], seller: Sellers[40], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 10, 27, 14, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 27.43, id: "ps_8bfa2478-befc-42a9-891d-97915a111e59", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[87], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 27, 22, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 375.18, id: "ps_348d28cd-2d05-44fa-8cbf-2442de29a05b", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[69], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 27, 5, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 88.15, id: "ps_4a432926-d083-4e34-8587-457e6c64982b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[49], seller: Sellers[9], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 10, 27, 1, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 44.82, id: "ps_30a3912e-0357-476c-b602-fd49aa2b6bcf", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[39], seller: Sellers[39], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 27, 9, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 49.29, id: "ps_f3764813-5eef-423e-8c93-8cd5cbc104ae", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[95], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 28, 4, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 141.08, id: "ps_494d8bdf-34e4-4d86-8929-202f9d3a99cb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[25], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 28, 18, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 76.19, id: "ps_11ee800a-d5ed-45d5-a63d-003ce7fc448c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[100], seller: Sellers[4], state: PAYIN_STATE.INITIATED, date: new Date(2023, 10, 28, 12, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 12.85, id: "ps_03231417-cca9-4d76-bfd7-097f33479de2", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[12], seller: Sellers[64], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 28, 2, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 42.34, id: "ps_3c01b31d-dbd8-4044-bc9d-91512fdfff64", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 28, 17, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 181.95, id: "ps_ad3ad4d3-e91d-41be-89dc-a10d9f7804f9", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[93], seller: Sellers[73], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 29, 9, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 58.59, id: "ps_3ddad15a-bbda-4f5d-afd5-936f41a4802a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[48], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 29, 6, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 55.22, id: "ps_79dfe97c-d580-4a1f-b1c3-3414e33c16a0", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[17], seller: Sellers[32], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 29, 14, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 47.71, id: "ps_73f910a1-55fe-497a-bee7-de2f2668d0c9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[51], seller: Sellers[77], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 29, 3, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 114.57, id: "ps_9cb4ba48-96fd-4ec1-8dd2-88e76c41846b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[57], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 29, 19, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 11.77, id: "ps_733dc32e-55af-4534-b711-875ba060f0b8", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[34], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 10, 29, 17, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 26.51, id: "ps_4e35d864-2d38-4bc4-a55a-c8290a6bd57c", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[81], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 29, 13, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 12.05, id: "ps_d5a6c5ae-10ef-4547-8fa2-2ea10f2e94a0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[28], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 30, 0, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 423.22, id: "ps_f2b28123-c6e9-47f9-ad3e-5e74c6e4b306", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[48], seller: Sellers[9], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 10, 30, 13, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 42.01, id: "ps_b7fb1f9a-acbf-471a-90c9-51fbb00b7bfd", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[1], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 30, 1, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 88.08, id: "ps_f902594d-4509-4be3-8272-045ea478e5a9", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[81], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 30, 20, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 35.55, id: "ps_fba3c454-48ab-4c8d-8520-eaed8da5fcf7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[29], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 30, 21, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 14.51, id: "ps_f6227ebe-8527-4f64-9907-2904ed8f2d0e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[31], seller: Sellers[71], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 10, 30, 2, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid, dispute: Disputes[61] },
        { currency: "$", amount: 78.74, id: "ps_cc8dff4d-2e54-4cb3-8172-034af19535f6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[29], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 30, 17, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 71.86, id: "ps_6bdc4aea-3082-46db-bea9-9554ce394b8b", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[90], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 10, 30, 1, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.18, id: "ps_d6fee546-2a86-4590-8c57-ecd3cdd6538e", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[48], seller: Sellers[86], state: PAYIN_STATE.PENDING, date: new Date(2023, 11, 1, 23, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 25.22, id: "ps_7f89b195-3edb-47b9-8b62-62603000f6db", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[55], seller: Sellers[26], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 1, 4, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 184.88, id: "ps_5552bcb2-6536-452c-ad08-ca8c890bea9a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[54], seller: Sellers[42], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 11, 1, 17, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid, dispute: Disputes[62] },
        { currency: "$", amount: 14.95, id: "ps_7cdf7a83-a358-446e-8a21-399849732701", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[40], seller: Sellers[16], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 1, 20, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 61.77, id: "ps_d83158b5-3a99-4a9a-8481-763f060c0ac6", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[87], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 1, 16, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 57.64, id: "ps_edaff13e-d576-45de-9184-a84de3b67861", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[80], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 1, 10, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 229.28, id: "ps_0e890f62-ac6e-462f-96b2-cf09b144a4d4", provider: PSPs[0], method: PaymentMethods[2], buyer: Buyers[62], seller: Sellers[66], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 11, 1, 22, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox, dispute: Disputes[63] },
        { currency: "$", amount: 310.83, id: "ps_0c47efa4-5c67-4423-93be-d4ae1d45dddd", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[82], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 1, 9, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 310.38, id: "ps_7a23557a-83cb-49a0-a9fe-805d545ead68", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[50], seller: Sellers[79], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 1, 0, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 87.72, id: "ps_85df8338-cdaf-4fec-aa08-acebc0f9b24d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[50], seller: Sellers[26], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 2, 20, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 70.74, id: "ps_1666eda6-9e6b-478d-ab11-878c4b190c13", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[38], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 11, 2, 4, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv, dispute: Disputes[64] },
        { currency: "$", amount: 18.72, id: "ps_7b89a1d7-1c92-4c83-97e6-dd371e563ca8", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[62], seller: Sellers[83], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 3, 15, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 189.76, id: "ps_cfd7d33c-ff8a-476e-a560-a0e3541cf87d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[41], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 3, 1, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 87.01, id: "ps_f76a4ca4-e046-4d34-9f74-61ec697d3698", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[99], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 3, 11, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.44, id: "ps_321f28b6-be27-4d59-8d56-5bd8a3af16c3", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[87], seller: Sellers[56], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 3, 5, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 36.37, id: "ps_2b6e9e6b-15a9-4516-b9dc-5599b6145334", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[29], seller: Sellers[19], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 4, 2, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 58.17, id: "ps_67fcc64b-cb0f-488a-9804-bb18e7dd5959", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[43], seller: Sellers[83], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 4, 23, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 11.88, id: "ps_be01f63a-9442-4ee2-94ec-960bfb786752", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[40], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 4, 20, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 84.84, id: "ps_9785edec-d8ea-42a5-a473-f55c0b8a5751", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[53], seller: Sellers[45], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 4, 8, 31).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.36, id: "ps_1a25b2c9-78ae-446d-a377-842b83e14c56", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[5], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 5, 11, 15).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileWindows },
        { currency: "$", amount: 84.99, id: "ps_38878fbe-fee3-49ce-a5c5-afff6340df2a", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[48], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 5, 5, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 80.88, id: "ps_a6dcdfe7-0383-4c09-a568-ae5f48ebdab6", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[57], seller: Sellers[69], state: PAYIN_STATE.INITIATED, date: new Date(2023, 11, 5, 9, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 18.16, id: "ps_df00d7f9-07cb-4604-b1eb-876be5775f4e", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[57], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 5, 23, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 22.89, id: "ps_16fea6e4-4a18-446e-8ba4-8519f47ffe50", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[24], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 5, 7, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabledAndroid },
        { currency: "$", amount: 161.58, id: "ps_2c5ff975-ec27-4230-bd5e-e923fef263be", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[96], seller: Sellers[39], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 5, 0, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 187.67, id: "ps_b9616b17-8afc-4db1-92f4-d4eebdcf060d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[47], seller: Sellers[26], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 6, 20, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 209.25, id: "ps_2c7f6e35-10ae-4ed4-b9a6-2667c3589c49", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[31], seller: Sellers[36], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 6, 10, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 488.3, id: "ps_bae8375b-5252-4d96-89af-ae9ce0bfa0ad", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[53], seller: Sellers[30], state: PAYIN_STATE.INITIATED, date: new Date(2023, 11, 6, 16, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 87.26, id: "ps_3d3f1cd3-5f26-4212-9fa0-83dd80b7d11a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[53], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 6, 4, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 97.42, id: "ps_05818d4c-4ecd-4b96-9f13-343d8d387616", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[47], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 6, 15, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv },
        { currency: "$", amount: 3.99, id: "ps_17521518-342f-4b4e-95e8-82584c6b2e41", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[102], seller: Sellers[31], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 6, 14, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 109.2, id: "ps_87c7307e-9642-4de2-904d-162124a777bc", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[12], seller: Sellers[19], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 6, 8, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 42.37, id: "ps_97b36986-0b96-4b57-8456-7f9831432905", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[93], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 6, 9, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 73.54, id: "ps_e0c703b0-8591-4cce-8cb8-d4c149568c55", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[71], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 6, 16, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 115.04, id: "ps_7757667a-fbc1-4b6c-9dbe-eda11dc96cef", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[9], seller: Sellers[79], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 7, 2, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 78.07, id: "ps_f096ccfb-6d3c-42a0-abb5-d7f39165918e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[49], seller: Sellers[69], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 7, 15, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 40.76, id: "ps_01462862-380d-48e2-97a1-f72394f398eb", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[62], seller: Sellers[9], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 7, 2, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 91.65, id: "ps_4c397a5b-8786-40c5-b3c3-bb72f7b333f9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[99], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 7, 12, 26).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 124.01, id: "ps_00741d53-6ae9-48bb-a237-dd5dd4edfc94", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[82], seller: Sellers[9], state: PAYIN_STATE.PENDING, date: new Date(2023, 11, 8, 12, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 215.15, id: "ps_4838d521-853f-42d0-a54b-ea924da23e62", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[27], seller: Sellers[33], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 8, 13, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 87.58, id: "ps_b9541c92-6638-4ea8-941c-624bb4794530", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[62], seller: Sellers[46], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 11, 8, 13, 45).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs, dispute: Disputes[65] },
        { currency: "$", amount: 83.24, id: "ps_2f95a834-c491-48ea-aeb9-0cf032a3d3f6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[90], seller: Sellers[6], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 8, 8, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 15.9, id: "ps_b46c9764-85d5-4129-a515-2f5e13699a9d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[98], seller: Sellers[82], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 8, 19, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 112.04, id: "ps_f969d03d-07f2-4cae-b8ad-2f5a0ce0f01b", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[76], seller: Sellers[32], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 8, 1, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 86.78, id: "ps_42a96910-ef14-4108-a98a-14c2e8bc1d76", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[15], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 8, 2, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 40.48, id: "ps_8df0cfe0-4c76-4f4d-aef1-e8644b9da19e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[9], seller: Sellers[4], state: PAYIN_STATE.INITIATED, date: new Date(2023, 11, 9, 20, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 12.3, id: "ps_33125e42-c8d6-454a-bc19-20d82dec362d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[102], seller: Sellers[75], state: PAYIN_STATE.INITIATED, date: new Date(2023, 11, 9, 12, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 17.86, id: "ps_a9abea7a-b4f6-43df-b78c-c953c454f274", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[77], seller: Sellers[75], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 9, 14, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 440.62, id: "ps_0b1d4f76-baed-4e5e-8497-8cda45a7ce2d", provider: PSPs[0], method: PaymentMethods[5], buyer: Buyers[7], seller: Sellers[78], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 9, 11, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 63.92, id: "ps_f146306d-751f-4bbe-a0e6-87cfbb69e34e", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[80], seller: Sellers[47], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 9, 1, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 183.57, id: "ps_1d58cd4e-a200-4cc4-97ff-35bceaab30d6", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[86], seller: Sellers[18], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 10, 12, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 63.88, id: "ps_f7fe1ef4-300c-4c5c-a9f0-a770748660bc", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[24], seller: Sellers[28], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 10, 7, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 50.64, id: "ps_815a4a44-ebdd-4493-8d3e-9a0e47b7a8be", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[4], seller: Sellers[16], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 10, 14, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 13.14, id: "ps_b2320ea2-0278-4c0b-9439-5328f3c22f2f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[62], seller: Sellers[16], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 10, 15, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 63.35, id: "ps_eeb94c02-ec95-4326-9f4e-e9a3ac4bb554", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[95], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 10, 4, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 489.93, id: "ps_395aed86-1662-4f0f-b78d-6120e19be601", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[16], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 10, 10, 32).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 37.92, id: "ps_51b04506-1c8e-4ab6-8f04-e7a37270a61c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[76], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 15, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 37.93, id: "ps_f8e4e6da-db93-42f3-a20f-56108f555f3a", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[33], seller: Sellers[86], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 0, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 20.83, id: "ps_6ca2b29c-2d1a-4831-9190-856c38b00569", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[31], seller: Sellers[63], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 11, 3, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 135.72, id: "ps_9372ea74-db86-428d-af16-cec930ec8126", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[74], seller: Sellers[81], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 17, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 17.85, id: "ps_62c74d56-a895-4f0a-a766-87ed273d68c1", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[44], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 5, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 36.02, id: "ps_810403cf-34ae-4fec-87d7-1525ce94d86c", provider: PSPs[0], method: PaymentMethods[8], buyer: Buyers[72], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 10, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 93.76, id: "ps_6ae9f0df-eab1-4ee0-9c35-e3e1fd8be622", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[78], seller: Sellers[65], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 17, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 34.21, id: "ps_2bc9b2f2-0c39-428e-a639-e689a27e891b", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[5], seller: Sellers[14], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 8, 35).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 18.65, id: "ps_37fb5e78-5664-47e6-9945-79c88fcb8f04", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[49], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 3, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 269.12, id: "ps_01a21256-91df-4855-9382-d7d65c6f5471", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[51], seller: Sellers[86], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 11, 11, 10, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[66] },
        { currency: "$", amount: 13.75, id: "ps_9b70963c-8b33-4786-8e99-b1bed0a3ac16", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[58], seller: Sellers[43], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 11, 11, 3, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs, dispute: Disputes[67] },
        { currency: "$", amount: 50.99, id: "ps_bb48c800-3f0c-4598-842b-ab5526934d0b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[7], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 11, 19, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 151.54, id: "ps_1412cc62-3b4d-4a71-9aff-472b911966e0", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[76], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 12, 8, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 78.82, id: "ps_effb77d5-51cb-44f3-9a33-802a079079f3", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[47], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 12, 21, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 42.55, id: "ps_01bce114-7a5f-4230-a13c-14a939aeae26", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[57], seller: Sellers[28], state: PAYIN_STATE.PENDING, date: new Date(2023, 11, 12, 11, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 93.52, id: "ps_228d2b87-6260-4f83-97e1-c2f869b84175", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[86], seller: Sellers[13], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 12, 23, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 281.38, id: "ps_40ab2464-0a02-47ab-8922-2ee4dcada888", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[34], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 12, 18, 11).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 331.47, id: "ps_08382cd3-3a94-49d8-a0a2-3c616f920d29", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[19], seller: Sellers[87], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 13, 16, 24).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 58.82, id: "ps_553159ea-ab4f-40b7-b686-a2cf0bd4c313", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[104], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 13, 8, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 157.71, id: "ps_bec4fb79-d3ff-406b-8df3-7aca369da0b6", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 13, 10, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 134.22, id: "ps_3021e7cb-20fc-4e36-9481-e78caec01a2f", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[49], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 13, 17, 6).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 138.21, id: "ps_7ec40d5f-1c05-4cd6-98c0-cf25548b8b14", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[70], seller: Sellers[13], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 13, 21, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 343.38, id: "ps_969a624c-3c5f-47f6-8343-e8f1268d4489", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[8], seller: Sellers[67], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 14, 15, 46).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 97.19, id: "ps_5e50d205-fd6b-45bc-b7ec-ca09cfa53ac7", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[97], seller: Sellers[63], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 11, 14, 11, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.smartTv, dispute: Disputes[68] },
        { currency: "$", amount: 124.09, id: "ps_80ea2898-ce5e-4e7d-bbf2-e324795c3172", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[54], seller: Sellers[63], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 11, 14, 21, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs, dispute: Disputes[69] },
        { currency: "$", amount: 3.9, id: "ps_1edc81c6-0cd0-41b6-9083-29cd91078609", provider: PSPs[0], method: PaymentMethods[9], buyer: Buyers[88], seller: Sellers[83], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 14, 12, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 65.1, id: "ps_5163ac79-0bcc-4b72-9389-2f20777c900c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[83], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 14, 13, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 40.65, id: "ps_9dcc648e-9c5f-452a-9cb6-78457a3378e9", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[70], seller: Sellers[39], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 15, 23, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 165.19, id: "ps_febbc61f-4e59-4c04-8384-88e5045cde3a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[41], seller: Sellers[4], state: PAYIN_STATE.PENDING, date: new Date(2023, 11, 15, 12, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 22.03, id: "ps_776e4c7d-6e88-497a-8f21-21073b7905c3", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[25], seller: Sellers[88], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 15, 16, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 52.75, id: "ps_bcfd12d8-f2e6-4d6c-b5b9-63e77a1650b3", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[88], seller: Sellers[58], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 15, 14, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 270.42, id: "ps_7b5b0d25-030f-4952-ae6d-f662d1e3f8d8", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[70], seller: Sellers[85], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 16, 9, 27).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 89.29, id: "ps_8fe67bf7-f6bc-46af-9b71-8d79635acda5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[54], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 16, 18, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 66.96, id: "ps_367ee17d-6589-492d-a44b-788d4a0ac8b4", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[66], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 16, 9, 33).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 90.93, id: "ps_135fe974-9159-42f5-8581-70c1710ad871", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[14], seller: Sellers[67], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 16, 14, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 293.36, id: "ps_21e46768-a229-42fc-a274-17c1f7c8585d", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[64], seller: Sellers[7], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 16, 21, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 120.56, id: "ps_b732373e-1528-4295-badb-c16edbf4afdc", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[83], seller: Sellers[79], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 16, 15, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 271.83, id: "ps_cf3793ac-0889-4b4c-8a88-eeeac70aada7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[101], seller: Sellers[34], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 17, 1, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabledAndroid },
        { currency: "$", amount: 33.55, id: "ps_b143ad8a-7921-434e-9226-b941be9e884b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[4], seller: Sellers[50], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 17, 15, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 368.41, id: "ps_77dbf4b6-5353-40ad-96ee-ce7be84f97c4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[29], seller: Sellers[7], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 17, 18, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 99.66, id: "ps_dba18c96-c5d2-49a1-8ffe-56c547f33716", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[74], seller: Sellers[32], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 17, 0, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 122.04, id: "ps_8613e223-af0d-4f0f-9f15-889c844363b1", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[12], seller: Sellers[85], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 17, 8, 4).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileIPhone },
        { currency: "$", amount: 40.64, id: "ps_561e0491-340e-461a-a696-32743abf1e8e", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[68], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 17, 6, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 43.03, id: "ps_69f1844a-2f6d-4f50-995c-6ca417a8e4cd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[28], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 17, 11, 13).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 20.31, id: "ps_482fd5b4-882c-4325-91fe-14e83b5c0b10", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[71], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 17, 15, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 321.25, id: "ps_b23486a3-e5f8-433d-81aa-af01c5ca8ff9", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[18], seller: Sellers[71], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 18, 13, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 16.94, id: "ps_646d6878-39f1-430c-8b9d-a66e25e9448f", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[34], seller: Sellers[59], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 18, 1, 0).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 68.52, id: "ps_7edfdd84-b2ca-48fe-9aa0-2a395c39c298", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[74], seller: Sellers[56], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 18, 13, 41).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 56.46, id: "ps_2832ca23-c155-4535-85f0-610c8c3137d2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[52], seller: Sellers[32], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 18, 20, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 72.7, id: "ps_e96a06c6-b8de-4e8f-b5f4-4be71b55dab7", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[59], seller: Sellers[82], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 18, 21, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 23.8, id: "ps_011245ec-8d7b-4c7a-b160-a0d91df5c682", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[50], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 18, 19, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 50.65, id: "ps_304917f0-9173-4bf9-be69-4a5cd4944455", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[77], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 18, 8, 38).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 98.02, id: "ps_8f488e24-9bec-47dc-8025-cd1e18a6afa7", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[43], seller: Sellers[69], state: PAYIN_STATE.PENDING, date: new Date(2023, 11, 18, 6, 54).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.consoleXbox },
        { currency: "$", amount: 153.36, id: "ps_85095726-f862-42af-b878-bdaa32287266", provider: PSPs[0], method: PaymentMethods[12], buyer: Buyers[63], seller: Sellers[83], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 18, 14, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 83.86, id: "ps_13a85051-578e-487f-94fc-f4382215517d", provider: PSPs[0], method: PaymentMethods[10], buyer: Buyers[58], seller: Sellers[69], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 19, 7, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 153.1, id: "ps_1c408611-8de1-4ae1-9ce9-b7db6e0a2496", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[16], seller: Sellers[15], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 19, 14, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 127.55, id: "ps_ad965b4b-634e-41af-b273-4a572e051866", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[66], seller: Sellers[4], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 19, 12, 2).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 32.74, id: "ps_a35774a7-27a2-42b2-883c-9fbd6f9a0120", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[6], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 19, 9, 22).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 44.35, id: "ps_601988e6-a3eb-4027-ab97-825817835cb6", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[42], seller: Sellers[43], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 20, 7, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 77.07, id: "ps_190674f5-7d8a-4e29-abe1-9aba93a3244a", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[12], seller: Sellers[84], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 21, 22, 56).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 25.66, id: "ps_2fcb083d-bf3f-497b-8a28-812519eb1a41", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[33], seller: Sellers[23], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 21, 14, 8).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 3.52, id: "ps_377d0cc9-1aa5-435a-8ef1-781d9b1b3254", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[60], seller: Sellers[44], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 21, 1, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 59.24, id: "ps_f0341882-d064-4d56-aee2-7d8b2751f022", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[81], seller: Sellers[74], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 21, 20, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 91.51, id: "ps_301f8a67-a503-44d9-bb0e-772a58c15fa5", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[21], seller: Sellers[82], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 21, 16, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 18.99, id: "ps_fd64133c-1abe-477f-b6fd-da9814bb702d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[94], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 21, 3, 53).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 76.39, id: "ps_61437908-a4ec-4bf0-aad8-ca99d284b716", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[20], seller: Sellers[22], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 21, 13, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 193.14, id: "ps_660df2bb-361a-41ed-832f-cc14f735216d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[19], seller: Sellers[1], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 21, 19, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 3.32, id: "ps_dcb60349-fcbb-4390-a8ad-374d7ae86d0b", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[13], seller: Sellers[49], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 22, 8, 20).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.smartTv },
        { currency: "$", amount: 49.13, id: "ps_6c934a0c-e206-45a0-b263-a9a15bff558c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[30], seller: Sellers[21], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 22, 10, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 15.67, id: "ps_1e70a843-20d7-41f9-a877-0c5bd176fa8d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[95], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 22, 6, 3).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 34.82, id: "ps_fbe8ff91-2d21-46c9-abb9-fce557086472", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[94], seller: Sellers[78], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 22, 3, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 58.6, id: "ps_94879bb7-e554-4b84-9755-80dde0c3a348", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[95], seller: Sellers[42], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 22, 12, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 10.96, id: "ps_134f00d8-599c-412f-9855-ca83e8e4d329", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[43], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 22, 21, 25).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.mobileAndroid },
        { currency: "$", amount: 56.41, id: "ps_ea64b085-7afc-4d64-9fc9-60c23efbf67e", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[44], seller: Sellers[57], state: PAYIN_STATE.DISPUTED, "isFraudolent": true, date: new Date(2023, 11, 23, 22, 30).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid, dispute: Disputes[70] },
        { currency: "$", amount: 44.46, id: "ps_ef6207a1-fb03-4399-985d-048da62f8708", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[79], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 23, 21, 42).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 221.41, id: "ps_224026de-adc8-4df6-b1a2-54ab8ea99cd2", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[22], seller: Sellers[72], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 23, 22, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 184.17, id: "ps_72a00fe9-1148-4c2c-b3d3-6a730b606e9e", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[24], seller: Sellers[86], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 24, 11, 12).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 71.55, id: "ps_0e567b1e-856a-4a44-ba58-6d1898696458", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[1], seller: Sellers[88], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 24, 7, 40).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 64.05, id: "ps_f699e556-2c59-43dd-96db-a890e188525d", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[102], seller: Sellers[24], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 25, 6, 49).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 224.81, id: "ps_8b33c0a1-0628-4626-9b1b-d9d9f89b235a", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[70], seller: Sellers[29], state: PAYIN_STATE.INITIATED, date: new Date(2023, 11, 25, 15, 18).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 42.22, id: "ps_640a2909-be56-4dba-b2ff-54ba85de9ff2", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[92], seller: Sellers[29], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 25, 4, 55).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopMacs },
        { currency: "$", amount: 121.34, id: "ps_aab47836-0d66-4d39-884b-e77bc5a4b749", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[45], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 25, 19, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 474.97, id: "ps_9cee0099-0bf3-4ed8-b0ff-bd31d93b0f55", provider: PSPs[0], method: PaymentMethods[3], buyer: Buyers[30], seller: Sellers[25], state: PAYIN_STATE.INITIATED, date: new Date(2023, 11, 25, 19, 50).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 85.17, id: "ps_99aa34ce-c3c8-4a23-9ebc-6ac470d676e7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[23], seller: Sellers[8], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 25, 10, 21).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 90.92, id: "ps_bfe15457-3632-4f6b-a3c0-6dabb8bdb291", provider: PSPs[0], method: PaymentMethods[11], buyer: Buyers[31], seller: Sellers[52], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 25, 18, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileAndroid },
        { currency: "$", amount: 74.28, id: "ps_df9d5e12-fc07-4797-ab07-c066646e1bbf", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[64], seller: Sellers[68], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 25, 23, 1).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 19.77, id: "ps_16be968a-dc2f-4355-b871-2e0155b4ebdd", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[5], seller: Sellers[63], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 25, 10, 7).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 192.77, id: "ps_5617fa96-f9e5-48ee-9e7e-8b4a24ce7505", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[92], seller: Sellers[44], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 25, 22, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consoleXbox },
        { currency: "$", amount: 375.35, id: "ps_049ba759-af1f-426a-ba29-2a0adc1d66d0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[35], seller: Sellers[80], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 26, 22, 48).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 91.69, id: "ps_4ef9c9b0-7cf8-44ec-92c9-70e15119da37", provider: PSPs[0], method: PaymentMethods[7], buyer: Buyers[79], seller: Sellers[49], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 26, 6, 14).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 82.53, id: "ps_1a8e4dc9-eb01-4235-89a4-613bf89c68d0", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[67], seller: Sellers[11], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 26, 16, 36).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 69.36, id: "ps_8f6b4c21-88ef-4b9a-bf9e-37800a2c1c33", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[10], seller: Sellers[48], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 27, 8, 16).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 43.19, id: "ps_643435eb-4548-4db8-a755-098309db8cc5", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[31], seller: Sellers[22], state: PAYIN_STATE.PENDING, date: new Date(2023, 11, 27, 0, 19).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 41.63, id: "ps_040a7d6c-5c63-4260-8151-102a09b0d4f8", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[102], seller: Sellers[12], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 28, 5, 51).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 36.84, id: "ps_88260840-b257-425e-8547-3b8358860634", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[88], seller: Sellers[74], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 28, 8, 39).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileWindows },
        { currency: "$", amount: 178.25, id: "ps_74487d63-2a52-42be-91ef-2f34c4dafd1f", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[95], seller: Sellers[73], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 28, 14, 52).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 56.25, id: "ps_bf0fc360-8ab9-43dd-b3c9-45e19599fca8", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[56], seller: Sellers[37], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 28, 6, 9).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 131.11, id: "ps_9fa215c9-4d1f-423a-bbe4-666af5a9df6b", provider: PSPs[0], method: PaymentMethods[4], buyer: Buyers[37], seller: Sellers[10], state: PAYIN_STATE.PROVIDED_METHOD, date: new Date(2023, 11, 28, 3, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows },
        { currency: "$", amount: 60.59, id: "ps_e2d08338-d236-40c6-af47-77d430d4de6b", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[36], seller: Sellers[73], state: PAYIN_STATE.REFUNDED, date: new Date(2023, 11, 28, 11, 17).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 16.96, id: "ps_4c68f6f8-3ee8-401b-b418-d2466b379a22", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[17], seller: Sellers[27], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 29, 20, 28).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.mobileIPhone },
        { currency: "$", amount: 80.75, id: "ps_4275fa97-5853-4c7e-977e-14e6c966724d", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[73], seller: Sellers[36], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 29, 18, 43).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation },
        { currency: "$", amount: 68.26, id: "ps_799d93ef-f862-4ca5-909d-3cae7b640a29", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[5], seller: Sellers[6], state: PAYIN_STATE.FAILED, date: new Date(2023, 11, 29, 12, 10).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 176.92, id: "ps_9e5b1b54-ecc7-4d29-9445-e3cb31e37575", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[86], seller: Sellers[47], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 29, 5, 59).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 81.56, id: "ps_308ffe6a-3f5e-4260-aed0-396b2daf8d26", provider: PSPs[0], method: PaymentMethods[0], buyer: Buyers[103], seller: Sellers[9], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 29, 17, 5).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.tabletIPad },
        { currency: "$", amount: 15.71, id: "ps_98c3b0f6-2a86-4507-b6b1-180c1d70f9e4", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[7], seller: Sellers[28], state: PAYIN_STATE.DISPUTED, date: new Date(2023, 11, 29, 20, 37).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopWindows, dispute: Disputes[71] },
        { currency: "$", amount: 256.68, id: "ps_1f92d087-4785-4662-8e36-01a23e666c8d", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[56], seller: Sellers[3], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 29, 5, 58).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 82.01, id: "ps_d7c65355-951f-41e4-b5a3-b19340ae718b", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[69], seller: Sellers[13], state: PAYIN_STATE.INITIATED, date: new Date(2023, 11, 29, 13, 57).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.tabletIPad },
        { currency: "$", amount: 293.62, id: "ps_18f14d03-3b41-4e37-a802-067e95488dd7", provider: PSPs[0], method: PaymentMethods[1], buyer: Buyers[93], seller: Sellers[41], state: PAYIN_STATE.INITIATED, date: new Date(2023, 11, 30, 16, 47).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.desktopMacs },
        { currency: "$", amount: 184.29, id: "ps_352c9564-621a-4ef1-8dc1-4467cb9fc21c", provider: PSPs[1], method: PaymentMethods[7], buyer: Buyers[103], seller: Sellers[35], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 30, 18, 44).getTime(), payout: PAYOUT_STATE.TO_PAY, device: Devices.desktopWindows },
        { currency: "$", amount: 45.43, id: "ps_4d56e751-faa6-426f-b7a0-0656c380fda2", provider: PSPs[0], method: PaymentMethods[6], buyer: Buyers[36], seller: Sellers[87], state: PAYIN_STATE.SUCCEEDED, date: new Date(2023, 11, 30, 0, 29).getTime(), payout: PAYOUT_STATE.PAID, device: Devices.consolePlaystation }
    ]