import { WebhookChannelsData } from "../../redux"


export enum WEBHOOK_CHANNELS_TABLE_COLS {
    NAME,
    URL,
    EVENT_TYPE,
    LAST_EVENT,
    CREATED
}

export interface WebhookChannelsOrder {
    by: WEBHOOK_CHANNELS_TABLE_COLS,
    ascendent: boolean
}

export interface WebhookChannelsRowProps {
    id: string
    name: string
    url: string
    eventType: string
    lastEvent?: number
    created: number
}

export class WebhookChannelsEngine {

    static getValues(state: WebhookChannelsData): WebhookChannelsRowProps[] {
        const reoredered = this.getOrderedValues(state.webhookChannels, state.order)
        return reoredered
    }

    static getOrderedValues(rows: WebhookChannelsRowProps[], order: WebhookChannelsOrder): WebhookChannelsRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {

            case WEBHOOK_CHANNELS_TABLE_COLS.NAME:
                result = result.sort((a, b) => a.name < b.name ? factor * -1 : factor * 1)
                break

            case WEBHOOK_CHANNELS_TABLE_COLS.URL:
                result = result.sort((a, b) => a.url < b.url ? factor * -1 : factor * 1)
                break

            case WEBHOOK_CHANNELS_TABLE_COLS.EVENT_TYPE:
                result = result.sort((a, b) => a.eventType < b.eventType ? factor * -1 : factor * 1)
                break

            case WEBHOOK_CHANNELS_TABLE_COLS.LAST_EVENT:
                result = result.sort((a, b) => (a.lastEvent ?? 0) < (b.lastEvent ?? 0) ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => a.created < b.created ? factor * -1 : factor * 1)
                break
        }
        return result
    }

    static getRows(): WebhookChannelsRowProps[] {

        var result: WebhookChannelsRowProps[] = [
            {
                id: "1919b53ca05d4e1eb2980d548b235fdc",
                name: "Order notifications",
                url: "https://tipeeestream/order-notifications",
                eventType: "order:all",
                lastEvent: new Date().setDate(-4),
                created: new Date(2023, 6, 20).getTime()
            },
            {
                id: "6edd1c7be7db4bcdb235e659947fa1c8",
                name: "Seller notifications",
                url: "https://tipeeestream/seller-notifications",
                eventType: "seller:all",
                lastEvent: new Date().setDate(-1),
                created: new Date(2023, 5, 20).getTime()
            }
        ]

        return result
    }
}
