import { BadgePropsForSellerOnboarding, Card, CardDataCollection, CardDivider, CardHeaderData, CardTitle } from "../.."
import { Buyers } from "../../../fake_data"
import { DemoRoutes } from "../../../routes"
import { CountryCode, ONBOARDING_STATE, SellerProps } from "../../../utilities"

export const SellerProfileDetails = (seller: SellerProps) => {

    let d = new Date(seller.registration)

    const render = () =>
        <Card>
            <CardTitle>
                {
                    window.location.pathname.startsWith(DemoRoutes.ledger.sellers) ||
                        window.location.pathname.startsWith(DemoRoutes.ledger.sellerDetails)
                        ? "Profile" : "Seller profile"
                }
            </CardTitle>

            <CardHeaderData
                title={seller.email}
                badge={BadgePropsForSellerOnboarding(seller.onboarding)} />

            <CardDivider />

            <CardDataCollection data={[
                { type: "address", data: [seller.country as CountryCode, Buyers[Math.floor(Math.random() * 105)].address] },
                { data: ["Payout Schedule", "Daily - 7 days"] },
                { data: ["Registration", d.toDateString()] },
                {
                    data: ["Onboarding", seller.onboarding === ONBOARDING_STATE.VERIFIED ?
                        new Date(d.setDate(d.getDate() + 2)).toDateString()
                        : "-"]
                },
            ]
            } />
        </Card>

    return render()
}