import { CurrencyProps } from "./currencies"

export interface BuyerProps {
    id: string
    name: string
    email: string
    type: BUYER_TYPE
    state: BUYER_STATE
    currency: CurrencyProps
    country: string
    firstPayment: number
    lastPayment: number
    address: string
}

export enum BUYER_TYPE {
    GUEST = 0,
    CUSTOMER = 1
}

export enum BUYER_STATE {
    LOCKED = 0,
    ACTIVE = 1
}
