import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, BadgePropsForBuyer, BuyerAccountDetails, BuyerMethodsDetails, BuyerProfileDetails, CardHorizzontalSpacer, Col, CountryFlag, Icon, ModalPage, P, Spacer } from "../.."
import { Buyers } from "../../../fake_data"
import { REDUX_STATE, buyersLedgerActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { BUYERS_LEDGER_COLS, BUYER_TYPE, BuyersLedgerRowProps, UUID } from "../../../utilities"


export const BuyersTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={head ? undefined : `1px solid ${palette.gray2}`} pointer>
            {!head && <Spacer size={12} />}
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="27%" max_w="27%">{children[0]}</Col>
                <Col w="13%" max_w="13%">{children[1]}</Col>
                <Col w="13%" max_w="13%">{children[2]}</Col>
                <Col w="13%" max_w="13%">{children[3]}</Col>
                <Col w="13%" max_w="13%">{children[4]}</Col>
                <Col w="13%" max_w="13%">{children[5]}</Col>
                <Col w="8%" max_w="8%">{children[6]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            {!head && <Spacer size={12} />}
        </Col>

    return render()
}

export const BuyersTableHead = () => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { orderData } = buyersLedgerActions
    const { theme: { palette }, buyers: { order } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Email", col: BUYERS_LEDGER_COLS.EMAIL },
        { name: "Type", col: BUYERS_LEDGER_COLS.TYPE },
        { name: "State", col: BUYERS_LEDGER_COLS.STATE },
        { name: "Balance", col: BUYERS_LEDGER_COLS.BALANCE },
        { name: "Volume", col: BUYERS_LEDGER_COLS.VOLUME },
        { name: "From", col: BUYERS_LEDGER_COLS.FROM },
        { name: "Country", col: BUYERS_LEDGER_COLS.COUNTRY }
    ]

    const render = () =>
        <BuyersTableRowLayout head>
            {
                cols.map(x =>
                    <Col xs="content" key={id + n++} py={12} pointer wrap="nowrap" align="center" onClick={() => dispatch(orderData(x.col))}>
                        <P nowrap mb={2} medium variant="secondary"> {x.name} </P>
                        <Icon pointer color={palette.black} height={18} variant={`ordinament${order.by !== x.col ? "" : order.ascendent ? "-asc" : "-des"}`} />
                    </Col>
                )
            }
        </BuyersTableRowLayout>

    return render()
}

export const BuyersTableRow = (props: BuyersLedgerRowProps) => {

    const { id, email, type, state, balance, volume, from, country } = props

    const render = () =>
        <BuyersTableRowLayout>
            <P regular variant="dark">{email}</P>
            <P regular variant="dark">{type === BUYER_TYPE.GUEST ? "Guest" : "Customer"}</P>
            <Badge {...BadgePropsForBuyer(state)} />
            <P medium variant="dark">${balance.toLocaleString()}</P>
            <P medium variant="dark">${volume.toLocaleString()}</P>
            <P regular variant="dark">{from && `${new Date(from).getDate()}/${new Date(from).getMonth() + 1}/${new Date(from).getFullYear()}`}</P>
            <P regular variant="dark"><CountryFlag variant={country} /></P>
        </BuyersTableRowLayout >

    const buyer = Buyers.find(x => x.id === id)

    return buyer ?
        <ModalPage href={DemoRoutes.ledger.buyerDetails + id}>
            {render()}
            <Col>
                <BuyerAccountDetails {...buyer} />
                <Spacer size={32} />
                <BuyerProfileDetails {...buyer} />
                <Spacer size={32} />
                <BuyerMethodsDetails {...buyer} />
            </Col>
        </ModalPage>
        :
        render()
}
