import "flatpickr/dist/flatpickr.css"
import { ReactNode, useEffect, useRef, useState } from "react"
import DatePicker from "react-flatpickr"
import { useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { Button, ButtonGroup, ButtonsDesign, Col, Icon, InputDesign, TextProps } from ".."
import { PaletteProps } from "../../design_system"
import { REDUX_STATE } from "../../redux"
import { checkDate } from "../../utilities"

interface DateSelectorProps {
    onChange?: (val: number) => void
    defaultValue?: Date
    withControls?: boolean
}

export const DateSelector = (props: DateSelectorProps) => {

    const ref = useRef<DatePicker>(null)
    const { palette } = useSelector(REDUX_STATE).theme

    const [day, setDay] = useState('')
    const refreshPicker = () => setDay(
        checkDate(ref.current?.flatpickr?.selectedDates[0]))
    useEffect(refreshPicker, [])


    const render = () =>
        <ButtonGroup>
            <Arrows palette={palette} onClick={removeDay}><Icon variant="arrow" rotate={90} /></Arrows>
            <Col xs="content" h='100%'>
                {
                    day !== '' &&
                    <Col bg={palette[InputDesign.background]} pointer align="center" justify="center" position="absolute" m={4} w={`calc(100% - ${8}px)`} h={`calc(100% - ${8}px)`} onClick={() => ref.current?.flatpickr.open()} >
                        <Today palette={palette}> {day} </Today>
                    </Col>
                }
                <CalendarStyled ref={ref} palette={palette} onChange={() => setDate()} options={
                    {
                        defaultDate: props.defaultValue ?? "today",
                        dateFormat: "d-m-Y",
                    }} />
            </Col>
            <Arrows palette={palette} onClick={addDay}><Icon variant="arrow" rotate={270} /></Arrows>
        </ButtonGroup>

    const addDay = () => setDate(1)
    const removeDay = () => setDate(-1)

    const setDate = (n: number = 0) => {
        refreshPicker()
        const d = ref.current?.flatpickr?.selectedDates[0]
        if (d) {
            d.setDate(d.getDate() + n)
            ref.current.flatpickr.setDate(d)
            props.onChange && props.onChange(d.getTime())
        }
    }

    return render()
}


const Arrows = styled((e: { onClick: () => void, children: ReactNode, palette: PaletteProps }) => Button({ ...e, variant: "alternate", type: "button" }))`
   &:hover{
        background: ${(p) => p.palette[ButtonsDesign.alternate.background]};
        border-color: ${(p) => p.palette[ButtonsDesign.alternate.borderColor]}; 
    }
`

interface TodayProps extends TextProps { palette: PaletteProps }
const Today = styled.div <TodayProps>`
${({ palette }) => css`
    ${ButtonsDesign.alternate.typography.default};
    font-weight: 500;
    color: ${palette[InputDesign.color]}; 
`}
`

const CalendarStyled = styled(DatePicker) <{ palette: PaletteProps }>`
${({ palette }) => css`
    ${ButtonsDesign.alternate.typography.default};

    cursor: pointer;
    font-weight: 500;
    text-align: center;
    height: ${ButtonsDesign.alternate.height}px;
    max-height: ${ButtonsDesign.alternate.height}px;

    color: ${palette[ButtonsDesign.alternate.color]};
    background: ${palette[ButtonsDesign.alternate.background]};

    border-radius: 0px;
    border-width: ${ButtonsDesign.alternate.borderWidth}px;
    border-style: ${ButtonsDesign.alternate.borderStyle};
    border-color: ${palette[ButtonsDesign.alternate.borderColor]};
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;

    outline-width: 0px!important;

    &.active {
        border-color: ${palette[ButtonsDesign.alternate.borderColor]};
    }

`}
`


