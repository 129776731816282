import { FontWeight } from "./types"

export interface TypographyProps {
    family: string
    H1: { size: number, weight: number, styles: string }
    H2: { size: number, weight: number, styles: string }
    H3: { size: number, weight: number, styles: string }
    H4: { size: number, weight: number, styles: string }
    H5: { size: number, weight: number, styles: string }
    P: { size: number, weight: number, styles: string }
    S: { size: number, weight: number, styles: string }
    XS: { size: number, weight: number, styles: string }
}


const Family = "'Inter', sans-serif";
const Size = {
    H1: 28,
    H2: 24,
    H3: 21,
    H4: 16,
    H5: 14,
    P: 13,
    S: 12,
    XS: 10
}

const Weight = {
    H1: FontWeight.medium,
    H2: FontWeight.medium,
    H3: FontWeight.regular,
    H4: FontWeight.regular,
    H5: FontWeight.medium,
    P: FontWeight.regular,
    S: FontWeight.regular,
    XS: FontWeight.regular
}

export const Typography: TypographyProps = {
    family: Family,
    H1: { size: Size.H1, weight: Weight.H1, styles: `font-family: ${Family}; font-size: ${Size.H1}px; font-weight: ${Weight.H1}; letter-spacing: 0px; line-height: normal;` },
    H2: { size: Size.H2, weight: Weight.H2, styles: `font-family: ${Family}; font-size: ${Size.H2}px; font-weight: ${Weight.H2}; letter-spacing: 0px; line-height: normal;` },
    H3: { size: Size.H3, weight: Weight.H3, styles: `font-family: ${Family}; font-size: ${Size.H3}px; font-weight: ${Weight.H3}; letter-spacing: 0px; line-height: normal;` },
    H4: { size: Size.H4, weight: Weight.H4, styles: `font-family: ${Family}; font-size: ${Size.H4}px; font-weight: ${Weight.H4}; letter-spacing: 0px; line-height: normal;` },
    H5: { size: Size.H5, weight: Weight.H5, styles: `font-family: ${Family}; font-size: ${Size.H5}px; font-weight: ${Weight.H5}; letter-spacing: 0px; line-height: normal;` },
    P: { size: Size.P, weight: Weight.P, styles: `font-family: ${Family}; font-size: ${Size.P}px; font-weight: ${Weight.P}; letter-spacing: 0px; line-height: normal;` },
    S: { size: Size.S, weight: Weight.S, styles: `font-family: ${Family}; font-size: ${Size.S}px; font-weight: ${Weight.S}; letter-spacing: 0px; line-height: normal;` },
    XS: { size: Size.S, weight: Weight.XS, styles: `font-family: ${Family}; font-size: ${Size.XS}px; font-weight: ${Weight.XS}; letter-spacing: 0px; line-height: normal;` }
}
