import { BUYER_STATE, BUYER_TYPE, DateTime, PAYIN_STATE, PAYOUT_STATE } from ".."
import { Buyers, Payins } from "../../fake_data"
import { BuyersLedgerData } from "../../redux"

export enum BUYERS_LEDGER_COLS {
    EMAIL,
    TYPE,
    STATE,
    BALANCE,
    VOLUME,
    COUNTRY,
    FROM
}

export interface BuyersLedgerOrder {
    by: BUYERS_LEDGER_COLS,
    ascendent: boolean
}

export interface BuyersLedgerFilter {
    state: boolean
    type: BUYER_STATE
}

export interface BuyersLedgerRowProps {
    id: string
    email: string
    type: BUYER_TYPE
    state: BUYER_STATE
    balance: number
    volume: number
    display_balance: string
    display_volume: string
    country: string
    from?: number
}


export class BuyersLedgerEngine {

    static getValues(state: BuyersLedgerData): BuyersLedgerRowProps[] {
        const rows = this.filterValues(state.buyers, state.filters)
        const reoredered = this.getOrderedValues(rows, state.order)
        return reoredered
    }

    static filterValues(buyers: BuyersLedgerRowProps[], applied: BuyersLedgerFilter[]) {
        let filtered: BuyersLedgerRowProps[] = []
        applied.some(x => x.type === BUYER_STATE.ACTIVE && x.state) && (filtered = filtered.concat(buyers.filter(x => x.state === BUYER_STATE.ACTIVE)))
        applied.some(x => x.type === BUYER_STATE.LOCKED && x.state) && (filtered = filtered.concat(buyers.filter(x => x.state === BUYER_STATE.LOCKED)))
        return filtered
    }

    static getOrderedValues(rows: BuyersLedgerRowProps[], order: BuyersLedgerOrder): BuyersLedgerRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {
            case BUYERS_LEDGER_COLS.EMAIL:
                result = result.sort((a, b) => a.email.toUpperCase() < b.email.toUpperCase() ? factor * -1 : factor * 1)
                break

            case BUYERS_LEDGER_COLS.TYPE:
                result = result.sort((a, b) => a.type < b.type ? factor * -1 : factor * 1)
                break

            case BUYERS_LEDGER_COLS.STATE:
                result = result.sort((a, b) => a.state < b.state ? factor * -1 : factor * 1)
                break

            case BUYERS_LEDGER_COLS.BALANCE:
                result = result.sort((a, b) => a.balance < b.balance ? factor * -1 : factor * 1)
                break

            case BUYERS_LEDGER_COLS.VOLUME:
                result = result.sort((a, b) => a.volume < b.volume ? factor * -1 : factor * 1)
                break

            case BUYERS_LEDGER_COLS.COUNTRY:
                result = result.sort((a, b) => a.country < b.country ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => !a.from ? factor * 1 : !b.from ? factor * -1 : a.from > b.from ? factor * -1 : factor * 1)
                break
        }
        return result
    }

    static getRows(start: number, end: number): BuyersLedgerRowProps[] {

        const buyers = Buyers.filter(x =>
            x.firstPayment >= start &&
            x.firstPayment <= end
        )
            .sort((a, b) =>
                a.firstPayment - b.firstPayment
            )
        const payins = Payins.filter(x =>
            x.date >= start &&
            x.date <= end
        )
            .sort((a, b) =>
                a.date - b.date
            )

        let p = []
        let b = 0
        let v = 0

        var result: BuyersLedgerRowProps[] = buyers.map(s => {
            p = payins.filter(x => x.buyer.email === s.email && x.state === PAYIN_STATE.SUCCEEDED)
            b = p.filter(x => x.payout === PAYOUT_STATE.PENDING).reduce((a, b) => a + b.amount, 0)
            v = p.filter(x => x.payout === PAYOUT_STATE.PAID).reduce((a, b) => a + b.amount, 0)

            return {
                id: s.id,
                email: s.email,
                type: s.type,
                state: s.state,
                balance: b,
                volume: v,
                display_balance: b.toFixed(2),
                display_volume: v.toFixed(2),
                country: s.country,
                from: p.sort((a, b) => a.date < b.date ? 1 : -1)[0]?.date
            }
        })

        return result
    }

}
