import { useSelector } from "react-redux"
import { REDUX_STATE } from "../../../redux"
import { LandingRoutes } from "../../../routes"

export interface LogoProps {
        color?: string
        short?: boolean
}

export const BiosLogo = (props: LogoProps) => {

        const { palette } = useSelector(REDUX_STATE).theme

        return <a href={LandingRoutes.index}>
                {
                        props.short ?
                                <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 21V0H10.41C11.71 0 12.85 0.24 13.83 0.72C14.81 1.2 15.56 1.85 16.08 2.67C16.6 3.49 16.86 4.4 16.86 5.4C16.86 6.58 16.55 7.55 15.93 8.31C15.33 9.05 14.59 9.56 13.71 9.84V9.99C14.81 10.21 15.74 10.74 16.5 11.58C17.28 12.4 17.67 13.52 17.67 14.94C17.67 16.1 17.38 17.14 16.8 18.06C16.22 18.98 15.37 19.7 14.25 20.22C13.15 20.74 11.82 21 10.26 21H0ZM10.11 17.7C10.97 17.7 11.69 17.58 12.27 17.34C12.85 17.08 13.28 16.73 13.56 16.29C13.86 15.85 14.01 15.35 14.01 14.79C14.01 14.19 13.86 13.67 13.56 13.23C13.28 12.77 12.85 12.41 12.27 12.15C11.69 11.89 10.97 11.76 10.11 11.76H3.57V17.7H10.11ZM10.05 8.55C11.03 8.55 11.8 8.31 12.36 7.83C12.92 7.33 13.2 6.69 13.2 5.91C13.2 5.15 12.92 4.53 12.36 4.05C11.8 3.55 11.03 3.3 10.05 3.3H3.57V8.55H10.05Z" fill={props.color ?? palette.black} />
                                </svg>
                                :
                                <svg width="65" height="21" viewBox="0 0 65 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 20.16V0.559999H10.08C11.3867 0.559999 12.516 0.783999 13.468 1.232C14.42 1.66133 15.1387 2.25867 15.624 3.024C16.128 3.78933 16.38 4.648 16.38 5.6C16.38 6.70133 16.0813 7.59733 15.484 8.288C14.8867 8.96 14.1587 9.41733 13.3 9.66V9.8C14.3453 10.0053 15.232 10.5 15.96 11.284C16.7067 12.0493 17.08 13.0947 17.08 14.42C17.08 15.5027 16.8 16.4827 16.24 17.36C15.68 18.2187 14.8587 18.9 13.776 19.404C12.712 19.908 11.4333 20.16 9.94 20.16H0ZM9.66 16.38C10.332 16.38 10.9013 16.2867 11.368 16.1C11.8347 15.8947 12.18 15.624 12.404 15.288C12.628 14.952 12.74 14.5693 12.74 14.14C12.74 13.7107 12.628 13.328 12.404 12.992C12.18 12.656 11.8347 12.3947 11.368 12.208C10.9013 12.0027 10.332 11.9 9.66 11.9H4.2V16.38H9.66ZM9.52 8.26C10.3413 8.26 10.9667 8.08267 11.396 7.728C11.8253 7.35467 12.04 6.87867 12.04 6.3C12.04 5.72133 11.8253 5.25467 11.396 4.9C10.9667 4.52667 10.3413 4.34 9.52 4.34H4.2V8.26H9.52Z" fill={props.color ?? palette.black} />
                                        <path d="M19.0389 20.16V0.559999H23.2389V20.16H19.0389Z" fill={props.color ?? palette.black} />
                                        <path d="M35.9866 20.72C33.9706 20.72 32.1412 20.2627 30.4986 19.348C28.8559 18.4147 27.5586 17.1547 26.6066 15.568C25.6732 13.9813 25.2066 12.2453 25.2066 10.36C25.2066 8.47467 25.6732 6.73867 26.6066 5.152C27.5586 3.56533 28.8559 2.31467 30.4986 1.4C32.1412 0.466667 33.9706 0 35.9866 0C38.0026 0 39.8319 0.466667 41.4746 1.4C43.1172 2.31467 44.4052 3.56533 45.3386 5.152C46.2906 6.73867 46.7666 8.47467 46.7666 10.36C46.7666 12.2453 46.2906 13.9813 45.3386 15.568C44.4052 17.1547 43.1172 18.4147 41.4746 19.348C39.8319 20.2627 38.0026 20.72 35.9866 20.72ZM35.9866 16.8C37.1812 16.8 38.2546 16.5293 39.2066 15.988C40.1772 15.428 40.9332 14.6627 41.4746 13.692C42.0159 12.7213 42.2866 11.6107 42.2866 10.36C42.2866 9.10933 42.0159 7.99867 41.4746 7.028C40.9332 6.05733 40.1772 5.30133 39.2066 4.76C38.2546 4.2 37.1812 3.92 35.9866 3.92C34.7919 3.92 33.7092 4.2 32.7386 4.76C31.7866 5.30133 31.0399 6.05733 30.4986 7.028C29.9572 7.99867 29.6866 9.10933 29.6866 10.36C29.6866 11.6107 29.9572 12.7213 30.4986 13.692C31.0399 14.6627 31.7866 15.428 32.7386 15.988C33.7092 16.5293 34.7919 16.8 35.9866 16.8Z" fill={props.color ?? palette.black} />
                                        <path d="M56.4222 20.72C54.7049 20.72 53.2302 20.4027 51.9982 19.768C50.7662 19.1333 49.7862 18.312 49.0582 17.304C48.3489 16.296 47.8635 15.1947 47.6022 14H51.9422C52.1849 14.8213 52.6795 15.5213 53.4262 16.1C54.1915 16.66 55.1902 16.94 56.4222 16.94C57.2809 16.94 57.9809 16.8467 58.5222 16.66C59.0635 16.4547 59.4555 16.1933 59.6982 15.876C59.9409 15.54 60.0622 15.148 60.0622 14.7C60.0622 14.2333 59.8755 13.8413 59.5022 13.524C59.1289 13.2067 58.6435 12.9453 58.0462 12.74C57.4675 12.5347 56.6835 12.3107 55.6942 12.068C54.1635 11.6573 52.9129 11.2653 51.9422 10.892C50.9902 10.5 50.1689 9.912 49.4782 9.128C48.7875 8.344 48.4422 7.308 48.4422 6.02C48.4422 4.93733 48.7409 3.93867 49.3382 3.024C49.9542 2.09067 50.8315 1.35333 51.9702 0.812C53.1275 0.270667 54.4715 0 56.0022 0C57.6262 0 59.0075 0.289334 60.1462 0.868001C61.3035 1.428 62.1995 2.16533 62.8342 3.08C63.4875 3.976 63.9169 4.956 64.1222 6.02H59.7822C59.6142 5.404 59.2129 4.88133 58.5782 4.452C57.9622 4.004 57.1035 3.78 56.0022 3.78C55.2929 3.78 54.6955 3.87333 54.2102 4.06C53.7249 4.24667 53.3609 4.49867 53.1182 4.816C52.8942 5.13333 52.7822 5.488 52.7822 5.88C52.7822 6.34667 52.9689 6.73867 53.3422 7.056C53.7155 7.37333 54.1915 7.63467 54.7702 7.84C55.3675 8.04533 56.1609 8.26933 57.1502 8.512C58.6809 8.92267 59.9222 9.324 60.8742 9.716C61.8449 10.0893 62.6755 10.668 63.3662 11.452C64.0569 12.236 64.4022 13.272 64.4022 14.56C64.4022 15.68 64.0942 16.7067 63.4782 17.64C62.8622 18.5733 61.9475 19.32 60.7342 19.88C59.5395 20.44 58.1022 20.72 56.4222 20.72Z" fill={props.color ?? palette.black} />
                                </svg>
                }
        </a>

}
