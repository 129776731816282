import { FeesProps } from "../utilities";
import { PAYMENT_METHOD_STATE } from "../utilities/paymentMethods";

export const Fees: FeesProps = {
    [0]: {
        au: { country: "au", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        be: { country: "be", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.45 }, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        bg: { country: "bg", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        ca: { country: "ca", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 1.5, max: 2.2 }, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        cy: { country: "cy", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.45 }, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2.4, state: PAYMENT_METHOD_STATE.SUSPENDED },
        dk: { country: "dk", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        ee: { country: "ee", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.3 }, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        fi: { country: "fi", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        fr: { country: "fr", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.45 }, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.45 }, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        hk: { country: "hk", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        hu: { country: "hu", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.3 }, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        ie: { country: "ie", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        it: { country: "it", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        jp: { country: "jp", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        lv: { country: "lv", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.45 }, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        mx: { country: "mx", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 1, max: 1.8 }, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        nz: { country: "nz", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        pl: { country: "pl", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        ro: { country: "ro", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        sg: { country: "sg", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        sk: { country: "sk", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        si: { country: "si", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        se: { country: "se", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.45 }, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        ch: { country: "ch", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.35 }, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        gb: { country: "gb", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.45 }, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        us: { country: "us", biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 1.5, max: 2.2 }, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED }
    },
    [1]: {
        au: { country: "au", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        at: { country: "at", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        be: { country: "be", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        bg: { country: "bg", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        ca: { country: "ca", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        hr: { country: "hr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        cy: { country: "cy", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        dk: { country: "dk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        ee: { country: "ee", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        fi: { country: "fi", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        fr: { country: "fr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        gi: { country: "gi", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        hk: { country: "hk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        hu: { country: "hu", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        ie: { country: "ie", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        it: { country: "it", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        jp: { country: "jp", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        lv: { country: "lv", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        li: { country: "li", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        mx: { country: "mx", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        nz: { country: "nz", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        no: { country: "no", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        pl: { country: "pl", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        pt: { country: "pt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        ro: { country: "ro", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        sk: { country: "sk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        si: { country: "si", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        se: { country: "se", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        ch: { country: "ch", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        gb: { country: "gb", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        us: { country: "us", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED }
    },
    [2]: {
        au: { country: "au", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        at: { country: "at", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        be: { country: "be", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        bg: { country: "bg", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        ca: { country: "ca", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        hr: { country: "hr", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        cy: { country: "cy", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        dk: { country: "dk", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        ee: { country: "ee", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        fi: { country: "fi", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        fr: { country: "fr", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        gi: { country: "gi", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        hk: { country: "hk", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        hu: { country: "hu", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        ie: { country: "ie", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        it: { country: "it", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        jp: { country: "jp", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        lv: { country: "lv", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        li: { country: "li", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        my: { country: "my", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        nl: { country: "nl", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        nz: { country: "nz", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        ro: { country: "ro", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        sk: { country: "sk", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        si: { country: "si", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        es: { country: "es", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        se: { country: "se", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        ch: { country: "ch", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        gb: { country: "gb", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        us: { country: "us", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED }
    },
    [3]: {
        au: { country: "au", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        be: { country: "be", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        ca: { country: "ca", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        dk: { country: "dk", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        fi: { country: "fi", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        fr: { country: "fr", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        de: { country: "de", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        hk: { country: "hk", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        ie: { country: "ie", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        it: { country: "it", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        jp: { country: "jp", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        no: { country: "no", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        es: { country: "es", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        se: { country: "se", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        ch: { country: "ch", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        gb: { country: "gb", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        us: { country: "us", biosFeeFix: 0.09, biosFeePerc: 0.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED }
    },
    [4]: {
        au: { country: "au", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        be: { country: "be", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        bg: { country: "bg", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        ca: { country: "ca", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        hr: { country: "hr", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        cy: { country: "cy", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        cz: { country: "cz", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        dk: { country: "dk", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        ee: { country: "ee", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        fi: { country: "fi", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        fr: { country: "fr", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        gi: { country: "gi", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        hk: { country: "hk", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        hu: { country: "hu", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        ie: { country: "ie", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        it: { country: "it", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        jp: { country: "jp", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        lv: { country: "lv", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        li: { country: "li", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        mx: { country: "mx", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        nz: { country: "nz", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        pl: { country: "pl", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        ro: { country: "ro", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        sk: { country: "sk", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        si: { country: "si", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        se: { country: "se", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        ch: { country: "ch", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        gb: { country: "gb", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        us: { country: "us", biosFeeFix: 0.25, biosFeePerc: 1.6, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED }
    },
    [5]: {
        au: { country: "au", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        be: { country: "be", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        bg: { country: "bg", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        ca: { country: "ca", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        hr: { country: "hr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        cy: { country: "cy", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        dk: { country: "dk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        ee: { country: "ee", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        fi: { country: "fi", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        fr: { country: "fr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        gi: { country: "gi", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        hk: { country: "hk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        hu: { country: "hu", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        ie: { country: "ie", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        it: { country: "it", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        jp: { country: "jp", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        lv: { country: "lv", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        li: { country: "li", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        mx: { country: "mx", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        nz: { country: "nz", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        pl: { country: "pl", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        pt: { country: "pt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        ro: { country: "ro", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        sk: { country: "sk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        si: { country: "si", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        se: { country: "se", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        ch: { country: "ch", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        gb: { country: "gb", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        us: { country: "us", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED }
    },
    [6]: {
        au: { country: "au", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        be: { country: "be", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        bg: { country: "bg", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        ca: { country: "ca", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        hr: { country: "hr", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        cy: { country: "cy", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        dk: { country: "dk", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        ee: { country: "ee", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        fi: { country: "fi", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        fr: { country: "fr", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        de: { country: "de", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        gi: { country: "gi", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        hk: { country: "hk", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        hu: { country: "hu", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        ie: { country: "ie", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        it: { country: "it", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        jp: { country: "jp", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        lv: { country: "lv", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        li: { country: "li", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        mt: { country: "mt", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        mx: { country: "mx", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        nz: { country: "nz", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        pl: { country: "pl", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        ro: { country: "ro", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        sg: { country: "sg", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        sk: { country: "sk", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        si: { country: "si", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        se: { country: "se", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        ch: { country: "ch", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        gb: { country: "gb", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        us: { country: "us", biosFeeFix: 0.25, biosFeePerc: 2.2, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED }
    },
    [7]: {
        au: { country: "au", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        at: { country: "at", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        be: { country: "be", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        bg: { country: "bg", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        ca: { country: "ca", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        cy: { country: "cy", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        dk: { country: "dk", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        ee: { country: "ee", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        fi: { country: "fi", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        fr: { country: "fr", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        hk: { country: "hk", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        hu: { country: "hu", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        ie: { country: "ie", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        it: { country: "it", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        jp: { country: "jp", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        lv: { country: "lv", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        lt: { country: "lt", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        lu: { country: "lu", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        mx: { country: "mx", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        nz: { country: "nz", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        no: { country: "no", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        pl: { country: "pl", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        pt: { country: "pt", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        ro: { country: "ro", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        sg: { country: "sg", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        sk: { country: "sk", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        si: { country: "si", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        se: { country: "se", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        ch: { country: "ch", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        gb: { country: "gb", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        us: { country: "us", biosFeeFix: 0.25, biosFeePerc: 1.5, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED }
    },
    [8]: {
        mx: { country: "mx", biosFeeFix: 3, biosFeePerc: 3.6, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED }
    },
    [9]: {
        au: { country: "au", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        be: { country: "be", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        bg: { country: "bg", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        ca: { country: "ca", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        cy: { country: "cy", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        dk: { country: "dk", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        ee: { country: "ee", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        fi: { country: "fi", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        fr: { country: "fr", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        gr: { country: "gr", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        hk: { country: "hk", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        hu: { country: "hu", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        ie: { country: "ie", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        it: { country: "it", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        jp: { country: "jp", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        lv: { country: "lv", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        mx: { country: "mx", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        nl: { country: "nl", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        nz: { country: "nz", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        pl: { country: "pl", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        ro: { country: "ro", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        sk: { country: "sk", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED },
        si: { country: "si", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        se: { country: "se", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        ch: { country: "ch", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        gb: { country: "gb", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        us: { country: "us", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED }
    },
    [10]: {
        au: { country: "au", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        be: { country: "be", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        bg: { country: "bg", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        ca: { country: "ca", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        cy: { country: "cy", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        dk: { country: "dk", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        ee: { country: "ee", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        fi: { country: "fi", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        fr: { country: "fr", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        hk: { country: "hk", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        hu: { country: "hu", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        ie: { country: "ie", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        it: { country: "it", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        jp: { country: "jp", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        lv: { country: "lv", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        lt: { country: "lt", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        mx: { country: "mx", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        nz: { country: "nz", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        pl: { country: "pl", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        ro: { country: "ro", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        sk: { country: "sk", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        si: { country: "si", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        se: { country: "se", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        ch: { country: "ch", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        gb: { country: "gb", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        us: { country: "us", biosFeeFix: 0.025, biosFeePerc: 0.3, platformFee: 3.9, state: PAYMENT_METHOD_STATE.DISABLED }
    },
    [11]: {
        au: { country: "au", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.8, state: PAYMENT_METHOD_STATE.DISABLED },
        be: { country: "be", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        bg: { country: "bg", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        ca: { country: "ca", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        hr: { country: "hr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        cy: { country: "cy", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        dk: { country: "dk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        ee: { country: "ee", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        fi: { country: "fi", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        fr: { country: "fr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        gi: { country: "gi", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        hk: { country: "hk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        hu: { country: "hu", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        ie: { country: "ie", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        it: { country: "it", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        jp: { country: "jp", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        lv: { country: "lv", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.7, state: PAYMENT_METHOD_STATE.DISABLED },
        li: { country: "li", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.8, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        mx: { country: "mx", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        nl: { country: "nl", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        nz: { country: "nz", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        pl: { country: "pl", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        ro: { country: "ro", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        sk: { country: "sk", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        si: { country: "si", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        es: { country: "es", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        se: { country: "se", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        ch: { country: "ch", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        gb: { country: "gb", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        us: { country: "us", biosFeeFix: 0.25, biosFeePerc: 1.4, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED }
    },
    [12]: {
        au: { country: "au", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        at: { country: "at", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        be: { country: "be", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        bg: { country: "bg", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        ca: { country: "ca", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        hr: { country: "hr", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.7, state: PAYMENT_METHOD_STATE.DISABLED },
        cy: { country: "cy", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        cz: { country: "cz", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2, state: PAYMENT_METHOD_STATE.DISABLED },
        dk: { country: "dk", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        ee: { country: "ee", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        fi: { country: "fi", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        fr: { country: "fr", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        de: { country: "de", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.5, state: PAYMENT_METHOD_STATE.ENABLED },
        gi: { country: "gi", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.5, state: PAYMENT_METHOD_STATE.ENABLED },
        gr: { country: "gr", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED },
        hk: { country: "hk", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        hu: { country: "hu", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        ie: { country: "ie", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        it: { country: "it", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.2, state: PAYMENT_METHOD_STATE.DISABLED },
        jp: { country: "jp", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        lv: { country: "lv", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        li: { country: "li", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        lt: { country: "lt", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        lu: { country: "lu", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        mt: { country: "mt", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.6, state: PAYMENT_METHOD_STATE.ENABLED },
        mx: { country: "mx", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        nl: { country: "nl", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED },
        nz: { country: "nz", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        no: { country: "no", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        pl: { country: "pl", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.2, state: PAYMENT_METHOD_STATE.ENABLED },
        pt: { country: "pt", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        ro: { country: "ro", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.1, state: PAYMENT_METHOD_STATE.ENABLED },
        sg: { country: "sg", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.6, state: PAYMENT_METHOD_STATE.ENABLED },
        sk: { country: "sk", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.9, state: PAYMENT_METHOD_STATE.DISABLED },
        si: { country: "si", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        es: { country: "es", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.3, state: PAYMENT_METHOD_STATE.ENABLED },
        se: { country: "se", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2, state: PAYMENT_METHOD_STATE.ENABLED },
        ch: { country: "ch", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.3, state: PAYMENT_METHOD_STATE.DISABLED },
        gb: { country: "gb", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 3.4, state: PAYMENT_METHOD_STATE.ENABLED },
        us: { country: "us", biosFeeFix: 0.29, biosFeePerc: 0, platformFee: 2.1, state: PAYMENT_METHOD_STATE.DISABLED }
    }
}


