import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PAYIN_STATE, PAYINS_LEDGER_COLS, PayinsLedgerEngine, PayinsLedgerFilter, PayinsLedgerOrder, PayinsLedgerRowProps } from "../utilities";
import { stat } from "fs";

export interface PayinsLedgerData {
    payins: PayinsLedgerRowProps[]
    period: {
        start: number
        end: number
    }
    order: PayinsLedgerOrder
    filters: PayinsLedgerFilter[]
    pagination: {
        results: number
        current: number
    }
}

const initialState: PayinsLedgerData = {
    payins: [],
    period: {
        start: new Date(2023, 0, 1).getTime(),
        end: new Date().getTime()
    },
    order: {
        by: PAYINS_LEDGER_COLS.DATE,
        ascendent: true
    },
    filters: [
        {
            state: true,
            type: PAYIN_STATE.SUCCEEDED
        },
        {
            state: true,
            type: PAYIN_STATE.PENDING
        },
        {
            state: false,
            type: PAYIN_STATE.REFUNDED
        },
        {
            state: true,
            type: PAYIN_STATE.INITIATED
        },
        {
            state: false,
            type: PAYIN_STATE.FAILED
        }
    ],
    pagination: {
        results: 15,
        current: 0
    }
}

export const payinsLedgerSlice = createSlice({
    name: 'payinsLedger',
    initialState,
    reducers: {

        setPeriod: (state: PayinsLedgerData, action: PayloadAction<{ start: number, end: number }>) => {
            const { start, end } = action.payload
            return {
                ...state,
                period: { start: start, end: end }
            }
        },

        loadData: (state: PayinsLedgerData) => {
            var payins = PayinsLedgerEngine.getRows(state.period.start, state.period.end)
            return {
                ...state,
                payins,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order: initialState.order
            }
        },

        orderData: (state: PayinsLedgerData, action: PayloadAction<PAYINS_LEDGER_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order
            }
        },

        setCurrentPage: (state: PayinsLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: PayinsLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        refund: (state: PayinsLedgerData, action: PayloadAction<string>) => {
            state.payins.forEach(x => x.id === action.payload && (x.state = PAYIN_STATE.REFUNDED) && (x.selected = false))
            return state
        },

        refundAll: (state: PayinsLedgerData) => {
            state.payins.forEach(x => x.selected && (x.state = PAYIN_STATE.REFUNDED) && (x.selected = false))
            return state
        },

        selectPayin: (state: PayinsLedgerData, action: PayloadAction<string>) => {
            state.payins.forEach(x => x.id === action.payload && (x.selected = !x.selected))
            return state
        },

        toggleAppliedFilter: (state: PayinsLedgerData, action: PayloadAction<PAYIN_STATE>) => {
            return {
                ...state,
                filters: state.filters.map(x => x.type !== action.payload ? x : { ...x, state: !x.state }),
                pagination: {
                    ...state.pagination,
                    current: 0
                }
            }
        }
    }
})

export const payinsLedgerActions = payinsLedgerSlice.actions

export const payinsLedgerReducer = payinsLedgerSlice.reducer

