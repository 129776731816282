import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, XS } from "../..";
import { SpacerValue } from "../../../design_system";
import { REDUX_STATE } from "../../../redux";
import { UUID } from "../../../utilities";

export const LineChart = (props: { sellers: number[], volumes: number[], labels: string[] }) => {

    const { sellers, volumes, labels } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const chartPadding = {
        top: 16 as SpacerValue,
        bottom: 32 as SpacerValue,
        horizzontal: 16 as SpacerValue
    }

    const ref = useRef<SVGSVGElement>(null)
    const [{ w, h }, setH] = useState({ w: ref.current?.scrollWidth ?? 0, h: ref.current?.scrollHeight ?? 0 })
    useEffect(() => {
        const element = ref?.current;
        if (!element) return;

        const observer = new ResizeObserver(() =>
            setH({ w: element.scrollWidth, h: element.scrollHeight - (chartPadding.bottom + chartPadding.top) })
        );

        observer.observe(element);

        return () => {
            observer.disconnect()
        }
    }, [])

    const yLabels = {
        sellers: Array.from({ length: 5 }, (_, index) => Math.ceil(Math.max(...sellers) / 4) * 4 - index * Math.ceil(Math.max(...sellers) / 4)),
        volumes: Array.from({ length: 5 }, (_, index) => Math.ceil(Math.max(...volumes) / 4) * 4 - index * Math.ceil(Math.max(...volumes) / 4))
    }

    const yPoints = [
        { id: UUID.create(), name: "Sellers", color: palette.primary2, values: sellers.map(x => h - (x / Math.max(...yLabels.sellers)) * h) },
        { id: UUID.create(), name: "Volumes", color: palette.gray5, values: volumes.map(x => h - (x / Math.max(...yLabels.volumes)) * h) }
    ]


    const chartPoints = Object
        .values(yPoints)
        .map(line => {
            return {
                ...line,
                values: Object.keys(line.values)
                    .slice(0, labels.length)
                    .map(x => {
                        return {
                            x: Number(x) * (w / (line.values.length - 1)),
                            y: chartPadding.top + line.values[Number(x)]
                        }
                    })
            }
        })


    let n = 0, uuid = UUID.create()

    const Render = () =>
        <Col h="100%" w="100%" wrap="nowrap">
            <Col xs="content" h="102%" direction="column" justify="between" pr={8} zIndex={3} pt={chartPadding.top} pb={chartPadding.bottom}>
                {
                    yLabels.sellers.map(x =>
                        <XS key={`seller_label${uuid}${n++}`}>{x.toLocaleString()}</XS>
                    )
                }
            </Col>


            <Col h="100%" mt={4}>
                <Col h="100%" zIndex={3}>
                    <svg ref={ref} width="100%" height="100%" version="1.2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        {
                            chartPoints.every(x => x.values.every(p => !isNaN(p.x) && !isNaN(p.y))) && <>
                                {
                                    chartPoints.map(({ id, color, values }) =>
                                        <linearGradient key={`gradient${id}`} id={id} x1="0%" y1="10%" x2="30%" y2="100%">
                                            <stop offset="2%" style={{ stopColor: color, stopOpacity: .3 }} />
                                            <stop offset="70%" style={{ stopColor: palette.white, stopOpacity: 0 }} />
                                        </linearGradient>
                                    )
                                }
                                {
                                    chartPoints.map(({ id, color, values }) =>
                                        <polyline key={`fill${id}`} fill={`url(#${id})`} stroke={color} strokeWidth="0"
                                            points={
                                                [
                                                    { x: values[0].x, y: h },
                                                    ...values,
                                                    { x: values[values.length - 1].x, y: h }
                                                ]
                                                    .map(({ x, y }) => `${x},${y}`)
                                                    .join(" ")
                                            } />

                                    )
                                }
                                {
                                    chartPoints.map(({ id, color, values }) =>
                                        <polyline key={`line${id}`} fill="none" stroke={color} strokeWidth="1.5"
                                            points={
                                                values
                                                    .map(({ x, y }) => `${x},${y}`)
                                                    .join(" ")
                                            } />
                                    )
                                }
                            </>
                        }
                    </svg>
                </Col>

                <Col h="100%" direction="column" position="absolute" left="0px" top="0px" justify="between" zIndex={2} pt={chartPadding.top} pb={chartPadding.bottom}  >
                    {
                        Object.keys(yLabels.sellers).map(x =>
                            <div key={`seller_labels${uuid}${n++}`} style={{ borderBottom: `1px ${Number(x) === yLabels.sellers.length - 1 ? "solid" : "dashed"} ${palette.gray2}` }} />
                        )
                    }
                </Col>
            </Col>


            <Col xs="content" h="102%" direction="column" justify="between" align="end" pl={12} zIndex={3} pt={chartPadding.top} pb={chartPadding.bottom} >
                {
                    yLabels.volumes.map(x =>
                        <XS key={`volume_labels${uuid}${n++}`}>{x.toLocaleString()}</XS>
                    )
                }
            </Col>


            <Col xs={12} justify="between" position="absolute" bottom="0px">
                {
                    labels.map(x =>
                        <XS key={`labels${uuid}${n++}`}>{x}</XS>
                    )
                }
            </Col>
        </Col>


    return Render()
}

