import { Card, CardDataCollection, CardDivider, CardHeaderData, CardTitle, DescriptionBlock } from "../.."
import { BuyerProps, CountryCode, PAYMENT_METHOD, PaymentMethod, UUID } from "../../../utilities"

export const PaymentMethodDetails = (props: { method: PaymentMethod, buyer: BuyerProps }) => {

    const id = UUID.create()
    const { method, buyer } = props

    const render = () =>
        <Card>
            <CardTitle>Payment method</CardTitle>

            <CardHeaderData id={id} title={<DescriptionBlock type="method-psp-large" data={method} />} />

            <CardDivider />

            <CardDataCollection data={
                method.value === PAYMENT_METHOD.CARD ?
                    [
                        { type: "address", data: [buyer.country as CountryCode, buyer.address] },
                        { data: ['Network', 'Visa'] },
                        { data: ['Expires', '04/2024'] },
                        { data: ['Number', '•••• 4254'] },
                        { data: ['CVC check', 'Passed'] },
                        { data: ['Zip check', 'Passed'] }
                    ]
                    :
                    [
                        { type: "address", data: [buyer.country as CountryCode, buyer.address] },
                        { data: ["Email", buyer.email] },
                        { data: ["Name", buyer.name] }
                    ]
            } />

        </Card>

    return render()
}