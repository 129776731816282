import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, ButtonsDesign, Col, Dashboard, Icon, Modal, Spacer, TablePagination, WebhookChannelsCreationForm, WebhookChannelsTable } from "../.."
import { REDUX_STATE, webhookChannelsActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { WebhookChannelsEngine } from "../../../utilities"

export const WebhookChannels = () => {

        const dispatch = useDispatch()
        useEffect(() => { dispatch(loadData()) }, [])

        const state = useSelector(REDUX_STATE).webhookChannels
        const { palette } = useSelector(REDUX_STATE).theme
        var channels = WebhookChannelsEngine.getValues(state)

        const { results, current } = state.pagination
        const { loadData, setCurrentPage } = webhookChannelsActions

        const pages = Math.floor(channels.length / results) - (channels.length % results === 0 ? 1 : 0)
        const rows = channels.slice(current * results, (current * results) + results)

        const [toClose, setToClose] = useState(false)

        const Render = () =>
                <Dashboard
                        pageTitle="Webhook Channels"
                        onPage={DemoRoutes.os.webhookChannel}
                        breadcrumb={["OS", "Webhook", "Channels"]}>

                        <Col mt={-40} position="absolute" justify="end" wrap="nowrap">
                                <Modal toClose={toClose}>
                                        <Button xs w100={false} variant="alternate">
                                                <Icon ml={-4} mr={4} variant="close" rotate={45} width={10} color={palette[ButtonsDesign["alternate"].color]} />
                                                Add new
                                        </Button>
                                        <WebhookChannelsCreationForm onCreated={() => { setToClose(true); window.setTimeout(() => setToClose(false), 300) }} />
                                </Modal>
                        </Col>

                        <WebhookChannelsTable {...rows} />

                        <Spacer size={24} />

                        <TablePagination {...{
                                pages, current, results,
                                items: channels.length,
                                setCurrent: (page: number) => dispatch(setCurrentPage(page))
                        }} />
                </Dashboard>

        return Render()
}

