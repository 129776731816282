import { BUYER_STATE, BUYER_TYPE, BuyerProps, Currencies } from "../utilities";


export const Buyers: BuyerProps[]
    =
    [
        { id: "ba42de15-f7ae-47b9-bfe6-06565f3a3dcf", name: "Dexter Foy", email: "rdowsing0@youtu.be", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.mxn, country: "ro", firstPayment: new Date(2023, 10, 7).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "69 Eggendart Drive" },
        { id: "fab46a92-9d5e-4f05-a3e4-2e1e7f0c9b81", name: "Gregoire Mattiazzi", email: "cobrian1@unicef.org", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.jpy, country: "it", firstPayment: new Date(2023, 9, 5).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "48967 Graedel Alley" },
        { id: "5c69d533-8cf8-4fbf-a6b4-8a6cdf28a910", name: "Zonda Baulcombe", email: "cneve2@plala.or.jp", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.mxn, country: "ro", firstPayment: new Date(2023, 8, 25).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "34 Beilfuss Street" },
        { id: "73cfcaf8-b23a-4ba1-9dc6-494bf6571128", name: "Lavinia Spurett", email: "sshovelin3@utexas.edu", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 5, 28).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "7 Petterle Trail" },
        { id: "4b5ae836-d56b-4674-ba04-539af5da686e", name: "Orsa Sloey", email: "fpering4@who.int", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.idr, country: "fr", firstPayment: new Date(2023, 2, 5).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "2 Havey Park" },
        { id: "ff671585-95ee-4008-abad-9d6277c8fb38", name: "Nahum Pettit", email: "ghazlewood5@cam.ac.uk", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.sek, country: "se", firstPayment: new Date(2023, 4, 23).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "479 Loomis Drive" },
        { id: "80a73d18-9488-45d9-8f8b-86a98ddd698d", name: "Holly Kopps", email: "drault6@delicious.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 3, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "6 La Follette Way" },
        { id: "d0680b6a-14b3-42bc-ab7c-46a9f5df261b", name: "Dill Butlin", email: "hhelgass7@google.pl", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.pen, country: "nl", firstPayment: new Date(2023, 5, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "489 Bellgrove Street" },
        { id: "c1ef2407-8deb-4640-8bf6-7022ecdbf628", name: "Craggy Landers", email: "ghutchinges8@dmoz.org", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.sek, country: "se", firstPayment: new Date(2023, 0, 26).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "7 Talmadge Road" },
        { id: "1ee547f1-8a2c-4b0a-978e-bcd6de76649b", name: "Mavis Catlette", email: "lholdall9@com.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.usd, country: "us", firstPayment: new Date(2023, 5, 9).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "07 Maywood Hill" },
        { id: "75785f8b-cc91-4469-a292-f0a8ff91c2ad", name: "Reine McEniry", email: "kgooma@chron.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.eur, country: "fr", firstPayment: new Date(2023, 3, 15).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "221 Continental Center" },
        { id: "e738d282-d039-470d-87ac-1b2f43a8b2a7", name: "Hali Speerman", email: "aburchmoreb@123-reg.co.uk", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 7, 16).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "95138 Messerschmidt Street" },
        { id: "b7efc95e-4994-4b55-bcb3-73d1061baf26", name: "Zenia Oselton", email: "dpelosc@cpanel.net", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.pkr, country: "de", firstPayment: new Date(2023, 10, 14).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "43 Porter Avenue" },
        { id: "6350e888-9ceb-4036-9587-938f557f3477", name: "Maud McLanachan", email: "mhundelld@cmu.edu", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.idr, country: "fr", firstPayment: new Date(2023, 0, 12).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "7 Declaration Center" },
        { id: "e9352957-c7ec-449d-b226-0303feaedab5", name: "Zaria Dellenbach", email: "cgurdone@tripod.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.eur, country: "pt", firstPayment: new Date(2023, 11, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "75261 Golf Court" },
        { id: "ed09b57c-488e-469b-aa3e-805ac4022ef0", name: "Pavel Drysdale", email: "nthurlbournef@goodreads.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.pkr, country: "de", firstPayment: new Date(2023, 1, 14).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "09 Delladonna Parkway" },
        { id: "a6b8e4c0-f4b3-4b56-bc8d-ce997f2cc5ce", name: "Britni Murrock", email: "dmccordg@free.fr", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.mxn, country: "pt", firstPayment: new Date(2023, 10, 5).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "2864 Hanson Lane" },
        { id: "ea5e5eb6-d36c-49b8-8700-18c3f4f78a98", name: "Bud Kayley", email: "jbollonh@cbslocal.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 0, 17).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "040 Kim Lane" },
        { id: "8033b73d-6c7e-4534-848c-dcfccef954f2", name: "Willette Habergham", email: "aabotsoni@thetimes.uk", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.egp, country: "be", firstPayment: new Date(2023, 1, 2).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "594 Arizona Center" },
        { id: "f06a7353-d9a9-41c7-968a-050faf559b07", name: "Yule Paddington", email: "tloftusj@sina.com.cn", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 1, 19).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "53 Jenna Avenue" },
        { id: "701264af-da34-4b98-a944-89bbfc677de4", name: "Ermina Riolfo", email: "rcopperk@zimbio.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.brl, country: "nl", firstPayment: new Date(2023, 5, 19).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "7157 Caliangt Pass" },
        { id: "543958f5-c8ea-47d7-a1d3-1d873eac98a8", name: "Link Pickavant", email: "gbartolomeol@wikimedia.org", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.php, country: "it", firstPayment: new Date(2023, 0, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "228 2nd Plaza" },
        { id: "8882cac0-f282-4c9d-9ab7-7b8d762e574c", name: "Monah Treleven", email: "ifairheadm@mac.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 5, 10).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "034 Arapahoe Road" },
        { id: "8e170b34-9c1f-4387-9a8d-04a0bc9a4a28", name: "Don Lippiello", email: "bpetrn@fastcompany.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 3, 26).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "79482 Washington Road" },
        { id: "d6781f6c-833b-4a25-bf2f-b1a32358a131", name: "Cordelie Karslake", email: "ubollando@business.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cop, country: "at", firstPayment: new Date(2023, 5, 23).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "690 Ridgeview Avenue" },
        { id: "110284e6-6f35-4cce-a7fc-4b5fb93abf79", name: "Land Elgram", email: "bsancraftp@webster.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.eur, country: "gr", firstPayment: new Date(2023, 9, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "91918 Clemons Lane" },
        { id: "d7cb9f88-2138-400e-9cfc-f669e8037163", name: "Sid Cuel", email: "aradsdaleq@last.fm", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.all, country: "al", firstPayment: new Date(2023, 9, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "20 Schlimgen Pass" },
        { id: "72c09531-2239-4e9d-947f-8e7ee9560c1e", name: "Leena Collocott", email: "hinglibyr@slashdot.org", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.brl, country: "nl", firstPayment: new Date(2023, 5, 10).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "447 Butterfield Circle" },
        { id: "99aa6523-04e3-4a82-98fb-542e4896b101", name: "Ban Corpe", email: "tstofferss@mysql.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.usd, country: "fi", firstPayment: new Date(2023, 2, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "26463 Maywood Trail" },
        { id: "4a02b3dc-8fe7-48c2-a0f4-c65ea6e311e5", name: "Maxi Gotts", email: "tdenest@youtu.be", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.pln, country: "pl", firstPayment: new Date(2023, 11, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "787 Claremont Plaza" },
        { id: "45c801ae-cdbb-4378-b018-56523e08f4f5", name: "Myrtice Cassel", email: "aferrsiu@purevolume.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.eur, country: "fr", firstPayment: new Date(2023, 2, 12).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "6990 Superior Lane" },
        { id: "fa4da5d1-fc2e-4ed2-b6f9-4b5669f671a3", name: "Garland Ganter", email: "cassandriv@cnn.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 9, 5).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "9 Graceland Junction" },
        { id: "cdaae84f-f586-4da3-b87f-707489a99ff8", name: "Letta Churchward", email: "obehneckenw@who.int", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.pln, country: "pl", firstPayment: new Date(2023, 6, 12).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "25123 Erie Road" },
        { id: "f1f65cf5-58e0-44a0-9cd6-08eb674a32bb", name: "Lib Whelpdale", email: "vallotx@tamu.edu", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.php, country: "it", firstPayment: new Date(2023, 4, 3).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "585 2nd Alley" },
        { id: "627b4595-7168-47f4-ae9b-04677516c8d9", name: "Shara Pepler", email: "rswancocky@tripod.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 8, 19).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "6999 Holy Cross Drive" },
        { id: "ae5d7d7d-2a82-4b94-ba6e-60243f8e0603", name: "Rees Lainge", email: "tstuddersz@science.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.eur, country: "gr", firstPayment: new Date(2023, 9, 29).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "45568 Esch Center" },
        { id: "94565f09-4b25-49eb-b1de-2c84465e4f7f", name: "Meghann Donkersley", email: "mauston10@booking.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.eur, country: "au", firstPayment: new Date(2023, 0, 20).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "8880 Corscot Road" },
        { id: "516f5d5b-eb3f-477f-838b-fecaa0ad4f10", name: "Alayne Simnett", email: "tguesford11@boston.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.eur, country: "gr", firstPayment: new Date(2023, 2, 20).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "0 Farragut Pass" },
        { id: "880a74f5-a05f-49f2-aa15-c5a9f89223a4", name: "Glennie Lansley", email: "kmquharg12@slideshare.net", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.idr, country: "fr", firstPayment: new Date(2023, 0, 10).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "4 Homewood Plaza" },
        { id: "b45912ea-4272-4315-9df5-5aedc7b599db", name: "Shauna Stockney", email: "kmatelyunas13@icq.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.jpy, country: "nl", firstPayment: new Date(2023, 2, 3).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "2 Artisan Road" },
        { id: "debfb2d8-622d-42c3-aedc-9a54c3596943", name: "Wiley Hollyar", email: "tcaile14@biblegateway.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.mxn, country: "fi", firstPayment: new Date(2023, 0, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "41 Buell Center" },
        { id: "8a773e25-57f4-446c-ad73-a3179efcf299", name: "Dehlia Wrankmore", email: "amalley15@oakley.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.usd, country: "us", firstPayment: new Date(2023, 10, 16).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "17 Transport Park" },
        { id: "f38c81eb-6f1c-40d7-bac4-34a4dd794c55", name: "Anton Demogeot", email: "ewainwright16@blog.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 4, 10).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "179 Buena Vista Street" },
        { id: "04567111-1a03-469b-a4bb-7950c95dc514", name: "Phillis Nanson", email: "hdurant17@uiuc.edu", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.irr, country: "ro", firstPayment: new Date(2023, 2, 9).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "8 Evergreen Avenue" },
        { id: "65214718-7a5e-4073-8821-6c8788fac6b7", name: "Kylila MacCracken", email: "gstrauss18@wordpress.org", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.usd, country: "it", firstPayment: new Date(2023, 6, 8).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "57808 Lakeland Alley" },
        { id: "33f9a0fa-5e6f-45f5-b538-885f8656559c", name: "Tatum Dumphry", email: "gmandifield19@1688.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.ngn, country: "es", firstPayment: new Date(2023, 11, 23).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "972 Bunker Hill Court" },
        { id: "4afefba7-83cd-4ce1-a1cd-df50c733d3be", name: "bscutts1a@123-reg.co.uk", email: " Dynah Dykins", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.eur, country: "gr", firstPayment: new Date(2023, 6, 8).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "8105 Duke Lane" },
        { id: "24698bc0-3885-4b4e-88c0-7187cb64ea92", name: "Lauree Dundredge", email: "mmelia1b@mashable.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.usd, country: "us", firstPayment: new Date(2023, 10, 8).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "006 Cordelia Terrace" },
        { id: "a841392c-6aeb-459e-b7b1-968fef496d65", name: "Heall Haizelden", email: "mbloys1c@cdbaby.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.hnl, country: "de", firstPayment: new Date(2023, 5, 16).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "23554 Milwaukee Drive" },
        { id: "3948213a-317b-4b04-af2b-9516038a3484", name: "Gwyneth Whitely", email: "dfrandsen1d@japan.jp", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.php, country: "it", firstPayment: new Date(2023, 9, 31).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "8 Moland Road" },
        { id: "b6125c7f-b29c-40ab-a0d3-4a6e3a8fa674", name: "Carmella La Batie", email: "zbuff1e@amazon.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 7, 3).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "2 Northland Center" },
        { id: "fea29a99-b51a-4991-be89-dbca5b4dad3e", name: "Emelita Bleckly", email: "uogorman1f@disqus.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.all, country: "al", firstPayment: new Date(2023, 2, 15).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "956 Kennedy Lane" },
        { id: "fafe4ef3-7e5e-4bf1-9661-9b12d1519d99", name: "Anabel Forward", email: "rdaley1g@latimes.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 11, 7).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "90517 Artisan Alley" },
        { id: "82a05ef4-d096-4850-852c-dda05b81f279", name: "Tiphanie Lott", email: "amohamed1h@hubpages.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.idr, country: "fr", firstPayment: new Date(2023, 10, 19).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "53 Talisman Circle" },
        { id: "2fe06f9b-ca1b-4ba6-80fb-ffe56a222d0a", name: "Hadleigh Van der Velde", email: "gosnell1i@hao123.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.brl, country: "nl", firstPayment: new Date(2023, 0, 20).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "282 Sullivan Drive" },
        { id: "06ad4478-e9cf-4065-8b1a-6d3c3962e97b", name: "Crawford Boltwood", email: "talleyn1j@ihg.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.yer, country: "es", firstPayment: new Date(2023, 1, 24).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "109 Algoma Avenue" },
        { id: "15d6814f-4727-4043-86ec-1a24fa683ba9", name: "Sherrie Halsworth", email: "hbutting1k@google.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 0, 2).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "6 Jenifer Drive" },
        { id: "9dba7808-6a9c-46e8-ac3a-f49bb5283b7a", name: "Bill Filipov", email: "hdeme1l@msu.edu", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 11, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "8 Prentice Plaza" },
        { id: "1213f4d1-d4ad-41c4-94fa-e3cc7d1902fa", name: "Loraine Dawks", email: "staggert1m@google.ca", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.brl, country: "nl", firstPayment: new Date(2023, 7, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "269 Gale Place" },
        { id: "bedff76a-e6dd-4712-a3f0-4e33f81123b5", name: "Charil Aldcorne", email: "dvasyatkin1n@eepurl.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 9, 5).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "1 Old Gate Road" },
        { id: "46e96578-d9fd-40d0-9dc5-28ce3324605a", name: "Hinda Midner", email: "mblagdon1o@network.org", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 4, 20).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "29 Riverside Road" },
        { id: "63eb7d5c-a997-4c0f-855e-e6ba9eecf21d", name: "Anna Vanner", email: "lsimeone1p@indiegogo.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.mxn, country: "fi", firstPayment: new Date(2023, 6, 18).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "9 Mayfield Street" },
        { id: "bdf6cf1d-20e0-4746-89d3-9241c82bc9ef", name: "Gerta O' Scanlan", email: "shabens1q @microsoft.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.hrk, country: "hr", firstPayment: new Date(2023, 0, 21).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "8500 Mesta Drive" },
        { id: "fe71e779-d1bb-4fd8-82fe-6e6d8c620d12", name: "Elizabeth Kitteridge", email: "bhamm1r@wp.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 8, 5).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "5728 Cascade Way" },
        { id: "c764654b-facb-41e2-b266-8f55057d0a9a", name: "Mic Pallis", email: "bpimbley1s@cbc.ca", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.sek, country: "se", firstPayment: new Date(2023, 11, 3).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "699 Kropf Court" },
        { id: "051f6c12-60e9-4968-b480-bb4fd997c808", name: "Lezley Hawtry", email: "bcopes1t@un.org", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.usd, country: "us", firstPayment: new Date(2023, 10, 29).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "8684 Scofield Parkway" },
        { id: "176fc9c1-5c5f-4fd7-9f29-2574810a3cd4", name: "Christy Jacobovitz", email: "mvanyukhin1u@tmall.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 10, 29).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "57 Lunder Circle" },
        { id: "c4f7946b-ebf4-46be-bb08-36f570e93d29", name: "Herbert O'Hare", email: "vleydon1v@photobucket.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.usd, country: "es", firstPayment: new Date(2023, 6, 5).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "4265 Mitchell Plaza" },
        { id: "b36f7a41-3676-4f34-b929-1303d867e4b3", name: "Maurita Cranke", email: "mmarquez1w@google.co.jp", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.usd, country: "us", firstPayment: new Date(2023, 7, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "31546 Briar Crest Terrace" },
        { id: "9210727c-7145-4501-b212-63426b960509", name: "Goldarina Wontner", email: "rspray1x@google.it", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.mxn, country: "es", firstPayment: new Date(2023, 3, 14).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "07508 Starling Point" },
        { id: "6ea76889-4d47-4a38-a60a-696e1a4efb54", name: "Daniel Petz", email: "mdangerfie@aboutads.info", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.myr, country: "it", firstPayment: new Date(2023, 2, 2).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "66 Mitchell Road" },
        { id: "38b5e03e-d453-43a9-91f6-9416c553e254", name: "Cordelia Yea", email: "jyancey1z@kickstarter.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 5, 23).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "3 Norway Maple Road" },
        { id: "0e65844f-02fc-4420-8c7c-5e9c7b020abc", name: "Grenville Raincin", email: "lradband20@gov.uk", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.eur, country: "be", firstPayment: new Date(2023, 9, 1).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "9 Harper Trail" },
        { id: "140038a5-f396-4b54-aa1e-57b7b645246a", name: "Merill McGurn", email: "sgravener21@youtu.be", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.uah, country: "ua", firstPayment: new Date(2023, 3, 12).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "9152 American Ash Junction" },
        { id: "e3a6ec40-f116-4368-9cad-72a0e089f97c", name: "Laurette Backshall", email: "rmusson22@cbc.ca", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 10, 1).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "792 Sycamore Street" },
        { id: "3a354f7a-ae3a-4f4b-9783-87c86e349a03", name: "Kaye Souter", email: "rboken23@squarespace.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.eur, country: "si", firstPayment: new Date(2023, 2, 31).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "84442 Stang Terrace" },
        { id: "99d6531e-ca6f-46aa-bed0-c9d194f8e16e", name: "Irene Senescall", email: "rnears24@national.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 2, 18).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "17 Bashford Drive" },
        { id: "91954308-9ba1-4119-ab19-f7fe4f5823f0", name: "Joey Boocock", email: "agauchier25@state.tx.us", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 0, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "360 Stoughton Court" },
        { id: "4536667a-46e7-4946-be20-8e1a9f8fe867", name: "Walther Cellone", email: "mpavy26@twitter.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 8, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "676 Division Parkway" },
        { id: "39fc79ae-f415-4e80-88bf-1630c8630200", name: "Gabie Gozney", email: "hworsall27@gov.uk", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 10, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "5388 Vera Road" },
        { id: "01f0cdfb-0b83-4702-b88e-84093a8dd16d", name: "Wells Kinchlea", email: "rfandrey28@dion.ne.jp", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.all, country: "al", firstPayment: new Date(2023, 11, 22).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "9 Bunker Hill Plaza" },
        { id: "6fd923f6-1f7a-4527-9b99-cdae8d4c80c3", name: "Beatrice Grunnill", email: "jgrellis29@boston.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 5, 18).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "6815 Elka Lane" },
        { id: "edfc6899-9c14-493f-8870-648d93b9fbb5", name: "Lorelei Bartomeu", email: "rvasyukov2a@marriott.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.krw, country: "ie", firstPayment: new Date(2023, 3, 15).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "7125 Eastwood Court" },
        { id: "929b54ec-5882-46e2-b524-2a5e57b1bfba", name: "Toby Kinvig", email: "fwaylen2b@behance.net", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 4, 30).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "02562 Mayer Drive" },
        { id: "dbd3f3f2-7c98-4c90-b001-ef88f0d82e14", name: "Alene Ong", email: "ashallcross2c@mapy.cz", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.idr, country: "fr", firstPayment: new Date(2023, 6, 4).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "428 Thompson Pass" },
        { id: "142b0c45-d464-4692-adf3-1c5d4c1d1109", name: "Sonny Tullis", email: "ophillcox2d@java.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.pln, country: "pl", firstPayment: new Date(2023, 2, 7).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "23 Northland Place" },
        { id: "1ad12ff0-3068-425e-9c68-609c9fad4fce", name: "Steffen Lindop", email: "dbarsham2e@vinaora.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 10, 8).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "277 Division Terrace" },
        { id: "2589d143-67a2-49f4-94c2-ee91382ef980", name: "Freedman Hoovart", email: "kparget@washington.edu", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 3, 18).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "3712 Randy Road" },
        { id: "f9fb0f87-999e-4d8b-b257-8b260b18cfef", name: "Nevin Dunsire", email: "jdavion2g@uiuc.edu", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.myr, country: "it", firstPayment: new Date(2023, 8, 18).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "524 Brentwood Way" },
        { id: "779fc216-6134-4cff-adc5-288775826258", name: "Lesly Dot", email: "ihalshaw2h@design.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.pln, country: "pl", firstPayment: new Date(2023, 4, 3).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "2803 Dawn Crossing" },
        { id: "87c9c530-4767-4107-99de-d9764816670f", name: "Tommie Facer", email: "wpendlebery2i@deviantart.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.idr, country: "fr", firstPayment: new Date(2023, 9, 19).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "07 5th Hill" },
        { id: "19400bab-dcb8-4b29-9ec4-a92e67acb8dc", name: "Shurwood Harp", email: "bdrinkhill2j@gateway.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.czk, country: "cz", firstPayment: new Date(2023, 5, 29).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "776 Golden Leaf Court" },
        { id: "2b364be2-e8d9-47c2-970c-1e5bed533267", name: "Alexine Luciano", email: "nflecknoe2k@wisc.edu", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.ars, country: "ar", firstPayment: new Date(2023, 2, 12).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "11 Kenwood Park" },
        { id: "a123e114-92cc-41ed-91b4-450f86afbe99", name: "Hamlen Hambers", email: "dshewon2l@eepurl.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.thb, country: "es", firstPayment: new Date(2023, 6, 23).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "40728 Elka Trail" },
        { id: "fa5d9b9e-44c8-49c7-a84c-04bd2b106c6c", name: "Catlin Hawgood", email: "mmarkel2m@cbslocal.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.hnl, country: "de", firstPayment: new Date(2023, 3, 28).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "2 Goodland Road" },
        { id: "208aeaa3-7117-424b-903a-6c80398ea77e", name: "Val Tebbs", email: "flanham2n@hostgator.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.krw, country: "ie", firstPayment: new Date(2023, 6, 7).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "897 Brickson Park Junction" },
        { id: "860c2dd8-dc6f-40f9-b56d-f3193eb7c263", name: "Mab Montague", email: "smoreton2o@barnesan.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.hnl, country: "de", firstPayment: new Date(2023, 10, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "934 Cottonwood Place" },
        { id: "0efdeed3-5928-4ecf-a5d0-dd0ee3b2b9a2", name: "Tally Aves", email: "chawgood2p@infoseek.co.jp", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 4, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "232 Paget Circle" },
        { id: "f8222427-7fa2-4b78-a7b7-b1fb9a19825a", name: "Elbertine Crace", email: "osimeonov2q@indiatimes.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.usd, country: "us", firstPayment: new Date(2023, 4, 27).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "00559 Sunnyside Park" },
        { id: "91d24027-e716-4b91-909c-74e142b9ffb8", name: "Doralynne Scargle", email: "tcorran2r@jiathis.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.usd, country: "us", firstPayment: new Date(2023, 3, 28).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "621 Sunnyside Court" },
        { id: "da9fe166-41ef-4bcf-beb8-6e89ee0f5f23", name: "Malvina Seemmonds", email: "hbenedito0@xinhuanet.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.rub, country: "gb", firstPayment: new Date(2023, 11, 16).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "621 Sunnyside Court" },
        { id: "09224e68-eae8-4501-b44c-5e36985af42a", name: "Angeline Beddin", email: "cneill1@altervista.org", type: BUYER_TYPE.GUEST, state: BUYER_STATE.ACTIVE, currency: Currencies.cny, country: "us", firstPayment: new Date(2023, 9, 12).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "621 Sunnyside Court" },
        { id: "85660787-6348-42c0-943c-bfb0b48cfd87", name: "Sayers Frobisher", email: "nambrosoli2@microsoft.com", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.ACTIVE, currency: Currencies.thb, country: "es", firstPayment: new Date(2023, 0, 11).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "621 Sunnyside Court" },
        { id: "592e231c-a8e2-4405-a779-1b6b73c9efdd", name: "Natalee Lascell", email: "tpeatt3@over-blog.com", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.brl, country: "nl", firstPayment: new Date(2023, 8, 28).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "621 Sunnyside Court" },
        { id: "af5f2680-1f6c-4a2c-b65b-33e65a8bbc9b", name: "Corie Turneux", email: "zfeetham4@google.co.jp", type: BUYER_TYPE.CUSTOMER, state: BUYER_STATE.LOCKED, currency: Currencies.cad, country: "ca", firstPayment: new Date(2023, 5, 3).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "621 Sunnyside Court" },
        { id: "bf34fd31-8048-4ee1-b4c6-781f1d141ae7", name: "Kermy M'Quharge", email: "taucourte5 @unicef.org", type: BUYER_TYPE.GUEST, state: BUYER_STATE.LOCKED, currency: Currencies.vnd, country: "ca", firstPayment: new Date(2023, 9, 18).getTime(), lastPayment: new Date(2023, 6, 14).getTime(), address: "621 Sunnyside Court" }
    ]
