import { useSelector } from "react-redux"
import { Card, CardTitle, Col, Icon, P, Spacer } from "../.."
import { REDUX_STATE } from "../../../redux"
import { UUID } from "../../../utilities"

export const PaymentTimeline = (items: [string, string][]) => {

    let n = 0, id = UUID.create()
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Card>
            <CardTitle>Timeline</CardTitle>

            <Col px={40} justify="between" wrap="wrap">
                <Col px={40} top="23px" left="0px" h="1px" max_h="1px" position="absolute"><Col h="1px" max_h="1px" bg={palette.gray2} /></Col>
                {
                    Object.values(items).map(x =>
                        <Col key={id + n++} xs="content" direction="column" align="center" bg={palette.white}>
                            <Col xs="content" w="46px" h="46px" b_rad="50%" bg={palette.primary1} justify="center" align="center">
                                <Icon variant={"user"} color={palette.primary2} />
                            </Col>
                            <Spacer size={8} />
                            <P variant="dark" nowrap>{x[0]}</P>
                            <P variant="secondary" nowrap>{x[1]}</P>
                        </Col>
                    )
                }
            </Col>

            <Spacer size={12} />
        </Card>

    return render()
}