import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardHorizzontalSpacer, Col, DescriptionBlock, FeeDetails, Icon, ModalPage, P, PaymentMethodDetails, SellerProfileDetails, Spacer } from "../.."
import { Payins } from "../../../fake_data"
import { REDUX_STATE, feesLedgerActions } from "../../../redux"
import { FEES_LEDGER_COLS, FeesLedgerRowProps, UUID } from "../../../utilities"


export const FeesTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={head ? undefined : `1px solid ${palette.gray2}`} pointer>
            {!head && <Spacer size={12} />}
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="20%" max_w="20%">{children[0]}</Col>
                <Col w="20%" max_w="20%">{children[1]}</Col>
                <Col w="20%" max_w="20%">{children[2]}</Col>
                <Col w="20%" max_w="20%">{children[3]}</Col>
                <Col w="20%" max_w="20%">{children[4]}</Col>
                <Col w="20%" max_w="20%">{children[5]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            {!head && <Spacer size={12} />}
        </Col>

    return render()
}

export const FeesTableHead = () => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { orderData } = feesLedgerActions
    const { theme: { palette }, fees: { order } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Gateway", col: FEES_LEDGER_COLS.GATEWAY },
        { name: "Amount", col: FEES_LEDGER_COLS.AMOUNT },
        { name: "BIOS", col: FEES_LEDGER_COLS.BIOS_FEE },
        { name: "Interchange", col: FEES_LEDGER_COLS.INTERCHANGE_FEE },
        { name: "Collected", col: FEES_LEDGER_COLS.COLLECTED_FEE },
        { name: "Date", col: FEES_LEDGER_COLS.DATE },
    ]

    const render = () =>
        <FeesTableRowLayout head>
            {
                cols.map(x =>
                    <Col key={id + n++} py={12} pointer wrap="nowrap" align="center" onClick={() => dispatch(orderData(x.col as FEES_LEDGER_COLS))}>
                        <P mb={2} medium variant="secondary"> {x.name} </P>
                        <Icon pointer color={palette.black} height={18} variant={`ordinament${order.by !== x.col ? "" : order.ascendent ? "-asc" : "-des"}`} />
                    </Col>
                )
            }
        </FeesTableRowLayout>

    return render()
}

export const FeesTableRow = (props: FeesLedgerRowProps) => {

    const { payinId, amount, method, biosFee, interchangeFee, collectedFee, date } = props

    const render = () =>
        <FeesTableRowLayout>
            <DescriptionBlock data={method} type="method-psp" />
            <P medium variant="dark">${amount.toFixed(2)}</P>
            <P medium variant="dark">${biosFee.toFixed(2)}</P>
            <P medium variant="dark">{interchangeFee === 0 ? "-" : "$" + interchangeFee.toFixed(2)}</P>
            <P medium variant="dark">${collectedFee.toFixed(2)}</P>
            <P regular variant="dark">{date}</P>
        </FeesTableRowLayout>

    const payin = Payins.find(x => x.id === payinId)

    return payin ?
        <ModalPage>
            {render()}
            <Col>
                <FeeDetails payin={payin} fee={props} />
                <Spacer size={32} />
                <SellerProfileDetails {...payin.seller} />
                <Spacer size={32} />
                <PaymentMethodDetails {...{ ...payin }} />
            </Col>
        </ModalPage>
        :
        render()
}