import { ReactNode } from "react";
import styled from "styled-components";
import { ButtonStyled } from "..";

export interface ButtonGroupProps { children: ReactNode[] }

export const ButtonGroup = (props: ButtonGroupProps) => {

    const { children } = props

    const first = children[0]
    const middle = children.slice(1, props.children.length - 1)
    const last = children[props.children.length - 1]

    const render = () => <ButtonGroupStyled>
        {
            first && <First> {first} </First>
        }
        {
            middle.length > 0 && <Middle> {middle} </Middle>
        }
        {
            last && <Last> {last} </Last>
        }
    </ButtonGroupStyled>

    return render()
}

const First = styled.div`
    ${ButtonStyled} {
        border-top-right-radius: 0px; border-bottom-right-radius: 0px;
        border-right-width: 0px;
        border-right-color: transparent;
    }
`

const Middle = styled.div`
    display: flex;
    flex-wrap: nowrap;
 
    ${ButtonStyled} {
        border-radius: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        border-right-color: transparent;
        border-left-color: transparent;
    }
`


const Last = styled.div`
    ${ButtonStyled} {
        border-top-left-radius: 0px; border-bottom-left-radius: 0px;
        border-left-width: 0px;
        border-left-color: transparent;
    }    
`

const ButtonGroupStyled = styled.div`
    display: flex;
    flex-wrap: nowrap;
`