import { Dashboard } from "../.."
import { DemoRoutes } from "../../../routes"

export const WebhookLogs = () => {


        const Render = () =>
                <Dashboard
                        pageTitle="Webhook Logs"
                        onPage={DemoRoutes.os.webhookLogs}
                        breadcrumb={["OS", "Webhook", "Logs"]}>
                </Dashboard>


        return Render()
}

