import { ReactNode } from "react"
import { Breadcrumb, Col, Container, H1, Navbar, PageHorizzontalSpacer, PageVerticalSpacer, Row, Sidebar, Spacer } from ".."

interface DashboardProps {
    pageTitle: string
    children: ReactNode | ReactNode[]
    onPage: string
    breadcrumb: string[]
}

export const Dashboard = (props: DashboardProps) => {

    const { pageTitle, children, onPage, breadcrumb } = props

    const Render = () =>
        <Container>
            <Navbar />
            <Row wrap="nowrap">
                <Sidebar {...{ onPage }} />
                <Col wrap="nowrap" justify="center">
                    <PageHorizzontalSpacer />
                    <Col max_w="1300px">
                        <PageVerticalSpacer />
                        <Breadcrumb path={breadcrumb} />
                        <Spacer size={16} />
                        <H1 semiBold variant="dark">{pageTitle}</H1>
                        <Spacer size={24} />
                        {children}
                        <PageVerticalSpacer />
                        <PageVerticalSpacer />
                    </Col>
                    <PageHorizzontalSpacer />
                </Col>
            </Row>
        </Container>

    return Render()
}





