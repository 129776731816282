import { Card, FeesTableHead, FeesTableRow } from "../.."
import { FeesLedgerRowProps, UUID } from "../../../utilities"

export const FeesTable = (rows: FeesLedgerRowProps[]) => {

    let n = 0, id = UUID.create()

    const render = () =>
        <Card noPadding>
            <FeesTableHead />
            {
                Object.values(rows).map(row =>
                    <FeesTableRow key={id + n++} {...row} />
                )
            }
        </Card>

    return render()
}