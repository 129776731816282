import { BadgePropsForBuyerType, Card, CardDataCollection, CardDivider, CardHeaderData, CardTitle } from "../.."
import { BuyerProps, CountryCode } from "../../../utilities"

export const BuyerProfileDetails = (buyer: BuyerProps) => {

    const render = () =>
        <Card>
            <CardTitle>Profile</CardTitle>

            <CardHeaderData
                title={buyer.email}
                badge={BadgePropsForBuyerType(buyer.type)} />

            <CardDivider />

            <CardDataCollection data={[
                { type: "address", data: [buyer.country as CountryCode, buyer.address] },
                { data: ["Default Currency", `${buyer.currency.symbol} ${buyer.currency.name}`] },
                { data: ["First payment", new Date(buyer.firstPayment).toDateString()] },
                { data: ["Last payment", new Date(buyer.lastPayment).toDateString()] }
            ]} />
        </Card>

    return render()
}