import { Dashboard, SellerAccountDetails, SellerCapabilitiesDetails, SellerProfileDetails, Spacer } from "../.."
import { Sellers } from "../../../fake_data"
import { DemoRoutes } from "../../../routes"
import { ONBOARDING_STATE } from "../../../utilities"

export const SellerDetails = () => {

        const path = window.location.pathname.split('/')
        const id = path[path.length - 1]
        const seller = Sellers.find(x => x.id === id)

        const Render = () =>
                <Dashboard
                        pageTitle="Details"
                        onPage={DemoRoutes.ledger.sellerDetails}
                        breadcrumb={["Ledger", "Customers", "Sellers"]}>
                        {
                                seller && <>
                                        <SellerAccountDetails {...seller} />
                                        <Spacer size={24} />
                                        <SellerProfileDetails {...seller} />
                                        {
                                                seller.onboarding === ONBOARDING_STATE.VERIFIED && <>
                                                        <Spacer size={24} />
                                                        <SellerCapabilitiesDetails {...seller} />
                                                </>
                                        }
                                </>
                        }
                </Dashboard>

        return Render()
}

