import { useSelector } from "react-redux"
import { Card, CardDataCollection, CardDivider, CardHeaderData, CardTitle, Col, H4, H5, Icon, P, Spacer, TextColors } from "../.."
import { Fees } from "../../../fake_data"
import { REDUX_STATE } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { FeesLedgerRowProps, PAYMENT_METHOD, PayinProps } from "../../../utilities"

export const FeeDetails = (props: { payin: PayinProps, fee: FeesLedgerRowProps }) => {

    const { palette } = useSelector(REDUX_STATE).theme
    const { currency, amount, state, provider, method, seller, buyer, date } = props.payin
    const { biosFee, biosFeeDescription, interchangeFee, interchangeFeeDescription, collectedFee, collectedFeeDescription } = props.fee
    const avgInterchangeFee = Fees[method.value][seller.country].interchangeFee
    const avgInterchangeFeeMin = avgInterchangeFee?.min.toFixed(2)
    const avgInterchangeFeeMax = avgInterchangeFee?.max.toFixed(2)

    const racq = Math.random() * 0.30;
    const rsch = Math.random() * 0.30;
    const rint = 1 - racq - rsch;

    const render = () => <>
        <Card>
            <Col justify="between" align="start">
                <Col xs="content">
                    <CardTitle>Total Fee</CardTitle>
                </Col>
                <Col xs="content" justify="end">
                    <H5 semiBold variant="link" targetBlank href={DemoRoutes.system[
                        method.value === PAYMENT_METHOD.CARD ? 'stripeCard' :
                            method.value === PAYMENT_METHOD.BANCONTACT ? 'stripeBancontact' :
                                method.value === PAYMENT_METHOD.ALIPAY ? 'stripeAlipay' :
                                    method.value === PAYMENT_METHOD.WECHATPAY ? 'stripeWechatPay' :
                                        method.value === PAYMENT_METHOD.EPS ? 'stripeEps' :
                                            method.value === PAYMENT_METHOD.SOFORT ? 'stripeSofort' :
                                                method.value === PAYMENT_METHOD.P24 ? 'stripeP24' :
                                                    method.value === PAYMENT_METHOD.OXXO ? 'stripeOxxo' :
                                                        method.value === PAYMENT_METHOD.GOOGLE_PAY ? 'stripeGooglePay' :
                                                            method.value === PAYMENT_METHOD.APPLE_PAY ? 'stripeApplePay' :
                                                                method.value === PAYMENT_METHOD.GIROPAY ? 'stripeGiroPay' :
                                                                    method.value === PAYMENT_METHOD.IDEAL ? 'stripeIdeal' : 'paypal'
                    ] + `#${seller.country}`}>Setup fee<Icon ml={4} mt={2} width={10} variant="link" color={palette[TextColors.link]} /></H5>
                </Col>
            </Col>

            <CardHeaderData title={`${currency}${(biosFee + (interchangeFee ?? 0) + collectedFee).toFixed(2)} (${((biosFee + (interchangeFee ?? 0) + collectedFee) / amount * 100).toFixed(2)}%)`} />
            <CardDivider />

            {
                method.value === PAYMENT_METHOD.CARD ?
                    <CardDataCollection data={[
                        { data: ["BIOS fee", `$${biosFee.toFixed(2)} (${biosFeeDescription})`] },
                        { data: ["Interchange fee", `$${interchangeFee.toFixed(2)} (${interchangeFeeDescription})`] },
                        { data: ["Collected fee", `$${collectedFee.toFixed(2)} (${collectedFeeDescription})`] },
                    ]} />
                    :
                    <CardDataCollection data={[
                        { data: ["BIOS fee", `$${biosFee.toFixed(2)} (${biosFeeDescription})`] },
                        { data: ["Collected fee", `$${collectedFee.toFixed(2)} (${collectedFeeDescription})`] },
                    ]} />
            }
        </Card>
        {
            method.value === PAYMENT_METHOD.CARD && <>
                <Spacer size={32} />
                <Card>
                    <CardTitle>Interchange Fee</CardTitle>
                    <CardHeaderData title={`${currency}${interchangeFee.toFixed(2)} (${interchangeFeeDescription})`}
                        badge={avgInterchangeFeeMin && avgInterchangeFeeMax ? { variant: "default", children: `Expected ${avgInterchangeFeeMin.charAt(avgInterchangeFeeMin.length - 1) === '0' ? avgInterchangeFeeMin.slice(0, avgInterchangeFeeMin.length - 1) : avgInterchangeFeeMin}-${avgInterchangeFeeMax.charAt(avgInterchangeFeeMax.length - 1) === '0' ? avgInterchangeFeeMax.slice(0, avgInterchangeFeeMax.length - 1) : avgInterchangeFeeMax}%` } : undefined} />

                    <CardDivider />

                    <CardDataCollection data={[
                        { data: ["Acquiring fee", `$${(interchangeFee * racq).toFixed(2)} (${((interchangeFee * racq) / amount * 100).toFixed(2)}%)`] },
                        { data: ["Scheme fee", `$${(interchangeFee * rsch).toFixed(2)} (${((interchangeFee * rsch) / amount * 100).toFixed(2)}%)`] },
                        { data: ["Issuing fee", `$${(interchangeFee * rint).toFixed(2)} (${((interchangeFee * rint) / amount * 100).toFixed(2)}%)`] },
                    ]} />
                </Card>
            </>
        }
    </>

    return render()
}
