import { useState } from "react"
import { useSelector } from "react-redux"
import { BaseInputProps, Checkbox, CheckboxProps, Col, Container, DateInput, DateInputProps, Icon, Input, NumberInputProps, P, Radio, RadioProps, Row, S, Select, SelectProps, Spacer, TextArea, TextAreaProps, TextInputProps } from ".."
import { SpacerValue } from "../../design_system"
import { REDUX_STATE } from "../../redux"
import { DemoRoutes } from "../../routes"
import { UUID } from "../../utilities"

export interface FormInputBaseProps {
    label?: string
    children?: string
    disabled?: boolean
    errorMessage?: string
}

interface RadioFormInputProps extends FormInputBaseProps, RadioProps { type: 'radio', vertical?: boolean, option: string[], value?: string, onSelected?: (val: string) => void }
interface CheckboxFormInputProps extends FormInputBaseProps, CheckboxProps { type: 'checkbox' }
interface TextFormInputProps extends FormInputBaseProps, TextInputProps { type: 'text' }
interface EditableTextFormInputProps extends FormInputBaseProps, BaseInputProps { type: 'editable-text', isEditing: boolean, setIsEditing: (s: boolean) => void, onConfirm?: (val: string | number | (string | number)[] | undefined) => void }
export interface EditableNumberFormInputProps extends FormInputBaseProps, BaseInputProps { type: 'editable-number', isEditing: boolean, setIsEditing: (s: boolean) => void, onConfirm?: (val: string | number | (string | number)[] | undefined) => void }
export interface EditableSelectFormInputProps extends FormInputBaseProps, SelectProps { type: 'editable-select', isEditing: boolean, setIsEditing: (s: boolean) => void, onConfirm?: (val: string | number | (string | number)[] | undefined) => void }
interface TextAreaFormInputProps extends FormInputBaseProps, TextAreaProps { type: 'area' }
interface NumberFormInputProps extends FormInputBaseProps, NumberInputProps { type: 'number', minValue?: number, maxValue?: number }
interface PasswordFormInputProps extends FormInputBaseProps, TextInputProps { type: 'password' }
interface SelectFormInputProps extends FormInputBaseProps, SelectProps { type: 'select' }
interface DateFormInputProps extends FormInputBaseProps, DateInputProps { type: 'date' }

export const FormInput = (props: RadioFormInputProps | CheckboxFormInputProps | TextFormInputProps | EditableTextFormInputProps | EditableNumberFormInputProps | EditableSelectFormInputProps | TextAreaFormInputProps | NumberFormInputProps | PasswordFormInputProps | SelectFormInputProps | DateFormInputProps) => {
    const render = () => {
        switch (props.type) {
            case 'radio': return <RadioFormInput {...props} />
            case 'checkbox': return <CheckboxFormInput {...props} />
            case 'area': return <TextAreaFormInput {...props} />
            case 'editable-text': case 'editable-number': case 'editable-select': return <EditableFormInput {...props} />
            case 'password': return <PasswordFormInput {...props} />
            case 'date': return <DateFormInput {...props} />
            case 'select': return <SelectFormInput {...props} />
            default: return <TextFormInput {...props} />
        }
    }

    return <Container>
        {render()}
    </Container>
}


const TextFormInput = (props: TextFormInputProps | NumberFormInputProps) =>
    <Col direction='column'>
        {
            props.children &&
            <P medium variant="secondary" mb={8}>{props.children}</P>
        }
        <Input  {...{ ...props, error: props.error || (props.errorMessage?.length ?? 0) > 0 }} />
        {
            props.errorMessage && <>
                <Spacer size={8} />
                <S variant="error">{props.errorMessage}</S>
            </>
        }
    </Col>


const TextAreaFormInput = (props: TextAreaFormInputProps) =>
    <Col direction='column'>
        {
            props.children &&
            <P medium variant="secondary" mb={8}>{props.children}</P>
        }
        <TextArea  {...{ ...props, error: props.error || (props.errorMessage?.length ?? 0) > 0 }} />
        {
            props.errorMessage && <>
                <Spacer size={8} />
                <S variant="error">{props.errorMessage}</S>
            </>
        }
    </Col>


const EditableFormInput = (props: EditableTextFormInputProps | EditableNumberFormInputProps | EditableSelectFormInputProps) => {

    const { palette } = useSelector(REDUX_STATE).theme
    const { children, errorMessage, defaultValue, type, placeholder, isEditing, setIsEditing, onConfirm } = props


    const inputProps = { ...props, error: (errorMessage?.length ?? 0) > 0 }

    const [val, setVal] = useState<string | number | (string | number)[] | undefined>(typeof defaultValue === 'object' ? defaultValue.value : defaultValue)

    const render = () =>
        <Col xs={12} direction="column">
            {
                props.children &&
                <P variant="secondary" mb={8}>{children}</P>
            }
            <Col xs={12} wrap="nowrap" align="center">
                {
                    !isEditing ?
                        <Input
                            type="editable"
                            placeholder={placeholder}
                            defaultValue={typeof defaultValue === 'object' ? defaultValue.label : defaultValue}
                            onClick={() => { setIsEditing(true) }}
                        /> :

                        type === 'editable-select' ?
                            <Select  {...{
                                ...inputProps,
                                options: props.options,
                                defaultMenuIsOpen: true,
                                defaultValue: typeof defaultValue === 'object' ? defaultValue : undefined,
                                onSelect: (e) => {
                                    console.log(e)
                                    setVal(e)
                                }
                            }} /> :

                            <Input  {...{
                                ...inputProps,
                                autoFocus: true,
                                type: type === 'editable-text' ? "text" : "number",
                                defaultValue: typeof defaultValue !== 'object' ? defaultValue : undefined,
                                onChange: setVal,
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        onConfirm && onConfirm(val)
                                    }
                                }
                            }} />
                }
                {
                    isEditing &&
                    <>
                        <Spacer />
                        <Icon variant="checkmark" width={28} color={palette.success2} pointer onClick={() => { onConfirm && onConfirm(val) }} />
                        <Spacer />
                        <Icon variant="close" width={22} color={palette.error2} pointer onClick={() => setIsEditing(false)} />
                        <Spacer />
                    </>
                }
            </Col>
            {
                props.errorMessage && <>
                    <Spacer size={8} />
                    <S variant="error">{props.errorMessage}</S>
                </>
            }
        </Col>

    return render()
}


const PasswordFormInput = (props: PasswordFormInputProps) =>
    <Row>
        <Col>
            {
                props.children &&
                <P medium variant="secondary" mb={8}>{props.children}</P>
            }
        </Col>
        <Col xs={12} direction='column'>
            <Input  {...{ ...props, error: props.error || (props.errorMessage?.length ?? 0) > 0 }} />
            {
                props.errorMessage && <>
                    <Spacer size={8} />
                    <S variant="error">{props.errorMessage}</S>
                </>
            }
        </Col>
    </Row>


const RadioFormInput = (props: RadioFormInputProps) => {

    let n = 0, id = UUID.create()

    const isVertical = props.vertical

    return <Col wrap="wrap">
        {
            props.children &&
            <Col xs={12} pb={12}>
                <P medium variant="secondary">{props.children}</P>
            </Col>
        }
        {
            isVertical ?
                <Row mr={-16 as SpacerValue}>
                    {
                        props.option.map(x =>
                            <Col xs={"content"} key={id + n++} pr={16 as SpacerValue}>
                                <Radio name={id} checked={props.value === x} onChange={(e) => { e && props.onSelected && props.onSelected(x) }}>{x}</Radio>
                            </Col>
                        )
                    }
                </Row>
                :
                <Col wrap="wrap" mb={-10 as SpacerValue}>
                    {
                        props.option.map(x =>
                            <Col xs={12} key={id + n++} pb={10 as SpacerValue}>
                                <Radio name={id} checked={props.value === x} onChange={(e) => { e && props.onSelected && props.onSelected(x) }}>{x}</Radio>
                            </Col>
                        )
                    }
                </Col>
        }
        {
            props.errorMessage && <Col xs={12}>
                <Spacer size={8} />
                <S variant="error">{props.errorMessage}</S>
            </Col>
        }
    </Col>
}



const CheckboxFormInput = (props: CheckboxFormInputProps) =>
    <Col direction='column'>
        <Row align="center">
            <Checkbox {...props} />
            <Spacer size={8} />
            <P medium>{props.children}</P>
        </Row>
        {
            props.errorMessage && <>
                <Spacer size={8} />
                <S variant="error">{props.errorMessage}</S>
            </>
        }
    </Col>


const SelectFormInput = (props: SelectFormInputProps) =>
    <Col direction='column'>
        {
            props.children &&
            <P medium variant="secondary" mb={8}>{props.children}</P>
        }
        <Select  {...{ ...props, error: (props.errorMessage?.length ?? 0) > 0 }} />
        {
            props.errorMessage && <>
                <Spacer size={8} />
                <S variant="error">{props.errorMessage}</S>
            </>
        }
    </Col>


const DateFormInput = (props: DateFormInputProps) =>
    <Col direction='column'>
        {
            props.children &&
            <P medium variant="secondary" mb={8}>{props.children}</P>
        }
        <DateInput  {...{ ...props, error: (props.errorMessage?.length ?? 0) > 0 }} />
        {
            props.errorMessage && <>
                <Spacer size={8} />
                <S variant="error">{props.errorMessage}</S>
            </>
        }
    </Col>
