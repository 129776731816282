import { useDispatch, useSelector } from "react-redux";
import { Col, Icon, PageHorizzontalSpacer, PageVerticalSpacer, SidebarBlock, Spacer } from "..";
import { REDUX_STATE, setSidebar } from "../../redux";
import { DemoRoutes } from "../../routes";

// export const SidebarSize = 120
export const Sidebar = (props: { onPage: string }) => {

    const { onPage } = props
    const dispatch = useDispatch()
    const { index, system, ledger, os } = DemoRoutes
    const { palette, collapsedSidebar } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col xs={collapsedSidebar ? "content" : undefined} min_w={collapsedSidebar ? '20px' : "240px"} max_w={collapsedSidebar ? undefined : "240px"} h="100%" wrap="nowrap" br={`1px solid ${palette.gray2}`} style={{ transition: 'min-width 250ms ease-out' }}>
            <PageHorizzontalSpacer />
            <Col xs={collapsedSidebar ? "content" : undefined}>
                <PageVerticalSpacer />

                {
                    collapsedSidebar &&
                    <>
                        <Col xs="content" wrap="nowrap">
                            <Spacer size={8} />
                            <Icon width={19} variant="collapse" rotate={180} color={palette.gray5} onClick={() => dispatch(setSidebar(false))} pointer />
                            <Spacer size={8} />
                        </Col>
                        <PageVerticalSpacer />
                    </>
                }
                <SidebarBlock {...{
                    title: "System",
                    items: [
                        {
                            name: 'Overview',
                            icon: 'analytics',
                            url: index,
                            active: onPage === index
                        },
                        {
                            name: 'Stripe',
                            icon: 'stripe',
                            url: system.stripeCard,
                            open: onPage === system.stripeCard || onPage === system.stripeBancontact || onPage === system.stripeAlipay || onPage === system.stripeWechatPay || onPage === system.stripeEps || onPage === system.stripeSofort || onPage === system.stripeP24 || onPage === system.stripeOxxo || onPage === system.stripeGooglePay || onPage === system.stripeApplePay || onPage === system.stripeGiroPay || onPage === system.stripeIdeal,
                            sub: [
                                { name: 'Card', url: system.stripeCard, active: onPage === system.stripeCard, icon: 'card', iconSize: 18 },
                                { name: 'Bancontact', url: system.stripeBancontact, active: onPage === system.stripeBancontact, icon: 'bancontact', iconSize: 17 },
                                { name: 'Alipay', url: system.stripeAlipay, active: onPage === system.stripeAlipay, icon: 'alipay' },
                                { name: 'Wechat pay', url: system.stripeWechatPay, active: onPage === system.stripeWechatPay, icon: 'wechatpay' },
                                { name: 'Eps', url: system.stripeEps, active: onPage === system.stripeEps, icon: 'eps' },
                                { name: 'Sofort', url: system.stripeSofort, active: onPage === system.stripeSofort, icon: 'sofort' },
                                { name: 'P24', url: system.stripeP24, active: onPage === system.stripeP24, icon: 'p24' },
                                { name: 'Oxxo', url: system.stripeOxxo, active: onPage === system.stripeOxxo, icon: 'oxxo' },
                                { name: 'Google pay', url: system.stripeGooglePay, active: onPage === system.stripeGooglePay, icon: 'googlepay', iconSize: 22 },
                                { name: 'Apple pay', url: system.stripeApplePay, active: onPage === system.stripeApplePay, icon: 'applepay', iconSize: 22 },
                                { name: 'Giropay', url: system.stripeGiroPay, active: onPage === system.stripeGiroPay, icon: 'giropay', iconSize: 16 },
                                { name: 'Ideal', url: system.stripeIdeal, active: onPage === system.stripeIdeal, icon: 'ideal', iconSize: 20 },
                            ]
                        },
                        {
                            name: 'Paypal',
                            icon: 'paypal',
                            iconSize: 17,
                            url: system.paypal,
                            active: onPage === system.paypal
                        }
                    ]
                }} />
                {!collapsedSidebar && <Spacer size={24} />}
                <SidebarBlock {...{
                    title: "Ledger",
                    items: [
                        {
                            name: 'Payments',
                            icon: 'dollar',
                            url: ledger.payins,
                            open: onPage === ledger.payins || onPage === ledger.payinDetails || onPage === ledger.payouts || onPage === ledger.disputes || onPage === ledger.disputeDetails || onPage === ledger.fees,
                            sub: [
                                { name: 'Payins', url: ledger.payins, active: onPage === ledger.payins || onPage === ledger.payinDetails, icon: 'payin', iconSize: 14 },
                                { name: 'Payouts', url: ledger.payouts, active: onPage === ledger.payouts, icon: 'payout', iconSize: 14 },
                                { name: 'Disputes', url: ledger.disputes, active: onPage === ledger.disputes || onPage === ledger.disputeDetails, icon: 'danger-filled', iconSize: 17 },
                                { name: 'Fees', url: ledger.fees, active: onPage === ledger.fees, icon: 'percentage', iconSize: 18 },
                            ]
                        },
                        {
                            name: 'Customers',
                            icon: 'users',
                            iconSize: 17,
                            url: ledger.sellers,
                            open: onPage === ledger.sellers || onPage === ledger.sellerDetails || onPage === ledger.buyers || onPage === ledger.buyerDetails,
                            sub: [
                                { name: 'Sellers', url: ledger.sellers, active: onPage === ledger.sellers || onPage === ledger.sellerDetails, icon: 'seller' },
                                { name: 'Buyers', url: ledger.buyers, active: onPage === ledger.buyers || onPage === ledger.buyerDetails, icon: 'buyer' }
                            ]
                        }
                    ]
                }} />
                {!collapsedSidebar && <Spacer size={24} />}
                <SidebarBlock {...{
                    title: "OS",
                    items: [
                        {
                            name: 'Docs',
                            icon: 'docs',
                            iconSize: 17,
                            url: os.docs,
                            active: onPage === os.docs
                        },
                        {
                            name: 'Api',
                            icon: 'api',
                            url: os.apiKeys,
                            open: onPage === os.apiKeys || onPage === os.apiLogs,
                            sub: [
                                { name: 'Keys', url: os.apiKeys, active: onPage === os.apiKeys, icon: 'key', iconSize: 17 },
                                { name: 'Logs', url: os.apiLogs, active: onPage === os.apiLogs, icon: 'logs' }
                            ]
                        },
                        {
                            name: 'Webhook',
                            icon: 'webhook',
                            url: os.webhookChannel,
                            open: onPage === os.webhookChannel || onPage === os.webhookLogs,
                            sub: [
                                { name: 'Channels', url: os.webhookChannel, active: onPage === os.webhookChannel, icon: 'channel', iconSize: 17 },
                                { name: 'Logs', url: os.webhookLogs, active: onPage === os.webhookLogs, icon: 'logs' }
                            ]
                        }
                    ]
                }} />
            </Col>
            <PageHorizzontalSpacer />
        </Col>

    return render()
}
