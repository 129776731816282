import styled from "styled-components"
import { Col, Container, CountryFlag, H5, MethodLogo, P, ProviderLogo, Row, S, Spacer } from ".."
import { Countries, CountryCode, PSP, PSP_ONBOARDING_STATE, PSPs, PaymentMethod, PspOnboardingState } from "../../utilities"

interface DefaultDescriptionBlockProps { type?: undefined, data: [string, string] }
interface PspStateDescriptionBlockProps { type: 'psp-state', data: [PSP, PSP_ONBOARDING_STATE] }
interface PspDescriptionBlockProps { type: 'psp', data: PaymentMethod }
interface MethodPspDescriptionBlockProps { type: 'method-psp', data: PaymentMethod }
interface MethodPspLargeDescriptionBlockProps { type: 'method-psp-large', data: PaymentMethod }
interface CountryDescriptionBlockProps { type: 'country', data: CountryCode }
interface AddressDescriptionBlockProps { type: 'address', data: [CountryCode, string] }

export type DescriptionBlockProps = DefaultDescriptionBlockProps | PspStateDescriptionBlockProps | PspDescriptionBlockProps | MethodPspDescriptionBlockProps | MethodPspLargeDescriptionBlockProps | CountryDescriptionBlockProps | AddressDescriptionBlockProps

export const DescriptionBlock = (props: DescriptionBlockProps) => {

    const { type, data } = props

    const method = type === "method-psp" || type === "method-psp-large" || type === "psp" ? data : undefined
    const provider = method ? PSPs[method.provider] : type === "psp-state" ? PSPs[data[0]] : undefined
    const country = type === "country" ? data : type === "address" ? data[0] : undefined
    const address = type === "address" ? data[1] : undefined

    const renderDefault = () => type === undefined ?
        <Col xs="content" >
            <P medium nowrap variant="dark">
                {data[0]}
            </P>
            <Spacer size={2} />
            <P regular nowrap variant="secondary">
                {data[1]}
            </P>
        </Col> : <></>


    const logo = () =>
        (type === "psp" || type === "psp-state") && provider &&
        <ProviderLogo variant={provider.value} />
        ||
        type === "method-psp" && method &&
        <SmallContainer>
            <MethodLogo variant={method.value} />
        </SmallContainer>
        ||
        type === "method-psp-large" && method &&
        <Col min_w="36px" max_w="36px">
            <MethodLogo variant={method.value} />
        </Col>
        ||
        (type === "address" || type === "country") && country &&
        <Col min_w="36px" max_w="36px" px={0} justify="center" align="center">
            <CountryFlag variant={country} width={type === "country" ? 28 : undefined} />
        </Col>
        ||
        <></>

    const renderWithLogo = () =>
        <Col xs="content" align="center">

            {logo()}

            <Spacer w size={12} />

            <Col xs="content" px={0}>
                {
                    type === "address" ?
                        <P medium nowrap variant="dark">{country ? Countries[country] ?? "" : ""}</P>
                        :
                        type === "psp" || type === "method-psp" ?
                            <S medium variant="dark" nowrap>{provider ? provider.display_name : ""}</S>
                            :
                            <P medium nowrap variant="dark">
                                {provider ? provider.display_name : ""}
                            </P>
                }
                <Spacer size={2} />
                {
                    type === "psp" || type === "method-psp" ?
                        <S regular variant="secondary" nowrap>{method?.display_name ?? ""}</S> :

                        <P regular nowrap variant="secondary">
                            {
                                type === "address" ? address :
                                    type === "psp-state" ? PspOnboardingState(data[1]) :
                                        method ? method.display_name : ""
                            }
                        </P>
                }
            </Col>
        </Col>


    return <Container>
        <Row>
            {
                type === undefined ?
                    renderDefault() : renderWithLogo()
            }
        </Row>
    </Container>
}


const SmallContainer = styled.div`

    svg {
        max-width: 28px;
        max-height: 28px;
    }

`
