import { ReactNode, useEffect, useState } from "react";
import { default as ReactModal, default as _Modal } from 'react-modal';
import { useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { Col, Icon, Spacer } from "..";
import { PaletteProps } from "../../design_system";
import { REDUX_STATE } from "../../redux";
import { UUID } from "../../utilities";

export const ModalPage = (props: { children: [ReactNode, ReactNode], toClose?: boolean, href?: string }) => {

    const { children, toClose, href } = props
    const { isDark, palette } = useSelector(REDUX_STATE).theme

    const [isOpen, setIsOpen] = useState(false)

    ReactModal.setAppElement('#root');
    let id = UUID.create()


    useEffect(() => { toClose && setIsOpen(false) }, [toClose])

    const render = () => <>
        {
            <div onClick={() => setIsOpen(!isOpen)}>
                {children[0]}
            </div>
        }
        <_Modal id={id} className="modal-page" isOpen={isOpen} shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true} closeTimeoutMS={250} onRequestClose={() => setIsOpen(false)} style={{
            overlay: {
                zIndex: 10,
                background: `${palette.white}D9`,
                overflowY: "hidden",
                paddingTop: '0px'
            },
            content: {
                position: 'fixed',
                border: "unset",
                outline: "unset",
                background: isDark ? palette.gray2 : palette.white,
                boxShadow: `0px 1px 3px rgba(${isDark ? "255,255, 255, .08" : "0, 0, 0, 0.12"})${isDark ? "" : ", 0px 2px 8px -1px rgba(0, 0, 0, 0.05)"}`,
                top: '0px',
                marginLeft: `auto`,
                marginRight: `0px`,
                overflowY: 'auto',
                padding: `32px 32px`,
                width: '50%',
                minHeight: '100vh',
                maxHeight: '100vh',
                minWidth: '600px'
            }
        }}>
            <Col ml={-16} mt={-24} justify="start">
                <ModalControl palette={palette}>
                    <Icon variant="slide" width={18} color={palette.gray3} onClick={() => setIsOpen(false)} pointer />
                </ModalControl>
                {
                    href && <>
                        <Spacer size={4} />
                        <a href={href}>
                            <ModalControl palette={palette}>
                                <Icon variant="expand" width={18} color={palette.gray3} />
                            </ModalControl>
                        </a>
                    </>
                }
            </Col>
            <Col ml={-16} h='100%' p={4} pt={24} m={-4}>
                {children[1]}
            </Col>
            <NoScroll />
        </_Modal>
    </>

    return render()
}

const ModalControl = styled.div<{ palette: PaletteProps }>`
    padding: 2px;
    
    &:hover {
        border-radius: 4px;
        background-color: ${p => p.palette.gray1};
    }
`

export const NoScroll = createGlobalStyle`
    html,
    body {
        overflow-y: hidden ;
    }
`