import { useSelector } from "react-redux"
import { Button, Col, H5, Icon, Input, PageHorizzontalSpacer, S, Spacer, ThemeSelector } from "../.."
import { REDUX_STATE } from "../../../redux"


export const NavbarControls = () => {

    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col wrap="nowrap" align="center" justify="between">
            <Input type="search" style={{ minHeight: '56px', }} />

            <Col xs="content" wrap="nowrap" align="center">
                <Button variant="alternate" smooth>
                    <Icon variant="chat" color={palette.primary2} width={17} />
                    <Spacer size={12} w />
                    Get Support - 24/7
                </Button>
                <Spacer size={40} w />
                <ThemeSelector />
                <Spacer size={24} w />
                <Icon variant="info" color={palette.gray5} width={16} />
                <Spacer size={24} w />
                <Icon variant="bell" color={palette.gray5} width={16} />
                <Spacer size={24} w />
                <Icon variant="user" color={palette.gray5} width={16} />
                <Spacer size={40} w />
                <Col>
                    <H5 regular variant="dark">Tipeeestream</H5>
                    <S regular variant="light">Marketplace</S>
                </Col>
                <Spacer size={12} w />
                <Icon variant="arrow" color={palette.gray3} width={15} height={12} />
                <PageHorizzontalSpacer />
            </Col>
        </Col>

    return render()
}
