import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { DarkPalette, LightPalette, PaletteProps } from "../../design_system/palette"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"


export enum THEME {
    light = 0,
    dark = 1
}

export interface themeState {
    isDark: boolean
    palette: PaletteProps
    negative: PaletteProps
    collapsedSidebar: boolean
}

const initialState: themeState = {
    isDark: false,
    palette: LightPalette,
    negative: LightPalette,
    collapsedSidebar: false
}

export const themeSlice = createSlice({
    name: 'themeSlice',
    initialState,
    reducers: {

        changeTheme: (state: themeState, action: PayloadAction<boolean>) => {
            const isLightTheme = !action.payload
            return {
                ...state,
                isDark: !isLightTheme,
                palette: isLightTheme ? LightPalette : DarkPalette,
                negative: isLightTheme ? DarkPalette : LightPalette
            }
        },

        setSidebar: (state: themeState, action: PayloadAction<boolean>) => {
            return {
                ...state,
                collapsedSidebar: action.payload
            }
        }

    }
})

export const { changeTheme, setSidebar } = themeSlice.actions

export const themeReducer = persistReducer(
    {
        key: 'theme',
        storage,
    },
    themeSlice.reducer
)

