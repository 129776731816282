import { Dashboard } from "../.."
import { DemoRoutes } from "../../../routes"

export const Docs = () => {


        const Render = () =>
                <Dashboard
                        pageTitle="Docs"
                        onPage={DemoRoutes.os.docs}
                        breadcrumb={["OS", "Docs"]}>
                </Dashboard>


        return Render()
}

