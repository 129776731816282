import { Row as _Row, RowProps as _RowProps } from 'react-grid-system';
import styled from 'styled-components';
import { Spacer } from '../..';
import { Overflow, Position, SpacerValue } from '../../../design_system';

export interface RowProps extends _RowProps {
    bg?: string

    pointer?: boolean
    transition?: string

    overflow?: Overflow
    overflowX?: Overflow
    overflowY?: Overflow

    position?: Position
    left?: string
    right?: string
    top?: string
    bottom?: string

    min_w?: string
    w?: string
    max_w?: string

    min_h?: string
    h?: string
    max_h?: string

    b?: string
    bx?: string
    by?: string
    bl?: string
    br?: string
    bt?: string
    bb?: string

    gutterHeight?: SpacerValue
    gutterWidth?: SpacerValue

    p?: SpacerValue
    px?: SpacerValue
    py?: SpacerValue
    pl?: SpacerValue
    pr?: SpacerValue
    pt?: SpacerValue
    pb?: SpacerValue

    m?: SpacerValue
    mx?: SpacerValue
    my?: SpacerValue
    ml?: SpacerValue
    mr?: SpacerValue
    mt?: SpacerValue
    mb?: SpacerValue

}

export const Row = (props: RowProps) => {
    var style: React.CSSProperties = props.style ?? {}

    // Default
    style.marginRight = style.marginRight ? style.marginRight : 0
    style.marginLeft = style.marginLeft ? style.marginLeft : 0

    props.bg && (style.backgroundColor = props.bg)

    props.pointer && (style.cursor = 'pointer')
    props.transition && (style.transition = props.transition)

    props.overflow && (style.overflow = props.overflow)
    props.overflowX && (style.overflowX = props.overflowX)
    props.overflowY && (style.overflowY = props.overflowY)

    props.position && (style.position = props.position)
    props.left && (style.left = props.left)
    props.right && (style.right = props.right)
    props.top && (style.top = props.top)
    props.bottom && (style.bottom = props.bottom)

    props.min_w !== undefined && (style.minWidth = props.min_w)
    props.w !== undefined && (style.width = props.w)
    props.max_w !== undefined && (style.maxWidth = props.max_w)

    props.min_h !== undefined && (style.minHeight = props.min_h)
    props.h !== undefined && (style.height = props.h)
    props.max_h !== undefined && (style.maxHeight = props.max_h)

    props.b && (style.border = props.b)
    props.bx && (style.borderLeft = props.bx) && (style.borderRight = props.bx)
    props.by && (style.borderTop = props.by) && (style.borderBottom = props.by)
    props.bt && (style.borderTop = props.bt)
    props.bb && (style.borderBottom = props.bb)
    props.bl && (style.borderLeft = props.bl)
    props.br && (style.borderRight = props.br)


    props.p !== undefined && (style.padding = `${props.p}px`)
    props.px !== undefined && (style.paddingLeft = `${props.px}px`) && (style.paddingRight = `${props.px}px`)
    props.py !== undefined && (style.paddingTop = `${props.py}px`) && (style.paddingBottom = `${props.py}px`)
    props.pt !== undefined && (style.paddingTop = `${props.pt}px`)
    props.pb !== undefined && (style.paddingBottom = `${props.pb}px`)
    props.pl !== undefined && (style.paddingLeft = `${props.pl}px`)
    props.pr !== undefined && (style.paddingRight = `${props.pr}px`)

    props.m !== undefined && (style.margin = `${props.m}px`)
    props.mx !== undefined && (style.marginLeft = `${props.mx}px`) && (style.marginRight = `${props.mx}px`)
    props.my !== undefined && (style.marginTop = `${props.my}px`) && (style.marginBottom = `${props.my}px`)
    props.mt !== undefined && (style.marginTop = `${props.mt}px`)
    props.mb !== undefined && (style.marginBottom = `${props.mb}px`)
    props.ml !== undefined && (style.marginLeft = `${props.ml}px`)
    props.mr !== undefined && (style.marginRight = `${props.mr}px`)

    props.gutterHeight !== undefined && (style.rowGap = `${props.gutterHeight}px`)

    if (props.gutterWidth !== undefined) {
        style.marginLeft = `${(props.ml ?? props.mx ?? props.m ?? 0) - props.gutterWidth / 2}px`
        style.marginRight = `${(props.mr ?? props.mx ?? props.m ?? 0) - props.gutterWidth / 2}px`
    }

    const Render = () =>
        <RowStyled
            id={props.id}
            style={style}
            wrap={props.wrap}
            align={props.align}
            justify={props.justify}
            onClick={props.onClick}
            onMouseUp={props.onMouseUp}
            direction={props.direction}
            onMouseDown={props.onMouseDown}
            gutterWidth={props.gutterWidth}>
            {props.children}
        </RowStyled>


    return Render()
}

export const RowStyled = styled(_Row)``