import axios, { AxiosError, AxiosResponse } from "axios"
import { ApiDomain } from "../../routes"
import { ErrorCodes } from "./errors/codes"


axios.defaults.baseURL = ApiDomain
axios.defaults.withCredentials = true


export type StatusCodeResult = number

export interface ApiResponse<T> {
    data?: T
    error?: ApiErrors
}

export interface ApiErrors { [key: string]: string }

export const handleEmptyResponse = (res: AxiosResponse): ApiResponse<StatusCodeResult> => {
    const result: ApiResponse<StatusCodeResult> = {}
    if (res.status !== 200) {
        result.error = { generic: ErrorCodes.server_error }
        result.data = res.status as StatusCodeResult
    }

    result.data = res.data
    return result
}

export const handleResponse = <T>(res: AxiosResponse<T, any>): ApiResponse<T> => {
    const result: ApiResponse<T> = {}

    if (res.status !== 200) {
        result.error = { generic: ErrorCodes.server_error }
        return result;
    }

    result.data = res.data
    return result
}


export const handleEmptyBadResponse = (err: Error | AxiosError<ApiErrors>): ApiResponse<StatusCodeResult> => {
    const result: ApiResponse<StatusCodeResult> = {}

    if (!axios.isAxiosError(err) || !err.response) {
        result.error = { generic: ErrorCodes.server_error }
        result.data = 500 as StatusCodeResult
        return result;
    }

    if (err.response.status === 401) {
        // window.location.href = DemoRoutes.loginWithReturnUrl
    }

    result.error = (err.response.data ?? { generic: ErrorCodes.server_error }) as ApiErrors
    result.data = err.response.status as StatusCodeResult
    return result
}

export const handleBadResponse = <T>(err: Error | AxiosError<ApiErrors>): ApiResponse<T> => {
    const result: ApiResponse<T> = {}

    if (!axios.isAxiosError(err) || !err.response) {
        result.error = { generic: ErrorCodes.server_error }
        return result;
    }

    if (err.response.status === 401) {
        // window.location.href = DemoRoutes.loginWithReturnUrl
    }

    result.error = (err.response.data ?? { generic: ErrorCodes.server_error }) as ApiErrors
    return result
}


