import { PayloadAction } from "@reduxjs/toolkit";
import { put, select } from "redux-saga/effects";
import { Payins } from "../../fake_data";
import { State, setAnalyticsData } from "../../redux";
import { PAYIN_STATE, PAYMENT_METHOD, PayinProps } from "../../utilities";

export function* loadAnalyticsDataSaga(action: PayloadAction<PAYMENT_METHOD | undefined>) {

    try {

        const method = action.payload

        const P: PayinProps[] = method === undefined ? Payins : Payins.filter(x => x.method.value === method)

        const { analytics: { period: { start, end } } }: State = yield select();

        const payins = {
            current: P.filter(x => x.date >= start && x.date < end),
            last: P.filter(x => x.date >= (start - (end - start)) && x.date < start)
        }

        const succCurrPayins = payins.current.filter(x => x.state === PAYIN_STATE.SUCCEEDED || x.state === PAYIN_STATE.PENDING || x.state === PAYIN_STATE.REFUNDED || x.state === PAYIN_STATE.DISPUTED)
        const lastCurrPayins = payins.last.filter(x => x.state === PAYIN_STATE.SUCCEEDED || x.state === PAYIN_STATE.PENDING || x.state === PAYIN_STATE.REFUNDED || x.state === PAYIN_STATE.DISPUTED)

        const sellers = {
            current: Array.from(new Set(succCurrPayins.map(x => x.seller))),
            last: Array.from(new Set(lastCurrPayins.map(x => x.seller)))
        }
        const buyers = {
            current: Array.from(new Set(succCurrPayins.map(x => x.buyer))),
            last: Array.from(new Set(lastCurrPayins.map(x => x.buyer)))
        }

        yield put(setAnalyticsData({ payins, sellers, buyers }))

    } catch (error) {

    }

}
