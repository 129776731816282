import { CountryCode, PSP, PspProps } from ".."
import { Fees, Payins } from "../../fake_data"
import { FeesLedgerData } from "../../redux"
import { PAYMENT_METHOD_STATE, PaymentMethod } from "../paymentMethods"


export enum FEES_LEDGER_COLS {
    GATEWAY,
    AMOUNT,
    BIOS_FEE,
    INTERCHANGE_FEE,
    COLLECTED_FEE,
    DATE
}

export interface FeesLedgerOrder {
    by: FEES_LEDGER_COLS,
    ascendent: boolean
}

export interface FeesLedgerFilter {
    state: boolean
    type: PSP
}

export interface FeesLedgerRowProps {
    payinId: string
    provider: PspProps
    method: PaymentMethod
    amount: number
    biosFee: number
    biosFeeDescription: string
    interchangeFee: number
    interchangeFeeDescription: string
    collectedFee: number
    collectedFeeDescription: string
    date: string
}

export class FeesLedgerEngine {

    static getValues(state: FeesLedgerData): FeesLedgerRowProps[] {
        const rows = this.filterValues(state.fees, state.filters)
        const reoredered = this.getOrderedValues(rows, state.order)
        return reoredered
    }

    static filterValues(fees: FeesLedgerRowProps[], applied: FeesLedgerFilter[]) {
        let filtered: FeesLedgerRowProps[] = []
        applied.some(x => x.type === PSP.PAYPAL && x.state) && (filtered = filtered.concat(fees.filter(x => x.provider.value === PSP.PAYPAL)))
        applied.some(x => x.type === PSP.STRIPE && x.state) && (filtered = filtered.concat(fees.filter(x => x.provider.value === PSP.STRIPE)))
        return filtered
    }

    static getOrderedValues(rows: FeesLedgerRowProps[], order: FeesLedgerOrder): FeesLedgerRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {
            case FEES_LEDGER_COLS.GATEWAY:
                result = result.sort((a, b) => a.provider.display_name < b.provider.display_name ? factor * -1 : factor * 1)
                break

            case FEES_LEDGER_COLS.AMOUNT:
                result = result.sort((a, b) => a.amount < b.amount ? factor * -1 : factor * 1)
                break

            case FEES_LEDGER_COLS.BIOS_FEE:
                result = result.sort((a, b) => a.biosFee < b.biosFee ? factor * -1 : factor * 1)
                break


            case FEES_LEDGER_COLS.INTERCHANGE_FEE:
                result = result.sort((a, b) => a.interchangeFee < b.interchangeFee ? factor * -1 : factor * 1)
                break

            case FEES_LEDGER_COLS.COLLECTED_FEE:
                result = result.sort((a, b) => a.collectedFee < b.collectedFee ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => new Date(a.date) > new Date(b.date) ? factor * -1 : factor * 1)
                break
        }
        return result
    }

    static getRows(start: number, end: number): FeesLedgerRowProps[] {

        const payins = Payins
            .filter(({ date }) =>
                date >= start &&
                date <= end
            )
            .sort((a, b) =>
                a.date - b.date
            )

        var result: FeesLedgerRowProps[] = payins.map(s => {

            const fee = Fees[s.method.value][s.seller.country as CountryCode] ?? {
                country: s.seller.country, biosFeeFix: 0.09, biosFeePerc: 0.35, interchangeFee: { min: 0.2, max: 0.4 }, platformFee: 2.4, state: PAYMENT_METHOD_STATE.ENABLED
            }

            const factor = new Date(s.date).getDate() / 31;
            const interchangeFee = fee?.interchangeFee === undefined ? 0 : fee.interchangeFee.min + (factor * (fee.interchangeFee.max - fee.interchangeFee.min))

            return {
                payinId: s.id,
                provider: s.provider,
                method: s.method,
                amount: s.amount,
                biosFee: fee.biosFeeFix + (s.amount * fee.biosFeePerc / 100),
                biosFeeDescription: `$${fee.biosFeeFix.toFixed(2)} + ${(fee.biosFeePerc).toFixed(2)}%`,
                interchangeFee: s.amount * interchangeFee / 100,
                interchangeFeeDescription: `${interchangeFee.toFixed(2)}%`,
                collectedFee: s.amount * fee.platformFee / 100,
                collectedFeeDescription: `${fee.platformFee.toFixed(2)}%`,
                date: new Date(s.date).toDateString(),
            }
        })

        return result
    }
}