import { useDispatch, useSelector } from "react-redux"
import { Col, AnalyticsWidgets, FraudsRateChart, PeriodSelector, Row, Spacer, Spacers, VolumeSellersChart } from ".."
import { SpacerValue } from "../../design_system"
import { REDUX_STATE, setAnalyticsPeriod } from "../../redux"

export const Analytics = () => {

    const dispatch = useDispatch()
    const { analytics: { period: { start, end } } } = useSelector(REDUX_STATE)

    const Render = () =>
        <>
            <Col xs={12}>
                <Col xs={12} justify="end" position="absolute" bottom="100%" pb={Spacers.pageVertical as SpacerValue}>
                    <PeriodSelector start={new Date(start)} end={new Date(end)} onApplied={(s, e) => dispatch(setAnalyticsPeriod({ start: s, end: e }))} />
                </Col>
                <AnalyticsWidgets />
            </Col>

            <Spacer size={24} />

            <Row>
                <Col>
                    <VolumeSellersChart />
                </Col>
                <Spacer size={16} />
                <Col xs={3} min_w="290px">
                    <FraudsRateChart />
                </Col>
            </Row>
        </>

    return Render()
}





