import { useSelector } from "react-redux"
import { Col, Radio, S, Spacer } from ".."
import { REDUX_STATE } from "../../redux"


export const Filter = (props: { children?: string, active?: boolean, onClick: () => void }) => {

    const { children, active, onClick } = props
    const { isDark, palette } = useSelector(REDUX_STATE).theme

    return <Col xs="content" px={16} py={8} pointer wrap="nowrap" align="center" bg={palette[isDark ? "gray2" : "white"]} b_rad="6px" b={`1px solid ${palette.gray2}`} onClick={onClick}>
        <S medium nowrap variant={active ? "primary" : "secondary"}>{children}</S>
        <Spacer w size={16} />
        <Radio checked={active} size={14} />
    </Col>

}

