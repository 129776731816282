import { useDispatch, useSelector } from "react-redux"
import { BiosLogo, Col, Icon, PageHorizzontalSpacer, Spacer } from "../.."
import { REDUX_STATE, setSidebar } from "../../../redux"


export const NavbarLogo = () => {

    const dispatch = useDispatch()
    const { palette, collapsedSidebar } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col xs={collapsedSidebar ? "content" : undefined} max_w={collapsedSidebar ? undefined : '240px'} min_w={collapsedSidebar ? '20px' : '240px'} h="28px" wrap="nowrap" br={`1px solid ${palette.gray2}`} style={{ transition: 'min-width 250ms ease-out' }}>
            <PageHorizzontalSpacer />
            <Col justify={collapsedSidebar ? "start" : "between"} align="center">
                {
                    collapsedSidebar ?

                        <>
                            <Spacer size={8} />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <BiosLogo short />
                            </div>
                            <Spacer size={8} />
                            <PageHorizzontalSpacer />
                        </>
                        :
                        <>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <BiosLogo />
                            </div>
                            <Col xs="content" align="center">
                                <Icon width={20} variant="collapse" color={palette.gray5} onClick={() => dispatch(setSidebar(true))} pointer />
                                <Spacer size={16} />
                            </Col>
                        </>
                }
            </Col>
        </Col>

    return render()
}
