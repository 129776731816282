import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Badge, Col, Container, H2, Spacer } from ".."
import { Percentage } from "../../design_system"
import { REDUX_STATE } from "../../redux"



export const Donut = (props: { value: Percentage, growth: Percentage, maxValue?: Percentage }) => {

        const { growth } = props
        const { palette } = useSelector(REDUX_STATE).theme

        const maxValue = props.maxValue ?? 100
        const value = Math.min((props.value / maxValue) * 100, 100)

        const tot = 256
        const [degrees, setDegrees] = useState(tot)

        useEffect(() => {
                window.setTimeout(() => setDegrees(tot - (tot * value / 100)), 1)
        }, [value])

        const ref = useRef<HTMLDivElement>(null)
        const [size, setSize] = useState(ref.current?.scrollWidth)
        useEffect(() => {
                const contentEl = ref?.current;
                if (!contentEl) return;

                const observer = new ResizeObserver(() => {
                        setSize(contentEl.scrollWidth)
                });

                observer.observe(contentEl);

                return () => {
                        observer.disconnect()
                }
        }, [])


        const render = () =>
                <Container>
                        <div ref={ref} style={{ maxWidth: '220px', margin: 'auto' }}>
                                <Col xs="content" position="absolute" zIndex={2}>
                                        <svg viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: `${size}px`, height: `${size}px` }}>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M101 202C156.781 202 202 156.781 202 101C202 45.2192 156.781 0 101 0C45.2192 0 0 45.2192 0 101C0 156.781 45.2192 202 101 202ZM1 101C1 45.7715 45.7715 1 101 1C156.228 1 201 45.7715 201 101C201 123.08 193.844 143.489 181.724 160.034H177.205L174.5 157.84L174.233 155.028C185.399 139.918 192 121.23 192 101C192 50.7421 151.258 10 101 10C50.7421 10 10 50.7421 10 101C10 121.23 16.6012 139.918 27.7671 155.028L27.5 157.84L24.7955 160.034H20.2761C8.15633 143.489 1 123.08 1 101Z" fill={palette.white} />
                                        </svg>
                                </Col>

                                <Col xs="content" position="absolute" zIndex={1}>
                                        <svg viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(-${degrees}deg)`, width: `${size}px`, height: `${size}px`, transition: 'transform 1s ease-out' }}>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M27.5 157.84L27.7671 155.028L27.7666 153.928C44.3405 176.356 70.9715 190.9 101 190.9C131.027 190.9 157.658 176.356 174.232 153.928L174.233 155.028L174.5 157.84L177.205 160.034H181.724L182.5 160L181.724 160.834C163.529 185.672 134.148 201.8 101 201.8C67.8522 201.8 38.4711 185.672 20.2764 160.834L19.5 160L20.2761 160.034H24.7955L27.5 157.84Z" fill={palette.gray2} />
                                        </svg>
                                </Col>

                                <Col xs="content" position="absolute" zIndex={1}>
                                        <svg viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(-${degrees > 200 ? 200 : degrees}deg)`, width: `${size}px`, height: `${size}px`, transition: 'transform 1s ease-out' }}>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M27.5 157.84L27.7671 155.028L27.7666 153.928C44.3405 176.356 70.9715 190.9 101 190.9C131.027 190.9 157.658 176.356 174.232 153.928L174.233 155.028L174.5 157.84L177.205 160.034H181.724L182.5 160L181.724 160.834C163.529 185.672 134.148 201.8 101 201.8C67.8522 201.8 38.4711 185.672 20.2764 160.834L19.5 160L20.2761 160.034H24.7955L27.5 157.84Z" fill={palette.gray2} />
                                        </svg>
                                </Col>

                                <Col xs="content" position="absolute" zIndex={1}>
                                        <svg viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(-${degrees > 100 ? 100 : degrees}deg)`, width: `${size}px`, height: `${size}px`, transition: 'transform 1s ease-out' }}>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M27.5 157.84L27.7671 155.028L27.7666 153.928C44.3405 176.356 70.9715 190.9 101 190.9C131.027 190.9 157.658 176.356 174.232 153.928L174.233 155.028L174.5 157.84L177.205 160.034H181.724L182.5 160L181.724 160.834C163.529 185.672 134.148 201.8 101 201.8C67.8522 201.8 38.4711 185.672 20.2764 160.834L19.5 160L20.2761 160.034H24.7955L27.5 157.84Z" fill={palette.gray2} />
                                        </svg>

                                </Col>

                                <Col xs="content" position="absolute" zIndex={0} align="center" justify="center">
                                        <svg viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: `${size}px`, height: `${size}px`, transition: 'transform 1s ease-out' }}>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M1 101C1 45.7715 45.7715 1 101 1C156.228 1 201 45.7715 201 101C201 123.08 193.844 143.489 181.724 160.034H177.205L174.5 157.84L174.233 155.028C185.399 139.918 192 121.23 192 101C192 50.7421 151.258 10 101 10C50.7421 10 10 50.7421 10 101C10 121.23 16.6012 139.918 27.7671 155.028L27.5 157.84L24.7955 160.034H20.2761C8.15633 143.489 1 123.08 1 101Z" fill={numberToColorCode(value)} />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M201 101C201 123.08 193.844 143.489 181.724 160.034H177.205L174.5 157.84L174.233 155.028C185.399 139.918 192 121.23 192 101C192 50.7421 151.258 10 101 10C50.7421 10 10 50.7421 10 101C10 121.23 16.6012 139.918 27.7671 155.028L27.5 157.84L24.7955 160.034H20.2761C8.15633 143.489 1 123.08 1 101C1 45.7715 45.7715 1 101 1C156.228 1 201 45.7715 201 101Z" fill={numberToColorCode(value)} />
                                        </svg>
                                        <Col xs="content" position="absolute" justify="center" align="center" direction="column" bottom="20%" zIndex={2} >
                                                <H2 medium center>{props.value.toFixed(2)}%</H2>
                                                <Spacer size={24} />
                                                <Badge variant={growth < 0 ? "success" : growth > 1 ? "error" : "default"}>{`${growth.toFixed(2)}%`}</Badge>
                                                <Spacer size={12} />
                                        </Col>
                                </Col>
                        </div>
                </Container>

        return render()
}

function numberToColorCode(value: number): string {
        const clampedValue = Math.min(100, Math.max(0, value));

        let r: number, g: number, b: number;

        if (clampedValue <= 30) {
                r = Math.floor((clampedValue / 30) * 255);
                g = 255;
        } else if (clampedValue <= 85) {
                r = 255;
                g = 255;
        } else {
                r = 255;
                g = Math.floor(((100 - clampedValue) / 15) * 255);
        }

        b = 0;

        return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function componentToHex(c: number): string {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
}

/// TEST

// const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 |
//         11, 12, 13, 14, 15, 16, 17, 18, 19, 20 |
//         21, 22, 23, 24, 25, 26, 27, 28, 29, 30 |
//         31, 32, 33, 34, 35, 36, 37, 38, 39, 40 |
//         41, 42, 43, 44, 45, 46, 47, 48, 49, 50 |
//         51, 52, 53, 54, 55, 56, 57, 58, 59, 60 |
//         61, 62, 63, 64, 65, 66, 67, 68, 69, 70 |
//         71, 72, 73, 74, 75, 76, 77, 78, 79, 80 |
//         81, 82, 83, 84, 85, 86, 87, 88, 89, 90 |
//         91, 92, 93, 94, 95, 96, 97, 98, 99, 100]

// <Col>
// {
//         arr.map(x =>
//                 <div style={{ width: '50px', height: '5px', background: numberToColorCode(x) }} />
//         )
// }
// </Col>