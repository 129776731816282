import { BuyerAccountDetails, BuyerMethodsDetails, BuyerProfileDetails, Dashboard, Spacer } from "../.."
import { Buyers } from "../../../fake_data"
import { DemoRoutes } from "../../../routes"

export const BuyerDetails = () => {

        const path = window.location.pathname.split('/')
        const id = path[path.length - 1]
        const buyer = Buyers.find(x => x.id === id)

        const Render = () =>
                <Dashboard
                        pageTitle="Details"
                        onPage={DemoRoutes.ledger.buyerDetails}
                        breadcrumb={["Ledger", "Customers", "Buyer"]}>
                        {
                                buyer && <>

                                        <BuyerAccountDetails {...buyer} />
                                        <Spacer size={24} />
                                        <BuyerProfileDetails {...buyer} />
                                        <Spacer size={24} />
                                        <BuyerMethodsDetails {...buyer} />
                                </>
                        }
                </Dashboard>

        return Render()
}

