import { createSlice } from "@reduxjs/toolkit"
import { PAYMENT_METHOD } from "../utilities"


export interface MethodState {
    id: PAYMENT_METHOD
    state: boolean
    countries: string[]
}

const initialState: MethodState[] = [
    {
        id: PAYMENT_METHOD.CARD,
        state: true,
        countries: [
            "au", "at", "be", "bg", "ca", "cy", "cz",
            "dk", "ee", "fi", "fr", "de", "gr", "hk",
            "ro", "es", "se", "ch",
            "gb", "us"
        ] 
    }
]

export const methodsSlice = createSlice({
    name: 'methodsSlice',
    initialState,
    reducers: {

        setMethods: (state: MethodState[]) => {
            return state
        }

    }
})

export const { setMethods } = methodsSlice.actions

export const methodsReducer = methodsSlice.reducer
