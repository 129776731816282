import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Dashboard, PayinsTable, PeriodSelector, Spacer, TableFilters, TablePagination } from "../.."
import { REDUX_STATE, payinsLedgerActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { PSPs, PayinState, PayinsLedgerEngine } from "../../../utilities"

export const PayinsLedger = () => {

        const dispatch = useDispatch()
        useEffect(() => { dispatch(loadData()) }, [])

        const state = useSelector(REDUX_STATE).payins
        var payins = PayinsLedgerEngine.getValues(state)

        const { results, current } = state.pagination
        const { loadData, setCurrentPage, toggleAppliedFilter, setPeriod } = payinsLedgerActions

        const pages = Math.floor(payins.length / results) - (payins.length % results === 0 ? 1 : 0)
        const rows = payins.slice(current * results, (current * results) + results)

        const Render = () =>
                <Dashboard
                        pageTitle="Payins"
                        onPage={DemoRoutes.ledger.payins}
                        breadcrumb={["Ledger", "Payments", "Payins"]}>

                        <Col justify="between" wrap="nowrap">
                                <TableFilters {...{ filters: state.filters.map(x => { return { name: PayinState(x.type), state: x.state, type: x.type } }), onClick: (val: number) => dispatch(toggleAppliedFilter(val)) }} />
                                <PeriodSelector start={new Date(state.period.start)} end={new Date(state.period.end)} onApplied={(s, e) => { dispatch(setPeriod({ start: s.getTime(), end: e.getTime() })); window.setTimeout(()=>{dispatch(loadData())},500)}} />
                        </Col>

                        <Spacer size={16} />

                        <PayinsTable {...rows} />

                        <Spacer size={24} />

                        <TablePagination {...{
                                pages, current, results,
                                items: payins.length,
                                setCurrent: (page: number) => dispatch(setCurrentPage(page))
                        }} />
                </Dashboard>

        return Render()
}

