
export interface CurrencyProps {
    symbol: string
    name: string
    symbol_native: string
    decimal_digits: number
    rounding: number
    code: string
    name_plural: string
}

export const Currencies: { [key: string]: CurrencyProps } = {
    usd: {
        symbol: "$",
        name: "US Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "usd",
        name_plural: "US dollars"
    },
    cad: {
        symbol: "CA$",
        name: "Canadian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "cad",
        name_plural: "Canadian dollars"
    },
    eur: {
        symbol: "€",
        name: "Euro",
        symbol_native: "€",
        decimal_digits: 2,
        rounding: 0,
        code: "eur",
        name_plural: "euros"
    },
    aed: {
        symbol: "AED",
        name: "United Arab Emirates Dirham",
        symbol_native: "د.إ.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "aed",
        name_plural: "UAE dirhams"
    },
    afn: {
        symbol: "Af",
        name: "Afghan Afghani",
        symbol_native: "؋",
        decimal_digits: 0,
        rounding: 0,
        code: "afn",
        name_plural: "Afghan Afghanis"
    },
    all: {
        symbol: "ALL",
        name: "Albanian Lek",
        symbol_native: "Lek",
        decimal_digits: 0,
        rounding: 0,
        code: "all",
        name_plural: "Albanian lekë"
    },
    amd: {
        symbol: "AMD",
        name: "Armenian Dram",
        symbol_native: "դր.",
        decimal_digits: 0,
        rounding: 0,
        code: "amd",
        name_plural: "Armenian drams"
    },
    ars: {
        symbol: "AR$",
        name: "Argentine Peso",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "ars",
        name_plural: "Argentine pesos"
    },
    aud: {
        symbol: "AU$",
        name: "Australian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "aud",
        name_plural: "Australian dollars"
    },
    azn: {
        symbol: "man.",
        name: "Azerbaijani Manat",
        symbol_native: "ман.",
        decimal_digits: 2,
        rounding: 0,
        code: "azn",
        name_plural: "Azerbaijani manats"
    },
    bam: {
        symbol: "KM",
        name: "Bosnia-Herzegovina Convertible Mark",
        symbol_native: "KM",
        decimal_digits: 2,
        rounding: 0,
        code: "bam",
        name_plural: "Bosnia-Herzegovina convertible marks"
    },
    bdt: {
        symbol: "Tk",
        name: "Bangladeshi Taka",
        symbol_native: "৳",
        decimal_digits: 2,
        rounding: 0,
        code: "bdt",
        name_plural: "Bangladeshi takas"
    },
    bgn: {
        symbol: "BGN",
        name: "Bulgarian Lev",
        symbol_native: "лв.",
        decimal_digits: 2,
        rounding: 0,
        code: "bgn",
        name_plural: "Bulgarian leva"
    },
    bhd: {
        symbol: "BD",
        name: "Bahraini Dinar",
        symbol_native: "د.ب.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "bhd",
        name_plural: "Bahraini dinars"
    },
    bif: {
        symbol: "FBu",
        name: "Burundian Franc",
        symbol_native: "FBu",
        decimal_digits: 0,
        rounding: 0,
        code: "bif",
        name_plural: "Burundian francs"
    },
    bnd: {
        symbol: "BN$",
        name: "Brunei Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "bnd",
        name_plural: "Brunei dollars"
    },
    bob: {
        symbol: "Bs",
        name: "Bolivian Boliviano",
        symbol_native: "Bs",
        decimal_digits: 2,
        rounding: 0,
        code: "bob",
        name_plural: "Bolivian bolivianos"
    },
    brl: {
        symbol: "R$",
        name: "Brazilian Real",
        symbol_native: "R$",
        decimal_digits: 2,
        rounding: 0,
        code: "brl",
        name_plural: "Brazilian reals"
    },
    bwp: {
        symbol: "BWP",
        name: "Botswanan Pula",
        symbol_native: "P",
        decimal_digits: 2,
        rounding: 0,
        code: "bwp",
        name_plural: "Botswanan pulas"
    },
    byn: {
        symbol: "Br",
        name: "Belarusian Ruble",
        symbol_native: "руб.",
        decimal_digits: 2,
        rounding: 0,
        code: "byn",
        name_plural: "Belarusian rubles"
    },
    bzd: {
        symbol: "BZ$",
        name: "Belize Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "bzd",
        name_plural: "Belize dollars"
    },
    cdf: {
        symbol: "CDF",
        name: "Congolese Franc",
        symbol_native: "FrCD",
        decimal_digits: 2,
        rounding: 0,
        code: "cdf",
        name_plural: "Congolese francs"
    },
    chf: {
        symbol: "CHF",
        name: "Swiss Franc",
        symbol_native: "CHF",
        decimal_digits: 2,
        rounding: 0.05,
        code: "chf",
        name_plural: "Swiss francs"
    },
    clp: {
        symbol: "CL$",
        name: "Chilean Peso",
        symbol_native: "$",
        decimal_digits: 0,
        rounding: 0,
        code: "clp",
        name_plural: "Chilean pesos"
    },
    cny: {
        symbol: "CN¥",
        name: "Chinese Yuan",
        symbol_native: "CN¥",
        decimal_digits: 2,
        rounding: 0,
        code: "cny",
        name_plural: "Chinese yuan"
    },
    cop: {
        symbol: "CO$",
        name: "Colombian Peso",
        symbol_native: "$",
        decimal_digits: 0,
        rounding: 0,
        code: "cop",
        name_plural: "Colombian pesos"
    },
    crc: {
        symbol: "₡",
        name: "Costa Rican Colón",
        symbol_native: "₡",
        decimal_digits: 0,
        rounding: 0,
        code: "crc",
        name_plural: "Costa Rican colóns"
    },
    cup: {
        symbol: "$",
        name: "Cuban Peso",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "cup",
        name_plural: "Cuban pesos"
    },
    cve: {
        symbol: "CV$",
        name: "Cape Verdean Escudo",
        symbol_native: "CV$",
        decimal_digits: 2,
        rounding: 0,
        code: "cve",
        name_plural: "Cape Verdean escudos"
    },
    czk: {
        symbol: "Kč",
        name: "Czech Republic Koruna",
        symbol_native: "Kč",
        decimal_digits: 2,
        rounding: 0,
        code: "czk",
        name_plural: "Czech Republic korunas"
    },
    djf: {
        symbol: "Fdj",
        name: "Djiboutian Franc",
        symbol_native: "Fdj",
        decimal_digits: 0,
        rounding: 0,
        code: "djf",
        name_plural: "Djiboutian francs"
    },
    dkk: {
        symbol: "Dkr",
        name: "Danish Krone",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        code: "dkk",
        name_plural: "Danish kroner"
    },
    dop: {
        symbol: "RD$",
        name: "Dominican Peso",
        symbol_native: "RD$",
        decimal_digits: 2,
        rounding: 0,
        code: "dop",
        name_plural: "Dominican pesos"
    },
    dzd: {
        symbol: "DA",
        name: "Algerian Dinar",
        symbol_native: "د.ج.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "dzd",
        name_plural: "Algerian dinars"
    },
    eek: {
        symbol: "Ekr",
        name: "Estonian Kroon",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        code: "eek",
        name_plural: "Estonian kroons"
    },
    egp: {
        symbol: "EGP",
        name: "Egyptian Pound",
        symbol_native: "ج.م.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "egp",
        name_plural: "Egyptian pounds"
    },
    ern: {
        symbol: "Nfk",
        name: "Eritrean Nakfa",
        symbol_native: "Nfk",
        decimal_digits: 2,
        rounding: 0,
        code: "ern",
        name_plural: "Eritrean nakfas"
    },
    etb: {
        symbol: "Br",
        name: "Ethiopian Birr",
        symbol_native: "Br",
        decimal_digits: 2,
        rounding: 0,
        code: "etb",
        name_plural: "Ethiopian birrs"
    },
    gbp: {
        symbol: "£",
        name: "British Pound Sterling",
        symbol_native: "£",
        decimal_digits: 2,
        rounding: 0,
        code: "gbp",
        name_plural: "British pounds sterling"
    },
    gel: {
        symbol: "GEL",
        name: "Georgian Lari",
        symbol_native: "GEL",
        decimal_digits: 2,
        rounding: 0,
        code: "gel",
        name_plural: "Georgian laris"
    },
    ghs: {
        symbol: "GH₵",
        name: "Ghanaian Cedi",
        symbol_native: "GH₵",
        decimal_digits: 2,
        rounding: 0,
        code: "ghs",
        name_plural: "Ghanaian cedis"
    },
    gnf: {
        symbol: "FG",
        name: "Guinean Franc",
        symbol_native: "FG",
        decimal_digits: 0,
        rounding: 0,
        code: "gnf",
        name_plural: "Guinean francs"
    },
    gtq: {
        symbol: "GTQ",
        name: "Guatemalan Quetzal",
        symbol_native: "Q",
        decimal_digits: 2,
        rounding: 0,
        code: "gtq",
        name_plural: "Guatemalan quetzals"
    },
    hkd: {
        symbol: "HK$",
        name: "Hong Kong Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "hkd",
        name_plural: "Hong Kong dollars"
    },
    hnl: {
        symbol: "HNL",
        name: "Honduran Lempira",
        symbol_native: "L",
        decimal_digits: 2,
        rounding: 0,
        code: "hnl",
        name_plural: "Honduran lempiras"
    },
    hrk: {
        symbol: "kn",
        name: "Croatian Kuna",
        symbol_native: "kn",
        decimal_digits: 2,
        rounding: 0,
        code: "hrk",
        name_plural: "Croatian kunas"
    },
    huf: {
        symbol: "Ft",
        name: "Hungarian Forint",
        symbol_native: "Ft",
        decimal_digits: 0,
        rounding: 0,
        code: "huf",
        name_plural: "Hungarian forints"
    },
    idr: {
        symbol: "Rp",
        name: "Indonesian Rupiah",
        symbol_native: "Rp",
        decimal_digits: 0,
        rounding: 0,
        code: "idr",
        name_plural: "Indonesian rupiahs"
    },
    ils: {
        symbol: "₪",
        name: "Israeli New Sheqel",
        symbol_native: "₪",
        decimal_digits: 2,
        rounding: 0,
        code: "ils",
        name_plural: "Israeli new sheqels"
    },
    inr: {
        symbol: "₹",
        name: "Indian Rupee",
        symbol_native: "रुपया",
        decimal_digits: 2,
        rounding: 0,
        code: "inr",
        name_plural: "Indian rupees"
    },
    iqd: {
        symbol: "IQD",
        name: "Iraqi Dinar",
        symbol_native: "د.ع.‏",
        decimal_digits: 0,
        rounding: 0,
        code: "iqd",
        name_plural: "Iraqi dinars"
    },
    irr: {
        symbol: "IRR",
        name: "Iranian Rial",
        symbol_native: "﷼",
        decimal_digits: 0,
        rounding: 0,
        code: "irr",
        name_plural: "Iranian rials"
    },
    isk: {
        symbol: "Ikr",
        name: "Icelandic Króna",
        symbol_native: "kr",
        decimal_digits: 0,
        rounding: 0,
        code: "isk",
        name_plural: "Icelandic krónur"
    },
    jmd: {
        symbol: "J$",
        name: "Jamaican Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "jmd",
        name_plural: "Jamaican dollars"
    },
    jod: {
        symbol: "JD",
        name: "Jordanian Dinar",
        symbol_native: "د.أ.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "jod",
        name_plural: "Jordanian dinars"
    },
    jpy: {
        symbol: "¥",
        name: "Japanese Yen",
        symbol_native: "￥",
        decimal_digits: 0,
        rounding: 0,
        code: "jpy",
        name_plural: "Japanese yen"
    },
    kes: {
        symbol: "Ksh",
        name: "Kenyan Shilling",
        symbol_native: "Ksh",
        decimal_digits: 2,
        rounding: 0,
        code: "kes",
        name_plural: "Kenyan shillings"
    },
    khr: {
        symbol: "KHR",
        name: "Cambodian Riel",
        symbol_native: "៛",
        decimal_digits: 2,
        rounding: 0,
        code: "khr",
        name_plural: "Cambodian riels"
    },
    kmf: {
        symbol: "CF",
        name: "Comorian Franc",
        symbol_native: "FC",
        decimal_digits: 0,
        rounding: 0,
        code: "kmf",
        name_plural: "Comorian francs"
    },
    krw: {
        symbol: "₩",
        name: "South Korean Won",
        symbol_native: "₩",
        decimal_digits: 0,
        rounding: 0,
        code: "krw",
        name_plural: "South Korean won"
    },
    kwd: {
        symbol: "KD",
        name: "Kuwaiti Dinar",
        symbol_native: "د.ك.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "kwd",
        name_plural: "Kuwaiti dinars"
    },
    kzt: {
        symbol: "KZT",
        name: "Kazakhstani Tenge",
        symbol_native: "тңг.",
        decimal_digits: 2,
        rounding: 0,
        code: "kzt",
        name_plural: "Kazakhstani tenges"
    },
    lbp: {
        symbol: "L.L.",
        name: "Lebanese Pound",
        symbol_native: "ل.ل.‏",
        decimal_digits: 0,
        rounding: 0,
        code: "lbp",
        name_plural: "Lebanese pounds"
    },
    lkr: {
        symbol: "SLRs",
        name: "Sri Lankan Rupee",
        symbol_native: "SL Re",
        decimal_digits: 2,
        rounding: 0,
        code: "lkr",
        name_plural: "Sri Lankan rupees"
    },
    ltl: {
        symbol: "Lt",
        name: "Lithuanian Litas",
        symbol_native: "Lt",
        decimal_digits: 2,
        rounding: 0,
        code: "ltl",
        name_plural: "Lithuanian litai"
    },
    lvl: {
        symbol: "Ls",
        name: "Latvian Lats",
        symbol_native: "Ls",
        decimal_digits: 2,
        rounding: 0,
        code: "lvl",
        name_plural: "Latvian lati"
    },
    lyd: {
        symbol: "LD",
        name: "Libyan Dinar",
        symbol_native: "د.ل.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "lyd",
        name_plural: "Libyan dinars"
    },
    mad: {
        symbol: "MAD",
        name: "Moroccan Dirham",
        symbol_native: "د.م.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "mad",
        name_plural: "Moroccan dirhams"
    },
    mdl: {
        symbol: "MDL",
        name: "Moldovan Leu",
        symbol_native: "MDL",
        decimal_digits: 2,
        rounding: 0,
        code: "mdl",
        name_plural: "Moldovan lei"
    },
    mga: {
        symbol: "MGA",
        name: "Malagasy Ariary",
        symbol_native: "MGA",
        decimal_digits: 0,
        rounding: 0,
        code: "mga",
        name_plural: "Malagasy Ariaries"
    },
    mkd: {
        symbol: "MKD",
        name: "Macedonian Denar",
        symbol_native: "MKD",
        decimal_digits: 2,
        rounding: 0,
        code: "mkd",
        name_plural: "Macedonian denari"
    },
    mmk: {
        symbol: "MMK",
        name: "Myanma Kyat",
        symbol_native: "K",
        decimal_digits: 0,
        rounding: 0,
        code: "mmk",
        name_plural: "Myanma kyats"
    },
    mop: {
        symbol: "MOP$",
        name: "Macanese Pataca",
        symbol_native: "MOP$",
        decimal_digits: 2,
        rounding: 0,
        code: "mop",
        name_plural: "Macanese patacas"
    },
    mur: {
        symbol: "MURs",
        name: "Mauritian Rupee",
        symbol_native: "MURs",
        decimal_digits: 0,
        rounding: 0,
        code: "mur",
        name_plural: "Mauritian rupees"
    },
    mxn: {
        symbol: "MX$",
        name: "Mexican Peso",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "mxn",
        name_plural: "Mexican pesos"
    },
    myr: {
        symbol: "RM",
        name: "Malaysian Ringgit",
        symbol_native: "RM",
        decimal_digits: 2,
        rounding: 0,
        code: "myr",
        name_plural: "Malaysian ringgits"
    },
    mzn: {
        symbol: "MTn",
        name: "Mozambican Metical",
        symbol_native: "MTn",
        decimal_digits: 2,
        rounding: 0,
        code: "mzn",
        name_plural: "Mozambican meticals"
    },
    rmb: {
        symbol: "CN¥",
        name: "Chinese Yuan",
        symbol_native: "CN¥",
        decimal_digits: 2,
        rounding: 0,
        code: "rmb",
        name_plural: "Chinese yuan"
    },
    ntd: {
        symbol: "NT$",
        name: "New Taiwan Dollar",
        symbol_native: "NT$",
        decimal_digits: 2,
        rounding: 0,
        code: "ntd",
        name_plural: "New Taiwan dollars"
    },
    nad: {
        symbol: "N$",
        name: "Namibian Dollar",
        symbol_native: "N$",
        decimal_digits: 2,
        rounding: 0,
        code: "nad",
        name_plural: "Namibian dollars"
    },
    ngn: {
        symbol: "₦",
        name: "Nigerian Naira",
        symbol_native: "₦",
        decimal_digits: 2,
        rounding: 0,
        code: "ngn",
        name_plural: "Nigerian nairas"
    },
    nio: {
        symbol: "C$",
        name: "Nicaraguan Córdoba",
        symbol_native: "C$",
        decimal_digits: 2,
        rounding: 0,
        code: "nio",
        name_plural: "Nicaraguan córdobas"
    },
    nok: {
        symbol: "Nkr",
        name: "Norwegian Krone",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        code: "nok",
        name_plural: "Norwegian kroner"
    },
    npr: {
        symbol: "NPRs",
        name: "Nepalese Rupee",
        symbol_native: "नेरू",
        decimal_digits: 2,
        rounding: 0,
        code: "npr",
        name_plural: "Nepalese rupees"
    },
    nzd: {
        symbol: "NZ$",
        name: "New Zealand Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "nzd",
        name_plural: "New Zealand dollars"
    },
    omr: {
        symbol: "OMR",
        name: "Omani Rial",
        symbol_native: "ر.ع.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "omr",
        name_plural: "Omani rials"
    },
    pab: {
        symbol: "B/.",
        name: "Panamanian Balboa",
        symbol_native: "B/.",
        decimal_digits: 2,
        rounding: 0,
        code: "pab",
        name_plural: "Panamanian balboas"
    },
    pen: {
        symbol: "S/.",
        name: "Peruvian Nuevo Sol",
        symbol_native: "S/.",
        decimal_digits: 2,
        rounding: 0,
        code: "pen",
        name_plural: "Peruvian nuevos soles"
    },
    php: {
        symbol: "₱",
        name: "Philippine Peso",
        symbol_native: "₱",
        decimal_digits: 2,
        rounding: 0,
        code: "php",
        name_plural: "Philippine pesos"
    },
    pkr: {
        symbol: "PKRs",
        name: "Pakistani Rupee",
        symbol_native: "₨",
        decimal_digits: 0,
        rounding: 0,
        code: "pkr",
        name_plural: "Pakistani rupees"
    },
    pln: {
        symbol: "zł",
        name: "Polish Zloty",
        symbol_native: "zł",
        decimal_digits: 2,
        rounding: 0,
        code: "pln",
        name_plural: "Polish zlotys"
    },
    pyg: {
        symbol: "₲",
        name: "Paraguayan Guarani",
        symbol_native: "₲",
        decimal_digits: 0,
        rounding: 0,
        code: "pyg",
        name_plural: "Paraguayan guaranis"
    },
    qar: {
        symbol: "QR",
        name: "Qatari Rial",
        symbol_native: "ر.ق.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "qar",
        name_plural: "Qatari rials"
    },
    ron: {
        symbol: "RON",
        name: "Romanian Leu",
        symbol_native: "RON",
        decimal_digits: 2,
        rounding: 0,
        code: "ron",
        name_plural: "Romanian lei"
    },
    rsd: {
        symbol: "din.",
        name: "Serbian Dinar",
        symbol_native: "дин.",
        decimal_digits: 0,
        rounding: 0,
        code: "rsd",
        name_plural: "Serbian dinars"
    },
    rub: {
        symbol: "RUB",
        name: "Russian Ruble",
        symbol_native: "₽.",
        decimal_digits: 2,
        rounding: 0,
        code: "rub",
        name_plural: "Russian rubles"
    },
    rwf: {
        symbol: "RWF",
        name: "Rwandan Franc",
        symbol_native: "FR",
        decimal_digits: 0,
        rounding: 0,
        code: "rwf",
        name_plural: "Rwandan francs"
    },
    sar: {
        symbol: "SR",
        name: "Saudi Riyal",
        symbol_native: "ر.س.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "sar",
        name_plural: "Saudi riyals"
    },
    sdg: {
        symbol: "SDG",
        name: "Sudanese Pound",
        symbol_native: "SDG",
        decimal_digits: 2,
        rounding: 0,
        code: "sdg",
        name_plural: "Sudanese pounds"
    },
    sek: {
        symbol: "Skr",
        name: "Swedish Krona",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        code: "sek",
        name_plural: "Swedish kronor"
    },
    sgd: {
        symbol: "S$",
        name: "Singapore Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "sgd",
        name_plural: "Singapore dollars"
    },
    sos: {
        symbol: "Ssh",
        name: "Somali Shilling",
        symbol_native: "Ssh",
        decimal_digits: 0,
        rounding: 0,
        code: "sos",
        name_plural: "Somali shillings"
    },
    syp: {
        symbol: "SY£",
        name: "Syrian Pound",
        symbol_native: "ل.س.‏",
        decimal_digits: 0,
        rounding: 0,
        code: "syp",
        name_plural: "Syrian pounds"
    },
    thb: {
        symbol: "฿",
        name: "Thai Baht",
        symbol_native: "฿",
        decimal_digits: 2,
        rounding: 0,
        code: "thb",
        name_plural: "Thai baht"
    },
    tnd: {
        symbol: "DT",
        name: "Tunisian Dinar",
        symbol_native: "د.ت.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "tnd",
        name_plural: "Tunisian dinars"
    },
    top: {
        symbol: "T$",
        name: "Tongan Paʻanga",
        symbol_native: "T$",
        decimal_digits: 2,
        rounding: 0,
        code: "top",
        name_plural: "Tongan paʻanga"
    },
    try: {
        symbol: "TL",
        name: "Turkish Lira",
        symbol_native: "TL",
        decimal_digits: 2,
        rounding: 0,
        code: "try",
        name_plural: "Turkish Lira"
    },
    ttd: {
        symbol: "TT$",
        name: "Trinidad and Tobago Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "ttd",
        name_plural: "Trinidad and Tobago dollars"
    },
    twd: {
        symbol: "NT$",
        name: "New Taiwan Dollar",
        symbol_native: "NT$",
        decimal_digits: 2,
        rounding: 0,
        code: "twd",
        name_plural: "New Taiwan dollars"
    },
    tzs: {
        symbol: "TSh",
        name: "Tanzanian Shilling",
        symbol_native: "TSh",
        decimal_digits: 0,
        rounding: 0,
        code: "tzs",
        name_plural: "Tanzanian shillings"
    },
    uah: {
        symbol: "₴",
        name: "Ukrainian Hryvnia",
        symbol_native: "₴",
        decimal_digits: 2,
        rounding: 0,
        code: "uah",
        name_plural: "Ukrainian hryvnias"
    },
    ugx: {
        symbol: "USh",
        name: "Ugandan Shilling",
        symbol_native: "USh",
        decimal_digits: 0,
        rounding: 0,
        code: "ugx",
        name_plural: "Ugandan shillings"
    },
    uyu: {
        symbol: "$U",
        name: "Uruguayan Peso",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "uyu",
        name_plural: "Uruguayan pesos"
    },
    uzs: {
        symbol: "UZS",
        name: "Uzbekistan Som",
        symbol_native: "UZS",
        decimal_digits: 0,
        rounding: 0,
        code: "uzs",
        name_plural: "Uzbekistan som"
    },
    vef: {
        symbol: "Bs.F.",
        name: "Venezuelan Bolívar",
        symbol_native: "Bs.F.",
        decimal_digits: 2,
        rounding: 0,
        code: "vef",
        name_plural: "Venezuelan bolívars"
    },
    vnd: {
        symbol: "₫",
        name: "Vietnamese Dong",
        symbol_native: "₫",
        decimal_digits: 0,
        rounding: 0,
        code: "vnd",
        name_plural: "Vietnamese dong"
    },
    xaf: {
        symbol: "FCFA",
        name: "CFA Franc BEAC",
        symbol_native: "FCFA",
        decimal_digits: 0,
        rounding: 0,
        code: "xaf",
        name_plural: "CFA francs BEAC"
    },
    xof: {
        symbol: "CFA",
        name: "CFA Franc BCEAO",
        symbol_native: "CFA",
        decimal_digits: 0,
        rounding: 0,
        code: "xof",
        name_plural: "CFA francs BCEAO"
    },
    yer: {
        symbol: "YR",
        name: "Yemeni Rial",
        symbol_native: "ر.ي.‏",
        decimal_digits: 0,
        rounding: 0,
        code: "yer",
        name_plural: "Yemeni rials"
    },
    zar: {
        symbol: "R",
        name: "South African Rand",
        symbol_native: "R",
        decimal_digits: 2,
        rounding: 0,
        code: "zar",
        name_plural: "South African rand"
    },
    zmk: {
        symbol: "ZK",
        name: "Zambian Kwacha",
        symbol_native: "ZK",
        decimal_digits: 0,
        rounding: 0,
        code: "zmk",
        name_plural: "Zambian kwachas"
    },
    zwl: {
        symbol: "ZWL$",
        name: "Zimbabwean Dollar",
        symbol_native: "ZWL$",
        decimal_digits: 0,
        rounding: 0,
        code: "zwl",
        name_plural: "Zimbabwean Dollar"
    }
}