import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Dashboard, DisputesTable, PeriodSelector, Spacer, TableFilters, TablePagination } from "../.."
import { REDUX_STATE, disputesLedgerActions } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { DISPUTE_STATE, DisputeStateName, DisputesLedgerEngine } from "../../../utilities"

export const DisputesLedger = () => {

        const dispatch = useDispatch()
        useEffect(() => { dispatch(loadData()) }, [])

        const state = useSelector(REDUX_STATE).disputes
        var disputes = DisputesLedgerEngine.getValues(state)

        const { results, current } = state.pagination
        const { loadData, setCurrentPage, toggleAppliedFilter, setPeriod } = disputesLedgerActions

        const pages = Math.floor(disputes.length / results) - (disputes.length % results === 0 ? 1 : 0)
        const rows = disputes.slice(current * results, (current * results) + results)

        const Render = () =>
                <Dashboard
                        pageTitle="Disputes"
                        onPage={DemoRoutes.ledger.disputes}
                        breadcrumb={["Ledger", "Payments", "Disputes"]}>

                        <Col justify="between" wrap="nowrap">
                                <TableFilters
                                        filters={state.filters.map(x => { return { name: DisputeStateName(x.type), state: x.state, type: x.type } })}
                                        onClick={(val: number) => dispatch(toggleAppliedFilter(val as DISPUTE_STATE))} />
                                <PeriodSelector start={new Date(state.period.start)} end={new Date(state.period.end)} onApplied={(s, e) => { dispatch(setPeriod({ start: s.getTime(), end: e.getTime() })); window.setTimeout(() => { dispatch(loadData()) }, 500) }} />
                        </Col>

                        <Spacer size={16} />

                        <DisputesTable {...rows} />

                        <Spacer size={24} />

                        <TablePagination {...{
                                pages, current, results,
                                items: disputes.length,
                                setCurrent: (page: number) => dispatch(setCurrentPage(page))
                        }} />
                </Dashboard>

        return Render()
}

