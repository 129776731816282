import { useSelector } from 'react-redux'
import _Select from 'react-select'
import styled from 'styled-components'
import { PaletteProps } from '../../design_system'
import { REDUX_STATE } from '../../redux'
import { InputDesign as design } from './Input'

export interface SelectProps {
    error?: boolean
    disabled?: boolean
    isMulti?: boolean
    defaultMenuIsOpen?: boolean
    placeholder?: string
    options: { label: string, value: string | number }[]
    onSelect?: (code: string | number | (string | number)[]) => void
    defaultValue?: { label: string | number, value: string | number }
}

export const Select = (props: SelectProps) => {

    const { palette, isDark } = useSelector(REDUX_STATE).theme

    const render = () =>
        <SelectStyled
            menuPlacement="auto"
            options={props.options}
            isMulti={props.isMulti}
            defaultMenuIsOpen={props.defaultMenuIsOpen}
            isDisabled={props.disabled}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            onChange={(e) => {
                props.onSelect &&
                    props.onSelect(
                        Array.isArray(e) ?
                            (e as { label: string, value: number | string }[]).map(x => x.value) :
                            (e as { label: string, value: number | string }).value
                    )
            }}
            styles={{
                container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: '100%'
                }),
                singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: palette[state.isDisabled ? design.onDisabled.color : design.color]
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: `0px`,
                }),
                placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: palette[design.placeholderColor]
                }),
                dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    color: palette[state.isDisabled ? design.onDisabled.color : design.color]
                }),
                indicatorSeparator: (baseStyles, state) => ({
                    ...baseStyles,
                    background: palette[state.isDisabled ? design.onDisabled.background : design.background],
                    width: '0px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: `${design.height.default}px`,
                    maxHeight: `${design.height.default}px`,
                    borderRadius: `${design.borderRadius}px`,
                    transition: design.transition,
                    padding: `0px ${design.horizontalPadding}px`,
                    color: palette[state.isDisabled ? design.onDisabled.color : design.color],
                    pointerEvents: state.isDisabled ? "none" : "auto",
                    background: palette[state.isDisabled ? design.onDisabled.background : design.background],
                    boxShadow: state.isFocused ? props.error ? design.onError.onFocus.shadow(palette) : design.onFocus.shadow(palette) : 'unset',

                    borderStyle: design.borderStyle,
                    borderWidth: `${design.borderWidth}px`,
                    borderColor: palette[
                        state.isDisabled ? design.onDisabled.borderColor :
                            state.isFocused && props.error ? design.onError.onFocus.borderColor :
                                state.isFocused ? design.onFocus.borderColor :
                                    props.error ? design.onError.borderColor : design.borderColor],

                    ':hover': {
                        boxShadow:
                            props.error && state.isFocused ? design.onError.onFocus.shadow(palette) :
                                props.error ? design.onError.onHover.shadow(palette) :
                                    state.isFocused ? design.onFocus.shadow(palette) : 'unset',
                        borderColor: palette[
                            props.error && state.isFocused ? design.onError.onFocus.borderColor :
                                props.error ? design.onError.onHover.borderColor :
                                    state.isFocused ? design.onFocus.borderColor : design.onHover.borderColor]
                    },

                    outlineStyle: design.outlineStyle,
                    outlineWidth: `${design.outlineWidth}px`,
                    outlineColor: palette[state.isDisabled ? design.onDisabled.outlineColor : state.isFocused ? design.onFocus.outlineColor : design.outlineColor],
                }),
                menu: (baseStyles, state) => ({
                    ...baseStyles,
                    boxShadow: `0px 1px 3px rgba(${isDark ? "255,255, 255, .08" : "0, 0, 0, 0.12"})${isDark ? "" : ", 0px 2px 8px -1px rgba(0, 0, 0, 0.05)"}`,
                    color: palette[optionDesign.color]
                }),
                option: (baseStyles, state) => ({
                    ...baseStyles,
                    color: palette[state.isSelected ? optionDesign.onSelected.color : optionDesign.color],
                    background: palette[state.isSelected ? optionDesign.onSelected.background : optionDesign.background],
                    ':hover': {
                        color: palette[optionDesign.onHover.color],
                        background: `${palette[state.isSelected ? optionDesign.onSelected.background : optionDesign.onHover.background]}${state.isSelected ? '' : `80`}`
                    }
                })
            }} />

    return render()
}

const SelectStyled = styled(_Select)`
    ${design.typography.default}
`

const optionDesign: SelectOptionsDesignProps = {
    color: 'gray4',
    background: 'white',

    onHover: {
        color: 'white',
        background: 'primary2'
    },
    // 1A
    onSelected: {
        color: 'white',
        background: 'primary2'
        // 4D
    }
}

export const SelectOptionsDesign = optionDesign

interface SelectOptionsDesignProps {
    color: keyof PaletteProps
    background: keyof PaletteProps

    onHover: {
        color: keyof PaletteProps
        background: keyof PaletteProps
    }

    onSelected: {
        color: keyof PaletteProps
        background: keyof PaletteProps
    }
}


