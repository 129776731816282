import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SELLER_STATE, SELLERS_LEDGER_COLS, SellersLedgerEngine, SellersLedgerFilter, SellersLedgerOrder, SellersLedgerRowProps } from "../utilities";

export interface SellersLedgerData {
    sellers: SellersLedgerRowProps[]
    period: {
        start: number
        end: number
    }
    order: SellersLedgerOrder
    filters: SellersLedgerFilter[]
    pagination: {
        results: number
        current: number
    }
}

const initialState: SellersLedgerData = {
    sellers: [],
    period: {
        start: new Date(2023, 0, 1).getTime(),
        end: new Date().getTime()
    },
    order: {
        by: SELLERS_LEDGER_COLS.ONBOARDED,
        ascendent: true
    },
    filters: [
        {
            state: true,
            type: SELLER_STATE.ACTIVE
        },
        {
            state: false,
            type: SELLER_STATE.LOCKED
        }
    ],
    pagination: {
        results: 15,
        current: 0
    }
}

export const sellersLedgerSlice = createSlice({
    name: 'sellersLedger',
    initialState,
    reducers: {

        setPeriod: (state: SellersLedgerData, action: PayloadAction<{ start: number, end: number }>) => {
            const { start, end } = action.payload
            return {
                ...state,
                period: { start: start, end: end }
            }
        },

        loadData: (state: SellersLedgerData) => {
            var sellers = SellersLedgerEngine.getRows(state.period.start, state.period.end)
            return {
                ...state,
                sellers,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order: initialState.order
            }
        },

        orderData: (state: SellersLedgerData, action: PayloadAction<SELLERS_LEDGER_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order
            }
        },

        setCurrentPage: (state: SellersLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: SellersLedgerData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        toggleAppliedFilter: (state: SellersLedgerData, action: PayloadAction<SELLER_STATE>) => {
            return {
                ...state,
                filters: state.filters.map(x => x.type !== action.payload ? x : { ...x, state: !x.state }),
                pagination: {
                    ...state.pagination,
                    current: 0
                }
            }
        }
    }
})

export const sellersLedgerActions = sellersLedgerSlice.actions

export const sellersLedgerReducer = sellersLedgerSlice.reducer

