import { BuyerProps, DateTime, PAYIN_STATE, PayinProps, PspProps } from ".."
import { Disputes, Payins } from "../../fake_data"
import { DisputesLedgerData } from "../../redux"
import { DISPUTE_REASON, DISPUTE_STATE } from "../disputes"
import { PaymentMethod } from "../paymentMethods"


export enum DISPUTES_LEDGER_COLS {
    AMOUNT,
    STATE,
    REASON,
    BUYER,
    PROVIDER,
    DISPUTED_ON,
    DUE_ON
}

export interface DisputesLedgerOrder {
    by: DISPUTES_LEDGER_COLS,
    ascendent: boolean
}

export interface DisputesLedgerFilter {
    state: boolean
    type: DISPUTE_STATE
}

export interface DisputesLedgerRowProps {
    id: string
    amount: number
    state: DISPUTE_STATE
    reason: DISPUTE_REASON
    networkReasonCode: string
    customer: BuyerProps
    provider: PspProps
    method: PaymentMethod
    disputedOn: number
    dueOn: number
    submittedEvidenceOn?: number
}

export class DisputesLedgerEngine {

    static getValues(state: DisputesLedgerData): DisputesLedgerRowProps[] {
        const rows = this.filterValues(state.disputes, state.filters)
        const reoredered = this.getOrderedValues(rows, state.order)
        return reoredered
    }

    static filterValues(payins: DisputesLedgerRowProps[], applied: DisputesLedgerFilter[]) {
        let filtered: DisputesLedgerRowProps[] = []
        applied.some(x => x.type === DISPUTE_STATE.LOST && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === DISPUTE_STATE.LOST)))
        applied.some(x => x.type === DISPUTE_STATE.NEEDS_RESPONSE && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === DISPUTE_STATE.NEEDS_RESPONSE)))
        applied.some(x => x.type === DISPUTE_STATE.UNDER_REVIEW && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === DISPUTE_STATE.UNDER_REVIEW)))
        applied.some(x => x.type === DISPUTE_STATE.WON && x.state) && (filtered = filtered.concat(payins.filter(x => x.state === DISPUTE_STATE.WON)))
        return filtered
    }

    static getOrderedValues(rows: DisputesLedgerRowProps[], order: DisputesLedgerOrder): DisputesLedgerRowProps[] {
        var factor = order.ascendent ? 1 : -1

        var result = [...rows]

        switch (order.by) {
            case DISPUTES_LEDGER_COLS.AMOUNT:
                result = result.sort((a, b) => a.amount < b.amount ? factor * -1 : factor * 1)
                break

            case DISPUTES_LEDGER_COLS.STATE:
                result = result.sort((a, b) => a.state < b.state ? factor * -1 : factor * 1)
                break

            case DISPUTES_LEDGER_COLS.REASON:
                result = result.sort((a, b) => a.reason < b.reason ? factor * -1 : factor * 1)
                break

            case DISPUTES_LEDGER_COLS.BUYER:
                result = result.sort((a, b) => a.customer.email < b.customer.email ? factor * -1 : factor * 1)
                break

            case DISPUTES_LEDGER_COLS.PROVIDER:
                result = result.sort((a, b) => a.provider.display_name < b.provider.display_name ? factor * -1 : factor * 1)
                break

            case DISPUTES_LEDGER_COLS.DISPUTED_ON:
                result = result.sort((a, b) => a.disputedOn < b.disputedOn ? factor * -1 : factor * 1)
                break

            default:
                result = result.sort((a, b) => a.dueOn > b.dueOn ? factor * -1 : factor * 1)
                break
        }
        return result
    }


    static getRows(start: number, end: number): DisputesLedgerRowProps[] {

        const disputed = Payins.filter(x => x.state === PAYIN_STATE.DISPUTED)
            .filter(({ dispute }) =>
                dispute !== undefined &&
                dispute.disputedOn >= start &&
                dispute.disputedOn <= end
            )
            .sort((a, b) =>
                a.dispute && b.dispute ?
                    a.dispute.disputedOn - b.dispute.disputedOn : 0
            )

        var result: DisputesLedgerRowProps[] = disputed.map(this.toLedgerRow)

        return result
    }

    static toLedgerRow(p: PayinProps): DisputesLedgerRowProps {
        if (!p.dispute) {
            throw new Error();
        }

        return {
            id: p.dispute.id,
            amount: p.amount,
            state: p.dispute.state,
            reason: p.dispute.reason,
            networkReasonCode: p.dispute.networkReasonCode,
            customer: p.buyer,
            provider: p.provider,
            method: p.method,
            disputedOn: p.dispute.disputedOn,
            dueOn: p.dispute.dueOn,
            submittedEvidenceOn: p.dispute.submittedEvidenceOn
        }
    }

}