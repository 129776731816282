import { PayinProps } from "./payins"

export enum DISPUTE_STATE {
    NEEDS_RESPONSE,
    UNDER_REVIEW,
    WON,
    LOST
}

export interface DisputeProps {
    id: string
    state: DISPUTE_STATE,
    reason: DISPUTE_REASON,
    networkReasonCode: string,
    submittedEvidenceOn?: number
    disputedOn: number
    dueOn: number
}

export enum DISPUTE_REASON {
    BANK_CANNOT_PROCESS,
    CHECK_RETURNED,
    CREDIT_NOT_PROCESSED,
    CUSTOMER_INITIATED,
    DEBIT_NOT_AUTHORIZED,
    DUPLICATE,
    FRAUDULENT,
    GENERAL,
    INCORRECT_ACCOUNT_DETAILS,
    INSUFFICIENT_FUNDS,
    PRODUCT_NOT_RECEIVED,
    PRODUCT_UNACCEPTABLE,
    SUBSCRIPTION_CANCELLED,
    UNRECOGNIZED
}


export const DisputeNetworkReasonCode = {
    // VISA

    // Credit not processed	
    '13.6': 'Credit not processed',
    '13.7': 'Cancelled Merchandise/ Services',
    // Duplicate
    '12.6.1': 'Duplicate processing',
    '12.6.2': 'Paid by other means',
    // Fraudulent
    '33': 'Fraud analysis request',
    '10.1': 'EMV Liability Shift Counterfeit Fraud',
    '10.2': 'EMV Liability Shift Non-Counterfeit Fraud',
    '10.3': 'Other Fraud-Card Present Environment',
    '10.4': 'Other Fraud-Card Absent Environment',
    '10.5': 'Visa Fraud Monitoring Program',
    // General
    '28': 'Request for copy bearing signature',
    '30': 'Cardholder request due to dispute',
    '34': 'Legal process request',
    '11.1': 'Card Recovery Bulletin',
    '11.2': 'Declined Authorization',
    '11.3': 'No Authorization',
    '12.1': 'Late Presentment',
    '12.2': 'Incorrect Transaction Code',
    '12.3': 'Incorrect Currency',
    '12.4': 'Incorrect Account Number',
    '12.5': 'Incorrect Amount',
    '12.7': 'Invalid Data',
    '13.8': 'Original Credit Transaction Not Accepted',
    // Product not received
    '13.1': 'Merchandise/Services Not Received',
    '13.9': 'Non-Receipt of Cash or Load Transaction Value',
    // Product unacceptable
    '13.3': 'Not as Described or Defective Merchandise/Services',
    '13.4': 'Counterfeit Merchandise',
    '13.5': 'Misrepresentation',
    // Subscription canceled
    '13.2': 'Cancelled Recurring',

    // MASTERCARD
    // Credit not processed	
    '4860': 'Credit Not Processed',
    // '4860': 'Timeshares',
    // '4860': 'Credit Posted as a Purchase',
    // Duplicate	
    // '4834': 'Transaction Amount Differs',
    // '4834': 'Cardholder Debited More than Once for the Same Goods or Services',
    '4834': 'ATM Disputes',
    // '4834': 'Charges for Loss, Theft, or Damages',
    // '4834': 'Late Presentment',
    // '4834': 'POI Currency Conversion (Dynamic Currency Conversion)',
    // '4834': 'Merchant Credit Correcting Error Resulting in Cardholder Currency Exchange Loss',
    // '4834': 'Improper Merchant Surcharge (Intra-European and Inter-European transactions only)',
    // '4834': 'Unreasonable Amount—Intra-European Economic Area (EEA) Transactions Only',
    // Fraudulent	
    '6341': 'Fraud investigation',
    '4837': 'No Cardholder Authorization',
    '4849': 'Questionable Merchant Activity',
    '4870': 'Chip Liability Shift',
    '4871': 'Chip Liability Shift—Lost/Stolen/Never Received Issue (NRI) Fraud',
    // General	
    '6305': 'Cardholder does not agree with amount billed',
    '6322': 'Request Transaction Certificate for a chip transaction',
    '6323': 'Cardholder needs information for personal records',
    '6342': 'Potential chargeback or compliance documentation is required',
    '6343': 'IIAS Audit (for healthcare transactions only)',
    '6390': 'Identifies a syntax error return',
    '4808': 'General Chargeback AND General Arbitration Chargeback',
    '4807': 'Deprecated',
    '4812': 'Account number not on file',
    '4859': 'Addendum, No-show, or ATM Dispute',
    // '4859': 'German Domestic Rule-Card Acceptor Unwilling or Unable to Render Services',
    // '4859': 'Addendum Dispute',
    // '4859': 'No-show Hotel Charge',
    // '4831': 'Transaction Amount Differs',
    '4831': 'Cardholder Debited More than Once for the Same Goods or Services',
    // '4831': 'Merchant Credit Correcting Error Resulting in Cardholder Currency Exchange Loss',
    '4842': 'Late Presentment',
    '4846': 'POI Currency Conversion (Dynamic Currency Conversion)',
    '4901': 'Required Documentation Not Received to Support Second Presentment',
    '4902': 'Documentation Received was Illegible',
    '4903': 'Scanning error—Unrelated Documents or Partial Scan',
    '4905': 'Acquirer Reference Data (ARD) Does Not Match or is Invalid',
    '4908': 'Invalid Acquirer Reference Data; Documentation Received',
    '4968': 'bad code-needs investigation',
    '4840': 'bad code-needs investigation',
    // Product not received	
    '4855': 'Transaction Did Not Complete',
    // Product unacceptable	
    // '4853': 'General Defective/Not as Described',
    // '4853': 'Goods or Services Were Either Not as Described or Defective',
    '4853': 'Goods or Services Not Provided',
    // '4853': 'Failed Travel Merchant—Intra-EEA and Domestic European Transactions Only',
    // '4853': 'Digital Goods Purchase of USD 25 or Less',
    // '4853': 'Credit Not Processed',
    // '4853': 'Counterfeit Goods',
    // '4853': 'Cardholder Dispute of a Recurring Transaction',
    // '4853': 'Issuer Dispute of a Recurring Transaction',
    // '4853': 'Addendum Dispute',
    // '4853': 'No-show Hotel Charge',
    // '4853': 'Transaction Did Not Complete',
    // '4853': 'Timeshares',
    // '4853': 'Credit Posted as a Purchase',
    // Subscription canceled	
    // '4841': 'Digital Goods Purchase of USD 25 or Less',
    // '4841': 'Canceled Recurring or Digital Goods Transactions',
    // '4841': 'Cardholder Dispute of a Recurring Transaction',
    '4841': 'Issuer Dispute of a Recurring Transaction',
    // Unrecognized	
    '6321': 'Cardholder does not recognize transaction',
    '4863': 'Cardholder Does Not Recognize—Potential Fraud',

    // AMEX
    //Credit not processed	
    'A01': 'Charge Amount Exceeds Authorization Amount',
    'C02': 'Credit Not Processed',
    'C04': 'Goods/Services Returned or Refused',
    'C05': 'Goods/Services Cancelled',
    'C18': ' “No Show” or CARDeposit Cancelled',
    'P03': 'Credit Processed as Charge',
    'P05': 'Incorrect Charge Amount',
    '061': 'Credit Processed as Charge',
    '062': 'Charge Processed as Credit',
    '154': 'Goods/Services Cancelled/Refused',
    '158': 'Goods Returned (Request Credit)',
    '170': 'Cancelled lodging reservation/CARDeposit not received',
    '175': 'Credit Not Processed',
    '680': 'Incorrect Charge Amount',
    // Duplicate	
    'C14': ' Paid by Other Means',
    'P08': ' Duplicate Charge',
    '173': 'Duplicate Charge',
    '684': 'Paid by Other Means',
    // Fraudulent	
    'F10': 'Missing Imprint',
    'F24': 'Multiple ROCs',
    'F29': 'Card Not Present',
    'F30': 'EMV Liability Shift-Counterfeit',
    'F31': 'EMV Liability Shift-Lost/Stolen/Non-Received',
    'FR2': 'Fraud Full Recourse Program',
    'FR4': 'Immediate Chargeback Program',
    'FR5': 'Immediate Chargeback Program',
    'FR6': 'Partial Immediate Chargeback Program',
    '177': 'Unauthorized Charge',
    '193': 'Fraudulent Charge',
    // General	
    'A02': 'No Valid Authorization',
    'A08': 'Authorization Approval Expired',
    'M10': 'Vehicle Rental-Capital Damages, Theft, or Loss of Use',
    'R03': 'Insufficient Reply',
    'R13': ' No Reply',
    'M01': 'Chargeback Authorization',
    'P01': 'Unassigned Card Number',
    'P04': 'Charge Processed as Credit',
    'P07': 'Late Submission',
    'P22': 'Non-Matching Card Number',
    'P23': 'Currency Discrepancy',
    'M04': 'Merchant Accepted',
    'M11': 'Reversal Due To Credit',
    'M36': 'See Notes',
    'M38': 'Reversal',
    'M39': 'Correct a Previous Transaction',
    'M42': 'Reversal Request Too Late',
    'S01': 'Reversal Request Denied',
    'S04': 'Reversal Request Under Review, Please Wait',
    'OP1': 'General',
    '147': 'Charge paid by Insurance Company',
    '169': 'Charge submitted in an invalid currency',
    '693': 'Questioning Charge for Damage/Theft/Loss',
    'S02': 'Reviewed Support; Will not be Debiting Account',
    'S03': 'Support received',
    // Product not received	
    'C08': 'Goods/Services Not Received or Only Partially Received',
    '004': 'Product Not Received',
    '155': 'Goods not Received (Request Credit)',
    //Product unacceptable	
    'C31': 'Goods/Services Not As Described',
    'C32': 'Goods/Services Damaged or Defective',
    '024': 'Goods Damaged/Defective',
    '059': 'Goods Damaged/Defective (Request Repair)',
    '063': 'Product Quality Unacceptable',
    // Subscription canceled	
    'C28': 'Cancelled Recurring Billing',
    '021': 'Goods/Services Cancelled/Expired',
    // Unrecognized	
    '127': 'Unrecognized Charge',
    '176': 'Unrecognized Charge (Card Not Present)',
    '691': 'Requesting Support',

    // DISCOVER
    // Credit not processed	
    '002 ': 'Credit Not Processed',
    // Duplicate	
    '4534 ': 'Duplicate Processing',
    '4865 ': 'Paid By Other Means',
    // Fraudulent	
    '6041 ': 'Transaction Documentation Request for Fraud Analysis',
    '4866 ': 'Fraud Chip Card Counterfeit Transaction',
    '4867 ': 'Fraud Chip Card and PIN Transaction',
    '7010 ': 'Fraud Card Present Transaction',
    '7030 ': 'Fraud Card Not Present Transaction',
    // General	
    '6021 ': 'Transaction Documentation Request',
    '6005 ': 'Transaction Documentation Request Due to Cardholder Dispute',
    '6040 ': 'Good Faith Investigation',
    '4542 ': 'Late Presentation',
    '4550 ': 'Credit/Debit Posted Incorrectly',
    '4553 ': 'Cardholder Disputes Quality of Goods or Services',
    '4586 ': 'Altered Amount',
    '4752 ': 'Does Not Recognize',
    '4753 ': 'Invalid Cardholder Number',
    '4757 ': 'Violation of Operating Regulations',
    '4762 ': 'Good Faith Investigation',
    '4863 ': 'Authorization Non Compliance',
    '6050 ': 'Disputes Compliance',
    // Product not received	
    '4755 ': 'Non-Receipt of Goods or Services',
    '4864': 'Non Receipt Of Cash From ATM',
    // Subscription canceled	
    '4541': 'Recurring Payment',
}


// console.log(JSON.stringify(
//     Payins.filter(x => x.state === PAYIN_STATE.DISPUTED).map(x => {
//             let disp = new Date(x.date.setDate(x.date.getDate() + Math.floor(Math.random() * 14)))

//             return {
//                     id: UUID.create(),
//                     state: `DISPUTE_STATE[${Math.floor(Math.random() * 5)}]`,
//                     reason: `DISPUTE_REASON[${Math.floor(Math.random() * 14)}]`,
//                     payin: `Payins[${Payins.indexOf(x)}]`,
//                     disputedOn: `new Date(${disp.getFullYear()},${disp.getMonth()},${disp.getDate()})`,
//             }
//     })
// ))


// console.log(JSON.stringify(
//     Disputes.map(x => {

//         return {
//             id: UUID.create(),
//             state: `DISPUTE_STATE.${DisputeState(x.state).toUpperCase().replaceAll(" ", "_")}`,
//             reason: `DISPUTE_REASON.${DisputeReason(x.reason).toUpperCase().replaceAll(" ", "_")}`,
//             payin: `Payins[${Payins.indexOf(x.payin)}]`,
//             disputedOn: `new Date(${x.disputedOn.getFullYear()},${x.disputedOn.getMonth()},${x.disputedOn.getDate()},${x.disputedOn.getHours()},${x.disputedOn.getMinutes()},${x.disputedOn.getSeconds()})`,
//             dueOn: `new Date(${x.dueOn.getFullYear()},${x.dueOn.getMonth()},${x.dueOn.getDate()},${x.dueOn.getHours()},${x.dueOn.getMinutes()},${x.dueOn.getSeconds()})`,
//         }
//     })
// ))
