import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, CardHorizzontalSpacer, Col, Icon, P, Spacer } from "../.."
import { REDUX_STATE, payoutsLedgerActions } from "../../../redux"
import { PAYOUTS_LEDGER_COLS, PAYOUT_STATE, PayoutState, PayoutsLedgerRowProps, UUID } from "../../../utilities"


export const PayoutsTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={head ? undefined : `1px solid ${palette.gray2}`}>
            {!head && <Spacer size={12} />}
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="25%" max_w="25%">{children[0]}</Col>
                <Col w="30%" max_w="30%">{children[1]}</Col>
                <Col w="30%" max_w="30%">{children[2]}</Col>
                <Col w="15%" max_w="15%">{children[3]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            {!head && <Spacer size={12} />}
        </Col>

    return render()
}

export const PayoutsTableHead = () => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { orderData } = payoutsLedgerActions
    const { theme: { palette }, payouts: { order } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Amount", col: PAYOUTS_LEDGER_COLS.AMOUNT },
        { name: "Date", col: PAYOUTS_LEDGER_COLS.DATE },
        { name: "Description", col: PAYOUTS_LEDGER_COLS.DESCRIPTION },
        { name: "State", col: PAYOUTS_LEDGER_COLS.STATUS },
    ]

    const render = () =>
        <PayoutsTableRowLayout head>
            {
                cols.map(x =>
                    <Col key={id + n++} py={12} pointer wrap="nowrap" align="center" onClick={() => dispatch(orderData(x.col))}>
                        <P mb={2} medium variant="secondary"> {x.name} </P>
                        <Icon pointer color={palette.black} height={18} variant={`ordinament${order.by !== x.col ? "" : order.ascendent ? "-asc" : "-des"}`} />
                    </Col>
                )
            }
        </PayoutsTableRowLayout>

    return render()
}

export const PayoutsTableRow = (props: PayoutsLedgerRowProps) => {

    const { amount, state, date, bank } = props

    const render = () =>
        <PayoutsTableRowLayout>
            <P medium variant="dark">${amount.toLocaleString()}</P>
            <P regular variant="dark">{date}</P>
            {
                <Badge variant={
                    state === PAYOUT_STATE.LOCKED ? "error" :
                        state === PAYOUT_STATE.PAID ? "success" : "danger"
                }>
                    {PayoutState(state)}
                </Badge>
            }
            <P regular variant="dark">{bank}</P>
        </PayoutsTableRowLayout >

    return render()
}
