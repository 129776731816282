import { BUYER_STATE, BUYER_TYPE, DISPUTE_REASON, DISPUTE_STATE, DisputeProps, DisputesLedgerRowProps, HTTP_STATUS_CODE, Minutes, ONBOARDING_STATE, PAYIN_STATE, PAYMENT_METHOD_STATE, PAYOUT_STATE, PSP_ONBOARDING_STATE, SELLER_STATE } from "."

export const PayinState = (val: PAYIN_STATE) => {
    return val === PAYIN_STATE.FAILED ? "Failed" :
        val === PAYIN_STATE.INITIATED ? "Initiated" :
            val === PAYIN_STATE.REFUNDED ? "Charge refunded" :
                val === PAYIN_STATE.SUCCEEDED ? "Succeeded" :
                    val === PAYIN_STATE.DISPUTED ? "Disputed" : "Uncaptured"
}

export const PaymentMethodState = (val: PAYMENT_METHOD_STATE) => {
    return val === PAYMENT_METHOD_STATE.DISABLED ? "Disabled" :
        val === PAYMENT_METHOD_STATE.ENABLED ? "Enabled" : "Suspended"
}

export const SellerState = (val: SELLER_STATE) => {
    return val === SELLER_STATE.LOCKED ? "Locked" : "Active"
}

export const OnboardingState = (val: ONBOARDING_STATE) => {
    return val === ONBOARDING_STATE.VERIFIED ? "KYC Verified" :
        val === ONBOARDING_STATE.HAS_ERRORS ? "KYC Errors" :
            val === ONBOARDING_STATE.PENDING ? "KYC Pending" : "To Onboard"
}

export const BuyerState = (val: BUYER_STATE) => {
    return val === BUYER_STATE.ACTIVE ? "Active" : "Locked"
}

export const BuyerType = (val: BUYER_TYPE) => {
    return val === BUYER_TYPE.GUEST ? "Guest" : "Customer"
}

export const PayoutState = (val: PAYOUT_STATE) => {
    return val === PAYOUT_STATE.LOCKED ? "Locked" :
        val === PAYOUT_STATE.PAID ? "Paid" : "Pending"
}

export const DisputeReason = (val: DISPUTE_REASON) => {
    return val === DISPUTE_REASON.BANK_CANNOT_PROCESS ? "Bank cannot process" :
        val === DISPUTE_REASON.CHECK_RETURNED ? "Check returned" :
            val === DISPUTE_REASON.CREDIT_NOT_PROCESSED ? "Credit not processed" :
                val === DISPUTE_REASON.CUSTOMER_INITIATED ? "Customer initiated" :
                    val === DISPUTE_REASON.DEBIT_NOT_AUTHORIZED ? "Debit not authorized" :
                        val === DISPUTE_REASON.DUPLICATE ? "Duplicate" :
                            val === DISPUTE_REASON.FRAUDULENT ? "Fraudulent" :
                                val === DISPUTE_REASON.GENERAL ? "General" :
                                    val === DISPUTE_REASON.INCORRECT_ACCOUNT_DETAILS ? "Incorrect account details" :
                                        val === DISPUTE_REASON.INSUFFICIENT_FUNDS ? "Insufficient funds" :
                                            val === DISPUTE_REASON.PRODUCT_NOT_RECEIVED ? "Product not received" :
                                                val === DISPUTE_REASON.PRODUCT_UNACCEPTABLE ? "Product unacceptable" :
                                                    val === DISPUTE_REASON.SUBSCRIPTION_CANCELLED ? "Subscription cancelled" : "Unrecognized"
}

export const DisputeState = (val: DisputeProps | DisputesLedgerRowProps) => {
    const { state, dueOn } = val

    let ms = dueOn - new Date().getTime()
    let days = Math.ceil(ms / 1000 / 60 / 60 / 24)

    return state === DISPUTE_STATE.LOST ? "Dispute lost" :
        state === DISPUTE_STATE.WON ? "Dispute won" :
            state === DISPUTE_STATE.UNDER_REVIEW ? "Evidence submitted" :
                `${days} day${days === 1 ? "" : "s"} to respond`
}

export const DisputeStateName = (state: DISPUTE_STATE) => {
    return state === DISPUTE_STATE.LOST ? "Lost" :
        state === DISPUTE_STATE.WON ? "Won" :
            state === DISPUTE_STATE.UNDER_REVIEW ? "Under review" : "Need response"
}

export const PspOnboardingState = (val: PSP_ONBOARDING_STATE) => {
    return val === PSP_ONBOARDING_STATE.DISABLED ? "Disabled" :
        val === PSP_ONBOARDING_STATE.PENDING ? "Pending" : "Verified"
}


export const HttpStatusCode = (val: HTTP_STATUS_CODE) => {
    return val === HTTP_STATUS_CODE.OK ? "200 OK" :
        val === HTTP_STATUS_CODE.BAD_REQUEST ? "400 ERR" :
            val === HTTP_STATUS_CODE.NOT_FOUND ? "404 ERR" :
                val === HTTP_STATUS_CODE.SERVER_ERROR ? "500 ERR" : "401 ERR"
}