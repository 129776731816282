

export enum PAYOUT_STATE {
    TO_PAY,
    PENDING,
    PAID,
    LOCKED
}

export interface PayoutProps {
    state: PAYOUT_STATE
    date: number
    id: string
}

