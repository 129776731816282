import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Analytics, Col, Dashboard, MethodTable, Spacer } from "../.."
import { REDUX_STATE } from "../../../redux"
import { DemoRoutes } from "../../../routes"
import { LOAD_ANALYTICS_DATA } from "../../../sagas"
import { PAYMENT_METHOD, PaymentMethods } from "../../../utilities"


interface MethodPageProps {
        method: PAYMENT_METHOD
}

export const MethodPage = (props: MethodPageProps) => {

        const { method } = props
        const dispatch = useDispatch()
        const { analytics: { period: { start, end } } } = useSelector(REDUX_STATE)
        useEffect(() => { dispatch({ type: LOAD_ANALYTICS_DATA, payload: method }) }, [start, end])

        const Render = () =>
                <Dashboard
                        pageTitle={PaymentMethods[method].display_name}
                        onPage={DemoRoutes.system[
                                method === PAYMENT_METHOD.CARD ? "stripeCard" : method === PAYMENT_METHOD.BANCONTACT ? "stripeBancontact" : method === PAYMENT_METHOD.ALIPAY ? "stripeAlipay" : method === PAYMENT_METHOD.WECHATPAY ? "stripeWechatPay" : method === PAYMENT_METHOD.EPS ? "stripeEps" : method === PAYMENT_METHOD.SOFORT ? "stripeSofort" : method === PAYMENT_METHOD.P24 ? "stripeP24" : method === PAYMENT_METHOD.PAYPAL ? "paypal" : method === PAYMENT_METHOD.OXXO ? "stripeOxxo" : method === PAYMENT_METHOD.GOOGLE_PAY ? "stripeGooglePay" : method === PAYMENT_METHOD.APPLE_PAY ? "stripeApplePay" : method === PAYMENT_METHOD.GIROPAY ? "stripeGiroPay" : "stripeIdeal"
                        ]}
                        breadcrumb={["System", method === PAYMENT_METHOD.PAYPAL ? "Paypal" : "Stripe", method === PAYMENT_METHOD.PAYPAL ? "" : PaymentMethods[method].display_name]}>

                        <Analytics />

                        <Spacer size={24} />

                        <MethodTable {...props} />
                </Dashboard>


        return Render()
}
