import { ApiKeysTableHead, ApiKeysTableRow, Card } from "../..";
import { ApiKeysRowProps, UUID } from "../../../utilities";



export const ApiKeysTable = (rows: ApiKeysRowProps[]) => {

    let n = 0, id = UUID.create()

    const render = () =>
        <Card noPadding>
            <ApiKeysTableHead />
            {
                Object.values(rows).map(row =>
                    <ApiKeysTableRow key={id + n++} {...row} />
                )
            }
        </Card>

    return render()
}