import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_LOGS_TABLE_COLS, ApiLogsEngine, ApiLogsFilter, ApiLogsOrder, ApiLogsRowProps, HTTP_STATUS_CODE } from "../utilities";

export interface ApiLogsData {
    apiLogs: ApiLogsRowProps[]
    period: number
    order: ApiLogsOrder
    filters: ApiLogsFilter[]
    pagination: {
        results: number
        current: number
    }
}

const initialState: ApiLogsData = {
    apiLogs: [],
    period: 0,
    order: {
        by: API_LOGS_TABLE_COLS.DATE,
        ascendent: true
    },
    filters: [
        {
            state: true,
            type: HTTP_STATUS_CODE.OK
        },
        {
            state: true,
            type: HTTP_STATUS_CODE.UNAUTHORIZED
        },
        {
            state: false,
            type: HTTP_STATUS_CODE.NOT_FOUND
        },
        {
            state: true,
            type: HTTP_STATUS_CODE.BAD_REQUEST
        },
        {
            state: false,
            type: HTTP_STATUS_CODE.SERVER_ERROR
        }
    ],
    pagination: {
        results: 15,
        current: 0
    }
}

export const apiLogsSlice = createSlice({
    name: 'apiLogs',
    initialState,
    reducers: {

        loadData: (state: ApiLogsData) => {
            var apiLogs = ApiLogsEngine.getRows(state.period)
            return {
                ...state,
                apiLogs,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order: initialState.order
            }
        },

        orderData: (state: ApiLogsData, action: PayloadAction<API_LOGS_TABLE_COLS>) => {
            let order = {
                by: action.payload,
                ascendent: action.payload !== state.order.by || !state.order.ascendent
            }

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0
                },
                order
            }
        },

        setCurrentPage: (state: ApiLogsData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload
                }
            }
        },

        setPageResults: (state: ApiLogsData, action: PayloadAction<number>) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 0,
                    results: action.payload
                }
            }
        },

        toggleAppliedFilter: (state: ApiLogsData, action: PayloadAction<HTTP_STATUS_CODE>) => {
            return {
                ...state,
                filters: state.filters.map(x => x.type !== action.payload ? x : { ...x, state: !x.state }),
                pagination: {
                    ...state.pagination,
                    current: 0
                }
            }
        }
    }
})

export const apiLogsActions = apiLogsSlice.actions

export const apiLogsReducer = apiLogsSlice.reducer

