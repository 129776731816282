import { Col, Icon, P, Spacer } from ".."
import { UUID } from "../../utilities"


export const Breadcrumb = (props: { path: string[] }) => {

        let n = 0, id = UUID.create()

        var cleaned = props.path.filter(x => x.length > 0)

        const render = () => <Col wrap="nowrap" align="center">
                {
                        cleaned[0] &&
                        <Col xs="content" align="center" key={id + n++}>
                                <P medium variant="secondary">
                                        {cleaned[0]}
                                </P>
                        </Col>
                }
                {
                        cleaned.slice(1).map(x =>
                                <Col xs="content" align="center" key={id + n++}>
                                        <Spacer size={8} />
                                        <Icon variant="arrow" width={10} rotate={270} />
                                        <Spacer size={8} />
                                        <P medium variant="secondary">
                                                {x}
                                        </P>
                                </Col>
                        )
                }
        </Col>

        return render()
}


