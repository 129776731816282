import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, FormInput, ModalTitle, Spacer } from "../..";
import { webhookChannelsActions } from "../../../redux";
import { UUID } from "../../../utilities";



export const WebhookChannelsCreationForm = (props: { onCreated: () => void }) => {

    const dispatch = useDispatch()
    const { createWebhookChannel } = webhookChannelsActions
    const [state, setState] = useState({ name: '', url: '', eventType: '' })
    const [err, setErr] = useState({ name: '', url: '', eventType: '' })

    const render = () =>
        <Col>
            <ModalTitle>
                Create channel
            </ModalTitle>
            <FormInput type="text" onChange={onChange("name")} errorMessage={err.name}>Name</FormInput>
            <Spacer size={16} />
            <FormInput type="text" onChange={onChange("url")} errorMessage={err.url}>Url</FormInput>
            <Spacer size={16} />
            <FormInput type="select"
                options={Object.keys(EventTypes).map(x => { return { label: x, value: x } })}
                onSelect={onChange("eventType")} errorMessage={err.eventType}>Url</FormInput>
                <Spacer size={24} />
            <Button variant="primary" onClick={onConfirm} disabled={err.name.length > 0 || err.eventType.length > 0 || err.url.length > 0}>Confirm</Button>
        </Col>

    const validate = () => {
        var valid = true
        var name = ''
        var url = ''
        var eventType = ''

        if (state.name.length === 0) {
            name = "Type a channel name."
            valid = false
        }
        else if (state.name.length < 3) {
            name = "Type a name longer at least 3 characters."
            valid = false
        }

        if (state.url.length === 0) {
            url = "Type a channel url."
            valid = false
        }
        else if (!state.url.startsWith("https://")) {
            url = "Url must start wtih https://."
            valid = false
        }

        if (state.eventType.length === 0) {
            eventType = "Select an event type."
            valid = false
        }

        setErr({ name, url, eventType })
        return valid
    }

    const onChange = (field: "name" | "url" | "eventType") => (val: string | number | (string | number)[]) => {
        setState({ ...state, [field]: val })

        if (err.name.length === 0 && err.url.length === 0 && err.eventType.length === 0) {
            return
        }

        validate()
    }


    const onConfirm = () => {

        if (!validate()) {
            return
        }

        dispatch(createWebhookChannel({
            id: UUID.create(),
            name: state.name,
            url: state.url,
            eventType: state.eventType,
            lastEvent: undefined,
            created: new Date().getTime()
        }))

        setState({ name: '', url: '', eventType: '' })
        props.onCreated && props.onCreated()
    }


    return render()
}

const EventTypes = {
    ["payin:created"]: "payin:created",
    ["payin:updated"]: "payin:updated",
    ["payin:partial"]: "payin:partial",
    ["payin:paid"]: "payin:paid",
    ["payin:paid:product"]: "payin:paid:product",
    ["payin:cancelled"]: "payin:cancelled",
    ["payin:disputed"]: "payin:disputed",
    ["payin:cancelled:product"]: "payin:cancelled:product",
    ["payin:disputed:product"]: "payin:disputed:product",
    ["payin:created:product"]: "payin:created:product",
    ["payin:partial:product"]: "payin:partial:product",
    ["payin:updated:product"]: "payin:updated:product"
    ,
    ["seller:created"]: "seller:created",
    ["seller:updated"]: "seller:updated",
    ["seller:onboarded"]: "seller:onboarded",
    ["seller:locked"]: "seller:locked"
    ,
    ["payout:created"]: "payout:created",
    ["payout:updated"]: "payout:updated",
    ["payout:locked"]: "payout:locked"
    ,
    ["dispute:created"]: "dispute:created",
    ["dispute:updated"]: "dispute:updated",
    ["dispute:closed"]: "dispute:closed",
    ["dispute:win"]: "dispute:win",
    ["dispute:lost"]: "dispute:lost"
    ,
    ["method:created"]: "method:created",
    ["method:updated"]: "method:updated",
    ["method:locked"]: "method:locked"
}
