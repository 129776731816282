import "flatpickr/dist/flatpickr.css"
import { useEffect, useRef, useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown'
import DatePicker, { DateTimePickerProps } from 'react-flatpickr'
import { useSelector } from 'react-redux'
import styled, { createGlobalStyle, css } from 'styled-components'
import { Button, ButtonsDesign, Col, Icon, P, S, Spacer, XS } from '..'
import { PaletteProps, Typography } from '../../design_system'
import { REDUX_STATE } from '../../redux'
import { Months, UUID } from "../../utilities"

export interface PeriodSelectorProps {
    start: Date
    end: Date
    onApplied: (start: Date, end: Date) => void
}

enum PERIOD_TYPE {
    UNDEFINED,
    LAST_24HR,
    LAST_7DAYS,
    LAST_30DAYS,
    THIS_MONTH,
    LAST_MONTH,
    THIS_YEAR,
    LAST_YEAR,
    ALL_TIME
}

export const PeriodSelector = (props: PeriodSelectorProps) => {

    let n = 0, id = UUID.create()
    const { palette } = useSelector(REDUX_STATE).theme

    const [show, setShow] = useState(false)
    const [period, setPeriod] = useState(PERIOD_TYPE.UNDEFINED)
    const [sameMonth, setSameMonth] = useState(props.start.getMonth() === props.end.getMonth() && props.start.getFullYear() === props.end.getFullYear())
    const [data, setData] = useState({ start: props.start, end: props.end })

    const ref = useRef<HTMLDivElement>(null)
    const startPicker = useRef<DatePicker>(null)
    const endPicker = useRef<DatePicker>(null)

    useEffect(() => {
        const contentEl = ref?.current;
        if (!contentEl) return;

        const listener: (this: Window, ev: MouseEvent) => any = (e) => {
            let t = e.target as Node
            t &&
                ref.current && !ref.current.contains(t) &&
                setShow(false)
        }

        window.addEventListener("click", listener);

        return () => {
            window.removeEventListener("click", listener)
        }
    }, [])

    useEffect(() => {
        ref.current && (
            ref.current.className = sameMonth ? "same-month" : " ")
    }, [sameMonth])

    useEffect(() => {
        setPeriod(
            Object.values(shortcutSelections)
                .reverse()
                .find(({ values: { start, end } }) =>
                    start.getTime() === data.start.getTime() &&
                    end.getTime() === data.end.getTime())?.type
            ?? PERIOD_TYPE.UNDEFINED
        )

        startPicker &&
            startPicker.current?.flatpickr.setDate(data.start)

        window.setTimeout(() => {
            endPicker &&
                endPicker.current?.flatpickr.setDate(data.end)
        }, 1)

        refreshMonthSelector()
    }, [data])


    const
        today = new Date(new Date().setHours(0, 0, 0, 0)),
        Y = today.getFullYear(),
        M = today.getMonth(),
        D = today.getDate()

    const shortcutSelections = [
        {
            name: "Last 24 Hours",
            type: PERIOD_TYPE.LAST_24HR,
            values: { start: today, end: new Date() }
        },
        {
            name: "Last 7 Days",
            type: PERIOD_TYPE.LAST_7DAYS,
            values: { start: new Date(Y, M, D - 7), end: today }
        },
        {
            name: "Last 30 Days",
            type: PERIOD_TYPE.LAST_30DAYS,
            values: { start: new Date(Y, M, D - 30), end: today }
        },
        {
            name: "This month",
            type: PERIOD_TYPE.THIS_MONTH,
            values: { start: new Date(Y, M, 1), end: today }
        },
        {
            name: "Last month",
            type: PERIOD_TYPE.LAST_MONTH,
            values: { start: new Date(Y, M - 1, 1), end: new Date(Y, M, 0) }
        },
        {
            name: "This year",
            type: PERIOD_TYPE.THIS_YEAR,
            values: { start: new Date(Y, 0, 1), end: today }
        },
        {
            name: "Last year",
            type: PERIOD_TYPE.LAST_YEAR,
            values: { start: new Date(Y - 1, 0, 1), end: new Date(Y, 0, 0) }
        },
        {
            name: "All time",
            type: PERIOD_TYPE.ALL_TIME,
            values: { start: UserRegistration, end: today }
        }
    ]

    const cur = {
        p: shortcutSelections
            .find(({ values: { start, end } }) =>
                start.getTime() === props.start.setHours(0, 0, 0, 0) &&
                end.getTime() === props.end.setHours(0, 0, 0, 0))?.name,
        s: {
            d: props.start.getDate(),
            m: props.start.getMonth() + 1,
            y: props.start.getFullYear().toString().slice(2),
        },
        e: {
            d: props.end.getDate(),
            m: props.end.getMonth() + 1,
            y: props.end.getFullYear().toString().slice(2),
        }
    }

    const btn = ButtonsDesign.alternate

    const render = () =>
        <div ref={ref} className={sameMonth ? "same-month" : " "}>
            <Dropdown show={show}>
                <DropdownToggleStyled id={id} onClick={() => setShow(!show)}>
                    <Col align="center" px={btn.horizontalPadding} bg={palette[btn.background]} max_h='33px' min_h='33px' wrap="nowrap" b_rad="6px" b={`1px solid ${palette.gray2}`}>
                        <Icon variant="date" height={11} color={palette[btn.color]+"cc"} />
                        <Spacer size={8} />
                        <S style={{ letterSpacing: '.6px' }} color={palette[btn.color]}>
                            {
                                cur.p ? cur.p :
                                    cur.s.m === cur.e.m ?
                                        `${cur.s.d} - ${cur.e.d}/${cur.s.m}/${cur.s.y}` :
                                        cur.s.y === cur.e.y ?
                                            `${cur.s.d}/${cur.s.m} - ${cur.e.d}/${cur.e.m}/${cur.e.y}` :
                                            `${cur.s.d}/${cur.s.m}/${cur.s.y} - ${cur.e.d}/${cur.e.m}/${cur.e.y}`
                            }
                        </S>
                    </Col>
                </DropdownToggleStyled>
                <Dropdown.Menu>
                    <Col xs={12} bg={palette.white} zIndex={1}>
                        <Col xs={12} wrap='nowrap'>
                            <Col xs="content" direction="column" p={12}>
                                {
                                    shortcutSelections.map(({ name, values: { start, end }, type }) =>
                                        <Col key={id + n++} pointer onClick={(e) => { e.preventDefault(); e.stopPropagation(); setData({ start, end }) }}>
                                            <PeriodMenuItem {...{ palette, active: type === period }} >
                                                <P medium nowrap variant={type === period ? "dark" : "primary"}>{name}</P>
                                            </PeriodMenuItem>
                                        </Col>
                                    )
                                }
                            </Col>
                            <Col xs="content" className="start-date" bl={`1px solid ${palette.gray2}`} br={`1px solid ${palette.gray2}`}>
                                <DatePicker
                                    {...{
                                        ...pickerProps,
                                        ref: startPicker,
                                        options: { ...pickerProps.options, maxDate: data.end, defaultDate: data.start },
                                        onChange: (e) => setData({ ...data, start: e[0] })
                                    }}
                                />
                            </Col>
                            <Col xs="content" className="end-date">
                                <DatePicker
                                    {...{
                                        ...pickerProps,
                                        ref: endPicker,
                                        options: { ...pickerProps.options, minDate: data.start, defaultDate: data.end },
                                        onChange: (e) => setData({ ...data, end: e[0] })
                                    }}
                                />
                            </Col>
                        </Col>
                        <Col xs={12} p={24} bt={`1px solid ${palette.gray2}`} align='center'>
                            <Col xs={3} />
                            <Col>
                                <P>
                                    {
                                        `${data.start.getDate()} ${Months[data.start.getMonth()]} ${data.start.getFullYear()}`
                                        + " - " +
                                        `${data.end.getDate()} ${Months[data.end.getMonth()]} ${data.end.getFullYear()}`
                                    }
                                </P>
                            </Col>
                            <Col xs="content" wrap='nowrap'>
                                <Button variant="alternate" onClick={() => setData({ start: props.start, end: props.end })}>Cancel</Button>
                                <Spacer size={24} />
                                <Button variant="primary" onClick={() => {
                                    props.onApplied &&
                                        props.onApplied(data.start, data.end)
                                    setShow(false)
                                }}>Apply</Button>
                            </Col>
                        </Col>
                    </Col>
                </Dropdown.Menu>
            </Dropdown>
        </div>

    const pickerProps: DateTimePickerProps = {
        style: { display: 'none' },
        onMonthChange: () => refreshMonthSelector(),
        options: {
            dateFormat: "d-m-Y",
            static: true, inline: true,
            monthSelectorType: "static"
        }
    }

    const refreshMonthSelector = () => {
        startPicker && endPicker &&
            setSameMonth(
                startPicker.current?.flatpickr.currentMonth === endPicker.current?.flatpickr.currentMonth &&
                startPicker.current?.flatpickr.currentYear === endPicker.current?.flatpickr.currentYear)
    }





    return render()
}


const UserRegistration = new Date(2022, 11, 28)

const DropdownToggleStyled = styled(Dropdown.Toggle)`
    padding: 0px!important;
    border: unset!important;
    box-shadow: unset!important;
    background: unset!important;
    border-radius: 6px!important;

    &::after {
        display: none;
    }
`

const PeriodMenuItem = styled.div<{ palette: PaletteProps, active: boolean }>`
${({ palette, active }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
  
    ${active && css`&, &:hover {
        background-color: ${palette.gray2};
    }`};

    &:hover {
        background-color: ${palette.gray1};
    }
`};
`


export const PeriodStyles = createGlobalStyle<{ palette: PaletteProps }>`
${({ palette }) => css`
    .same-month .start-date .flatpickr-next-month,
    .same-month .end-date .flatpickr-prev-month {
        visibility: hidden !important;
        pointer-events: none !important;
    }  

    .flatpickr-wrapper {
        padding: 8px;
        
        div.flatpickr-calendar {
                border: unset !important;
                box-shadow: unset !important;
                -webkit-box-shadow: unset !important;

            .flatpickr-months {
                align-items: center;

                
                .flatpickr-next-month , .flatpickr-prev-month {
                    display: flex;
                    position: static;
                    align-items: center;
                    height: min-content;

                    &, &:hover svg {
                        fill: ${palette.black};
                    }

                    svg {
                        width: 12px;
                    }
                }

                .flatpickr-current-month {
                    width: 100%;
                    display: flex;
                    position: static;
                    justify-content: space-between;
                }

                .cur-month {
                    width: 44%;
                    text-align: right;
                    color: ${palette.black};
                    ${Typography.H4.styles};
                    font-weight: 600;
                    &:hover {
                        background: transparent;
                    }
                }

                .numInputWrapper {
                    width: 44%;
                    pointer-events: none;
                    color: ${palette.black};
                    input {
                        ${Typography.H5.styles};
                        font-weight: 500;
                    }
                }
            }
        }
    }
`}
`
