import { PAYMENT_METHOD_STATE } from "../utilities/paymentMethods";
import { ONBOARDING_STATE, SELLER_STATE, SellerProps } from "../utilities/seller";

export const Sellers: SellerProps[]
    = [
        {
            id: "415e13c3-2ef4-47ed-b78e-4083a97eb7de", name: "Dexter Foy", email: "dfoy0@time.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "czk", country: "cz", registration: new Date(2023, 6, 19).getTime(),
            methods: {}
        },
        {
            id: "3ea67da4-9a67-4dbf-bec8-e3b66c4c363b", name: "Gregoire Mattiazzi", email: "gmattiazzi@delicious.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "mxn", country: "mx", registration: new Date(2023, 2, 10).getTime(),
            methods: {
                2: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "5da857c5-61ed-414e-a1b2-5a34e3bd0058", name: "Zonda Baulcombe", email: "zbaulcombe2@weather.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "pl", registration: new Date(2023, 7, 8).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "508a7226-9e0b-4ed1-8522-c48865911e95", name: "Lavinia Spurett", email: "lspurett3@census.gov", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED,currency: "aud", country: "au", registration: new Date(2023, 8, 19).getTime(),
            methods: {
                8: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "a558af78-4dc2-4e6b-a2c1-608693c18e79", name: "Orsa Sloey", email: "osloey4@google.es", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING,  currency: "usd", country: "us",  registration: new Date(2023, 12, 11).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "4c33320b-3af9-4d35-821f-5698179ff7b7", name: "Nahum Pettit", email: "npettit5@national.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "sek", country: "se", registration: new Date(2023, 3, 14).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.SUSPENDED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "e8504b35-c434-40dd-830c-6bf791243af0", name: "Holly Kopps", email: "hkopps6@forbes.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "gr",registration: new Date(2023, 6, 18).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "e94a23cf-a0bd-40a9-a441-852628319928", name: "Dill Butlin", email: "dbutlin7@mediafire.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "mxn", country: "mx", registration: new Date(2023, 5, 21).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "6968746b-4463-4514-944c-232a5150b554", name: "Craggy Landers", email: "clanders8@tinyurl.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 10, 3).getTime(),
            methods: {
                0: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "522017d9-b0fe-41a0-9099-56738447ae4f", name: "Mavis Catlette", email: "mcatlette9@chron.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "sek", country: "se", registration: new Date(2023, 2, 14).getTime(),
            methods: {
                9: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.SUSPENDED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "b892579c-2b71-4ae2-9da8-b3ed5d8cc0d1", name: "Reine McEniry", email: "rmcenirya@symantec.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "eur", country: "de", registration: new Date(2023, 3, 27).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "c0cf2235-ebd5-42a6-a96b-a2594678323f", name: "Hali Speerman", email: "hspeermanb@gravatar.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 10, 16).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "8f8bda9b-761c-447c-8c73-882cc3c91436", name: "Zenia Oselton", email: "zoseltonc@slashdot.org", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "de", registration: new Date(2023, 8, 24).getTime(),
            methods: {
                8: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.SUSPENDED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "a0e48146-19db-4d56-91bc-1af8f0b07f24", name: "Maud McLanachan", email: "mmclanachand@business.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "eur", country: "fr", registration: new Date(2024, 1, 17).getTime(),
            methods: {
                11: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "b95f1abd-01d1-4d33-a5c3-036ab5d760b5", name: "Zaria Dellenbach", email: "zdellenbache@loc.gov", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 10, 15).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "e376f1ee-d10e-4273-8f11-5b1f9f2c3fbf", name: "Pavel Drysdale", email: "pdrysdalef@ca.gov", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "czk", country: "cz", registration: new Date(2023, 9, 30).getTime(),
            methods: {
                10: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "b6be15a3-ded1-46ca-bc53-3e44f02c33a7", name: "Britni Murrock", email: "bmurrockg@epa.gov", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "jpy", country: "jp", registration: new Date(2023, 10, 7).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "68075ac0-31c5-4b84-8280-c52a90b582ca", name: "Bud Kayley", email: "bkayleyh@aboutads.info", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "usd", country: "us", registration: new Date(2023, 3, 12).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "806b6f9d-41ea-4981-b2f0-051903116356", name: "Willette Habergham", email: "whaberghami@gravatar.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "eur", country: "fr", registration: new Date(2023, 2, 11).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "e2cb6177-f59b-4cfb-a497-3697510b65e0", name: "Yule Paddington", email: "ypaddingtonj@mapy.cz", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.VERIFIED,currency: "aud", country: "au", registration: new Date(2023, 2, 23).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "d116690a-4b84-4b57-a085-3b6d3e57c442", name: "Ermina Riolfo", email: "eriolfok@dell.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "pt", registration: new Date(2023, 4, 21).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "eb1ef997-3d4c-442f-bd22-61b1843652c9", name: "Link Pickavant", email: "lpickavantl@disqus.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "it", registration: new Date(2023, 9, 16).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "de7598b4-ee43-40ec-94dc-a1abb1eb9cbb", name: "Monah Treleven", email: "mtrele@independent.uk", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "eur", country: "it", registration: new Date(2023, 11, 30).getTime(),
            methods: {
                11: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "74de30a2-c1bb-4f74-96d9-9b891c5cd9a9", name: "Don Lippiello", email: "dlippiellon@live.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "eur", country: "pl", registration: new Date(2023, 6, 2).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.SUSPENDED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "f4b8602a-9028-4c55-b902-4851e6cce465", name: "Cordelie Karslake", email: "ckarslak@independent.uk", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED,currency: "aud", country: "au", registration: new Date(2023, 11, 29).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "ca2a9a41-c1dd-46ea-ad08-f4a501d34a1b", name: "Land Elgram", email: "lelgramp@unc.edu", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "eur", country: "fr", registration: new Date(2023, 7, 22).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.SUSPENDED,
            }
        },
        {
            id: "011e0a1d-5d46-4d2e-914e-79f211f2a39c", name: "Sid Cuel", email: "scuelq@umn.edu", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 11, 28).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "d333a369-38c1-4d90-a5f1-d7c0b450981e", name: "Leena Collocott", email: "lcollocottr@amazon.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.VERIFIED, currency: "bgn", country: "bg", registration: new Date(2023, 6, 26).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.SUSPENDED,
            }
        },
        {
            id: "dafc1187-3a91-4980-82c9-ba1f9920b711", name: "Ban Corpe", email: "bcorpes@kickstarter.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "eur", country: "ie", registration: new Date(2023, 10, 23).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "a8f355b4-4802-4eb9-a178-96252e6378da", name: "Maxi Gotts", email: "mgottst@ovh.net", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED,  currency: "usd", country: "us",  registration: new Date(2023, 3, 11).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "d784a105-ad4d-4bb2-a01b-9eee0d7a9ce9", name: "Myrtice Cassel", email: "mcasselu@hp.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "nok", country: "no", registration: new Date(2023, 3, 15).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "3fb1e7a7-69e6-4af0-8b41-834c5187e699", name: "Garland Ganter", email: "gganterv@soundcloud.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 8, 19).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "6983edbd-8928-4ee5-b54d-07313cf2a14c", name: "Letta Churchward", email: "lchurchwardw@linkedin.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 6, 22).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "387bcea2-6395-4ad6-ae02-fa2227379708", name: "Lib Whelpdale", email: "lwhelpdalex@usnews.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 2, 7).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "e04dcc3d-a7fa-49a1-b65f-f27b3f23b974", name: "Shara Pepler", email: "speplery@over-blog.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "cad", country: "ca", registration: new Date(2023, 11, 5).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "51321729-5d04-4666-a62b-1914558e54cc", name: "Rees Lainge", email: "rlaingez@imgur.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "es", registration: new Date(2023, 5, 28).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "58f79e33-a40d-4f91-84b6-83c4d3bd22bc", name: "Meghann Donkersley", email: "mdonkersley@amazon.uk", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 2, 18).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "a246dc46-528b-4c64-8f85-81ddb2d63d80", name: "Alayne Simnett", email: "asimnett11@apache.org", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED,currency: "eur", country: "ro", registration: new Date(2024, 1, 17).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "9624d7f5-84df-49bd-b1c2-2965c15de781", name: "Glennie Lansley", email: "glansley12@flavors.me", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "gbp", country: "gb", registration: new Date(2023, 10, 10).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "e2737084-f81f-437c-8a22-21f8c4bbf02a", name: "Shauna Stockney", email: "sstockney13@auda.au", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "es", registration: new Date(2023, 5, 1).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "ce682f0e-f27e-4f93-863b-06b242679bf4", name: "Wiley Hollyar", email: "whollyar14@ed.gov", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "pt", registration: new Date(2023, 9, 28).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "7325b77d-4835-4a6c-9e3f-3ea435819a4c", name: "Dehlia Wrankmore", email: "dwrankmore15@hibu.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 11, 20).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.SUSPENDED,
                9: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "06e4c7c1-648e-4282-961a-72325592ec28", name: "Anton Demogeot", email: "ademogeot16@journal.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "eur", country: "fr", registration: new Date(2023, 5, 31).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "a3f995b0-ab51-4580-9e7e-241c33e258e3", name: "Phillis Nanson", email: "pnanson17@tinypic.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "eur", country: "pt", registration: new Date(2023, 12, 28).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "382aea2a-d6ac-44f0-84bc-12ead4850da0", name: "Kylila MacCracken", email: "kmaccrack@wikimedia.org", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "usd", country: "us", registration: new Date(2023, 2, 3).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.SUSPENDED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "d048fd3f-f105-4b21-9bc1-26a745442e47", name: "Tatum Dumphry", email: "tdumphry19@examiner.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD,currency: "eur", country: "ro", registration: new Date(2023, 8, 2).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "04665964-c54f-4b45-8c00-af70e94dfb1e", name: "Dynah Dykins", email: "ddykins1a@tinypic.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "eur", country: "fr", registration: new Date(2024, 1, 22).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "634272a2-f747-45fc-ad82-568b52bc295d", name: "Lauree Dundredge", email: "ldundredge1b@lulu.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "eur", country: "de",registration: new Date(2023, 12, 13).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "f30e07a6-ce33-4964-82a6-094050b1bd35", name: "Heall Haizelden", email: "hhaizeld@parallel.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fi", registration: new Date(2023, 7, 13).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "d9f9c048-8f77-4caa-b664-006dd0f8dd3e", name: "Gwyneth Whitely", email: "gwhitely1d@ocn.jp", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "eur", country: "fi", registration: new Date(2023, 3, 15).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "1eca6e35-1fd3-4395-ac0a-d189b556ffea", name: "Carmella La Batie", email: "clabatie1e@diigo.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "cad", country: "ca", registration: new Date(2023, 5, 24).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "695082a4-2339-4412-bc14-4e0f90492072", name: "Emelita Bleckly", email: "ebleckly1f@nymag.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "de",registration: new Date(2023, 5, 7).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "295cb591-9d47-4e9d-abcc-2312466a6894", name: "Anabel Forward", email: "aforward1g@desdev.cn", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 12, 4).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "68cfa1b7-bbb3-43d0-922a-595e5a5ed03b", name: "Tiphanie Lott", email: "tlott1h@ftc.gov", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "at", registration: new Date(2023, 9, 27).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "8c798261-3644-48e9-be7d-dcb3ef93494e", name: "Hadleigh Van der Velde", email: "hvandervel@istockphoto.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING,currency: "eur", country: "ro", registration: new Date(2023, 10, 1).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "174a68ec-f63e-4486-8e93-644edd37ea6c", name: "Crawford Boltwood", email: "cboltwood1j@prweb.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 2, 2).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "29942c4e-486e-4b8c-9933-ce2dad0662ff", name: "Sherrie Halsworth", email: "shalsworth1k@business.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED,currency: "usd", country: "us", registration: new Date(2023, 6, 12).getTime(),
            methods: {
                9: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.SUSPENDED,
            }
        },
        {
            id: "b2fb0dcf-6cfb-4eba-af1f-fed8a61e8489", name: "Bill Filipov", email: "bfilipov1l@whitehouse.gov", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "de",registration: new Date(2023, 9, 1).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "02a800f2-f97e-433c-a8cb-b51a4470938a", name: "Loraine Dawks", email: "ldawks1m@about.me", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED,currency: "usd", country: "us", registration: new Date(2023, 10, 13).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "41859c3a-f070-4f6c-804b-501af4947dfd", name: "Charil Aldcorne", email: "caldcorne@artisteer.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "sek", country: "se", registration: new Date(2023, 8, 2).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "eb1692ce-2ac3-406c-ac61-e04b2c2e6058", name: "Hinda Midner", email: "hmidner1o@artisteer.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "cad", country: "ca", registration: new Date(2023, 11, 21).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "f6aab546-bd62-48cd-9b35-0a049b8e0e38", name: "Anna Vanner", email: "avanner1p@uol.com.br", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "gbp", country: "gb", registration: new Date(2023, 10, 7).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "f868a32f-37cc-43ea-a01e-df488e4b2666", name: "Gerta O'Scanlan", email: "goscanlan@atlantic.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "usd", country: "us", registration: new Date(2023, 7, 20).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.SUSPENDED,
                8: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "13916f77-ac63-4faf-8bab-f5d98c61648a", name: "Elizabeth Kitteridge", email: "ekitteridge1r@msu.edu", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 9, 9).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "cce13521-3819-45e0-a748-16d6b724d56b", name: "Mic Pallis", email: "mpallis1s@slate.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "nok", country: "no", registration: new Date(2023, 12, 25).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "39e8d0cd-3f78-4e0b-85d9-14575bdd0ff9", name: "Lezley Hawtry", email: "lhawtry1t@collective.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "gbp", country: "gb", registration: new Date(2023, 10, 19).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "7b45956f-029b-4ad1-a44a-6e31d33307d0", name: "Christy Jacobovitz", email: "cjacoboviu@cpanel.net", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED,currency: "eur", country: "ie", registration: new Date(2023, 4, 3).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "dffb468a-8318-4b05-ae9b-7fb02f7076f1", name: "Herbert O'Hare", email: "hohare1v@bing.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "de",registration: new Date(2023, 7, 2).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.SUSPENDED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "b5f76567-95fd-4d3a-b8e6-e5e1f2bfdfc4", name: "Maurita Cranke", email: "mcranke1w@cnn.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "eur", country: "gr", registration: new Date(2024, 1, 23).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "2cae6779-f176-4dd3-a644-02b70284f1d5", name: "Goldarina Wontner", email: "gwontner@biglobe.jp", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 12, 17).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "4fb44100-4bdc-42e6-92d1-8e7e8343d6a5", name: "Daniel Petz", email: "dpetz1y@market.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 9, 4).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "8b00bfe8-e12e-4ca1-a68a-dc959bb3adaf", name: "Cordelia Yea", email: "cyea1z@archive.org", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "eur", country: "de",registration: new Date(2023, 6, 12).getTime(),
            methods: {
                6: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "785e573b-6ec0-4290-bd80-5b2c84aa022c", name: "Grenville Raincin", email: "graincin20@gateway.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "gbp", country: "gb", registration: new Date(2023, 4, 14).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "43253e79-d511-482e-b4c8-dc419b67bd6d", name: "Merill McGurn", email: "mmcgurn21@photo.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "usd", country: "us", registration: new Date(2023, 5, 24).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "deacde5e-5214-43de-8bb5-fef7a593f017", name: "Laurette Backshall", email: "lbackshall22@multiply.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "pt", registration: new Date(2023, 8, 5).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "b35a0e8f-45fc-4df8-8dc3-a8cadb651380", name: "Kaye Souter", email: "ksouter23@rakuten.jp", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "cad", country: "ca", registration: new Date(2023, 7, 10).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "552b4e72-cf2f-44b9-ac1f-a1d22b80aa40", name: "Irene Senescall", email: "isenesca@paginegialle.it", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "be", registration: new Date(2024, 1, 10).getTime(),
            methods: {
                0: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "bc6a6045-5b6f-4906-9058-bc0c51f6b8a5", name: "Joey Boocock", email: "jboocock25@umn.edu", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "sek", country: "se", registration: new Date(2023, 8, 21).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.SUSPENDED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "0f70dd8c-bed5-4a5b-ba4c-5965a03e257f", name: "Walther Cellone", email: "wcellone26@patch.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 4, 8).getTime(),
            methods: {
                3: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "1f92a4b1-123c-421b-bea0-75d4460c99e1", name: "Gabie Gozney", email: "ggozney27@fc2.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 4, 6).getTime(),
            methods: {
                0: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "13b58a66-ef39-450b-a335-e6c2deeaca80", name: "Wells Kinchlea", email: "wkinchlea28@is.gd", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "mt", registration: new Date(2023, 6, 5).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "aa4fdcfa-0e1f-47e6-b2f2-ea00ce2acfbd", name: "Beatrice Grunnill", email: "bgrunnill29@upenn.edu", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "nl",registration: new Date(2023, 11, 2).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "b847dbc9-538e-459c-82a4-ace88b08528f", name: "Lorelei Bartomeu", email: "lbartomeu2a@taobao.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 10, 18).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "c10f4ed3-4e0c-4356-a2e3-7a1fcf482d86", name: "Toby Kinvig", email: "tkinvig2b@storify.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "mxn", country: "mx", registration: new Date(2023, 4, 8).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                8: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                9: PAYMENT_METHOD_STATE.ENABLED,
                12: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "009ce5c9-c678-4263-9ad9-00181ba0e63f", name: "Alene Ong", email: "aong2c@usatoday.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "czk", country: "cz", registration: new Date(2023, 8, 4).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
                2: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "33179375-d9aa-4ae0-ae2b-b96f9e905e7c", name: "Sonny Tullis", email: "stullis2d@artisteer.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 6, 4).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                0: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "742fd28f-64fc-4e1a-b934-abc4fee50147", name: "Steffen Lindop", email: "slindop2e@geocities.jp", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "nl",registration: new Date(2023, 2, 14).getTime(),
            methods: {
                1: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                11: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "a46bbaad-e3ef-4320-92a4-6d76036444fe", name: "Freedman Hoovart", email: "fhoova@berkeley.edu", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "usd", country: "us", registration: new Date(2023, 9, 15).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                6: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "2fbb94fc-dad4-4ac5-a75a-5eea83847df0", name: "Nevin Dunsire", email: "ndunsire2g@examiner.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "eur", country: "at", registration: new Date(2023, 5, 24).getTime(),
            methods: {
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
                10: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "8459a28e-4d81-4457-b6f8-410f9bb3c231", name: "Lesly Dot", email: "ldot2h@1688.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED,currency: "eur", country: "ie", registration: new Date(2023, 3, 1).getTime(),
            methods: {
                10: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                3: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
        {
            id: "3d6ed4de-771b-42e5-a4c4-5a5220980b13", name: "Tommie Facer", email: "tfacer2i@tmall.com", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "eur", country: "fr", registration: new Date(2023, 7, 12).getTime(),
            methods: {
                0: PAYMENT_METHOD_STATE.ENABLED,
                7: PAYMENT_METHOD_STATE.ENABLED,
                1: PAYMENT_METHOD_STATE.ENABLED,
                5: PAYMENT_METHOD_STATE.ENABLED,
                4: PAYMENT_METHOD_STATE.ENABLED,
            }
        },
       
    ]

    // {
    //     id: "4c394f64-d6e3-4aef-b46d-f0efaf5a46ee", name: "Shurwood Harp", email: "sharp2j@blog.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "eur", country: "nl",registration: new Date(2023, 7, 17).getTime(),
    //     methods: {
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         3: PAYMENT_METHOD_STATE.ENABLED,
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         0: PAYMENT_METHOD_STATE.ENABLED,
    //         4: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "9ca55389-4ec1-436f-b450-d208641ec903", name: "Alexine Luciano", email: "aluciano2k@state.gov", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 4, 10).getTime(),
    //     methods: {
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         10: PAYMENT_METHOD_STATE.ENABLED,
    //         3: PAYMENT_METHOD_STATE.ENABLED,
    //         6: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "a07f4dce-9178-407f-bd66-2aa5c5aea69a", name: "Hamlen Hambers", email: "hhambers2l@51.la", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "nl",registration: new Date(2023, 2, 7).getTime(),
    //     methods: {
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.SUSPENDED,
    //     }
    // },
    // {
    //     id: "fea40b05-6fc6-4185-bb4a-53e8f6acc435", name: "Catlin Hawgood", email: "chawgood@princeton.edu", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "usd", country: "us", registration: new Date(2023, 7, 16).getTime(),
    //     methods: {
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         0: PAYMENT_METHOD_STATE.ENABLED,
    //         4: PAYMENT_METHOD_STATE.ENABLED,
    //         11: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "ccd0fba8-7bee-4926-bcfc-bfb53172a3eb", name: "Val Tebbs", email: "vtebbs2n@dot.gov", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "pt", registration: new Date(2023, 4, 21).getTime(),
    //     methods: {
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         11: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         4: PAYMENT_METHOD_STATE.ENABLED,
    //         3: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "6c423900-add0-4d51-a837-c1dcc14e0d9f", name: "Mab Montague", email: "mmontague@mediafire.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "nl",registration: new Date(2023, 7, 7).getTime(),
    //     methods: {
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         11: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         3: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "93797dc0-9d42-47c6-be3f-6205d64e80d6", name: "Tally Aves", email: "taves2p@hatena.jp", state: SELLER_STATE.LOCKED, onboarding: ONBOARDING_STATE.PENDING, currency: "usd", country: "us", registration: new Date(2023, 2, 27).getTime(),
    //     methods: {
    //         3: PAYMENT_METHOD_STATE.ENABLED,
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         8: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         4: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "ec23d848-e955-46ab-8b4a-21fcc929a7fc", name: "Elbertine Crace", email: "ecrace2q@drupal.org", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "eur", country: "fr", registration: new Date(2023, 10, 26).getTime(),
    //     methods: {
    //         6: PAYMENT_METHOD_STATE.ENABLED,
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         10: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "0dadb4ea-4606-4351-95e3-1fc8015101be", name: "Doralynne Scargle", email: "dscargle2r@ameblo.jp", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "gr",registration: new Date(2023, 8, 23).getTime(),
    //     methods: {
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         0: PAYMENT_METHOD_STATE.ENABLED,
    //         6: PAYMENT_METHOD_STATE.SUSPENDED,
    //     }
    // },
    // {
    //     id: "ab5aaca8-bee9-4726-8642-0a40eae33471", name: "Malvina Seemmonds", email: "mseemmonds1@business.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 8, 11).getTime(),
    //     methods: {
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         12: PAYMENT_METHOD_STATE.ENABLED,
    //         10: PAYMENT_METHOD_STATE.ENABLED,
    //         3: PAYMENT_METHOD_STATE.ENABLED,
    //         6: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "043d2a59-ad2f-4b98-bfd9-d96266ca4782", name: "Angeline Beddin", email: "abeddin2@tripadvisor.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "fr", registration: new Date(2023, 5, 7).getTime(),
    //     methods: {
    //         7: PAYMENT_METHOD_STATE.SUSPENDED,
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         9: PAYMENT_METHOD_STATE.ENABLED,
    //         4: PAYMENT_METHOD_STATE.ENABLED,
    //         11: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "1a353111-b659-4f25-98e0-7d7d0a675153", name: "Sayers Frobisher", email: "sfrobisher3@weebly.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "cad", country: "ca", registration: new Date(2023, 7, 8).getTime(),
    //     methods: {
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         0: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "359f6cfc-8d62-45df-a339-2488551c0ee6", name: "Natalee Lascell", email: "nlascell4@elpais.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.TO_ONBOARD, currency: "cad", country: "ca",registration: new Date(2023, 8, 11).getTime(),
    //     methods: {
    //         12: PAYMENT_METHOD_STATE.ENABLED,
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         1: PAYMENT_METHOD_STATE.SUSPENDED,
    //         5: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "19af11b6-866b-4a31-ae0b-e7952f5c2b62", name: "Corie Turneux", email: "cturneux5@mapy.cz", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.HAS_ERRORS, currency: "eur", country: "fr", registration: new Date(2024, 1, 22).getTime(),
    //     methods: {
    //         1: PAYMENT_METHOD_STATE.ENABLED,
    //         12: PAYMENT_METHOD_STATE.ENABLED,
    //         7: PAYMENT_METHOD_STATE.ENABLED,
    //         0: PAYMENT_METHOD_STATE.ENABLED,
    //     }
    // },
    // {
    //     id: "5d195a29-f57a-48ae-8ac4-876187c4ef26", name: "Kermy M'Quharge", email: "kmquharge6@yellow.com", state: SELLER_STATE.ACTIVE, onboarding: ONBOARDING_STATE.VERIFIED, currency: "eur", country: "gr", registration: new Date(2023, 7, 14).getTime(),
    //     methods: {}
    // }