import { ReactNode, useEffect, useState } from "react";
import { default as ReactModal, default as _Modal } from 'react-modal';
import { useSelector } from "react-redux";
import { Col, H2, Icon, Spacer } from "..";
import { REDUX_STATE } from "../../redux";
import { UUID } from "../../utilities";

export const Modal = (props: { children: [ReactNode, ReactNode], toClose?: boolean }) => {

    const { children, toClose } = props
    const { isDark, palette } = useSelector(REDUX_STATE).theme

    const [isOpen, setIsOpen] = useState(false)
    const close = () => setIsOpen(!isOpen)


    ReactModal.setAppElement('#root');
    let id = UUID.create()

    const onOpen = () => {
        let div = document.createElement('div')
        div.style.height = '120vh'
        document.getElementById(id)?.parentElement?.appendChild(div)
    }

    useEffect(() => { toClose && setIsOpen(false) }, [toClose])

    const render = () => <>
        {
            <Col xs='content' onClick={() => setIsOpen(!isOpen)}>
                {children[0]}
            </Col>
        }
        <_Modal  className="bios-modal" onAfterOpen={onOpen} id={id} isOpen={isOpen} shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true} onRequestClose={close} style={{
            overlay: {
                zIndex: 10,
                background: `${palette.white}D9`,
                overflowY: "scroll"
            },
            content: {
                border: "unset",
                background: isDark ? palette.gray2 : palette.white,
                boxShadow: `0px 1px 3px rgba(${isDark ? "255,255, 255, .08" : "0, 0, 0, 0.12"})${isDark ? "" : ", 0px 2px 8px -1px rgba(0, 0, 0, 0.05)"}`,
                height: 'min-content',
                margin: `18vh auto 5vh auto`,
                padding: `20px 20px`,
                width: '40%',
                minWidth: '450px'
            }
        }}>
            <Col xs={12} justify="end">
                <Col xs='content' position="absolute" zIndex={10}>
                    <Icon variant="close" width={20} color={palette.gray3} onClick={() => setIsOpen(!isOpen)} pointer />
                </Col>
            </Col>
            {children[1]}
        </_Modal>
    </>

    return render()
}

export const ModalTitle = (props: { children: ReactNode }) => {

    const render = () =>
        <Col>
            <H2 semiBold>
                {props.children}
            </H2>
            <Spacer size={24} h />
        </Col>

    return render()
}

