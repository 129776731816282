import styled, { css } from 'styled-components';
import { Layout, SpacerValue } from '../../design_system';

interface SpacerProps {
    size?: SpacerValue
    w?: boolean
    h?: boolean

    xs?: SpacerValue
    sm?: SpacerValue
    md?: SpacerValue
    lg?: SpacerValue
    xl?: SpacerValue
    xxl?: SpacerValue
}

interface LayoutSpacerProps {
    negative?: boolean
}

export const Spacers = {
    cardVertical: 20,
    cardHorizzontal: 20,
    pageVertical: 24,
    pageHorizzontal: 24
}

export const Spacer = (props: SpacerProps) =>
    <SpacerStyled {...{ ...props, size: props.size ?? Layout.defaultSpacer }} />

export const CardVerticalSpacer = (props: LayoutSpacerProps) =>
    <SpacerStyled {...{ ...props, size: props.negative ? 0 : Spacers.cardVertical, h: true }} style={props.negative ? { marginTop: `-${Spacers.cardVertical}px`, marginBottom: `-${Spacers.cardVertical}px` } : undefined} />

export const CardHorizzontalSpacer = (props: LayoutSpacerProps) =>
    <SpacerStyled {...{ ...props, size: props.negative ? 0 : Spacers.cardHorizzontal, w: true }} style={props.negative ? { marginLeft: `-${Spacers.cardHorizzontal}px`, marginRight: `-${Spacers.cardHorizzontal}px` } : undefined} />

export const PageVerticalSpacer = (props: LayoutSpacerProps) =>
    <SpacerStyled {...{ ...props, size: props.negative ? 0 : Spacers.pageVertical, h: true }} />

export const PageHorizzontalSpacer = (props: LayoutSpacerProps) =>
    <SpacerStyled {...{ ...props, size: props.negative ? 0 : Spacers.pageHorizzontal, w: true }} />

interface SpacerStyledProps {
    size?: number
    w?: boolean
    h?: boolean

    xs?: SpacerValue
    sm?: SpacerValue
    md?: SpacerValue
    lg?: SpacerValue
    xl?: SpacerValue
    xxl?: SpacerValue
}

const SpacerStyled = styled.div<SpacerStyledProps >`
    ${({ size, w, h }) => size !== undefined && h && css`
        min-height:  ${size}px !important;
        height:  ${size}px !important;
        width: 100%;
        left: 0;
        right: 0;
    ` || size !== undefined && w && css`
        min-width: ${size}px !important;
        width: ${size}px !important;
        height: 100%;
        top: 0;
        bottom: 0;
    ` || size !== undefined && css`
        min-width: ${size}px !important;
        min-height:  ${size}px !important;
        width: ${size}px !important;
        height:  ${size}px !important;
    `};

    ${({ xs, w, h }) => xs !== undefined && css`
        @media (max-width: ${Layout.breakpoints.xs}px) {
            ${h ? css`
                min-height:  ${xs}px !important;
                height:  ${xs}px !important;
            ` : w ? css`
                min-width: ${xs}px !important;
                width: ${xs}px !important;
            ` : css`
                min-width: ${xs}px !important;
                min-height:  ${xs}px !important;
                width: ${xs}px !important;
                height:  ${xs}px !important;
            `};
        }
    `}

    ${({ sm, w, h }) => sm !== undefined && css`
        @media (min-width: ${Layout.breakpoints.xs}px) and (max-width: ${Layout.breakpoints.sm}px) {
            ${h ? css`
                min-height:  ${sm}px !important;
                height:  ${sm}px !important;
            ` : w ? css`
                min-width: ${sm}px !important;
                width: ${sm}px !important;
            ` : css`
                min-width: ${sm}px !important;
                min-height:  ${sm}px !important;
                width: ${sm}px !important;
                height:  ${sm}px !important;
            `};
        }
    `}

    ${({ md, w, h }) => md !== undefined && css`
        @media (min-width: ${Layout.breakpoints.sm}px) and (max-width: ${Layout.breakpoints.md}px) {
            ${h ? css`
                min-height:  ${md}px !important;
                height:  ${md}px !important;
            ` : w ? css`
                min-width: ${md}px !important;
                width: ${md}px !important;
            ` : css`
                min-width: ${md}px !important;
                min-height:  ${md}px !important;
                width: ${md}px !important;
                height:  ${md}px !important;
            `};
        }
    `}

    ${({ lg, w, h }) => lg !== undefined && css`
        @media (min-width: ${Layout.breakpoints.md}px) and (max-width: ${Layout.breakpoints.lg}px) {
            ${h ? css`
                min-height:  ${lg}px !important;
                height:  ${lg}px !important;
            ` : w ? css`
                min-width: ${lg}px !important;
                width: ${lg}px !important;
            ` : css`
                min-width: ${lg}px !important;
                min-height:  ${lg}px !important;
                width: ${lg}px !important;
                height:  ${lg}px !important;
            `};
        }
    `}

    ${({ xl, w, h }) => xl !== undefined && css`
        @media (min-width: ${Layout.breakpoints.lg}px) and (max-width: ${Layout.breakpoints.xl}px) {
            ${h ? css`
                min-height:  ${xl}px !important;
                height:  ${xl}px !important;
            ` : w ? css`
                min-width: ${xl}px !important;
                width: ${xl}px !important;
            ` : css`
                min-width: ${xl}px !important;
                min-height: ${xl}px !important;
                width: ${xl}px !important;
                height:  ${xl}px !important;
            `};
        }
    `}

    ${({ xxl, w, h }) => xxl !== undefined && css`
        @media (min-width: ${Layout.breakpoints.xl}px) {
            ${h ? css`
                min-height:  ${xxl}px !important;
                height:  ${xxl}px !important;
            ` : w ? css`
                min-width: ${xxl}px !important;
                width: ${xxl}px !important;
            ` : css`
                min-width: ${xxl}px !important;
                min-height:  ${xxl}px !important;
                width: ${xxl}px !important;
                height:  ${xxl}px !important;
            `};
        }
    `}
`