import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BuyerProps, PayinProps, SellerProps } from "../utilities";

export interface AnalyticsData {
    period: {
        start: number
        end: number
    }
    payins: {
        current: PayinProps[]
        last: PayinProps[]
    }
    sellers: {
        current: SellerProps[]
        last: SellerProps[]
    }
    buyers: {
        current: BuyerProps[]
        last: BuyerProps[]
    }
}

const initialState: AnalyticsData = {
    period: {
        start: new Date(2023, 0, 1).getTime(),
        end: new Date().getTime()
    },
    payins: {
        current: [],
        last: []
    },
    sellers: {
        current: [],
        last: []
    },
    buyers: {
        current: [],
        last: []
    }
}

export const analyticsDataSlice = createSlice({
    name: 'analyticsData',
    initialState,
    reducers: {
        
        setAnalyticsPeriod: (state: AnalyticsData, action: PayloadAction<{ start: Date, end: Date }>) => {
            const { start, end } = action.payload
            return {
                ...state,
                period: { ...state.period, start: start.getTime(), end: end.getTime() }
            }
        },
        
        setAnalyticsData: (state: AnalyticsData, action: PayloadAction<{
            payins: { current: PayinProps[], last: PayinProps[] }
            sellers: { current: SellerProps[], last: SellerProps[] }
            buyers: { current: BuyerProps[], last: BuyerProps[] }
        }>) => {
            const { payins, sellers, buyers } = action.payload
            return {
                ...state,
                payins,
                sellers,
                buyers
            }
        }
    }
})

export const { setAnalyticsPeriod, setAnalyticsData } = analyticsDataSlice.actions

export const analyticsDataReducer = analyticsDataSlice.reducer

