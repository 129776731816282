import { Dashboard, DisputeStateInfo, PaymentDetails, PaymentMethodDetails, PaymentTimeline, Spacer } from "../.."
import { Payins } from "../../../fake_data"
import { DemoRoutes } from "../../../routes"

export const PayinDetails = () => {

        const path = window.location.pathname.split('/')
        const id = path[path.length - 1]
        const payin = Payins.find(x => x.id === id)

        const Render = () =>
                <Dashboard
                        pageTitle="Details"
                        onPage={DemoRoutes.ledger.payinDetails}
                        breadcrumb={["Ledger", "Payments", "Payins"]}>
                        {
                                payin && <>
                                        <PaymentDetails {...payin} />
                                        <Spacer size={24} />

                                        {
                                                payin.dispute &&
                                                <>
                                                        <DisputeStateInfo {...{ ...payin.dispute, amount: payin.amount }} />
                                                        <Spacer size={24} />
                                                </>
                                        }

                                        <PaymentTimeline {...[
                                                ["10:32 AM", "Payment session created"],
                                                ["10:34 AM", "Checkout initialized"],
                                                ["10:34 AM", "Payment Method choosed"],
                                                ["10:34 AM", "Payment confirmed"],
                                                ["10:35 AM", "Payment method charged"]
                                        ]} />

                                        <Spacer size={24} />

                                        <PaymentMethodDetails {...{ method: payin.method, buyer: payin.buyer }} />
                                </>
                        }
                </Dashboard>

        return Render()
}
