import { BadgePropsForDispute, Card, CardDataCollection, CardDivider, CardHeaderData, CardTitle } from "../.."
import { DisputeReason, DisputesLedgerRowProps, Months, PAYMENT_METHOD } from "../../../utilities"

export const DisputeInfoDetails = (dispute: DisputesLedgerRowProps) => {

    const { id, amount, reason, networkReasonCode, provider, method, customer } = dispute

    let dueOn = new Date(dispute.dueOn)

    const render = () =>
        <Card>
            <CardTitle>Dispute</CardTitle>

            <CardHeaderData
                id={id}
                title={`$${amount}`}
                badge={BadgePropsForDispute(dispute)} />

            <CardDivider />

            <CardDataCollection data={[
                { data: ["Evidence due", `${Months[dueOn.getMonth()].slice(0, 3)} ${dueOn.getDate()} ${dueOn.getFullYear().toString().slice(2, 4)}`] },
                { data: ["Reason", DisputeReason(reason)] },
                { data: ["Network code", networkReasonCode] },
                { data: ["Gateway", `${provider.display_name}/${method.display_name}`] },
                { data: ["Source", method.value === PAYMENT_METHOD.CARD || method.value === PAYMENT_METHOD.GOOGLE_PAY || method.value === PAYMENT_METHOD.APPLE_PAY ? `•••• ${(Math.random() * 10000).toString().slice(0, 4)}` : customer.email] }
            ]} />
        </Card>

    return render()
}
