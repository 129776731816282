import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, Button, CardHorizzontalSpacer, Col, H3, H4, Icon, ModalPage, P, Row, Spacer } from "../.."
import { REDUX_STATE, apiLogsActions } from "../../../redux"
import { ApiLogDetails } from "../../../utilities"
import { API_LOGS_TABLE_COLS, ApiLogsEngine, ApiLogsRowProps, HTTP_METHOD, HTTP_STATUS_CODE, HttpStatusCode, UUID } from "../../../utilities"


export const ApiLogsTableRowLayout = (props: { children: ReactNode[], head?: boolean }) => {

    const { head, children } = props
    const { palette } = useSelector(REDUX_STATE).theme

    const render = () =>
        <Col bt={head ? undefined : `1px solid ${palette.gray2}`} pointer>
            {!head && <Spacer size={12} />}
            <Col align="center" wrap="nowrap">
                <CardHorizzontalSpacer />
                <Col w="15%" max_w="15%">{children[0]}</Col>
                <Col w="20%" max_w="20%">{children[1]}</Col>
                <Col w="25%" max_w="25%">{children[2]}</Col>
                <Col w="25%" max_w="25%">{children[3]}</Col>
                <Col w="15%" max_w="15%" justify="end">{children[4]}</Col>
                <CardHorizzontalSpacer />
            </Col>
            {!head && <Spacer size={12} />}
        </Col>

    return render()
}

export const ApiLogsTableHead = () => {

    let n = 0, id = UUID.create()
    const dispatch = useDispatch()
    const { orderData } = apiLogsActions
    const { theme: { palette }, apiLogs: { order } } = useSelector(REDUX_STATE)

    const cols = [
        { name: "Status", col: API_LOGS_TABLE_COLS.STATUS },
        { name: "Method", col: API_LOGS_TABLE_COLS.METHOD },
        { name: "Endpoint", col: API_LOGS_TABLE_COLS.ENDPOINT },
        { name: "Date", col: API_LOGS_TABLE_COLS.DATE },
    ]

    const render = () =>
        <ApiLogsTableRowLayout head>
            {
                cols.map(x =>
                    <Col xs="content" key={id + n++} py={12} pointer wrap="nowrap" align="center" onClick={() => dispatch(orderData(x.col as API_LOGS_TABLE_COLS))}>
                        <P nowrap mb={2} medium variant="secondary"> {x.name} </P>
                        <Icon pointer color={palette.black} height={18} variant={`ordinament${order.by !== x.col ? "" : order.ascendent ? "-asc" : "-des"}`} />
                    </Col>
                )
            }
        </ApiLogsTableRowLayout>

    return render()
}

export const ApiLogsTableRow = (props: ApiLogsRowProps) => {

    const { palette } = useSelector(REDUX_STATE).theme
    const { id, status, method, endpoint, date, time, origin, apiVersion, idempotency } = props

    let req = 0, res = 0
    const { query, request, response }: ApiLogDetails = ApiLogsEngine.getLog(props)

    const render = () =>
        <ApiLogsTableRowLayout>
            <Badge variant={status === HTTP_STATUS_CODE.OK ? "success" : "danger"}>
                {HttpStatusCode(status)}
            </Badge>
            <P semiBold>
                {method === HTTP_METHOD.GET ? "GET" : "POST"}
            </P>
            <P regular variant="dark">{endpoint}</P>
            <P regular variant="dark">{date.toLocaleDateString()}</P>
            <Col justify="end">
                <Col xs="content">
                    <ModalPage>
                        <Button xs variant="alternate">Payload</Button>

                        <Col >
                            <H3 semiBold>{`${method === HTTP_METHOD.GET ? "GET" : "POST"} ${endpoint}${query}`}</H3>
                            <Spacer size={24} />
                            <Row gutterHeight={16}>
                                {
                                    [
                                        ["Api Version", apiVersion],
                                        ["Time", time],
                                        ["Status", HttpStatusCode(status)],
                                        ["Origin", origin],
                                        ["ID", id],
                                        ["Idempotency", idempotency],
                                    ].map(x =>
                                        <Col xs={12} key={id + x[0]} justify="between">
                                            <P medium>{x[0]}</P>
                                            <P regular>{x[1]}</P>
                                        </Col>
                                    )
                                }
                            </Row>
                            <Spacer size={40} />
                            <Col px={16} bg={palette.black} b_rad="4px">
                                <Spacer size={24} />
                                <H4 negative variant="dark" semiBold>Request</H4>
                                <Spacer size={16} />
                                {
                                    request.map(x =>
                                        <Row mb={8} key={`req${id}${req++}`}>
                                            <Col xs='content'>
                                                <P negative>{req}</P>
                                            </Col>
                                            <Col px={12} ml={x === '{' || x === '}' ? 0 : 24}>
                                                <P negative>{x}</P>
                                            </Col>
                                        </Row>
                                    )
                                }
                                <Spacer size={24} />
                                <H4 negative variant="dark" semiBold>Response</H4>
                                <Spacer size={16} />
                                {
                                    response.map(x =>
                                        <Row mb={8} key={`res${id}${res++}`}>
                                            <Col xs='content'>
                                                <P negative>{res}</P>
                                            </Col>
                                            <Col px={12} ml={x === '{' || x === '}' ? 0 : 24}>
                                                <P negative>{x}</P>
                                            </Col>
                                        </Row>
                                    )
                                }
                                <Spacer size={24} />
                            </Col>
                        </Col>
                    </ModalPage>
                </Col>
            </Col>
        </ApiLogsTableRowLayout >

    return render()
}
